import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OutputAcceptOrRejectRequestPermissionModel } from '../../models/classes/notification/output-accept-or-reject-request-permission-model';
import { RequestPermissionNotification } from '../../models/classes/notification/request-permission-notification';
import { RequestPermissionNotificationStatus } from '../../models/enums/request-permission-notification-status';

@Component({
  selector: 'app-modal-accept-or-reject-request-permission',
  templateUrl: './modal-accept-or-reject-request-permission.component.html',
  styleUrls: ['./modal-accept-or-reject-request-permission.component.scss']
})
export class ModalAcceptOrRejectRequestPermissionComponent implements OnInit {

  public SupervisorNotes: string;

  public RequestTitle: string;
  public RequestBody: string;
  public ButtonText: string;

  @Input() RequestPermissionNotification: RequestPermissionNotification;
  @Input() RequestPermissionNotificationStatus: RequestPermissionNotificationStatus;

  @Output() OutputAcceptOrRejectRequestPermission =
    new EventEmitter<OutputAcceptOrRejectRequestPermissionModel>();



  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {


    if (this.RequestPermissionNotificationStatus == RequestPermissionNotificationStatus.Accepted) {
      this.RequestTitle = this.ButtonText = "AcceptRequest";
      this.RequestBody = "AreYouSureThatYouWantToAcceptRequest";
    } else if (this.RequestPermissionNotificationStatus == RequestPermissionNotificationStatus.Rejected) {
      this.RequestTitle = this.ButtonText = "RejectRequest";
      this.RequestBody = "AreYouSureThatYouWantToRejectRequest";
    }

  }


  AcceptOrRejectRequest(
    requestPermissionNotification: RequestPermissionNotification,
    requestPermissionNotificationStatus: RequestPermissionNotificationStatus) {

    var outputAcceptOrRejectRequestPermissionModel = new OutputAcceptOrRejectRequestPermissionModel();
    outputAcceptOrRejectRequestPermissionModel.RequestPermissionNotification
      = requestPermissionNotification;

    outputAcceptOrRejectRequestPermissionModel.RequestPermissionNotificationStatus =
      requestPermissionNotificationStatus;

    outputAcceptOrRejectRequestPermissionModel.SupervisorNotes =
      this.SupervisorNotes;

    this.OutputAcceptOrRejectRequestPermission.emit(outputAcceptOrRejectRequestPermissionModel);


  }

}
