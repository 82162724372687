import { Pipe, PipeTransform } from '@angular/core';
import { SharedService } from 'src/app/services/other/shared.service';
@Pipe({
  name: 'ToFixedPipe',
  pure: false
})

export class ToFixedPipe implements PipeTransform {

  constructor(public sharedService: SharedService) {

  }

  transform(inputNumber: number): string {

    if (!inputNumber || !Number(inputNumber)) {
      return null;
    }

    var number = Number(inputNumber);

    //var returnNumber = number % 1 != 0 ? number.toFixed(this.sharedService.GetFractionRoundCount) : (number + "");
    var returnNumber = number.toFixed(this.sharedService.GetFractionRoundCount);

    return returnNumber;

  }
}
