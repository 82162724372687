
export class CreateGymClientMembershipFrozenDaysLog {
    constructor() {

    }

    public Id: number;
    public BranchId: number;

    public GymClientMembershipId: number;
    public FrozenDaysCount: number;

    public FrozenDaysStartDate: string;
    public FrozenDaysEndDate: string;


}