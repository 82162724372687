<div class="branch-list" fxLayout="column" fxLayoutAlign="start center">
  <lib-language style="visibility: hidden"></lib-language>

  <div class="branch-list__content">
    <main>
      <div class="flex">
        <div class="flex back-btn">
          <i class="fa-solid fa-arrow-left me-2" [ngClass]="{'fa-rotate-180': isRtl}" [routerLink]="['/']" ></i>
        </div>
        <h1 class="flex-1">{{ "Your_Branches" | translate }}</h1>
        <div class="w-8"></div>
      </div>
      <p>{{ "Select_the_branch" | translate }}</p>
    </main>
    <div class="search">
      <i class="fa-solid fa-magnifying-glass" [ngClass]="isRtl ? 'rtl' : 'ltr'"></i>
      <input
        type="search"
        placeholder="{{ 'Search_by_branch' | translate }}"
        (input)="onSearchBranchList($event)"
      />
    </div>
    <!-- 
    <mat-form-field
      appearance="outline"
      (selectionchange)="onCategoryChange($event)"
      class="w-100"
      *ngIf="isSzSupport"
    >
      <mat-label>User Branch</mat-label>
      <mat-select>
        <mat-option *ngFor="let branch of branchUser" [value]="branch.Id">
          {{ branch.Name }}
        </mat-option>
      </mat-select>
    </mat-form-field> -->

    <div class="branch-list-items">
      <app-branch-item
        [branchList]="branchList"
        (clickItem)="onClickBranchItem($event)"
        *ngIf="branchList?.length; else noData"
      ></app-branch-item>
    </div>

    <mat-paginator
      [length]="totalCount"
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 25, 100]"
      aria-label="Select page"
      (page)="handlePageEvent($event)"
    >
    </mat-paginator>
  </div>

  <ng-template #noData>
    <h1 class="empty-text">{{ "Empty Data" | translate }}</h1>
  </ng-template>
</div>
