export enum PaymentMethodType {
  Cash = 1,
  Cheque = 2,
  CreditCard = 3,//
  Other = 4,
  DelayedForPurchases = 5,//
  Loyalty = 6,//
  GiftCard = 7,
  MoneyOrder = 8,
  Voucher = 9,
  ClientDown = 10,
  Wallet = 11,
  Fawry = 12,
  VodafoneCash = 13,
  HyperPay = 14,
  HICAPS = 15,//
  PayTaps = 16,
  STC = 17,
  ApplePay = 18,
  Tamara = 20,
  Geidea = 21,
  tabby = 22,
  ElectronicsFundTransfer = 19, //
  Payfort = 23,
  ClickPay = 25,
  MyFatoorah = 26,
  GlameraMyFatoorah = 99,
}
