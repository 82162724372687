<section fxLayout="row" fxLayoutAlign="start stretch">
  <div
    [ngClass]="dir === 'ar' ? 'slider-ar' : 'slider-en'"
    fxFlex="40"
    fxHide
    fxShow.gt-sm
  >
    <img
    class="sign-up-logo"
      src="assets/img/glameraTradeMarkLogo.png"
      width="190px"
      alt=""
      title=""
    />
    <main class="signup-main">
      <h2>{{ "welocme_to_glamera" | translate }}</h2>
      <div class="d-flex">
        <p>{{ "ADOBT_GLAMERA" | translate }}</p>
      </div>
    </main>
  </div>
  <form
    fxFlex="60"
    fxFlex.lt-md="100"
    fxLayout="column"
    fxLayoutAlign="start stretch"
    (submit)="onSubmit()"
    [formGroup]="form"
  >
    <div fxLayout="row" fxLayoutAlign="space-between start" class="watch-video">
      <lib-language></lib-language>
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        class="gap-5"
        (click)="onOpenVideo()"
      >
        <img src="assets/img/watch_video.jpg" alt="" title="" />

        <div class="know-more">
          <span>{{ "know_more" | translate }}</span>
          <p>{{ "WatchVideo" | translate }}</p>
        </div>
      </div>
    </div>

    <main>
      <h1>
        {{ "START_YOUR_BUSINESS" | translate }}
        <img class="path" src="assets/img/auth_path.jpg" title="" alt="" />
      </h1>
      <p class="mb-0">{{ "SIMPLE_STEPS" | translate }}</p>
    </main>

    <div fxLayout="row wrap" fxLayoutAlign="space-between center">
      <div
        fxLayout="column"
        fxLayoutAlign="space-between stretch"
        fxFlex.gt-sm="45"
        fxFlex.lt-md="100"
      >
        <mat-label class="label">{{ "FIRST_NAME" | translate }}</mat-label>
        <mat-form-field appearance="outline" class="br-20">
          <input
            matInput
            [placeholder]="'ENTER_FIRST_NAME' | translate"
            formControlName="FirstName"
            [maxLength]="10"
          />
          <mat-error>
            <app-error-form
              [name]="'FIRST_NAME'"
              [control]="form.controls['FirstName']"
              [maxLength]="10"
            ></app-error-form>
          </mat-error>
        </mat-form-field>
      </div>

      <div
        fxLayout="column"
        fxLayoutAlign="space-between stretch"
        fxFlex.gt-sm="45"
        fxFlex.lt-md="100"
      >
        <mat-label class="label">{{ "LAST_NAME" | translate }}</mat-label>
        <mat-form-field appearance="outline">
          <input
            matInput
            [placeholder]="'ENTER_LAST_NAME' | translate"
            formControlName="LastName"
            [maxLength]="10"
          />
          <mat-error>
            <app-error-form
              [name]="'LAST_NAME'"
              [control]="form.controls['LastName']"
            ></app-error-form>
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="space-between center">
      <div
        fxLayout="column"
        fxLayoutAlign="space-between stretch"
        fxFlex.gt-sm="45"
        fxFlex.lt-md="100"
      >
        <mat-label class="label">{{ "EMAIL" | translate }}</mat-label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input
            type="email"
            matInput
            [placeholder]="'Enter_your_email' | translate"
            formControlName="Email"
          />
          <mat-error>
            <app-error-form
              [name]="'EMAIL'"
              [control]="form.controls['Email']"
            ></app-error-form>
          </mat-error>
        </mat-form-field>
      </div>

      <div
        fxLayout="column"
        fxLayoutAlign="space-between stretch"
        fxFlex.gt-sm="45"
        fxFlex.lt-md="100"
      >
        <mat-label class="label">{{
          "AUTH.PHONE_NUMBER" | translate
        }}</mat-label>
        <div fxLayout="row" fxLayoutAlign="start start" class="gap-5">
          <mat-form-field fxFlex="33" appearance="outline">
            <mat-select formControlName="CountryId">
              <mat-select-trigger>
                <ng-container *ngIf="form.controls['CountryId'].value">
                  <div class="d-flex align-items-center">
                    <img style="border: 1px solid #ddd;" [src]="getSelectedCountry()?.FlagPath" width="24px" alt="">
                  <span class="mx-1 f14">{{ getSelectedCountry()?.CallingCode }}</span>
                  </div>
                </ng-container>
              </mat-select-trigger>
              <mat-option
                *ngFor="let country of countries"
                [value]="country.Id"
              >
                <div class="d-flex align-items-center">
                  <img style="border: 1px solid #ddd;" [src]="country.FlagPath" width="24px" alt="">
                <span class="mx-1 f14">{{ country.CallingCode }}</span>
                </div>
              </mat-option>
            </mat-select>
            
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="67">
            <input
              matInput
              [placeholder]="'AUTH.PHONE_NUMBER' | translate"
              formControlName="MobileNumber"
              [maxLength]="12"
              appNumberOnly
            />
            <mat-error>
              <app-error-form
                [name]="'PHONE_NUMBER'"
                [control]="form.controls['MobileNumber']"
                [maxLength]="12"
                [minLength]="4"
              ></app-error-form>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="space-between center">
      <div
        fxLayout="column"
        fxLayoutAlign="space-between stretch"
        fxFlex.gt-sm="45"
        fxFlex.lt-md="100"
      >
        <mat-label class="label">{{ "PASSWORD" | translate }}</mat-label>
        <mat-form-field appearance="outline">
          <input
            matInput
            [type]="hide ? 'password' : 'text'"
            formControlName="Password"
            [placeholder]="'ENTER_PASSWORD' | translate"
            [maxlength]="20"
          />
          <button
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
            type="button"
          >
            <img
              [src]="
                hide ? 'assets/img/close_eye.svg' : 'assets/img/close_eye.svg'
              "
              alt=""
              title=""
              class="mt__5"
            />
          </button>
          <mat-error>
            <app-error-form
              [name]="'PASSWORD'"
              [control]="form.controls['Password']"
            ></app-error-form>
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="bt-1">
      <mat-checkbox
        class="example-margin"
        formControlName="AgreeToPrivacyPolicy"
        color="primary"
      >
        <span>
          {{ "AGREE_WITH" | translate }}
          <a (click)="onOpenPrivacy($event)" class="privacy">
            {{ "TERMS_CONDITIONS" | translate }}
          </a>
        </span>
      </mat-checkbox>

      <button
        mat-raised-button
        color="primary"
        type="submit
  
      "
        class="submit-btn"
        [class.spinner]="loading"
        [disabled]="form.invalid || loading"
      >
        {{ "SIGN_UP" | translate }}
      </button>
    </div>

    <div class="signUp">
      <p>
        {{ "HAVE_ACCOUNT" | translate
        }}<a [href]="'/'+config.routes.LOGIN">{{ "Login" | translate }}</a>
      </p>
    </div>
  </form>
</section>
