import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AdminService } from '../../dashboard/admin/service/admin.service';
import { SetDoneShowIntroVideoModel } from '../../models/classes/registration-cycle/set-done-show-intro-video-model';
import { ModalPictureType } from '../../models/enums/modal-picture-type';
import { VideoNumber } from '../../models/enums/registration-cycle-video-Number';
import { SetDoneShowIntroVideoSearchResult } from '../../models/search-result/registration-cycle/set-done-show-intro-video-search-result';
import { UploadImageResult } from '../../models/search-result/upload-image-result';
import { ResponseState } from '../../models/support/response-state';
import { ClientService } from '../../services/client/client.service';
import { SharedService } from '../../services/other/shared.service';
import { RegistrationCycleGeneralService } from '../../services/registration-cycle/registration-cycle-general.service';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-modal-terms-and-conditions',
  templateUrl: './modal-terms-and-conditions.component.html',
  styleUrls: ['./modal-terms-and-conditions.component.scss'],
})
export class ModalmodalTermsConditions implements OnInit {
  //@ViewChild('videoPlayer') videoplayer: ElementRef;

  public dir;

  public CurrentModalPictureType: ModalPictureType;
  public ModalTitle: string;
  public Name: string;
  public NoPicture: string;
  public DeletePicture: string;
  public LoadingSavePicture: string;
  public videoSource: string;
  public SavePicture: string;
  public UploadImageResultList: UploadImageResult[] = [];
  public PicturePath: string;
  public apiLoaded = false;
  public FilesList: string[] = [];
  public Loading: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    public sharedService: SharedService,
    public adminService: AdminService,
    public generalService: RegistrationCycleGeneralService,
    public translate: TranslateService,
    public clientService: ClientService,
    public userService: UserService
  ) {}

  ngOnInit(): void {
    this.dir = localStorage.getItem('dir');

    this.ModalTitle = 'Glamera|TheBestProgramForManagingBeautyCentersAndCenters';
  }

  public get videoNumber(): typeof VideoNumber {
    return VideoNumber;
  }

  deleteItem(item: any) {
    console.log(item.Id);
  }

  ngOnDestroy() {}
}
