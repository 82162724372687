<!-- <div mat-dialog-content> -->
<!-- <iframe
  src="https://www.youtube.com/watch?v=zuLaaHgVXR0"
  style="width: 100%; height: 100%"
  frameborder="0"
  allow="autoplay; fullscreen"
  allowfullscreen
></iframe> -->
<!-- </div> -->

<div class="h-100 overflow-hidden">
  <a class="close-icon" href="javascript:void(0)" (click)="close()">
    <img width="20px" src="assets/icons/close-icon.svg" alt="">
  </a>
  <iframe width="100%" height="100%" src="https://www.youtube.com/embed/zuLaaHgVXR0?si=RAZDGjQo6zvhauoS"
  title="Welcome to Glamera" frameborder="0"
  allow="accelerometer; autoplay;  encrypted-media; gyroscope; picture-in-picture; web-share"
  referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
</div>