import { Component, OnInit, Input, OnDestroy, EventEmitter, VERSION, forwardRef, Output, ViewChild, ElementRef } from '@angular/core';
import { debounceTime, map, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { Observable, Subject, of } from 'rxjs';
import { ControlValueAccessor, UntypedFormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AutoCompleteService } from './sz-auto-complete.service';
import { TranslateService } from '@ngx-translate/core';
import { IOption } from 'ng-select';
import { uniqBy } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { BaseServiceSZ } from '../../../services/other/baseService';


export class MyOption implements IOption {
  value: string;
  label: string;
  item: any;
}

@Component({
  selector: 'sz-autocomplete',
  templateUrl: './sz-autocomplete.component.html',
  styleUrls: ['./sz-autocomplete.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SzAutocompleteComponent),
      multi: true
    }
  ]
})
export class SzAutocompleteComponent implements OnInit, OnDestroy, ControlValueAccessor {
  ModelData: any[];
  tempLookUp: string;
  @ViewChild('szautocomplete') szautocomplete: ElementRef;


  FilteredModelData: any[];
  selectedItems: any[] = [];
  public SelectedId: number;
  @Output() clearAllSelected = new EventEmitter<any>();
  @Output() addNewPosition = new EventEmitter<any>();

  @Output() selectedItem = new EventEmitter<any>();
  @Output() pushSelectedItems = new EventEmitter<any>();
  @Output() unselected = new EventEmitter<any>();
  @Input() placeholderKey: string;


  @Input() lookupKey: string;

  @Input() PageNumber: number;

  @Input() ClearOnSelect: boolean;

  @Input() PageSize: number;



  @Input() displayedColumns: string;
  @Input() filterColumns: string;
  @Input() criteria: any;

  @Input() multivaluearray: any[];// new by elsayed Not Cpmplete


  @Input() multi: boolean;

  @Input() viewClearAllButton: boolean;




  @Input() viewAddPositionButton: boolean;
  @Input() hideAddButton: boolean;

  @Input() editMode: boolean; // New By Elsayed 
  @Input() disabled: boolean;
  @Input() selectFirst: boolean;
  @Input() checkIfData: boolean;
  @Input() isRequired: boolean;
  @Input() excludedIds: number[] = [];

  onChange: Function = (_: any) => { };
  onTouched: Function = (_: any) => { };
  keyup: Function = (_: any) => { };
  get value(): number {
    return this._value;
  }
  selectedOptions: any[];
  //placeholderKey = "retailProduct.Supplier"
  version = VERSION;
  ModelDataMulti: MyOption[];
  //   = [
  //   {value: '0', label: 'Alabama', item:{Id:1,Name:'first'}},
  //   {value: '1', label: 'Wyoming', item:{Id:2,Name:'second'}},
  //   {value: '2', label: 'Coming', item:{Id:3,Name:'third'}},
  //   {value: '3', label: 'Henry Die', item:{Id:4,Name:'fourth'}},
  //   {value: '4', label: 'John Doe', item:{Id:5,Name:'fifth'}}
  // ];
  private _value: number;
  private change = new EventEmitter<any>();
  private _onDestroy = new Subject<void>();
  public modelDataCtrl: UntypedFormControl = new UntypedFormControl();


  constructor(private _autoCompleteService: AutoCompleteService,
    public _baseService: BaseServiceSZ, private toastr: ToastrService,
    public translate: TranslateService) {

  }




  writeValue(value) {

    if (value == null) {
      this._value = null;
      this.selectedItems = [];
      this.selectedOptions = [];
      return this.modelDataCtrl.reset();
    }
    if (value && this.ModelData && this.ModelData.length) {
      let item = this.ModelData.find(x => x.Id == value);
      this.setModelDataCtrlVal(item);
    } else if (value) {
      return this.callToAssignValue(value);
    }
    const valueChanged = value !== this._value;
    if (valueChanged) {
      this._value = value;
      this.onChange(value);
      this.change.emit(value);
    }
  }

  assignFirstOption(data: any[]) {

    if (this.lookupKey == '1001') {

    }
    if (data.length > 0) {

      let item = data[0];
      this.setModelDataCtrlVal(item);
      const valueChanged = data[0].Id !== this._value;
      if (valueChanged) {
        this._value = data[0].Id;
        this.onChange(data[0].Id);
        this.change.emit(data[0].Id);
      }
      this.emitSelected(data[0], true)

      if (data.length == 1) {
        this.ModelData = [];

      }
    }

  }
  emitSelected(item: any, AutoEmitted: boolean) {
    item['AutoEmitted'] = AutoEmitted;

    this.selectedItem.emit(item);

  }
  callToAssignValue(value: number) {

    if (+value > 0) {
      this.criteria = this.criteria || {};
      this.criteria.AutoCompleteSelectionId = value;

      this.showListData(String(value), true);
      delete this.criteria.AutoCompleteSelectionId;
    } else {
      this.writeValue(value);

    }

  }
  registerOnChange(fn: Function) {
    this.onChange = fn;
  }
  registerOnTouched(fn: Function) {
    this.onTouched = fn;
  }
  selected(event) {

    var d = this.selectedOptions;

    this.selectedItem.emit(event.item); // new by elsayed 
    // console.log(event);
    this.selectedItems.push(event.item);
  }

  deselected(event) {

    this.unselected.emit(event.item); // new by elsayed 

    this.selectedItems.forEach((item, i) => {
      if (item.Id == event.item.Id) this.selectedItems.splice(i, 1);
    })
  }


  public onFocus(e: Event): void {

    this.modelDataCtrl.reset();
    this._value = null;
    this.onChange(this._value);
    this.keyup(this._value);
    setTimeout(() => {
      const inputEvent: Event = new Event('input');
      e.target.dispatchEvent(inputEvent);
    }, 100);
  }


  onFilterInputChanged(event) {
    // 

    this.autocomplete(of(event)).subscribe(items => {


      items = [...this.selectedItems, ...items];
      items = uniqBy(items, 'Id');
      this.ModelDataMulti = items.map(item => {
        return { value: item.Id, label: item.NameEn, item: item }
      })
    });
  }

  pushSelected() {

    this.pushSelectedItems.emit(this.selectedItems);
    this.writeValue(null);
  }


  clearAll() {

    this.clearAllSelected.emit(this.selectedItems);
    this.writeValue(null);
  }


  addPosition() {

    this.addNewPosition.emit(this.selectedItems);

  }

  public onFocusOut(event: Event) {
    // var self = this;
    // setTimeout(() => {
    //   let item = {};
    //   if (this.ModelData) {

    //     item = this.ModelData.find(x => x.Id == self._value);
    //   }
    //   item ? self.writeValue(self._value) : self.callToAssignValue(self._value);
    // }, 500);
  }

  autocomplete = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(500),
      switchMap(
        term => {


          return this.showListData(term, false);
        }
      )
    )



  //show list data

  showListData(term: string, assignFirst: boolean) {


    if (this.PageNumber >= 0 && this.PageSize >= 0) {

      this.criteria = {};
      this.criteria.PageSize = this.PageSize;
      this.criteria.PageNumber = this.PageNumber;
      this.criteria.PagingEnabled = true;
    }

    if (this.ModelData != null && this.ModelData.length > 0) {
      let filtered = this.FilterListByCriteria(term);
      if (filtered && filtered.length > 0) {
        return of(filtered);
      }
    }

    //subscribe


    if (assignFirst || this.checkIfData) {

      this._autoCompleteService.getData(this.lookupKey, term, this.criteria, this.editMode   /*New By Elsayed*/).subscribe(res => {
        this.ModelData = this.excludedIds ? res.filter(x => !this.excludedIds.includes(x.Id)) : res;
        if (this.checkIfData && res.length <= 0) {
          this.ShowNoDataMsg();
        }
        let filtered = this.FilterListByCriteria(term);
        this.assignFirstOption(filtered);
      });
    }
    if (this.lookupKey == "2004") {

      return this._autoCompleteService.getData(this.lookupKey, term, this.criteria, this.editMode
        /*New By Elsayed*/)
        .pipe(map(res => this.ModelData = res));
    }
    //async pipe 

    return this._autoCompleteService.getData(this.lookupKey, term, this.criteria, this.editMode
      /*New By Elsayed*/)
      .pipe(map(res =>//this.ModelData = res
      {
        //   ;
        let x = res;


        this.ModelData = this.excludedIds ? res.filter(x => !this.excludedIds.includes(x.Id)) : res;
        let filtered = this.FilterListByCriteria(term);
        return filtered;
      }
      ));

  }

  ShowNoDataMsg() {
    switch (this.lookupKey) {
      case '1007':
        this._baseService.getTranslation('PleaseCreateEmployee').subscribe(c => {
          this.toastr.warning(c);
        });
        break;
      default:

        break;
    }

  }
  FilterListByCriteria(data: string) {
    if (this.lookupKey == '2001' || this.lookupKey == '2000') {
      //   
    }

    this.criteria = this.criteria || {};
    delete this.criteria.AutoCompleteSelectionId;

    this.FilteredModelData = this.ModelData.map(x => x);
    if (this.criteria) {
      Object.keys(this.criteria).forEach(key => {

        if (this.criteria[key]) {
          this.FilteredModelData = this.ModelData.filter(r => r[key] == this.criteria[key]);
        }
      })
    }


    data = String(data);
    let filteredList: any[];
    if (data && this.FilteredModelData) {

      data = data.toLowerCase();
      let filtered = this.FilteredModelData.filter(item => {

        let match = false;
        let props = this.filterColumns && this.filterColumns.length > 0 ? this.filterColumns.split(',') : ['Id', 'Name'];
        for (let prop of props) {
          if (item[prop]) {
            if (item[prop].toString().toLowerCase().indexOf(data) > -1) {
              match = true;
              break;
            }
          }
        };
        return match;
      });

      filteredList = filtered;
    }
    else {
      filteredList = this.FilteredModelData;
    }

    return filteredList;

  }

  getDisplayoedResult(obj: any) {

    let str = ''; // obj.Id;
    if (this.translate.currentLang == 'en') {
      str += obj.Name ? obj.Name : (obj.NameEn ? obj.NameEn : '');

    } else {
      //str += obj.Name ? ' - ' + obj.Name : (obj.NameAr ? ' - ' + obj.NameAr : '');
      str += obj.Name ? obj.Name : (obj.NameAr ? obj.NameAr : '');
    }

    if (this.displayedColumns) {

      this.displayedColumns.split(',').forEach(col => {
        let deep = col.split('.');

        if (deep.length > 1) {
          str += obj[deep[0]][deep[1]] !== undefined && obj[deep[0]][deep[1]] !== null ? (' - ' + obj[deep[0]][deep[1]]) : '';
        }
        else {
          let rrr = obj[col];
          str += obj[col] !== undefined && obj[col] !== null && obj[col] !== '' ? (' - ' + obj[col]) : '';
        }


      });
    }
    return str;

  }


  formatter = (x: { Name: string, NameEn: string, NameAr: string, Description: string, DescriptionAr: string, Id: number }) => {
    if (this.translate.currentLang == 'en') {
      return x.Name || x.NameEn || x.Description || x.Id;

    } else {
      return x.Name || x.NameAr || x.DescriptionAr || x.Id;
    }


  };
  ngOnInit() {



    var dd = this.viewClearAllButton;
    dd = this.viewAddPositionButton;
    dd = this.hideAddButton;
    var ddd = this.selectedOptions;

    this.displayedColumns = this.displayedColumns || '';
    this.filterColumns = this.filterColumns || '';
    if (this.multi) {
      this.selectedOptions = this.multivaluearray;
      this.onFilterInputChanged('');
    }

    this.lookupSetting(this.lookupKey);
    if (this.selectFirst) {

      this.showListData('', true);
    }
    else if (this.checkIfData) {
      //   ;

      this.showListData('', false);
    }
  }
  lookupSetting(lookupKey) {

    switch (lookupKey) {

      case '1001': {
        this.displayedColumns = this.displayedColumns + ',Mobile';
        this.filterColumns = this.filterColumns + ',Id,SearchName,NameAr,NameEn,Mobile';
        break;
      }
      // case '1002': {
      //   this.displayedColumns = this.displayedColumns + ',Id';       
      //   this.filterColumns = this.filterColumns + ',Id,NameAr,NameEn,DateFormated';
      //   break;
      // }
      case '1004': {


        this.filterColumns = this.filterColumns + ',Code,Price,NameAr,NameEn,BarCode';
        this.displayedColumns = this.displayedColumns + ',Code,Price,BarCode';

        break;
      }
      // case '1013': {
      //   this.filterColumns = this.filterColumns + ',Id,NameAr,NameEn';
      //   break;
      // }

      case '1014': {
        this.displayedColumns = this.displayedColumns + ',Currency.Name';
        this.filterColumns = this.filterColumns + ',Id,Currency.Name';
        break;
      }
      // case '1008': {
      //   this.filterColumns = this.filterColumns + ',Id,NameAr,NameEn,Code';
      //   this.displayedColumns = this.displayedColumns + 'Code';
      //   break;
      // }
      case '1026': {
        this.displayedColumns = this.displayedColumns + ',Id,OpenDate';
        this.filterColumns = this.filterColumns + ',Id,OpenDate';
        break;
      }
      case '2000':
      case '2001': {
        // this.displayedColumns = this.displayedColumns + ',Id,NameAr';
        this.filterColumns = this.filterColumns + ',Id,NameAr,NameEn';
        break;
      }
      case '2003': {
        this.displayedColumns = this.displayedColumns + ',SiteName';
        this.filterColumns = this.filterColumns + 'SiteName';
        break;
      } default: {
        this.filterColumns = "Id,NameAr,NameEn";
      }

    }

    if (lookupKey == '1008') {


    }
  }

  ItemSelected($event) {

    if (this.multi) {
      $event.preventDefault();
    } else {
      this.writeValue($event.item.Id);
    }
    this.selectedItem.emit($event.item);

    console.log("this.ClearOnSelect=" + this.ClearOnSelect);

    if (this.ClearOnSelect) {

      console.log("SelectedIdSelectedIdSelectedId" + this.SelectedId)
      this.SelectedId = null;

      this.ModelData = [];
      this.unSelect();
      
      var ddd = this.szautocomplete;
      this.szautocomplete.nativeElement.value = "";
      console.log(" this.szautocomplete.nativeElement.value = ");
    }
  }

  unSelect() {
    console.log("unSelectunSelectunSelectunSelectunSelect");
    this.modelDataCtrl.reset();
    this._value = null;
    this.onChange(this._value)
    this.unselected.emit();
  }
  setModelDataCtrlVal(obj: any) {
    //let   term  =  this.getDisplayoedResult(obj);
    this.modelDataCtrl.setValue(obj);
  };

  resetDataList() {

    this.ModelData = [];
    this.unSelect();

  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

}
