import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap, delay } from 'rxjs/operators';
import { MessageService } from '../message/message.service';
import { formValueDates } from '../../shared/helpers';
import { ClientSearchCriteria } from '../../models/search-criterias/client-search-criteria';
import { GetPictureSearchCriteria } from '../../models/search-criterias/get-picture-search-criteria';
import { SavePictureSearchCriteria } from '../../models/search-criterias/save-picture-search-criteria';
import { ClientSearchResult } from '../../models/search-result/client-search-result';
import { GetPictureSearchResult } from '../../models/search-result/get-picture-search-result';
import { SavePictureSearchResult } from '../../models/search-result/save-picture-search-result';
import { ExecutionResponse } from '../../models/support/execution-response';
import { ValidationCriteria } from '../../models/support/validation-criterias/validation-criteria';
import { SharedService } from '../other/shared.service';
import { FingerprintReportSearchCriteria } from 'src/app/models/search-criterias/fingerprint-report-search-criteria';
import { FingerprintReportSearchResult } from 'src/app/models/search-result/fingerprint-report-search-result';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { Client } from 'src/app/models/classes/client/client';
import { GetClientsSearchResult } from 'src/app/models/search-result/get-clients-search-result';
import { ClientFactorModel } from 'src/app/models/classes/client/client-factor-model';
import { GetClientsLiteSearchResult } from 'src/app/models/search-result/get-clients-lite--search-result';
import { ClientInfoDTO } from 'src/app/models/classes/client/client-info-dto';
import { GetClientInfoSearchCriteria } from 'src/app/models/search-criterias/get-client-info-search-criteria';
import { GetClientBookingsSearchCriteria } from 'src/app/models/search-criterias/get-client-bookings-search-criteria';
import { GetClientSalesSearchCriteria } from 'src/app/models/search-criterias/get-client-sales-search-criteria';
import { ClientSaleDTO } from 'src/app/models/classes/client/client-sale-dto';
import { ClientBookingDTO } from 'src/app/models/classes/client/client-booking-dto';
import { GetClientGroupsSearchCriteria } from 'src/app/models/search-criterias/get-client-groups-search-criteria';
import { EditClientGroupsModel } from 'src/app/models/classes/client/edit-client-groups-model';

@Injectable({
  providedIn: 'root',
})
export class ClientService extends BaseService {
  isAlterEgoTaken(alterEgo: string): Observable<boolean> {
    const isTaken = false; //alterEgo.includes(alterEgo);
    return of(isTaken).pipe(delay(400));
  }
  private clientsUrl; // = super.BaseUrl() + 'Client';  // URL to web api

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private _BaseService: BaseServiceSZ,
    private sharedService: SharedService
  ) {
    super();
    this.clientsUrl = this.sharedService.ApiUrl + 'Client';
  }
  getApiUrl() {
    return this._BaseService.ApiUrl;
  }
  /** GET client by id. Will 404 if id not found */
  getClient(id: number): Observable<ExecutionResponse<Client>> {
    return this.http
      .post<ExecutionResponse<Client>>(
        this.clientsUrl + '/getById',
        id,
        this.sharedService.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('fetched client id=${id}');
          this._BaseService.ValidationResult(res);
          super.bufferAuditColumns(res.Result, res.Result.constructor.name);
        }),

        catchError(
          this._BaseService.handleError<ExecutionResponse<Client>>(
            'getClient id=${id}'
          )
        )
      );
  }

  GetClientInfo(criteria: GetClientInfoSearchCriteria):
    Observable<ExecutionResponse<ClientInfoDTO>> {
    return this.http.get<ExecutionResponse<ClientInfoDTO>>(
      this.clientsUrl + '/GetClientInfo',
      {
        headers: this.sharedService.getHeaders().headers,
        params: this.sharedService.ToHttpParams(criteria),
      }
    )
      .pipe(
        tap((res) => {
          this._BaseService.log('GetClientInfo');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<ExecutionResponse<ClientInfoDTO>>(
            'GetClientInfo'
          )
        )
      );
  }


  GetClientBookings(criteria: GetClientBookingsSearchCriteria):
    Observable<ExecutionResponse<ClientBookingDTO[]>> {
    return this.http.get<ExecutionResponse<ClientBookingDTO[]>>(
      this.clientsUrl + '/GetClientBookings',
      {
        headers: this.sharedService.getHeaders().headers,
        params: this.sharedService.ToHttpParams(criteria),
      }
    )
      .pipe(
        tap((res) => {
          this._BaseService.log('GetClientBookings');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<ExecutionResponse<ClientBookingDTO[]>>(
            'GetClientBookings'
          )
        )
      );
  }

  GetClientSales(criteria: GetClientSalesSearchCriteria):
    Observable<ExecutionResponse<ClientSaleDTO[]>> {
    return this.http.get<ExecutionResponse<ClientSaleDTO[]>>(
      this.clientsUrl + '/GetClientSales',
      {
        headers: this.sharedService.getHeaders().headers,
        params: this.sharedService.ToHttpParams(criteria),
      }
    )
      .pipe(
        tap((res) => {
          this._BaseService.log('GetClientSales');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<ExecutionResponse<ClientSaleDTO[]>>(
            'GetClientSales'
          )
        )
      );
  }



  GetClientGroups(criteria: GetClientGroupsSearchCriteria):
    Observable<ExecutionResponse<number[]>> {
    return this.http.get<ExecutionResponse<number[]>>(
      this.clientsUrl + '/GetClientGroups',
      {
        headers: this.sharedService.getHeaders().headers,
        params: this.sharedService.ToHttpParams(criteria),
      }
    )
      .pipe(
        tap((res) => {
          this._BaseService.log('GetClientGroups');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<ExecutionResponse<number[]>>(
            'GetClientGroups'
          )
        )
      );
  }


  EditClientGroups(model: EditClientGroupsModel): Observable<ExecutionResponse<boolean>> {

    return this.http
      .post<ExecutionResponse<boolean>>(
        this.clientsUrl + '/EditClientGroups',
        model,
        this.sharedService.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('EditClientGroups');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<ExecutionResponse<boolean>>(
            'EditClientGroups'
          )
        )
      );
  }
  //abdo comment
  /* GET clients whose name contains search term */
  searchClients(
    clientSearchCriteria: ClientSearchCriteria
  ): Observable<ClientSearchResult> {
    clientSearchCriteria.AccountSetupId = this.sharedService.AccountSetupId;
    return this.http
      .post<ClientSearchResult>(
        this.clientsUrl + '/Get',
        clientSearchCriteria,
        this.sharedService.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('fetched clients');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<ClientSearchResult>(
            'addClient id=${id}'
          )
        )
      );
  }

  GetNextClientCode(): Observable<ExecutionResponse<number>> {

    return this.http.get<ExecutionResponse<number>>(this.clientsUrl + '/GetNextClientCode', { headers: this.sharedService.getHeaders().headers })
      .pipe(
        tap(code => this._BaseService.log('fetched Next Client Code'))
      );
  }

  GetClients(
    clientSearchCriteria: ClientSearchCriteria
  ): Observable<GetClientsSearchResult> {
    clientSearchCriteria.AccountSetupId = this.sharedService.AccountSetupId;
    return this.http
      .post<GetClientsSearchResult>(
        this.clientsUrl + '/GetForGB',
        clientSearchCriteria,
        this.sharedService.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('fetched clients');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<GetClientsSearchResult>(
            'addClient id=${id}'
          )
        )
      );
  }
  GetClientsLite(
    clientSearchCriteria: ClientSearchCriteria
  ): Observable<GetClientsLiteSearchResult> {
    clientSearchCriteria.AccountSetupId = this.sharedService.AccountSetupId;
    return this.http
      .post<GetClientsLiteSearchResult>(
        this.clientsUrl + '/GetLiteForGB',
        clientSearchCriteria,
        this.sharedService.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('fetched clients');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<GetClientsLiteSearchResult>(
            'addClient id=${id}'
          )
        )
      );
  }
  searchForAllClients(
    clientSearchCriteria: ClientSearchCriteria
  ): Observable<ClientSearchResult> {
    return this.http
      .post<ClientSearchResult>(
        this.clientsUrl + '/Get',
        clientSearchCriteria,
        this.sharedService.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('fetched clients');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<ClientSearchResult>(
            'addClient id=${id}'
          )
        )
      );
  }

  GetClientPicture(
    criteria: GetPictureSearchCriteria
  ): Observable<GetPictureSearchResult> {
    return this.http
      .post<GetPictureSearchResult>(
        this.clientsUrl + '/GetClientPicture',
        criteria,
        this.sharedService.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('Fetched User');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<GetPictureSearchResult>(
            'Fetched Client  id=${id}'
          )
        )
      );
  }

  SaveClientPicture(
    criteria: SavePictureSearchCriteria
  ): Observable<SavePictureSearchResult> {
    return this.http
      .post<SavePictureSearchResult>(
        this.clientsUrl + '/SaveClientPicture',
        criteria,
        this.sharedService.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('Fetched Client');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<SavePictureSearchResult>(
            'Fetched Client  id=${id}'
          )
        )
      );
  }

  /** POST: add a new client to the server */
  addClient(client: Client): Observable<ExecutionResponse<Client>> {
    let AccountSetupId = super.getAccountSetupId();
    if (client.AccountSetupClients == null) {
      client.AccountSetupClients = [];
      client.AccountSetupClients.push({
        AccountSetupId: AccountSetupId,
        Id: 0,
        ClientId: client.Id,
      });
    }
    client = formValueDates(client, true);
    return this.http
      .post<ExecutionResponse<Client>>(
        this.clientsUrl + '/Create',
        client,
        this.sharedService.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('added client w/ id=${client.Id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<ExecutionResponse<Client>>(
            'addClient id=${id}'
          )
        )
      );
  }

  /** DELETE: delete the client from the server */
  deleteClient(client: Client | number): Observable<ExecutionResponse<Client>> {
    return this.http
      .post<ExecutionResponse<Client>>(
        this.clientsUrl + '/delete',
        client,
        this.sharedService.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('deleted client id=${id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<ExecutionResponse<Client>>(
            'deleteClient'
          )
        )
      );
  }

  IsNameUnique(
    validationCriteria: ValidationCriteria
  ): Observable<ExecutionResponse<Boolean>> {
    return this.http
      .post<ExecutionResponse<Boolean>>(
        this.clientsUrl + '/IsNameUnique',
        validationCriteria,
        this.sharedService.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('deleted client id=${id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<ExecutionResponse<Boolean>>(
            'deleteClient'
          )
        )
      );
  }

  IsMobileNumberUnique(
    validationCriteria: ValidationCriteria
  ): Observable<ExecutionResponse<Boolean>> {
    return this.http
      .post<ExecutionResponse<Boolean>>(
        this.clientsUrl + '/IsMobileNumberUnique',
        validationCriteria,
        this.sharedService.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('deleted client id=${id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<ExecutionResponse<Boolean>>(
            'deleteClient'
          )
        )
      );
  }

  IsPhoneNumberUnique(
    validationCriteria: ValidationCriteria
  ): Observable<ExecutionResponse<Boolean>> {
    return this.http
      .post<ExecutionResponse<Boolean>>(
        this.clientsUrl + '/IsPhoneNumberUnique',
        validationCriteria,
        this.sharedService.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('deleted client id=${id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<ExecutionResponse<Boolean>>(
            'deleteClient'
          )
        )
      );
  }

  IsWorkNumberUnique(
    validationCriteria: ValidationCriteria
  ): Observable<ExecutionResponse<Boolean>> {
    return this.http
      .post<ExecutionResponse<Boolean>>(
        this.clientsUrl + '/IsWorkNumberUnique',
        validationCriteria,
        this.sharedService.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('deleted client id=${id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<ExecutionResponse<Boolean>>(
            'deleteClient'
          )
        )
      );
  }

  /** PUT: update the client on the server */
  updateClient(client: Client): Observable<ExecutionResponse<Client>> {
    let AccountSetupId = super.getAccountSetupId();
    if (client.AccountSetupClients == null) {
      client.AccountSetupClients = [];
      client.AccountSetupClients.push({
        AccountSetupId: AccountSetupId,
        Id: 0,
        ClientId: client.Id,
      });
    }
    client = formValueDates(client, true);
    super.setBufferAuditColumns(client, client.constructor.name);
    return this.http
      .post<ExecutionResponse<Client>>(
        this.clientsUrl + '/Update',
        client,
        this.sharedService.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('added client w/ id=${client.Id}');
          this._BaseService.ValidationResult(res);
        }),
        catchError(
          this._BaseService.handleError<ExecutionResponse<Client>>(
            'addClient id=${id}'
          )
        )
      );
  }

  /** PUT: update the clientFactor on the server */
  UpdateClientFactor(
    clientFactorModel: ClientFactorModel
  ): Observable<ExecutionResponse<ClientFactorModel>> {
    // clientFactorModel = formValueDates(clientFactorModel, true);
    // super.setBufferAuditColumns(clientFactorModel, clientFactorModel.constructor.name);
    return this.http
      .post<ExecutionResponse<ClientFactorModel>>(
        this.clientsUrl + '/UpdateClientFactor',
        clientFactorModel,
        this.sharedService.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('Updated client w/ id=${client.Id}');
          this._BaseService.ValidationResult(res);
        }),
        catchError(
          this._BaseService.handleError<ExecutionResponse<ClientFactorModel>>(
            'updateClient id=${id}'
          )
        )
      );
  }

  ClientFingerprintReport(
    reportSearchCriteria: FingerprintReportSearchCriteria
  ): Observable<FingerprintReportSearchResult> {
    reportSearchCriteria.AccountSetupId = this.sharedService.AccountSetupId;
    return this.http
      .post<FingerprintReportSearchResult>(
        this.sharedService.ApiUrl +
        'FingerprintTransaction/ClientFingerprintReport',
        reportSearchCriteria,
        this.sharedService.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('fetched clients fingerprint');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<FingerprintReportSearchResult>(
            'addClient id=${id}'
          )
        )
      );
  }

  public ExportFingerprints(criteria: FingerprintReportSearchCriteria) {
    let url = `${this.sharedService.ApiUrl}FingerprintTransaction/ExportClientsFingerprints?AccountSetupId=${this.sharedService.AccountSetupId}&To=${criteria.To}`;
    if (criteria.ClientId != null) {
      url += `&ClientId=${criteria.ClientId}`;
    }
    if (criteria.From != null) {
      url += `&From=${criteria.From}`;
    }

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.sharedService.Token,
          'Content-Type': 'application/octet-stream',
        }),
        responseType: 'blob',
      })
      .pipe(
        tap(
          (data) => console.log('You received data'),
          (error) => console.log(error)
        )
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */

  /** Log a ClientService message with the MessageService */
}
