import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { ExecutionResponse } from '../../models/support/execution-response';
import { Company } from '../../models/classes/company/company';

@Injectable({
  providedIn: 'root',
})
export class GetCurrentCompanyService extends BaseService {
  companyUrl: string;
  constructor(private http: HttpClient, private _BaseService: BaseServiceSZ) {
    super();
  }

  getCompanyById(
    ApiUrl: string,
    CompanyId?: number
  ): Observable<ExecutionResponse<Company>> {
    this.companyUrl = ApiUrl + 'Company';
    var companyId = CompanyId || super.getCompanyId();
    return this.http
      .post<ExecutionResponse<Company>>(
        this.companyUrl + '/GetById',
        companyId,
        super.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.ValidationResult(res);
          //this._UIStateService._AppGlobals.AccountData = res.Result ;
        }),
        catchError(
          this._BaseService.handleError<ExecutionResponse<Company>>(
            'getCompany id=${id}'
          )
        )
      );
  }

  getCompanyByIdLite(
    ApiUrl: string,
    headers: { headers: HttpHeaders },
    CompanyId?: number
  ): Observable<ExecutionResponse<Company>> {
    this.companyUrl = ApiUrl + 'Company';
    var companyId = CompanyId || super.getCompanyId();
    return this.http
      .post<ExecutionResponse<Company>>(
        this.companyUrl + '/GetByIdLite',
        companyId,
        headers
      )
      .pipe(
        tap((res) => {
          this._BaseService.ValidationResult(res);
          //this._UIStateService._AppGlobals.AccountData = res.Result ;
        }),
        catchError(
          this._BaseService.handleError<ExecutionResponse<Company>>(
            'getCompany id=${id}'
          )
        )
      );
  }
}
