<section class="reset-password" fxLayout="column" fxLayoutAlign="center center">
  <form
    [formGroup]="form"
    fxLayout="column"
    fxLayoutAlign="start stretch"
    (submit)="onSubmit()"
  >
    <lib-language style="visibility: hidden"></lib-language>

    <main>
      <img
        src="assets/img/back.jpg"
        alt=""
        title=""
        class="ptr"
        (click)="sharedService.back()"
      />
    </main>

    <p>{{ "UPDATE_YOUR_PASSWORD" | translate }}</p>
    <span class="span">
      {{ "DIFFERENT_PASSWORD" | translate }}<br />
      {{ "PREVIOUS_PASSWORD" | translate }}
    </span>

    <mat-label class="label mt-40">
      {{ "AUTH.PASSWORD" | translate }}</mat-label
    >
    <mat-form-field appearance="outline">
      <input
        matInput
        [type]="hide ? 'password' : 'text'"
        formControlName="newPassword"
      />
      <button
        mat-icon-button
        matSuffix
        (click)="hide = !hide"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hide"
        type="button"
      >
        <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
      </button>
    </mat-form-field>

    <div fxLayout="row" fxLayoutAlign="space-between center">
      <mat-checkbox class="example-margin">
        <h6>
          {{ "SIGN_OUT" | translate }} <br />
          {{ "FROM_OTHER_DEVICE" | translate }}
        </h6>
      </mat-checkbox>
      <button
        mat-raised-button
        color="primary"
        type="submit
  
      "
        class="submit-btn"
        [class.spinner]="loading"
      >
        {{ "CONFIRM" | translate }}
      </button>
    </div>
  </form>
</section>
