<div class="modal-header">
  <h5 class="modal-title text-capitalize" id="exampleModalLabel">
    <i class="fas fa-dolly-flatbed fa-beat"></i> &nbsp; {{ modalTitle | translate }}
  </h5>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">

    <div class="col-lg-6">
      {{"ConsumablesServiceGlobalName:" | translate}}
    </div>

    <div class="col-lg-6">
      {{ConsumablesServiceGlobalName}}
    </div>


  </div>
  <div class="row">

    <div class="col-lg-6">
      {{"ConsumablesTransactionCode:" | translate}}
    </div>
    <div class="col-lg-6">
      {{ConsumablesTransactionCode}}
    </div>




  </div>

</div>
<div class="modal-footer">



  <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">
    {{'Close' | translate}}
  </button>

</div>
