import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { SharedService } from 'src/app/services/other/shared.service';

@Component({
  selector: 'app-done-successfully',
  templateUrl: './done-successfully.component.html',
  styleUrls: ['./done-successfully.component.scss'],
})
export class DoneSuccessfullyComponent implements OnInit, OnDestroy {
  public constructor(
    private router: Router,
    private translateService: TranslateService,
    private sharedService: SharedService,
    private activeRoute: ActivatedRoute,
    private title: Title
  ) {
    translateService.onLangChange.subscribe((_) => {
      title.setTitle(translateService.instant('Title_registeration_success'));
    });
    title.setTitle(translateService.instant('Title_registeration_success'));
  }

  timeOut: any;

  ngOnInit(): void {
    this.timeOut = setTimeout(() => {
      this.router.navigate(['', 'details'], { skipLocationChange: true });
    }, 4000);

    // this.HandleTitleTranslation();
  }

  HandleTitleTranslation() {
    if (
      this.translateService.store.translations[
        this.translateService.currentLang
      ] ||
      this.translateService.store.translations[
        this.translateService.defaultLang
      ]
    ) {
      this.HandlePageTitle();
    } else {
      this.suscripcionLangChange = this.translateService.onLangChange.subscribe(
        (event: LangChangeEvent) => {
          this.HandlePageTitle();
        }
      );

      this.sharedService.langObsevable.subscribe((res) => {
        this.HandlePageTitle();
      });
    }
  }
  suscripcionLangChange;
  async HandlePageTitle() {
    await this.translateService.get('ُElsayedLeilla').toPromise();
    this.title.setTitle(
      this.translateService.instant(this.activeRoute.snapshot.data['title'])
    );
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeOut);
  }
}
