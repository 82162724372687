import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-delete',
  templateUrl: './modal-delete.component.html',
  styleUrls: ['./modal-delete.component.scss']
})
export class ModalDeleteComponent implements OnInit {

  @Input() item;
  @Output() sendIdForDelete = new EventEmitter<string>();

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }
  deleteItem(item: any) {
    console.log(item.Id)
    this.sendIdForDelete.emit(item);
  }
}
