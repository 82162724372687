<div class="modal-header">
    <h4 class="modal-title"> {{ "AccountsTree" | translate }}  </h4>

    <!-- <button type="button"  (click)="sendMessage()" class="btn btn-primary">Send Message</button> -->

    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <form [formGroup]="GForm">
          <div class="cardTabs">
            <div class="tabsContent">
              <div class="formCard">
                <div class="row">
                  <div class="col-12">
                    <!-- <h5>{{ "AccountsTree" | translate }}</h5> -->
                  </div>
                  <!-- <div class="form-group col-md-6">
                  <label>{{ 'CostCenterNo' | translate }}</label>
                  <input type="text" class="form-control" placeholder="{{ 'CostCenterNo' | translate }}"
                    formControlName="CostCenterNo" autocomplete="off" required maxlength="50">
                </div> -->
                  <div class="col-md-6">
                    <label>{{ "CostCenterNo" | translate }}</label>
                    <div class="input-group">
                      <input type="text" class="form-control" placeholder="{{ 'CostCenterNo' | translate }}"
                        formControlName="CostCenterNo" autocomplete="off" required maxlength="50" />
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">{{
                          GForm.get("ParentNumber").value
                          }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-5" *ngIf="GForm.get('IsChild').value == 1">
                    <label>{{ "Branch" | translate }}</label>
                    <ng-select [items]="branches" bindValue="Id" (change)="changeBranch($event)"
                      [bindLabel]="sharedService.Lang == 'ar' ? 'Name' : 'Name'" placeholder="{{ 'Branch' | translate }}"
                      formControlName="AccountSetupId" name="AccountSetupId" class="form-group">
                    </ng-select>
  
                  </div>
                  <div class="form-group col-md-6">
                    <!--  -->
                    <label>{{ "NameAr" | translate }}</label>
                    <input type="text" class="form-control" placeholder="{{ 'NameAr' | translate }}"
                      formControlName="NameAr" required name="NameEn" maxlength="50" />
                  </div>
                  <div class="form-group col-md-6">
                    <label>{{ "NameEn" | translate }}</label>
                    <input type="text" class="form-control" placeholder="{{ 'NameEn' | translate }}"
                      formControlName="NameEn" required name="NameEn" maxlength="50" />
                  </div>
                  <div class="form-group col-md-6">
                    <label>{{ "ArabicAccountDescription" | translate }}</label>
                    <textarea class="form-control" formControlName="DescriptionAr" rows="3"
                      placeholder="{{ 'ArabicAccountDescription' | translate }}">
                  </textarea>
                  </div>
                  <div class="form-group col-md-6">
                    <label>{{ "EnglishAccountDescription" | translate }}</label>
                    <textarea class="form-control" formControlName="DescriptionEn" rows="3"
                      placeholder="{{ 'EnglishAccountDescription' | translate }}">
                  </textarea>
                  </div>
            
              
          
  
               
                </div>
                <div class="row">
              
                  <div class="form-group col-md-2">
                    <label>{{ "IsChild" | translate }} </label>
                    <div class="switch col-3 text-end">
                      <label>
                        <input type="checkbox" [checked]="IsChild.value == 1 ? true : false" class="form-check-input"
                           formControlName="IsChild" />
                        <span class="lever"></span>
                      </label>
                    </div>
                  </div>
  
                  <div class="form-group col-md-3">
                    <label>{{ "IsActive" | translate }} </label>
                    <div class="switch col-3 text-end">
                      <label>
                        <input type="checkbox" [checked]="IsActive.value == 1 ? true : false" class="form-check-input"
                          (change)="ChangeIsActive($event)" formControlName="IsActive" />
                        <span class="lever"></span>
                      </label>
                    </div>
                  </div>
              
                </div>
              </div>
            </div>
          </div>
          <div class="formCard mt-3">
            <div class="row">
              <div class="col-md-9"></div>
              <div class="col-md-3">
                <button class="btn btn-success btn-block" (click)="CreateData()" [disabled]="!GForm.valid || IsSubmit">
                  {{ "Submit" | translate }}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-modal-action" (click)="activeModal.close('Close click')">{{'Close' | translate}}</button>
  </div>