import { Directive, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
@Directive({
  selector: '[appComponentBase]',
})
export abstract class ComponentBase implements OnDestroy {
  constructor() {}
  checkResponse!: boolean;
  isSzSupport!: boolean;
  destroy$ = new Subject<void>();
  descriptionTooltipPosition: string = 'top';
  public loading: boolean = false;
  public loading$ = new BehaviorSubject<boolean>(false);

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }
}
