import { PHASE_TWO, PHASE_THREE, SERVICE_FORM, PHASE_FIVE } from './phases.model';

export interface BUSINESS_DETAILS_INPUT {
  BusinessLogoFile?: string;
  RegistrationCyclePhaseThreeModelStirng?: REGITRATIONCYCLEPHASTHREEMODELSTRING;
}

export interface REGITRATIONCYCLEPHASTHREEMODELSTRING {
  RegistrationCycleLogId?: string;
  BusinessPhoneNumber?: string;
  TaxRate?: string;
  HaveTax?: boolean;
  PriceIncludeTax?: boolean;
  SavePhasesDataType?: number;
}

export interface BUSINESS_DETAILS_RESPONSE {}

export interface REGISTRATION_PHASES_INPUT {
  RegistrationCycleLogId?: number;
}

export interface REGISTRATION_PHASES_RESPONSE {
  RegistrationCycleLogId: number;
  One: {
    DoneBySkip: boolean;
    Edited: boolean;
    OneData: {
      FirstName: string;
      LastName: string;
      Email: string;
      MobileNumber: string;
      CountryId: number;
      Password: string;
      AgreeToPrivacyPolicy: boolean;
      IsPhoneValidated: boolean;
    };
  };
  Two: {
    DoneBySkip: boolean;
    Edited: boolean;
    TwoData: {
      BusinessName: string;
      BusinessTypes: PHASETWO_BUSINESS_TYPE[];
      CountryId: number;
      CityId: number;
      KnowUsWayId: number;
      IsCurrentlyUsingAnyOtherSystem: boolean;
    };
  };
  Three: {
    DoneBySkip: boolean;
    Edited: boolean;
    ThreeData: {
      BusinessLogoName: string;
      BusinessLogoFolderName: string;
      BusinessLogoPath: string;
      BusinessPhoneNumber: string;
      HaveTax: boolean;
      TaxRate: number;
      PriceIncludeTax: boolean;
      CountryId?: number;
      CallingCode?: string;
    };
  };

  Four?: PHASE_TWO;
  Five?: PHASE_THREE;
  Six?: PHASE_FIVE;
  LastPhaseNumber: number;
  DoneRegistering: boolean;
  LastDataVersion: number;
}

export enum SavePhasesDataType {
  Add = 1,
  Edit = 2,
}

export interface PHASETWO_BUSINESS_TYPE {
  Id?: number;
  GlobalName?: string;
}

export interface GET_SERVICES_INPUT {
  BusinessTypeId?: number;
  PagingEnabled?: boolean;
}

export interface SERVICE {
  GlobalName?: string;
  ServiceId?: number;
  ServicePrice?: number;
  checked?: boolean;
}

export interface BUSINESS_SERVICE_INPUT {
  RegistrationCycleLogId: string;
  RegistrationServiceCategories: SERVICE_FORM[];
  SavePhasesDataType: number;
}

export interface POSITION {
  PositionId?: number;
  NameAr?: string;
  NameEn?: string;
  GlobalName?: string;
}

export interface BUSINESS_EMPLOYEE_INPUT {
  RegistrationCycleLogId?: number;
  PositionId?: number;
  NumberOfBranches?: number;
  EmployeesNames?: string[];
  SavePhasesDataType?: number;
}

export interface WORKING_HOUR_INPUT {
  WorkingHours?: WORKING_HOUR[];
  RegistrationCycleLogId?: number;
  SavePhasesDataType?: number;
}

export interface WORKING_DAY {
  WeekDay?: number;
  NameEn?: string;
  NameAr?: string;
  GlobalName?: string;
  StartTime?: string;
  FinishTime?: string;
  checked?: boolean;
}

export interface WORKING_HOUR {
  WeekDay: number;
  StartTime: string;
  FinishTime: string;
}

export interface SKIP_INPUT {}

export interface SKIP_RESPONSE {}
