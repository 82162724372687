import {
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { BookingService } from '../../service/booking.service';
import { PaginationComponent } from '../../../../shared/pagination/pagination.component';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ResponseState } from '../../../../models/support/response-state';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FileSaverService } from 'ngx-filesaver';
import { AccountSetup } from '../../../../models/classes/account-setup/account-setup';
import { LOYALTY_POINT_SYSTEM } from '../../../../models/const/loyality-const';
import { CategoryType } from '../../../../models/enums/category-type';
import { AccountSetupSearchCriteria } from '../../../../models/search-criterias/account-setup-search-criteria';
import { AccountSetupService } from '../../../../services/account/account-setup.service';
import { BaseServiceSZ } from '../../../../services/other/baseService';
import { SharedService } from '../../../../services/other/shared.service';
import { HttpClient } from '@angular/common/http';
import FileSaver from 'file-saver';
import { ChooseAccountsComponent } from 'src/app/shared/choose-accounts/choose-accounts.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import saveAs from 'file-saver';
// import saveAs from 'file-saver';

@Component({
  selector: 'app-service-categories',
  templateUrl: './service-categories.component.html',
  styleUrls: ['./service-categories.component.scss'],
})
export class ServiceCategoriesComponent implements OnInit {
  @ViewChild('pager', { static: true }) pager: PaginationComponent;

  public LoyaltyPointSystem: any;

  public CategoryBranchesIdList: number[] = [];
  public CompanyAccountSetups: AccountSetup[] = [];
  catTypes: number[];
  @Input() asModal: boolean;
  @Input() modeType: number;
  @Input() editAccounts: any[] = [];

  @Output() changeAccount = new EventEmitter();
  @Output() removeAccount = new EventEmitter();

  constructor(
    private bookingService: BookingService,
    public sharedService: SharedService,
    private readonly modalService: NgbModal,
    private fb: UntypedFormBuilder,
    private accountSetUpService: AccountSetupService
  ) {
    this.LoyaltyPointSystem = LOYALTY_POINT_SYSTEM;
  }

  ngOnInit(): void {
    this.pager.pageSize = 10;
    this.pager.ddlPageSize = 10;
    this.pager.GlobalPageIndex = 0;
    this.catTypes = this.sharedService.AllowUseGym ? [2, 3] : [2];
    this.GetData();
    this.GetAccountSetUps();
    // this.comapnyCategories();
    this.GetClassificationDropdown();
    this.GFormDate();
  }

  get IsGymMembershipCategory() {
    return this.GForm.get('IsGymMembershipCategory');
  }

  GFormDate() {
    this.GForm = this.fb.group({
      Id: [0],
      IsGymMembershipCategory: false,
      TagId: [],
      Name: [],
      NameEn: [''],
      NameAr: [''],
      OnlineDescriptionEn: [''],
      OnlineDescription: [''],
      OnlineDetails: [''],
      Status: 1,
      CatType: CategoryType.Service,
      ClassId: [null],
      ComapnyCategoriesId: 0,
      PointsRedemption: 0,
      LoyaltyPoints: [],
      LoyaltyMoney: [],
      LoyaltyPointSystem: [],
      AccountSetupId: this.sharedService.AccountSetupId,
      CategoryBranchesIdList: [],
      IsFastCategory: false,
      ShowOnline: false,
      Order: 0
    });
  }
  GForm: UntypedFormGroup;
  get Vform() {
    return this.GForm.controls;
  }

  Categories;
  totalCount;
  GetData() {
    let category = {
      AccountSetupId: this.sharedService.AccountSetupId,
      CatTypes: this.catTypes,
      FreeText: '',
      Status: '',

      IsForTableView: true,

      PagingEnabled: true,
      PageNumber: this.pager.GlobalPageIndex,
      PageSize: this.pager.pageSize,
    };
    category.FreeText = this.freeText;
    category.Status = this.status;
    this.bookingService.GetCategory(category).subscribe((response: any) => {
      console.log('edit accounts is => ', this.editAccounts);
      if (response.State == ResponseState.Success) {
        this.Categories = response.Categories;
        if (this.editAccounts.length) {
          this.Categories.forEach((element, index) => {
            this.editAccounts.forEach((itemAcc) => {
              if ((itemAcc.categoryId || itemAcc.Id) === element.Id) {
                this.Categories[index] = {
                  ...this.Categories[index],
                  accountSelected: {
                    ...itemAcc,
                    Name: this.modeType
                      ? itemAcc?.accountSelected?.DebitAccountName ||
                      itemAcc?.accountSelected?.Name
                      : itemAcc?.accountSelected?.CreditAccountName ||
                      itemAcc?.accountSelected?.Name,
                  },
                };
              }
            });
          });
        }

        this.pager.EventsCount = response.TotalCount;
        this.totalCount = response.TotalCount;
        this.pager.setPage(this.pager.GlobalPageIndex, false);
      }
    });
  }
  freeText;
  searchByText() {
    this.GetData();
  }

  ReciveFreeText(e) {
    this.freeText = e;
    this.GetData();
  }
  autoSearchByText(e) {
    this.freeText = e.target.value;
    this.GetData();
  }
  status;
  filterByStatus(e) {
    this.status = Number(e.target.value);
    this.GetData();
  }

  filterByCatType(e) {
    this.catTypes =
      e.target.value == 'null' ? [2, 3] : [Number(e.target.value)];
    this.GetData();
  }

  ComapnyCategoriess;
  comapnyCategories() {
    let companyservice = {
      AccountSetupId: this.sharedService.AccountSetupId,
      CompanyId: this.sharedService.CompanyId,
      CatType: 2,
      ForAutoComplete: 1,
      FreeText: '',
    };
    this.bookingService
      .GetComapnyCategories(companyservice)
      .subscribe((response: any) => {
        if (response.State == ResponseState.Success) {
          this.ComapnyCategoriess = response['ComapnyCategoriess'];
        }
      });
  }

  Classifications;
  GetClassificationDropdown() {
    let dropdowns = {
      MasterCodeId: '11',
    };
    this.bookingService.GetClassificationDropdown(dropdowns).subscribe((response: any) => {
      if (response.State == ResponseState.Success) {

        this.Classifications = response.Result;
      }
    });
  }

  CreateData() {
    if (this.GForm.value.Id == 0) {
      this.Create();
    } else {
      this.Update();
    }
  }
  file: File;
  imageSrc: any;

  onFileChange(event: any) {
    if (event.target.files?.length > 0) {
      this.file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        this.imageSrc = reader.result;
      }
      reader.readAsDataURL(this.file)
      //console.log('File changed:', file1);
    }
  }

  deleteIcon() {
    this.file = null;
    this.imageSrc = null;
    this.ImagePath = null;
  }
  isSubmitting: boolean = false;
  Create() {
    console.log(this.GForm.value);
    let model = this.GForm.value;
    model.CatType = this.IsGymMembershipCategory.value
      ? CategoryType.GymMembership
      : CategoryType.Service;
    this.isSubmitting = true;
    this.bookingService.CreateCategory(model).subscribe((response: any) => {
      this.isSubmitting = false;
      if (response.State == ResponseState.Success) {
        this.sharedService.ToastrSuccess(response.Message);
        this.sharedService.HideModal();
        this.GForm.reset();
        this.GetData();
      } else {
        this.sharedService.ToastrError(response.Message);
      }
    });
  }
  Update() {
    this.isSubmitting = true;
    this.GForm.get('Name').setValue(this.GForm.get('NameAr').value);
    let model = this.GForm.value;
    model.CatType = this.IsGymMembershipCategory.value
      ? CategoryType.GymMembership
      : CategoryType.Service;
    this.bookingService.UpdateCategory(model, this.file).subscribe((response: any) => {
      this.isSubmitting = false;
      if (response.State == ResponseState.Success) {
        this.sharedService.ToastrSuccess(response.Message);
        this.sharedService.HideModal();
        this.GetData();
      } else {
        this.sharedService.ToastrError(response.Message);
      }
    });
  }

  Delete(category) {
    this.bookingService
      .DeleteCategory(category.Id)
      .subscribe((response: any) => {
        if (response.State == ResponseState.Success) {
          this.sharedService.ToastrSuccess(response.Message);
          this.sharedService.HideModal();
          this.GetData();
        } else {
          this.sharedService.ToastrError(response.Message);
        }
      });
  }
  editState: boolean = false;
  itemToEdit;
  OpenModal(event, category) {
    this.editState = true;
    category.IsGymMembershipCategory =
      category.CatType == CategoryType.GymMembership;
    this.itemToEdit = category;
    this.ImagePath = category.ImagePath;
    this.GForm.patchValue(category);
  }
  ImagePath: string;
  ModelAdd() {
    this.ImagePath = null;
    this.imageSrc = null;
    this.GForm.reset();
    this.GFormDate();
  }

  ModalEdit(event, category) {
    category.IsGymMembershipCategory =
      category.CatType == CategoryType.GymMembership;
    this.ImagePath = null;
    this.imageSrc = null;
    this.CategoryBranchesIdList = [];

    let CategoryBranchesIdListTemp: number[] = [];

    if (category.CategoryBranches && category.CategoryBranches.length > 0) {
      CategoryBranchesIdListTemp = category.CategoryBranches.map(
        (s) => s.BranchId
      );
    }

    this.editState = true;
    this.itemToEdit = category;

    this.ImagePath = category.ImagePath;
    this.GForm.patchValue(category);

    this.CategoryBranchesIdList = CategoryBranchesIdListTemp;
  }

  GetAccountSetUps() {

    var accountSetupSearchCriteria = new AccountSetupSearchCriteria();
    accountSetupSearchCriteria.AccountSetupId = this.sharedService.AccountSetupId;
    accountSetupSearchCriteria.PagingEnabled = true;
    accountSetupSearchCriteria.PageNumber = 0;
    accountSetupSearchCriteria.PageSize = 1000;

    this.accountSetUpService
      .getForAutoCompleteNewDoNotDepenOnTableUserAccountSetup(
        accountSetupSearchCriteria
      )
      .subscribe((result) => {
        this.CompanyAccountSetups = result.AccountSetupBasic;
      });
  }

  exportServicesCategories() {
    this.bookingService.ExportServiceCategories().subscribe((res: any) => {
      const blob = new Blob([res], { type: 'application/octet-stream' });
      FileSaver.saveAs(blob, 'excel-file.xlsx');
    });
  }

  getAccountInfo2(category: any) {
    const modalRef = this.modalService.open(ChooseAccountsComponent);
    modalRef.componentInstance.isViewPlus = true;
    modalRef.componentInstance.emitService.subscribe((emmitedValue) => {
      category.accountSelected = emmitedValue;
      this.changeAccount.emit(category);
    });
  }

  onRemoveAccount(category: any): void {
    this.removeAccount.emit(category);
    delete category.accountSelected;
  }
}
