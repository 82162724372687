
import { RequestPermissionNotificationStatus } from "../../enums/request-permission-notification-status";
import { SearchCriteria } from "../../search-criterias/search-criteria";
import { RequestPermissionNotification } from "./request-permission-notification";

export class OutputAcceptOrRejectRequestPermissionModel extends SearchCriteria{

    public RequestPermissionNotification: RequestPermissionNotification;
    public RequestPermissionNotificationStatus: RequestPermissionNotificationStatus;
    public SupervisorNotes:string;

}
