<div class="modal-header">
  <h5 class="modal-title text-capitalize" id="exampleModalLabel" data-backdrop="static">

    <i class="fas " [ngClass]="{'fa-handshake':VideoNumberInput == videoNumber.One,
      'fa-book': VideoNumberInput == videoNumber.Two,'fa-home':VideoNumberInput == videoNumber.Three }"></i>
    {{ModalTitle | translate }}
  </h5>
  <button type="button" class="close" aria-label="Close"
    (click)="activeModal.dismiss('Cross click');HandelSetDoneShowIntroVideo(VideoNumberInput);">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <youtube-player [playerVars]="playerConfig" [videoId]="VideoId" [height]="400" [width]="">
  </youtube-player>

</div>
<!-- <div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">
    {{'Close' | translate}}</button>
</div> -->
