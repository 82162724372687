import { RowState } from '../../enums/row-state';

export class AccountSetupFeature {
  constructor() {
    this.Id = 0;
    this.AccountSetupId = 0;
  }
  public Id: number;
  public AccountSetupId: number;
  public IconUrl: string;
  public IconType: number;
  public TooltipAr: string;
  public FeatureSort: number;
  public TooltipEn: string;
  public Description: string;
  public RowState: RowState;
}
