export enum StockTransTypes {
  OpenBalance = 1,
  DirectAddition = 2,
  TransfareToStock = 3,
  Dispensing = 4,
  ReturnBack = 5,
  ImportFromStock = 6,

  DispensingProductComponents = 7,

  ProduceCompositeProduct = 8,
  DispensingServicesConsumables = 9,
  DamagedItems = 10,
  TransferCustodyToEmployee = 11,

  StockSettlement = 12,

  TransferToClient = 13,
  SettlementCustodyEmployee = 14,
  DirectAdditionReturn = 15,
  SettlementServiceConsumables = 16,
  PurchaseReturn = 18,
  PurchaseOrder = 19,
  AddServiceConsumables = 20,
  SubtractServiceConsumables = 21

}
