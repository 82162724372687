import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AccountCurrency } from '../../models/classes/account-setup/account-currency';
import { AccountSetup } from '../../models/classes/account-setup/account-setup';
import { urlExistInNavigationModel } from '../../shared/helpers';

@Injectable({
    providedIn: 'root'
})

export class UIStateService {
    behaviorSubjectObj: BehaviorSubject<any>;
    _AppGlobals: AppGlobals;
    _UIState: UIState;
    constructor() {
        this._AppGlobals = {};
        this._UIState = { 'ChangedKey': '' };
        this._UIState['KeyLogs'] = {};
        this.behaviorSubjectObj = new BehaviorSubject<UIState>(this._UIState);
    }

    //-------------------
    setUIState(stateKey: string, stateval: any) {
        
        this._UIState[stateKey] = stateval;
        this._UIState['ChangedKey'] = stateKey;
        this._UIState[stateKey + 'Log'] = this._UIState[stateKey + 'Log'] || [];
        this._UIState[stateKey + 'Log'].push(stateval);
        this.tellUIObservers();

    }
    getUIState(stateKey: string) {
       return this._UIState[stateKey] ;
    }
    urlExistInNavigationModel(url:string){
        let _NavigationModel= this.getUIState(UISTATE_KEYS.NavigationModel );
        var  res    = urlExistInNavigationModel(_NavigationModel , url );
        return res ;
    
      }

    tellUIObservers() {
        this.behaviorSubjectObj.next(this._UIState);
    };
    //---------------------------
    getUIState$() {
        
        return this.behaviorSubjectObj.asObservable();
    }
    getAccountSetup(): AccountSetup {
        return this._AppGlobals['AccountData'];
    }

    getCurrentCountry(): string {
    
       let x= this._AppGlobals['Country'];
       return x;
    }

    getSelectedTab(): number {
    
        let x= this._AppGlobals['Clickedtab'];
        return x;
     }

     getCreatCatState(): any {
    
        let x= this._AppGlobals['CreatCatState'];
        return x;
     }

    getDefaultAccountCurrency(): AccountCurrency {
        return this._AppGlobals['DefaultCurrency'];
    }
}

export class UIState {
    constructor() {

    }
    public ChangedKey: string;
    
}

export const UISTATE_KEYS = {
    SIGN_IN_COMP: "SIGN_IN_COMP",
    MENU_COMP: "MENU_COMP",
    WEBSITE_COMP: "WEBSITE_COMP",
    ACCOUNT_DATA_BUFFERD: "ACCOUNT_DATA_BUFFERD",
    ROUTE_CHANGED: "ROUTE_CHANGED",
    ROUTE_BASE_CHANGED: "ROUTE_BASE_CHANGED",
    NavigationModel: "NavigationModel",
    COUNTRY_SET :"COUNTRY_SET",
    Clicked_Icon :"Clicked_Icon",
    CreatCat_State:"CreatCat_State"

}

export const UISTATE_KEYS_VAL = {
    WebSiteHome: 'WebSiteHome',
    CreateNewBooking:"CreateNewBooking",
    DownPayment:'DownPayment',
    Cancellations:'Cancellations',
    EditDownPayment:"EditDownPayment",
    CashSales:"CashSales",
    NewDownPayment:"NewDownPayment",
    AllClientDownPayments :"AllClientDownPayments",
    PettyCashs:"PettyCashs",
    NewCashierTransfer:"NewCashierTransfer",
    TheCashierTransfer:"TheCashierTransfer",
    EditCashierTransfer:"EditCashierTransfer",
    NewCashSale:"NewCashSale",
    SearchBookings: "SearchBookings",
    Booking: "Booking",
    CreateLeaveRequest:"CreateLeaveRequest",
    EditLeaveRequest:"EditLeaveRequest",
    New: "New",
    NewPettyCash : "NewPettyCash",
    CreateDepartment: "CreateDepartment",
    CreatePosition: "CreatePosition",
    Edit: "Edit",
    SearchLeaveRequests:"SearchLeaveRequests",
    TotalEmployeesSalaries:"TotalEmployeesSalaries",
    PayRolls:"PayRolls",
    AddNewPayRollForEmployee:"AddNewPayRollForEmployee",
    EditDepartment: "EditDepartment",
    EditPosition: "EditPosition",
    Sales: "Sales",
    CashSale: "CashSale",
    EditCashSale: "EditCashSale",
    EditPettyCash : "EditPettyCash",
    SaleRefund: "SaleRefund",
    Calender: "Calender",
    Search: "Search",
    Transactions: "Transactions",
    Cashier: "Cashier",
    PettyCash: "PettyCash.Transactions",
    Category: "Category",
    ProductCategories: "ProductCategories",
    ServiceCategories: "ServiceCategories",
    Clients: "Clients",
    Site: "Site",
    SetUp: "SetUp",
    AttendMethodMaster: "NewAttendMethodMaster",
    Employees: "Employees",
    Roster: "roster",
    ResourcesAndRooms: "ResourcesAndRooms",
    Promotions: "Promotions",
    Offers: "Offers",
    Orders: "Orders",
    Print: "Print",
    PaymentTypes: "PaymentTypes",
    Signin: "Signin",
    ServiceTask: "ServiceTask",
    Services: "Services",
    Stock: "Stock",
    SearchDepartments: "SearchDepartments",
    SearchPositions: "SearchPositions",
    Departments: "Departments",
    Positions: "Positions",
    RetailProducts: "RetailProducts",
    Suppliers: "Suppliers",
    ClockIn: "ClockIn",
    Admin: "Admin",
    Units: "Units",
    Users: "Users",
    WaitingItem: "WaitingItem",
    Register: "Register",
    Clicked:"Clicked",
    CreatCat:"CreatCat",
    TEST:"TEST",
    LeaveRequests : "LeaveRequests",
    AddNewSalaryBasicData: "AddNewSalaryBasicData",
    EditNewSalaryBasicData: "EditNewSalaryBasicData",
    SalaryBasicData:"SalaryBasicData",
    SearchSalaryBasicData:"SearchSalaryBasicData",
}
export class AppGlobals {
    constructor() {

    }
    public AccountData?: AccountSetup
    public Country?: string
    public Clickedtab?: number
    public CreatCatState?: any
    public DefaultCurrency?: AccountCurrency
}
