import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../../services/other/shared.service';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import * as _ from 'underscore';
import { BookingOrTransactionItemType } from 'src/app/models/enums/booking-or-transaction-item-type';

@Component({
  selector: 'app-print-items',
  templateUrl: './print-items.component.html',
  styleUrls: ['./print-items.component.scss']
})
export class PrintItemsComponent implements OnInit {

  public item;

  @Input('item')
  set in(item) {

    this.item = item;

    if (item?.TrProductServices && item?.TrProductServices?.length > 0) {

      this.handleTransItems();

    }

  }

  @Input() public readonly AccountSetup;
  @Input() public dirc;
  public BillInfoUrl: string;
  public BillInfoForPackageUrl: string;
  public printLang: string = 'ar';

  constructor(
    public sharedService: SharedService,
    public translate: TranslateService,

  ) {

  }

  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.LOW;

  ngOnInit(): void {



    this.BillInfoUrl = location.origin + "/transaction/bill-info/billItemId/";
    this.BillInfoForPackageUrl = location.origin + "/transaction/bill-info/billPackageItemId/";

    if (this.AccountSetup?.SelectedPrintEmployeeLanguage === 'en') {
      // this.translate.use('en');
      // console.log(this.AccountSetup?.SelectedPrintEmployeeLanguage)
      // this.translate.use('en');
      this.dirc = 'ltr';
      this.sharedService.TempLangKey = "en";
      this.sharedService.SetTranslationPipeData();
    }
    else if (this.AccountSetup?.SelectedPrintEmployeeLanguage === 'ar') {
      // this.translate.use('ar');
      this.dirc = 'rtl';
      this.sharedService.TempLangKey = "ar";
      this.sharedService.SetTranslationPipeData();

    }
    else if (this.AccountSetup?.SelectedPrintEmployeeLanguage === 'all') {
      console.log('all')

    }

    this.printLang = this.sharedService.Lang;

    if (this.AccountSetup?.SelectedPrintEmployeeLanguage && this.AccountSetup?.SelectedPrintEmployeeLanguage != 'all') {
      this.printLang = this.AccountSetup?.SelectedPrintEmployeeLanguage;
    }

  }
  hasNonNullEmployeeTransactionCommission(item: any): boolean {
    return item.TrProductServices.some(service => service.EmployeeTransactionCommission
      && service.EmployeeTransactionCommission.CommissionValue > 0);
  }

  handleTransItems() {


    this.item.TransactionItems = [];

    this.item.TrProductServices.forEach((trProductService, index) => {

      if (trProductService.ServiceId > 0) {
        trProductService.ItemId = trProductService.ServiceId;
      } else if (trProductService.PackageId > 0) {
        trProductService.ItemId = trProductService.PackageId;
      } else if (trProductService.GiftCardId > 0) {
        trProductService.ItemId = trProductService.GiftCardId;
      } else if (trProductService.ProductId > 0) {
        trProductService.ItemId = trProductService.ProductId;
      }

      this.item.TransactionItems.push(trProductService);

      if (trProductService.TransactionsPackageServices &&
        trProductService.TransactionsPackageServices.length > 0) {

        trProductService.TransactionsPackageServices.forEach((transactionPackageServices, index) => {

          if (transactionPackageServices.ServiceId > 0) {

            transactionPackageServices.ItemType = BookingOrTransactionItemType.Service;
            transactionPackageServices.ItemId = transactionPackageServices.ServiceId;
            transactionPackageServices.Quantity = 1;
            transactionPackageServices.OriginalPrice = transactionPackageServices.ServicePriceInPackage ?? 0;
            transactionPackageServices.Total = transactionPackageServices.ServicePriceInPackage;
            transactionPackageServices.Note = "PackageService";
            transactionPackageServices.ItemGlobalName = this.sharedService.Lang == 'ar' ?
              transactionPackageServices.Service.NameAr : transactionPackageServices.Service.NameEn;
          }

          this.item.TransactionItems.push(transactionPackageServices);

        });
      }
    });

  }

  getItemName(transItem: any): string {

    var getTransactionItem = this.item.TransactionItems.
      find(ti => ti.ItemType == transItem.ItemType && ti.ItemId == transItem.ItemId);

    if (getTransactionItem) {

      var itemName = getTransactionItem.ItemGlobalName ? getTransactionItem.ItemGlobalName :
        this.translate.instant('NotFound');

      return itemName;
    }

    return this.translate.instant('NotFound');

  }
  getCategoryNameName(transItem: any): string {

    var getTransactionItem = this.item.TransactionItems.
      find(ti => ti.ItemType == transItem.ItemType && ti.ItemId == transItem.ItemId);

    if (getTransactionItem && (getTransactionItem?.service?.Category ||
      getTransactionItem?.product?.Category)) {

      var categoryName = getTransactionItem?.service?.Category?.GlobalName ??
        getTransactionItem?.product?.Category?.GlobalName;

      return categoryName;
    }

    return null;

  }

}
