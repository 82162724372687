export enum PeriodStatus {
  NotDefined = -1,
  NotStarted = 1,
  OnGoing = 2,
  AlmostFinished = 3,
  Finished = 4,
  Canceled = 5,
  FinishedAfterActivitiesTimesComplete = 6,
  FinishedAfterAttendanceTimesComplete = 7
}
