
import { Injectable } from '@angular/core';
import { CompanyImagesServiceEditedName } from './company-image-edited-name.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { ComapnyCategories } from '../../models/classes/company/comapny-categories';
import { ComapnyCategoriesearchCriteria } from '../../models/search-criterias/companycategories-search-criteria';
import { CompanyCategoriesSearchResult } from '../../models/search-result/company-categories-search-result';
import { ExecutionResponse } from '../../models/support/execution-response';
import { ValidationCriteria } from '../../models/support/validation-criterias/validation-criteria';


@Injectable({
  providedIn: 'root'
})
export class CompanyCategoriesService extends BaseService {
  private comapnyCategoriesUrl //= super.BaseUrl() + 'companyService';  // URL to web api

  constructor(
    private _BaseService: BaseServiceSZ,
    private http: HttpClient, private companyImagesService: CompanyImagesServiceEditedName
  ) {
    super();
    this.comapnyCategoriesUrl = this._BaseService.ApiUrl + 'comapnyCategories';
  }


  /* GET comapnyCategories whose name contains search term */
  searchComapnyCategories(comapnyCategoriesearchCriteria: ComapnyCategoriesearchCriteria): Observable<CompanyCategoriesSearchResult> {

    comapnyCategoriesearchCriteria.AccountSetupId = super.getAccountSetupId();
    comapnyCategoriesearchCriteria.CompanyId = this.companyImagesService.getCompanyId();
    comapnyCategoriesearchCriteria.CatType = 2;
    return this.http.post<CompanyCategoriesSearchResult>(this.comapnyCategoriesUrl + '/Get', comapnyCategoriesearchCriteria, super.getHeaders())
      .pipe(
        tap(comapnyCategories => {
          this._BaseService.log('fetched comapnyCategories');
          this._BaseService.ValidationResult(comapnyCategories);
        }), catchError(this._BaseService.handleError<CompanyCategoriesSearchResult>('searchcomapnyCategories id=${id}')));
  }



  /** GET ComapnyCategories by id. Will 404 if id not found */
  getCompanyCategory(id: String): Observable<ExecutionResponse<ComapnyCategories>> {
    //  getCategory(id: String): ExecutionResponse<Category> {
    //const url = '${this.categoriesUrl}/${id}';
    return this.http.post<ExecutionResponse<ComapnyCategories>>(this.comapnyCategoriesUrl + '/getById', id, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched Comapny Categorys id=${id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<ComapnyCategories>>('get company Category id=${id}'))
      );
  }


  addCompanyCategory(companyCategory: ComapnyCategories): Observable<ExecutionResponse<ComapnyCategories>> {
    //category.AccountSetupId = super.getAccountSetupId();//
    companyCategory.CompanyId = this.companyImagesService.getCompanyId();

    return this.http.post<ExecutionResponse<ComapnyCategories>>(this.comapnyCategoriesUrl + '/Create', companyCategory, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('added Comapny Category w/ id=${company category.Id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<ComapnyCategories>>('add Company Category id=${id}'))
      );
  }




  /** DELETE: delete the Comapny Category from the server */
  deleteCompanyCategory(companyCategory: ComapnyCategories | number): Observable<ExecutionResponse<ComapnyCategories>> {
    return this.http.post<ExecutionResponse<ComapnyCategories>>(this.comapnyCategoriesUrl + '/delete', companyCategory, super.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deleted company category id=${id}');
        this._BaseService.ValidationResult(res);
      }),

      catchError(this._BaseService.handleError<ExecutionResponse<ComapnyCategories>>('delete Company Category'))
    );
  }

  /** PUT: update the company category on the server */
  updateCompanyCategory(category: ComapnyCategories): Observable<ExecutionResponse<ComapnyCategories>> {
    // category.AccountSetupId = super.getAccountSetupId();
    return this.http.post<ExecutionResponse<ComapnyCategories>>(this.comapnyCategoriesUrl + '/Update', category, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('added company category w/ id=${company category.Id}');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<ExecutionResponse<ComapnyCategories>>('add company Category id=${id}'))
      );
  }
  IsNameUnique(validationCriteria: ValidationCriteria): Observable<ExecutionResponse<Boolean>> {
    validationCriteria.AccountSetupId = super.getAccountSetupId();
    validationCriteria.CompanyId = this.companyImagesService.getCompanyId();
    return this.http.post<ExecutionResponse<Boolean>>(this.comapnyCategoriesUrl + '/IsNameUnique', validationCriteria, super.getHeaders()).pipe(
      tap(_ => this._BaseService.log('deleted Category id=${id}')),
      catchError(this._BaseService.handleError<ExecutionResponse<Boolean>>('deleteCategory'))
    );
  }

}
