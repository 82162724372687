import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { SharedService } from '../other/shared.service';
import { GiftCardInformationSearchCriteria } from 'src/app/models/search-criterias/gift-card-information-search-criteria';
import { GiftCardInformationSearchResult } from 'src/app/models/search-result/gift-card-information-search-result';
import { GetGiftCardUsingDetailsSearchCriteria } from 'src/app/models/search-criterias/getGiftCardUsingDetailsSearchCriteria';
import { GetGiftCardUsingDetailsSearchResult } from 'src/app/models/search-result/getGiftCardUsingDetailsSearchResult';
import { GetGiftCardBranchesSearchCriteria } from 'src/app/models/search-criterias/getGiftCardBranchesSearchCriteria';
import { GetGiftCardBranchesSearchResult } from 'src/app/models/search-result/getGiftCardBranchesSearchResult';
import { CheckGiftCardCodeSearchCriteria } from 'src/app/models/search-criterias/checkGiftCardCodeSearchCriteria';
import { CheckGiftCardCodeSearchResult } from 'src/app/models/search-result/checkGiftCardCodeSearchResult';
import { SendOTPCodeToClientSearchCriteria } from '../../models/search-criterias/send-OTP-code-to-client-search-criteria';
import { SendOTPCodeToClientSearchResult } from '../../models/search-result/send-OTP-code-to-client-search-result';
import { GetGiftCardOTPCodesSearchCriteria } from '../../models/search-criterias/getGiftCardOTPCodesSearchCriteria';
import { GetGiftCardOTPCodesSearchResult } from '../../models/search-result/getGiftCardOTPCodesSearchResult';

@Injectable({
  providedIn: 'root'
})
export class GiftCardInformationService extends BaseService {
  private diftCardInformationUrl

  constructor(
    private http: HttpClient,
    private _BaseService: BaseServiceSZ,
    private sharedService: SharedService
  ) {
    super();
    this.diftCardInformationUrl = this.sharedService.ApiUrl + 'GiftCardInformation';

  }


  searchGiftCardInformations(giftCardInformationSearchCriteria: GiftCardInformationSearchCriteria):
    Observable<GiftCardInformationSearchResult> {
    giftCardInformationSearchCriteria.AccountSetupId = this.sharedService.AccountSetupId;
    return this.http.post<GiftCardInformationSearchResult>(this.diftCardInformationUrl +
      '/Get', giftCardInformationSearchCriteria, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('GiftCardInformationSearch');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<GiftCardInformationSearchResult>('GiftCardInformationSearch id=${id}'))
      );
  }

  searchGiftCardInformationsLite(giftCardInformationSearchCriteria: GiftCardInformationSearchCriteria):
    Observable<GiftCardInformationSearchResult> {
    giftCardInformationSearchCriteria.AccountSetupId = this.sharedService.AccountSetupId;
    return this.http.post<GiftCardInformationSearchResult>(this.diftCardInformationUrl +
      '/GetLite', giftCardInformationSearchCriteria, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('GiftCardInformationSearch');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<GiftCardInformationSearchResult>('GiftCardInformationSearch id=${id}'))
      );
  }

  ExportGiftCardInformations(criteria: GiftCardInformationSearchCriteria):
  Observable<any> {
  return this.http.get(
    this.diftCardInformationUrl + '/GetExportGiftCardInformations',
    {
      headers: this.sharedService.getHeaders('ExportExcel').headers,
      params: this.sharedService.ToHttpParams(criteria),
      responseType: 'blob'
    }
  )
    .pipe(
      tap((res) => {
        this._BaseService.log('ExportGiftCardInformations');
        //this._BaseService.ValidationResult(res);
      })
    );
}


  GetGiftCardUsingDetails(getGiftCardUsingDetailsSearchCriteria: GetGiftCardUsingDetailsSearchCriteria):
    Observable<GetGiftCardUsingDetailsSearchResult> {
    getGiftCardUsingDetailsSearchCriteria.AccountSetupId = this.sharedService.AccountSetupId;
    return this.http.post<GetGiftCardUsingDetailsSearchResult>(this.diftCardInformationUrl +
      '/GetGiftCardUsingDetails', getGiftCardUsingDetailsSearchCriteria, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('GiftCardUsingDetails');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<GetGiftCardUsingDetailsSearchResult>('GiftCardUsingDetails id=${id}'))
      );
  }


  GetGiftCardOTPCodes(getGetGiftCardOTPCodesSearchCriteria: GetGiftCardOTPCodesSearchCriteria):
  Observable<GetGiftCardOTPCodesSearchResult> {
    getGetGiftCardOTPCodesSearchCriteria.AccountSetupId = this.sharedService.AccountSetupId;
  return this.http.post<GetGiftCardOTPCodesSearchResult>(this.diftCardInformationUrl +
    '/GetGiftCardOTPCodes', getGetGiftCardOTPCodesSearchCriteria, this.sharedService.getHeaders())
    .pipe(
      tap(res => {
        this._BaseService.log('GiftCardUsingDetails');
        this._BaseService.ValidationResult(res);
      }),
      catchError(this._BaseService.handleError<GetGiftCardOTPCodesSearchResult>('GiftCardUsingDetails id=${id}'))
    );
}


  GetGiftCardBranches(getGiftCardBranchesSearchCriteria: GetGiftCardBranchesSearchCriteria):
    Observable<GetGiftCardBranchesSearchResult> {
    getGiftCardBranchesSearchCriteria.AccountSetupId = this.sharedService.AccountSetupId;
    return this.http.post<GetGiftCardBranchesSearchResult>(this.diftCardInformationUrl +
      '/GetGiftCardBranches', getGiftCardBranchesSearchCriteria, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('GiftCardBranches');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<GetGiftCardBranchesSearchResult>('GiftCardBranches id=${id}'))
      );
  }


  CheckGiftCardCode(checkGiftCardCodeSearchCriteria: CheckGiftCardCodeSearchCriteria):
    Observable<CheckGiftCardCodeSearchResult> {
    checkGiftCardCodeSearchCriteria.AccountSetupId = this.sharedService.AccountSetupId;
    return this.http.post<CheckGiftCardCodeSearchResult>(this.diftCardInformationUrl +
      '/CheckGiftCardCode', checkGiftCardCodeSearchCriteria, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('CheckGiftCardCode');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<CheckGiftCardCodeSearchResult>('CheckGiftCardCode id=${id}'))
      );
  }


  SendOTPCodeToClient(sendOTPCodeToClientSearchCriteria: SendOTPCodeToClientSearchCriteria):
    Observable<SendOTPCodeToClientSearchResult> {
    sendOTPCodeToClientSearchCriteria.AccountSetupId = this.sharedService.AccountSetupId;
    return this.http.post<SendOTPCodeToClientSearchResult>(this.diftCardInformationUrl +
      '/SendOTPCodeToClient', sendOTPCodeToClientSearchCriteria, this.sharedService.getHeaders())
      .pipe(
        tap(res => {


          this._BaseService.log('Send OTP Code To Client');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<SendOTPCodeToClientSearchResult>('Send OTP Code To Client id=${id}'))
      );
  }




}

