import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { formValueDates } from '../../shared/helpers';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { CashierBalance } from '../../models/classes/cashier/cashier-balance';
import { CloseShiftModel } from '../../models/classes/shifts/close-shift-model';
import { OpenBalanceBindingModel } from '../../models/classes/transactions/open-balance-binding-model';
import { CashierBalanceSearchCriteria } from '../../models/search-criterias/cashier-balance-search-criteria';
import { SafeBalanceSearchCriteria } from '../../models/search-criterias/safe-balance-search-criteria';
import { CashierBalanceSearchResult } from '../../models/search-result/cashier-balance-search-result';
import { SafeBalanceSearchResult } from '../../models/search-result/safe-balance-search-result';
import { ExecutionResponse } from '../../models/support/execution-response';
import { SharedService } from '../other/shared.service';
import { GetSafeTransactionsCriteria } from 'src/app/models/search-criterias/get-safe-transactions-criteria';
import { GetSafeTransactionsSearchResult } from 'src/app/models/search-result/get-safe-transactions-search-result';


@Injectable({
  providedIn: 'root'
})
export class CashierBalanceService extends BaseService {
  private cashierBalanceUrl //= super.BaseUrl() + 'CashierBalance';  // URL to web api

  constructor(
    private http: HttpClient,
    private  _BaseService:BaseServiceSZ,
    private sharedService: SharedService,
  ) {
    super();
    this.cashierBalanceUrl =    this.sharedService.ApiUrl + 'CashierBalance';

  }



  /** GETcashierBalance by id. Will 404 if id not found */
  getCashierBalance(id: String): Observable<ExecutionResponse<CashierBalance>> {
    //  getCashierBalance(id: String): ExecutionResponse<CashierBalance> {
    //const url = '${this.cashierBalanceUrl}/${id}';
    return this.http.post<ExecutionResponse<CashierBalance>>(this.cashierBalanceUrl + '/getById', id, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetchedcashierBalance id=${id}');
          this._BaseService.ValidationResult(res);
      }),

        catchError(this._BaseService.handleError<ExecutionResponse<CashierBalance>>('getCashierBalance id=${id}'))
      );
  }


  addCashierBalance(cashierBalance: CashierBalance): Observable<ExecutionResponse<CashierBalance>> {
   cashierBalance= formValueDates(cashierBalance,true);
   cashierBalance.AccountSetupId = super.getAccountSetupId();
    return this.http.post<ExecutionResponse<CashierBalance>>(this.cashierBalanceUrl + '/Create',cashierBalance, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('addedcashierBalance w/ id=${cashierBalance.Id}');
          this._BaseService.ValidationResult(res);
      }),

        catchError(this._BaseService.handleError<ExecutionResponse<CashierBalance>>('addCashierBalance id=${id}'))
      );
  }

  openBalance(openBalanceBindingModel:OpenBalanceBindingModel): Observable<ExecutionResponse<CashierBalance>> {

     return this.http.post<ExecutionResponse<CashierBalance>>(this.cashierBalanceUrl + '/OpenBalance',openBalanceBindingModel, super.getHeaders())
       .pipe(
         tap(res => {
           this._BaseService.log('addedcashierBalance w/ id=${cashierBalance.Id}');
           this._BaseService.ValidationResult(res);
       }),

         catchError(this._BaseService.handleError<ExecutionResponse<CashierBalance>>('addCashierBalance id=${id}'))
       );
   }

   closeShift(closeShiftModel:CloseShiftModel): Observable<ExecutionResponse<any>> {

    return this.http.post<ExecutionResponse<any>>(this.cashierBalanceUrl + '/CloseShift',closeShiftModel, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('addedcashierBalance w/ id=${cashierBalance.Id}');
          this._BaseService.ValidationResult(res);
      }),

        catchError(this._BaseService.handleError<ExecutionResponse<any>>('addCashierBalance id=${id}'))
      );
  }

  getCloseShiftRequests(): Observable<ExecutionResponse<any>> {
    let AccountSetupId = super.getAccountSetupId();
    return this.http.post<ExecutionResponse<any>>(this.cashierBalanceUrl + '/GetCloseShiftRequests',AccountSetupId, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('addedcashierBalance w/ id=${cashierBalance.Id}');
          this._BaseService.ValidationResult(res);
      }),

        catchError(this._BaseService.handleError<ExecutionResponse<any>>('addCashierBalance id=${id}'))
      );
  }





  getSafeCurrentBalances(safeBalanceSearchCriteria: SafeBalanceSearchCriteria): Observable<SafeBalanceSearchResult> {
    safeBalanceSearchCriteria.AccountSetupId = this.sharedService.AccountSetupId;
    console.log(this.cashierBalanceUrl + '/GetSafeCurrentBalances');

    return this.http.post<SafeBalanceSearchResult>(this.cashierBalanceUrl + '/GetSafeCurrentBalances', safeBalanceSearchCriteria, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched Stocks');
          this._BaseService.ValidationResult(res);
      }),


        //catchError(this._BaseService.handleError('getEmployees', []))
      );

  }
  getSafeTransactions(getSafeTransactionsCriteria: GetSafeTransactionsCriteria): Observable<GetSafeTransactionsSearchResult> {
    getSafeTransactionsCriteria.AccountSetupId = this.sharedService.AccountSetupId;

    return this.http.post<GetSafeTransactionsSearchResult>(this.cashierBalanceUrl + '/GetSafeTransactions', getSafeTransactionsCriteria, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('Get Safe Transactions');
          this._BaseService.ValidationResult(res);
      }),
      );

  }

  /* GETcashierBalance whose name contains search term */
  searchCashierBalances(cashierBalanceSearchCriteria: CashierBalanceSearchCriteria): Observable<CashierBalanceSearchResult> {
   cashierBalanceSearchCriteria.AccountSetupId = super.getAccountSetupId();
    return this.http.post<CashierBalanceSearchResult>(this.cashierBalanceUrl + '/Get',cashierBalanceSearchCriteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetchedcashierBalance');
          this._BaseService.ValidationResult(res);
      }),
        catchError(this._BaseService.handleError<CashierBalanceSearchResult>('searchCashierBalances id=${id}'))
      );
  }

  /** DELETE: delete thecashierBalance from the server */
  deleteCashierBalance(cashierBalance: CashierBalance | number): Observable<ExecutionResponse<CashierBalance>> {
    return this.http.post<ExecutionResponse<CashierBalance>>(this.cashierBalanceUrl + '/delete',cashierBalance, super.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deletedcashierBalance id=${id}');
        this._BaseService.ValidationResult(res);
    }),

      catchError(this._BaseService.handleError<ExecutionResponse<CashierBalance>>('deleteCashierBalance'))
    );
  }

  /** PUT: update thecashierBalance on the server */
  updateCashierBalance(cashierBalance: CashierBalance): Observable<ExecutionResponse<CashierBalance>> {
   cashierBalance.AccountSetupId = super.getAccountSetupId();
   cashierBalance= formValueDates(cashierBalance,true);
    return this.http.post<ExecutionResponse<CashierBalance>>(this.cashierBalanceUrl + '/Update',cashierBalance, super.getHeaders())
      .pipe(
        tap(res=> {
          this._BaseService.log('addedcashierBalance w/ id=${cashierBalance.Id}');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<ExecutionResponse<CashierBalance>>('addCashierBalance id=${id}'))
      );
  }

}

