
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { MessageService } from '../message/message.service';
import { CashierTransHeaderData, CashierTransHeaderCriteria } from '../../models/classes/cashier/cashier-daily';
import { ClientPointsBalance } from '../../models/classes/client/client-points-balance';
import { GlameraPayment } from '../../models/classes/payment/glamera-payment';
import { CashierTransfereRequestModel } from '../../models/classes/shifts/close-day-model';
import { PaymentSum } from '../../models/classes/shifts/close-shift-dto';
import { TransactionBindingModel } from '../../models/classes/transactions/transaction-binding.model';
import { TransactionWithStock } from '../../models/classes/transactions/transaction-with-stock';
import { Transactions } from '../../models/classes/transactions/transactions';
import { ClientPointsBalanceSearchCriteria } from '../../models/search-criterias/client-points-balance';
import { TransactionSearchCriteria } from '../../models/search-criterias/transaction-search-criteria';
import { TransactionsProductAndServiceSearchCriteria, NotPayedItemsSearchCriteria } from '../../models/search-criterias/transactions-product-and-service-searchCriteria';
import { TransactionSearchResult } from '../../models/search-result/transaction-search-result';
import { TransactionsProductAndServiceSearchResult, ClientChargesSearchResult } from '../../models/search-result/transactions-product-and-service-search-result';
import { ExecutionResponse } from '../../models/support/execution-response';
import { CompanyImagesServiceEditedName } from '../company/company-image-edited-name.service';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { GetNewClientNumberInQueueModel } from '../../models/search-criterias/get-new-client-number-in-queue-model';
import { GetNewClientNumberInQueueResult } from '../../models/search-result/get-new-client-number-in-queue-result';

@Injectable({
  providedIn: 'root'
})
export class Transaction1Service extends BaseService {
  private transactionsUrl;  //= super.BaseUrl() + 'Transactions';  // URL to web api
  private clientPointsBalanceUrl;   //= super.BaseUrl() + 'Transactions';  // URL to web api
  private daysAndShiftsTransactionsUrl;

  constructor(
    private http: HttpClient, private companyImagesService: CompanyImagesServiceEditedName, private messageService: MessageService, private _BaseService: BaseServiceSZ) {
    super();
    this.transactionsUrl = this._BaseService.ApiUrl + 'Transactions';
    this.daysAndShiftsTransactionsUrl = this._BaseService.ApiUrl + 'DaysAndShiftsTransactions';
    this.clientPointsBalanceUrl = this._BaseService.ApiUrl + 'ClientPointsBalance';


  }
  /** GET transaction by id. Will 404 if id not found */
  getTransaction(id: String): Observable<ExecutionResponse<Transactions>> {

    return this.http.post<ExecutionResponse<Transactions>>(this.transactionsUrl + '/GetTransactionWithPaidProductAndService', id, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched transaction id=${id}');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<ExecutionResponse<Transactions>>
          ('getTransaction id=${id}'))
      );

  }

  getTransactionForPrint(id: String): Observable<ExecutionResponse<Transactions>> {
    return this.http.post<ExecutionResponse<Transactions>>(this.transactionsUrl + '/GetTransactionForPrint', id, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched transaction id=${id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<Transactions>>('getTransaction id=${id}'))
      );
  }

  getShiftDetails(criteria: TransactionSearchCriteria): Observable<ExecutionResponse<PaymentSum[]>> {

    return this.http.post<ExecutionResponse<PaymentSum[]>>(this.daysAndShiftsTransactionsUrl + '/GetShiftDetails', criteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched transaction id=${id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<PaymentSum[]>>('getTransaction id=${id}'))
      );
  }

  ConfirmRecieveFromCashier(transactionId: number): Observable<ExecutionResponse<boolean>> {

    return this.http.post<ExecutionResponse<boolean>>(this.transactionsUrl + '/ConfirmRecieveFromCashier', transactionId, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched transaction id=${id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<boolean>>('getTransaction id=${id}'))
      );
  }



  GetTransactionHeader(): Observable<ExecutionResponse<CashierTransHeaderData>> {
    let criteria = new CashierTransHeaderCriteria();
    criteria.EmployeeId = super.getEmployeeId();
    criteria.AccountSetupId = super.getAccountSetupId();
    return this.http.post<ExecutionResponse<CashierTransHeaderData>>(this.transactionsUrl + '/GetTransactionHeader', criteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched transaction id=${id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<CashierTransHeaderData>>('getTransaction id=${id}'))
      );
  }

  addTransaction(transactionBindingModel: TransactionBindingModel): Observable<ExecutionResponse<Transactions>> {
    transactionBindingModel.Transaction.AccountSetupId = super.getAccountSetupId();
    transactionBindingModel.Transaction.CompanyId = this.companyImagesService.getCompanyId();

    return this.http.post<ExecutionResponse<Transactions>>(this.transactionsUrl +
      '/Create', transactionBindingModel, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('added transaction w/ id=${transaction.Id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<Transactions>>('addTransaction id=${id}'))
      );

  }


  //current worked
  editTransaction(transactionBindingModel: TransactionBindingModel): Observable<ExecutionResponse<Transactions>> {

    transactionBindingModel.Transaction.AccountSetupId = super.getAccountSetupId();
    transactionBindingModel.Transaction.CompanyId = this.companyImagesService.getCompanyId();
    return this.http.post<ExecutionResponse<Transactions>>(this.transactionsUrl + '/Update', transactionBindingModel, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('edited transaction w/ id=${transaction.Id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<Transactions>>('addTransaction id=${id}'))
      );
  }

  CreatePettyCash(transaction: Transactions): Observable<ExecutionResponse<Transactions>> {
    transaction.AccountSetupId = super.getAccountSetupId();
    return this.http.post<ExecutionResponse<Transactions>>(this.transactionsUrl + '/CreatePettyCash', transaction, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('added transaction w/ id=${transaction.Id}');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<ExecutionResponse<Transactions>>('addTransaction id=${id}'))
      );
  }

  GetNewClientNumberInQueue(getNewClientNumberInQueueModel: GetNewClientNumberInQueueModel): Observable<GetNewClientNumberInQueueResult> {

    return this.http.post<GetNewClientNumberInQueueResult>(this.transactionsUrl + '/GetNewClientNumberInQueue', getNewClientNumberInQueueModel, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('Get New Client Number In Queue');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<GetNewClientNumberInQueueResult>('Get New Client Number In Queue'))
      );
  }
  /* GET transactions whose name contains search term */
  searchTransactions(transactionSearchCriteria: TransactionSearchCriteria): Observable<TransactionSearchResult> {
    transactionSearchCriteria.AccountSetupId = super.getAccountSetupId();
    return this.http.post<TransactionSearchResult>(this.transactionsUrl + '/Get', transactionSearchCriteria, super.getHeaders())
      .pipe(
        tap(transactions => this._BaseService.log('fetched transactions'))
      );
  }

  GetProductAndServices(transactionsProductAndServiceSearchCriteria: TransactionsProductAndServiceSearchCriteria): Observable<TransactionsProductAndServiceSearchResult> {
    transactionsProductAndServiceSearchCriteria.AccountSetupId = super.getAccountSetupId();

    return this.http.post<TransactionsProductAndServiceSearchResult>(this.transactionsUrl + '/GetProductAndServices', transactionsProductAndServiceSearchCriteria, super.getHeaders())
      .pipe(
        tap(transactions => this._BaseService.log('fetched transactions'))
      );
  }
  GetBookingNotPayedCharges(_NotPayedItemsSearchCriteria: NotPayedItemsSearchCriteria): Observable<ClientChargesSearchResult> {
    _NotPayedItemsSearchCriteria.AccountSetupId = super.getAccountSetupId();
    return this.http.post<ClientChargesSearchResult>(this.transactionsUrl + '/GetBookingNotPayedCharges', _NotPayedItemsSearchCriteria, super.getHeaders())
      .pipe(
        tap(transactions => this._BaseService.log('fetched transactions'))
      );
  }

  GetClientPoints(ClientId: number): Observable<ExecutionResponse<ClientPointsBalance>> {
    let _ClientPointsBalanceSearchCriteria = new ClientPointsBalanceSearchCriteria();
    _ClientPointsBalanceSearchCriteria.ClientId = ClientId;
    _ClientPointsBalanceSearchCriteria.AccountSetupId = super.getAccountSetupId();

    return this.http.post<ExecutionResponse<ClientPointsBalance>>(this.clientPointsBalanceUrl + '/GetClientPoints', _ClientPointsBalanceSearchCriteria, super.getHeaders())
      .pipe(
        tap(transactions => this._BaseService.log('fetched transactions'))
      );
  }

  GetCashierTransfereRequests(cashierTransfereRequestModel: CashierTransfereRequestModel): Observable<ExecutionResponse<Transactions[]>> {
    return this.http.post<ExecutionResponse<Transactions[]>>(this.transactionsUrl + '/GetCashierTransfereRequests', cashierTransfereRequestModel, super.getHeaders())
      .pipe(
        tap(transactions => this._BaseService.log('fetched transactions'))
      );
  }

  /** DELETE: delete the transaction from the server */
  deleteTransaction(transaction: Transactions | number): Observable<ExecutionResponse<Transactions>> {
    return this.http.post<ExecutionResponse<Transactions>>(this.transactionsUrl + '/delete', transaction, super.getHeaders()).pipe(
      tap(res => {
        this._BaseService.ValidationResult(res);
        this._BaseService.log('deleted transaction w/ id=${transaction.Id}')
      }),
      catchError(this._BaseService.handleError<ExecutionResponse<Transactions>>('deleteTransaction'))
    );
  }

  /** PUT: update the transaction on the server */
  updateTransaction(transaction: Transactions): Observable<ExecutionResponse<Transactions>> {
    transaction.AccountSetupId = super.getAccountSetupId();
    transaction.CompanyId = this.companyImagesService.getCompanyId();
    return this.http.post<ExecutionResponse<Transactions>>(this.transactionsUrl + '/Update', transaction, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);
          this._BaseService.log('added transaction w/ id=${transaction.Id}')
        }),
        catchError(this._BaseService.handleError<ExecutionResponse<Transactions>>(""))
      );
  }



  /** PUT: update the transaction and StockBalance on the server */
  updateTransactionWithStock(transactionWithStock: TransactionWithStock): Observable<ExecutionResponse<Transactions>> {
    transactionWithStock.Transaction.AccountSetupId = super.getAccountSetupId();
    return this.http.post<ExecutionResponse<Transactions>>(this.transactionsUrl + '/UpdateTransactionsWithStock', transactionWithStock, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);
          this._BaseService.log('added transaction w/ id=${transaction.Id}')
        }),
        catchError(this._BaseService.handleError<ExecutionResponse<Transactions>>('addTransactionWithStock id=${id}'))
      );
  }

  /** PUT: update the transaction and StockBalance on the server */
  AddGlameraPayment(glamerapayment: GlameraPayment): Observable<ExecutionResponse<Transactions>> {
    glamerapayment.AccountSetupId = super.getAccountSetupId();
    return this.http.post<ExecutionResponse<Transactions>>(this.transactionsUrl + '/GlameraPayment', glamerapayment, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);
          this._BaseService.log('added transaction w/ id=${transaction.Id}')
        }),
        catchError(this._BaseService.handleError<ExecutionResponse<Transactions>>('addTransactionWithStock id=${id}'))
      );
  }
}
