import { Employee } from "../employee/employee";

export class ServiceBranchEmployeeDTO {
  constructor() {

  }

  public ServiceBranchId: number;
  public CategoryBranchId: number;
  public ServiceName: string;
  public EmployeeIds: number[];
  public Employees: Employee[];
}


