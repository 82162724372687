<div class="modal-header">
  <h4 class="modal-title">{{'ChooseAccount' | translate}} </h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <!-- <p>Hello, {{name}}!</p> -->
  <div class="row">
    <div class="col-md-12" *ngIf="CostCenters; let CostCenters; else: loading">
      <div class="table-card">
        <div class="table-responsive text-nowrap">
          <table class="table table-stripedd table-sm table-bordered">
            <thead class="table-head">
              <tr>
                <th colspan="7">{{ "Name" | translate }}</th>
                <th>{{ "Actions" | translate }}</th>
              </tr>
            </thead>

            <tbody>
              <ng-container *ngFor="let item of CostCenters; let i = index">
                <tr class="">
                  <td colspan="7" (click)="selectAccountId(item)">
                    <i *ngIf="item.IsChild == true" class="fas fa-file-alt"></i>
                    <i
                      *ngIf="item.IsChild == false && i !== ExpandRowLevelTwo"
                      class="fas fa-folder"
                    ></i>
                    <i
                      *ngIf="item.IsChild == false && i == ExpandRowLevelTwo"
                      class="fas fa-folder-open"
                    ></i>
                    {{
                      translateService.currentLang == "ar"
                        ? item.NameAr
                        : item.NameEn
                    }}
                  </td>
                  <td>
                    <button
                      *ngIf="
                        item.IsChild == false && item.CostCenters.length != 0
                      "
                      class="btn btn-info btn-sm my-0 px-3"
                      (click)="ExpandLevelTwo(i, item.Id)"
                    >
                      <i
                        *ngIf="i !== ExpandRowLevelTwo"
                        class="fas fa-arrow-down"
                      ></i>
                      <i
                        *ngIf="i == ExpandRowLevelTwo"
                        class="fas fa-arrow-up"
                      ></i>
                    </button>
                    <button
                      *ngIf="item.IsChild == false && isViewPlus == true"
                      class="btn btn-warning btn-sm my-0 px-3"
                      (click)="
                        ExpandSubLevelAndEditLevelOpenModal(i, item);
                        activeModal.dismiss('Cross click')
                      "
                    >
                      <i class="fas fa-plus"></i>
                    </button>
                  </td>
                </tr>
                <ng-container *ngIf="ExpandRowLevelTwo == i">
                  <ng-container
                    *ngFor="let inneritem of CostCenters2; let j = index"
                  >
                    <tr class="purple lighten-5">
                      <td colspan="1"></td>
                      <td colspan="6">
                        <span
                          *ngIf="inneritem.IsChild == false"
                          (click)="selectAccountId(inneritem)"
                        >
                          <i
                            *ngIf="
                              inneritem.IsChild == false &&
                              j !== ExpandRowLevelThree
                            "
                            class="fas fa-folder"
                          ></i>

                          <i
                            *ngIf="
                              inneritem.IsChild == false &&
                              j == ExpandRowLevelThree
                            "
                            class="fas fa-folder-open"
                          ></i>
                          {{
                            translateService.currentLang == "ar"
                              ? inneritem.NameAr
                              : inneritem.NameEn
                          }}
                        </span>

                        <span
                          *ngIf="inneritem.IsChild == true"
                          (click)="selectAccountId(inneritem)"
                        >
                          <i class="fas fa-file-alt"></i>
                          {{
                            translateService.currentLang == "ar"
                              ? inneritem.NameAr
                              : inneritem.NameEn
                          }}
                        </span>
                      </td>
                      <td>
                        <button
                          class="btn btn-info btn-sm my-0 px-3"
                          (click)="ExpandLevelThree(j, inneritem.Id)"
                          *ngIf="inneritem.CostCenters.length != 0"
                        >
                          <i
                            *ngIf="j !== ExpandRowLevelThree"
                            class="fas fa-arrow-down"
                          ></i>
                          <i
                            *ngIf="j == ExpandRowLevelThree"
                            class="fas fa-arrow-up"
                          ></i>
                        </button>

                        <button
                          *ngIf="
                            inneritem.IsChild == false && isViewPlus == true
                          "
                          class="btn btn-warning btn-sm my-0 px-3"
                          (click)="
                            ExpandSubLevelAndEditLevelOpenModal(j, inneritem);
                            activeModal.dismiss('Cross click')
                          "
                        >
                          <i class="fas fa-plus"></i>
                        </button>
                      </td>
                    </tr>
                    <ng-container *ngIf="j == ExpandRowLevelThree">
                      <ng-container
                        *ngFor="
                          let innerinneritem of CostCenters3;
                          let k = index
                        "
                      >
                        <tr class="grey lighten-5">
                          <td></td>
                          <td></td>
                          <td colspan="5">
                            <span
                              *ngIf="innerinneritem.IsChild == false"
                              (click)="selectAccountId(innerinneritem)"
                            >
                              <i
                                *ngIf="
                                  innerinneritem.IsChild == false &&
                                  k !== ExpandRowLevelFour
                                "
                                class="fas fa-folder"
                              ></i>
                              <i
                                *ngIf="
                                  innerinneritem.IsChild == false &&
                                  k == ExpandRowLevelFour
                                "
                                class="fas fa-folder-open"
                              ></i>

                              {{
                                translateService.currentLang == "ar"
                                  ? innerinneritem.NameAr
                                  : innerinneritem.NameEn
                              }}
                            </span>
                            <span
                              *ngIf="innerinneritem.IsChild == true"
                              (click)="selectAccountId2(innerinneritem)"
                            >
                              <i class="fas fa-file-alt"></i>
                              {{
                                translateService.currentLang == "ar"
                                  ? innerinneritem.NameAr
                                  : innerinneritem.NameEn
                              }}
                            </span>
                          </td>
                          <td>
                            <button
                              class="btn btn-info btn-sm my-0 px-3"
                              (click)="ExpandLevelFour(k, innerinneritem.Id)"
                              *ngIf="innerinneritem.CostCenters.length != 0"
                            >
                              <i
                                *ngIf="k !== ExpandRowLevelFour"
                                class="fas fa-arrow-down"
                              ></i>
                              <i
                                *ngIf="k == ExpandRowLevelFour"
                                class="fas fa-arrow-up"
                              ></i>
                            </button>
                            <button
                              *ngIf="
                                innerinneritem.IsChild == false &&
                                isViewPlus == true
                              "
                              class="btn btn-warning btn-sm my-0 px-3"
                              (click)="
                                ExpandSubLevelAndEditLevelOpenModal(
                                  k,
                                  innerinneritem
                                );
                                activeModal.dismiss('Cross click')
                              "
                            >
                              <i class="fas fa-plus"></i>
                            </button>
                          </td>
                        </tr>
                        <ng-container *ngIf="k == ExpandRowLevelFour">
                          <ng-container
                            *ngFor="
                              let inneritem4 of CostCenters4;
                              let r = index
                            "
                          >
                            <tr class="grey lighten-5">
                              <td></td>
                              <td></td>
                              <td></td>
                              <td colspan="4">
                                <span
                                  *ngIf="inneritem4.IsChild == false"
                                  (click)="selectAccountId(inneritem4)"
                                >
                                  <i
                                    *ngIf="
                                      inneritem4.IsChild == false &&
                                      r !== ExpandRowLevelFive
                                    "
                                    class="fas fa-folder"
                                  ></i>
                                  <i
                                    *ngIf="
                                      inneritem4.IsChild == false &&
                                      r == ExpandRowLevelFive
                                    "
                                    class="fas fa-folder-open"
                                  ></i>
                                  {{
                                    translateService.currentLang == "ar"
                                      ? inneritem4.NameAr
                                      : inneritem4.NameEn
                                  }}
                                </span>
                                <span
                                  *ngIf="inneritem4.IsChild == true"
                                  (click)="selectAccountId3(inneritem4)"
                                >
                                  <i class="fas fa-file-alt"></i>
                                  {{
                                    translateService.currentLang == "ar"
                                      ? inneritem4.NameAr
                                      : inneritem4.NameEn
                                  }}
                                </span>
                              </td>
                              <td>
                                <button
                                  class="btn btn-info btn-sm my-0 px-3"
                                  (click)="ExpandLevelFive(r, inneritem4.Id)"
                                  *ngIf="inneritem4.CostCenters.length != 0"
                                >
                                  <i
                                    *ngIf="r !== ExpandRowLevelFive"
                                    class="fas fa-arrow-down"
                                  ></i>
                                  <i
                                    *ngIf="r == ExpandRowLevelFive"
                                    class="fas fa-arrow-up"
                                  ></i>
                                </button>
                                <button
                                  *ngIf="
                                    inneritem4.IsChild == false &&
                                    isViewPlus == true
                                  "
                                  class="btn btn-warning btn-sm my-0 px-3"
                                  (click)="
                                    ExpandSubLevelAndEditLevelOpenModal(
                                      r,
                                      inneritem4
                                    );
                                    activeModal.dismiss('Cross click')
                                  "
                                >
                                  <i class="fas fa-plus"></i>
                                </button>
                              </td>
                            </tr>
                            <ng-container *ngIf="r == ExpandRowLevelFive">
                              <ng-container
                                *ngFor="
                                  let inneritem5 of CostCenters5;
                                  let f = index
                                "
                              >
                                <tr class="grey lighten-5">
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td colspan="3">
                                    <span
                                      *ngIf="inneritem5.IsChild == false"
                                      (click)="selectAccountId(inneritem5)"
                                    >
                                      <i
                                        *ngIf="
                                          inneritem5.IsChild == false &&
                                          f !== ExpandRowLevelSix
                                        "
                                        class="fas fa-folder"
                                      ></i>
                                      <i
                                        *ngIf="
                                          inneritem5.IsChild == false &&
                                          f == ExpandRowLevelSix
                                        "
                                        class="fas fa-folder-open"
                                      ></i>
                                      {{
                                        translateService.currentLang == "ar"
                                          ? inneritem5.NameAr
                                          : inneritem5.NameEn
                                      }}
                                    </span>
                                    <span
                                      *ngIf="inneritem5.IsChild == true"
                                      (click)="selectAccountId5(inneritem5)"
                                    >
                                      <i class="fas fa-file-alt"></i>
                                      {{
                                        translateService.currentLang == "ar"
                                          ? inneritem5.NameAr
                                          : inneritem5.NameEn
                                      }}
                                    </span>
                                  </td>
                                  <td>
                                    <button
                                      class="btn btn-info btn-sm my-0 px-3"
                                      (click)="ExpandLevelSix(f, inneritem5.Id)"
                                      *ngIf="inneritem5.CostCenters.length != 0"
                                    >
                                      <i
                                        *ngIf="f !== ExpandRowLevelSix"
                                        class="fas fa-arrow-down"
                                      ></i>
                                      <i
                                        *ngIf="f == ExpandRowLevelSix"
                                        class="fas fa-arrow-up"
                                      ></i>
                                    </button>
                                    <button
                                      *ngIf="
                                        inneritem5.IsChild == false &&
                                        isViewPlus == true
                                      "
                                      class="btn btn-warning btn-sm my-0 px-3"
                                      (click)="
                                        ExpandSubLevelAndEditLevelOpenModal(
                                          f,
                                          inneritem5
                                        );
                                        activeModal.dismiss('Cross click')
                                      "
                                    >
                                      <i class="fas fa-plus"></i>
                                    </button>
                                  </td>
                                </tr>
                                <ng-container *ngIf="f == ExpandRowLevelSix">
                                  <ng-container
                                    *ngFor="
                                      let inneritem6 of CostCenters6;
                                      let s = index
                                    "
                                  >
                                    <tr class="grey lighten-5">
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td colspan="2">
                                        <span
                                          *ngIf="inneritem6.IsChild == false"
                                          (click)="selectAccountId6(inneritem6)"
                                        >
                                          <i
                                            *ngIf="
                                              inneritem6.IsChild == false &&
                                              s !== ExpandRowLevelSeven
                                            "
                                            class="fas fa-folder"
                                          ></i>
                                          <i
                                            *ngIf="
                                              inneritem6.IsChild == false &&
                                              s == ExpandRowLevelSeven
                                            "
                                            class="fas fa-folder-open"
                                          ></i>

                                          {{
                                            translateService.currentLang == "ar"
                                              ? inneritem6.NameAr
                                              : inneritem6.NameEn
                                          }}
                                        </span>
                                        <span
                                          (click)="selectAccountId6(inneritem6)"
                                          *ngIf="inneritem6.IsChild == true"
                                        >
                                          <i class="fas fa-file-alt"></i>
                                          {{
                                            translateService.currentLang == "ar"
                                              ? inneritem6.NameAr
                                              : inneritem6.NameEn
                                          }}
                                        </span>
                                      </td>
                                      <td>
                                        <button
                                          class="btn btn-info btn-sm my-0 px-3"
                                          (click)="
                                            ExpandLevelSeven(s, inneritem6.Id)
                                          "
                                          *ngIf="
                                            inneritem6.CostCenters.length != 0
                                          "
                                        >
                                          <i
                                            *ngIf="s !== ExpandRowLevelSeven"
                                            class="fas fa-arrow-down"
                                          ></i>
                                          <i
                                            *ngIf="s == ExpandRowLevelSeven"
                                            class="fas fa-arrow-up"
                                          ></i>
                                        </button>
                                        <button
                                          *ngIf="
                                            inneritem6.IsChild == false &&
                                            isViewPlus == true
                                          "
                                          class="btn btn-warning btn-sm my-0 px-3"
                                          (click)="
                                            ExpandSubLevelAndEditLevelOpenModal(
                                              s,
                                              inneritem6
                                            );
                                            activeModal.dismiss('Cross click')
                                          "
                                        >
                                          <i class="fas fa-plus"></i>
                                        </button>
                                      </td>
                                    </tr>
                                    <ng-container
                                      *ngIf="s == ExpandRowLevelSeven"
                                    >
                                      <ng-container
                                        *ngFor="
                                          let inneritem7 of CostCenters7;
                                          let v = index
                                        "
                                      >
                                        <tr class="grey lighten-5">
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td colspan="1">
                                            <span
                                              *ngIf="
                                                inneritem7.IsChild == false
                                              "
                                              (click)="
                                                selectAccountId(inneritem7)
                                              "
                                            >
                                              <i
                                                *ngIf="
                                                  inneritem7.IsChild == false &&
                                                  v !== ExpandRowLevelEight
                                                "
                                                class="fas fa-folder"
                                              ></i>
                                              <i
                                                *ngIf="
                                                  inneritem7.IsChild == false &&
                                                  v == ExpandRowLevelEight
                                                "
                                                class="fas fa-folder-open"
                                              ></i>

                                              {{
                                                translateService.currentLang ==
                                                "ar"
                                                  ? inneritem7.NameAr
                                                  : inneritem7.NameEn
                                              }}
                                            </span>
                                            <span
                                              (click)="
                                                selectAccountId7(inneritem7)
                                              "
                                              *ngIf="inneritem7.IsChild == true"
                                            >
                                              <i class="fas fa-file-alt"></i>

                                              {{
                                                translateService.currentLang ==
                                                "ar"
                                                  ? inneritem7.NameAr
                                                  : inneritem7.NameEn
                                              }}
                                            </span>
                                          </td>
                                          <td>
                                            <button
                                              class="btn btn-info btn-sm my-0 px-3"
                                              (click)="
                                                ExpandLevelEight(
                                                  v,
                                                  inneritem7.Id
                                                )
                                              "
                                              *ngIf="
                                                inneritem7.CostCenters.length !=
                                                0
                                              "
                                            >
                                              <i
                                                *ngIf="
                                                  v !== ExpandRowLevelEight
                                                "
                                                (click)="
                                                  GetData(8, inneritem7.Id)
                                                "
                                                class="fas fa-arrow-down"
                                              ></i>
                                              <i
                                                *ngIf="v == ExpandRowLevelEight"
                                                class="fas fa-arrow-up"
                                              ></i>
                                            </button>
                                            <button
                                              *ngIf="
                                                inneritem7.IsChild == false &&
                                                isViewPlus == true
                                              "
                                              class="btn btn-warning btn-sm my-0 px-3"
                                              (click)="
                                                ExpandSubLevelAndEditLevelOpenModal(
                                                  v,
                                                  inneritem7
                                                );
                                                activeModal.dismiss(
                                                  'Cross click'
                                                )
                                              "
                                            >
                                              <i class="fas fa-plus"></i>
                                            </button>
                                          </td>
                                        </tr>
                                        <ng-container
                                          *ngIf="v == ExpandRowLevelEight"
                                        >
                                          <ng-container
                                            *ngFor="
                                              let inneritem8 of CostCenters8;
                                              let e = index
                                            "
                                          >
                                            <tr class="grey lighten-5">
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td>
                                                <span
                                                  *ngIf="
                                                    inneritem8.IsChild == false
                                                  "
                                                  (click)="
                                                    selectAccountId(inneritem8)
                                                  "
                                                >
                                                  <i class="fas fa-folder"></i>

                                                  {{
                                                    translateService.currentLang ==
                                                    "ar"
                                                      ? inneritem8.NameAr
                                                      : inneritem8.NameEn
                                                  }}
                                                </span>
                                                <span
                                                  (click)="
                                                    selectAccountId8(inneritem8)
                                                  "
                                                  *ngIf="
                                                    inneritem8.IsChild == true
                                                  "
                                                >
                                                  <i
                                                    class="fas fa-file-alt"
                                                  ></i>
                                                  {{
                                                    translateService.currentLang ==
                                                    "ar"
                                                      ? inneritem8.NameAr
                                                      : inneritem8.NameEn
                                                  }}
                                                </span>
                                              </td>
                                              <td></td>
                                            </tr>
                                          </ng-container>
                                        </ng-container>
                                      </ng-container>
                                    </ng-container>
                                  </ng-container>
                                </ng-container>
                              </ng-container>
                            </ng-container>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
          <div class="text-center py-4 my-4" *ngIf="CostCenters.length == 0">
            <h4 class="py-4">{{ "NoDataFound" | translate }}</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <ng-template #loading>
        <div class="col-md-12 text-center py-4 my-4">
          <i class="fas fa-circle-notch fa-spin fa-3x"></i>
        </div>
      </ng-template>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-modal-action"
    (click)="activeModal.close('Close click')"
  >
    {{ "Close" | translate }}
  </button>
</div>
