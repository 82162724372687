import { Injectable } from '@angular/core';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../message/message.service';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { SharedService } from '../other/shared.service';
import { ServiceBranchSearchCriteria } from '../../models/search-criterias/service-branch-search-criteria';
import { ServiceBranchSearchResult } from '../../models/search-result/service-branch-search-result';

@Injectable({
  providedIn: 'root'
})
export class ServiceBranchService extends BaseService {

  private serviceBranchEmployeeUrl  //= super.BaseUrl() + 'Service';  // URL to web api

  constructor(
    private http: HttpClient, private messageService: MessageService,
    private sharedService:SharedService,
    private _BaseService: BaseServiceSZ

  ) {
    super();
    this.serviceBranchEmployeeUrl = this.sharedService.ApiUrl + 'ServiceBranch';

  }

  GetServiceBranches(serviceBranchSearchCriteria: ServiceBranchSearchCriteria): Observable<ServiceBranchSearchResult> {
    serviceBranchSearchCriteria.AccountSetupId = super.getAccountSetupId();
    return this.http.post<ServiceBranchSearchResult>(this.serviceBranchEmployeeUrl + '/Get', serviceBranchSearchCriteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('Fetched Service Branch');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ServiceBranchSearchResult>('Fetched Service Branch'))
      );
  }
}
