import { Injectable } from '@angular/core';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { HttpClient } from '@angular/common/http';
import { UIStateService } from '../other/ui-state.service';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { CompanyImages, CompanyImageAccounts } from '../../models/classes/company/company-image';
import { CompanyImagesSearchCriteria, CompanyImageAccountsSearchCriteria } from '../../models/search-criterias/companyImages-search-criteria';
import { CompanyImagesSearchResult } from '../../models/search-result/company-images-search-result';
import { ExecutionResponse } from '../../models/support/execution-response';


@Injectable({
  providedIn: 'root'
})
export class CompanyImagesServiceEditedName extends BaseService {
  uploaderUrl: string;
  companyImagesUrl: string;
  companyImageAccountsUrl: string;
  constructor(
    private http: HttpClient,
    private _UIStateService: UIStateService,
    private _BaseService: BaseServiceSZ,
  ) {
    super();
    
    this.uploaderUrl = this._BaseService.ApiUrl + 'Upload/PostImageFile';
    this.companyImagesUrl = this._BaseService.ApiUrl + 'CompanyImages';
    this.companyImageAccountsUrl = this._BaseService.ApiUrl + 'CompanyImageAccounts';

  }
  getApiUrl() {
    return this._BaseService.ApiUrl;
  }

  getCompanyImages(): Observable<CompanyImagesSearchResult> {
    let companyImagesSearchCriteria = new CompanyImagesSearchCriteria();
    companyImagesSearchCriteria.CompanyId = this.getCompanyId();

    
    
    return this.http.post<CompanyImagesSearchResult>(this.companyImagesUrl + '/Get', companyImagesSearchCriteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<CompanyImagesSearchResult>('getCompany id=${id}'))
      );
  }



  addCompanyImages(companyImages: CompanyImages[]): Observable<ExecutionResponse<CompanyImages>> {
    companyImages.forEach(x => {
      //x.AccountSetupId = super.getAccountSetupId();
      x.CompanyId = this.getCompanyId();
    });
    return this.http.post<ExecutionResponse<CompanyImages>>(this.companyImagesUrl + '/Create', companyImages, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<ExecutionResponse<CompanyImages>>(''))
      );
  }
  getCompanyId(): number {

    if (this._UIStateService._AppGlobals && this._UIStateService._AppGlobals.AccountData)
      return this._UIStateService._AppGlobals.AccountData.CompanyId;
    else
      return 0;


  }
  /** PUT: update the category on the server */
  updateCompanyImage(company: CompanyImages): Observable<ExecutionResponse<CompanyImages>> {
    return this.http.post<ExecutionResponse<CompanyImages>>(this.companyImagesUrl+'/Update', company, super.getHeaders())
      .pipe(
        tap(res => {
          
          
          let x=res;
          this._BaseService.log('added category w/ id=${category.Id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<CompanyImages>>('addCompany id=${id}'))
      );
  }
  //------------------------------------------------------image acccounts  
  getCompanyImageAccounts(CompanyImagesId: number): Observable<ExecutionResponse<CompanyImageAccounts[]>> {
    let _Criteria = new CompanyImageAccountsSearchCriteria();
    _Criteria.CompanyImagesId = CompanyImagesId;
     
    return this.http.post<ExecutionResponse<CompanyImageAccounts[]>>(this.companyImageAccountsUrl + '/Get', _Criteria, super.getHeaders())
      .pipe(
        tap(res => {
          let x=res;
          
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<ExecutionResponse<CompanyImageAccounts[]>>('getCompany id=${id}'))
      );
  }
  addCompanyImageAccounts(_CompanyImageAccounts: CompanyImageAccounts[]): Observable<ExecutionResponse<CompanyImageAccounts>> {

    return this.http.post<ExecutionResponse<CompanyImageAccounts>>(this.companyImageAccountsUrl + '/Create', _CompanyImageAccounts, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<ExecutionResponse<CompanyImageAccounts>>(''))
      );
  }

  deleteCompanyImageAccount(_CompanyImageAccounts: CompanyImageAccounts): Observable<ExecutionResponse<CompanyImageAccounts>> {

    return this.http.post<ExecutionResponse<CompanyImageAccounts>>(this.companyImageAccountsUrl + '/Delete', _CompanyImageAccounts.Id, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<ExecutionResponse<CompanyImageAccounts>>(''))
      );
  };

  deleteCompanyImage(id: number): Observable<ExecutionResponse<CompanyImages>> {

    return this.http.post<ExecutionResponse<CompanyImages>>(this.companyImagesUrl + '/Delete', id, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<ExecutionResponse<CompanyImages>>(''))
      );
  };

}
