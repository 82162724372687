import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { TransactionsSecondService } from 'src/app/services/transaction/transactions-second.service';
import { ExecutionResponse } from 'src/app/models/support/execution-response';
import { EditBillItemEmployeeModel } from 'src/app/models/classes/transactions/edit-bill-Item-employee-model';
import { SharedService } from 'src/app/services/other/shared.service';
import { ResponseState } from 'src/app/models/support/response-state';

@Component({
  selector: 'app-modal-change-bill-item-employee-to-current-employee',
  templateUrl: './modal-change-bill-item-employee-to-current-employee.component.html',
  styleUrls: ['./modal-change-bill-item-employee-to-current-employee.component.scss'],
})
export class ModalChangeBillItemEmployeeToCurrentEmployee implements OnInit {

  @Input() public ItemName: string;
  @Input() public EmployeeName: string;
  @Input() public BillItemId: number;
  @Input() public BillPackageItemId: number;
  @Output() BillItemEdited = new EventEmitter<void>();

  constructor(
    public activeModal: NgbActiveModal,
    public sharedService: SharedService,
    private transactionsSecondService: TransactionsSecondService,
    public translateService: TranslateService
  ) { }

  ngOnInit(): void {

  }

  btnLoading;
  EditBillItemEmployee() {
    this.btnLoading = true;

    var model = new EditBillItemEmployeeModel();
    model.BillItemId = this.BillItemId;
    model.BillPackageItemId = this.BillPackageItemId;

    this.transactionsSecondService
      .EditBillItemEmployee(model)
      .subscribe((response: ExecutionResponse<boolean>) => {
        if (response.State == ResponseState.Success) {
          this.BillItemEdited.next(null);
          this.sharedService.ToastrSuccess(response.Message);
          this.activeModal.close();
        }

        this.btnLoading = false;
      });
  }
}
