import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap, delay } from 'rxjs/operators';
import { MessageService } from '../message/message.service';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { SharedService } from '../other/shared.service';
import { HttpClient } from '@angular/common/http';
import { ExecutionResponse } from '../../models/support/execution-response';
import { AttachmentTypeDTO } from 'src/app/models/classes/registration-cycle/attachment-type-dto';
import { GetAttachmentTypesCriteria } from 'src/app/models/search-criterias/registration-cycle/get-attachement-type-criteria';

@Injectable({
  providedIn: 'root',
})
export class HelperService extends BaseService {
  private helperUrl;

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private _BaseService: BaseServiceSZ,
    private sharedService: SharedService
  ) {
    super();
    this.helperUrl =
      this.sharedService.ApiUrl + 'Helper';
  }
  getApiUrl() {
    return this._BaseService.ApiUrl;
  }

  GetAttachmentTypes(
    criteria: GetAttachmentTypesCriteria
  ): Observable<ExecutionResponse<AttachmentTypeDTO[]>> {
    return this.http
      .get<ExecutionResponse<AttachmentTypeDTO[]>>(
        this.helperUrl + '/GetAttachmentTypes',
        {
          headers: this.sharedService.getHeaders().headers,
          params: this.sharedService.ToHttpParams(criteria),
        }
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('GetAttachmentTypes');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<ExecutionResponse<AttachmentTypeDTO[]>>(
            'GetAttachmentTypes'
          )
        )
      );
  }
}
