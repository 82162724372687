
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { MessageService } from '../message/message.service';
import { formValueDates } from '../../shared/helpers';
import { BaseServiceSZ, BaseService } from '../other/baseService';
import { Position } from '../../models/classes/employee/position';
import { PositionSearchCriteria } from '../../models/search-criterias/position-search-criteria';
import { PositionResult } from '../../models/search-result/position-result';
import { ExecutionResponse } from '../../models/support/execution-response';
import { ValidationCriteria } from '../../models/support/validation-criterias/validation-criteria';

@Injectable({
  providedIn: 'root'
})
export class PositionService  extends BaseService{

  private PositionUrl 

  constructor(
    private http: HttpClient, private messageService: MessageService,
    private  _BaseService:BaseServiceSZ
  ) {
    super();
    this.PositionUrl =    this._BaseService.ApiUrl + 'employeeposition';

  }



  getPosition(id: String): Observable<ExecutionResponse<Position>> {
    //  getEmployee(id: String): ExecutionResponse<Employee> {
    //const url = '${this.employeesUrl}/${id}';
    
    return this.http.post<ExecutionResponse<Position>>(this.PositionUrl + '/GetPositionById', id, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched position id=${id}');
          this._BaseService.ValidationResult(res);
      }),
        
        catchError(this._BaseService.handleError<ExecutionResponse<Position>>
          ('getPosition id=${id}'))
      );
  }



  /* GET Position whose name contains search term */
  searchPosition(positionSearchCriteria: PositionSearchCriteria): 
  Observable<PositionResult> {
    
    positionSearchCriteria.AccountSetupId = super.getAccountSetupId();
    return this.http.post<PositionResult>
    (this.PositionUrl + '/GetAllPositions', positionSearchCriteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched Positions');
          this._BaseService.ValidationResult(res);
      }),
        
        
        //catchError(this._BaseService.handleError('getEmployees', []))
      );

  }




  addPosition(position: Position): Observable<ExecutionResponse<Position>> {
    position.AccountSetupId = super.getAccountSetupId();

    return this.http.post<ExecutionResponse<Position>>(this.PositionUrl + '/Create', 
    position, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('add Position id=${id}');
          this._BaseService.ValidationResult(res);
      }),
        catchError(this._BaseService.handleError<ExecutionResponse<Position>>('addPosition id=${id}'))
      );
  }

  /** DELETE: delete the employee from the server */
//   deleteEmployee(employee: Employee | number): Observable<ExecutionResponse<Employee>> {
//     return this.http.post<ExecutionResponse<Employee>>(this.employeesUrl + '/delete', employee, super.getHeaders()).pipe(
//       tap(res => {
//         this._BaseService.log('deleted employee id=${id}');
//         this._BaseService.ValidationResult(res);
//     }),
//       catchError(this._BaseService.handleError<ExecutionResponse<Employee>>('deleteEmployee'))
//     );
//   }


  IsNameUnique(validationCriteria: ValidationCriteria): Observable<ExecutionResponse<Boolean>> {
    return this.http.post<ExecutionResponse<Boolean>>(this.PositionUrl + '/IsNameUnique',
     validationCriteria, super.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deleted positionmaindata id=${id}');
        this._BaseService.ValidationResult(res);
    }),
      
      catchError(this._BaseService.handleError<ExecutionResponse<Boolean>>('deletePosition'))
    );
  }

  /** PUT: update the Position on the server */
  updatePosition(position: Position): Observable<ExecutionResponse<Position>> {
    position.AccountSetupId = super.getAccountSetupId();
    position = formValueDates(position,true);

    return this.http.post<ExecutionResponse<Position>>(this.PositionUrl + 
      '/Update', position, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('added Position w/ id=${Position.Id}');
          this._BaseService.ValidationResult(res);
      }),
        catchError(this._BaseService.handleError
          <ExecutionResponse<Position>>('addPosition id=${id}'))
      );
  }




}
