import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-congratulations',
  templateUrl: './congratulations.component.html',
  styleUrls: ['./congratulations.component.scss'],
})
export class CongratulationsComponent implements OnInit, OnDestroy {
  constructor(private readonly router: Router) {}
  timeOut: any;

  ngOnInit(): void {
    this.timeOut = setTimeout(() => {
      this.router.navigate(['']);
    }, 1000);
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeOut);
  }
}
