import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { formValueDates } from '../../shared/helpers';
import { PaymentMethodSearchCriteria } from '../../models/search-criterias/payment-method-search-criteria';
import { PaymentMethodSearchResult } from '../../models/search-result/payment-method-search-result';
import { ExecutionResponse } from '../../models/support/execution-response';
import { SharedService } from '../other/shared.service';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { PaymentMethod } from 'src/app/models/classes/cashier/payment-method';
import { ResourceStatus } from 'src/app/models/enums/resource-status';
@Injectable({
  providedIn: 'root',
})
export class PaymentMethodService extends BaseService {
  private paymentMethodUrl; //= super.BaseUrl() + 'PaymentMethod';  // URL to web api

  constructor(
    private http: HttpClient,
    private _BaseService: BaseServiceSZ,
    private sharedService: SharedService
  ) {
    super();
    this.paymentMethodUrl = this.sharedService.ApiUrl + 'PaymentMethod';
  }

  getPaymentMethod(id: String): Observable<ExecutionResponse<PaymentMethod>> {
    return this.http
      .post<ExecutionResponse<PaymentMethod>>(
        this.paymentMethodUrl + '/getById',
        id,
        this.sharedService.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('fetchedpaymentMethod id=${id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<ExecutionResponse<PaymentMethod>>(
            'getPaymentMethod id=${id}'
          )
        )
      );
  }

  addPaymentMethod(
    paymentMethod: PaymentMethod
  ): Observable<ExecutionResponse<PaymentMethod>> {
    paymentMethod = formValueDates(paymentMethod, true);
    paymentMethod.MainBranchId = this.sharedService.AccountSetupId;
    return this.http
      .post<ExecutionResponse<PaymentMethod>>(
        this.paymentMethodUrl + '/Create',
        paymentMethod,
        this.sharedService.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('addedpaymentMethod w/ id=${paymentMethod.Id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<ExecutionResponse<PaymentMethod>>(
            'addPaymentMethod id=${id}'
          )
        )
      );
  }

  /* GETpaymentMethod whose name contains search term */
  searchPaymentMethods(
    paymentMethodSearchCriteria: PaymentMethodSearchCriteria,
    defaultStatus: ResourceStatus = 1
  ): Observable<PaymentMethodSearchResult> {
    paymentMethodSearchCriteria.AccountSetupId = this.sharedService.AccountSetupId;
    paymentMethodSearchCriteria.Status = defaultStatus;
    return this.http
      .post<PaymentMethodSearchResult>(
        this.paymentMethodUrl + '/Get',
        paymentMethodSearchCriteria,
        this.sharedService.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('fetchedpaymentMethod');
          this._BaseService.ValidationResult(res);
        }),
        catchError(
          this._BaseService.handleError<PaymentMethodSearchResult>(
            'searchPaymentMethods id=${id}'
          )
        )
      );
  }

  /* GETpaymentMethod whose name contains search term */
  searchPaymentMethodsLite(
    paymentMethodSearchCriteria: PaymentMethodSearchCriteria
  ): Observable<PaymentMethodSearchResult> {
    paymentMethodSearchCriteria.AccountSetupId =
      this.sharedService.AccountSetupId;
    return this.http
      .post<PaymentMethodSearchResult>(
        this.paymentMethodUrl + '/GetLite',
        paymentMethodSearchCriteria,
        this.sharedService.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('fetchedpaymentMethodLite');
          this._BaseService.ValidationResult(res);
        }),
        catchError(
          this._BaseService.handleError<PaymentMethodSearchResult>(
            'searchPaymentMethodsLite id=${id}'
          )
        )
      );
  }

  GetAllUserBranchesPaymentMethods(
    paymentMethodSearchCriteria: PaymentMethodSearchCriteria
  ): Observable<PaymentMethodSearchResult> {
    return this.http
      .post<PaymentMethodSearchResult>(
        this.paymentMethodUrl + '/GetAllUserBranchesPaymentMethods',
        paymentMethodSearchCriteria,
        super.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('GetAllUserBranchesSafes');
          this._BaseService.ValidationResult(res);
        }),
        catchError(
          this._BaseService.handleError<PaymentMethodSearchResult>(
            'GetAllUserBranchesPaymentMethods id=${id}'
          )
        )
      );
  }

  deletePaymentMethod(
    paymentMethod: PaymentMethod | number
  ): Observable<ExecutionResponse<PaymentMethod>> {
    return this.http
      .post<ExecutionResponse<PaymentMethod>>(
        this.paymentMethodUrl + '/delete',
        paymentMethod,
        this.sharedService.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('deletedpaymentMethod id=${id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<ExecutionResponse<PaymentMethod>>(
            'deletePaymentMethod'
          )
        )
      );
  }

  /** PUT: update thepaymentMethod on the server */
  updatePaymentMethod(
    paymentMethod: PaymentMethod
  ): Observable<ExecutionResponse<PaymentMethod>> {
    paymentMethod.MainBranchId = this.sharedService.AccountSetupId;
    paymentMethod = formValueDates(paymentMethod, true);
    return this.http
      .post<ExecutionResponse<PaymentMethod>>(
        this.paymentMethodUrl + '/Update',
        paymentMethod,
        this.sharedService.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('addedpaymentMethod w/ id=${paymentMethod.Id}');
          this._BaseService.ValidationResult(res);
        }),
        catchError(
          this._BaseService.handleError<ExecutionResponse<PaymentMethod>>(
            'addPaymentMethod id=${id}'
          )
        )
      );
  }
}
