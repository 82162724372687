import { Component, Input, EventEmitter, Output } from '@angular/core';
import { BRANCH, USER } from 'core';

@Component({
  selector: 'app-user-item',
  templateUrl: './user-item.component.html',
  styleUrls: ['./user-item.component.scss'],
})
export class UserItemComponent {
  @Input() userList: USER[] = [];
  @Output() clickItem = new EventEmitter<number>();
  loading: boolean = false;

  onClickItem(user: USER): void {
    this.loading = true;
    this.clickItem.emit(user.Id);
  }
}
