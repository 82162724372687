<section     (click)="onClickItem(branch)"
  class="branch-item"
  fxLayout="row"
  fxLayoutAlign="space-between center"
  *ngFor="let branch of branchList"

>
  <main
    fxLayout="row"
    fxLayoutAlign="start start"
  >
    <div *ngIf="branch.IsMainBranch" class="side-line" [ngClass]="isRtl ? 'rtl' : 'ltr'"></div>
    <img *ngIf="branch.IsMainBranch" src="assets/img/branch/main.png" />
    <img *ngIf="!branch.IsMainBranch" src="assets/img/branch/none-main.png" />
    <div class="desc">
      <h1>
        {{ branch.Name }}
        <span *ngIf="branch.IsMainBranch" class="is-main">
          ({{ "Main_Branch" | translate }})
        </span>
      </h1>
      <div>{{ branch.CityName }}</div>
    </div>
  </main>
  <i class="fa-solid fa-chevron-right" [ngClass]="{'fa-rotate-180': isRtl}"></i>
</section>
