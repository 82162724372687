import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap, delay } from 'rxjs/operators';
import { MessageService } from '../message/message.service';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { SharedService } from '../other/shared.service';
import { HttpClient } from '@angular/common/http';
import { SkipSearchResult } from '../../models/search-result/registration-cycle/skip-search-result';
import { RegistrationSkipModel } from '../../models/classes/registration-cycle/registration-skip-model';
import { GetRegisteredPhasesDataResult } from '../../models/search-result/registration-cycle/get-registered-phases-data-result';
import { GetRegisteredPhasesDataModel } from '../../models/classes/registration-cycle/get-registered-phases-data-model';
import { SetDoneShowIntroVideoSearchResult } from '../../models/search-result/registration-cycle/set-done-show-intro-video-search-result';
import { SetDoneShowIntroVideoModel } from '../../models/classes/registration-cycle/set-done-show-intro-video-model';
import { ExecutionResponse } from '../../models/support/execution-response';
import { RegisteredPhasesDataDTO } from '../../models/classes/registration-cycle/registered-phases-data-DTO';

@Injectable({
  providedIn: 'root',
})
export class RegistrationCycleGeneralService extends BaseService {
  private registrationCycleGeneralUrl;

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private _BaseService: BaseServiceSZ,
    private sharedService: SharedService
  ) {
    super();
    this.registrationCycleGeneralUrl =
      this.sharedService.ApiUrl + 'RegistrationCycleGeneral';
  }
  getApiUrl() {
    return this._BaseService.ApiUrl;
  }

  Skip(model: RegistrationSkipModel): Observable<SkipSearchResult> {
    return this.http
      .post<SkipSearchResult>(
        this.registrationCycleGeneralUrl + '/Skip',
        model,
        this.sharedService.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<SkipSearchResult>('Skip'))
      );
  }

  GetRegisteredPhasesData(
    model: GetRegisteredPhasesDataModel
  ): Observable<ExecutionResponse<RegisteredPhasesDataDTO>> {
    return this.http
      .get<ExecutionResponse<RegisteredPhasesDataDTO>>(
        this.registrationCycleGeneralUrl + '/GetRegisteredPhasesData',
        {
          headers: this.sharedService.getHeaders().headers,
          params: this.sharedService.ToHttpParams(model),
        }
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('GetRegisteredPhasesData');
          //this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<
            ExecutionResponse<RegisteredPhasesDataDTO>
          >('GetRegisteredPhasesData')
        )
      );
  }

  SetDoneShowIntroVideo(
    model: SetDoneShowIntroVideoModel
  ): Observable<SetDoneShowIntroVideoSearchResult> {
    return this.http
      .post<GetRegisteredPhasesDataResult>(
        this.registrationCycleGeneralUrl + '/SetDoneShowIntroVideo',
        model,
        this.sharedService.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('Set Done Show Intro Video');
          //this._BaseService.ValidationResult(res);
        }),
        catchError(
          this._BaseService.handleError<SetDoneShowIntroVideoSearchResult>(
            'Set Done Show Intro Video'
          )
        )
      );
  }
}
