<div id="printClientGiftCardInfo">
  <div class="modal-header">
    <h5 class="modal-title text-capitalize" id="exampleModalLabel">
      <i class="fas fa-edit"></i> &nbsp; {{'ChangeMembershipClient' | translate}}
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12" *ngIf="Clients ">
        <div class="text-start py-4">
          <div class="formCard">
            <div class="row">

              <div class="col-md-12 col-12 " style="color: black;font-size: 14px;margin-bottom: 5%;">
                {{'CurrentClientName:' | translate}} &nbsp; {{CurrentClientName}}
              </div>
              <div class="col-md-12 col-12" style="margin-bottom: 20%;">
                <label>
                  {{'ChooseTheNewClient'| translate}}
                </label>
                <ng-select [placeholder]="'SearchClients' | translate" name="BookingOrTransactionClientId"
                  [(ngModel)]="ClientId" [multiple]="false" [items]="Clients" bindLabel="Name" bindValue="Id"
                  [searchFn]="customSearchFn" (search)="onSearch($event)">
                  <ng-template ng-option-tmp let-item="item">
                    <div class="list-client-insearch">
                      <div [ngClass]="{'first-char': !item.IsBanned, 'first-char-banned' : item.IsBanned }"
                        [title]="item.IsBanned ? (('BannedClient' | translate) + ' - ' + ('BanReason' | translate) + ':' + item.BanReason) : item.Name">
                        {{ item.Name | slice : 0 : 1 }}
                      </div>
                      <div [ngClass]="{'info-client': !item.IsBanned, 'info-client-banned' : item.IsBanned }"
                        [title]="item.IsBanned ? (('BannedClient' | translate) + ' - ' + ('BanReason' | translate) + ':' + item.BanReason) : item.Name">
                        {{ item.Name }} <br />
                        <span>{{ item.Mobile }}</span>
                      </div>
                    </div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="col-lg-12" *ngIf="!Clients">
        <div class="col-md-12 text-center py-4 my-4">
          <i class="fas fa-circle-notch fa-spin fa-3x"></i>
          <h1>{{'Loading' | translate}} </h1>
        </div>
      </div>

    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">
      {{'Close' | translate}}</button>
    <button type="button" class="btn btn-success" [disabled]="LoadingChangeClient"
      (click)="ChangeGymMembershipClient()">
      <span *ngIf="!LoadingChangeClient">
        {{'ChangeClient' | translate}}
      </span>
      <span *ngIf="LoadingChangeClient">
        {{'LoadingChangeClient' | translate}}
      </span>
    </button>
  </div>
</div>
