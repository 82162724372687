import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SzAutocompleteComponent } from './sz-autocomplete.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {SelectModule} from 'ng-select'
import { TranslateSharedModule } from '../../../shared/translate-shared/translate-shared.module';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateSharedModule,
    SelectModule
  ],
  declarations: [SzAutocompleteComponent],
  exports:[SzAutocompleteComponent]
})
export class SzAutocompleteModule { }
