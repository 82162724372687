import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { ExecutionResponse } from "../../../models/support/execution-response"
import { HttpBaseService } from "../../../services/other/httpbase.service"
import { SharedService } from "../../../services/other/shared.service"

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  constructor(private httpBaseService: HttpBaseService, private http: HttpClient, private sharedService: SharedService) { }
  /*=================================================================*/
  GetTransactions(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Transactions/Get', body)
  }
  CreateTransactions(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Transactions/Create', body)
  }

  ReceiveAndConfirmCloseShift(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('DaysAndShiftsTransactions/ReceiveAndConfirmCloseShift', body)
  }

  ReviewBalances(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('CashierShifts/DoneReviewShiftBalance', body)
  }

  UpdateTransactions(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Transactions/Update', body)
  }
  DeleteTransactions(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Transactions/delete', body)
  }
  GetDetailCodesWithMasterId(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Helper/GetDetailCodesWithMasterId', body)
  }
  GetBookingNotPayedCharges(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Transactions/GetBookingNotPayedCharges', body)
  }
  GetBookingNotPayedChargesNew(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Transactions/GetBookingNotPayedChargesNew', body)
  }

  /*===============   CloseShift  ===================================*/

  CreateCloseShift(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('CashierBalance/CloseShift', body);
  }
  GetShiftDetails(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('DaysAndShiftsTransactions/GetShiftDetails', body)
  }

  GetDailyDetails(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('DaysAndShiftsTransactions/GetDailyDetails', body)
  }

  GetTransactionHeader(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Transactions/GetTransactionHeader', body);
    // {"EmployeeId":13640,"AccountSetupId":7299}
  }
  GetTransactionWithPaidProductAndService(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Transactions/GetTransactionWithPaidProductAndService', body);
  }
  getByIdAccountSetup(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('AccountSetup/getById', body);
  }


  /*=================================================================*/
  GetVisaType(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('VisaType/Get', body)
  }
  /*=================================================================*/
  GetEmployee(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Employee/Get', body)
  }
  /*============ PettyCashItems =========================================*/
  GetPettyCashItems(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('PettyCashItems/Get', body)
  }

  /*============ GetRetailProduct =========================================*/
  GetRetailProduct(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('RetailProduct/Get', body)
  }
  /*============ GetBasicService =========================================*/
  GetBasicService(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Service/GetBasic', body)
  }
  /*============ GetByIdAccountSetup =========================================*/
  GetByIdAccountSetup(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('AccountSetup/getById', body)
  }
  // /*============ GetByIdAccountSetup =========================================*/
  GetStockMainData(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('StockMainData/Get', body)
  }


  // /*============ GetByIdAccountSetup =========================================*/
  GetGetBookingNotPayedCharges(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Transactions/GetBookingNotPayedCharges', body)
  }
  //*============ GetGiftCards =========================================*/
  GetGiftCards(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('OffersSetting/GetGiftCards', body)
  }

  //*============ GetVouchers =========================================*/
  GetVouchers(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('OffersSetting/GetVouchers', body)
  }
  //*============ GetPackages =========================================*/
  GetPackages(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('OffersSetting/GetPackages', body)
  }
  GetPackageById(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('OffersSetting/GetPackageById', body)
  }

  //*============ GetBooking =========================================*/
  GetBooking(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Booking/Get', body)
  }

  //*============ GetTransactionForPrint =========================================*/
  GetTransactionForPrint(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Transactions/GetTransactionForPrint', body)
  }
  //*============ GetCloseDay =========================================*/
  GetCloseDay(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('CashierDaily/CloseDay', body)
  }
  //*============ GetCloseDay =========================================*/
  GetCashierDaily(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('CashierDaily/Get', body)
  }
  GetCashierDailyById(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('CashierDaily/GetCashierDailyById', body)
  }
  OpenDay(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('CashierDaily/OpenDay', body)
  }
  CloseDay(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('CashierDaily/CloseDay', body)
  }
  CheckIfDailyInvoicesSyncedToZatca(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Transactions/CheckIfDailyInvoicesSyncedToZatca', body)
  }
  ReportFailedInvoicesToZatca(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Transactions/ReportFailedInvoicesToZatca', body)
  }
  EditDayDate(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('CashierDaily/EditDayDate', body);
  }
  ReOpenDay(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('CashierDaily/ReOpenDay', body);
  }


  //*============ CashierShifts =========================================*/
  GetCashierShifts(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('CashierShifts/Get', body)
  }
  OpenShift(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('CashierShifts/OpenShift', body)
  }
  CloseShift(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('CashierShifts/CloseShift', body)
  }
  ChangeShiftPaymentDevice(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('CashierShifts/ChangeShiftPaymentDevice', body)
  }
  EditCloseShift(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('CashierShifts/EditCloseShift', body)
  }
  UpdateChargingAmount(body): Observable<ExecutionResponse<number>> {
    return this.httpBaseService.Post('CashierShifts/UpdateChargingAmount', body);
  }
  GetCurrentShift(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('CashierShifts/GetCurrentShift', body)
  }
  GetCashierMainData(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('CashierMainData/Get', body)
  }



  GetCloseShiftRequests(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('CashierShifts/GetCloseShiftRequests', body);
  }


  OpenShiftValidation(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('CashierDailyAndShiftValidation/OpenShiftValidation', body);
  }

  CloseShiftValidation(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('CashierDailyAndShiftValidation/CloseShiftValidation', body);
  }

  MakeTransactionValidation(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('CashierDailyAndShiftValidation/MakeTransactionValidation', body);
  }



  ClientDownPayment(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('ClientDownPayment/Get', body);
  }

  GetDiscountType(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('DiscountType/Get', body);
  }

  getByIdBookings(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Booking/getById', body)
  }
  Getuser(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('user/Get', body)
  }
  /*========= AccountCurrency  ================================================*/
  GetAccountCurrency(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('AccountCurrency/Get', body)
  }
  /*========= GetCategoryProduct  ================================================*/
  GetCategory(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Category/Get', body)
  }
  /*========= GetCategoryProduct  ================================================*/
  GetProduct(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('RetailProduct/GetProduct', body)
  }
  /*=================================================================*/
  GetGlameraServices(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Category/GlameraServices', body)
  }
  /*=================================================================*/
  GetDayAndShiftSales(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('DaysAndShiftsTransactions/GetDayAndShiftSales', body)
  }
  /*=================================================================*/
  GetDetailedService(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Service/GetDetailedService', body)
  }
  /*=================================================================*/
  GetDailyShitSaleRefund(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Transactions/GetDailyShitSaleRefund', body)
  }
  /*=================================================================*/
  GetDailySaleRefund(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Transactions/GetDailySaleRefund', body)
  }
  /*=================================================================*/
  GetBasicServices(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Service/GetBasicServices', body)
  }
  /*=================================================================*/
  GetEmployeeTimeSlots(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('v2/BookingService/GetEmployeeTimeSlots', body)
  }
  /*=================================================================*/
  CheckPromocodeValidation(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('v2/OffersSetting/CheckPromocodeValidation', body)
  }
  GetVouchersBranch() {
    return this.http.get(`${this.sharedService.ApiUrl}v2/vouchers/Branch`, this.sharedService.getHeaders());
  }
  /*=================================================================*/
  Getpackages() {
    return this.http.get(`${this.sharedService.ApiUrl}v2/packages`, this.sharedService.getHeaders());
  }
  /*=================================================================*/
  Getgiftcards() {
    return this.http.get(`${this.sharedService.ApiUrl}v2/GiftCards/Company?Status=1`, this.sharedService.getHeaders());
    // return this.http.get('https://apiios.glamour-plan.com/api/v2/giftcards', this.sharedService.getHeaders());
  }
  /*=================================================================*/
  GetPromoCodesBranch() {
    return this.http.get(`${this.sharedService.ApiUrl}v2/PromoCodes/Branch?ClientId=11`, this.sharedService.getHeaders());
  }
  /*=================================================================*/
  // GetPackageById(body): Observable<ExecutionResponse<any>> {
  //   return this.httpBaseService.Post('OffersSetting/GetPackageById', body)
  // }
}

