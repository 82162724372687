
<div class="modal-header">
  <h5 class="modal-title text-capitalize" id="exampleModalLabel">
    <i class="fas fa-dolly-flatbed fa-beat"></i> &nbsp; {{ modalTitle | translate }} {{modalTitleForServiceName}}
  </h5>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-lg-12">

      <h6 class="text-success">
        {{ serviceConsumablesWithdrawStock }}
      </h6>

      <h6 class="text-info" *ngIf="serviceConsumablesWithdrawStock && HaveConsumableQuantityForExpirationDates">
        {{'Note-BalanceWillWithdrawnAccordingToExpirationDateFromOlderToNewer' | translate}}
      </h6>

      <br>
    </div>
  </div>

  <div class="row">

    <div class="col-lg-12" *ngIf="ServiceConsumablesList ">
      <div class="table-card">
        <table class="table table-stripedd table-sm table-bordered">
          <thead class="table-head">
            <tr>
              <th style="width: 40%;" class="text-center">{{ 'ProductName' | translate }}</th>
              <th style="width: 20%;" class="text-center">{{ 'UnitName' | translate }}</th>
              <th style="width: 20%;" class="text-center">{{ 'RequiredQuantity' | translate }}</th>
              <th style="width: 20%;" class="text-center">{{ 'AvailableQuantity' | translate }}</th>

            </tr>
          </thead>
          <tbody class="text-center" style="font-size: 20px;font-weight: bolder;color: black;"
            *ngFor="let item of ServiceConsumablesList; let i = index">
            <tr>
              <td class="text-center">
                {{ item.ProductGlobalName }}
              </td>

              <td class="text-center">
                {{ item.UnitGlobalName }}
              </td>

              <td class="text-center">
                {{ item.Quantity }}
              </td>
              <td class="text-center" [ngClass]="{'text-danger': !item.ValidStockBalance }">
                {{ (item.ConsumableTotalStockBalance && item.ConsumableTotalStockBalance > 0
                ?item.ConsumableTotalStockBalance :
                ('NotFound' | translate) ) }}

                <span class="text-danger" *ngIf="!item.ConsumableTotalStockBalance">
                  &nbsp;&nbsp;
                  <i class="fa fa-info-circle" title="{{ 'NoBalanceInConsumableStock' | translate }}">
                  </i>
                </span>
                <span class="text-danger" *ngIf="item.ConsumableTotalStockBalance  &&
                ( item.ConsumableTotalStockBalance <= 0 || item.ConsumableTotalStockBalance < item.Quantity)">
                  &nbsp;&nbsp;
                  <i class="fa fa-info-circle" title="{{ 'NoEnoughBalanceInConsumableStock' | translate }}">
                  </i>
                </span>

              </td>

            </tr>
            <tr *ngIf="item.ConsumableQuantityForExpirationDates &&
            item.ConsumableQuantityForExpirationDates.length > 0">
              <td colspan="5">


                <button class="btn btn-collabse" type="button" (click)="ExpandLevelTwo(i)">
                  &nbsp;&nbsp;<i class="fas fa-chevron-down"></i> &nbsp;&nbsp;
                  &nbsp;&nbsp;{{ 'ConsumableQuantitiesForExpirationDates' | translate }}&nbsp;&nbsp;
                  ( {{item.ProductGlobalName}} )
                  &nbsp;&nbsp;<i class="fas fa-chevron-down"></i>&nbsp;&nbsp;
                </button>

                <ng-container *ngIf="ExpandRowLevelTwo==i">

                  <div class="row">
                    <div class="col-md-12" *ngIf="item.ConsumableQuantityForExpirationDates; let GiftCardInformations;">
                      <div class="table-card">
                        <table class="table table-stripedd table-sm table-bordered">
                          <thead class="table-head">
                            <tr>
                              <th class="text-center" style="width: 20%;">{{ 'Index' | translate }}</th>
                              <th class="text-center" style="width: 50%;">{{ 'ExpirationDate' | translate }}</th>
                              <th class="text-center" style="width: 30%;">{{ 'AvailableQuantity' | translate }}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="let consumableQuantityForExpirationDate of item.ConsumableQuantityForExpirationDates; let i = index">

                              <td class="text-center">
                                {{ i+1}}
                              </td>

                              <td class="text-center">
                                {{ consumableQuantityForExpirationDate.ExpirationDate | date:'yyyy-MM-dd'}}
                              </td>

                              <td class="text-center">
                                {{ consumableQuantityForExpirationDate.AvailableQuantity }}
                              </td>

                            </tr>
                          </tbody>
                        </table>
                        <div class="text-center py-4 my-4"
                          *ngIf="item.ConsumableQuantityForExpirationDates.length == 0">
                          <h4 class="py-4">{{'NoDataFound' | translate}}</h4>
                        </div>
                      </div>
                    </div>
                  </div>

                </ng-container>

              </td>
            </tr>

          </tbody>
        </table>
        <div class="text-center py-4 my-4" *ngIf="ServiceConsumablesList.length == 0">
          <h4 class="py-4">{{'NoDataFound' | translate}}</h4>
        </div>
      </div>
    </div>


    <div class="col-lg-12" *ngIf="!ServiceConsumablesList ">
      <div class="col-md-12 text-center py-4 my-4">
        <i class="fas fa-circle-notch fa-spin fa-3x"></i>
        <h1>{{'Loading' | translate}} </h1>
      </div>
    </div>

  </div>

</div>
<div class="modal-footer">



  <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">
    {{'Close' | translate}}
  </button>

</div>
