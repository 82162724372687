import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Transaction2Service } from '../../transaction/service/transaction.service';
import { ResponseState } from '../../../models/support/response-state';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SharedService } from '../../../services/other/shared.service';

@Component({
  selector: 'app-transaction-header',
  templateUrl: './transaction-header.component.html',
  styleUrls: ['./transaction-header.component.scss']
})
export class TransactionHeaderComponent implements OnInit {

  @Input() THForm: UntypedFormGroup;

  @Output() TransactionHeaderData = new EventEmitter();
  constructor(private transactionService: Transaction2Service,
    public sharedService: SharedService,
  ) {

  }
  ngOnInit(): void {
    this.GetTransactionHeader();
    this.GetCashierMainData();
  }
  /*======== TransactionHeader=========================================================*/
  TransactionHeader;
  GetTransactionHeader() {
    let jsonData =
      { EmployeeId: this.sharedService.EmployeeId, AccountSetupId: this.sharedService.AccountSetupId }

    this.transactionService.GetTransactionHeader(jsonData).subscribe((response: any) => {
      if (response.State == ResponseState.Success) {
        this.TransactionHeader = response.Result;
        this.TransactionHeaderData.emit(this.TransactionHeader);
        // console.log(this.TransactionHeader)
        // console.log(this.TransactionHeader.EmployeeId)

        // this.THForm.patchValue({
        //   TransfereToCashierId: this.TransactionHeader.CashierId,
        //   EmployeeId: this.TransactionHeader.EmployeeId,
        // })

        // this.THForm.get('Transaction').patchValue({
        //   EnteredById: this.TransactionHeader.EmployeeId,
        //   CashierDailyId: this.TransactionHeader.CashierDailyId,
        //   CashierId: this.TransactionHeader.CashierId,
        // })

      }
    });
  }

  /*======== GetCashierMainData=========================================================*/
  Cashiers;
  GetCashierMainData() {
    let jsonData =
      { EmployeeId: this.sharedService.EmployeeId, AccountSetupId: this.sharedService.AccountSetupId }
    this.transactionService.GetCashierMainData(jsonData).subscribe((response: any) => {
      if (response.State == ResponseState.Success) {
        console.log(response)
        this.Cashiers = response.Cashiers;
      }
    });
  }

}
