import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
})
export class AlertsComponent {
  @Input()
  public isPricesWithTax: boolean = false;

  @Input()
  public haveTax: boolean = false;

  constructor(){
    console.log(this.haveTax, this.isPricesWithTax)
  }
}
