import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { ExecutionResponse } from '../../../models/support/execution-response';
import { SharedService } from '../../../services/other/shared.service';
import { HttpBaseService } from 'src/app/services/other/httpbase.service';
import { BaseServiceSZ } from 'src/app/services/other/baseService';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  constructor(
    private httpBaseService: HttpBaseService,
     private _BaseService: BaseServiceSZ,
    private http: HttpClient,
    private sharedService: SharedService
  ) {}

  CreateClients(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Client/Create', body);
  }
  UpdateClients(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Client/Update', body);
  }
  DeleteClients(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Client/delete', body);
  }

  GenerateFingerprintCode(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Client/GenerateFingerprintCode', body);
  }
  /*=================================BlackListClient================================*/
  GetBlackListClient(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('BlacklistedClient/Get', body);
  }
  CreateBlackList(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('BlacklistedClient/Create', body);
  }
  UpdateBlackList(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('BlacklistedClient/Update', body);
  }
  DeleteBlackList(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('BlacklistedClient/delete', body);
  }
  /*=================================================================*/
  GetEmployee(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Employee/Get', body);
  }
  GetDropdowns(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('SystemCodes/GetDropdowns', body);
  }

  /*=================================BlackListingReason================================*/
  GetBlackListingReason(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('BlackListingReason/Get', body);
  }
  CreateBlackListingReason(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('BlackListingReason/Create', body);
  }
  UpdateBlackListingReason(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('BlackListingReason/Update', body);
  }
  DeleteBlackListingReason(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('BlackListingReason/Delete', body);
  }
  /*======================= GetClientGroup =========================*/
  GetClientGroup(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('ClientGroup/Get', body);
  }
  CreateClientGroup(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('ClientGroup/Create', body);
  }
  UpdateClientGroup(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('ClientGroup/Update', body);
  }
  DeleteClientGroup(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('ClientGroup/Delete', body);
  }
  /*======================= GetClienNickName =========================*/
  GetClienNickName(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('ClienNickName/Get', body);
  }
  CreateClienNickName(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('ClienNickName/Create', body);
  }
  UpdateClienNickName(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('ClienNickName/Update', body);
  }
  DeleteClienNickName(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('ClienNickName/Delete', body);
  }
  /*=================================BlackListingReason================================*/
  GetClientAccountStatement(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('ClientAccountStatement/Get', body);
  }
  /*=================================GetClientsBalances================================*/
  GetClientsBalances(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('LoyalityPoints/GetPointsDashboard', body);
  }
  /*=================================MembershipPointsHistory================================*/
  MembershipPointsHistory(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post(
      'ClientPointsBalance/MembershipPointsHistory',
      body
    );
  }

  /*=================================================================*/
  public ExportClients() {
    return this.http
      .get(
        `${this.sharedService.ApiUrl}v2/Clients/Export?AccountSetupId=${this.sharedService.AccountSetupId}`,
        {
          headers: new HttpHeaders({
            Authorization: 'Bearer ' + this.sharedService.Token,
            'Content-Type': 'application/octet-stream',
          }),
          responseType: 'blob',
        }
      )
      .pipe(
        tap(
          (data) => console.log('You received data'),
          (error) => console.log(error)
        )
      );
  }
  downloadExcelFile(apiActionName){
    return this.http.get(this.sharedService.ApiUrl  + 'Client/' + apiActionName, {
   
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.sharedService.Token,
        'reportProgress': 'true',
        'observe':'events',
        'CurrentBranchId': this.sharedService.AccountSetupId.toString(),
    
        'Content-Type': 'application/octet-stream',
      }), responseType: 'blob',}).pipe(
      tap(
        data => console.log('You received data'),
        error => console.log(error)
      )
    );
}

   uploadExcel(body){

    return this.http.post<ExecutionResponse<boolean>>(this.sharedService.ApiUrl + 'Client/Upload', body,{
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.sharedService.Token,
        'reportProgress': 'true',
        'observe':'events',
        'CurrentBranchId': this.sharedService.AccountSetupId.toString(),
        'CompanyId':this.sharedService.CompanyId.toString()
      })})
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);

        }),

 
      );
}
  /*=================================BlackListingReason================================*/
  UpdateImage(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('client/UpdateImage', body);
  }

  PostImageFile(body) {
    return this.http.post(
      `${this.sharedService.ApiUrl}Upload/PostImageFile`,
      body
    );
  }
}
