import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { MessageService } from '../message/message.service';
import { SharedService } from '../other/shared.service';
import { UploadResult } from '../../models/search-result/upload-result-search-result';
import { ExecutionResponse } from '../../models/support/execution-response';

@Injectable({
  providedIn: 'root',
})
export class UploadService extends BaseService {
  private uploadUrl;

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private _BaseService: BaseServiceSZ,
    private sharedService: SharedService
  ) {
    super();
    this.uploadUrl = this.sharedService.UploadUrl + 'UploadFiles';
  }

  public header = new HttpHeaders();
  public binaryFile: any;

  public upload(
    file: File | File[],
    fileFolder: string
  ): Observable<ExecutionResponse<UploadResult>> {
    if (!file) {
      return of(null);
    }

    const formData = new FormData();
    formData.append('FileFolder', fileFolder);
    if (Array.isArray(file)) {
      file.forEach((f, index) => formData.append(`${index}`, f));
    } else {
      formData.append('file', file);
    }
    return this.http.post<ExecutionResponse<UploadResult>>(
      this.uploadUrl,
      formData,
      {
        observe: 'body',
      }
    );
  }
}
