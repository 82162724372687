import { Injectable } from '@angular/core';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../message/message.service';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { EquipmentOrRoom } from '../../models/classes/resource/equipment-or-room';
import { EquipmentOrRoomSearchCriteria } from '../../models/search-criterias/equipment-or-room-search-criteria';
import { EquipmentOrRoomSearchResult } from '../../models/search-result/equipment-or-room-search-result';
import { ExecutionResponse } from '../../models/support/execution-response';


@Injectable({ providedIn: 'root' })
export class EquipmentOrRoomService extends BaseService {
  private equipmentsOrRoomsUrl //= super.BaseUrl() + 'EquipmentOrRoom';  // URL to web api

  constructor(
    private http: HttpClient, private messageService: MessageService,
    private  _BaseService:BaseServiceSZ

  ) {
    super();
    this.equipmentsOrRoomsUrl =    this._BaseService.ApiUrl + 'EquipmentOrRoom';

  }

  // /** GET equipmentsOrRooms from the server */
  // getEquipmentsOrRooms(): Observable<EquipmentOrRoom[]> {
  //   return this.http.get<EquipmentOrRoom[]>(this.equipmentsOrRoomsUrl, super.getHeaders())
  //     .pipe(
  //       tap(equipmentsOrRooms => this._BaseService.log('fetched equipmentsOrRooms')),
  //       catchError(this._BaseService.handleError('getEquipmentsOrRooms', []))
  //     );
  // }

  // /** GET equipmentOrRoom by id. Return 'undefined' when id not found */
  // getEquipmentOrRoomNo404<Data>(id: number): Observable<EquipmentOrRoom> {
  //   const url = '${this.equipmentsOrRoomsUrl}/?id=${id}';
  //   return this.http.get<EquipmentOrRoom[]>(url)
  //     .pipe(
  //       map(equipmentsOrRooms => equipmentsOrRooms[0]), // returns a {0|1} element array
  //       tap(h => {
  //         const outcome = h ? 'fetched' : 'did not find';
  //         this._BaseService.log('${outcome} equipmentOrRoom id=${id}');
  //       }),
  //       catchError(this._BaseService.handleError<EquipmentOrRoom>('getEquipmentOrRoom id=${id}'))
  //     );
  // }

  /** GET equipmentOrRoom by id. Will 404 if id not found */
  getEquipmentOrRoom(id: String): Observable<ExecutionResponse<EquipmentOrRoom>> {
    //  getEquipmentOrRoom(id: String): ExecutionResponse<EquipmentOrRoom> {
    //const url = '${this.equipmentsOrRoomsUrl}/${id}';
    return this.http.post<ExecutionResponse<EquipmentOrRoom>>(this.equipmentsOrRoomsUrl + '/getById', id, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched equipmentOrRoom id=${id}');
          this._BaseService.ValidationResult(res);
      }),
       
        catchError(this._BaseService.handleError<ExecutionResponse<EquipmentOrRoom>>('getEquipmentOrRoom id=${id}'))
      );
  }


  addEquipmentOrRoom(equipmentOrRoom: EquipmentOrRoom): Observable<ExecutionResponse<EquipmentOrRoom>> {
    equipmentOrRoom.AccountSetupId = super.getAccountSetupId();
    return this.http.post<ExecutionResponse<EquipmentOrRoom>>(this.equipmentsOrRoomsUrl + '/Create', equipmentOrRoom, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('added equipmentOrRoom w/ id=${equipmentOrRoom.Id}');
          this._BaseService.ValidationResult(res);
      }),
       
        catchError(this._BaseService.handleError<ExecutionResponse<EquipmentOrRoom>>('addEquipmentOrRoom id=${id}'))
      );
  }


  /* GET equipmentsOrRooms whose name contains search term */
  searchEquipmentsOrRooms(equipmentOrRoomSearchCriteria: EquipmentOrRoomSearchCriteria): Observable<EquipmentOrRoomSearchResult> {
    equipmentOrRoomSearchCriteria.AccountSetupId = super.getAccountSetupId();
    return this.http.post<EquipmentOrRoomSearchResult>(this.equipmentsOrRoomsUrl + '/Get', equipmentOrRoomSearchCriteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched equipmentsOrRooms');
          this._BaseService.ValidationResult(res);
      }),
        
        catchError(this._BaseService.handleError<EquipmentOrRoomSearchResult>('searchEquipmentsOrRooms id=${id}'))
      );
  }

  //////// Save methods //////////



  // /** POST: add a new equipmentOrRoom to the server */
  // addEquipmentOrRoom (equipmentOrRoom: EquipmentOrRoom)/*: Observable<EquipmentOrRoom> */{
  //   this.http.post<EquipmentOrRoom>(this.equipmentsOrRoomsUrl + '/Create', equipmentOrRoom).subscribe(result => {
  //     //this.equipmentOrRoom = new EquipmentOrRoom();
  //     alert("Save Succussfully");
  //   }, error => {
  //   });
  //   //return this.http.post<EquipmentOrRoom>(this.equipmentsOrRoomsUrl + '/post', equipmentOrRoom, super.getHeaders()).pipe(
  //   //  tap((equipmentOrRoom: EquipmentOrRoom) => this._BaseService.log('added equipmentOrRoom w/ id=${equipmentOrRoom.Id}')),
  //   //  catchError(this._BaseService.handleError<EquipmentOrRoom>('addEquipmentOrRoom'))
  //   //);
  // }

  /** DELETE: delete the equipmentOrRoom from the server */
  deleteEquipmentOrRoom(equipmentOrRoom: EquipmentOrRoom | number): Observable<ExecutionResponse<EquipmentOrRoom>> {
    return this.http.post<ExecutionResponse<EquipmentOrRoom>>(this.equipmentsOrRoomsUrl + '/delete', equipmentOrRoom, super.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deleted equipmentOrRoom id=${id}');
        this._BaseService.ValidationResult(res);
    }),
      
      catchError(this._BaseService.handleError<ExecutionResponse<EquipmentOrRoom>>('deleteEquipmentOrRoom'))
    );
  }

  /** PUT: update the equipmentOrRoom on the server */
  updateEquipmentOrRoom(equipmentOrRoom: EquipmentOrRoom): Observable<ExecutionResponse<EquipmentOrRoom>> {
    equipmentOrRoom.AccountSetupId = super.getAccountSetupId();
    return this.http.post<ExecutionResponse<EquipmentOrRoom>>(this.equipmentsOrRoomsUrl + '/Update', equipmentOrRoom, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('added equipmentOrRoom w/ id=${equipmentOrRoom.Id}');
          this._BaseService.ValidationResult(res);

        }),
        catchError(this._BaseService.handleError<ExecutionResponse<EquipmentOrRoom>>('addEquipmentOrRoom id=${id}'))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */

}