export const OfferTypes = [
    { id: 1, value: "giftCard", valueEn: "Gift Card", valueAr: "كارت الهدية", showInCashSale: true },
    { id: 2, value: "voucher", valueEn: "Voucher", valueAr: "قسيمة الشراء ", showInCashSale: true },
    { id: 3, value: "offer", valueEn: "Offer", valueAr: "عرض ", showInCashSale: false },
    { id: 3, value: "bngm", valueEn: "Buy n get m", valueAr: "اشتري n و خد m ", showInCashSale: false, offerSubType:3 },
    { id: 4, value: "package", valueEn: "Package", valueAr: "باقة ", showInCashSale: true },
    { id: 5, value: "promoCode", valueEn: "Promocode", valueAr: "بروموكود ", showInCashSale: false },
    { id: 6, value: "prepaid_Package", valueEn: "Prepaid Package", valueAr: "المدفوعة مسبقا", showInCashSale: false }



]

export const InOutOfferNature = [
    { id: 1, valueEn: "In", valueAr: " تشمل " },
    { id: 3, valueEn: "Out", valueAr: "لا تشمل " }
]
export const DiscountType = [
    { id: 1, valueEn: "Value", valueAr: " قيمة " },
    { id: 2, valueEn: "Percentage", valueAr: "نسبة مئوية " }
]
export const IsMandatory = [
    { id: 1, valueEn: "Yes", valueAr: " نعم " },
    { id: 0, valueEn: "No", valueAr: "  لا" }
]


export const LOYALTY_POINT_SYSTEM = [
    { Id: 1, TranslateKey: "HaveExceptionalPoints", valueEn: " Have exceptional  points ", valueAr: " Have exceptional  points " },
    // { Id: 2, TranslateKey: "CaculatedOnGeneralSetting", valueEn: " Caculated on general setting ", valueAr: " Caculated on general setting" },
    { Id: 3, TranslateKey: "GiveZeroPoints", valueEn: " Give  Zero Points ", valueAr: " Give  Zero Points " }
]


