<div class="cardPagin">
  <div class="row">
    <div class="col-md-3">
      <form role="form" class="form-inline">
        <div class="form-group mx-2 d-none">
          <select class="form-control" (change)="PageSizeClick()" [(ngModel)]="ddlPageSize" name="ddlPageSize">
            <option value="{{item}}" *ngFor="let item of pageSizeArr">{{item}}</option>
          </select>
        </div>
      </form>
    </div>
    <div class="col-md-12">
      <nav aria-label="Page navigation example">
        <ul class="pagination pg-blue" *ngIf="pager.pages && pager.pages.length" class="pagination">
          <!-- First Page Button -->
          <li class="page-item" [ngClass]="{disabled: pager.currentPage === 0}">
            <a class="page-link" (click)="pager.currentPage > 0 && pagingClickBtn(0)">
              {{ 'First' | translate }}
            </a>
          </li>
          
          <!-- Previous Page Button -->
          <li class="page-item" [ngClass]="{disabled: pager.currentPage === 0}">
            <a class="page-link" (click)="pager.currentPage > 0 && pagingClickBtn(pager.currentPage - 1)">
              {{ 'Previous' | translate }}
            </a>
          </li>

          <!-- Page Numbers -->
          <li class="page-item" *ngFor="let page of pager.pages" [ngClass]="{active: pager.currentPage === page}">
            <a class="page-link" (click)="pagingClickBtn(page)">{{ page + 1 }}</a>
          </li>

          <!-- Next Page Button -->
          <li class="page-item" [ngClass]="{disabled: pager.currentPage >= pager.totalPages - 1}">
            <a class="page-link" (click)="pager.currentPage < pager.totalPages - 1 && pagingClickBtn(pager.currentPage + 1)">
              {{ 'Next' | translate }}
            </a>
          </li>

          <!-- Last Page Button -->
          <li class="page-item" [ngClass]="{disabled: pager.currentPage >= pager.totalPages - 1}">
            <a class="page-link" (click)="pager.currentPage < pager.totalPages - 1 && pagingClickBtn(pager.totalPages - 1)">
              {{ 'Last' | translate }}
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>
