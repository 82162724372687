import { Component } from '@angular/core';

@Component({
  selector: 'app-pure-privacy-policy',
  templateUrl: './pure-privacy-policy.component.html',
  styleUrls: ['./pure-privacy-policy.component.scss']
})
export class PurePrivacyPolicyComponent {

}
