import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap, delay } from 'rxjs/operators';
import { MessageService } from '../message/message.service';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { SharedService } from '../other/shared.service';
import { HttpClient } from '@angular/common/http';
import { ExecutionResponse } from '../../models/support/execution-response';
import { GymReservationDTO } from '../../models/classes/gym/gym-reservation-dto';

@Injectable({
  providedIn: 'root',
})
export class GymClientMembershipClassAppointmentService extends BaseService {
  isAlterEgoTaken(alterEgo: string): Observable<boolean> {
    const isTaken = false; //alterEgo.includes(alterEgo);
    return of(isTaken).pipe(delay(400));
  }
  private gymClientMembershipAppointmentUrl; // = super.BaseUrl() + 'Client';  // URL to web api

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private _BaseService: BaseServiceSZ,
    private sharedService: SharedService
  ) {
    super();
    this.gymClientMembershipAppointmentUrl =
      this.sharedService.ApiUrlV2 + 'GymClientMembershipClassAppointment';
  }
  getApiUrl() {
    return this._BaseService.ApiUrl;
  }

  SaveGymClientMembershipClassAppointment(
    gymReservationDTO: GymReservationDTO
  ): Observable<ExecutionResponse<boolean>> {
    return this.http
      .post<ExecutionResponse<boolean>>(
        this.gymClientMembershipAppointmentUrl + '/Save',
        gymReservationDTO,
        this.sharedService.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('Save Gym Client Membership Appointment');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<ExecutionResponse<boolean>>(
            'Save Gym Client Membership Appointment'
          )
        )
      );
  }
}
