<div class="file-upload" fxLayout="row" fxLayoutAlign="start center">
  <label for="file-input" class="custom-file-upload">
    <img
      *ngIf="!imageUrl"
      src="/assets/img/upload_img.svg"
      alt=""
      title="Upload"
      class="default-img"
    />
    <img [src]="imageUrl" alt="Uploaded Image" *ngIf="imageUrl" />
  </label>
  <input
    id="file-input"
    type="file"
    (change)="onFileSelected($event)"
    #fileInput
  />
  <main fxLayout="column" fxLayoutAlign="center start">
    <p>{{ "Business_Logo" | translate }}</p>
    <span>{{ "max_size" | translate }}</span>
    <a class="link" (click)="onOpenFile()">{{
      "Click_to_upload_logo" | translate
    }}</a>
  </main>
</div>
