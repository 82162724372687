import { MasterCode } from './master-code';
import { AccountSetup } from '../account-setup/account-setup';

export class DetailCode {
  public Id: number;
  public Code: string;
  public Name: string;
  public GlobalName: string;
  public NameCultureVariant: string;
  public IsActive: boolean;
  public IsDeleted: boolean;
  public ParentId: number;
  public FieldOneValue: string;
  public FieldTwoValue: string;
  public FieldThreeValue: string;
  public CreatedBy: string;
  public CreatedOn: string;
  public UpdatedBy: string;
  public UpdatedOn: string;
  public MasterCodeId: number;
  public MasterCode: MasterCode;
  public ParentDetailCode: DetailCode;
  public ChildDetailCodes: DetailCode[];
  publicAccountSetupsForCountry: AccountSetup[];
  publicAccountSetupsForCulture: AccountSetup[];
}

export class DropdownsDTO {
  Countries: DropDowDTO[];
  Cities: DropDowDTO[];
  Areas: DropDowDTO[];
  Classifications: DropDowDTO[];
  Currencies: DropDowDTO[];
  Brands: DropDowDTO[];
  BlackListReasons: DropDowDTO[];
}

export class DropDowDTO {
  dbId: string;
  id: string;
  cid: string;
  na: string;
}

export const MASTER_CODES = {
  Countries: 4,
  Cities: 9,
  Areas: 10,
  Currencies: 6,
  Classifications: 11,
  Brands: 37,
  BlackListReasons: 40,
};
