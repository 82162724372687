import { SearchCriteria } from "./search-criteria";

export class ServiceConsumablesSearchCriteria  extends SearchCriteria {
    constructor() {
        super();
    }

    public Id:number;
    public ServiceId:number;
    public ForCashSale:boolean;
}
