<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel"> <i class="fas fa-ban"></i> &nbsp;
      {{'BannedClient' | translate}}
    </h5>
    <button type="button" class="close" (click)="activeModal.close('Close click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-card">

      <div class="row">
        <div class="col-lg-12">
          <div class="formCard">
            <span style="font-size:20px;color:black;font-weight:900;">
              {{'SelectedClientIsBanned.DoYouWantToNotUseIt' | translate}}
            </span>

          </div>
        </div>
        <div class="col-lg-12">
          <div class="formCard">
            <table class="table table-sm">
              <tr>
                <td>{{ "ClientName" | translate }}</td>
                <td>
                  {{ClientName}}
                </td>
              </tr>
            </table>
          </div>

        </div>

      </div>

      <div class="row">
        <div class="col-lg-6">
          <button class="btn btn-modal-sure" type="button" (click)="HandleRemoveClient()">
            <span> {{ 'NotUseClient' | translate }}</span>
          </button>
        </div>
        <div class="col-lg-6">
          <button class="btn btn-modal-close" (click)="activeModal.close('Close click')">{{'Close' | translate
            }}</button>
        </div>
      </div>


    </div>
  </div>
</div>
