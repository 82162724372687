import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap, delay } from 'rxjs/operators';
import { MessageService } from '../message/message.service';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { SharedService } from '../other/shared.service';
import { HttpClient } from '@angular/common/http';
import { CreateMultiGymClientMembershipFrozenDaysLogDTO } from '../../models/classes/gym/create-multi-gym-client-membership-frozen-days-log-dto';
import { GymClientMembershipFrozenDayLogMultiDeleteModel } from '../../models/classes/gym/gym-client-membership-frozen-day-log-multi-delete-model';
import { GymClientMembershipFrozenDaysLogsSearchCriteria } from '../../models/search-criterias/gym-client-membership-frozen-days-log-search-criteria';
import { GymClientMembershipFrozenDaysLogSearchResult } from '../../models/search-result/gym-client-membership-frozen-days-log-search-result';
import { ExecutionResponse } from '../../models/support/execution-response';
import { GymClientMembershipFrozenDaysLogLiteSearchResult } from '../../models/search-result/gym-client-membership-frozen-days-log-lite-search-result';
import { GymClientMembershipFrozenDaysLog } from 'src/app/models/classes/gym/gym-client-membership-frozen-days-log';


@Injectable({
  providedIn: 'root',
})
export class GymClientMembershipFrozenDaysLogServiceService extends BaseService {
  isAlterEgoTaken(alterEgo: string): Observable<boolean> {
    const isTaken = false; //alterEgo.includes(alterEgo);
    return of(isTaken).pipe(delay(400));
  }
  private clientGymFrozenMembershipUrl; // = super.BaseUrl() + 'Client';  // URL to web api

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private _BaseService: BaseServiceSZ,
    private sharedService: SharedService
  ) {
    super();

    this.clientGymFrozenMembershipUrl =
      this.sharedService.ApiUrl + 'GymClientMembershipFrozenDaysLog';
  }
  getApiUrl() {
    return this._BaseService.ApiUrl;
  }


  GetGymClientMembershipFrozenDayLogInfo(id: number): Observable<ExecutionResponse<GymClientMembershipFrozenDaysLog>> {

    return this.http.post<ExecutionResponse<GymClientMembershipFrozenDaysLog>>(this.clientGymFrozenMembershipUrl + '/GetById', id, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('Fetched Client Gym Frozen Memberships');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<GymClientMembershipFrozenDaysLog>>('Fetched Client Gym Frozen Memberships id=${id}'))
      );
  }

  GetGymClientMembershipFrozenDaysLogs(
    gymClientMembershipFrozenDaysLogSearchCriteria: GymClientMembershipFrozenDaysLogsSearchCriteria
  ): Observable<GymClientMembershipFrozenDaysLogSearchResult> {
    return this.http
      .post<GymClientMembershipFrozenDaysLogSearchResult>(
        this.clientGymFrozenMembershipUrl + '/Get',
        gymClientMembershipFrozenDaysLogSearchCriteria,
        this.sharedService.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('Fetched Client Gym Frozen Memberships');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<GymClientMembershipFrozenDaysLogSearchResult>(
            'Fetched Client Gym Frozen Memberships id=${id}'
          )
        )
      );
  }

  GetGymClientMembershipFrozenDaysLogsLite(
    gymClientMembershipFrozenDaysLogSearchCriteria: GymClientMembershipFrozenDaysLogsSearchCriteria
  ): Observable<GymClientMembershipFrozenDaysLogLiteSearchResult> {
    return this.http
      .post<GymClientMembershipFrozenDaysLogLiteSearchResult>(
        this.clientGymFrozenMembershipUrl + '/GetLite',
        gymClientMembershipFrozenDaysLogSearchCriteria,
        this.sharedService.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('Fetched Client Gym Frozen Memberships Lite');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<GymClientMembershipFrozenDaysLogLiteSearchResult>(
            'Fetched Client Gym Frozen Memberships id=${id}'
          )
        )
      );
  }

  CreateGymClientMembershipFrozenDaysLog(
    createGymClientMembershipFrozenDaysLog: any
  ): Observable<ExecutionResponse<Boolean>> {
    return this.http
      .post<ExecutionResponse<Boolean>>(
        this.clientGymFrozenMembershipUrl +
        '/CreateGymClientMembershipFrozenDaysLog',
        createGymClientMembershipFrozenDaysLog,
        this.sharedService.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('Fetched Client Gym Frozen Memberships');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<ExecutionResponse<Boolean>>(
            'Fetched Client Gym Frozen Memberships id=${id}'
          )
        )
      );
  }

  CreateMultiGymClientMembershipFrozenDaysLog(
    createMultiGymClientMembershipFrozenDaysLogDTO: CreateMultiGymClientMembershipFrozenDaysLogDTO
  ): Observable<ExecutionResponse<Boolean>> {
    return this.http
      .post<ExecutionResponse<Boolean>>(
        this.clientGymFrozenMembershipUrl +
        '/CreateMultiGymClientMembershipFrozenDaysLog',
        createMultiGymClientMembershipFrozenDaysLogDTO,
        this.sharedService.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('Fetched Client Gym Frozen Memberships');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<ExecutionResponse<Boolean>>(
            'Fetched Client Gym Frozen Memberships id=${id}'
          )
        )
      );
  }

  UpdateGymClientMembershipFrozenDaysLog(
    createAndUpdateGymClientMembershipFrozenDaysLog: any
  ): Observable<ExecutionResponse<Boolean>> {
    return this.http
      .post<ExecutionResponse<Boolean>>(
        this.clientGymFrozenMembershipUrl +
        '/UpdateGymClientMembershipFrozenDaysLog',
        createAndUpdateGymClientMembershipFrozenDaysLog,
        this.sharedService.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('Update Client Gym Frozen Memberships');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<ExecutionResponse<Boolean>>(
            'Update Client Gym Frozen Memberships id=${id}'
          )
        )
      );
  }

  DeleteGymClientMembershipFrozenDaysLog(
    gymClientMembershipFrozenDaysLogId: number
  ): Observable<ExecutionResponse<boolean>> {
    return this.http
      .post<ExecutionResponse<boolean>>(
        this.clientGymFrozenMembershipUrl + '/Delete',
        gymClientMembershipFrozenDaysLogId,
        this.sharedService.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log(
            'Deleted Gym Client Membership Frozen Days Log'
          );
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<ExecutionResponse<boolean>>(
            'Deleted Gym Client Membership Frozen Days Log id=${id}'
          )
        )
      );
  }

  MultiDelete(
    gymClientMembershipFrozenDayLogMultiDeleteModel: GymClientMembershipFrozenDayLogMultiDeleteModel
  ): Observable<ExecutionResponse<boolean>> {
    return this.http
      .post<ExecutionResponse<boolean>>(
        this.clientGymFrozenMembershipUrl + '/MultiDelete',
        gymClientMembershipFrozenDayLogMultiDeleteModel,
        this.sharedService.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log(
            'Multi Delete Gym Client Membership Frozen Days Log'
          );
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<ExecutionResponse<boolean>>(
            'Multi Delete Gym Client Membership Frozen Days Log id=${id}'
          )
        )
      );
  }
}
