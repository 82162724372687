import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ClientBalance } from '../../models/classes/balances/client-balance';
import { ClientBalanceDetail } from '../../models/classes/balances/client-balance-detail';
import { ClientBalanceStatus } from '../../models/enums/client-balance-status';
import { ClientBalanceTransactionDirection } from '../../models/enums/client-balance-transaction-direction';
import { ClientBalanceTransactionType } from '../../models/enums/client-balance-transaction-type';
import { ClientBalanceDetailSearchCriteria } from '../../models/search-criterias/client-balance-detail-search-criteria';
import { ClientBalanceDetailSearchResult } from '../../models/search-result/client-balance-detail-search-result';
import { ResponseState } from '../../models/support/response-state';
import { ClientBalanceDetailService } from '../../services/balances/client-balance-detail.service';
import { ClientBalanceService } from '../../services/balances/client-balance.service';
import { SharedService } from '../../services/other/shared.service';
import { TransactionsSecondService } from '../../services/transaction/transactions-second.service';
import { PaginationComponent } from '../pagination/pagination.component';



@Component({
  selector: 'app-modal-client-balance-details',
  templateUrl: './modal-client-balance-details.component.html',
  styleUrls: ['./modal-client-balance-details.component.scss'],
  providers: [DatePipe]

})
export class ModalClientBalanceDetailsComponent implements OnInit {

  @ViewChild('pager', { static: true }) pager: PaginationComponent;

  @Input() ClientBalance: ClientBalance;
  public ClientBalanceDetails: ClientBalanceDetail[] = [];
  public Loading: boolean = false;
  public freeText: string;
  public ClientBalanceTransactionType: ClientBalanceTransactionType = -1;
  public ClientBalanceTransactionTypeToSearchWith: ClientBalanceTransactionType;

  public ClientBalanceTransactionDirection: ClientBalanceTransactionDirection = -1;;
  public ClientBalanceTransactionDirectionToSearchWith: ClientBalanceTransactionDirection;

  constructor(public activeModal: NgbActiveModal,
    public translate: TranslateService,
    public sharedService: SharedService,
    public _transactionsSecondService: TransactionsSecondService,
    private fb: UntypedFormBuilder,
    public datepipe: DatePipe,
    public clientBalanceDetailService: ClientBalanceDetailService,
    public clientBalanceService: ClientBalanceService) { }

  ngOnInit(): void {
    this.pager.pageSize = 10;
    this.pager.ddlPageSize = 10;
    this.pager.GlobalPageIndex = 0;
    this.GetClientBalanceDetails();
  }


  divName
  printPage() {

    this.divName = 'printClientBalanceDetails';
    console.log(this.divName)

    let printContents = document.getElementById(this.divName).innerHTML;
    let originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
    // this.divName = null;
  }

  public get clientBalanceStatus(): typeof ClientBalanceStatus {
    return ClientBalanceStatus;
  }

  public get clientBalanceTransactionType(): typeof ClientBalanceTransactionType {
    return ClientBalanceTransactionType;
  }

  public get clientBalanceTransactionDirection(): typeof ClientBalanceTransactionDirection {
    return ClientBalanceTransactionDirection;
  }


  GetClientBalanceDetails() {
    this.Loading = true;
    var criteria = new ClientBalanceDetailSearchCriteria();

    criteria.FreeText = this.freeText;
    criteria.ClientId = this.ClientBalance.ClientId;
    criteria.ClientBalanceId = this.ClientBalance.Id;
    criteria.PageSize = this.pager.pageSize;
    criteria.PagingEnabled = true;
    criteria.PageNumber = this.pager.GlobalPageIndex;
    criteria.ClientBalanceTransactionType =
      this.ClientBalanceTransactionTypeToSearchWith;
    criteria.ClientBalanceTransactionDirection =
      this.ClientBalanceTransactionDirectionToSearchWith;

    this.clientBalanceDetailService
      .searchClientBalanceDetails(criteria)
      .subscribe((response: ClientBalanceDetailSearchResult) => {
        this.Loading = false;
        if (response.State == ResponseState.Success) {
          this.ClientBalanceDetails = response.ClientBalanceDetails;
          this.pager.EventsCount = response.TotalCount;
          this.pager.setPage(this.pager.GlobalPageIndex, false);
        }

      });
  }

  ReciveFreeText(e) {
    console.log(e)
    this.freeText = e
    this.GetClientBalanceDetails();
  }

  filterByClientBalanceTransactionType(e) {
    if (!e.target.value || e.target.value == '' || e.target.value == '-1' || e.target.value.length <= 0) {
      this.ClientBalanceTransactionTypeToSearchWith = null;
    } else {
      this.ClientBalanceTransactionTypeToSearchWith =
        Number(e.target.value);
    }

    this.GetClientBalanceDetails();
  }

  filterByClientBalanceTransactionDirection(e) {
    if (!e.target.value || e.target.value == '' || e.target.value == '-1' || e.target.value.length <= 0) {
      this.ClientBalanceTransactionDirectionToSearchWith = null;
    } else {
      this.ClientBalanceTransactionDirectionToSearchWith =
        Number(e.target.value);
    }

    this.GetClientBalanceDetails();
  }



}
