<div id="printClientGiftCardInfo">
  <div class="modal-header">
    <h5 class="modal-title text-capitalize" id="exampleModalLabel">
      <i class="fas fa-info-circle"></i> &nbsp; {{'ClientGiftCardInfo' | translate}}
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12" *ngIf="GiftCardInformation ">
        <div class="text-start py-4">

          <div class="row">
            <div class="col-lg-12">
              <div class="formCard">
                <table class="table table-sm">

                  <tr>
                    <td>{{ 'GiftCardName' | translate }}</td>
                    <td>
                      {{ sharedService.Lang == 'ar' ? GiftCardInformation.GiftCardNameAr :
                      GiftCardInformation.GiftCardNameEn}}
                    </td>
                  </tr>
                  <tr>
                  <tr>
                    <td>{{ 'GiftCardCode' | translate }}</td>
                    <td>
                      <span style="color: #009439;font-size: 15px;font-weight: 900;">
                        {{ GiftCardInformation.GiftCardCode }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ 'SaleBranch' | translate }}</td>
                    <td>
                      {{ GiftCardInformation.SaleBranchGlobalName}}
                    </td>
                  </tr>
                  <tr *ngIf="GiftCardInformation.GiftCardSaleBillCode">
                    <td>{{ 'GiftCardSaleBillCode' | translate }}</td>
                    <td>
                      {{ GiftCardInformation.GiftCardSaleBillCode}}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ 'ClientOrDistributorName' | translate }}</td>
                    <td>
                      {{ GiftCardInformation.ClientOrDistributorName }}
                    </td>
                  </tr>

                  <tr>
                    <td>{{ 'ClientMobile' | translate }}</td>
                    <td>
                      {{ GiftCardInformation.ClientMobile }}
                    </td>
                  </tr>

                  <tr>
                    <td>{{ 'GiftCardTotalCost' | translate }}</td>
                    <td>
                      {{ GiftCardInformation.GiftCardTotalCost.toFixed(sharedService.GetFractionRoundCount) }}

                      <span class="text-black" style="font-weight: bolder;font-size: small; color: black;">
                        ({{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr :
                        sharedService.CurrencyNameEn}} )
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td>{{ 'GiftCardBalance' | translate }}</td>
                    <td class="">
                      {{ GiftCardInformation.GiftCardBalance.toFixed(sharedService.GetFractionRoundCount) }}
                      <span class="text-black" style="font-weight: bolder;font-size: small; color: black;">
                        ({{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr :
                        sharedService.CurrencyNameEn}} )
                      </span>
                    </td>
                  </tr>


                  <tr>
                    <td>{{ 'GiftCardUsedBalance' | translate }}</td>
                    <td class="">
                      {{ GiftCardInformation.GiftCardUsedBalance.toFixed(sharedService.GetFractionRoundCount) }}
                      <span class="text-black" style="font-weight: bolder;font-size: small; color: black;">
                        ({{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr :
                        sharedService.CurrencyNameEn}} )
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td>{{ 'GiftCardRemainingBalance' | translate }}</td>
                    <td class="">
                      {{ GiftCardInformation.GiftCardRemainingBalance.toFixed(sharedService.GetFractionRoundCount) }}
                      <span class="text-black" style="font-weight: bolder;font-size: small; color: black;">
                        ({{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr :
                        sharedService.CurrencyNameEn}} )
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ 'GiftCardStatus' | translate }}</td>
                    <td style="font-weight: bold;" [ngClass]="{'text-info': GiftCardInformation.GiftCardPeriodStatus === periodStatus.NotStarted,
                     'text-success' : GiftCardInformation.GiftCardPeriodStatus === periodStatus.OnGoing ,
                     'text-danger' : GiftCardInformation.GiftCardPeriodStatus === periodStatus.Finished }">
                      {{ ('TheClientGiftCard' +
                      periodStatus[GiftCardInformation.GiftCardPeriodStatus]) | translate }}</td>
                  </tr>


                  <tr>
                    <td>{{ 'GiftCardSource' | translate }}</td>
                    <td class="">
                      {{GiftCardInformation.ClientGiftCardId ? ('ASales' | translate ) :
                      ('ADistribution' | translate )}}
                    </td>
                  </tr>

                  <tr>
                    <td>{{ 'GiftCardType' | translate }}</td>
                    <td style="font-weight: bold;" [ngClass]="{'text-info': GiftCardInformation.GiftCardType==
                          giftCardType.Remaining,
                          'text-success' : GiftCardInformation.GiftCardType==
                          giftCardType.Sold }">
                      {{(GiftCardInformation.GiftCardType==
                      giftCardType.Remaining? 'Remaining' : 'Sold') | translate}}
                    </td>
                  </tr>


                  <tr>
                    <td>{{ 'SaleBranch' | translate }}</td>
                    <td>{{ GiftCardInformation.SaleBranchGlobalName}}</td>
                  </tr>


                  <tr>
                    <td>{{ 'LastBranchUsedInIt' | translate }}</td>
                    <td>
                      <span
                        *ngIf="GiftCardInformation.LastBranchUsedInGlobalName	 && GiftCardInformation.LastBranchUsedInGlobalName.length > 0">
                        {{GiftCardInformation.LastBranchUsedInGlobalName }}
                      </span>

                      <span style="color:#be1414;"
                        *ngIf="!GiftCardInformation.LastBranchUsedInGlobalName || GiftCardInformation.LastBranchUsedInGlobalName.length <= 0">
                        {{ 'NotUsedYet' | translate }}
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td>{{ 'FrequencyOfUse' | translate }}</td>
                    <td>
                      {{GiftCardInformation.FrequencyOfUse}}
                    </td>
                  </tr>
                  <tr>
                    <td style="color:#4d19de">{{ 'GiftCardUseStartDate' | translate }}</td>
                    <td>{{ GiftCardInformation.GiftCardStartDate | date: 'yyyy-MM-dd'}}</td>
                  </tr>

                  <tr>
                    <td style="color:#de1922">{{ 'GiftCardUseEndDate' | translate }}</td>
                    <td>{{ GiftCardInformation.GiftCardEndDate | date: 'yyyy-MM-dd'}}</td>
                  </tr>

                  <tr>
                    <td>{{ 'GiftCardBuyDate' | translate }}</td>
                    <td>{{ GiftCardInformation.GiftCardBuyDate | date: 'yyyy-MM-dd'}}</td>
                  </tr>


                  <tr>
                    <td>{{ 'TotalClientGiftCardPeriod' | translate }}</td>

                    <td>
                      {{ (GiftCardInformation.TotalGiftCardPeriodYears) }} {{ ( 'AYear' |
                      translate)}} -
                      {{ (GiftCardInformation.TotalGiftCardPeriodMonthes) }} {{ ( 'Month' |
                      translate)}}-
                      {{ (GiftCardInformation.TotalGiftCardPeriodDays) }} {{ ( 'Day' |
                      translate)}}

                    </td>
                  </tr>


                  <tr>
                    <td>{{ 'RemainingClientGiftCardPeriod' | translate }}</td>

                    <td *ngIf="GiftCardInformation.GiftCardPeriodStatus ==
                    periodStatus.NotStarted">
                      {{ 'GiftCardNotStarted' | translate }}
                    </td>
                    <td *ngIf="GiftCardInformation.GiftCardPeriodStatus ==
                    periodStatus.Finished">
                      {{ 'GiftCardFinished' | translate }}
                    </td>

                    <td *ngIf="GiftCardInformation.GiftCardPeriodStatus ==
                    periodStatus.OnGoing">

                      {{ (GiftCardInformation.RemainingGiftCardPeriodYears) }}
                      {{ ( 'AYear' | translate)}}
                      -
                      {{ (GiftCardInformation.RemainingGiftCardPeriodMonthes) }}
                      {{ ( 'Month' |
                      translate)}}-
                      {{ (GiftCardInformation.RemainingGiftCardPeriodDays) }}
                      {{ ( 'Day' | translate)}}

                    </td>
                  </tr>

                </table>


              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="col-lg-12" *ngIf="!GiftCardInformation">
        <div class="col-md-12 text-center py-4 my-4">
          <i class="fas fa-circle-notch fa-spin fa-3x"></i>
          <h1>{{'Loading' | translate}} </h1>
        </div>
      </div>

      <div class="col-lg-12" [hidden]="!Company.AllowUseTheOTPSystemWhenWithdrawFromGiftCards">

        <div class="formCard">

          <br>
          <h5 class="text-capitalize" id="exampleModalLabel">
            <i class="fas fa-list px-2"></i> {{'GiftCardOTPCodes' | translate}}
          </h5>
          <br>
          <div class="row">


            <div class="col-lg-12">
              <div class="filter-card">
                <div class="row px-2">

                  <div class="col-md-12 col-sm-12 col-12 px-2">
                    <app-search (SendFreeText)="ReciveFreeTextGiftCardOTPCodes($event)"
                      [SearchName]="('SearchByOTPCode')">
                    </app-search>
                  </div>

                </div>
              </div>
            </div>

            <div class="col-lg-12"
              *ngIf="(!LoadingGetGiftCardOTPCodes && GiftCardInformationOTPs); let GiftCardInformationOTPs; else loading2">
              <div class="table-card">
                <table class="table table-stripedd table-sm table-bordered">
                  <thead class="table-head">
                    <tr style="color: black; font-size: large;">


                      <th style="width: 5%;" class="formatTd text-center">{{ 'Index' | translate }}</th>
                      <th style="width: 10%;" class="formatTd text-center">{{ 'TheOTPCode' | translate }}</th>
                      <th style="width: 10%;" class="formatTd text-center">{{ 'TheAmount' | translate }}</th>
                      <th style="width: 25%;" class="formatTd text-center">{{ 'Client' | translate }} </th>
                      <th style="width: 10%;" class="formatTd text-center">{{ 'TheMobile' | translate }}</th>
                      <th style="width: 10%;" class="formatTd text-center">{{ 'SendingDate' | translate }}</th>
                      <th style="width: 15%;" class="formatTd text-center">{{ 'OTPStatus' | translate }}</th>
                    </tr>
                  </thead>
                  <tbody>


                    <tr *ngFor="let giftCardInformationOTP of GiftCardInformationOTPs; let i=index">
                      <td class="formatTd text-center">
                        {{ i+1 }}
                      </td>
                      <td class="formatTd text-center"
                        [title]=" (giftCardInformationOTP.IsHiddenGiftCardOTPCode ?  'UserNotAllowedToViewOTP-LastTwoDigitsIsShownOnly' : '') | translate ">
                        {{ giftCardInformationOTP.GiftCardOTPCode}}

                        <span title=" {{'UserNotAllowedToViewOTP-LastTwoDigitsIsShownOnly' | translate}} "
                          class="text-info" *ngIf="giftCardInformationOTP.IsHiddenGiftCardOTPCode">
                          <i class="fas fa-info-circle"></i>
                        </span>
                      </td>

                      <td class="formatTd text-center">
                        {{ giftCardInformationOTP.GiftCardAmount }}
                      </td>


                      <td class="formatTd text-center">
                        {{giftCardInformationOTP.SentToClientName}}
                      </td>

                      <td class="formatTd text-center">
                        {{ giftCardInformationOTP.SentToClientMobile}}
                      </td>

                      <td class="formatTd text-center">
                        {{ giftCardInformationOTP.AddedDate | date: 'yyyy-MM-dd'}}
                      </td>

                      <td class="formatTd text-center">
                        <span class="text-success" *ngIf="giftCardInformationOTP.OTPStatus == oTPStatus.Used">
                          {{ 'Used' | translate }}
                        </span>
                        <span class="text-warning" *ngIf="giftCardInformationOTP.OTPStatus == oTPStatus.Pending">
                          {{ 'Pending' | translate }}
                        </span>
                        <span class="text-danger" *ngIf="giftCardInformationOTP.OTPStatus == oTPStatus.Canceled">
                          {{ 'Canceled' | translate }}
                        </span>
                      </td>

                    </tr>

                  </tbody>
                </table>
                <div class="text-center py-4 my-4" *ngIf="GiftCardInformationOTPs.length == 0">
                  <h4 class="py-4">{{'NoDataFound' | translate}}</h4>
                </div>
              </div>



            </div>

            <div class="col-lg-12" [hidden]="!GiftCardInformationOTPs">
              <app-pagination (GetSources)="GetGiftCardOTPCodes()" #pagerGiftCardOTPCodes></app-pagination>
            </div>

            <div class="col-lg-12">
              <ng-template #loading2>
                <div class="col-lg-12">
                  <app-loading></app-loading>
                </div>
              </ng-template>
            </div>

          </div>



        </div>

      </div>

      <div class="col-lg-12">

        <div class="formCard">

          <br>
          <h5 class="text-capitalize" id="exampleModalLabel">
            <i class="fas fa-list px-2"></i> {{'GiftCardAllowedToUsedInBranches' | translate}}
          </h5>
          <br>
          <div class="row">


            <div class="col-lg-12">
              <div class="filter-card">
                <div class="row px-2">

                  <div class="col-md-12 col-sm-12 col-12 px-2">
                    <app-search (SendFreeText)="ReciveFreeTextGetGiftCardBranches($event)"
                      [SearchName]="('SearchByBranchName')">
                    </app-search>
                  </div>

                </div>
              </div>
            </div>

            <div class="col-lg-12"
              *ngIf="(!LoadingGetGiftCardBranches && GiftCardInformationBranches); let GiftCardInformationBranches; else loading1">
              <div class="table-card">
                <table class="table table-stripedd table-sm table-bordered">
                  <thead class="table-head">
                    <tr style="color: black; font-size: large;">


                      <th style="width: 20%;" class="formatTd text-center">{{ 'Index' | translate }}</th>
                      <th style="width: 80%;" class="formatTd text-center">{{ 'ThisBranchName' | translate }}</th>


                    </tr>
                  </thead>
                  <tbody>


                    <tr *ngFor="let giftCardInformationBranch of GiftCardInformationBranches; let i=index">
                      <td class="formatTd text-center">
                        {{ (i +1) + (pagerBranches.GlobalPageIndex *10) }}
                      </td>
                      <td class="formatTd text-center">
                        {{ sharedService.Lang == 'ar' ?
                        giftCardInformationBranch.BranchNameAr :
                        giftCardInformationBranch.BranchNameEn}}
                      </td>


                    </tr>

                  </tbody>
                </table>
                <div class="text-center py-4 my-4" *ngIf="GiftCardInformationBranches.length == 0">
                  <h4 class="py-4">{{'NoDataFound' | translate}}</h4>
                </div>
              </div>



            </div>

            <div class="col-lg-12" [hidden]="!GiftCardInformationBranches">
              <app-pagination (GetSources)="GetGiftCardBranches()" #pagerBranches></app-pagination>
            </div>

            <div class="col-lg-12">
              <ng-template #loading1>
                <div class="col-lg-12">
                  <app-loading></app-loading>
                </div>
              </ng-template>
            </div>

            <div class="col-lg-12" *ngIf="false">
              <div class="col-lg-12 text-center py-4 my-4">
                <i class="fas fa-circle-notch fa-spin fa-3x"></i>
                <h1>{{'Loading' | translate}} </h1>
              </div>
            </div>

          </div>



        </div>

      </div>

      <div class="col-lg-12">

        <div class="formCard">

          <br>
          <h5 class="text-capitalize" id="exampleModalLabel">
            <i class="fas fa-list px-2"></i> {{'GiftCardUsingDetails' | translate}}
          </h5>
          <br>
          <div class="row">


            <div class="col-lg-12">
              <div class="filter-card">
                <div class="row px-2">

                  <div class="col-md-12 col-sm-12 col-12 px-2">
                    <app-search (SendFreeText)="ReciveFreeTextGetGiftCardUsingDetails($event)"
                      [SearchName]="('SearchByBillCode')">
                    </app-search>
                  </div>

                </div>
              </div>
            </div>

            <div class="col-lg-12"
              *ngIf="(!LoadingGetGiftCardUsingDetails && GiftCardInformationUsingDetails); let GiftCardInformationUsingDetails; else loading">
              <div class="table-card">
                <table class="table table-stripedd table-sm table-bordered">
                  <thead class="table-head">
                    <tr style="color: black; font-size: large;">


                      <th style="width: 5%;" class="formatTd text-center">{{ 'Index' | translate }}</th>
                      <th style="width: 15%;" class="formatTd text-center">{{ 'ClientThatUsedIt' | translate }}</th>
                      <th style="width: 15%;" class="formatTd text-center">{{ 'BillCodeThatUsedInIt' | translate }}</th>
                      <th style="width: 15%;" class="formatTd text-center">{{ 'ThisBranchName' | translate }} </th>
                      <th style="width: 10%;" class="formatTd text-center">{{ 'AmountUsedOfGiftCard' | translate }}</th>
                      <th style="width: 10%;" class="formatTd text-center">{{ 'AmountReturned' | translate }}</th>
                      <th style="width: 10%;" class="formatTd text-center">{{ 'UsingDate' | translate }}</th>
                      <th style="width: 10%;" class="formatTd text-center">{{ 'TransactionDirection' | translate }}</th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr
                      [ngClass]="GiftCardInformationUsingDetail.Direction ==
                      clientBalanceTransactionDirection.Input ? 'greenColor formatTd  text-center' : 'redColor formatTd  text-center'"
                      *ngFor="let GiftCardInformationUsingDetail of GiftCardInformationUsingDetails; let i=index">
                      <td class="formatTd text-center">
                        {{ i+1 }}
                      </td>
                      <td class="formatTd text-center">
                        {{ sharedService.Lang == 'ar' ?
                        GiftCardInformationUsingDetail.ClientNameAr :
                        GiftCardInformationUsingDetail.ClientNameEn}}
                      </td>

                      <td class="formatTd text-center">
                        {{ GiftCardInformationUsingDetail.UsedInTransactionCode }}
                      </td>


                      <td class="formatTd text-center">
                        {{ sharedService.Lang == 'ar' ?
                        GiftCardInformationUsingDetail.UsedInBranchNameAr :
                        GiftCardInformationUsingDetail.UsedInBranchNameEn}}
                      </td>

                      <td class="formatTd text-center">
                        {{ GiftCardInformationUsingDetail.Amount.toFixed(sharedService.GetFractionRoundCount)}}
                      </td>

                      <td class="formatTd text-center">
                        {{ GiftCardInformationUsingDetail.ReturnedAmount.toFixed(sharedService.GetFractionRoundCount)}}
                      </td>

                      <td class="formatTd text-center">
                        {{ GiftCardInformationUsingDetail.DateOfUse | date: 'yyyy-MM-dd'}}
                      </td>

                      <td class="formatTd" *ngIf="GiftCardInformationUsingDetail.Direction ==
                          clientBalanceTransactionDirection.Input">{{
                        ('Adding'| translate) }}</td>
                      <td class="formatTd" *ngIf="GiftCardInformationUsingDetail.Direction ==
                          clientBalanceTransactionDirection.Output">{{
                        ('Deducting' |translate)}}</td>

                    </tr>

                  </tbody>
                </table>
                <div class="text-center py-4 my-4" *ngIf="GiftCardInformationUsingDetails.length == 0">
                  <h4 class="py-4">{{'NoDataFound' | translate}}</h4>
                </div>
              </div>



            </div>

            <div class="col-lg-12" [hidden]="!GiftCardInformationUsingDetails">
              <app-pagination (GetSources)="GetGiftCardUsingDetails()" #pagerGiftCardUsingDetails></app-pagination>
            </div>

            <div class="col-lg-12">
              <ng-template #loading>
                <div class="col-lg-12">
                  <app-loading></app-loading>
                </div>
              </ng-template>
            </div>

          </div>



        </div>

      </div>
    </div>


  </div>
  <div class="modal-footer">

    <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">
      {{'Close' | translate}}</button>

    <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="printPage()">{{'Print' |
      translate}}</button>
  </div>
</div>
