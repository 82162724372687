import { Injectable, DebugElement } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { MessageService } from '../message/message.service';
import { formValueDates } from '../../shared/helpers';
import { CompanyImagesServiceEditedName } from '../company/company-image-edited-name.service';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { GlameraBooking } from '../../models/classes/booking/glamera-booking';
import { PaidStatus } from '../../models/enums/paid-status';
import { BookingSearchCriteria } from '../../models/search-criterias/booking-search-criteria';
import { BookingSearchResult } from '../../models/search-result/booking-search-result';
import { ExecutionResponse } from '../../models/support/execution-response';
import { Booking } from '../../models/classes/booking/booking';
import { SharedService } from '../other/shared.service';
import { UpdateItemNumberModel } from 'src/app/models/classes/booking/update-item-number-model';
import { BookingForWorkDoneDTO } from 'src/app/models/classes/booking/booking-for-work-done';
import { BookingInfoDTO } from 'src/app/models/classes/booking/booking-info';
import { GetBookingCriteria } from 'src/app/models/search-criterias/get-booking-criteria';
@Injectable({ providedIn: 'root' })
export class ABookingService extends BaseService {
  private bookingsUrl; //= super.BaseUrl() + 'Booking';  // URL to web api
  private bookingServicesUrl;

  constructor(
    private _BaseService: BaseServiceSZ,
    private companyImagesService: CompanyImagesServiceEditedName,
    private http: HttpClient,
    private messageService: MessageService,
    private sharedService: SharedService
  ) {
    super();
    this.bookingsUrl = this._BaseService.ApiUrl + 'Booking';
    this.bookingServicesUrl = this._BaseService.ApiUrl + 'BookingService';
  }

  /** GET booking by id. Will 404 if id not found */
  getBooking(id: String): Observable<ExecutionResponse<Booking>> {
    //  getBooking(id: String): ExecutionResponse<Booking> {
    //const url = '${this.bookingsUrl}/${id}';
    return this.http.post<ExecutionResponse<Booking>>(
      this.bookingsUrl + '/getById',
      id,
      super.getHeaders() //)
      // .pipe(
      //   tap(res => {
      //     this._BaseService.log('fetched booking id=${id}');
      //     this._BaseService.ValidationResult(res);

      //   }),
      //   catchError(this._BaseService.handleError<ExecutionResponse<Booking>>('getBooking id=${id}'))
    );
  }

  assignEmployeeIdToAllServices(_booking: Booking) {
    if (_booking.BookingServices) {
      _booking.BookingServices.forEach((x) => {
        x.EmployeeId = _booking.EmployeeIdTmp;
      });
    }
  }

  UpdateItemNumber(model: UpdateItemNumberModel): Observable<ExecutionResponse<boolean>> {
    return this.http.post<ExecutionResponse<boolean>>(this.bookingServicesUrl +
      '/UpdateItemNumber', model, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('updated');
          this._BaseService.ValidationResult(res);
        }),
      );

  }

  adjustForCopy(_booking: Booking) {
    if (_booking) {
      _booking.IsPaid = PaidStatus.NotPaid;
      _booking.BookingStatus = 0;
    }
  }
  addBooking(booking: Booking): Observable<ExecutionResponse<Booking>> {
    ///this.adjustData(booking, true);
    booking.Date = new Date(new Date().toLocaleDateString());
    booking.CompanyId = this.companyImagesService.getCompanyId();
    booking.AccountSetupId = this.getAccountSetupId();

    return this.http
      .post<ExecutionResponse<Booking>>(
        this.bookingsUrl + '/GlameraCreateWithPayment',
        booking,
        super.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('added booking w/ id=${booking.Id}');
          this._BaseService.ValidationResult(res);
        }),
        catchError(
          this._BaseService.handleError<ExecutionResponse<Booking>>(
            'addBooking id=${id}'
          )
        )
      );
  }
  addBookingWithPayment(
    booking: GlameraBooking
  ): Observable<ExecutionResponse<Booking>> {
    //this.adjustData(booking.bookingDTO, true);
    booking.bookingDTO.Date = new Date(new Date().toLocaleDateString());
    booking.bookingDTO.CompanyId = this.companyImagesService.getCompanyId();
    booking.bookingDTO.AccountSetupId = this.getAccountSetupId();

    let x = booking;
    return this.http
      .post<ExecutionResponse<Booking>>(
        this.bookingsUrl + '/GlameraCreateWithPayment',
        booking,
        super.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('added booking w/ id=${booking.Id}');
          this._BaseService.ValidationResult(res);
        }),
        catchError(
          this._BaseService.handleError<ExecutionResponse<Booking>>(
            'addBooking id=${id}'
          )
        )
      );
  }

  GetBookingForWorkDone(getBookingCriteria: GetBookingCriteria): Observable<ExecutionResponse<BookingForWorkDoneDTO>> {

    return this.http.get<ExecutionResponse<BookingForWorkDoneDTO>>(this.bookingsUrl
      + '/GetBookingForWorkDone', {
      headers: this.sharedService.getHeaders().headers,
      params: this.sharedService.ToHttpParams(getBookingCriteria)
    })
      .pipe(
        tap(res => {

          this._BaseService.ValidationResult(res);
        })
      );
  }
  GetBookingInfo(getBookingCriteria: GetBookingCriteria): Observable<ExecutionResponse<BookingInfoDTO>> {

    return this.http.get<ExecutionResponse<BookingInfoDTO>>(this.bookingsUrl
      + '/GetBookingInfo', {
      headers: this.sharedService.getHeaders().headers,
      params: this.sharedService.ToHttpParams(getBookingCriteria)
    })
      .pipe(
        tap(res => {

          this._BaseService.ValidationResult(res);
        })
      );
  }

  ExportBookings(criteria: BookingSearchCriteria):
    Observable<any> {
    return this.http.get(
      this.bookingsUrl + '/GetExportedBookings',
      {
        headers: this.sharedService.getHeaders('ExportExcel').headers,
        params: this.sharedService.ToHttpParams(criteria),
        responseType: 'blob'
      }
    )
      .pipe(
        tap((res) => {
          this._BaseService.log('GetExportedBookings');
        })
      );
  }

  addGlameraBooking(booking: Booking): Observable<ExecutionResponse<Booking>> {
    this.adjustData(booking, true);
    booking.Date = new Date(new Date().toLocaleDateString());
    booking.CompanyId = this.companyImagesService.getCompanyId();

    return this.http
      .post<ExecutionResponse<Booking>>(
        this.bookingsUrl + '/GlameraCreate',
        booking,
        super.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('added booking w/ id=${booking.Id}');
          this._BaseService.ValidationResult(res);
        }),
        catchError(
          this._BaseService.handleError<ExecutionResponse<Booking>>(
            'addBooking id=${id}'
          )
        )
      );
  }

  Reschedule(booking: Booking): Observable<ExecutionResponse<Booking>> {
    this.adjustData(booking);
    booking.CompanyId = this.companyImagesService.getCompanyId();
    return this.http
      .post<ExecutionResponse<Booking>>(
        this.bookingsUrl + '/Reschedule',
        booking,
        super.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('added booking w/ id=${booking.Id}');
          this._BaseService.ValidationResult(res);
        }),
        catchError(
          this._BaseService.handleError<ExecutionResponse<Booking>>(
            'addBooking id=${id}'
          )
        )
      );
  }

  /* GET bookings whose name contains search term */
  searchBookings(
    bookingSearchCriteria: BookingSearchCriteria
  ): Observable<BookingSearchResult> {
    bookingSearchCriteria.AccountSetupId = super.getAccountSetupId();
    return this.http
      .post<BookingSearchResult>(
        this.bookingsUrl + '/Get',
        bookingSearchCriteria,
        super.getHeaders()
      )
      .pipe(
        tap((bookings) => {
          this._BaseService.log('fetched bookings');
          this._BaseService.ValidationResult(bookings);
        }),
        catchError(
          this._BaseService.handleError<BookingSearchResult>(
            'searchBookings id=${id}'
          )
        )
      );
  }

  /** DELETE: delete the booking from the server */
  deleteBooking(
    booking: Booking | number
  ): Observable<ExecutionResponse<Booking>> {
    return this.http
      .post<ExecutionResponse<Booking>>(
        this.bookingsUrl + '/delete',
        booking,
        super.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.ValidationResult(res);
          this._BaseService.log('deleted booking id=${id}');
        }),
        catchError(
          this._BaseService.handleError<ExecutionResponse<Booking>>(
            'deleteBooking'
          )
        )
      );
  }

  /** PUT: update the booking on the server */
  updateBooking(booking: Booking): Observable<ExecutionResponse<Booking>> {
    this.adjustData(booking, true);

    // booking.ClassType="Glamera_Business";//FOR TEST
    booking.CompanyId = this.companyImagesService.getCompanyId();
    return this.http
      .post<ExecutionResponse<Booking>>(
        this.bookingsUrl + '/Update',
        booking,
        super.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('added booking w/ id=${booking.Id}');
          this._BaseService.ValidationResult(res);
        }),
        catchError(
          this._BaseService.handleError<ExecutionResponse<Booking>>(
            'addBooking id=${id}'
          )
        )
      );
  }
  adjustData(booking: Booking, isAdd = false) {
    booking.AccountSetupId = super.getAccountSetupId();
    booking = formValueDates(booking, true);
    // if (!isAdd)
    //   this.assignEmployeeIdToAllServices(booking);//no need --salah
    for (let bookingService of booking.BookingServices) {
      bookingService.AccountSetupId = super.getAccountSetupId();
    }
  }

  UpdateBookingStatus(
    booking: Booking
  ): Observable<ExecutionResponse<Booking>> {
    booking.AccountSetupId = super.getAccountSetupId();
    booking = formValueDates(booking, true);
    // booking.ClassType = "Glamera_User";
    booking.ClassType = 'Glamera_Business';
    return this.http
      .post<ExecutionResponse<Booking>>(
        this.bookingsUrl + '/UpdateBookingStatus',
        booking,
        super.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('added booking w/ id=${booking.Id}');
          this._BaseService.ValidationResult(res);
        }),
        catchError(
          this._BaseService.handleError<ExecutionResponse<Booking>>(
            'UpdateBooking id=${id}'
          )
        )
      );
  }
}

@Injectable({ providedIn: 'root' })
export class BookingStateService extends BaseService {
  private _booking: Booking;
  private _visibleBookingResources: any = {};
  public popupresrvationX = 0;
  public popupresrvationY = 0;
  ///-----------------------------------
  public getBooking(): Booking {
    return this._booking;
  }
  public setBooking(_Booking: Booking) {
    this._booking = _Booking;
  }
  ///-----------------------------------
  public getVisibleBookingResources(): Booking {
    return this._visibleBookingResources;
  }

  public setVisibleBookingResources(vbr: any) {
    this._visibleBookingResources = vbr;
  }
  //---------------------------------------

  popupX(event) {
    let xpos =
      event.clientX +
      (document.documentElement.scrollLeft
        ? document.documentElement.scrollLeft
        : document.body.scrollLeft);
    let winnerWidth = window.innerWidth;
    let visibleW = winnerWidth - xpos;
    if (visibleW < 450) {
      xpos = xpos - (450 - visibleW);
    }
    return xpos;
  }
  popupY(event) {
    let ypos =
      event.clientY +
      (document.documentElement.scrollTop
        ? document.documentElement.scrollTop
        : document.body.scrollTop);
    let innerHeight = window.innerHeight;
    let visibleW = innerHeight - ypos;
    if (visibleW < 350) {
      ypos = ypos - 100;
    }
    return ypos;
  }
}
