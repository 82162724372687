export enum BookingStatus {
  //upcoming
  Confirmed = 1,
  Arrived = 2,
  //AutoConfirmed = 20,

  //pending
  Unconfirmed = 0,
  WaitingForPayment = 6,

  WorkDone = 7,

  //history
  Canceled = -1,
  Completed = 3,
 // SurveyCompleted = 4,
  //DidNotShow = 5,

  //History = 21

}
