import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CostCenter } from '../../../models/classes/accounts/cost-center';
import { CostCenterCriteria } from '../../../models/search-criterias/cost-center-criteria';
import { CostCenterSearchResult } from '../../../models/search-result/cost-center-search-result';
import { ExecutionResponse } from '../../../models/support/execution-response';
import { MessageService } from '../../../services/message/message.service';
import { BaseService, BaseServiceSZ } from '../../../services/other/baseService';
import { SharedService } from '../../../services/other/shared.service';
import { UIStateService } from '../../../services/other/ui-state.service';

@Injectable({
  providedIn: 'root'
})


export class CostCenterService extends BaseService {
  CostCenterUrl: string;
  constructor(private http: HttpClient,
    private messageService: MessageService,
    private _BaseService: BaseServiceSZ,
    private sharedService: SharedService,
    private _UIStateService: UIStateService) {
    super();

    this.CostCenterUrl = this.sharedService.ApiUrl/*this._BaseService.ApiUrl */ + 'CostCenter';
  }

  getCostCenter(criteria?: CostCenterCriteria): Observable<CostCenterSearchResult> {


    return this.http.post<CostCenterSearchResult>(this.CostCenterUrl + '/Get', criteria, super.getHeaders())
      .pipe(
        tap(res => {
          // this._BaseService.ValidationResult(res);
        }),

        // catchError(this._BaseService.handleError<CostCenterSearchResult>('getCostCenter get=${criteria}'))
      );
  }

  getCostCenter2(body) {
    return this.http.post<any>(this.CostCenterUrl + '/Get', body, super.getHeaders())

  }
  GenerateNumber(body): Observable<any> {
    return this.http.post<any>(this.CostCenterUrl + '/GenerateNumber', body, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);
        }),
      );
  }
  CreateCostCenter(CostCenter?: CostCenter): Observable<ExecutionResponse<CostCenter>> {


    return this.http.post<ExecutionResponse<CostCenter>>(this.CostCenterUrl + '/Create', CostCenter, super.getHeaders())
      .pipe(
        tap(res => {
          //  this._BaseService.ValidationResult(res);

        }),

        catchError(this._BaseService.handleError<ExecutionResponse<CostCenter>>('createCostCenter create=${CostCenter}'))
      );
  }



  UpdateCostCenter(CostCenter?: CostCenter): Observable<ExecutionResponse<CostCenter>> {


    return this.http.post<ExecutionResponse<CostCenter>>(this.CostCenterUrl + '/Update', CostCenter, super.getHeaders())
      .pipe(
        tap(res => {
          //  this._BaseService.ValidationResult(res);

        }),

        catchError(this._BaseService.handleError<ExecutionResponse<CostCenter>>('UpdateCostCenter Update=${CostCenter}'))
      );
  }

  DeleteCostCenter(Id: number): Observable<ExecutionResponse<CostCenter>> {


    return this.http.post<ExecutionResponse<CostCenter>>(this.CostCenterUrl + '/Delete', Id, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);

        }),

        catchError(this._BaseService.handleError<ExecutionResponse<CostCenter>>('DeleteCostCenter Update=${CostCenter}'))
      );
  }



}
