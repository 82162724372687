import { MyUserRole } from "./my-user-role";
import { ResourceStatus } from '../../enums/resource-status';
import { UserPermissions } from './user-permissions';
import { UserAccountSetup } from './user-account-setup';
import { SecurityGroup } from "./security-group";

export class MyUser {
    constructor() {
        this.Status = 1;
        this.Id = 0;
    }
    public AccessFailedCount: number;
    public Claims: object[];
    public Email: string;
    public FirstName: string;
    public LastName: string;
    public FullName: string;
    public Status: ResourceStatus;
    public EmailConfirmed: boolean;
    public Id: number;
    public LockoutEnabled: boolean;
    public LockoutEndDateUtc: string;
    public Logins: object[];
    public PasswordHash: string;
    public Password: string;
    public PhoneNumber: string;
    public PhoneNumberConfirmed: boolean;
    public UserRols: MyUserRole[];
    public UserSecurityGroups: SecurityGroup[];
    public SecurityStamp: string;
    public TwoFactorEnabled: boolean;
    public UserName: string;

    public UserPictureFolder: string;
    public UserPictureName: string;
    public UserPicturePath: string;

    public EmployeeId: number;
    public LinkedEmployee: string;
    public UserPermissions: UserPermissions[];
    public UserAccounts: UserAccountSetup[];
    public AccountSetupPermissionId: number;
    public ScreenModules: any;
    public Name: string;
    public UserBranchIds: number[];
    public AuthorizedPermissions: number[];
    public AuthorizedLinks: number[];
    public AuthorizedPermissionCode: number[];
    public IsAdmin: boolean;
    public IsManager: boolean;
    public AllowAccessToClientsMobileNumbers: boolean;
    public AllowViewBalances: boolean;
    public numberOfAllowedPreviousDailys : number;
}
