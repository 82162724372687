import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap, delay } from 'rxjs/operators';
import { MessageService } from '../message/message.service';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { SharedService } from '../other/shared.service';
import { HttpClient } from '@angular/common/http';
import { GymClassDetailsSearchCriteria } from '../../models/search-criterias/gymClassDetailsSearchCriteria';
import { GymClassDetailsSearchResult } from '../../models/search-result/gymClassDetailsSearchResult';
import { GetGymClassDetailAppointmentsSearchCriteria } from '../../models/search-criterias/getGymClassDetailAppointmentsSearchCriteria';
import { GetGymClassDetailAppointmentsSearchResult } from '../../models/search-result/getGymClassDetailAppointmentsSearchResult';
import { SearchResult } from 'src/app/models/support/search-result';
import { HttpBaseServiceV2Service } from '../http-base-service-v2.service';

@Injectable({
  providedIn: 'root'
})

export class GymClassDetailsService extends BaseService {
  isAlterEgoTaken(alterEgo: string): Observable<boolean> {
    const isTaken = false; //alterEgo.includes(alterEgo);
    return of(isTaken).pipe(delay(400));
  }
  private gymClassDetailsUrl // = super.BaseUrl() + 'Client';  // URL to web api

  constructor(
    private http: HttpClient,
    private httpService: HttpBaseServiceV2Service,
    private messageService: MessageService,
    private _BaseService: BaseServiceSZ,
    private sharedService: SharedService
  ) {
    super();
    this.gymClassDetailsUrl = this.sharedService.ApiUrlV2 + 'GymClassDetails';
  }
  getApiUrl() {
    return this._BaseService.ApiUrl;
  }


  GetGymClassDetails(gymClassDetailsSearchCriteria: GymClassDetailsSearchCriteria): Observable<GymClassDetailsSearchResult> {

    return this.http.post<GymClassDetailsSearchResult>(this.gymClassDetailsUrl + '/GetGymClassDetails', gymClassDetailsSearchCriteria, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('Fetched Gym Class Details');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<GymClassDetailsSearchResult>('Fetched Gym Class Details id=${id}'))
      );
  }

 

  GetGymClassDetailAppointments(criteria?: GetGymClassDetailAppointmentsSearchCriteria): Observable<GetGymClassDetailAppointmentsSearchResult> {


    criteria.CompanyId = this.sharedService.CompanyId;
    //criteria.GetWithOutStatusAndVisibleToGlamera = true;
   


    return this.http.post<GetGymClassDetailAppointmentsSearchResult>(this.gymClassDetailsUrl + '/GetGymClassDetailAppointments', criteria, super.getHeaders())
      .pipe(
        tap(res => {

          this._BaseService.ValidationResult(res);

        }),

        catchError(this._BaseService.handleError<GetGymClassDetailAppointmentsSearchResult>('getAccountSetup id=${id}'))
      );
  }

  GetGymClassesWithTrainersReport(criteria: any): Observable<SearchResult<any>> {
    return this.httpService.Post('v2/GymClassDetails/GetGymClassesWithTrainersReport', criteria);
  }
 

}
