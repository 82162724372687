<div class="modal-header">
    <h5 class="modal-title text-capitalize" id="exampleModalLabel">
        {{title| translate }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="text-center py-4">
        <h4> {{ messageCode| translate}}?</h4>
    </div>
</div>
<div class="modal-footer">
     
    <button type="button" class="btn" (click)="SuteItem(item)">{{'Confirm'
        | translate}}</button>
    <button type="button" class="btn " (click)="activeModal.close('Close click')">{{'May be later' |
        translate}}</button>
</div>