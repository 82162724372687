import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from '../../dashboard/admin/service/admin.service';
import { AccountPayments } from '../../dashboard/transaction/model/cashier-constants';
import { TransactionService } from '../../dashboard/transactions/service/transaction.service';
import { PaymentMethodType } from '../../models/enums/payment-method-type';
import { CreditCardSearchCriteria } from '../../models/search-criterias/credit-card-search-criteria';
import { PaymentMethodSearchCriteria } from '../../models/search-criterias/payment-method-search-criteria';
import { ResponseState } from '../../models/support/response-state';
import { SharedService } from '../../services/other/shared.service';
import { PaymentMethodService } from 'src/app/services/cashier/payment-method.service';
import { CreditCardService } from 'src/app/services/cashier/credit-card.service';
import { Company } from 'src/app/models/classes/company/company';

@Component({
  selector: 'app-transaction-payment-two',
  templateUrl: './transaction-payment-two.component.html',
  styleUrls: ['./transaction-payment-two.component.scss'],
})
export class TransactionPaymentTwoComponent implements OnInit {
  @Input() TPForm: UntypedFormGroup;
  @Input() ClientLoyalityAmount: any;
  @Input() UseLoyalityPoints: any;
  public accountPayments: any;
  VisaTypeName = ['HSBC', 'ALEXBANK ', 'QNB Viza '];

  @Output() myData = new EventEmitter();
  @Output() sendTotalAmount = new EventEmitter();

  paymentMethodSearchCriteria: PaymentMethodSearchCriteria;
  creditCardSearchCriteria: CreditCardSearchCriteria;
  LoyaltyDiscount: any;
  Company: Company;

  constructor(
    private transactionService: TransactionService,
    public sharedService: SharedService,
    private adminService: AdminService,
    private paymentMethodService: PaymentMethodService,
    private creditCardService: CreditCardService,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) {
    this.accountPayments = AccountPayments;
    this.paymentMethodSearchCriteria = new PaymentMethodSearchCriteria();
    this.creditCardSearchCriteria = new CreditCardSearchCriteria();
  }

  @Output() CalculateNew = new EventEmitter();

  cashsaleId;

  public get paymentMethodType(): typeof PaymentMethodType {
    return PaymentMethodType;
  }

  ngOnInit(): void {
    this.cashsaleId = this.route.snapshot.params['id'];
    this.getByIdAccountSetup();
    this.getAccountCurrencies();
    this.searchPaymentMethods();
    this.searchCreditCards();
    this.Company = this.sharedService.CurrentCompany;
  }

  /*======== getByIdAccountSetup=========================================================*/
  AccountSetup;
  AccountCurrencies;
  getByIdAccountSetup() {
    this.transactionService
      .getByIdAccountSetup(this.sharedService.AccountSetupId)
      .subscribe((response: any) => {
        if (response.State == ResponseState.Success) {
          this.AccountSetup = response.Result;
        }
      });
  }

  getAccountCurrencies() {
    let jsonObj = {
      AccountSetupId: this.sharedService.AccountSetupId,
      FreeText: '',
      Status: '',
    };

    this.adminService.GetAccountCurrency(jsonObj).subscribe((response: any) => {
      if (response.State == ResponseState.Success) {
        this.AccountCurrencies = response.AccountCurrencys;

        if (!this.cashsaleId) {
          const controlArray = <UntypedFormArray>(
            this.TPForm.get('BookingOrTransactionModel').get(
              'TransactionsPayments'
            )
          );
          controlArray.controls[0]
            .get('CurrencyId')
            .patchValue(this.AccountCurrencies[0].Id);
        }
      }
    });
  }

  PaymentMethods;
  searchPaymentMethods() {
    this.paymentMethodSearchCriteria.AccountSetupId =
      this.sharedService.AccountSetupId;
    this.paymentMethodSearchCriteria.UseLoyalityPoints = this.UseLoyalityPoints;
    this.paymentMethodService
      .searchPaymentMethods(this.paymentMethodSearchCriteria)
      .subscribe((result) => {
        if (result.State == ResponseState.Success) {
          this.PaymentMethods = result.PaymentMethods;

          if (!this.cashsaleId) {
            const CashId = this.PaymentMethods.find(
              (e) => e.PaymentMethodType == 1
            );
            const controlArray = <UntypedFormArray>(
              this.TPForm.get('BookingOrTransactionModel').get(
                'TransactionsPayments'
              )
            );
            controlArray.controls[0]
              .get('PaymentMethodId')
              .patchValue(CashId?.Id);
            controlArray.controls[0].get('PaymentMethodType').patchValue(1);
          }
        }
      });
  }

  CreditCards;
  searchCreditCards() {
    this.creditCardSearchCriteria.AccountSetupId =
      this.sharedService.AccountSetupId;
    this.creditCardService
      .searchCreditCards(this.creditCardSearchCriteria)
      .subscribe((result) => {
        if (result.State == ResponseState.Success) {
          this.CreditCards = result.CreditCards;
        }
      });
  }

  ChoosePayment(e, i) {
    const controlArray = <UntypedFormArray>(
      this.TPForm.get('BookingOrTransactionModel').get('TransactionsPayments')
    );
    controlArray.controls[i]
      .get('PaymentMethodType')
      .patchValue(e.PaymentMethodType);
    controlArray.controls[i]
      .get('CurrencyId')
      .patchValue(this.AccountCurrencies[0].Id);
    console.log(controlArray.controls[i].get('Amount').value);

    if (e.PaymentMethodType != PaymentMethodType.GiftCard) {
      controlArray.controls[i].get('GiftCardCode').patchValue(null);
      controlArray.controls[i].get('GiftCardInformationId').patchValue(null);
      controlArray.controls[i].get('GiftCardCode').clearValidators();
      controlArray.controls[i].get('GiftCardCode').updateValueAndValidity();

      if (this.Company.AllowUseTheOTPSystemWhenWithdrawFromGiftCards) {
        controlArray.controls[i].get('GiftCardOTPCode').patchValue(null);
        controlArray.controls[i]
          .get('GiftCardInformationOTPId')
          .patchValue(null);
        controlArray.controls[i].get('GiftCardOTPCode').clearValidators();
        controlArray.controls[i]
          .get('GiftCardOTPCode')
          .updateValueAndValidity();
      }
    } else {
      controlArray.controls[i]
        .get('GiftCardCode')
        .setValidators(Validators.required);
      controlArray.controls[i].get('GiftCardCode').updateValueAndValidity();

      if (this.Company.AllowUseTheOTPSystemWhenWithdrawFromGiftCards) {
        controlArray.controls[i]
          .get('GiftCardOTPCode')
          .setValidators(Validators.required);
        controlArray.controls[i]
          .get('GiftCardOTPCode')
          .updateValueAndValidity();
      }
    }
    if (
      e.PaymentMethodType != PaymentMethodType.Other &&
      e.PaymentMethodType != PaymentMethodType.CreditCard
    ) {
      controlArray.controls[i].get('ReferenceCode').patchValue(null);
    }

    if (i == 0 && e.PaymentMethodType == PaymentMethodType.Loyalty) {
      if (
        this.ClientLoyalityAmount <
        Number(controlArray.controls[i].get('Amount').value)
      ) {
        this.sharedService.ToastrError(
          'لا يمكن استخدام المحفظة طريقة دفع أولى  '
        );

        const CashId = this.PaymentMethods.find(
          (e) => e.PaymentMethodType == PaymentMethodType.Cash
        );
        const controlArray = <UntypedFormArray>(
          this.TPForm.get('BookingOrTransactionModel').get(
            'TransactionsPayments'
          )
        );
        controlArray.controls[0].get('PaymentMethodId').patchValue(CashId?.Id);
        controlArray.controls[0]
          .get('PaymentMethodType')
          .patchValue(PaymentMethodType.Cash);
      }
    }

    if (controlArray.controls.length > 1) {
      if (
        (i == 1 &&
          e.Id == controlArray.controls[0].get('PaymentMethodId').value) ||
        (i == 0 &&
          e.Id == controlArray.controls[1].get('PaymentMethodId').value)
      ) {
        this.sharedService.ToastrError('لا يسمح اختيار طريقة دفع مرتين');
        controlArray.controls[i].get('PaymentMethodId').patchValue(0);
      }
    }
  }
  oneAmount = 0;
  twoAmount = 0;
  AllAmount: number = 0;
  CalcSecondPayValue(e,i) {
    this.AllAmount = 0;
    for (
      let t = 0;
      t <
      this.TPForm.get('BookingOrTransactionModel').get('TransactionsPayments')
        .value.length;
      t++
    ) {
      const controlArray = <UntypedFormArray>(
        this.TPForm.get('BookingOrTransactionModel').get('TransactionsPayments')
      );

      this.AllAmount += controlArray.controls[t].get('Amount').value;

      this.sendTotalAmount.emit(this.AllAmount);
    }
  }

  removeItem(i) {
    (
      this.TPForm.get('BookingOrTransactionModel').get(
        'TransactionsPayments'
      ) as UntypedFormArray
    ).removeAt(i);
    this.CalculateNew.emit('Calc');

    const controlArray = <UntypedFormArray>(
      this.TPForm.get('BookingOrTransactionModel').get('TransactionsPayments')
    );
    controlArray.controls[0]
      .get('Amount')
      .patchValue(
        this.TPForm.get('BookingOrTransactionModel').get(
          'BookingOrTransactionTotalPrice'
        ).value
      );

    let tp = this.TPForm.get('BookingOrTransactionModel').get(
      'TransactionsPayments'
    ).value.length;
    for (let p = 0; p < tp; p++) { }
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  checkKey(event, index): boolean {
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode != 110 && charCode != 188 && charCode != 190)
      this.CalcSecondPayValue(event, index);

    return true;
  }
}
