import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseState } from 'src/app/models/support/response-state';
import { SharedService } from 'src/app/services/other/shared.service';
import { BookingService } from '../../../dashboard/bookings/service/booking.service';

@Component({
  selector: 'app-group-tap',
  templateUrl: './group-tap.component.html',
  styleUrls: ['./group-tap.component.scss'],
})
export class GroupTapComponent implements OnInit {
  constructor(
    public readonly activeModal: NgbActiveModal,
    public readonly sharedService: SharedService,
    private readonly bookingService: BookingService
  ) {}

  activeType: string = 'serviceCategories';
  Categories: any[] = [];
  catTypes: number[];
  public freeText;
  @Input() pager;
  @Input() type: number;
  @Input() editDebitAccounts: any[] = [];
  @Input() editCreditAccounts: any[] = [];
  @Input() accountName: string;
  @Output() accountType = new EventEmitter<string>();
  @Output() emiitedAccountsArray = new EventEmitter<Array<any>>();

  debitAccounts: any[] = [];
  creditAccounts: any[] = [];

  totalCount: number;

  ngOnInit(): void {
    this.catTypes = this.sharedService.AllowUseGym ? [2, 3] : [2];
    if (this.editDebitAccounts.length) {
      this.debitAccounts = this.editDebitAccounts.map((account) => ({
        ...account,
        categoryId: account.CategoryId ?? account.categoryId,
        accountSelected: {
          IsDebit: true,
          categoryId: account.CategoryId ?? account.categoryId,
          Id: account.CategoryId ?? account.categoryId,
          DebitAccountName: account?.DebitAccountName ?? account?.Name,
          DebitAccountId: account?.DebitAccountId,
          categorySettingMigrationAccountsId:
            account?.categorySettingMigrationAccountsId,
        },
      }));
    }

    if (this.editCreditAccounts.length) {
      this.creditAccounts = this.editCreditAccounts.map((account) => ({
        ...account,
        categoryId: account.CategoryId ?? account.categoryId,
        accountSelected: {
          IsDebit: false,
          categoryId: account.CategoryId ?? account.categoryId,
          Id: account.CategoryId ?? account.categoryId,
          CreditAccountName: account?.CreditAccountName ?? account?.Name,
          CreditAccountId: account?.CreditAccountId,
          categorySettingMigrationAccountsId:
            account?.categorySettingMigrationAccountsId,
        },
      }));
    }
  }

  onGetData(type: string): void {
    this.activeType = type;
  }

  onRemoveAccount(account: any): void {
    if (this.type) {
      this.debitAccounts = this.debitAccounts.filter(
        (item) => item.categoryId !== account.Id
      );
    } else {
      this.creditAccounts = this.creditAccounts.filter(
        (item) => item.categoryId !== account.Id
      );
    }
  }

  onChangeAccount(emittedAccunt: any): void {
    if (this.type) {
      this.debitAccounts.push({
        ...emittedAccunt,
        accountSelected: {
          ...emittedAccunt.accountSelected,
          IsDebit: true,
          DebitAccountId: emittedAccunt?.accountSelected?.Id,
          categoryId: emittedAccunt.Id,
        },
      });
    } else {
      this.creditAccounts.push({
        ...emittedAccunt,
        accountSelected: {
          ...emittedAccunt.accountSelected,
          IsDebit: false,
          categoryId: emittedAccunt.Id,
          CreditAccountId: emittedAccunt?.accountSelected?.Id,
        },
      });
    }
  }

  onCloseModal(): void {
    if (this.type) {
      this.emiitedAccountsArray.emit(this.debitAccounts);
    } else {
      this.emiitedAccountsArray.emit(this.creditAccounts);
    }
    this.activeModal.close();
  }

  onClose(): void {
    this.activeModal.close();
  }
}
