<form [formGroup]="loyalityPointsForm" (submit)="onSubmittedform()">
  <h3>{{ "LoyaltyPointsSettings" | translate }}</h3>

  <section>
    <p class="title">{{ "GrantPolicy" | translate }}</p>
    <div>
      <p>{{ "Services_Points" | translate }}</p>
      <div class="form-inline">
        <div class="form-group">
          <label class="mx-2">
            {{ "Given" | translate }}
          </label>
          <input class="form-control mx-2" type="number" formControlName="ServiceLoyaltyPointsGivenForEveryXDollarSpent"
            min="0" oninput="validity.valid||(value='');" />

          <label class="mx-2">{{ "Points" | translate }}</label>
          <div class="no-border">
            <input class="form-control" type="number"
              formControlName="ServicePointsAndDollarsAreGivenForEveryXDollarSpent" min="0"
              oninput="validity.valid||(value='');" />
            <img src="../../assets/img/info.png" alt="" title="{{ 'lessMoney' | translate }}" [ngClass]="{
                'img-ar': sharedService.Lang == 'ar',
                'img-en': sharedService.Lang == 'en'
              }" />
          </div>

          <label class="mx-2">
            {{
            sharedService.Lang == "ar"
            ? sharedService.CurrencyNameAr
            : sharedService.AccountCurrency.Currency.GlobalName
            }}
          </label>

          <label class="mx-2">{{ "ExpiredAfter" | translate }}</label>
          <input class="form-control" type="number" formControlName="ServiceLoyaltyPointsExpiryDays"
            [disabled]="isServiceDisabled" min="0">
          <!-- <select class="form-control" formControlName="ServiceLoyaltyPointsExpiryDays">
            <option *ngFor="let item of numberOfExpired" [value]="item.value" [disabled]="isServiceDisabled">
              {{ sharedService.Lang == "ar" ? item.nameAr : item.nameEn }}
            </option>
          </select> -->
        </div>
        <div class="form-group" *ngIf="!ServiceLoyaltyPointsExpiryDays.value">{{ "NoExpiryDays" | translate }}</div>

        <hr />
      </div>

      <p>{{ "ProductsPoints" | translate }}</p>
      <div class="form-inline">
        <div class="form-group">
          <label class="mx-2">
            {{ "Given" | translate }}
          </label>
          <input class="form-control mx-2" type="number" formControlName="ProductLoyaltyPointsGivenForEveryXDollarSpent"
            min="0" oninput="validity.valid||(value='');" />
          <label class="mx-2">{{ "Points" | translate }}</label>
          <div class="no-border">
            <input class="form-control" type="number"
              formControlName="ProductPointsAndDollarsAreGivenForEveryXDollarSpent" min="0"
              oninput="validity.valid||(value='');" />
            <img src="../../assets/img/info.png" alt="" title="{{ 'lessMoney' | translate }}" [ngClass]="{
                'img-ar': sharedService.Lang == 'ar',
                'img-en': sharedService.Lang == 'en'
              }" />
          </div>

          <label class="mx-2">
            {{
            sharedService.Lang == "ar"
            ? sharedService.CurrencyNameAr
            : sharedService.AccountCurrency.Currency.GlobalName
            }}</label>
          <label class="mx-2">{{ "ExpiredAfter" | translate }}</label>
          <input class="form-control" type="number" formControlName="ProductLoyaltyPointsExpiryDays" min="0"
            [disabled]="isProductDisabled" oninput="validity.valid||(value='');">
          <!-- <select
            class="form-control"
            formControlName="ProductLoyaltyPointsExpiryDays" >
            <option *ngFor="let item of numberOfExpired" [value]="item.value" [disabled]="isProductDisabled">
              {{ sharedService.Lang == "ar" ? item.nameAr : item.nameEn }}
            </option>
          </select> -->
        </div>
        <div class="form-group" *ngIf="!ProductLoyaltyPointsExpiryDays.value">{{ "NoExpiryDays" | translate }}</div>
        <hr />
      </div>
      <p>{{ "GiftsPoint" | translate }}</p>
      <div class="form-inline">
        <div class="form-group">
          <label class="mx-2">
            {{ "Given" | translate }}
          </label>
          <input class="form-control mx-2" type="number"
            formControlName="GiftCardLoyaltyPointsGivenForEveryXDollarSpent" min="0"
            oninput="validity.valid||(value='');" />
          <label class="mx-2">{{ "Points" | translate }}</label>
          <div class="no-border">
            <input class="form-control" type="number"
              formControlName="GiftCardPointsAndDollarsAreGivenForEveryXDollarSpent" min="0"
              oninput="validity.valid||(value='');" />
            <img src="../../assets/img/info.png" alt="" title="{{ 'lessMoney' | translate }}" [ngClass]="{
                'img-ar': sharedService.Lang == 'ar',
                'img-en': sharedService.Lang == 'en'
              }" />
          </div>

          <label class="mx-2">
            {{
            sharedService.Lang == "ar"
            ? sharedService.CurrencyNameAr
            : sharedService.AccountCurrency.Currency.GlobalName
            }}</label>
          <label class="mx-2">{{ "ExpiredAfter" | translate }}</label>
          <input class="form-control" type="number" formControlName="GiftCardLoyaltyPointsExpiryDays" min="0"
            [disabled]="isGiftCardDisabled" min="0" oninput="validity.valid||(value='');">
          <!-- <select class="form-control" formControlName="GiftCardLoyaltyPointsExpiryDays">
            <option *ngFor="let item of numberOfExpired" [value]="item.value" [disabled]="isGiftCardDisabled">
              {{ sharedService.Lang == "ar" ? item.nameAr : item.nameEn }}
            </option>
          </select> -->
        </div>
        <div class="form-group" *ngIf="!GiftCardLoyaltyPointsExpiryDays.value">{{ "NoExpiryDays" | translate }}</div>
        <hr />
      </div>
      <p>{{ "PackagesPoints" | translate }}</p>
      <div class="form-inline">
        <div class="form-group">
          <label class="mx-2">
            {{ "Given" | translate }}
          </label>
          <input class="form-control mx-2" type="number" formControlName="PackageLoyaltyPointsGivenForEveryXDollarSpent"
            min="0" oninput="validity.valid||(value='');" />
          <label class="mx-2">{{ "Points" | translate }}</label>
          <div class="no-border">
            <input class="form-control" type="number"
              formControlName="PackagePointsAndDollarsAreGivenForEveryXDollarSpent" min="0"
              oninput="validity.valid||(value='');" />
            <img src="../../assets/img/info.png" alt="" title="{{ 'lessMoney' | translate }}" [ngClass]="{
                'img-ar': sharedService.Lang == 'ar',
                'img-en': sharedService.Lang == 'en'
              }" />
          </div>

          <label class="mx-2">
            {{
            sharedService.Lang == "ar"
            ? sharedService.CurrencyNameAr
            : sharedService.AccountCurrency.Currency.GlobalName
            }}</label>
          <label class="mx-2">{{ "ExpiredAfter" | translate }}</label>
          <input class="form-control" type="number" formControlName="PackageLoyaltyPointsExpiryDays"
            [disabled]="isPackageDisabled" min="0" oninput="validity.valid||(value='');">
          <!-- <select class="form-control" formControlName="PackageLoyaltyPointsExpiryDays">
            <option *ngFor="let item of numberOfExpired" [value]="item.value" [disabled]="isPackageDisabled">
              {{ sharedService.Lang == "ar" ? item.nameAr : item.nameEn }}
            </option>
          </select> -->
        </div>
        <div class="form-group" *ngIf="!PackageLoyaltyPointsExpiryDays.value">{{ "NoExpiryDays" | translate }}</div>
      </div>
    </div>
  </section>
  <section>
    <p class="title mt-3">{{ "WithdrawalPolicy" | translate }}</p>
    <div>
      <p class="d-in">{{ "PipsWithdrawal" | translate }}</p>
      <span class="m-lr-5">{{ "equations" | translate }}</span>
      <div class="form-inline">
        <div class="form-group">
          <label class="mx-2">
            {{ "ForAll" | translate }}
          </label>
          <div class="no-border">
            <input class="form-control mx-2" type="number" formControlName="XPointsCount" min="0"
              oninput="validity.valid||(value='');" (change)="onCalculateThePoints()" />
            <img src="../../assets/img/info.png" alt="" title="{{ 'lessPoint' | translate }}" [ngClass]="{
                'imgr-ar': sharedService.Lang == 'ar',
                'imgr-en': sharedService.Lang == 'en'
              }" />
          </div>

          <label class="mx-2">{{ "Points=" | translate }}</label>
          <input class="form-control" type="number" formControlName="XPointsValue" min="0"
            oninput="validity.valid||(value='');" (change)="onCalculateThePoints()" />
          <label class="mx-2">
            {{
            sharedService.Lang == "ar"
            ? sharedService.CurrencyNameAr
            : sharedService.AccountCurrency.Currency.GlobalName
            }}
          </label>
        </div>
      </div>
    </div>
  </section>

  <section>
    <div>
      <div class="flex">
        <p class="">{{ "CouponWithdrawal" | translate }}</p>
        <span class="switch">
          <label>
            <input type="checkbox" checked="checked" formControlName="AllowDynamicExchange"
              (change)="onChangeAllowDynamicExchange()" min="0" oninput="validity.valid||(value='');" />
            <span class="lever"></span>
          </label>
        </span>
      </div>

      <div class="no-border" *ngIf="loyalityPointsForm.get('AllowDynamicExchange').value"
        formArrayName="PredefinedPromocodes">
        <ng-container *ngFor="let lessonForm of myFormArray?.controls; let i = index" [>
          <div class="row no-border" [formGroupName]="i">
            <div class="col-12 col-md-3 no-border">
              <div class="form-group">
                <label>
                  {{ "CouponPoints" | translate }}
                </label>
                <input type="number" class="form-control" name="NumberOfPoints" formControlName="NumberOfPoints"
                  (input)="onChangeNumberOfPoints($event, i)" min="0" oninput="validity.valid||(value='');" />
                <span style="color: red" *ngIf="
                    lessonForm.get('NumberOfPoints').hasError('minValue') &&
                    lessonForm.dirty
                  ">
                  {{ "NumberOfPointsValidations" | translate }}
                </span>
              </div>
            </div>

            <div class="col-12 col-md-3 no-border">
              <div class="form-group">
                <label>
                  {{ "CouponValue" | translate }}
                  <span class="span">
                    ({{
                    sharedService.Lang == "ar"
                    ? sharedService.AccountCurrency.Currency
                    .NameCultureVariant
                    : sharedService.AccountCurrency.Currency?.GlobalName
                    }})
                  </span>
                </label>
                <input type="number" class="form-control" readonly formControlName="Amount" name="Amount" min="0"
                  oninput="validity.valid||(value='');" />
              </div>
            </div>

            <div class="col-12 col-md-3 no-border">
              <div class="form-group">
                <label>
                  {{ "CouponColor" | translate }}
                </label>
                <ngx-colors style="display: table; margin: 5px" ngx-colors-trigger
                  formControlName="ColorCode"></ngx-colors>
              </div>
            </div>

            <div class="col-12 col-md-3 no-border felx-center">
              <button type="button" class="delete-btn" (click)="onRemoveControl(i)">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </ng-container>
        <button type="button" class="add-btn mx-3" (click)="addControl()">
          <i class="fas fa-plus plus-icon"></i>
        </button>
      </div>
    </div>
  </section>

  <section>
    <p class="title">{{ "Prevention" | translate }}</p>
    <div>
      <div class="flex">
        <p class="">{{ "PreventOnUsingPromo" | translate }}</p>
        <span class="switch">
          <label>
            <input type="checkbox" checked="checked" formControlName="PreventOnUsingPromo" min="0"
              oninput="validity.valid||(value='');" />
            <span class="lever"></span>
          </label>
        </span>
      </div>

      <div class="flex">
        <p class="">{{ "PreventOnUsingDiscount" | translate }}</p>
        <span class="switch">
          <label>
            <input type="checkbox" checked="checked" formControlName="PreventOnUsingDiscount" min="0"
              oninput="validity.valid||(value='');" />
            <span class="lever"></span>
          </label>
        </span>
      </div>

      <div class="flex">
        <p class="">{{ "PreventOnUsingOffer" | translate }}</p>
        <span class="switch">
          <label>
            <input type="checkbox" checked="checked" formControlName="PreventOnUsingOffer" min="0"
              oninput="validity.valid||(value='');" />
            <span class="lever"></span>
          </label>
        </span>
      </div>

    </div>
  </section>

</form>