import { Component, OnInit, Inject, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { SharedService } from '../../../services/other/shared.service';
import { TransactionsProductOrService } from 'src/app/models/classes/transactions/transactions-product-or-service';
import { BookingOrTransactionItemType } from 'src/app/models/enums/booking-or-transaction-item-type';


@Component({
  selector: 'app-print-gym',
  templateUrl: './print-gym.component.html',
  styleUrls: ['./print-gym.component.scss']
})
export class PrintGymComponent implements OnInit {

  @Input() public item;

  @Input('item')
  set in(item) {

    this.item = item;

    if (item?.TrProductServices && item?.TrProductServices?.length > 0) {

      this.GymTransactionItems = item?.TrProductServices?.
        filter(ti => ti.ItemType == BookingOrTransactionItemType.GymMembership);

      if (this.GymTransactionItems && this.GymTransactionItems.length > 1) {
        this.GymTransactionItems = this.GymTransactionItems.splice(0, 1);
      }

    }

  }


  @Input() public readonly AccountSetup;

  public printLang: string = 'ar';
  cashsaleId: number;
  public ContractTermText: string;
  public GymTransactionItems: TransactionsProductOrService[] = [];

  constructor(
    public sharedService: SharedService,
    private route: ActivatedRoute,
  ) {

  }

  public get bookingOrTransactionItemType(): typeof BookingOrTransactionItemType {
    return BookingOrTransactionItemType;
  }

  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.LOW;
  // value = 'https://app.glamera.com/invoices/qr-code.pdf/';
  value = '';

  ngOnInit(): void {

    this.cashsaleId = this.route.snapshot.params['id'];
    this.value = 'https://app.glamera.com/invoices/' + this.item.Id;

    this.ContractTermText = this.item?.ContractTerms ?? this.AccountSetup?.ContractTermText;

    this.printLang = this.sharedService.Lang;

    if (this.AccountSetup?.SelectedPrintInvoiceLanguage && this.AccountSetup?.SelectedPrintInvoiceLanguage != 'all') {
      this.printLang = this.AccountSetup?.SelectedPrintInvoiceLanguage;
    }

  }
  PrintPage() {
    window.print();
  }


}
