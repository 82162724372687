import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AdminService } from '../../dashboard/admin/service/admin.service';
import { Client } from '../../models/classes/client/client';
import { MyUser } from '../../models/classes/my-user/my-user';
import { ModalPictureType } from '../../models/enums/modal-picture-type';
import { GetPictureSearchCriteria } from '../../models/search-criterias/get-picture-search-criteria';
import { SavePictureSearchCriteria } from '../../models/search-criterias/save-picture-search-criteria';
import { GetPictureSearchResult } from '../../models/search-result/get-picture-search-result';
import { UploadImageResult } from '../../models/search-result/upload-image-result';
import { ResponseState } from '../../models/support/response-state';
import { ClientService } from '../../services/client/client.service';
import { SharedService } from '../../services/other/shared.service';
import { UserService } from '../../services/user/user.service';
import { EmployeeService } from 'src/app/services/employee/employee.service';
import { Employee } from 'src/app/models/classes/employee/employee';

@Component({
  selector: 'app-modal-picture',
  templateUrl: './modal-picture.component.html',
  styleUrls: ['./modal-picture.component.scss']
})
export class ModalPictureComponent implements OnInit {

  @Input() user: MyUser;
  @Input() client: Client;
  @Input() employee: Employee;

  public CurrentModalPictureType: ModalPictureType;
  public ModalTitle: string;
  public Name: string;
  public NoPicture: string;
  public DeletePicture: string;
  public LoadingSavePicture: string;
  public SavePicture: string;
  public UploadImageResultList: UploadImageResult[] = [];
  public PicturePath: string;

  @Output() sendIdForDelete = new EventEmitter<string>();

  public FilesList: string[] = [];
  public Loading: boolean = false;
  constructor(
    public activeModal: NgbActiveModal,
    public sharedService: SharedService,
    public adminService: AdminService,
    public translate: TranslateService,
    public clientService: ClientService,
    public employeeService: EmployeeService,
    public userService: UserService) {

  }

  ngOnInit(): void {

    if (this.user && this.user != null) {

      this.CurrentModalPictureType = ModalPictureType.User;
      this.ModalTitle = "UserPicture";
      this.Name = this.user.FirstName + " " + this.user.LastName;
      this.NoPicture = "NoUserPicture";
      this.SavePicture = "SaveUserPicture";
      this.LoadingSavePicture = "LoadingSaveUserPicture";
      this.DeletePicture = "DeleteUserPicture";
      this.PicturePath = this.user.UserPicturePath;

    } else if (this.client && this.client != null) {

      this.CurrentModalPictureType = ModalPictureType.Client;
      this.ModalTitle = "ClientPicture";
      this.Name = this.client.Name;
      this.NoPicture = "NoClientPicture";
      this.SavePicture = "SaveClientPicture";
      this.LoadingSavePicture = "LoadingSaveClientPicture";
      this.DeletePicture = "DeleteClientPicture";
      this.PicturePath = this.client.ClientPicturePath;

    } else if (this.employee && this.employee != null) {

      this.CurrentModalPictureType = ModalPictureType.Employee;
      this.ModalTitle = "EmployeePicture";
      this.Name = this.employee.Name;
      this.NoPicture = "NoEmployeePicture";
      this.SavePicture = "SaveEmployeePicture";
      this.LoadingSavePicture = "LoadingSaveEmployeePicture";
      this.DeletePicture = "DeleteEmployeePicture";
      this.PicturePath = this.employee.EmployeePicturePath;

    }

  }
  deleteItem(item: any) {
    console.log(item.Id)
    this.sendIdForDelete.emit(item);
  }

  onSelectFile(event) {


    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event) => {

        if (this.CurrentModalPictureType == ModalPictureType.User) {

          this.user.UserPicturePath = (String)(event.target.result);

          if (this.user.Id == this.sharedService.UserId) {
            var Token = this.sharedService.TokenObject;
            Token.UserPicturePath = (String)(event.target.result);
            this.sharedService.TokenObject = Token;
          }

        } else if (this.CurrentModalPictureType == ModalPictureType.Client) {

          this.client.ClientPicturePath = (String)(event.target.result);

        }
        else if (this.CurrentModalPictureType == ModalPictureType.Employee) {

          this.employee.EmployeePicturePath = (String)(event.target.result);

        }

        this.PicturePath = (String)(event.target.result);
      }

      this.FilesList.push(event.target.files[0]);

      const formData = new FormData();
      formData.append('ImagesFolder', 'Pictures');
      for (var x = 0; x < this.FilesList.length; x++) {
        formData.append('Files', this.FilesList[x]);
      }
      return this.adminService.PostImageFile(formData).subscribe(
        (response: any) => {
          if (response) {


            this.UploadImageResultList = response.Result;

            if (this.CurrentModalPictureType == ModalPictureType.User) {

              this.user.UserPictureName = this.UploadImageResultList[0].FileName;
              this.user.UserPictureFolder = this.UploadImageResultList[0].FolderName;
              this.sharedService.ToastrSuccess(this.translate.instant('DoneSetUserPictureSuccessfully.'));

            }
            else if (this.CurrentModalPictureType == ModalPictureType.Client) {

              this.client.ClientPictureName = this.UploadImageResultList[0].FileName;
              this.client.ClientPictureFolder = this.UploadImageResultList[0].FolderName;
              this.sharedService.ToastrSuccess(this.translate.instant('DoneSetClientPictureSuccessfully.'));

            }
            else if (this.CurrentModalPictureType == ModalPictureType.Employee) {

              this.employee.EmployeePictureName = this.UploadImageResultList[0].FileName;
              this.employee.EmployeePictureFolder = this.UploadImageResultList[0].FolderName;
              this.sharedService.ToastrSuccess(this.translate.instant('DoneSetEmployeePictureSuccessfully.'));

            }

          }
          else {
            this.sharedService.ToastrError(response.Message);
          }
        });
    }
  }

  public get modalPictureType():
    typeof ModalPictureType {
    return ModalPictureType;
  }

  public DeleteAnyPicture() {
    if (this.user) {
      this.DeleteUserPicture();
    } else {
      this.DeleteClientPicture();
    }
  }
  public DeleteUserPicture() {
    //this.url = null;
    this.user.UserPicturePath = null;
    this.user.UserPictureFolder = null;
    this.user.UserPictureName = null;

    if (this.user.Id == this.sharedService.UserId) {
      var Token = this.sharedService.TokenObject;
      Token.UserPicturePath = null;
      this.sharedService.TokenObject = Token;
    }

    this.sharedService.ToastrSuccess(this.translate.instant('DoneDeleteUserPictureSuccessfully.'));

  }
  public DeleteClientPicture() {
    //this.url = null;
    this.client.ClientPicturePath = null;
    this.client.ClientPictureFolder = null;
    this.client.ClientPictureName = null;
    this.PicturePath = null;

    this.sharedService.ToastrSuccess(this.translate.instant('DoneDeleteClientPictureSuccessfully.'));

  }

  ngOnDestroy() {

    if (this.user && this.user.Id == this.sharedService.UserId) {


      var criteria = new GetPictureSearchCriteria();

      criteria.AccountSetupId = this.sharedService.AccountSetupId;
      criteria.UserId = this.sharedService.UserId;

      this.userService.GetUserPicture(criteria)
        .subscribe((response: GetPictureSearchResult) => {

          if (response.State == ResponseState.Success) {

            if (this.user.Id = this.sharedService.UserId) {

              var Token = this.sharedService.TokenObject;
              Token.UserPicturePath = response.PicturePath;
              this.user.UserPicturePath = response.PicturePath;
              this.sharedService.TokenObject = Token;
            }
          }
        });
    }
  }

  SaveAnyPicture() {

    if (this.user) {
      this.SaveUserPicture();
    }
    else if (this.client) {
      this.SaveClientPicture();
    }
    else if (this.employee) {
      this.SaveEmployeePicture();
    }
  }

  SaveUserPicture() {

    if (!this.sharedService.IsMainBranch &&
      !this.sharedService.IsAdmin &&
      !this.sharedService.IsManager &&
      this.sharedService.UserId != this.user.Id) {

      this.sharedService.ToastrError(this.translate.instant('Sorry!EditUserProfilePictureAllowedOnlyFor(Admin,Manager,MainBranch,UserOwnsThePicture)!'));
      return false;

    } else {

      this.Loading = true;

      var criteria = new SavePictureSearchCriteria();

      criteria.AccountSetupId = this.sharedService.AccountSetupId;
      criteria.Id = this.user.Id;
      criteria.PictureName = this.user.UserPictureName;
      criteria.PictureFolder = this.user.UserPictureFolder;
      criteria.PicturePath = this.user.UserPicturePath;

      this.userService.SaveUserPicture(criteria)
        .subscribe((response: GetPictureSearchResult) => {
          this.Loading = false;
          if (response.State == ResponseState.Success) {

            var Token = this.sharedService.TokenObject;
            Token.UserPicturePath = response.PicturePath;
            this.sharedService.TokenObject = Token;
            this.sharedService.ToastrSuccess(this.translate.instant('DoneSaveUserPictureSuccessfully.'));
          }
        });

    }

  }

  SaveClientPicture() {

    this.Loading = true;

    var criteria = new SavePictureSearchCriteria();

    criteria.AccountSetupId = this.sharedService.AccountSetupId;
    criteria.Id = this.client.Id;
    criteria.PictureName = this.client.ClientPictureName;
    criteria.PictureFolder = this.client.ClientPictureFolder;
    criteria.PicturePath = this.client.ClientPicturePath;

    this.clientService.SaveClientPicture(criteria)
      .subscribe((response: GetPictureSearchResult) => {
        this.Loading = false;
        if (response.State == ResponseState.Success) {

          this.sharedService
            .ToastrSuccess(this.translate.instant('DoneSaveClientPictureSuccessfully.'));
        }
      });
  }

  SaveEmployeePicture() {

    this.Loading = true;

    var criteria = new SavePictureSearchCriteria();

    criteria.AccountSetupId = this.sharedService.AccountSetupId;
    criteria.Id = this.employee.Id;
    criteria.PictureName = this.employee.EmployeePictureName;
    criteria.PictureFolder = this.employee.EmployeePictureFolder;
    criteria.PicturePath = this.employee.EmployeePicturePath;

    this.employeeService.SaveEmployeePicture(criteria)
      .subscribe((response: GetPictureSearchResult) => {
        this.Loading = false;
        if (response.State == ResponseState.Success) {

          this.sharedService
            .ToastrSuccess(this.translate.instant('DoneSaveEmployeePictureSuccessfully.'));
        }
      });
  }


}
