<div id="printGroupInfo">
  <div class="modal-header">
    <h5 class="modal-title text-capitalize" id="exampleModalLabel">
      <i *ngIf="GroupId && GroupId > 0" class="fas fa-edit"></i>
      <i *ngIf="!GroupId" class="fas fa-save"></i> &nbsp;
      <span *ngIf="!GroupId">{{'AddGroup' | translate}}</span>
      <span *ngIf="GroupId && GroupId > 0"> {{'UpdateGroup' | translate}}</span>
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12" *ngIf="Countries && Categories && Services && Clients && Nationalities && !LoadingGetGroup"
        style="margin-top: -3%;">
        <div class="text-start py-4">
          <div class="formCard">

            <div class="row">
              <div class="col-lg-12">
                <form [formGroup]="GForm" (ngSubmit)="SaveGroup()">

                  <div class="row">

                    <div class="form-group col-md-6">
                      <label>{{ 'GroupName' | translate }}</label>
                      <input type="text" class="form-control" id="Name" formControlName="Name" required maxlength="100">
                    </div>



                  </div>



                  <div class="row">

                    <div class="col-md-12">
                      <span class="text-info" style="font-size: 15px;">
                        {{ 'FilterClientsWay-AllClientsThatResultFromThisFiltersWillBeInGroup' | translate }}
                      </span>
                    </div>

                    <div class="col-md-12" style="margin-top:1%">

                    </div>

                    <div class="form-group col-md-6">
                      <label>{{ 'AllClientsThatNamesOrMobilesStartsThisWordsOrNumbers' | translate }}</label>
                      <input type="text" class="form-control"
                        placeholder="{{ 'AllClientsThatNamesOrMobilesStartsThisWordsOrNumbers' | translate }}"
                        [(ngModel)]="clientSearchCriteria.FreeText" [ngModelOptions]="{standalone: true}">
                    </div>

                    <div class="form-group col-md-3">

                      <div class="row">

                        <label class="col-12">
                          {{ 'TheGender' | translate }}
                        </label>
                        <div class="col-4">
                          <label class="radio-button-Stock">
                            <input type="radio" name="Gender" [(ngModel)]="clientSearchCriteria.Gender"
                              [ngModelOptions]="{standalone: true}" [value]="gender.Male">
                            <span class="label-visible">
                              <span class="fake-radiobutton"></span>
                              {{ 'Male'| translate }}
                            </span>
                          </label>
                        </div>
                        <div class="col-4">
                          <label class="radio-button-Stock">
                            <input type="radio" name="Gender" [(ngModel)]="clientSearchCriteria.Gender"
                              [ngModelOptions]="{standalone: true}" [value]="gender.Female">
                            <span class="label-visible">
                              <span class="fake-radiobutton"></span>
                              {{ 'Female'| translate }}
                            </span>
                          </label>
                        </div>
                        <div class="col-4">
                          <label class="radio-button-Stock">
                            <input type="radio" name="Gender" [(ngModel)]="clientSearchCriteria.Gender"
                              [ngModelOptions]="{standalone: true}" [value]="gender.NotDefined">
                            <span class="label-visible">
                              <span class="fake-radiobutton"></span>
                              {{ 'All'| translate }}
                            </span>
                          </label>
                        </div>
                      </div>

                    </div>

                    <div class="form-group col-md-3" style="margin-top:1%">
                      <label>{{ 'ClientOrdersTotalGreaterThanOrEqual' | translate }}</label>
                      <input type="text" class="form-control"
                        placeholder="{{ 'ClientOrdersTotalGreaterThanOrEqual' | translate }}"
                        [(ngModel)]="clientSearchCriteria.ClientOrdersTotalGreaterThanOrEqual"
                        [ngModelOptions]="{standalone: true}">
                    </div>

                    <div class="col-md-2">
                      <label>{{ 'AddingDateFrom' | translate }}</label>
                      <div class="input-group">
                        <input class="form-control" (change)="AddingDateFromChanged()" type="date"
                          [(ngModel)]="clientSearchCriteria.AddingDateFrom" [ngModelOptions]="{standalone: true}"
                          name="date">
                      </div>
                    </div>
                    <div class="col-md-2">
                      <label>{{ 'AddingDateTo' | translate }}</label>
                      <div class="input-group">

                        <input class="form-control" type="date" [(ngModel)]="clientSearchCriteria.AddingDateTo"
                          [ngModelOptions]="{standalone: true}" name="date">
                      </div>
                    </div>
                    <div class="col-md-2">
                      <label>{{ 'LastOrderDateFrom' | translate }}</label>
                      <div class="input-group">

                        <input class="form-control" type="date" (change)="LastOrderDateFromChanged()"
                          [(ngModel)]="clientSearchCriteria.LastOrderDateFrom" [ngModelOptions]="{standalone: true}"
                          name="date">
                      </div>
                    </div>
                    <div class="col-md-2">
                      <label>{{ 'LastOrderDateTo' | translate }}</label>
                      <div class="input-group">

                        <input class="form-control" type="date" [(ngModel)]="clientSearchCriteria.LastOrderDateTo"
                          [ngModelOptions]="{standalone: true}" name="date">
                      </div>
                    </div>
                    <div class="col-md-2">
                      <label>{{ 'BirthDateFrom' | translate }}</label>
                      <div class="input-group">

                        <input class="form-control" type="date" (change)="BirthDateFromChanged()"
                          [(ngModel)]="clientSearchCriteria.BirthDateFrom" [ngModelOptions]="{standalone: true}"
                          name="date">
                      </div>
                    </div>
                    <div class="col-md-2">
                      <label>{{ 'BirthDateTo' | translate }}</label>
                      <div class="input-group">
                        <input class="form-control" type="date" [(ngModel)]="clientSearchCriteria.BirthDateTo"
                          [ngModelOptions]="{standalone: true}" name="date">
                      </div>
                    </div>



                    <div class="form-group col-md-4" style="margin-top:1%">
                      <label>{{ 'AddingBranches' | translate }}</label>
                      <ng-select placeholder="{{ 'SearcAddingBranches' | translate }}" [multiple]="true"
                        [hideSelected]="true" [items]="Branches" bindLabel="Name" bindValue="Id"
                        [(ngModel)]="clientSearchCriteria.AddingBranchesIds" [ngModelOptions]="{standalone: true}"
                        (search)="onSearchBranches($event)" [searchFn]="customSearchFnBranches">
                      </ng-select>
                    </div>


                    <div class="form-group col-md-4" style="margin-top:1%">
                      <label>{{ 'ClientCountry' | translate }}</label>
                      <ng-select placeholder="{{ 'SearchClientCountry' | translate }}" [multiple]="false"
                        [hideSelected]="true" [items]="Countries" bindLabel="Name" bindValue="Id" (change)="GetCities()"
                        [(ngModel)]="clientSearchCriteria.CountryId" [ngModelOptions]="{standalone: true}"
                        (search)="onSearchCountries($event)" [searchFn]="customSearchFnCountries">
                      </ng-select>
                    </div>

                    <div class="form-group col-md-4" style="margin-top:1%">
                      <label>{{ 'ClientsNationalities' | translate }}</label>
                      <ng-select placeholder="{{ 'SearchClientsNationalities' | translate }}" [multiple]="true"
                        [hideSelected]="true" [items]="Nationalities" bindLabel="GlobalName" bindValue="Id"
                        [(ngModel)]="clientSearchCriteria.NationalitiesIds" [ngModelOptions]="{standalone: true}"
                        (search)="onSearchNationalities($event)" [searchFn]="customSearchFnNationalities">
                      </ng-select>
                    </div>

                    <div class="form-group col-md-4" style="margin-top:1%">
                      <label>{{ 'ClientCity' | translate }}</label>
                      <ng-select placeholder="{{ 'SearchClientCity' | translate }}" [multiple]="false"
                        [hideSelected]="true" [items]="Cities" bindLabel="Name" bindValue="Id"
                        [(ngModel)]="clientSearchCriteria.CityId" [ngModelOptions]="{standalone: true}"
                        (search)="onSearchCities($event)" [searchFn]="customSearchFnCities">
                      </ng-select>
                    </div>

                    <div class="form-group col-md-4" style="margin-top:1%">
                      <label>{{ 'UsedCategories' | translate }}</label>
                      <ng-select placeholder="{{ 'SearchUsedCategories' | translate }}" [multiple]="true"
                        [hideSelected]="true" [items]="Categories" bindLabel="Name" bindValue="Id"
                        [(ngModel)]="clientSearchCriteria.UsedCategoriesIds" [ngModelOptions]="{standalone: true}"
                        (search)="onSearchCategories($event)" [searchFn]="customSearchFnCategories">
                      </ng-select>
                    </div>

                    <div class="form-group col-md-4" style="margin-top:1%">
                      <label>{{ 'UsedServices' | translate }}</label>
                      <ng-select placeholder="{{ 'SearchUsedServices' | translate }}" [multiple]="true"
                        [hideSelected]="true" [items]="Services" bindLabel="Name" bindValue="Id"
                        [(ngModel)]="clientSearchCriteria.UsedServicesIds" [ngModelOptions]="{standalone: true}"
                        (search)="onSearchServices($event)" [searchFn]="customSearchFnServices">
                      </ng-select>
                    </div>

                    <div class="col-md-6 col-12 mb-3">
                      <label>{{ 'ExcludedClients' | translate }}</label>
                      <ng-select [placeholder]="'Clients-TypeAtLeast3CharsToSearch' | translate"
                        [(ngModel)]="clientSearchCriteria.ExcludedClientsIds" [ngModelOptions]="{standalone: true}"
                        [multiple]="true" [items]="Clients" bindLabel="Name" bindValue="Id"
                        [searchFn]="customSearchFnClients" (search)="onSearchClients($event)">
                        <ng-template ng-option-tmp let-item="item">
                          <div class="list-client-insearch">
                            <div [ngClass]="{'first-char': !item.IsBanned, 'first-char-banned' : item.IsBanned }"
                              [title]="item.IsBanned ? (('BannedClient' | translate) + ' - ' + ('BanReason' | translate) + ':' + item.BanReason) : item.Name">
                              {{ item.Name | slice : 0 : 1 }}
                            </div>
                            <div [ngClass]="{'info-client': !item.IsBanned, 'info-client-banned' : item.IsBanned }"
                              [title]="item.IsBanned ? (('BannedClient' | translate) + ' - ' + ('BanReason' | translate) + ':' + item.BanReason) : item.Name">
                              {{ item.Name }} <br />
                              <span>{{ item.Mobile }}</span>
                            </div>
                          </div>
                        </ng-template>
                      </ng-select>
                    </div>

                    <div class="col-md-6 col-12 mb-3">
                      <label>{{ 'AdditionalClients' | translate }}</label>
                      <ng-select [placeholder]="'Clients-TypeAtLeast3CharsToSearch' | translate"
                        [(ngModel)]="clientSearchCriteria.AdditionalClientsIds" [ngModelOptions]="{standalone: true}"
                        [multiple]="true" [items]="Clients" bindLabel="Name" bindValue="Id"
                        [searchFn]="customSearchFnClients" (search)="onSearchClients($event)">
                        <ng-template ng-option-tmp let-item="item">
                          <div class="list-client-insearch">
                            <div [ngClass]="{'first-char': !item.IsBanned, 'first-char-banned' : item.IsBanned }"
                              [title]="item.IsBanned ? (('BannedClient' | translate) + ' - ' + ('BanReason' | translate) + ':' + item.BanReason) : item.Name">
                              {{ item.Name | slice : 0 : 1 }}
                            </div>
                            <div [ngClass]="{'info-client': !item.IsBanned, 'info-client-banned' : item.IsBanned }"
                              [title]="item.IsBanned ? (('BannedClient' | translate) + ' - ' + ('BanReason' | translate) + ':' + item.BanReason) : item.Name">
                              {{ item.Name }} <br />
                              <span>{{ item.Mobile }}</span>
                            </div>
                          </div>
                        </ng-template>
                      </ng-select>
                    </div>

                    <div class="form-group col-md-12">
                      <label>{{ 'Notes' | translate }}</label>
                      <input type="text" class="form-control" id="Notes" formControlName="Notes" maxlength="200">
                    </div>

                    <div class="col-md-12"
                      *ngIf="CheckIfFiltersAccepted() && totalClientsNumbersInGroup >= 0 && !LoadingGetTotalClientsNumbersInGroup">
                      <label>{{ 'ExpectedNumberOfClientsFromSelectedFilters' | translate }}</label>
                      &nbsp; <label style="font-size:20px;">{{totalClientsNumbersInGroup}}</label>
                    </div>

                  </div>

                </form>
              </div>
            </div>

          </div>

        </div>
      </div>

      <div class="col-lg-12"
        *ngIf="!Countries || !Categories || !Services || !Clients || !Nationalities || LoadingGetGroup">
        <div class="col-md-12 text-center py-4 my-4">
          <i class="fas fa-circle-notch fa-spin fa-3x"></i>
          <h1>{{'Loading' | translate}} </h1>
        </div>
      </div>

    </div>
  </div>
  <div class="modal-footer">

    <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">
      {{'Close' | translate}}</button>
    <button type="button" class="btn btn-success"
      [disabled]="LoadingSaveGroup || !GForm || !GForm.valid || !CheckIfFiltersAccepted()" (click)="SaveGroup()">
      <span *ngIf="!LoadingSaveGroup">
        {{'SaveGroup' | translate}}
      </span>
      <span *ngIf="LoadingSaveGroup">
        {{'LoadingSaveGroup' | translate}}
      </span>
    </button>
    <button type="button" class="btn btn-success"
      [disabled]="!CheckIfFiltersAccepted() || LoadingGetTotalClientsNumbersInGroup"
      (click)="GetTotalClientsNumbersInGroup()">
      <span *ngIf="!LoadingGetTotalClientsNumbersInGroup">
        {{'ShowNumberOfClients' | translate}}
      </span>
      <span *ngIf="LoadingGetTotalClientsNumbersInGroup">
        {{'LoadingNumberOfClients' | translate}}
      </span>
    </button>

  </div>
</div>
