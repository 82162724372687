import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { CompanyImagesServiceEditedName } from './company-image-edited-name.service';
import { CompanyServices } from '../../models/classes/company/company-services';
import { CompanyServiceSearchCriteria } from '../../models/search-criterias/companyservices-search-criteria';
import { CompanyServicesSearchResult } from '../../models/search-result/companyservices-search-result';
import { ExecutionResponse } from '../../models/support/execution-response';
import { ValidationCriteria } from '../../models/support/validation-criterias/validation-criteria';

@Injectable({ providedIn: 'root' })
export class CompanyServicesService extends BaseService {
  private companyServicesUrl //= super.BaseUrl() + 'companyService';  // URL to web api

  constructor(
    private _BaseService: BaseServiceSZ,
    private http: HttpClient, private companyImagesService: CompanyImagesServiceEditedName
  ) {
    super();
    this.companyServicesUrl = this._BaseService.ApiUrl + 'companyServices';
  }


  /** GET companyService by id. Will 404 if id not found */
  getcompanyService(id: String): Observable<ExecutionResponse<CompanyServices>> {

    return this.http.post<ExecutionResponse<CompanyServices>>(this.companyServicesUrl + '/getById', id, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched companyService id=${id}');
          this._BaseService.ValidationResult(res);

        }),
        catchError(this._BaseService.handleError<ExecutionResponse<CompanyServices>>('getcompanyService id=${id}'))
      );
  }

  addcompanyService(companyService: CompanyServices): Observable<ExecutionResponse<CompanyServices>> {
    companyService.CompanyId = this.companyImagesService.getCompanyId();
    return this.http.post<ExecutionResponse<CompanyServices>>(this.companyServicesUrl + '/Create', companyService, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('added companyService w/ id=${companyService.Id}');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<ExecutionResponse<CompanyServices>>('addcompanyService id=${id}'))
      );
  }

  /* GET companyServices whose name contains search term */
  searchcompanyServices(companyServiceSearchCriteria: CompanyServiceSearchCriteria): Observable<CompanyServicesSearchResult> {
    // 
    companyServiceSearchCriteria.AccountSetupId = super.getAccountSetupId();
    companyServiceSearchCriteria.CompanyId = this.companyImagesService.getCompanyId();
    return this.http.post<CompanyServicesSearchResult>(this.companyServicesUrl + '/Get', companyServiceSearchCriteria, super.getHeaders())
      .pipe(
        tap(companyServices => {
          this._BaseService.log('fetched companyServices');
          this._BaseService.ValidationResult(companyServices);
        }), catchError(this._BaseService.handleError<CompanyServicesSearchResult>('searchcompanyServices id=${id}')));
  }
  /** DELETE: delete the companyService from the server */
  deletecompanyService(companyService: CompanyServices | number): Observable<ExecutionResponse<CompanyServices>> {
    return this.http.post<ExecutionResponse<CompanyServices>>(this.companyServicesUrl + '/delete', companyService, super.getHeaders()).pipe(
      tap(res => {

        this._BaseService.ValidationResult(res);
        this._BaseService.log('deleted companyService id=${id}')

      }),
      catchError(this._BaseService.handleError<ExecutionResponse<CompanyServices>>('deletecompanyService'))
    );
  }
  IsServicesCodeUnique(validationCriteria: ValidationCriteria): Observable<ExecutionResponse<Boolean>> {
    validationCriteria.AccountSetupId = super.getAccountSetupId();

    return this.http.post<ExecutionResponse<Boolean>>(this.companyServicesUrl + '/IsCodeUnique', validationCriteria, super.getHeaders()).pipe(
      tap(_ => this._BaseService.log('deleted client id=${id}')),
      catchError(this._BaseService.handleError<ExecutionResponse<Boolean>>('deleteClient'))
    );
  }

  IsServicesDescriptionUnique(validationCriteria: ValidationCriteria): Observable<ExecutionResponse<Boolean>> {
    validationCriteria.AccountSetupId = super.getAccountSetupId();

    return this.http.post<ExecutionResponse<Boolean>>(this.companyServicesUrl + '/IsDescriptionUnique', validationCriteria, super.getHeaders()).pipe(
      tap(_ => this._BaseService.log('deleted client id=${id}')),
      catchError(this._BaseService.handleError<ExecutionResponse<Boolean>>('deleteClient'))
    );
  }
  /** PUT: update the companyService on the server */
  updatecompanyService(companyService: CompanyServices): Observable<ExecutionResponse<CompanyServices>> {
    return this.http.post<ExecutionResponse<CompanyServices>>(this.companyServicesUrl + '/Update', companyService, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('added companyService w/ id=${companyService.Id}');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<ExecutionResponse<CompanyServices>>('addcompanyService id=${id}'))
      );
  }
  IsNameUnique(validationCriteria: ValidationCriteria): Observable<ExecutionResponse<Boolean>> {
    validationCriteria.AccountSetupId = super.getAccountSetupId();
    validationCriteria.CompanyId = this.companyImagesService.getCompanyId();
    return this.http.post<ExecutionResponse<Boolean>>(this.companyServicesUrl + '/IsNameUnique', validationCriteria, super.getHeaders()).pipe(
      tap(_ => this._BaseService.log('deleted Category id=${id}')),
      catchError(this._BaseService.handleError<ExecutionResponse<Boolean>>('deleteCategory'))
    );
  }


}