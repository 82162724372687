import { DOCUMENT } from '@angular/common';
import { Component, Inject, Renderer2, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'lib-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
})
export class LanguageComponent implements OnInit {
  selectedLanguage!: string;

  constructor(
    private readonly sharedService: SharedService,
    private readonly renderer: Renderer2,
    private readonly translate: TranslateService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.selectedLanguage = this.sharedService.getLocalStorage('lang') ?? 'en';
    this.translate.setDefaultLang(this.selectedLanguage);
  }

  ngOnInit(): void {
    this.onChangeDirection();
  }

  onChangeLang(lang: string): void {
    this.selectedLanguage = lang;
    this.sharedService.setLocalStorage('lang', lang);
    this.translate.use(this.selectedLanguage);
    this.onChangeDirection();
    // window.location.reload();
  }

  onChangeDirection(): void {
    this.renderer.setAttribute(
      this.document.documentElement,
      'dir',
      this.selectedLanguage === 'en' ? 'ltr' : 'rtl'
    );

    document.body.className =
      this.selectedLanguage === 'en' ? 'body-en' : 'body-ar';
    // window.location.reload();
  }
}
