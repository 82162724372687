import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { SharedService } from '../other/shared.service';
import { RetailProduct } from '../../models/classes/product/retail-product';
import { RetailProductSearchCriteria } from '../../models/search-criterias/retail-product-search-criteria';
import { RetailProductSearchResult } from '../../models/search-result/retail-product-search-result';
import { ExecutionResponse } from '../../models/support/execution-response';
import { ValidationCriteria } from '../../models/support/validation-criterias/validation-criteria';
import { MessageService } from '../message/message.service';

@Injectable({
  providedIn: 'root'
})

export class RetailProductService extends BaseService {



  private retailProductsUrl  //= super.BaseUrl() + 'RetailProduct';  // URL to web api

  constructor(
    private http: HttpClient, private messageService: MessageService,
    private _sharedService: SharedService,
    private _BaseService: BaseServiceSZ

  ) {
    super();
    
    this.retailProductsUrl = this._sharedService.ApiUrl + 'RetailProduct';

  }

  // /** GET retailProducts from the server */
  // getRetailProducts(): Observable<RetailProduct[]> {
  //   return this.http.get<RetailProduct[]>(this.retailProductsUrl, this._sharedService.getHeaders())
  //     .pipe(
  //       tap(retailProducts => this._BaseService.log('fetched retailProducts')),
  //       catchError(this._BaseService.handleError('getRetailProducts', []))
  //     );
  // }


  /** GET retailProduct by id. Will 404 if id not found */
  getRetailProduct(id: String): Observable<ExecutionResponse<RetailProduct>> {
    //  getRetailProduct(id: String): ExecutionResponse<RetailProduct> {
    //const url = '${this.retailProductsUrl}/${id}';

    return this.http.post<ExecutionResponse<RetailProduct>>(this.retailProductsUrl + '/getById', id, this._sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched retailProduct id=${id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<RetailProduct>>('getRetailProduct id=${id}'))
      );
  }



  /* GET retailProducts whose name contains search term */

  searchRetailProducts(retailProductSearchCriteria: RetailProductSearchCriteria):
    Observable<RetailProductSearchResult> {
    retailProductSearchCriteria.AccountSetupId = this._sharedService.AccountSetupId;
    return this.http.post<RetailProductSearchResult>(this.retailProductsUrl + '/Get', retailProductSearchCriteria, this._sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched retailProducts');
          this._BaseService.ValidationResult(res);
        }),

        //catchError(this._BaseService.handleError('getRetailProducts', []))
      );

    //if (!term.trim()) {
    //  // if not search term, return empty retailProduct array.
    //  return of([]);
    //}
    //return this.http.get<RetailProduct[]>('${this.retailProductsUrl}/?name=${term}').pipe(
    //  tap(_ => this._BaseService.log('found retailProducts matching "${term}"')),
    //  catchError(this._BaseService.handleError<RetailProduct[]>('searchRetailProducts', []))
    //);
  }

  //////// Save methods //////////

  /** POST: add a new retailProduct to the server */
  addRetailProduct(retailProduct: RetailProduct): Observable<ExecutionResponse<RetailProduct>> {
    retailProduct.AccountSetupId = this._sharedService.AccountSetupId;
    ;
    let rr = this._sharedService.getHeaders();
    return this.http.post<ExecutionResponse<RetailProduct>>(this.retailProductsUrl + '/Create', retailProduct, this._sharedService.getHeaders())
      .pipe(
        tap(res => {

          this._BaseService.log('added retailProduct w/ id=${retailProduct.Id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<RetailProduct>>('addRetailProduct id=${id}'))
      );
  }


  /** DELETE: delete the retailProduct from the server */
  deleteRetailProduct(retailProduct: RetailProduct | number): Observable<ExecutionResponse<RetailProduct>> {

    return this.http.post<ExecutionResponse<RetailProduct>>(this.retailProductsUrl + '/delete', retailProduct, this._sharedService.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deleted retailProduct id=${id}');
        this._BaseService.ValidationResult(res);
      }),

      catchError(this._BaseService.handleError<ExecutionResponse<RetailProduct>>('deleteRetailProduct'))
    );
  }


  IsRetailProductCodeUnique(validationCriteria: ValidationCriteria): Observable<ExecutionResponse<Boolean>> {
    validationCriteria.AccountSetupId = this._sharedService.AccountSetupId;
    return this.http.post<ExecutionResponse<Boolean>>(this.retailProductsUrl + '/IsCodeUnique', validationCriteria, this._sharedService.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deleted client id=${id}');
        this._BaseService.ValidationResult(res);
      }),

      catchError(this._BaseService.handleError<ExecutionResponse<Boolean>>('deleteClient'))
    );
  }

  IsRetailProductDescriptionUnique(validationCriteria: ValidationCriteria): Observable<ExecutionResponse<Boolean>> {
    validationCriteria.AccountSetupId
    return this.http.post<ExecutionResponse<Boolean>>(this.retailProductsUrl + '/IsDescriptionUnique', validationCriteria, this._sharedService.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deleted client id=${id}');
        this._BaseService.ValidationResult(res);
      }),

      catchError(this._BaseService.handleError<ExecutionResponse<Boolean>>('deleteClient'))
    );
  }

  /** PUT: update the retailProduct on the server */
  updateRetailProduct(retailProduct: RetailProduct): Observable<ExecutionResponse<RetailProduct>> {
    retailProduct.AccountSetupId = this._sharedService.AccountSetupId;

    return this.http.post<ExecutionResponse<RetailProduct>>(this.retailProductsUrl + '/Update', retailProduct, this._sharedService.getHeaders())
      .pipe(
        tap(res => {

          let x = res;
          this._BaseService.log('added retailProduct w/ id=${retailProduct.Id}');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<ExecutionResponse<RetailProduct>>('addRetailProduct id=${id}'))
      );
  }

  //   public getJSON(): Observable<any> {
  //     return this.http.get("../../assets/i18n/ar.json");
  // }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */

}
