import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Group } from '../../models/classes/account/group';
import { SecurityGroupDto } from '../../models/classes/account/security-group-dto';
import { SecurityGroupSearchCriteria } from '../../models/search-criterias/security-group-search-criteria';
import { SecurityGroupSearchResult } from '../../models/search-result/security-group-search-result';
import { ExecutionResponse } from '../../models/support/execution-response';
import { MessageService } from '../message/message.service';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { SharedService } from '../other/shared.service';
import { UIStateService } from '../other/ui-state.service';

@Injectable({
  providedIn: 'root'
})

export class SecurityGroupService extends BaseService {


  securityGroupUrl: string;
  loadingGroup: boolean;
  submittingGroup: boolean;
  constructor(private http: HttpClient,
    private messageService: MessageService,
    private _BaseService: BaseServiceSZ,
    private sharedService: SharedService,
    private _UIStateService: UIStateService
   
    ) {

    super();
    this.securityGroupUrl = this.sharedService.ApiUrl/*this._BaseService.ApiUrl */ + 'SecurityGroup';
  }


  CreateSecurityGroup(group: any): Observable<ExecutionResponse<Group>> {
    this.submittingGroup = true;
    return this.http.post<ExecutionResponse<Group>>(this.securityGroupUrl + '/CreateGroup', group, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);
          this.submittingGroup = false;

        }),
        catchError(err=>{
          this.submittingGroup = false;
          throw err;
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<Group>>('getSecurityGroup id=${id}'))
      );
  }

  UpdateSecurityGroup(group: Group): Observable<ExecutionResponse<Group>> {
    return this.http.post<ExecutionResponse<Group>>(this.securityGroupUrl + '/UpdateGroup', group, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);

        }),

        catchError(this._BaseService.handleError<ExecutionResponse<Group>>('getSecurityGroup id=${id}'))
      );
  }


  getById(id:number): Observable<ExecutionResponse<SecurityGroupDto>> {

    this.loadingGroup = true;
    return this.http.get<ExecutionResponse<SecurityGroupDto>>(this.securityGroupUrl + `/${id}`, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);
          this.loadingGroup = false;
        }),
        catchError(err=>{
          this.loadingGroup = false;
          throw err;
        }),
        catchError(this._BaseService.handleError<ExecutionResponse<SecurityGroupDto>>('getSecurityGroup id=${id}'))
      );
  }

  getSecurityGroup(criteria?: SecurityGroupSearchCriteria): Observable<SecurityGroupSearchResult> {

    this.loadingGroup = true;
    criteria.AccountSetupId = this.getAccountSetupId();
    return this.http.post<SecurityGroupSearchResult>(this.securityGroupUrl + '/Get', criteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);
          this.loadingGroup = false;

        }),
        catchError(err=>{
          this.loadingGroup = false;
          throw err;
        }),
        catchError(this._BaseService.handleError<SecurityGroupSearchResult>('getSecurityGroup id=${id}'))
      );
  }
  getSecurityGroupScreens(criteria?: SecurityGroupSearchCriteria): Observable<SecurityGroupSearchResult> {


    criteria.AccountSetupId = this.getAccountSetupId();
    var ss = super.getHeaders();
    return this.http.post<SecurityGroupSearchResult>(this.securityGroupUrl + '/GetWithScreenPermissions', criteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);

        }),

        catchError(this._BaseService.handleError<SecurityGroupSearchResult>('getSecurityGroup id=${id}'))
      );
  }
  getSecurityGroupDynamic(criteria?: SecurityGroupSearchCriteria): Observable<ExecutionResponse<Group[]>> {


    criteria.AccountSetupId = this.getAccountSetupId();
    return this.http.post<ExecutionResponse<Group[]>>(this.securityGroupUrl + '/GetDynamic', criteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);

        }),

        catchError(this._BaseService.handleError<ExecutionResponse<Group[]>>('getSecurityGroup GetDynamic=${id}'))
      );
  }
  getSecurityGroupById(Id: number): Observable<ExecutionResponse<Group>> {

    return this.http.post<ExecutionResponse<Group>>(this.securityGroupUrl + '/GetById', Id, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);

        }),

        catchError(this._BaseService.handleError<ExecutionResponse<Group>>('getSecurityGroup id=${id}'))
      );
  }
  DeleteSecurityGroupById(Id: number): Observable<ExecutionResponse<Group>> {

    return this.http.post<ExecutionResponse<Group>>(this.securityGroupUrl + '/Delete', Id, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);

        }),

        catchError(this._BaseService.handleError<ExecutionResponse<Group>>('DeleteSecurityGroup id=${id}'))
      );
  }




}
