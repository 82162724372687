import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExecutionResponse } from '../../models/support/execution-response';
import { HttpBaseService } from '../other/httpbase.service';
import { SharedService } from '../other/shared.service';

@Injectable({
  providedIn: 'root',
})
export class AppointmentService {
  constructor(
    public sharedService: SharedService,
    private httpBaseService: HttpBaseService,
    private http: HttpClient
  ) { }

  validateClientSegmentation(query: any): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Get('v2/offers/ValidateByClient', query);
  }
  /*========= GetCategory ================================================*/
  GetCategory(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Category/Get', body);
  }
  GetCategoryItem(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Category/GetItemNames', body);
  }

  GetServices(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Category/GlameraServices', body);
  }

  /*========= Get Products  ================================================*/
  GetStockMainData(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('StockMainData/Get', body);
  }
  GetRetailProduct(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('RetailProduct/Get', body);
  }
  GetRetailProductLiteForSales(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('RetailProduct/GetLiteForSales', body);
  }
  //*============ GetPackages =========================================*/
  Getpackages() {
    return this.http.get(
      `${this.sharedService.ApiUrl}v2/packages`,
      this.sharedService.getHeaders()
    );
  }
  GetPackageById(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('OffersSetting/GetPackageById', body);
  }
  Getgiftcards() {
    return this.http.get(
      `${this.sharedService.ApiUrl}v2/GiftCards/Company?Status=1`,
      this.sharedService.getHeaders()
    );
    // return this.http.get('https://apiios.glamour-plan.com/api/v2/giftcards', this.sharedService.getHeaders());
  }

  GetTransactionsById(id: number) {
    return this.http.get(
      `${this.sharedService.ApiUrl}Transactions/GetById/${id}`,
      this.sharedService.getHeaders()
    );
  }

  /*========= GetTransactionHeader  ================================================*/
  GetTransactionHeader(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Transactions/GetTransactionHeader', body);
    // {"EmployeeId":13640,"AccountSetupId":7299}
  }
  /*=================================================================*/
  GetClientBooking(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Booking/GetClientBooking', body);
  }
  CreateClients(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Client/Create', body);
  }
  GetBookingNotPayedChargesNew(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post(
      'Transactions/GetBookingNotPayedChargesNew',
      body
    );
  }

  GetDropdowns(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('SystemCodes/GetDropdowns', body);
  }
  /*=================================================================*/


  getPromoCodes(promoCode, clientId: number, serviceIds: number[], packageIds: number[], bookingId?: number) {
    let criteria = '';
    if (serviceIds) {
      for (let index = 0; index < serviceIds.length; index++) {
        criteria += 'serviceIds=' + serviceIds[index] + '&';
      }
    }
    if (packageIds) {
      for (let index = 0; index < packageIds.length; index++) {
        criteria += 'packageIds=' + packageIds[index] + '&';
      }
    }
    return this.http.get(`${this.sharedService.ApiUrl}v2/PromoCodes/Branch?status=1&${criteria}AccountSetupId=${this.sharedService.AccountSetupId}&clientId=${clientId}&PromoCode=${promoCode}&BookingId=${bookingId}`, this.sharedService.getHeaders());
  }
  /*================ Equipment Or Room =======================================*/
  GetEquipmentOrRoom(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('EquipmentOrRoom/Get', body);
  }
  /*================ MakeRequestPermission =======================================*/
  MakeRequestPermission(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post(
      'RequestPermissionNotificationActions/MakeRequestPermission',
      body
    );
  }
  /*=================================================================*/
  MakeTransactionValidation(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post(
      'CashierDailyAndShiftValidation/MakeTransactionValidation',
      body
    );
  }
  GetTransactions(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Transactions/Get', body);
  }
  ReportInvoiceToZatca(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Transactions/ReportInvoiceToZatca', body);
  }
  CreateTransactions(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Transactions/Create', body);
  }
  GetBookings(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Booking/Get', body);
  }
  getByIdBookings(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Booking/getById', body);
  }

  /*=================================================================*/
  GetEmployeeTimeSlots(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('v2/BookingService/GetEmployeeTimeSlots', body)
  }
  GetClientPoints(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('LoyalityPoints/GetPointsDashboard', body);
  }
  // GetBookingNotPayedChargesNew(body): Observable<ExecutionResponse<any>> {
  //   return this.httpBaseService.Post('Transactions/GetBookingNotPayedChargesNew', body)
  // }
  /*========= GetCategoryProduct  ================================================*/
  GetProduct(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('RetailProduct/GetProduct', body);
  }
  GetBookingNotPayedCharges(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post(
      'Transactions/GetBookingNotPayedCharges',
      body
    );
  }

  CreateBookingOrTransaction(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post(
      'BookingOrTransaction/CreateBookingOrTransaction',
      body
    );
  }
  /*============ GetByIdAccountSetup =========================================*/
  GetByIdAccountSetup(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('AccountSetup/getById', body);
  }

  /*=================================================================*/
  GetEmployee(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Employee/Get', body);
  }
  GetAvailableEmployee(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('BookingService/GetAvailableEmployee', body)
  }
}
