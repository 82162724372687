import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AdminService } from 'src/app/dashboard/admin/service/admin.service';
import { ResponseState } from 'src/app/models/support/response-state';
import { BaseServiceSZ } from 'src/app/services/other/baseService';
import { SharedService } from 'src/app/services/other/shared.service';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent implements OnInit {

  myFiles4: string[] = [];
  imagesUploaded4;
  imageAry4 = [];
  imageUrl4 = [];
  imagesPushAry4 = []
  @Input() branchId: number;
  @Output() fileUploaded = new EventEmitter<any>();
  @Input() path: string;
  constructor(private adminService: AdminService, private sharedService:SharedService) { }   
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }
 

  UploadBranchLogo(e) {
    for (let x = 0; x < e.target.files.length; x++) {
      this.myFiles4.push(e.target.files[x]);
    }

    for (let y = 0; y < this.myFiles4.length; y++) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imageUrl4.push(e.target.result);
      }
      reader.readAsDataURL(e.target.files[y]);
    }

    const formData = new FormData();
    formData.append('ImagesFolder', 'CompanyImgs');
    for (var x = 0; x < this.myFiles4.length; x++) {
      formData.append('Files', this.myFiles4[x]);
    }
    return this.adminService.PostImageFile(formData).subscribe(
      (response: any) => {
        if (response) {
          this.imagesUploaded4 = response.Result
          this.path = response.Result[0].Path;
          this.fileUploaded.emit(response.Result[0]);
        }
        else {
          this.sharedService.ToastrError(response.Message);
        }
      });
  }
}
