import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationComponent } from './pagination.component';
import { FormsModule } from '@angular/forms';
import { LangModule } from '../../shared/language/lang.module';

@NgModule({
  declarations: [PaginationComponent],
  imports: [
    CommonModule,
    FormsModule,
    LangModule
  ],
  exports: [PaginationComponent],

})
export class PaginationModule { }
