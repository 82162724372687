import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject  } from 'rxjs';
import {  tap  } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Title, Meta } from '@angular/platform-browser';
import { SzSupportLogInDTO } from '../../models/classes/account/sz-support-log-in-dto';
import { AccountCurrency } from '../../models/classes/account-setup/account-currency';
import { MyUser } from '../../models/classes/my-user/my-user';
import { AppEnum } from '../../models/enums/app-enum';
import { Countries } from '../../models/enums/countries';
import { EditBillOption } from '../../models/enums/edit-bill-options';
import { RequestPermissionNotificationType } from '../../models/enums/request-permission-notification-type';
import { Pages } from '../../models/pages';
import { UrlSetting } from '../../models/support/url-setting';
import { Token } from '../../models/token';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ResponseState } from '../../models/support/response-state';
import { GetCurrentAccountSetupService } from '../account/get-current-account-setup.service';
import { AccountSetup } from '../../models/classes/account-setup/account-setup';
import { LangObject } from '../../models/classes/other/lang-object';
import { RequestsPermissionsNotificationsSoundsType } from '../../models/enums/requests-permissions-notifications-sounds-type';
import { RequestPermissionNotificationStatus } from '../../models/enums/request-permission-notification-status';
import { AddedByDevice } from '../../models/enums/added-by-device';
import { ExecutionResponse } from '../../models/support/execution-response';
import { Company } from '../../models/classes/company/company';
import { GetCurrentCompanyService } from '../account/get-current-company.service';
import { HandleScriptType } from '../../models/enums/dynamicScript/handle-script-type';
import { ScriptType } from '../../models/enums/dynamicScript/script-type';
import { PageType } from '../../models/enums/dynamicScript/page-type';
import { ClientsQueueSystemType } from 'src/app/models/enums/clients_queue_system_type';
import { TranslateService } from '@ngx-translate/core';
import { LicenseService } from '../licenses/license.service';
import { debug } from 'console';

declare var $: any;

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private dataStore: object = {};
  pages: any;

  public CurrentCompany: Company;
  public LangList: LangObject[] = [];
  public TempLangKey = '';
  public langs = ['ar', 'en'];
  public accountAdded: any;
  public ZatcaStartDate: Date = new Date(2024, 2, 1);

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private getCurrentCompanyService: GetCurrentCompanyService,
    private getCurrentAccountSetupService: GetCurrentAccountSetupService,
    private licenseService: LicenseService,
    private title: Title,
    private meta: Meta
  ) {
    let url = {
      BioTimeSignalRUrl: environment.BioTimeSignalRUrl,
      ApiUrl: environment.ApiUrl,
      NewApiUrl: environment.NewApiUrl,
      ApiUrlV2: environment.ApiUrlV2,
      OldApiUrlFromGlamatekWeb: environment.OldApiUrlFromGlamatekWeb,
      OnlineReportDevUrl: environment.OnlineReportDevUrl,
      ReportDevUrl: environment.ReportDevUrl,
      UploadUrl: environment.UploadUrl,
      SurePayUrl: environment.SurePayUrl,
    };
    this.setDataIntoStore('urlSetting', url);
    this.pages = Pages;
  }


  IsPreciseIsTwoOrLess(num, digits: number): boolean {
    return String(num).indexOf(".") <= -1 || String(num).split(".")[1]?.length <= digits;
  }

  /**
   * Example => (2.525 This Will Rounded To =>> 2.53)
   */
  RoundAwayFromZero_Up(startValue: number, digits: number) {
    var decimalValue = 0;
    digits = digits || 0;
    startValue *= Math.pow(10, digits + 1);
    decimalValue = Math.floor(startValue) - Math.floor(startValue / 10) * 10;
    startValue = Math.floor(startValue / 10);
    if (decimalValue >= 5) {
      startValue += 1;
    }
    startValue /= Math.pow(10, digits);
    return startValue;
  }

  RoundUp(startValue: number, digits: number) {


    if (this.IsPreciseIsTwoOrLess(startValue, digits)) {
      return startValue;
    }

    var decimalValue = 0;
    digits = digits || 0;
    startValue *= Math.pow(10, digits + 1);
    decimalValue = Math.floor(startValue) - Math.floor(startValue / 10) * 10;
    startValue = Math.floor(startValue / 10);
    //if (decimalValue >= 5) {
    startValue += 1;
    //}
    startValue /= Math.pow(10, digits);
    return startValue;
  }

  RoundDown(startValue: number, digits: number) {

    if (this.IsPreciseIsTwoOrLess(startValue, digits)) {
      return startValue;
    }

    var decimalValue = 0;
    digits = digits || 0;
    startValue *= Math.pow(10, digits + 1);
    decimalValue = Math.floor(startValue) - Math.floor(startValue / 10) * 10;
    startValue = Math.floor(startValue / 10);
    /*if (decimalValue > 5) {
      startValue += 1;
    }*/
    startValue /= Math.pow(10, digits);
    return startValue;
  }

  /**
   * Example => (2.525 This Will Rounded To =>> 2.52)
   */
  RoundToZero_Down(startValue: number, digits: number) {
    var decimalValue = 0;
    digits = digits || 0;
    startValue *= Math.pow(10, digits + 1);
    decimalValue = Math.floor(startValue) - Math.floor(startValue / 10) * 10;
    startValue = Math.floor(startValue / 10);
    if (decimalValue > 5) {
      startValue += 1;
    }
    startValue /= Math.pow(10, digits);
    return startValue;
  }

  GetMetaTags(metaTags) {
    this.title.setTitle(metaTags.MetaTitle);
    this.meta.addTag({ name: 'description', content: metaTags.MetaTitle });
    this.meta.addTag({ name: 'keywords', content: metaTags.MetaTitle });
  }

  public SetTranslationPipeData() {
    this.langs.forEach((lang) => {
      var filePath = './assets/i18n/' + lang + '.json';
      fetch(filePath)
        .then((res) => res.json())
        .then((jsonData) => {
          this.LangList.push({ LangKey: lang, LangKeysWithValues: jsonData });
        });
    });
  }

  public getHeaders(type: 'FORM' | 'JSON' | 'ExportExcel' = 'JSON') {

    var headersObject = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + this.Token)
        .set('Accept-Language', this.Lang)
        .set('Lang', this.Lang)
        .set(
          'CurrentBranchId',
          this.CurrentBranchId ? this.CurrentBranchId.toString() : ''
        )
        .set('AppId', AppEnum.GlameraBusinessWeb.toString())
        .set('CompanyId', this.CompanyId ? this.CompanyId.toString() : '')
        .set('DEVICETYPE', 'Branch')
        .set('AddedByDevice', AddedByDevice.GlameraBusinessWebInBranch + '')
        .set('IsMainBranch', this.IsMainBranch ? 'TRUE' : 'FALSE')
        .set('Access-Control-Allow-Headers', '*'),
    };

    headersObject.headers =
      type == 'JSON'
        ? headersObject.headers.set(
          'Content-Type',
          type == 'JSON'
            ? 'application/json'
            : type == 'ExportExcel' ?
              'application/octet-stream' : 'application/x-www-form-urlencoded'
        )
        : headersObject.headers;

    return headersObject;
  }

  public GetAndSetCurrentBranch() {
    this.getCurrentAccountSetupService
      .getCurrentAccountSetup(this.ApiUrl, this.AccountSetupId)
      .subscribe((result) => {
        if (result.State == ResponseState.Success) {

          this.setLocalStorageItemByKey("CurrentBranch", result.Result);
        }
      });
    return null;
  }

  public GetAndSetCurrentCompany() {
    this.getCurrentCompanyService
      .getCompanyByIdLite(this.ApiUrl, this.getHeaders(), this.CompanyId)
      .subscribe((response: ExecutionResponse<Company>) => {
        if (response.State == ResponseState.Success) {
          this.CurrentCompany = response.Result;
        }
      });
    return null;
  }


  public getCurrentSubscription() {
   return  this.licenseService
      .CheckCurrentSubscription(this.AccountSetupId)
      .pipe(tap((result) => {
          if (result.state == ResponseState.Success) {
            this.setLocalStorageItemByKey("IsActiveSubscription", result.data.hasActiveLicense);
            this.setLocalStorageItemByKey("IsTrial", result.data.license == null ? false : result.data.license?.isTrial);

          }
      }));
  }


  public CheckCurrentSubscription() {
    return {
      IsActiveSubscription: this.getLocalStorageItemByKey("IsActiveSubscription") ?? false,
      IsTrial: this.getLocalStorageItemByKey("IsTrial") ?? false
    };

  }




  public getHeaderExportFile() {
    // return {
    //   headers: new HttpHeaders({
    //     'Authorization': 'Bearer ' + this.Token,
    //     'Content-Type': 'application/octet-stream',
    //   }), responseType: 'blob',
    // }
    // {
    //   headers: new HttpHeaders()
    //     .set('Content-Type', 'application/octet-stream')
    //     .set('responseType', 'blob')
    //     .set('Authorization', 'Bearer ' + this.Token)
    // };
  }
  //==============================

  private sharedData: Subject<any> = new Subject<any>();
  sharedData$: Observable<any> = this.sharedData.asObservable();

  setData(updatedData) {
    this.sharedData.next(updatedData);
  }

  private subject = new Subject<any>();

  sendMessage(test) {
    this.subject.next(test);
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  getCurrentDirection(): string {
    return this.Lang === 'en' ? 'ltr' : 'rtl';
  }

  //==============================
  public getDataFromStore(key: string): any {

    if (this.dataStore[key] && key != 'lang') {
      return this.dataStore[key];
    }
    this.dataStore[key] = this.getLocalStorageItemByKey(key);
    return this.dataStore[key];
  }

  public setDataIntoStore(key: string, data: any): void {
    this.dataStore[key] = data;
    this.setLocalStorageItemByKey(key, data);
  }

  private getLocalStorageItemByKey(key: string) {
    let item = localStorage.getItem(key);
    if (item && item != 'undefined' && item != 'null') {
      return item.startsWith('{') || item.startsWith('[')
        ? JSON.parse(item)
        : item;
    }
    return null;
  }

  private setLocalStorageItemByKey(key: string, value: any) {
    let item = typeof value == 'object' ? JSON.stringify(value) : value;
    localStorage.setItem(key, item);
  }

  public get ApiUrl(): string {
    let urlSetting = <UrlSetting>this.getDataFromStore('urlSetting');
    return urlSetting ? urlSetting.ApiUrl : null;
  }

  public get NewApiUrl(): string {
    let urlSetting = <UrlSetting>this.getDataFromStore('urlSetting');
    return urlSetting ? urlSetting.NewApiUrl : null;
  }

  public get BioTimeSignalRUrl(): string {
    let urlSetting = <UrlSetting>this.getDataFromStore('bioTimeSignalRUrl');
    return urlSetting ? urlSetting.BioTimeSignalRUrl : null;
  }
  public get UploadUrl(): string {
    let urlSetting = <UrlSetting>this.getDataFromStore('urlSetting');
    return urlSetting ? urlSetting.UploadUrl : null;
  }

  public get SurePayUrl(): string {
    let urlSetting = <UrlSetting>this.getDataFromStore('urlSetting');
    return urlSetting ? urlSetting.SurePayUrl : null;
  }

  public get ApiUrlV2(): string {
    let urlSetting = <UrlSetting>this.getDataFromStore('urlSetting');
    return urlSetting ? urlSetting.ApiUrlV2 : null;
  }

  public set CountryId(countryId: number) {
    this.setDataIntoStore(LocalStorageKeys.COUNTRYID, countryId);
  }

  public get CountryId(): number {
    return +this.getDataFromStore(LocalStorageKeys.COUNTRYID);
  }

  public get CurrentBranch(): AccountSetup {
    return this.getDataFromStore(LocalStorageKeys.CurrentBranch);
  }

  public get RediredtURLFromAuthGuard(): string {
    return this.getDataFromStore(LocalStorageKeys.RediredtURLFromAuthGuard);
  }

  public set RediredtURLFromAuthGuard(url: string) {
    this.setDataIntoStore(LocalStorageKeys.RediredtURLFromAuthGuard, url);
  }

  public get UserIdentity(): MyUser {
    return this.getDataFromStore(LocalStorageKeys.USERIDENTITY);
  }
  public set UserIdentity(userIdentity: MyUser) {
    this.setDataIntoStore(LocalStorageKeys.USERIDENTITY, userIdentity);
  }
  public set CountryName(countryName: string) {
    this.setDataIntoStore(LocalStorageKeys.COUNTRYNAME, countryName);
  }

  public get CountryName(): string {
    return this.getDataFromStore(LocalStorageKeys.COUNTRYNAME);
  }

  public set CityId(cityId: number) {
    this.setDataIntoStore(LocalStorageKeys.CITYID, cityId);
  }

  public get CityId(): number {
    return +this.getDataFromStore(LocalStorageKeys.CITYID);
  }

  public set CityName(cityName: string) {
    this.setDataIntoStore(LocalStorageKeys.CITYNAME, cityName);
  }

  public get CityName(): string {
    return this.getDataFromStore(LocalStorageKeys.CITYNAME);
  }
  public set Country(country: string) {
    this.setDataIntoStore(LocalStorageKeys.COUNTRY, country);
  }

  public get Country(): string {
    return this.getDataFromStore(LocalStorageKeys.COUNTRY);
  }
  public set Lang(lang: string) {
    this.setDataIntoStore(LocalStorageKeys.LANG, lang);
  }
  public get Lang(): string {

    return this.getDataFromStore(LocalStorageKeys.LANG);
  }
  public set TokenObject(token: Token) {
    this.setDataIntoStore(LocalStorageKeys.TOKENOBJECT, token);
  }

  public get TokenObject(): Token {
    return this.getDataFromStore(LocalStorageKeys.TOKENOBJECT);
  }

  public get Token(): string {
    return this.TokenObject ? this.TokenObject.Token : null;
  }

  public get UserId(): number {
    return this.TokenObject ? this.TokenObject.UserId : null;
  }

  public get IsCashier(): boolean {
    return this.TokenObject ? this.TokenObject.IsCashier : null;
  }

  public get AllowUseGym(): boolean {
    return this.TokenObject ? this.TokenObject.AllowUseGym : null;
  }

  public get ShowIntroVideo(): boolean {
    return this.TokenObject ? this.TokenObject.ShowIntroVideo : null;
  }

  public get IsDemoPackage(): boolean {
    return this.TokenObject ? this.TokenObject.IsDemoPackage : null;
  }

  public get AllowManageDaysAndShifts(): boolean {
    return this.TokenObject ? this.TokenObject.AllowManageDaysAndShifts : null;
  }

  public get IsUseAutomationShiftClose(): boolean {
    return this.TokenObject ? this.TokenObject.IsUseAutomationShiftClose : null;
  }

  public get AllowUseServiceEmployeesOnlyInBookingAndSales(): boolean {
    return this.TokenObject
      ? this.TokenObject.AllowUseServiceEmployeesOnlyInBookingAndSales
      : null;
  }

  public get CountryCode(): Countries {
    return this.TokenObject ? this.TokenObject.Country : null;
  }

  public get UserPicturePath(): string {
    return this.TokenObject ? this.TokenObject.UserPicturePath : null;
  }

  public get AllowedRequestPermissionNotificationTypesInCurrentBranch(): RequestPermissionNotificationType[] {
    return this.TokenObject
      ? this.TokenObject
        .AllowedRequestPermissionNotificationTypesInCurrentBranch
      : null;
  }

  public get AllowedEditBillOptionsInCurrentBranch(): EditBillOption[] {
    return this.TokenObject
      ? this.TokenObject.AllowedEditBillOptionsInCurrentBranch
      : null;
  }

  public get AllowUsePayForPostpaidBillsWhenCreateBills(): boolean {
    return this.TokenObject
      ? this.TokenObject.AllowUsePayForPostpaidBillsWhenCreateBills
      : null;
  }

  public get IsCallCenter(): boolean {
    return this.TokenObject ? this.TokenObject.IsCallCenter : null;
  }

  public get GetFractionRoundCount(): number {
    return this.CurrentBranch?.FractionApproximationMethod != null &&
      this.CurrentBranch?.FractionApproximationMethod != undefined ?
      this.CurrentBranch.FractionApproximationMethod : 2;
  }

  public get AllowUseSessions(): boolean {
    return this.TokenObject ? this.TokenObject.AllowUseSessions : null;
  }

  public get PriceIncludeTax(): boolean {
    return this.TokenObject ? this.TokenObject.PriceIncludeTax : null;
  }

  public get IsAdmin(): boolean {
    return this.TokenObject ? this.TokenObject.IsAdmin : null;
  }

  public get BranchLogo(): string {
    return this.TokenObject ? this.TokenObject.BranchLogo : null;
  }


  public get AllowAccessToClientsMobileNumbers(): boolean {
    return this.TokenObject
      ? this.TokenObject.AllowAccessToClientsMobileNumbers
      : null;
  }

  public get AllowShowBookingNotificationsInAllSystemPages(): boolean {
    return this.TokenObject
      ? this.TokenObject.AllowShowBookingNotificationsInAllSystemPages
      : null;
  }

  public get AllowToTakeActionsOnRequestsPermissions(): boolean {
    return this.TokenObject
      ? this.TokenObject.AllowToTakeActionsOnRequestsPermissions
      : null;
  }

  public get AllowUsePostpaidSystemAndClientBalances(): boolean {
    return this.TokenObject
      ? this.TokenObject.AllowUsePostpaidSystemAndClientBalances
      : null;
  }

  public get AllowUseClientsBalancesInSales(): boolean {
    return this.TokenObject
      ? this.TokenObject.AllowUseClientsBalancesInSales
      : null;
  }

  public get BusinessTypeBackgroundPath(): string {
    return this.TokenObject
      ? this.TokenObject.BusinessTypeBackgroundPath
      : null;
  }

  public get AllowUseAddRemainingAmountToClientBalance(): boolean {
    return this.TokenObject
      ? this.TokenObject.AllowUseAddRemainingAmountToClientBalance
      : null;
  }

  public get AllowUseRequestsPermissions(): boolean {
    return this.TokenObject
      ? this.TokenObject.AllowUseRequestsPermissions
      : null;
  }

  public get PulsePrice(): number {
    return this.TokenObject ? this.TokenObject.PulsePrice : null;
  }

  public get AllowUseLazerSessions(): boolean {
    return this.TokenObject ? this.TokenObject.AllowUseLazerSessions : null;
  }

  public get AllowUseSalesTaxRate(): boolean {
    return this.TokenObject ? this.TokenObject.AllowUseSalesTaxRate : null;
  }

  public get TaxRegistrationNumber(): string {
    return this.TokenObject ? this.TokenObject.TaxRegistrationNumber : null;
  }
  public get AllowUsePurchasesTaxRate(): boolean {
    return this.TokenObject ? this.TokenObject.AllowUsePurchasesTaxRate : null;
  }


  public get AllowUseServiceByAreaInServices(): boolean {
    return this.TokenObject
      ? this.TokenObject.AllowUseServiceByAreaInServices
      : null;
  }

  public get AllowTaxExemptionForServices(): boolean {
    return this.TokenObject
      ? this.TokenObject.AllowTaxExemptionForServices
      : null;
  }

  public get AllowUseClientsQueueSystem(): boolean {
    return this.TokenObject
      ? this.TokenObject.AllowUseClientsQueueSystem
      : null;
  }

  public get AllowShowClientsQueueNumberInPrintBill(): boolean {
    return this.TokenObject
      ? this.TokenObject.AllowShowClientsQueueNumberInPrintBill
      : null;
  }

  public get AllowShowClientsQueueNumberInPrintEmployees(): boolean {
    return this.TokenObject
      ? this.TokenObject.AllowShowClientsQueueNumberInPrintEmployees
      : null;
  }



  public get ClientsQueueSystemType(): ClientsQueueSystemType {
    return this.TokenObject
      ? this.TokenObject.ClientsQueueSystemType
      : null;
  }

  public get AllowTaxExemptionForProducts(): boolean {
    return this.TokenObject
      ? this.TokenObject.AllowTaxExemptionForProducts
      : null;
  }

  public get AllowUseTaxForProducts(): boolean {
    return this.TokenObject ? this.TokenObject.AllowUseTaxForProducts : null;
  }

  public get AllowUseTaxForServices(): boolean {
    return this.TokenObject ? this.TokenObject.AllowUseTaxForServices : null;
  }

  public get AccountCurrency(): AccountCurrency {
    return this.TokenObject ? this.TokenObject.AccountCurrency : null;
  }

  public get AccountSetupId(): number {
    return this.TokenObject ? this.TokenObject.AccountSetupId : null;
  }
  public get IsZatcaAccepted(): boolean {
    return this.TokenObject ? this.TokenObject.IsZatcaAccepted : null;
  }
  public get CurrentBranchId(): number {
    return this.TokenObject ? this.TokenObject.CurrentBranchId : null;
  }
  public get IsMainBranch(): boolean {
    return this.TokenObject ? this.TokenObject.IsMainBranch : null;
  }
  public get CompanyName(): string {
    return this.TokenObject
      ? this.Lang == 'ar' && this.TokenObject.CompanyNameAr
        ? this.TokenObject.CompanyNameAr
        : this.TokenObject.CompanyNameEn
      : null;
  }

  public get BranchName(): string {
    return this.TokenObject
      ? this.Lang == 'ar' && this.TokenObject.BranchNameAr
        ? this.TokenObject.BranchNameAr
        : this.TokenObject.BranchNameEn
      : null;
  }

  public get BranchNameAr(): string {
    return this.TokenObject ? this.TokenObject.BranchNameAr : null;
  }
  public get BranchNameEn(): string {
    return this.TokenObject ? this.TokenObject.BranchNameEn : null;
  }

  public get EmployeeId(): number {
    return this.TokenObject ? this.TokenObject.EmployeeId : null;
  }
  public get CompanyId(): number {
    return this.TokenObject ? this.TokenObject.CompanyId : null;
  }
  public get UserName(): string {
    return this.TokenObject ? this.TokenObject.UserName : null;
  }
  public get UserFullName(): string {
    return this.TokenObject ? this.TokenObject.UserFullName : null;
  }
  public get Mobile(): number {
    return this.TokenObject ? this.TokenObject.Mobile : null;
  }

  public get CurrencyNameAr(): string {
    return this.TokenObject ? this.TokenObject.CurrencyNameAr : null;
  }

  public get CurrencyNameEn(): string {
    return this.TokenObject ? this.TokenObject.CurrencyNameEn : null;
  }

  public get LoggedInUsingSzSupport(): boolean {
    return this.TokenObject ? this.TokenObject.LoggedInUsingSzSupport : null;
  }

  public get SzSupportLogIn(): SzSupportLogInDTO {
    return this.TokenObject ? this.TokenObject.SzSupportLogIn : null;
  }

  public get IsManager(): boolean {
    return this.TokenObject ? this.TokenObject.User.IsManager : null;
  }

  public CheckAuthorization(permissionCode: number): boolean {
    if (this.UserIdentity.IsAdmin == false) {
      let index = -1;
      index = this.UserIdentity.AuthorizedPermissionCode.findIndex(
        (x) => x == permissionCode
      );
      return index != -1 ? true : false;
    }
    return true;
  }

  public CheckAuthorizationForSa3eed(permissionCode: number): boolean {
    return true;
  }

  /*========================================================================================*/
  // for login
  private messageSource = new BehaviorSubject<string>('');
  currentMessage = this.messageSource.asObservable();
  changeMessage(message) {
    this.messageSource.next(message);
  }
  /*========================================================================================*/

  IsDirty = false;

  updateDirty(dirty) {
    this.IsDirty = dirty;
  }

  get GetDirty() {
    return this.IsDirty;
  }

  playAudio(src: string) {
    let audio = new Audio();
    audio.src = src;
    audio.load();
    audio.play();
  }

  public HandelPlayAudio(
    type: RequestPermissionNotificationType,
    status: RequestPermissionNotificationStatus
  ) {
    var branch = this.CurrentBranch;

    if (
      branch &&
      branch.AllowUseRequestsPermissionsNotificationsSounds &&
      branch.RequestsPermissionsNotificationsSoundsType
    ) {
      if (
        branch.RequestsPermissionsNotificationsSoundsType ==
        RequestsPermissionsNotificationsSoundsType.UniformNormalBeep
      ) {
        this.playAudio(
          '../../../assets/audio/RequestsPermissions/UniformNormalBeep/Pixies.mp3'
        );
      } else if (
        branch.RequestsPermissionsNotificationsSoundsType ==
        RequestsPermissionsNotificationsSoundsType.VoiceExplanation
      ) {
        this.playAudio(
          '../../../assets/audio/RequestsPermissions/VoiceExplanation/' +
          RequestPermissionNotificationType[type] +
          RequestPermissionNotificationStatus[status] +
          '.mp3'
        );
      }
    }
  }

  IsNumber(value: string | number): boolean {
    return value != null && value !== '' && !isNaN(Number(value.toString()));
  }

  urlState;
  NavigateAway(state): boolean {
    if (this.IsDirty) {
      this.urlState = state.url;
      $('#AlertForPages').modal('show');
      return false;
    } else return true;
  }

  ToastrSuccess(message) {
    this.toastr.success(message || 'Success');
  }

  ToastrError(message) {
    this.toastr.error(message || 'Error');
  }

  ToastrInfo(message, title) {
    this.toastr.info(message, title);
  }

  ToastrWarning(message, title) {
    this.toastr.warning(message, title);
  }
  ToastrWarning2(message) {
    this.toastr.warning(message);
  }
  HideModal() {
    $('#ModalViewDailySales').modal('hide');
    $('#ModalAddEdit').modal('hide');
    $('#ModalEditCloseShift').modal('hide');
    $('#Edit').modal('hide');
    $('#Delete').modal('hide');
    $('#modalBooking').modal('hide');
    $('#ModalAddCategoryBranch').modal('hide');
    $('#ModalEditBranch').modal('hide');
    $('#modalBilling').modal('hide');
    $('#ModalViewCloseShift').modal('hide');
    $('#ModalViewDailyBalances').modal('hide');
    $('#modalPayment').modal('hide');
    $('#modalClientDownPayment').modal('hide');
    $('#AlertForPages').modal('hide');
    $('#addNew').modal('hide');
    $('#voucherModal').modal('hide');
    $('#ModalFreezClientMembership').modal('hide');
    $('#openAppointment').modal('hide');

    $('#ModalFreezMultiMembership').modal('hide');
    $('#ModalFreezIndividualMembership').modal('hide');
  }

  InitializePageScripts(pageType: PageType) {
    switch (pageType) {
      case PageType.MainPage:
        this.HandleScript(ScriptType.WebsiteChatScript, HandleScriptType.Add);
        this.HandleScript(
          ScriptType.RegistrationChatScript,
          HandleScriptType.Remove
        );
        this.HandleScript(ScriptType.SmartLookScript, HandleScriptType.Remove);
        break;

      case PageType.Registration:
        this.HandleScript(
          ScriptType.GoogleAnalyticsScript,
          HandleScriptType.Add
        );
        this.HandleScript(
          ScriptType.WebsiteChatScript,
          HandleScriptType.Remove
        );
        this.HandleScript(
          ScriptType.RegistrationChatScript,
          HandleScriptType.Add
        );
        this.HandleScript(ScriptType.SmartLookScript, HandleScriptType.Add);
        break;

      case PageType.LogIn:
        this.HandleScript(ScriptType.WebsiteChatScript, HandleScriptType.Add);
        this.HandleScript(
          ScriptType.RegistrationChatScript,
          HandleScriptType.Remove
        );
        this.HandleScript(ScriptType.SmartLookScript, HandleScriptType.Remove);
        break;

      default:
        break;
    }
  }

  RemovePageScripts(pageType: PageType) {
    switch (pageType) {
      case PageType.MainPage:
        this.HandleScript(
          ScriptType.WebsiteChatScript,
          HandleScriptType.Remove
        );
        this.HandleScript(
          ScriptType.RegistrationChatScript,
          HandleScriptType.Remove
        );
        this.HandleScript(ScriptType.SmartLookScript, HandleScriptType.Remove);
        break;
      case PageType.Registration:
        this.HandleScript(
          ScriptType.GoogleAnalyticsScript,
          HandleScriptType.Remove
        );
        this.HandleScript(
          ScriptType.RegistrationChatScript,
          HandleScriptType.Remove
        );
        this.HandleScript(ScriptType.WebsiteChatScript, HandleScriptType.Add);
        this.HandleScript(ScriptType.SmartLookScript, HandleScriptType.Remove);
        break;
      case PageType.LogIn:
        this.HandleScript(
          ScriptType.WebsiteChatScript,
          HandleScriptType.Remove
        );
        this.HandleScript(
          ScriptType.RegistrationChatScript,
          HandleScriptType.Remove
        );
        this.HandleScript(ScriptType.SmartLookScript, HandleScriptType.Remove);
        break;

      default:
        break;
    }
  }

  HandleScript(scriptType: ScriptType, handleScriptType: HandleScriptType) {
    switch (scriptType) {
      /*  case ScriptType.GoogleAnalyticsScript:
         switch (handleScriptType) {
           case HandleScriptType.Add:
             this.loadGoogleAnalyticsScript();
             break;
           case HandleScriptType.Remove:
             this.removeGoogleAnalyticsScript();
             break;
           default:
             break;
         }

         break; */
      case ScriptType.WebsiteChatScript:
        switch (handleScriptType) {
          case HandleScriptType.Add:
            this.loadWebsiteChatScript();
            break;
          case HandleScriptType.Remove:
            this.removeWebsiteChatScript();
            break;
          default:
            break;
        }
        break;
      case ScriptType.RegistrationChatScript:
        switch (handleScriptType) {
          case HandleScriptType.Add:
            this.loadRegistrationChatScript();
            break;
          case HandleScriptType.Remove:
            this.removeRegistrationChatScript();
            break;
          default:
            break;
        }
        break;

      case ScriptType.SmartLookScript:
        switch (handleScriptType) {
          case HandleScriptType.Add:
            this.loadSmartLookScript();
            break;
          case HandleScriptType.Remove:
            this.removeSmartLookScript();
            break;
          default:
            break;
        }
        break;

      default:
        break;
    }
  }

  private loadGoogleAnalyticsScript() {
    var env = environment;
    if (!env.production || !env.ApiUrl.toLowerCase().includes('proapi')) {
      return false;
    }

    var googleAnalyticsScript1 = document.createElement('script');
    googleAnalyticsScript1.setAttribute('id', 'googleAnalyticsScript1');
    googleAnalyticsScript1.async = true;
    googleAnalyticsScript1.src =
      'https://www.googletagmanager.com/gtag/js?id=G-EPVXQ65V9M';
    document.head.insertBefore(
      googleAnalyticsScript1,
      document.head.firstChild
    );

    var googleAnalyticsScript2 = document.createElement('script');
    googleAnalyticsScript2.setAttribute('id', 'googleAnalyticsScript2');
    googleAnalyticsScript2.innerHTML =
      'window.dataLayer = window.dataLayer || [];' +
      'function gtag(){dataLayer.push(arguments);}' +
      "gtag('js', new Date());" +
      "gtag('config', 'G-EPVXQ65V9M');";
    document.head.insertBefore(
      googleAnalyticsScript2,
      document.head.firstChild
    );
  }

  private removeGoogleAnalyticsScript() {
    var env = environment;
    if (!env.production || !env?.ApiUrl?.toLowerCase()?.includes('proapi')) {
      return false;
    }

    const script1 = document.getElementById('googleAnalyticsScript1');
    const script2 = document.getElementById('googleAnalyticsScript2');

    if (script1) script1.parentElement.removeChild(script1);

    if (script2) script2.parentElement.removeChild(script2);
  }

  private loadWebsiteChatScript() {

    var websiteChatScript = document.createElement('script');
    websiteChatScript.setAttribute(
      'id',
      'ZohoScriptWebsite-6521676c67970cc1331ddd7c9f458036b8698722c9359373e9897318e1408ab9'
    );
    websiteChatScript.setAttribute('type', 'text/javascript');

    websiteChatScript.innerHTML =
      'var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "6521676c67970cc1331ddd7c9f458036b8698722c9359373e9897318e1408ab9", values:{},ready:function(){$zoho.salesiq.floatbutton.coin.hidetooltip(); }};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);';
    document.head.insertBefore(websiteChatScript, document.head.firstChild);

    /* setTimeout(() => {
      const title = document.getElementById('titlediv');
      if (title)
        title.parentElement.removeChild(title);
    }, 1000); */

    /*  const tag = document.getElementById('zsiq_float');
     if (tag)
       tag.style.display = 'block'; */
  }

  private removeWebsiteChatScript() {
    const script1 = document.getElementById(
      'ZohoScriptWebsite-6521676c67970cc1331ddd7c9f458036b8698722c9359373e9897318e1408ab9'
    );
    const script2 = document.getElementById('zsiqscript');

    if (script1) script1.parentElement.removeChild(script1);

    if (script2) script2.parentElement.removeChild(script2);
    /* if (tag)
      tag.style.display = 'none'; */
    /* if (tag)
      tag.parentElement.removeChild(tag); */
  }

  private loadRegistrationChatScript() {
    var registrationCycleChatScript = document.createElement('script');
    registrationCycleChatScript.setAttribute(
      'id',
      'ZohoScriptRegistration-ee83d6e225792672dc37c7845df2e12e3065c74c996e47ef834e181ac9b195e5be34e116a6c72161648d32e5f3463409'
    );
    registrationCycleChatScript.setAttribute('type', 'text/javascript');

    registrationCycleChatScript.innerHTML =
      'var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "ee83d6e225792672dc37c7845df2e12e3065c74c996e47ef834e181ac9b195e5be34e116a6c72161648d32e5f3463409", values:{},ready:function(){$zoho.salesiq.floatbutton.coin.hidetooltip();}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);';

    document.head.insertBefore(
      registrationCycleChatScript,
      document.head.firstChild
    );

    /* setTimeout(() => {
      const title = document.getElementById('titlediv');
      if (title)
        title.parentElement.removeChild(title);
    }, 3000); */

    /* const tag = document.getElementById('zsiq_float');
    if (tag)
      tag.style.display = 'block'; */
  }

  private removeRegistrationChatScript() {
    const script1 = document.getElementById(
      'ZohoScriptRegistration-ee83d6e225792672dc37c7845df2e12e3065c74c996e47ef834e181ac9b195e5be34e116a6c72161648d32e5f3463409'
    );
    const script2 = document.getElementById('zsiqscript');

    if (script1) script1.parentElement.removeChild(script1);

    if (script2) script2.parentElement.removeChild(script2);

    /* const tag = document.getElementById('zsiq_float');
    if (tag)
      tag.style.display = 'none'; */
  }

  private loadSmartLookScript() {
    var env = environment;
    if (!env.production || !env.ApiUrl.toLowerCase().includes('proapi')) {
      return false;
    }

    var smartLookScript = document.createElement('script');
    smartLookScript.setAttribute(
      'id',
      'SmartLookScript-722bd8f3d27e0896b04083e87306666ef29e3ec8'
    );
    smartLookScript.setAttribute('type', 'text/javascript');

    smartLookScript.innerHTML =
      'window.smartlook || (function (d) { var o = smartlook = function () { o.api.push(arguments) }, h = d.getElementsByTagName("head")[0]; var c = d.createElement("script"); o.api = new Array(); c.async = true; c.type = "text/javascript"; c.charset = "utf-8"; c.src = "https://web-sdk.smartlook.com/recorder.js"; h.appendChild(c); })(document); smartlook("init", "722bd8f3d27e0896b04083e87306666ef29e3ec8", { region: "eu" });';

    document.head.insertBefore(smartLookScript, document.head.firstChild);

    /* setTimeout(() => {
      const title = document.getElementById('titlediv');
      if (title)
        title.parentElement.removeChild(title);
    }, 3000); */

    /* const tag = document.getElementById('zsiq_float');
    if (tag)
      tag.style.display = 'block'; */
  }

  private removeSmartLookScript() {
    var env = environment;
    if (!env.production || !env.ApiUrl.toLowerCase().includes('proapi')) {
      return false;
    }

    const script = document.getElementById(
      'SmartLookScript-722bd8f3d27e0896b04083e87306666ef29e3ec8'
    );
    if (script) script.parentElement.removeChild(script);
  }

  public ToHttpParams(request: any): HttpParams {
    let httpParams = new HttpParams();

    if (request) {
      Object.keys(request).forEach(function (key) {
        if (request[key] != null) {

          if (request[key] instanceof Array) {

            request[key].forEach((item) => {
              httpParams = httpParams.append(key, item);
            });

          } else {
            httpParams = httpParams.append(key, request[key]);
          }

        }
      });
    }

    return httpParams;
  }
  public langObsevable: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );

  public onLanguageChanged(lang: string) {
    this.langObsevable.next(lang);
  }


  public CheckAllowPrint(currentNumberOfPrints: number): boolean {


    if (this.CurrentBranch?.AllowUseMaxNumberOfPrintsInBillsForCashier &&
      this.IsCashier &&
      currentNumberOfPrints >= this.CurrentBranch?.MaxNumberOfPrintsInBillsForCashier) {

      return false;

    }

    return true;

  }

  public CheckAllowPrintForEmployees(currentNumberOfPrints: number): boolean {


    if (this.CurrentBranch?.AllowUseMaxNumberOfPrintsInEmployeesForCashier &&
      this.IsCashier &&
      currentNumberOfPrints >= this.CurrentBranch?.MaxNumberOfPrintsInEmployeesForCashier) {

      return false;

    }

    return true;

  }


  public CheckAllowPrintItems(currentNumberOfPrints: number): boolean {


    if (this.CurrentBranch?.AllowUseMaxNumberOfPrintsInItemsForCashier &&
      this.IsCashier &&
      currentNumberOfPrints >= this.CurrentBranch?.MaxNumberOfPrintsInItemsForCashier) {

      return false;

    }

    return true;

  }

  public GetTitleForCheckAllowPrint(currentNumberOfPrints: number): string {


    if (!this.CheckAllowPrint(currentNumberOfPrints)) {
      let message = this.translateService.instant('Sorry!!YouHaveReachedMaxNumberOfPrintsAllowedForYou!') + " " +
        this.translateService.instant('MaxNumberOfPrintsInBillsForCashier') + ": " + this.CurrentBranch?.MaxNumberOfPrintsInBillsForCashier + ".";
      return message;
    }

    return null;

  }


  public GetTitleForCheckAllowPrintForEmployees(currentNumberOfPrints: number): string {


    if (!this.CheckAllowPrintForEmployees(currentNumberOfPrints)) {
      let message = this.translateService.instant('Sorry!!YouHaveReachedMaxNumberOfPrintsAllowedForYou!') + " " +
        this.translateService.instant('MaxNumberOfPrintsInEmployeesForCashier') + ": " + this.CurrentBranch?.MaxNumberOfPrintsInEmployeesForCashier + ".";
      return message;
    }

    return null;

  }

  public GetTitleForCheckAllowPrintForItems(currentNumberOfPrints: number): string {


    if (!this.CheckAllowPrintItems(currentNumberOfPrints)) {
      let message = this.translateService.instant('Sorry!!YouHaveReachedMaxNumberOfPrintsAllowedForYou!') + " " +
        this.translateService.instant('MaxNumberOfPrintsInItemsForCashier') + ": " + this.CurrentBranch?.MaxNumberOfPrintsInItemsForCashier + ".";
      return message;
    }

    return null;

  }

}




export const LocalStorageKeys = {
  COUNTRYID: 'countryId',
  COUNTRYNAME: 'countryName',
  CITYID: 'cityId',
  CITYNAME: 'cityName',
  TOKENOBJECT: 'tokenObject',
  COUNTRY: 'country',
  LANG: 'lang',
  GlameraResources: 'glameraResources',
  DROPDOWN: 'dropdown',
  GLAMERABLOG: 'GlameraBlog',
  USERIDENTITY: 'UserIdentity',
  CurrentBranch: 'CurrentBranch',
  RediredtURLFromAuthGuard: 'RediredtURLFromAuthGuard'
};
