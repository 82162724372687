import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';

@Component({
  selector: 'app-import-export',
  templateUrl: './import-export.component.html',
  styleUrls: ['./import-export.component.scss']
})
export class ImportExportComponent implements OnInit {

  @Output() public readonly exportExelFile = new EventEmitter<any>();
  @Input() public LoadingExport;

  constructor() { }

  ngOnInit(): void {
  }

}
