import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { TransactionsSecondService } from 'src/app/services/transaction/transactions-second.service';
import { SharedService } from 'src/app/services/other/shared.service';
@Component({
  selector: 'app-modal-banned-client-info',
  templateUrl: './modal-banned-client-info.component.html',
  styleUrls: ['./modal-banned-client-info.component.scss'],
})
export class ModalBannedClientInfo implements OnInit {

  @Input() public ClientName: string;
  @Output() RemoveClient = new EventEmitter<void>();

  constructor(
    public activeModal: NgbActiveModal,
    public sharedService: SharedService,
    private transactionsSecondService: TransactionsSecondService,
    public translateService: TranslateService
  ) { }

  ngOnInit(): void {

  }

  HandleRemoveClient() {
    this.RemoveClient.next(null);
    this.activeModal.close();
  }
  Close() {
    this.activeModal.close();
  }

}
