import { HttpClient, HttpHeaders } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { ExecutionResponse } from "../../../models/support/execution-response"
import { HttpBaseService } from "../../../services/other/httpbase.service"
import { SharedService } from "../../../services/other/shared.service"
import { tap } from "rxjs/operators"


@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(
    private httpBaseService: HttpBaseService,
    private http: HttpClient,
    private sharedService: SharedService,
  ) { }











  /*========= UpdateAccountSetup  ================================================*/
  CreateAccountSetup(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('AccountSetup/Create', body)
  }
  UpdateAccountSetup(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('AccountSetup/Update', body)
  }
  GetByIdAccountSetup(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('AccountSetup/getById', body)
  }
  GetAccountSetupFeature(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('AccountSetupFeature/Get', body)
  }
  getAccountSetups(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Company/getAccountSetups', body)
  }

  /*========= user  ================================================*/
  Getuser(body): Observable<ExecutionResponse<any>> {

    return this.httpBaseService.Post('user/Get', body)
  }
  Createuser(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('user/Create', body)
  }
  Updateuser(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Postv2('user/Update', body)
  }
  DeleteUser(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('user/Delete', body)
  }
  GetByIduser(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('user/GetById', body)
  }

  /*========= Stocks  ================================================*/
  GetStockMainData(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('StockMainData/Get', body)
  }
  CreateStockMainData(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('StockMainData/Create', body)
  }
  DeleteStockMainData(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('StockMainData/Delete', body)
  }
  UpdateStockMainData(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('StockMainData/Update', body)
  }
  GetByIdStockMainData(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('StockMainData/GetById', body)
  }

  /*========= Cashier  ================================================*/
  GetCashierMainData(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('CashierMainData/Get', body)
  }
  CreateCashierMainData(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('CashierMainData/Create', body)
  }
  DeleteCashierMainData(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('CashierMainData/Delete', body)
  }
  GetByIdCashierMainData(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('CashierMainData/GetById', body)
  }
  UpdateCashierMainData(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('CashierMainData/Update', body)
  }
  /*========= DiscountType  ================================================*/
  GetDiscountType(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('DiscountType/Get', body)
  }
  CreateDiscountType(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('DiscountType/Create', body)
  }
  UpdateDiscountType(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('DiscountType/Update', body)
  }
  DeleteDiscountType(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('DiscountType/Delete', body)
  }
  GetByIdDiscountType(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('DiscountType/GetById', body)
  }
  /*========= AccountCurrency  ================================================*/
  GetAccountCurrency(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('AccountCurrency/Get', body)
  }
  CreateAccountCurrency(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('AccountCurrency/Create', body)
  }
  UpdateAccountCurrency(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('AccountCurrency/Update', body)
  }
  DeleteAccountCurrency(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('AccountCurrency/Delete', body)
  }
  GetByIdAccountCurrency(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('AccountCurrency/GetById', body)
  }

  GetDetailCodesWithMasterId(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Helper/GetDetailCodesWithMasterId', body)
  }

  /*========= PettyCashItems  ================================================*/
  GetPettyCashItems(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('PettyCashItems/Get', body)
  }
  CreatePettyCashItems(body): Observable<ExecutionResponse<any>> {

    return this.httpBaseService.Post('PettyCashItems/Create', body)
  }
  UpdatePettyCashItems(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('PettyCashItems/Update', body)
  }
  DeletePettyCashItems(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('PettyCashItems/Delete', body)
  }
  GetByIdPettyCashItems(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('PettyCashItems/GetById', body)
  }

  /*========= unit  ================================================*/
  Getunit(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('unit/Get', body)
  }
  Createunit(body): Observable<ExecutionResponse<any>> {

    return this.httpBaseService.Post('unit/Create', body)
  }
  Updateunit(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('unit/Update', body)
  }
  Deleteunit(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('unit/Delete', body)
  }
  GetByIdunit(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('unit/GetById', body)
  }
  /*========= HrBranchYear  ================================================*/
  GetHrBranchYear(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrBranchYear/Get', body)
  }
  CreateHrBranchYear(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrBranchYear/Create', body)
  }
  UpdateHrBranchYear(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrBranchYear/Update', body)
  }
  DeleteHrBranchYear(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrBranchYear/Delete', body)
  }
  GetByIdHrBranchYear(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrBranchYear/GetById', body)
  }
  UpdateYearStatus(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrBranchYear/UpdateYearStatus', body)
  }

  /*=============================HrAttendanceSettingsMaster============================*/
  GetAttendanceSetting(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrAttendanceSettingsMaster/Get', body);
  }
  CreateAttendanceSetting(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrAttendanceSettingsMaster/Create', body);
  }
  UpdateAttendanceSetting(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrAttendanceSettingsMaster/Update', body);
  }
  DeleteAttendanceSetting(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrAttendanceSettingsMaster/Delete', body);
  }
  /*=============================CancelPolicy============================*/
  GetCancelPolicy(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Get('CancelPolicy/Get', body);
  }
  CreateCancelPolicy(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('CancelPolicy/Add', body);
  }
  UpdateCancelPolicy(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('CancelPolicy/Update', body);
  }
  UpdateListCancelPolicy(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('CancelPolicy/UpdateList', body);
  }

   /*========= fingerprint devices  ================================================*/
   GetFDevices(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('FingerprintDevice/Get', body)
  }
  AddFDevice(body): Observable<ExecutionResponse<any>> {

    return this.httpBaseService.Post('FingerprintDevice/Create', body)
  }
  UpdateFDevice(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('FingerprintDevice/Update', body)
  }
  DeleteFDevice(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('FingerprintDevice/Delete', body)
  }
  GetByIdFDevice(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('FingerprintDevice/GetById', body)
  }

     /*========= fingerprint device Areas ================================================*/
     GetFPAreas(body): Observable<ExecutionResponse<any>> {
      return this.httpBaseService.Post('FingerPrintArea/Get', body)
    }
    AddFPArea(body): Observable<ExecutionResponse<any>> {
  
      return this.httpBaseService.Post('FingerPrintArea/Create', body)
    }
    UpdateFPArea(body): Observable<ExecutionResponse<any>> {
      return this.httpBaseService.Post('FingerPrintArea/Update', body)
    }
    DeleteFPArea(body): Observable<ExecutionResponse<any>> {
      return this.httpBaseService.Post('FingerPrintArea/Delete', body)
    }
    // GetByIdFDevice(body): Observable<ExecutionResponse<any>> {
    //   return this.httpBaseService.Post('FingerprintDevice/GetById', body)
    // }

  /*=============================HrPermissionslookup============================*/
  getHrPermissionsLookup(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrPermissionsLookup/Get', body);
  }
  createHrPermissionsLookup(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrPermissionsLookup/Create', body);
  }
  updateHrPermissionsLookup(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrPermissionsLookup/Update', body);
  }
  /*========= Employee  ================================================*/
  GetEmployee(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Employee/Get', body)
  }
  /*========= Getroles  ================================================*/
  Getroles(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('roles/Get', body)
  }
  /*========= Getroles  ================================================*/
  GetPermissions(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Permissions/Get', body)
  }
  /*========= GetcomapnyCategories  ================================================*/
  GetcomapnyCategories(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('comapnyCategories/Get', body)
  }
  GetcompanyServices(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('companyServices/Get', body)
  }
  GetDetailCodes(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('SystemCodes/GetDetailCodes', body)
  }
  // GetcomapnyCategories(body): Observable<ExecutionResponse<any>> {
  //   return this.httpBaseService.Post('comapnyCategories/Get', body)
  // }
  /*========================================================================================*/
  GetAppServices(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('AppTags/GetAppServices', body)
  }
  /*========================================================================================*/
  AddBusinesTypeImages(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('AccountSetup/AddBusinesTypeImages', body)
  }
  /*========================================================================================*/
  // PostImageFile(body): Observable<ExecutionResponse<any>> {
  //   return this.httpBaseService.Post('Upload/PostImageFile', body)
  // }
  GetDropdowns(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('SystemCodes/GetDropdowns', body)
  }

  GetCachedDropdowns(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('SystemCodes/GetCachedDropdowns', body)
  }
  CurrentUserBranchValidation(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('CurrentUserBranchValidation/ValidateCallCenter', body)
  }
  /*========================================================================================*/

  UpdateList(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('CompanyImages/UpdateList', body)
  }
  SetLogo(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('AccountSetup/SetLogo', body)
  }
  UpdateSliders(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Ads/UpdateSliders', body)
  }
  CreateResources(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('GlameraHome/Create', body)
  }
  GetGlameraHome(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('v2/GlameraHome/Get', body)
  }
  /*========================================================================================*/

  GetCompanyImages(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('CompanyImages/Get', body)
  }
  CreateCompanyImages(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('CompanyImageAccounts/Create', body)
  }
  DeleteCompanyImages(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('CompanyImages/Delete', body)
  }
  GetImages(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('CompanyImageAccounts/GetImages', body)
  }
  /*========================================================================================*/
  GetCompanyImageAccounts(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('CompanyImageAccounts/Get', body)
  }
  DeleteDetail(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('ads/DeleteDetail', body)
  }
  /*========================================================================================*/
  PostImageFile(body) {
    return this.http.post(`${this.sharedService.UploadUrl}PostImageFile`, body)
  }
  /*===========UserGroups======================================================================*/
  GetUserGroups(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('UserGroup/Get', body)
  }

  GetFingerprintDeviceInfo(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Get('FingerprintDevice/GetInfo', body)
  }

  


}

