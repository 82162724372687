<div class="branch-list" fxLayout="column" fxLayoutAlign="start center">
  <lib-language style="visibility: hidden"></lib-language>
  <div class="branch-list__content">
    <img
      src="../../../assets/img/back.jpg"
      alt=""
      title=""
      (click)="sharedService.back()"
    />
    <main>
      <h1>{{ "USERS" | translate }}</h1>
      <p>{{ "SELECT_USER" | translate }}</p>
    </main>
    <div class="search">
      <input
        (input)="onSearchBranchList($event)"
        type="search"
        placeholder="{{ 'Search_by_user' | translate }}"
      />
    </div>

    <app-user-item
      [userList]="userList"
      (clickItem)="onClickUserItem($event)"
      *ngIf="userList?.length; else noData"
    ></app-user-item>

    <mat-paginator
      [length]="totalCount"
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 25, 100]"
      aria-label="Select page"
      (page)="handlePageEvent($event)"
    >
    </mat-paginator>
  </div>

  <ng-template #noData>
    <h1 class="empty-text">{{ "Empty Data" | translate }}</h1>
  </ng-template>
</div>
