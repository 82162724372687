
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { MessageService } from '../message/message.service';
import { formValueDates } from '../../shared/helpers';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { SharedService } from '../other/shared.service';
import { StockMainData } from '../../models/classes/stock/stock-main-data';
import { StockMainDataSearchCriteria } from '../../models/search-criterias/stock-main-data-search-criteria';
import { StockMainDataResult } from '../../models/search-result/stock-main-data-result';
import { ExecutionResponse } from '../../models/support/execution-response';
import { ValidationCriteria } from '../../models/support/validation-criterias/validation-criteria';



@Injectable({
  providedIn: 'root'
})
export class StockMainDataService extends BaseService {

  private StocksUrl

  constructor(
    private http: HttpClient, private messageService: MessageService,
    private  _BaseService:BaseServiceSZ,
    private _sharedService:SharedService
  ) {
    super();

    this.StocksUrl =    this._sharedService.ApiUrl/* this._BaseService.ApiUrl */ + 'StockMainData';

  }

  // /** GET employees from the server */
  // getEmployees(): Observable<Employee[]> {
  //   return this.http.get<Employee[]>(this.employeesUrl, this._sharedService.getHeaders())
  //     .pipe(
  //       tap(employees => this._BaseService.log('fetched employees')),
  //       catchError(this._BaseService.handleError('getEmployees', []))
  //     );
  // }


  /** GET employee by id. Will 404 if id not found */
  getStockMainData(id: String): Observable<ExecutionResponse<StockMainData>> {

    //  getEmployee(id: String): ExecutionResponse<Employee> {
    //const url = '${this.employeesUrl}/${id}';
    return this.http.post<ExecutionResponse<StockMainData>>(this.StocksUrl + '/GetById', id, this._sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched stock id=${id}');
          this._BaseService.ValidationResult(res);
      }),

        catchError(this._BaseService.handleError<ExecutionResponse<StockMainData>>('getgetStockMainData id=${id}'))
      );
  }



  /* GET Stock whose name contains search term */
  searchStock(stockMainDataSearchCriteria: StockMainDataSearchCriteria): Observable<StockMainDataResult> {
   if(stockMainDataSearchCriteria.AccountSetupId == undefined)
   {
    stockMainDataSearchCriteria.AccountSetupId = this._sharedService.AccountSetupId;
   }

    var headers = this._sharedService.getHeaders();
    return this.http.post<StockMainDataResult>(this.StocksUrl + '/Get', stockMainDataSearchCriteria , headers)
      .pipe(
        tap(res => {

          this._BaseService.log('fetched Stocks');
          this._BaseService.ValidationResult(res);
      }),


        //catchError(this._BaseService.handleError('getEmployees', []))
      );

  }

  //////// Save methods //////////

  /** POST: add a new employee to the server */
  addStock(stock: StockMainData): Observable<ExecutionResponse<StockMainData>> {
    stock.AccountSetupId = this._sharedService.AccountSetupId;

    return this.http.post<ExecutionResponse<StockMainData>>(this.StocksUrl + '/Create', stock, this._sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('add StockMainData id=${id}');
          this._BaseService.ValidationResult(res);
      }),
        catchError(this._BaseService.handleError<ExecutionResponse<StockMainData>>('addStockMainData id=${id}'))
      );
  }

  /** DELETE: delete the employee from the server */
//   deleteEmployee(employee: Employee | number): Observable<ExecutionResponse<Employee>> {
//     return this.http.post<ExecutionResponse<Employee>>(this.employeesUrl + '/delete', employee, this._sharedService.getHeaders()).pipe(
//       tap(res => {
//         this._BaseService.log('deleted employee id=${id}');
//         this._BaseService.ValidationResult(res);
//     }),
//       catchError(this._BaseService.handleError<ExecutionResponse<Employee>>('deleteEmployee'))
//     );
//   }


  IsNameUnique(validationCriteria: ValidationCriteria): Observable<ExecutionResponse<Boolean>> {
    return this.http.post<ExecutionResponse<Boolean>>(this.StocksUrl + '/IsNameUnique', validationCriteria, this._sharedService.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deleted stockmaindata id=${id}');
        this._BaseService.ValidationResult(res);
    }),

      catchError(this._BaseService.handleError<ExecutionResponse<Boolean>>('deleteStock'))
    );
  }

  /** PUT: update the Stock on the server */
  updateStock(stock: StockMainData): Observable<ExecutionResponse<StockMainData>> {
    stock.AccountSetupId = this._sharedService.AccountSetupId;
    stock = formValueDates(stock,true);

    return this.http.post<ExecutionResponse<StockMainData>>(this.StocksUrl + '/Update', stock, this._sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('added Stock w/ id=${Stock.Id}');
          this._BaseService.ValidationResult(res);
      }),
        catchError(this._BaseService.handleError<ExecutionResponse<StockMainData>>('addStock id=${id}'))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */

}

