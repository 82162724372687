import { Injectable } from '@angular/core';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { DiscountType } from '../../models/classes/cashier/discount-type';
import { DiscountTypeSearchCriteria } from '../../models/search-criterias/discount-type-search-criteria';
import { DiscountTypeSearchResult } from '../../models/search-result/discount-type-search-result';
import { ExecutionResponse } from '../../models/support/execution-response';
import { ValidationCriteria } from '../../models/support/validation-criterias/validation-criteria';
import { formValueDates } from '../../shared/helpers';


@Injectable({
  providedIn: 'root'
})
export class DiscountTypeService extends BaseService {
  private discountTypeUrl //= super.BaseUrl() + 'DiscountType';  // URL to web api

  constructor(
    private http: HttpClient,
    private  _BaseService:BaseServiceSZ
  ) {
    super();
    this.discountTypeUrl =    this._BaseService.ApiUrl + 'DiscountType';

  }



  /** GETdiscountType by id. Will 404 if id not found */
  getDiscountType(id: String): Observable<ExecutionResponse<DiscountType>> {
    //  getDiscountType(id: String): ExecutionResponse<DiscountType> {
    //const url = '${this.discountTypeUrl}/${id}';
    return this.http.post<ExecutionResponse<DiscountType>>(this.discountTypeUrl + '/getById', id, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetcheddiscountType id=${id}');
          this._BaseService.ValidationResult(res);
      }),
        
        catchError(this._BaseService.handleError<ExecutionResponse<DiscountType>>('getDiscountType id=${id}'))
      );
  }


  addDiscountType(discountType: DiscountType): Observable<ExecutionResponse<DiscountType>> {
   discountType= formValueDates(discountType,true);
   discountType.AccountSetupId = super.getAccountSetupId();
    return this.http.post<ExecutionResponse<DiscountType>>(this.discountTypeUrl + '/Create',discountType, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('addeddiscountType w/ id=${discountType.Id}');
          this._BaseService.ValidationResult(res);
      }),
       
        catchError(this._BaseService.handleError<ExecutionResponse<DiscountType>>('addDiscountType id=${id}'))
      );
  }


  /* GETdiscountType whose name contains search term */
  searchDiscountTypes(discountTypeSearchCriteria: DiscountTypeSearchCriteria): Observable<DiscountTypeSearchResult> {
   discountTypeSearchCriteria.AccountSetupId = super.getAccountSetupId();
    return this.http.post<DiscountTypeSearchResult>(this.discountTypeUrl + '/Get',discountTypeSearchCriteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetcheddiscountType');
          this._BaseService.ValidationResult(res);
      }),
        catchError(this._BaseService.handleError<DiscountTypeSearchResult>('searchDiscountTypes id=${id}'))
      );
  }

  /** DELETE: delete thediscountType from the server */
  deleteDiscountType(discountType: DiscountType | number): Observable<ExecutionResponse<DiscountType>> {
    return this.http.post<ExecutionResponse<DiscountType>>(this.discountTypeUrl + '/delete',discountType, super.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deleteddiscountType id=${id}');
        this._BaseService.ValidationResult(res);
    }),
     
      catchError(this._BaseService.handleError<ExecutionResponse<DiscountType>>('deleteDiscountType'))
    );
  }

  /** PUT: update thediscountType on the server */
  updateDiscountType(discountType: DiscountType): Observable<ExecutionResponse<DiscountType>> {
   discountType.AccountSetupId = super.getAccountSetupId();
   discountType= formValueDates(discountType,true);
    return this.http.post<ExecutionResponse<DiscountType>>(this.discountTypeUrl + '/Update',discountType, super.getHeaders())
      .pipe(
        tap(res=> {
          this._BaseService.log('addeddiscountType w/ id=${discountType.Id}');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<ExecutionResponse<DiscountType>>('addDiscountType id=${id}'))
      );
  }

  IsNameUnique(validationCriteria: ValidationCriteria): Observable<ExecutionResponse<Boolean>> {
    return this.http.post<ExecutionResponse<Boolean>>(this.discountTypeUrl + '/IsNameUnique', validationCriteria, super.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deleted discount id=${id}');
        this._BaseService.ValidationResult(res);
    }),
      
      catchError(this._BaseService.handleError<ExecutionResponse<Boolean>>('deletediscount'))
    );
  }

}

