<div id="printClientGiftCardInfo">
  <div class="modal-header">
    <h5 class="modal-title text-capitalize" id="exampleModalLabel">
      <i class="fas fa-cloud-upload-alt"></i> &nbsp; {{'AddPettyCash' | translate}}
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12 col-md-12"
        *ngIf="!LoadingMakeTransactionValidation && !LoadingGetPettyCashItems; else loading">
        <form [formGroup]="GForm" (ngSubmit)="CreateData()" *ngIf="IsActive && !MessageValidation">
          <div class="modal-body">
            <div class="formCard mb-3">
              <div class="row">
                <div class="col-md-12">
                  <app-transaction-header [THForm]="GForm" (TransactionHeaderData)="getTransactionHeaderData($event)">
                  </app-transaction-header>
                </div>
              </div>
            </div>
            <div class="formCard" formGroupName="Transaction">
              <div class="row">
                <div class="form-group col-md-6">
                  <label>{{ 'pettyCashItems' | translate }}</label>
                  <ng-select placeholder="{{ 'SearchWithPettyCashItemName' | translate }}"
                    formControlName="PettyCashItemsId" name="PettyCashItemsId" [multiple]="false"
                    [items]="PettyCashItems" bindLabel="GlobalName" (search)="onSearch($event)"
                    (clear)="onSearch($event)" bindValue="Id" required>
                  </ng-select>

                </div>
                <div class="col-md-4"
                  *ngIf="AllowUseJournals && GForm.get('Transaction').get('PettyCashItemsId').value != null">
                  <label for="">.</label>
                  <div class="input-group mb-3">
                    <button class="btn-primary btn-sm active" type="button" id="button-addon1"
                      (click)="openModalAccounts()">اختر
                      الحساب</button>
                    <input type="text" class="form-control" placeholder="" aria-label="Example text with button addon"
                      aria-describedby="button-addon1" formControlName="PettyCashAccountName"
                      name="PettyCashAccountName">
                  </div>

                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group ">
                    <label for="Amount">{{ 'Amount' | translate }} </label>
                    <input type="number" class="form-control" min="0" step="any" formControlName="PriceBeforeDiscount"
                      (keyup)="SetValueAmount($event)">
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group row">
                    <label class="ms-2"> {{ 'TaxTreatment' | translate }}</label>
                    <div class="switch col-3 text-end">

                      <label>
                        <input type="checkbox" name="TaxTreatment" formControlName="TaxTreatment"
                          (change)="toggleTaxTreatment($event)">
                        <span class="lever"></span>
                      </label>
                    </div>

                  </div>
                </div>
                <div class="col-md-4" *ngIf="TaxTreatment">
                  <div class="form-group ">
                    <label for="Amount">{{ 'AmountAfterTax' | translate }} </label>
                    <input type="number" class="form-control" formControlName="TotalNetPrice"
                      (keyup)="SetValueNetAmount($event)">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group ">
                    <label for="Descreption">{{ 'Descreption' | translate }}</label>
                    <input type="text" formControlName="Descreption" name="Descreption" class="form-control">
                  </div>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-md-12">
                  <app-transaction-payment [TPForm]="GForm"></app-transaction-payment>
                  <!-- <div class="text-end">
                    <button type="button" class="btn btn-sm btn-primary" (click)="PushTransactionsPayments()">
                      {{'AddOtherPayment' | translate}} </button>
                  </div> -->
                </div>
              </div>

            </div>
          </div>

          <div class="modal-footer">

            <button class="btn btn-success" type="submit" [disabled]="btnLoading || !GForm.valid">
              <span *ngIf="!btnLoading"> {{ 'Submit' | translate }}</span>
              <span *ngIf="btnLoading"> <i class="fas fa-circle-notch fa-spin"></i> {{ 'Loading' | translate
                }}</span>
            </button>

            <button [disabled]="btnLoading" class="btn btn-danger" type="button"
              (click)="openModalUploadAttachements()"> <i class="fas fa-cloud-upload-alt"></i>
              &nbsp;{{'UploadAttachements' |
              translate }}</button>

          </div>


        </form>
      </div>
      <div class="col-lg-12 col-md-12" *ngIf="MessageValidation">
        <div class="IsWarning">
          <i class="fas fa-exclamation-triangle"></i>
          <br>
          <br>
          <br>
          <h5>{{MessageValidation}}</h5>
        </div>
      </div>
      <div class="col-md-12">
        <ng-template #loading>
          <div class="col-md-12 text-center py-4 my-4">
            <i class="fas fa-circle-notch fa-spin fa-3x"></i>
          </div>
        </ng-template>
      </div>
    </div>


  </div>
  <div class="modal-footer">

    <button type="button" class="btn btn-danger" (click)="closeModal()">
      {{'Close' | translate}}</button>

    <!--  <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="printPage()">{{'Print' |
      translate}}</button> -->


  </div>
</div>
