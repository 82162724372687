import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable, of } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { DetailCode } from "../../models/classes/other/detail-code";
import { DetailCodeSearchCriteria } from "../../models/search-criterias/detail-code-search-criteria";
import { DetailCodeSearchResult } from "../../models/search-result/detail-code-search-result";
import { ExecutionResponse } from "../../models/support/execution-response";
import { BaseService, BaseServiceSZ } from "./baseService";
import { HttpBaseService } from "./httpbase.service";


@Injectable({
  providedIn: 'root'
})

export class DetailCodeService extends BaseService {
  private accountSetupUrl //= super.BaseUrl() + 'SystemCodes';  // URL to web api
  private cachedData: DetailCodeSearchResult;
  private firstDelay: number = 0;
  constructor(
    private http: HttpClient,
    private _BaseService: BaseServiceSZ,
    private httpBaseService:HttpBaseService,
    public _translateServ: TranslateService

  ) {
    super();
    this.accountSetupUrl = this._BaseService.ApiUrl + 'SystemCodes';

  }


  getTimeZones(criteria:any): Observable<DetailCodeSearchResult> {
    return this.getTimeZoneDetailCodes(criteria);
  }



  getCultures(): Observable<DetailCodeSearchResult> {
    return this.getLookupDetail(3);
  }
  getCounties(): Observable<DetailCodeSearchResult> {
    return this.getLookupDetail(5);
  }
  getCities(): Observable<DetailCodeSearchResult> {
    return this.getLookupDetail(9);
  }
  getAreas(): Observable<DetailCodeSearchResult> {
    return this.getLookupDetail(10);
  }

  getSalesTaxsSystem(): Observable<DetailCodeSearchResult> {
    return this.getLookupDetail(6);
  }

  getCurrnciesSystem(): Observable<DetailCodeSearchResult> {
    return this.getLookupDetail(7);
  }
  getPaymentTypes(): Observable<DetailCodeSearchResult> {
    return this.getLookupDetail(8);
  }

  GetFingerprintDeviceInfo(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Get('FingerprintDevice/GetInfo', body)
  }


  getLookupDetail(masterCode: number): Observable<DetailCodeSearchResult> {
    var supplierSearchCriteria = new DetailCodeSearchCriteria();
    supplierSearchCriteria.MasterCodeId = masterCode;
    return this.http.post<DetailCodeSearchResult>(this.accountSetupUrl + '/GetDetailCodes', supplierSearchCriteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched suppliers');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<DetailCodeSearchResult>('getCounties id=${id}'))
      );
  }

  
getTimeZoneDetailCodes(criteria:any): Observable<DetailCodeSearchResult> {
  return this.http.post<DetailCodeSearchResult>(this.accountSetupUrl + '/GetTimeZones', criteria, super.getHeaders())
    .pipe(
      tap(res => {
        this._BaseService.log('fetched suppliers');
        this._BaseService.ValidationResult(res);
      }),
      catchError(this._BaseService.handleError<DetailCodeSearchResult>('getCounties id=${id}'))
    );
}

  getDropDownsDTO(masterCode: number): Observable<DetailCodeSearchResult> {
    var sc = new DetailCodeSearchCriteria();
    sc.MasterCodeId = masterCode;
    sc.Lang = this._translateServ.currentLang;
    if (this.cachedData) {
      return of(this.cachedData);
    }else{
      return this.http.post<DetailCodeSearchResult>(this.accountSetupUrl + '/GetDropdowns', sc, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);
          this.cachedData = res;
        }),
        catchError(this._BaseService.handleError<DetailCodeSearchResult>(' '))
      );
    }
  }

  getdetailCode(id: String): Observable<ExecutionResponse<DetailCode>> {

    
    return this.http.post<ExecutionResponse<DetailCode>>(this.accountSetupUrl + '/GetDetailCodeById', id, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched detailCode id=${id}');
          this._BaseService.ValidationResult(res);
      }),
        
        catchError(this._BaseService.handleError<ExecutionResponse<DetailCode>>('getRetailProduct id=${id}'))
      );
  }


  getBusinessTypes(): Observable<ExecutionResponse<any>> {

    
    return this.http.post<ExecutionResponse<any>>(this.accountSetupUrl + '/GetBusinessTypes', {})
      .pipe(
        tap(res => {
          this._BaseService.log('fetched detailCode id=${id}');
          this._BaseService.ValidationResult(res);
      }),
        
        catchError(this._BaseService.handleError<ExecutionResponse<any>>('getRetailProduct id=${id}'))
      );
  }

}
