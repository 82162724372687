
export enum AppEnum {
  Glamera = 1,
  GlameraBusiness = 2,
  GlameraConsultation = 3,
  GlameraBusinessWeb = 4,
  Glamatek_AdminPanel = 5,
  Glamatek_Booking = 6,
  GlameraBillingPortal = 7,
  BookingLink = 8,
  GentleApp = 100,
  Diva = 101,
  Elixir = 103,
  Aram = 104,
  OrientalRelax = 105,
  RelaxGate = 106,
  FileAndStyle = 107,
  Pure = 108,
  TagSpa = 109,
  SoftSpa = 110,
  Padio = 111,
  Nioluci = 112,
  Ghedaf = 113
}
