<div class="modal-header">
  <h5 class="modal-title text-capitalize" id="exampleModalLabel">
    <i class="fas fa-money-bill-alt"></i> &nbsp; {{ modalTitle | translate }}
  </h5>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">

    <div class="col-lg-12" *ngIf="InvoicesPayableByTheClient ">
      <div class="text-center py-4">
        <div class="row" *ngIf="TransactionType == transactionType.AddToAnyClientBalance">
          <div class="col-lg-6">
            <label>{{ 'ChooseClientYouWantToAddBalance' | translate }}</label>
          </div>
          <div class="col-lg-6">
            <ng-select placeholder="{{ 'SearchByClientNameOrMobile' | translate }}" required [multiple]="false"
              [items]="Clients" bindLabel="Name" [searchFn]="ClientsCustomSearchFn" [(ngModel)]="ClientId"
              bindValue="Id" (change)="ClientChanged()" (clear)="ClientChanged()" (search)="onSearch($event)">

              <ng-template ng-option-tmp let-item="item">
                <div class="list-client-insearch">
                  <div [ngClass]="{'first-char': !item.IsBanned, 'first-char-banned' : item.IsBanned }"
                    [title]="item.IsBanned ? (('BannedClient' | translate) + ' - ' + ('BanReason' | translate) + ':' + item.BanReason) : item.Name">
                    {{ item.Name | slice : 0 : 1 }}
                  </div>
                  <div [ngClass]="{'info-client': !item.IsBanned, 'info-client-banned' : item.IsBanned }"
                    [title]="item.IsBanned ? (('BannedClient' | translate) + ' - ' + ('BanReason' | translate) + ':' + item.BanReason) : item.Name">
                    {{ item.Name }} <br />
                    <span>{{ item.Mobile }}</span>
                  </div>
                </div>
              </ng-template>

              <ng-template ng-option-tmp let-item="item">
                <div class="list-client-insearch">
                  <div [ngClass]="{'first-char': !item.IsBanned, 'first-char-banned' : item.IsBanned }"
                    [title]="item.IsBanned ? (('BannedClient' | translate) + ' - ' + ('BanReason' | translate) + ':' + item.BanReason) : item.Name">
                    {{ item.Name | slice : 0 : 1 }}
                  </div>
                  <div [ngClass]="{'info-client': !item.IsBanned, 'info-client-banned' : item.IsBanned }"
                    [title]="item.IsBanned ? (('BannedClient' | translate) + ' - ' + ('BanReason' | translate) + ':' + item.BanReason) : item.Name">
                    {{ item.Name }} <br />
                    <span>{{ item.Mobile }}</span>
                  </div>
                </div>
              </ng-template>

            </ng-select>
          </div>
        </div>

        <div class="row" *ngIf="TransactionType != transactionType.AddToAnyClientBalance">
          <div class="col-lg-6">
            <div class="row" style="font-size: 15px;color: black;">
              <div class="col-lg-6">
                {{ 'ClientName:' | translate }}
              </div>
              <div class="col-lg-6">
                {{ ClientBalance?.ClientGlobalName }}
              </div>
            </div>

          </div>
          <div class="col-lg-6" style="font-size: 15px;color: black;">
            <div class="row">
              <div class="col-lg-6">
                {{ 'ClientStatus:' | translate }}
              </div>
              <div class="col-lg-6">

                <span *ngIf="ClientBalance.ClientBalanceStatus ==
                                clientBalanceStatus.Neutral" class="text-info">{{
                  ('Neutral'| translate) }}</span>
                <span *ngIf="ClientBalance.ClientBalanceStatus ==
                                clientBalanceStatus.Creditor" class="text-success">{{
                  ('Creditor-ForHim' |translate)}}</span>
                <span *ngIf="ClientBalance.ClientBalanceStatus ==
                                  clientBalanceStatus.Debtor" class="text-danger">{{
                  ('Debtor-OnHim' |translate) }}</span>
                <span *ngIf="ClientBalance.ClientBalanceStatus !=
                                  clientBalanceStatus.Neutral &&
                                  ClientBalance.ClientBalanceStatus !=
                                  clientBalanceStatus.Creditor &&
                                  ClientBalance.ClientBalanceStatus !=
                                  clientBalanceStatus.Debtor" class="text-warning">{{
                  ('NotDetermined' |translate)}}
                </span>

              </div>
            </div>
          </div>
        </div>

        <br *ngIf="TransactionType == transactionType.AddToClientBalance||
        TransactionType == transactionType.AddToAnyClientBalance ">
        <hr *ngIf="TransactionType == transactionType.AddToClientBalance||
        TransactionType == transactionType.AddToAnyClientBalance " style="text-align:left;margin-left:0;color: black;">
        <br *ngIf="TransactionType == transactionType.AddToClientBalance||
        TransactionType == transactionType.AddToAnyClientBalance ">
        <div *ngIf="false" class="row" style="font-size: 15px;color: black;">

          <div class="col-lg-4">
            {{ (TransactionType == transactionType.AddToClientBalance||
            TransactionType == transactionType.AddToAnyClientBalance ?
            'TotalAddedAmountToClientBalance:':'TotalWithdrawBalanceToTheClient:') | translate }}
          </div>
          <div class="col-lg-8">

            <div class="input-group">
              <input type="number" class="form-control text-center" min="0" placeholder="{{ (TransactionType == transactionType.AddToClientBalance ||
                TransactionType == transactionType.AddToAnyClientBalance ?
                  'TotalAddedAmountToClientBalance:':'TotalWithdrawBalanceToTheClient:') | translate }}"
                [(ngModel)]="TotalAmountForClientBalance" (keyup)="TotalAmountForClientBalanceKeyup($event)"
                (keypress)="numberOnly($event)">
              <div class="input-group-append">
                <span class="input-group-text" id="basic-addon2">
                  {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr :
                  sharedService.CurrencyNameEn}}</span>
              </div>
            </div>

          </div>
        </div>

        <br *ngIf="TransactionType == transactionType.AddToClientBalance||
        TransactionType == transactionType.AddToAnyClientBalance ">

        <div *ngIf="(TransactionType == transactionType.AddToClientBalance||
        TransactionType == transactionType.AddToAnyClientBalance ) &&

        TotalAmountForClientBalance &&
                 SelectedInvoicesPayableByTheClient && SumSelectedInvoicesPayableByTheClient &&
                 TotalAmountForClientBalance > SumSelectedInvoicesPayableByTheClient" class="row"
          style="font-size: 15px;color: black;">
          <div class="col-lg-4">
            {{ 'AddAmountExcessOfPostpaidToClientBalance' | translate }}
          </div>
          <div class="col-lg-8">
            <div class="switch col-6 text-end">
              <label>
                <input type="checkbox" [(ngModel)]="AddAmountExcessOfPostpaidToClientBalance" checked="checked">
                <span class="lever"></span>
              </label>
            </div>
          </div>
        </div>

        <br>

        <div *ngIf="TransactionType == transactionType.AddToClientBalance ||
        TransactionType == transactionType.AddToAnyClientBalance " class="row" style="font-size: 15px;color: black;">

          <div class="col-lg-4">
            {{ 'InvoicesPayableByTheClient:' | translate }}
          </div>
          <div class="col-lg-8">
            <ng-select [placeholder]="InvoicesPayableByTheClientPlaceHolder" [multiple]="true" required
              [items]="InvoicesPayableByTheClient" bindLabel="TransactionCode" [hideSelected]="true"
              (change)="InvoicesPayableByTheClientChange($event)" [(ngModel)]="SelectedInvoicesPayableByTheClientIds"
              bindValue="TransactionId">
            </ng-select>
          </div>
        </div>

        <br>

        <div class="row" style="font-size: 15px;color: black;" *ngIf="(TransactionType == transactionType.AddToClientBalance||
        TransactionType == transactionType.AddToAnyClientBalance ) &&
          SelectedInvoicesPayableByTheClient &&
          SelectedInvoicesPayableByTheClient.length > 0">
          <div class="col-lg-12">

            <div class="table-card">
              <table class="table table-stripedd table-sm table-bordered">
                <thead class="table-head">
                  <tr>
                    <th style="width: 3%;">{{ 'DoneInBranchName' | translate }}</th>
                    <th style="width: 3%;">{{ 'TransactionCode' | translate }}</th>
                    <th style="width: 3%;">{{ 'TransactionDate' | translate }}</th>
                    <th style="width: 3%;">{{ 'TransactionTime' | translate }}</th>
                    <th style="width: 6%;">{{ 'TotalRemainingAmount' | translate }}</th>
                    <th style="width: 6%;">{{ 'TotalPaidAmount' | translate }}</th>
                    <th style="width: 8%;">{{ 'TotalPayableAmount' | translate }}</th>
                    <th style="width: 10%;">{{ 'PaidAmount' | translate }}</th>

                  </tr>
                </thead>
                <tbody style="font-size: 15px;font-weight: bolder;color: black;">
                  <tr *ngFor="let item of SelectedInvoicesPayableByTheClient; let i = index">


                    <td>
                      {{ item.DoneInBranchGlobalName }}
                    </td>

                    <td>
                      {{ item.TransactionCode }}
                    </td>

                    <td>{{ item.TransactionDate | date: 'yyyy-MM-dd' }}</td>

                    <td>{{ item.TransactionDate | date: 'hh:mm a' }}</td>

                    <td>{{ item.TotalRemainingAmount}}</td>

                    <td>{{ item.TotalPaidAmount}}</td>

                    <td>{{ item.TotalPayableAmount}}</td>

                    <td>

                      <div class="input-group">
                        <input type="number" class="form-control text-center" min="0" [max]="item.TotalPayableAmount"
                          placeholder="{{'PaidAmount' | translate}}" [(ngModel)]="item.PaidAmount"
                          (keypress)="numberOnly($event)">
                        <div class="input-group-append">
                          <span class="input-group-text" id="basic-addon2">
                            {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr :
                            sharedService.CurrencyNameEn}}</span>
                        </div>
                      </div>
                    </td>

                  </tr>
                </tbody>
              </table>
              <div class="text-center py-4 my-4" *ngIf="SelectedInvoicesPayableByTheClient.length == 0">
                <h4 class="py-4">{{'NoDataFound' | translate}}</h4>
              </div>
            </div>

          </div>


          <!-- [ClientLoyalityAmount]="ClientLoyalityAmount"
          [UseLoyalityPoints]="AccountSetup?.UseLoyalityPoints" (CalculateNew)="CalctotalPaid()" -->
        </div>
        <br>
        <hr style="text-align:left;margin-left:0;color: black;">

        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-6">

            <div class="card-pay">
              <app-transaction-payment-two [TPForm]="GForm" (sendTotalAmount)="SetTotalAmountForClientBalance($event)">
              </app-transaction-payment-two>
              <div class="text-start">

                <button type="button" class="btn btn-add-pay" (click)="PushTransactionsPayments()">
                  {{'AddOtherPayment' | translate}} </button>


              </div>


              <div class="row">
                <div class="col-lg-12">
                  <label>
                    {{( TransactionType == transactionType.AddToClientBalance||
                    TransactionType == transactionType.AddToAnyClientBalance ?
                    'TotalAddedAmountToClientBalance:' :
                    'TotalWithdrawBalanceToTheClient:' )| translate}} {{TotalAmountForClientBalance}} -
                    {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr :
                    sharedService.CurrencyNameEn}}
                  </label>

                </div>

              </div>

            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="col-lg-12" *ngIf="!InvoicesPayableByTheClient ">
      <div class="col-md-12 text-center py-4 my-4">
        <i class="fas fa-circle-notch fa-spin fa-3x"></i>
        <h1>{{'Loading' | translate}} </h1>
      </div>
    </div>

  </div>

</div>
<div class="modal-footer">

  <button type="button" class="btn btn-success" (click)="SubmitClientBalance()" [disabled]="btnLoading">
    {{ ((TransactionType == transactionType.AddToClientBalance||
    TransactionType == transactionType.AddToAnyClientBalance ) && !btnLoading?
    'AddTheBalance': (TransactionType == transactionType.AddToClientBalance ||
    TransactionType == transactionType.AddToAnyClientBalance) &&
    btnLoading ? 'AddingToClientBalance' :
    TransactionType == transactionType.WithdrawBalanceToTheClient &&
    !btnLoading ?
    'WithdrawTheBalance' : TransactionType ==
    transactionType.WithdrawBalanceToTheClient && btnLoading ?
    'WithdrawingTheBalanceToTheClient' : "") | translate }}
  </button>

  <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">
    {{'Close' | translate}}
  </button>

</div>
