import {
  Component,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { PagerService } from './pager.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  EventsCount: number;
  RssCount: number;
  public pageSizeArr: string[] = [];
  @Output() public pageNumber: number = 0;
  @Output() public pageSize: number = 10;
  @Output() GetSources = new EventEmitter<string>();
  GlobalPageIndex: number;
  private allItems: any[];
  pager: any = {};
  pagedItems: any[];
  ddlPageSize: any = 10;

  uploadComplete() {
    this.GetSources.emit();
  }

  pagingClickBtn(btnIndex) {
    if (btnIndex >= 0 && this.GlobalPageIndex !== btnIndex) {
      this.GlobalPageIndex = btnIndex;
      this.pageNumber = btnIndex;
      this.alert = this.pageNumber.toString();
      this.uploadComplete();
    }
  }

  PageSizeClick() {
    this.pageSize = this.ddlPageSize;
    this.uploadComplete();
  }

  public alert = 'No Value Alert';

  constructor(
    public http: HttpClient,
    private _router: Router,
    private pagerService: PagerService
  ) {
    this.GlobalPageIndex = 0;
    this.ddlPageSize = 10;
  }

  ngOnInit() {
    this.pageNumber = 0;
    this.pageSize = 10;

    if (this.pageSizeArr.length === 0) {
      this.pageSizeArr.push('10');
      this.pageSizeArr.push('20');
      this.pageSizeArr.push('40');
      this.pageSizeArr.push('60');
      this.pageSizeArr.push('80');
      this.pageSizeArr.push('100');
      this.pageSizeArr.push('200');
    }
  }

  public setPage(page: number, getData: boolean) {
    console.log('setPage: Page number requested:', page);
    console.log('setPage: Current pager totalPages:', this.pager.totalPages);
    
    if (page < 0 || page >= this.pager.totalPages) {
      this.pageNumber = this.pager.totalPages - 1;
      console.log('setPage: Invalid page number, setting to last page:', this.pageNumber);
    } else {
      this.pageNumber = page;
    }

    if (this.EventsCount) {
      if (this.EventsCount > 0) {
        this.pager = this.pagerService.getPager(
          this.EventsCount,
          this.pageNumber,
          this.ddlPageSize
        );
        console.log('setPage: Updated pager:', this.pager);
      }
    }
    
    if (this.EventsCount) {
      this.alert = this.EventsCount.toString();
    } else {
      this.alert = 'No Events Count';
    }
    console.log('setPage: Updated alert:', this.alert);
  }
}
