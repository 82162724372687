import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AdminService } from '../../dashboard/admin/service/admin.service';
import { ModalPictureType } from '../../models/enums/modal-picture-type';
import { UploadImageResult } from '../../models/search-result/upload-image-result';
import { ClientService } from '../../services/client/client.service';
import { SharedService } from '../../services/other/shared.service';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-modal-show-info-to-all-users',
  templateUrl: './modal-show-info-to-all-users.component.html',
  styleUrls: ['./modal-show-info-to-all-users.component.scss'],
})
export class ModalShowInfoToAllUsers implements OnInit {
  public CurrentModalPictureType: ModalPictureType;
  public ModalTitle: string;
  public Name: string;
  public NoPicture: string;
  public DeletePicture: string;
  public LoadingSavePicture: string;
  public SavePicture: string;
  public UploadImageResultList: UploadImageResult[] = [];
  public PicturePath: string;

  public FilesList: string[] = [];
  public Loading: boolean = false;
  constructor(
    public activeModal: NgbActiveModal,
    public sharedService: SharedService,
    public adminService: AdminService,
    public translate: TranslateService,
    public clientService: ClientService,
    public userService: UserService
  ) {}

  ngOnInit(): void {
    this.ModalTitle = 'HappyEidFitter';
  }
  deleteItem(item: any) {
    console.log(item.Id);
  }

  public get modalPictureType(): typeof ModalPictureType {
    return ModalPictureType;
  }

  ngOnDestroy() {}

  goToWhatsappSupport(): void {
    window.open('https://wa.me/966551301446', '_blank');
  }
  goToWhatsappBusinessServices(): void {
    window.open('https://wa.me/966555094720', '_blank');
  }
}
