import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Employee } from '../classes/employee/employee';

@Pipe({
  name: 'FilterEmployeesPipe',
  pure: false
})
export class FilterEmployeesPipe implements PipeTransform {


  transform(employees: Employee[], serviceId: number, AllowUseServiceEmployeesOnlyInBookingAndSales: boolean): Employee[] {


    if (!AllowUseServiceEmployeesOnlyInBookingAndSales || !employees || !serviceId) {
      return employees;
    }
    var filteredEmployees = employees
      .filter(item => item.ServiceIds
        .indexOf(serviceId) !== -1);

    if (filteredEmployees && filteredEmployees.length > 0) {

      return filteredEmployees;

    } else {
      return employees;
    }

  }
}
