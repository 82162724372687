import { Injectable } from '@angular/core';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../message/message.service';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Service } from '../../dashboard/bookings/models/Service';
import { ServiceSearchCriteria } from '../../models/search-criterias/service-search-criteria';
import { ServiceSearchResult } from '../../models/search-result/service-search-result';
import { ExecutionResponse } from '../../models/support/execution-response';
import { ValidationCriteria } from '../../models/support/validation-criterias/validation-criteria';
import { SharedService } from '../other/shared.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceService extends BaseService {

  private servicesUrl  //= super.BaseUrl() + 'Service';  // URL to web api

  constructor(
    private http: HttpClient, private messageService: MessageService,
    private sharedService:SharedService,
    private _BaseService: BaseServiceSZ

  ) {
    super();
    this.servicesUrl = this.sharedService.ApiUrl + 'Service';

  }

  // /** GET services from the server */
  // getServices(): Observable<Service[]> {
  //   return this.http.get<Service[]>(this.servicesUrl, super.getHeaders())
  //     .pipe(
  //       tap(services => this._BaseService.log('fetched services')),
  //       catchError(this._BaseService.handleError('getServices', []))
  //     );
  // }


  /** GET service by id. Will 404 if id not found */
  getService(id: String): Observable<ExecutionResponse<Service>> {
    //  getService(id: String): ExecutionResponse<Service> {
    //const url = '${this.servicesUrl}/${id}';
    return this.http.post<ExecutionResponse<Service>>(this.servicesUrl + '/getById', id, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched service id=${id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<Service>>('getService id=${id}'))
      );


  }



  /* GET services whose name contains search term */
  searchServices(serviceSearchCriteria: ServiceSearchCriteria): Observable<ServiceSearchResult> {
    serviceSearchCriteria.AccountSetupId = super.getAccountSetupId();
    return this.http.post<ServiceSearchResult>(this.servicesUrl + '/Get', serviceSearchCriteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched services');
          this._BaseService.ValidationResult(res);
        }),
        //catchError(this._BaseService.handleError('getServices', []))
      );
  }
  /* GET services whose name contains search term  with basic fields */
  searchServicesBasic(serviceSearchCriteria: ServiceSearchCriteria): Observable<ServiceSearchResult> {
    
    serviceSearchCriteria.AccountSetupId = super.getAccountSetupId();
    return this.http.post<ServiceSearchResult>(this.servicesUrl + '/GetBasic', serviceSearchCriteria, super.getHeaders())
      .pipe(
        tap(res => {
          
          this._BaseService.ValidationResult(res);
        }),
        //catchError(this._BaseService.handleError('getServices', []))
      );
  }
  /** POST: add a new service to the server */
  addService(service: Service): Observable<ExecutionResponse<Service>> {
    service.AccountSetupId = super.getAccountSetupId();
    return this.http.post<ExecutionResponse<Service>>(this.servicesUrl + '/Create', service, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('added service w/ id=${service.Id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<Service>>('addService id=${id}'))
      );
  }

  uploadServiceExecl(uploadedFormData: FormData) {
    uploadedFormData.append("AccountSetupId", super.getAccountSetupId().toString());

    return this.http.post<ExecutionResponse<Boolean>>(this._BaseService.ApiUrl + 'Upload/PostFile', uploadedFormData, super.getUploadHeaders()).pipe(
      tap(res => {
        this._BaseService.ValidationResult(res);
      }),
      catchError(this._BaseService.handleError<ExecutionResponse<Boolean>>(''))
    );

  }
  /** DELETE: delete the service from the server */
  deleteService(service: Service | number): Observable<ExecutionResponse<Service>> {
    return this.http.post<ExecutionResponse<Service>>(this.servicesUrl + '/delete', service, super.getHeaders()).pipe(
      tap(_ => this._BaseService.log('deleted service id=${id}')),
      catchError(this._BaseService.handleError<ExecutionResponse<Service>>('deleteService'))
    );
  }

  IsServicesCodeUnique(validationCriteria: ValidationCriteria): Observable<ExecutionResponse<Boolean>> {
    validationCriteria.AccountSetupId = super.getAccountSetupId();

    return this.http.post<ExecutionResponse<Boolean>>(this.servicesUrl + '/IsCodeUnique', validationCriteria, super.getHeaders()).pipe(
      tap(_ => this._BaseService.log('deleted client id=${id}')),
      catchError(this._BaseService.handleError<ExecutionResponse<Boolean>>('deleteClient'))
    );
  }

  IsServicesDescriptionUnique(validationCriteria: ValidationCriteria): Observable<ExecutionResponse<Boolean>> {
    validationCriteria.AccountSetupId = super.getAccountSetupId();

    return this.http.post<ExecutionResponse<Boolean>>(this.servicesUrl + '/IsDescriptionUnique', validationCriteria, super.getHeaders()).pipe(
      tap(_ => this._BaseService.log('deleted client id=${id}')),
      catchError(this._BaseService.handleError<ExecutionResponse<Boolean>>('deleteClient'))
    );
  }
  /** PUT: update the service on the server */
  updateService(service: Service): Observable<ExecutionResponse<Service>> {
    service.AccountSetupId = super.getAccountSetupId();
    return this.http.post<ExecutionResponse<Service>>(this.servicesUrl + '/Update', service, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('added service w/ id=${service.Id}');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<ExecutionResponse<Service>>('addService id=${id}'))
      );
  }


  //update service branches 
  updateServiceBranches(accounts: any[]): Observable<ExecutionResponse<boolean>> {
    return this.http.post<ExecutionResponse<boolean>>(this.servicesUrl + '/UpdateServiceBranches', accounts, super.getHeaders())
      .pipe(
        tap(res => {
          // this._BaseService.log('update servicebranches w/ id=${service.Id}');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<ExecutionResponse<boolean>>('updateservicebranchesid=${id}'))
      );
  }

}
