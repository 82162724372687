import { Component, OnInit, Inject, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  NgxQrcodeElementTypes,
  NgxQrcodeErrorCorrectionLevels,
} from '@techiediaries/ngx-qrcode';
import { BookingOrTransactionItemType } from '../../../models/enums/booking-or-transaction-item-type';
import { TransactionType } from '../../../models/enums/transaction-type';
import { SharedService } from '../../../services/other/shared.service';
import { Transaction1Service } from '../../../services/transaction/transaction.service';
import { PaymentMethodType } from 'src/app/models/enums/payment-method-type';
import { TypeThatClientsQueueSystemDependsOn } from '../../../models/enums/type_that_clients_queue_system_depends_on';
import { EmployeeShowType } from '../../../models/enums/employee-show-type';

@Component({
  selector: 'app-print-bill3',
  templateUrl: './print-bill3.component.html',
  styleUrls: ['./print-bill3.component.scss'],
})
export class PrintBill3Component implements OnInit {
  @Input() public readonly item;
  @Input() public readonly AccountSetup;
  public printLang: string = 'ar';
  cashsaleId: number;
  private htmlRoot = this.document.documentElement;

  public get employeeShowType(): typeof EmployeeShowType {
    return EmployeeShowType;
  }
  bill;
  constructor(
    private transactionService: Transaction1Service,
    public sharedService: SharedService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    @Inject(DOCUMENT) private document: any
  ) {
    translate.addLangs(['en', 'ar']);
    translate.setDefaultLang(localStorage.getItem('lang'));
    this.htmlRoot.setAttribute('dir', localStorage.getItem('dir'));
    this.translate.use(localStorage.getItem('lang'));
  }

  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.LOW;
  // value = 'https://app.glamera.com/invoices/qr-code.pdf/';
  value = '';

  ngOnInit(): void {
    this.cashsaleId = this.route.snapshot.params['id'];

    this.value =
      'ARRCZWF1dHkgVG91Y2ggLSBjYWlybwIJOTg0OTg0NTQ2AxQyMDIxLTEyLTE0VDExOjAzOjIzWgQGMjEwLjAwBQUyMS4wMA==';

    this.printLang = this.sharedService.Lang;

    if (this.AccountSetup?.SelectedPrintInvoiceLanguage && this.AccountSetup?.SelectedPrintInvoiceLanguage != 'all') {
      this.printLang = this.AccountSetup?.SelectedPrintInvoiceLanguage;
    }
  }
  PrintPage() {
    window.print();
  }

  public get transactionType(): typeof TransactionType {
    return TransactionType;
  }

  public get typeThatClientsQueueSystemDependsOn(): typeof TypeThatClientsQueueSystemDependsOn {
    return TypeThatClientsQueueSystemDependsOn;
  }

  public get bookingOrTransactionItemType(): typeof BookingOrTransactionItemType {
    return BookingOrTransactionItemType;
  }

  public get paymentMethodType(): typeof PaymentMethodType {
    return PaymentMethodType;
  }
}
