import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { RESPONCE } from '../../model/responce';
import { PHASE_RESPONSE } from '../../model/signUp.model';
import {
  REGISTRATION_PHASES_INPUT,
  REGISTRATION_PHASES_RESPONSE,
  GET_SERVICES_INPUT,
  SERVICE,
  BUSINESS_SERVICE_INPUT,
  POSITION,
  BUSINESS_EMPLOYEE_INPUT,
  WORKING_DAY,
  WORKING_HOUR_INPUT,
  SKIP_INPUT,
  SKIP_RESPONSE,
} from '../../model/step-phases.model';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class BusinessDetailsService {
  public registeredPhasesSubject: BehaviorSubject<REGISTRATION_PHASES_RESPONSE> =
    new BehaviorSubject<REGISTRATION_PHASES_RESPONSE>(null);

  steperActive = new BehaviorSubject<number>(1);

  constructor(
    private readonly apiService: ApiService,
    private readonly router: Router
  ) {}

  savePhaseTwo(data: FormData): Observable<RESPONCE<PHASE_RESPONSE>> {
    return this.apiService.post<FormData, RESPONCE<PHASE_RESPONSE>>(
      'RegistrationCyclePhaseThree/SavePhaseThreeData',
      data
    );
  }

  getRegisteredPhases(
    data: REGISTRATION_PHASES_INPUT
  ): Observable<RESPONCE<REGISTRATION_PHASES_RESPONSE>> {
    return this.apiService.get<RESPONCE<REGISTRATION_PHASES_RESPONSE>>(
      'RegistrationCycleGeneral/GetRegisteredPhasesData',
      data
    );
  }

  getServices(data: GET_SERVICES_INPUT): Observable<RESPONCE<SERVICE[]>> {
    return this.apiService.get<RESPONCE<SERVICE[]>>(
      'RegistrationCyclePhaseFour/GetServices',
      data
    );
  }

  saveBusinessService(
    data: BUSINESS_SERVICE_INPUT
  ): Observable<RESPONCE<PHASE_RESPONSE>> {
    return this.apiService.post<
      BUSINESS_SERVICE_INPUT,
      RESPONCE<PHASE_RESPONSE>
    >('RegistrationCyclePhaseFour/SavePhaseFourData', data);
  }

  getPositions(): Observable<RESPONCE<POSITION[]>> {
    return this.apiService.get<RESPONCE<POSITION[]>>(
      'RegistrationCyclePhaseFive/GetPositions'
    );
  }

  saveEmployeeAndBranch(
    data: BUSINESS_EMPLOYEE_INPUT
  ): Observable<RESPONCE<PHASE_RESPONSE>> {
    return this.apiService.post<
      BUSINESS_EMPLOYEE_INPUT,
      RESPONCE<PHASE_RESPONSE>
    >('RegistrationCyclePhaseFive/SavePhaseFiveData', data);
  }

  changeRouteBaseOnPhase(phase: number): void {
    switch (phase) {
      case 1:
        // this.router.navigate(['']);
        break;
      case 2:
        this.router.navigate(['/details']);
        break;
      case 3:
        this.router.navigate(['details/step-three']);
        break;
      case 4:
        this.router.navigate(['details/step-four']);
        break;
        break;
      case 5:
        this.router.navigate(['details/step-five']);
        break;
    }
  }

  getWeekDays(): Observable<RESPONCE<WORKING_DAY[]>> {
    return this.apiService.get<RESPONCE<WORKING_DAY[]>>(
      'RegistrationCyclePhaseSix/GetWeekDays'
    );
  }

  savePhaseSix(data: WORKING_HOUR_INPUT): Observable<RESPONCE<PHASE_RESPONSE>> {
    return this.apiService.post<WORKING_HOUR_INPUT, RESPONCE<PHASE_RESPONSE>>(
      'RegistrationCyclePhaseSix/SavePhaseSixData',
      data
    );
  }

  checkTimeValidation(items: WORKING_DAY[]): boolean {
    let checked: boolean = false;
    for (let item of items) {
      if (!item.StartTime || !item.FinishTime) {
        checked = true;
      }
    }
    return checked;
  }

  skip(data: SKIP_INPUT): Observable<RESPONCE<SKIP_RESPONSE>> {
    return this.apiService.post<SKIP_INPUT, RESPONCE<SKIP_RESPONSE>>(
      'RegistrationCycleGeneral/Skip',
      data
    );
  }
}
