import { User } from 'firebase';
import { Countries } from '../../enums/countries';
import { AccountCurrency } from '../account-setup/account-currency';
import { MyUser } from '../my-user/my-user';

export class TokenDTO {
  public UserId: number;
  public User: MyUser;
  public ClientId: number;
  public CompanyId: number;
  public VisibleToGlamera: number;
  public AccountSetupId: number;
  public Token: string;
  public UserName: string;
  public UserFullName: string;
  public EmployeeId: number;
  public BranchNameAr: string;
  public BranchNameEn: string;
  public CompanyNameAr: string;
  public CompanyNameEn: string;
  public IsMainBranch: boolean;

  public IsCallCenter: boolean;
  public Country: Countries;

  public AllowUseSessions: boolean;
  public AllowUseLazerSessions: boolean;
  public PulsePrice: number;
  public AllowUseSalesTaxRate: boolean;

  public AccountCurrency: AccountCurrency;
  public AllowTaxExemptionForServices: boolean;
  public AllowTaxExemptionForProducts: boolean;
  public AllowUseTaxForProducts: boolean;
  public AllowUseTaxForServices: boolean;

  public CurrentBranchId: number;

  public AllowUseServiceByAreaInServices: boolean;

  public PriceIncludeTax: boolean;
  public TaxRegistrationNumber: string;

  public AllowAccessToClientsMobileNumbers: boolean;

  public AllowShowBookingNotificationsInAllSystemPages: boolean;
}
