import { HttpClient, HttpHeaders } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { tap } from "rxjs/operators"
import { StockTransMaster } from "../../../models/classes/stock/stock-trans-master"
import { ExecutionResponse } from "../../../models/support/execution-response"
import { HttpBaseService } from "../../../services/other/httpbase.service"
import { SharedService } from "../../../services/other/shared.service"
import { BaseServiceSZ } from "src/app/services/other/baseService"


@Injectable({
  providedIn: 'root'
})
export class StockService {
  RetailProductUrl: string
  RetailProductCatUrl: string

  constructor(private httpBaseService: HttpBaseService,
    private http: HttpClient, private sharedService: SharedService, private _BaseService: BaseServiceSZ,) {
      this.RetailProductUrl = this.sharedService.ApiUrl + 'RetailProduct';
      this.RetailProductCatUrl = this.sharedService.ApiUrl + 'Category';
     }
  /*=================================================================*/
  GetRetailProduct(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('RetailProduct/Get', body)
  }

  GetRetailProductLite(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('RetailProduct/GetLite', body)
  }
  GetRetailProductById(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('RetailProduct/getById', body)
  }
  CreateRetailProduct(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('RetailProduct/Create', body, true)
  }
  UpdateRetailProduct(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('RetailProduct/Update', body, true)
  }
  DeleteRetailProduct(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('RetailProduct/delete', body)
  }
  /*================ Category =================================================*/
  GetCategory(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Category/Get', body)
  }
  CreateCategory(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Category/Create', body)
  }
  UpdateCategory(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Category/Update', body)
  }
  DeleteCategory(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Category/delete', body)
  }
  /*================ Category =================================================*/
  GetcomapnyCategories(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('comapnyCategories/Get', body)
  }

  /*================ Unit =================================================*/
  GetUnit(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('unit/Get', body)
  }
  /*================ GetDropdowns =================================================*/
  GetDropdowns(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('SystemCodes/GetDropdowns', body)
  }

  /*================ GetWithStockName =================================================*/
  GetWithStockName(body): Observable<ExecutionResponse<StockTransMaster>> {
    return this.httpBaseService.Post('StockTransMaster/Get', body)
  }
  GetStockMainData(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('StockMainData/Get', body)
  }

  CreateStandardDeviation(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('StandardDeviation/Create', body)
  }
  /*================ GetProductsReachedMinLimit =================================================*/
  GetProductsReachedMinLimit(body): Observable<ExecutionResponse<StockTransMaster>> {
    return this.httpBaseService.Post('RetailProduct/GetProductsReachedMinLimit', body)
  }
  /*================ GetPurchaseOrder =================================================*/

  MakeStockTransactionValidation(asId, body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post(`StockTransMaster/MakeStockTransactionValidation?accountSetupId=${asId}`, body);
  }
  MakePurchaseTransactionValidation(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('CashierDailyAndShiftValidation/MakePurchaseTransactionValidation', body);
  }
  /*================ GetProductsReachedMinLimit =================================================*/
  CreateBond(body): Observable<ExecutionResponse<StockTransMaster>> {
    return this.httpBaseService.Post('Bond/Create', body)
  }
  UpdateBond(body): Observable<ExecutionResponse<StockTransMaster>> {
    return this.httpBaseService.Post('Bond/update', body)
  }
  GetBond(body): Observable<ExecutionResponse<StockTransMaster>> {
    return this.httpBaseService.Post('Bond/Get', body)
  }
  GetStockTransMaster(body): Observable<ExecutionResponse<StockTransMaster>> {
    return this.httpBaseService.Post('StockTransMaster/Get', body)
  }
  /*================ CheckUserStock =================================================*/
  CheckUserStock(body): Observable<ExecutionResponse<StockTransMaster>> {
    return this.httpBaseService.Post('StockTransMaster/CheckUserStock', body)
  }
  /*================ GetSupplierBalance =================================================*/
  GetSupplierBalance(body): Observable<ExecutionResponse<StockTransMaster>> {
    return this.httpBaseService.Post('SupplierBalance/Get', body)
  }
  /*=================================================================*/
  GetEmployee(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Employee/Get', body)
  }
  /*=================================================================*/
  public ExportProductCategories() {
    return this.http.get(`${this.sharedService.ApiUrl}v2/Categories/ExportProductCategories?AccountSetupId:${this.sharedService.AccountSetupId}`, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.sharedService.Token,
        'Content-Type': 'application/octet-stream',
      }), responseType: 'blob',
    }).pipe(
      tap(
        data => console.log('You received data'),
        error => console.log(error)
      )
    );
  }

  downloadProCatExcelFile(apiActionName){
    return this.http.get(this.RetailProductCatUrl  + '/' + apiActionName, {
   
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.sharedService.Token,
        'reportProgress': 'true',
        'observe':'events',
        'CurrentBranchId': this.sharedService.AccountSetupId.toString(),
        'Content-Type': 'application/octet-stream'
     
      }), responseType: 'blob',}).pipe(
      tap(
        data => console.log('You received data'),
        error => console.log(error)
      )
    );
}
  downloadExcelFile(apiActionName){
    return this.http.get(this.RetailProductUrl  + '/' + apiActionName, {
   
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.sharedService.Token,
        'reportProgress': 'true',
        'observe':'events',
        'CurrentBranchId': this.sharedService.AccountSetupId.toString(),
        'Content-Type': 'application/octet-stream'
     
      }), responseType: 'blob',}).pipe(
      tap(
        data => console.log('You received data'),
        error => console.log(error)
      )
    );
}
uploadProCatExcel(body){

  return this.http.post<ExecutionResponse<boolean>>(this.RetailProductCatUrl + '/Upload', body,{
    headers: new HttpHeaders({
      'Authorization': 'Bearer ' + this.sharedService.Token,
      'reportProgress': 'true',
      'observe':'events',
      'CurrentBranchId': this.sharedService.AccountSetupId.toString(),
      'CompanyId':this.sharedService.CompanyId.toString()
    })})
    .pipe(
      tap(res => {
        this._BaseService.ValidationResult(res);

      }),


    );
}
   uploadExcel(body){

    return this.http.post<ExecutionResponse<boolean>>(this.RetailProductUrl + '/Upload', body,{
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.sharedService.Token,
        'reportProgress': 'true',
        'observe':'events',
        'CurrentBranchId': this.sharedService.AccountSetupId.toString(),
        'CompanyId':this.sharedService.CompanyId.toString()
      })})
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);

        }),

 
      );
}
  /*=================================================================*/
  public ExportProducts() {

    return this.http.get(`${this.sharedService.ApiUrl}v2/Products/Export?AccountSetupId=${this.sharedService.AccountSetupId}`, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.sharedService.Token,
        'Content-Type': 'application/octet-stream',
      }), responseType: 'blob',
    }).pipe(
      tap(
        data => console.log('You received data'),
        error => console.log(error)
      )
    );
  }

  
}
