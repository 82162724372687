import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'src/app/services/other/shared.service';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent {

  constructor(
    public translateService: TranslateService, public sharedService: SharedService,
    public dialogRef: MatDialogRef<VideoComponent>) { }

    close() {
      this.dialogRef.close();
    }

}
