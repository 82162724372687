import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { MessageService } from '../message/message.service';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { SharedService } from '../other/shared.service';
import { HttpClient } from '@angular/common/http';
import { GetBranchUsersSearchCriteria } from '../../models/search-criterias/get-branch-users-search-criteria';
import { GetPictureSearchCriteria } from '../../models/search-criterias/get-picture-search-criteria';
import { MyUserSearchCriteria } from '../../models/search-criterias/my-user-search-criteria';
import { SavePictureSearchCriteria } from '../../models/search-criterias/save-picture-search-criteria';
import { GetBranchUsersSearchResult } from '../../models/search-result/get-branch-users-search-result';
import { GetPictureSearchResult } from '../../models/search-result/get-picture-search-result';
import { MyUserSearchResult } from '../../models/search-result/my-user-search-result';
import { SavePictureSearchResult } from '../../models/search-result/save-picture-search-result';

@Injectable({
  providedIn: 'root'
})

export class UserService extends BaseService {

  private userUrl

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private _BaseService: BaseServiceSZ,
    private sharedService: SharedService
  ) {
    super();
    this.userUrl = this.sharedService.ApiUrl/*this._BaseService.ApiUrl */ + 'User';
  }
  getApiUrl() {
    return this._BaseService.ApiUrl;
  }


  GetUsers(myUserSearchCriteria: MyUserSearchCriteria): Observable<MyUserSearchResult> {

    return this.http.post<MyUserSearchResult>(this.userUrl + '/Get', myUserSearchCriteria, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('Fetched User');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<MyUserSearchResult>('Fetched User  id=${id}'))
      );
  }

  GetUserPicture(criteria: GetPictureSearchCriteria): Observable<GetPictureSearchResult> {

    return this.http.post<GetPictureSearchResult>(this.userUrl + '/GetUserPicture', criteria, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('Fetched User');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<GetPictureSearchResult>('Fetched User  id=${id}'))
      );
  }


  SaveUserPicture(criteria: SavePictureSearchCriteria): Observable<SavePictureSearchResult> {

    return this.http.post<SavePictureSearchResult>(this.userUrl + '/SaveUserPicture', criteria, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('Fetched User');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<SavePictureSearchResult>('Fetched User  id=${id}'))
      );
  }



  GetBranchUsers(getBranchUsersSearchCriteria: GetBranchUsersSearchCriteria): Observable<GetBranchUsersSearchResult> {

    return this.http.post<GetBranchUsersSearchResult>(this.userUrl + '/GetBranchUsers', getBranchUsersSearchCriteria, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('Fetched Branch Users');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<GetBranchUsersSearchResult>('Fetched Branch Users id=${id}'))
      );
  }


}
