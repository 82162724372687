import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchResult } from '../../../../models/support/search-result';
import { HttpBaseServiceV2Service } from '../../../../services/http-base-service-v2.service';
import { ClientBaseCriteria } from '../models/client-base-criteria';
import { ClientBasicDTO } from '../models/client-basic-dto';
import { FilterClientCriteria, MarketingClientCriteria } from '../models/filter-client-criteria';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  constructor(private httpService: HttpBaseServiceV2Service) { }

  GetClients(criteria: ClientBaseCriteria): Observable<SearchResult<ClientBasicDTO>> {
    return this.httpService.Get('v2/clients', criteria);
  }

  FilterClients(criteria: MarketingClientCriteria): Observable<SearchResult<ClientBasicDTO>> {
    return this.httpService.Get('v2/offers/GetClients', criteria);
  }
}
