
export const AccountPayments = [
    {
        Id:1,
        NameEn:'Cash',
        NameAr:'كاش'
    },
    {
        Id:2,
        NameEn:'Cheque',
        NameAr:'شيك'
    },
    {
        Id:3,
        NameEn:'CreditCard',
        NameAr:'بطاقة إئتمانية'
    }
   
    // ,
    // {
    //     Id:5,
    //     NameEn:'Gift Card',
    //     NameAr:' كارت الهدية '
    // } ,
    // {
    //     Id:9,
    //     NameEn:'Voucher',
    //     NameAr:'قسيمة الشراء'
    // }
]

export const ClientPayment = [
    {
        Id:1,
        NameEn:'Cash',
        NameAr:'كاش'
    },
    {
        Id:2,
        NameEn:'Cheque',
        NameAr:'شيك'
    },
    {
        Id:3,
        NameEn:'CreditCard',
        NameAr:'بطاقة إئتمانية'
    },
    {
        Id:4,
        NameEn:'ElectronicsFundsTransfer',
        NameAr:'تحويل الأموال الإلكتروني'
    },
    {
        Id:5,
        NameEn:'GiftCard',
        NameAr:'بطاقات الهدايا'
    },
    {
        Id:6,
        NameEn:'HICAPS',
        NameAr:'HICAPS'
    },
    {
        Id:7,
        NameEn:'LoyaltyPoints',
        NameAr:'نقاط الولاء'
    },
    {
        Id:8,
        NameEn:'MoneyOrder',
        NameAr:'حوالة مالية'
    },
     {
        Id:9,
        NameEn:'Voucher',
        NameAr:'قسيمة الشراء'
    },
    {
       Id:10,
       NameEn:'ClientDownPayment',
       NameAr:'مقدم حجز'
   }
]

export const PaymentConstants={
    2000:{
        //paymentType :AccountPayments,
        //excludedPaymentTypesIds:[2] ,
        component:'openBalance'
    },
    2001:{
        //paymentType :AccountPayments,
        // excludedPaymentTypesIds:[2] ,
        component:'cashSale'
    }
    ,
    2002:{
        //paymentType :AccountPayments,
        // excludedPaymentTypesIds:[2] ,
        component:'pettyCash'
    },
    2003:{
        //paymentType :AccountPayments,
         excludedPaymentTypesIds:[2,3] ,
        component:'cashierTransfere'
    }
    ,
    2004:{
        //paymentType :AccountPayments,
        // excludedPaymentTypesIds:[2,3] ,
        component:'refund'
    }
    ,
    2005:{
        //paymentType :AccountPayments,
        // excludedPaymentTypesIds:[2,3] ,
        component:'cancle-transaction'
    }
    ,
    2006:{
        //paymentType :AccountPayments,
        // excludedPaymentTypesIds:[2,3] ,
        component:'client-down-payment'
    }
}

