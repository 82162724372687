import { Pipe, PipeTransform } from '@angular/core';
import { SharedService } from '../../services/other/shared.service';

@Pipe({
  name: 'TranslationPipe',
  pure: false
})
export class TranslationPipe implements PipeTransform {

  constructor(public sharedService: SharedService) {

  }

  transform(key: string): string {

    let listOfKeys = this.sharedService
      ?.LangList
      ?.find(l => l.LangKey == this.sharedService?.TempLangKey)
      ?.LangKeysWithValues;

    var value = listOfKeys ? listOfKeys[key] : null;

    if (value)
      return listOfKeys[key];
    else
      return key;
  }
}
