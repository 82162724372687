<div class="modal-header">
  <h4 class="modal-title"> <i class="fas fa-user"></i> &nbsp;
    {{ (ChangeType == changeType.Edit ? 'EditServicesEmployee'
    :'AddServicesEmployee') | translate }} </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" id="modalServiceBranchEmployeesDetails">
  <div class="row">
    <div class="col-lg-12" id="printServiceBranchEmployeesDetails">

      <div class="cardTabs">
        <!--  <div *ngIf="selectedTab==1" class=" tabsContent"> -->
        <div class=" tabsContent">
          <div class="formCard">
            <!--  <h5>{{ 'Details' | translate }}</h5> -->
            <div class="row">

              <div class="form-group col-md-6" *ngIf="ChangeType == changeType.Add">
                <div class="row">
                  <div class="col">
                    <label class="radio-button-Stock">
                      <input type="radio" name="BranchServicesOrBranchCategories" [checked]="true"
                        [(ngModel)]="branchServicesOrBranchCategoriesVar"
                        [value]="branchServicesOrBranchCategories.BranchServices">
                      <span class="label-visible">
                        <span class="fake-radiobutton"></span>
                        {{ 'BranchServices'| translate }}
                      </span>
                    </label>
                  </div>
                  <div class="col">
                    <label class="radio-button-Stock">
                      <input type="radio" name="BranchServicesOrBranchCategories" [checked]="false"
                        [(ngModel)]="branchServicesOrBranchCategoriesVar"
                        [value]="branchServicesOrBranchCategories.BranchCategories">
                      <span class="label-visible">
                        <span class="fake-radiobutton"></span>
                        {{ 'BranchServicesCategories'| translate }}
                      </span>
                    </label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12"
                    *ngIf="branchServicesOrBranchCategoriesVar== branchServicesOrBranchCategories.BranchServices">
                    <!-- <label>{{ 'BranchServices' | translate }} </label> -->
                    <ng-select placeholder="{{ 'SearchBranchService' | translate }}" [multiple]="false"
                      [hideSelected]="false" [items]="BrancServices" bindLabel="ServiceName" [searchFn]="customSearchFn"
                      (search)="onSearch($event)" (clear)="ClearBrancService()" bindValue="Id" name="BrancService"
                      [ngModelOptions]="{standalone: true}" [(ngModel)]="ServiceBranchId">
                    </ng-select>
                  </div>
                  <div class="col-lg-12"
                    *ngIf="branchServicesOrBranchCategoriesVar == branchServicesOrBranchCategories.BranchCategories">
                    <!-- <label>{{ 'BranchServicesCategories' | translate }} </label> -->
                    <ng-select placeholder="{{ 'SearchBranchServiceCategory' | translate }}" [multiple]="false"
                      [hideSelected]="false" [items]="CategoryBranches" bindLabel="CategoryName"
                      [searchFn]="customSearchFnCategory" (search)="onSearchCategory($event)"
                      (clear)="ClearBrancCategory()" bindValue="Id" name="BrancCategory"
                      [ngModelOptions]="{standalone: true}" [(ngModel)]="CategoryBranchId">
                    </ng-select>
                  </div>

                </div>
              </div>

              <div class="form-group col-md-6" *ngIf="ChangeType == changeType.Edit">
                <label style="margin-top: 10%;">{{ 'ServiceName' | translate }} :
                  {{serviceBranchEmployee.ServiceName}}</label>
              </div>

              <div class="form-group col-md-6">
                <label>{{ 'ServiceEmployees' | translate }} </label>
                <ng-select placeholder="{{ 'ChooseServiceEmployees' | translate }}" [multiple]="true"
                  [hideSelected]="true" [items]="Employees" bindLabel="GlobalName" bindValue="Id" name="EmployeeIds"
                  [ngModelOptions]="{standalone: true}" (search)="onSearchEmployees($event)" (clear)="ClearEmployees()"
                  [(ngModel)]="EmployeeIds">
                </ng-select>

              </div>



            </div>

            <br><br><br><br><br><br><br><br><br><br>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>
<div class="modal-footer">

  <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">{{'Close' |
    translate}}</button>

  <button class="btn btn-danger" [disabled]="SendingData" (click)="Submit()">
    {{ (SendingData ? (ChangeType == changeType.Edit ? 'OnEditServiceEmployees' : 'OnCreateServiceEmployees') :
    'Submit')
    | translate }}
  </button>
</div>
