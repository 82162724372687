import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CostCenterService } from '../../dashboard/accounts/services/cost-center.service';
import { AdminService } from '../../dashboard/admin/service/admin.service';
import { AccountSetupSearchCriteria } from '../../models/search-criterias/account-setup-search-criteria';
import { ResponseState } from '../../models/support/response-state';
import { SharedService } from '../../services/other/shared.service';
import { Transaction1Service } from '../../services/transaction/transaction.service';
import { BaseServiceSZ } from 'src/app/services/other/baseService';
import { ServiceBranchEmployeeDTO } from 'src/app/models/classes/service/service-branch-employee-list-dto';
import { ChangeType } from 'src/app/models/enums/change-type';
import { ServiceBranchEmployeeService } from 'src/app/services/service/service-branch-employee.service';
import { ServiceBranchService } from 'src/app/services/service/service-branch.service';
import { ExecutionResponse } from 'src/app/models/support/execution-response';
import { BranchServicesOrBranchCategories } from 'src/app/models/enums/branch-services-or-branch-categories';
import { Employee } from 'src/app/models/classes/employee/employee';
import { ServiceBranche } from 'src/app/models/classes/service/service-branche';
import { ServiceBranchSearchCriteria } from 'src/app/models/search-criterias/service-branch-search-criteria';
import { ServiceType } from 'src/app/models/enums/service-type';
import { ServiceBranchSearchResult } from 'src/app/models/search-result/service-branch-search-result';
import { EmployeeSearchCriteria } from 'src/app/models/search-criterias/employee-search-criteria';
import { EmployeeSearchResult } from 'src/app/models/search-result/employee-search-result';
import { EmployeeService } from 'src/app/services/employee/employee.service';
import { CategoryBranchService } from '../../services/Service/category-branch.service';
import { CategoryBranchSearchResult } from 'src/app/models/search-result/category-branch-search-result';
import { CategoryBranchSearchCriteria } from 'src/app/models/search-criterias/category-branch-search-criteria';
import { CategoryBranch } from 'src/app/models/classes/service/category-branch';

@Component({
  selector: 'app-modal-service-employee-add-update',
  templateUrl: './modal-service-employee-add-update.component.html',
  styleUrls: ['./modal-service-employee-add-update.component.scss'],
})
export class ModalServiceEmployeeAddUpdateComponent implements OnInit {
  public GForm: UntypedFormGroup;
  public IsSubmit: boolean;
  public AccountSetupId: number;
  public SendingData: boolean;
  public EmployeeIds: number[];
  public ServiceBranchId: number;
  public CategoryBranchId: number;
  public brancServicesSearchText: string;
  public brancCategoriesSearchText: string;
  public employeeSearchText: string;
  public branchServicesOrBranchCategoriesVar: BranchServicesOrBranchCategories
    = BranchServicesOrBranchCategories.BranchServices;

  @Input() CategoryBranches: CategoryBranch[] = [];
  @Input() BrancServices: ServiceBranche[] = [];

  @Input() Employees: Employee[] = [];

  @Input() serviceBranchEmployee: ServiceBranchEmployeeDTO;
  @Input() ChangeType: ChangeType;

  @Output() AddOrUpdateDone = new EventEmitter<ChangeType>();

  constructor(
    private toastr: ToastrService,
    public sharedService: SharedService,
    private _BaseService: BaseServiceSZ,
    private fb: UntypedFormBuilder,
    private employeeService: EmployeeService,
    private serviceBranchService: ServiceBranchService,
    private categoryBranchService: CategoryBranchService,
    private serviceBranchEmployeeService: ServiceBranchEmployeeService,
    private transactionService: Transaction1Service,
    private router: Router,
    private route: ActivatedRoute,
    public datepipe: DatePipe,
    public translateservice: TranslateService,
    public CostCenterService: CostCenterService,
    private adminService: AdminService,
    public activeModal: NgbActiveModal
  ) { }

  public get changeType(): typeof ChangeType {
    return ChangeType;
  }

  public get branchServicesOrBranchCategories(): typeof BranchServicesOrBranchCategories {
    return BranchServicesOrBranchCategories;
  }

  ngOnInit(): void {
    if (this.ChangeType == ChangeType.Edit && this.serviceBranchEmployee) {
      this.ServiceBranchId = this.serviceBranchEmployee.ServiceBranchId;
      this.EmployeeIds = this.serviceBranchEmployee.EmployeeIds;
      this.GetEmployees(this.EmployeeIds);
    }
  }

  sendMessage() {
    this.sharedService.setData('test');

    // let test = 'rtest one'
    // this.sharedService.sendMessage(test);
  }

  divName;
  printPage() {
    this.divName = 'printServiceBranchEmployeesDetails';
    console.log(this.divName);

    let printContents = document.getElementById(this.divName).innerHTML;
    let originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
    // this.divName = null;
  }

  get IsActive() {
    return this.GForm.get('IsActive');
  }
  get IsChild() {
    return this.GForm.get('IsChild');
  }

  GFormData() {
    this.GForm = this.fb.group({
      Id: 0,
      CostCenterNo: ['', Validators.required],

      NameAr: ['', Validators.required],
      NameEn: ['', Validators.required],
      DescriptionAr: '',
      DescriptionEn: '',
      LevelNumber: 1,
      ParentId: null,
      AccountSetupId: null,
      IsChild: false,

      IsActive: true,

      ParentNumber: '',
      Code: '',
      BranchServicesOrBranchCategories: BranchServicesOrBranchCategories.BranchServices
    });
  }
  ChangeIsActive(e) {
    if (e.target.checked) {
      this.GForm.controls.IsActive.setValue(true);
    } else {
      this.GForm.controls.IsActive.setValue(false);
    }
  }

  @Output() LoadData = new EventEmitter();

  ClearBrancService() {
    this.brancServicesSearchText = null;
    this.GetBrancServices();
  }

  onSearch(e) {
    this.brancServicesSearchText = e.term;
    this.GetBrancServices();
  }

  ClearBrancCategory() {
    this.brancCategoriesSearchText = null;
    this.GetBrancCategories();
  }

  onSearchCategory(e) {
    this.brancCategoriesSearchText = e.term;
    this.GetBrancCategories();
  }


  onSearchEmployees(e) {
    console.log(e);
    this.employeeSearchText = e.term;
    this.GetEmployees(null);
  }

  ClearEmployees() {
    this.employeeSearchText = null;
    this.GetEmployees(null);
  }

  GetEmployees(EmployeeIds: number[]) {

    var employeeSearchCriteria = new EmployeeSearchCriteria();
    employeeSearchCriteria.AccountSetupId = this.sharedService.AccountSetupId;
    employeeSearchCriteria.FreeText = this.employeeSearchText;
    employeeSearchCriteria.PagingEnabled = true;
    employeeSearchCriteria.PageSize = 5;
    employeeSearchCriteria.PageNumber = 0;
    employeeSearchCriteria.employeesIds = EmployeeIds;

    this.employeeService
      .searchEmployees(employeeSearchCriteria)
      .subscribe((response: EmployeeSearchResult) => {
        if (response.State == ResponseState.Success) {
          this.Employees = response.Employees;
        }
      });
  }

  customSearchFn(term: string, item: ServiceBranche) {
    term = term.toLowerCase();
    return (
      item.ServiceNameAr.toLowerCase().indexOf(term) > -1 ||
      item.ServiceNameEn.toLowerCase().indexOf(term) > -1 ||
      item.ServiceNameAr.toLowerCase() === term ||
      item.ServiceNameEn.toLowerCase() === term
    );
  }
  customSearchFnCategory(term: string, item: CategoryBranch) {
    term = term.toLowerCase();
    return (
      item.CategoryName.toLowerCase().indexOf(term) > -1 ||
      item.CategoryName.toLowerCase() === term 
    );
  }

  GetBrancServices() {

    var serviceSearchCriteria = new ServiceBranchSearchCriteria();
    serviceSearchCriteria.AccountSetupId = this.sharedService.AccountSetupId;
    serviceSearchCriteria.ServiceTypes = [ServiceType.Service];
    serviceSearchCriteria.CompanyId = this.sharedService.CompanyId;
    serviceSearchCriteria.PagingEnabled = true;
    serviceSearchCriteria.PageNumber = 0;
    serviceSearchCriteria.PageSize = 5;
    serviceSearchCriteria.FreeText = this.brancServicesSearchText;

    this.serviceBranchService
      .GetServiceBranches(serviceSearchCriteria)
      .subscribe((response: ServiceBranchSearchResult) => {
        if ((response.State = ResponseState.Success)) {
          this.BrancServices = response.ServiceBranches;
        }
      });
  }

  GetBrancCategories() {

    var criteria = new CategoryBranchSearchCriteria();
    criteria.AccountSetupId = this.sharedService.AccountSetupId;
    criteria.CompanyId = this.sharedService.CompanyId;
    criteria.PagingEnabled = true;
    criteria.PageNumber = 0;
    criteria.PageSize = 5;
    criteria.FreeText = this.brancCategoriesSearchText;

    this.categoryBranchService
      .GetCategoryBranches(criteria)
      .subscribe((response: CategoryBranchSearchResult) => {
        if ((response.State = ResponseState.Success)) {
          this.CategoryBranches = response.CategoryBranches;
        }
      });
  }

  Submit() {
    if (this.ChangeType == ChangeType.Add) {
      this.Create();
    } else if (this.ChangeType == ChangeType.Edit) {
      this.Update();
    }
  }

  Create() {
    this.SendingData = true;
    var serviceBranchEmployeeListDTO = new ServiceBranchEmployeeDTO();

    serviceBranchEmployeeListDTO.ServiceBranchId = this.ServiceBranchId;
    serviceBranchEmployeeListDTO.CategoryBranchId = this.CategoryBranchId;
    serviceBranchEmployeeListDTO.EmployeeIds = this.EmployeeIds;

    this.serviceBranchEmployeeService
      .CreateList(serviceBranchEmployeeListDTO)
      .subscribe((response: ExecutionResponse<boolean>) => {
        if (response.State == ResponseState.Success) {
          this.sharedService.ToastrSuccess(response.Message);
          this.sharedService.HideModal();
          this.AddOrUpdateDone.emit(ChangeType.Add);
        }
        this.SendingData = false;
      });
  }

  Update() {
    this.SendingData = true;
    var serviceBranchEmployeeListDTO = new ServiceBranchEmployeeDTO();

    serviceBranchEmployeeListDTO.ServiceBranchId = this.ServiceBranchId;
    serviceBranchEmployeeListDTO.EmployeeIds = this.EmployeeIds;

    this.serviceBranchEmployeeService
      .UpdateList(serviceBranchEmployeeListDTO)
      .subscribe((response: ExecutionResponse<boolean>) => {
        if (response.State == ResponseState.Success) {
          this.sharedService.ToastrSuccess(response.Message);
          this.sharedService.HideModal();
          this.AddOrUpdateDone.emit(ChangeType.Edit);
        }

        this.SendingData = false;
      });
  }

  changeBranch(e) {
    this.AccountSetupId = e.Id;
  }

  pNum: any;
  pId: number;
  GenerateNumber() {
    this.CostCenterService.GenerateNumber({
      parentNumber: this.pNum,
      parentId: this.pId,
    }).subscribe((response: any) => {
      if (response) {
        this.GForm.get('CostCenterNo').patchValue(response.Result);
      }
    });
  }

  branches;
  totalCount;
  getAccountSetups() {
    let accountSetupSearchCriteria = new AccountSetupSearchCriteria();
    accountSetupSearchCriteria.CompanyId = this.sharedService.CompanyId;
    accountSetupSearchCriteria.PagingEnabled = false;

    this.adminService
      .getAccountSetups(accountSetupSearchCriteria)
      .subscribe((response: any) => {
        if (response.State == ResponseState.Success) {
          this.branches = response.AccountSetups;
        }
      });
  }
}
