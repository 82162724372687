import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AdminService } from '../../dashboard/admin/service/admin.service';
import { SetDoneShowIntroVideoModel } from '../../models/classes/registration-cycle/set-done-show-intro-video-model';
import { ModalPictureType } from '../../models/enums/modal-picture-type';
import { VideoNumber } from '../../models/enums/registration-cycle-video-Number';
import { SetDoneShowIntroVideoSearchResult } from '../../models/search-result/registration-cycle/set-done-show-intro-video-search-result';
import { UploadImageResult } from '../../models/search-result/upload-image-result';
import { ResponseState } from '../../models/support/response-state';
import { ClientService } from '../../services/client/client.service';
import { SharedService } from '../../services/other/shared.service';
import { RegistrationCycleGeneralService } from '../../services/registration-cycle/registration-cycle-general.service';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-modal-show-intro-video',
  templateUrl: './modal-show-intro-video.component.html',
  styleUrls: ['./modal-show-intro-video.component.scss'],
})
export class ModalShowIntroVideo implements OnInit {
  //@ViewChild('videoPlayer') videoplayer: ElementRef;

  public VideoId = 'zuLaaHgVXR0';
  public VideoNumberInput: VideoNumber;

  playerConfig = {
    controls: 1,
    mute: 0,
    autoplay: 1,
  };

  public CurrentModalPictureType: ModalPictureType;
  public ModalTitle: string;
  public Name: string;
  public NoPicture: string;
  public DeletePicture: string;
  public LoadingSavePicture: string;
  public videoSource: string;
  public SavePicture: string;
  public UploadImageResultList: UploadImageResult[] = [];
  public PicturePath: string;
  public apiLoaded = false;
  public FilesList: string[] = [];
  public Loading: boolean = false;
  constructor(
    public activeModal: NgbActiveModal,
    public sharedService: SharedService,
    public adminService: AdminService,
    public generalService: RegistrationCycleGeneralService,
    public translate: TranslateService,
    public clientService: ClientService,
    public userService: UserService
  ) {}

  ngOnInit(): void {
    /*if (!this.apiLoaded) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      this.apiLoaded = true;
    }*/

    this.ModalTitle = '';

    if (this.VideoNumberInput == VideoNumber.One) {
      this.ModalTitle =
        'Glamera|TheBestProgramForManagingBeautyCentersAndCenters';
    } else if (this.VideoNumberInput == VideoNumber.Two) {
      this.ModalTitle = 'RegistrationInGlamera';
    } else if (this.VideoNumberInput == VideoNumber.Three) {
      this.ModalTitle = 'WelcomeInGlameraFamily';
    }

    this.videoSource = 'https://www.youtube.com/watch?v=zuLaaHgVXR0';
  }

  public get videoNumber(): typeof VideoNumber {
    return VideoNumber;
  }

  deleteItem(item: any) {
    console.log(item.Id);
  }

  ngOnDestroy() {}

  HandelSetDoneShowIntroVideo(VideoNumberInput: VideoNumber) {
    if (VideoNumberInput == VideoNumber.Three) {
      let model = new SetDoneShowIntroVideoModel();
      model.UserId = this.sharedService.UserId;
      model.CompanyId = this.sharedService.CompanyId;

      this.generalService
        .SetDoneShowIntroVideo(model)
        .subscribe((response: SetDoneShowIntroVideoSearchResult) => {
          if (response.State == ResponseState.Success) {
            var getToken = this.sharedService.TokenObject;
            getToken.ShowIntroVideo = false;
            this.sharedService.TokenObject = getToken;
            //this.sharedService.ToastrSuccess(response.Message);
          }
        });
    }
  }

  toggleVideo(event: any) {
    //this.videoplayer.nativeElement.play();
  }
}
