import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Transaction2Service } from '../../transaction/service/transaction.service';
import { ResponseState } from '../../../models/support/response-state';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from '../../admin/service/admin.service';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { CreditCardSearchCriteria } from '../../../models/search-criterias/credit-card-search-criteria';
import { PaymentMethodSearchCriteria } from '../../../models/search-criterias/payment-method-search-criteria';
import { CreditCardService } from '../../../services/cashier/credit-card.service';
import { SharedService } from '../../../services/other/shared.service';
import { AccountPayments } from '../../transaction/model/cashier-constants';
import { PaymentMethodService } from '../../../services/cashier/payment-method.service';
import { PaymentMethodType } from '../../../models/enums/payment-method-type';
@Component({
  selector: 'app-transaction-payment',
  templateUrl: './transaction-payment.component.html',
  styleUrls: ['./transaction-payment.component.scss']
})
export class TransactionPaymentComponent implements OnInit {

  @Input() TPForm: UntypedFormGroup;
  public accountPayments: any;
  VisaTypeName = ['HSBC', 'ALEXBANK ', 'QNB Viza ']

  @Output() myData = new EventEmitter();
  paymentMethodSearchCriteria: PaymentMethodSearchCriteria;
  creditCardSearchCriteria: CreditCardSearchCriteria;

  constructor(private transactionService: Transaction2Service,
    public sharedService: SharedService,
    private adminService: AdminService,
    private paymentMethodService: PaymentMethodService,
    private creditCardService: CreditCardService,
    private toastr: ToastrService,
    private route: ActivatedRoute,


  ) {
    this.accountPayments = AccountPayments;
    this.paymentMethodSearchCriteria = new PaymentMethodSearchCriteria();
    this.creditCardSearchCriteria = new CreditCardSearchCriteria();
  }

  @Output() CalculateNew = new EventEmitter();

  cashsaleId;

  ngOnInit(): void {

    this.cashsaleId = this.route.snapshot.params['id'];
    this.getByIdAccountSetup();
    this.getAccountCurrencies();
    this.searchPaymentMethods();
    this.searchCreditCards();

  }

  /*======== getByIdAccountSetup=========================================================*/
  AccountSetup; AccountCurrencies;
  getByIdAccountSetup() {
    this.transactionService.getByIdAccountSetup(this.sharedService.AccountSetupId).subscribe((response: any) => {
      if (response.State == ResponseState.Success) {
        this.AccountSetup = response.Result;
      }
    });
  }

  getAccountCurrencies() {
    let jsonObj =
    {
      AccountSetupId: this.sharedService.AccountSetupId,
      FreeText: '', Status: ''
    }

    this.adminService.GetAccountCurrency(jsonObj).subscribe((response: any) => {
      if (response.State == ResponseState.Success) {
        this.AccountCurrencies = response.AccountCurrencys;

        if (!this.cashsaleId) {
          const controlArray = <UntypedFormArray>this.TPForm.get('Transaction').get('TransactionsPayments');
          controlArray.controls[0].get('CurrencyId').patchValue(this.AccountCurrencies[0].Id);
        }

      }
    });
  }

  PaymentMethods;
  searchPaymentMethods() {
    this.paymentMethodSearchCriteria.AccountSetupId = this.sharedService.AccountSetupId;

    this.paymentMethodService.searchPaymentMethodsLite(this.paymentMethodSearchCriteria).subscribe(result => {
      if (result.State == ResponseState.Success) {
        this.PaymentMethods = result.PaymentMethods;

        if (!this.cashsaleId) {
          const CashPaymentMethods = this.PaymentMethods
          .find(e => e.PaymentMethodType == PaymentMethodType.Cash);
          const controlArray = <UntypedFormArray>this.TPForm.get('Transaction').get('TransactionsPayments');
          controlArray.controls[0].get('PaymentMethodId').patchValue(CashPaymentMethods.Id);
        }
      }
    });
  }


  CreditCards;
  searchCreditCards() {
    this.creditCardSearchCriteria.AccountSetupId = this.sharedService.AccountSetupId;
    this.creditCardService
      .searchCreditCards(this.creditCardSearchCriteria)
      .subscribe(result => {
        if (result.State == ResponseState.Success) {
          this.CreditCards = result.CreditCards;
        }
      });
  }

  paymentMethodType;
  ChoosePayment(e, i) {
    console.log(e.Id)

    const controlArray = <UntypedFormArray>this.TPForm.get('Transaction').get('TransactionsPayments');
    controlArray.controls[i].get('PaymentMethodType').patchValue(e.PaymentMethodType);
    controlArray.controls[i].get('CurrencyId').patchValue(this.AccountCurrencies[0].Id);

    if (i == 1) {
      if (e.Id == controlArray.controls[0].get('PaymentMethodId').value) {
        this.sharedService.ToastrError('لا يسمح اختيار طريقة دفع مرتين')
        controlArray.controls[i].get('PaymentMethodId').patchValue(0);
      }
      else {
        console.log(controlArray.controls[0].get('PaymentMethodId').value)

      }
    }
    else if (i == 0) {
      if (e.Id == controlArray.controls[1].get('PaymentMethodId').value) {
        this.sharedService.ToastrError('لا يسمح اختيار طريقة دفع مرتين')
        controlArray.controls[i].get('PaymentMethodId').patchValue(0);
      }
      else {
        console.log(controlArray.controls[0].get('PaymentMethodId').value)
        // this.sharedService.ToastrSuccess('ok')

      }
    }
  }
  oneAmount = 0;
  twoAmount = 0;
  CalcTypePay(e, i) {

    const controlArray = <UntypedFormArray>this.TPForm.get('Transaction').get('TransactionsPayments');
    console.log(this.TPForm.get('Transaction').get('TotalNetPrice').value)

    console.log(controlArray.controls[0].get('Amount').value)
    console.log(controlArray.controls[1].get('Amount').value)
    if (controlArray.controls[1].get('Amount').value > controlArray.controls[0].get('Amount').value) {
      this.sharedService.ToastrError('اختر قيمة اقل ')
      controlArray.controls[0].get('Amount').patchValue(this.TPForm.get('Transaction').get('TotalNetPrice').value)
      controlArray.controls[1].get('Amount').patchValue(0)
    }
    else {
      if (i = 1) {
        controlArray.controls[0].get('Amount').patchValue(
          this.TPForm.get('Transaction').get('TotalNetPrice').value - controlArray.controls[1].get('Amount').value
        );
      }
    }

  }
  removeItem(i) {
    (this.TPForm.get('Transaction').get('TransactionsPayments') as UntypedFormArray).removeAt(i);
    this.CalculateNew.emit('Calc');

    const controlArray = <UntypedFormArray>this.TPForm.get('Transaction').get('TransactionsPayments');
    controlArray.controls[0].get('Amount').patchValue(
      this.TPForm.get('Transaction').get('TotalNetPrice').value
    );


    let tp = this.TPForm.get('Transaction').get('TransactionsPayments').value.length
    for (let p = 0; p < tp; p++) {
    }

  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

}
