
<!-- <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "square-jelly-box" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner> -->
<!-- <ngx-spinner type="ball-atom"></ngx-spinner> -->
<!-- <ngx-spinner class="la-ball-atom" bdColor = "#c0c2d5c2" size = "default" color = "#fff" type = "ball-atom" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner> -->
<ngx-spinner
  bdColor="#ffffff94"
  size="medium"
  color="#5d5bd7"
  type="ball-atom"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>


<router-outlet></router-outlet>
<!-- dev -->
