import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arabicDate'
})
export class ArabicDatePipe implements PipeTransform {
  transform(value: any): any {
    if (!value) {
      return '';
    }

    // Replace the Western Arabic numerals with Eastern Arabic numerals
    const arabicDigits = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
    const westernDigits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

    let formattedDate = value;

    for (let i = 0; i < 10; i++) {
      formattedDate = formattedDate.replace(new RegExp(westernDigits[i], 'g'), arabicDigits[i]);
    }

    return formattedDate;
  }
}