<div class="row">
  <div class="col-md-12">
    <nav aria-label="breadcrumb">
      <div class="row">
        <div class="col-12">
          <ol class="breadcrumb">
            <button *ngIf="sharedService.CheckAuthorization(2601) && !asModal" class="btn add-new-btn"
              data-toggle="modal" data-target="#ModalAddEdit" (click)="ModelAdd()">
              <i class="fas fa-plus"></i> &nbsp;
              {{ "NewServiceCategory" | translate }}
            </button>
            <li class="breadcrumb-item">
              <a routerLink="/admin"> {{ "Admin" | translate }}</a>
            </li>
            <li class="breadcrumb-item active">
              {{ "ServiceCategories" | translate }}
            </li>
          </ol>
          <!-- Modal add New -->
          <div class="modal fade" id="ModalAddEdit" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    {{ "ServiceCategories" | translate }}
                  </h5>
                </div>
                <form [formGroup]="GForm" (ngSubmit)="CreateData()">
                  <div class="modal-body">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="formCard">
                          <div class="form-group">
                            <!-- <label>{{ 'NameAr' | translate }}</label> -->
                            <input type="text" class="form-control" name="NameAr"
                              placeholder="{{ 'NameAr' | translate }}" formControlName="NameAr" required
                              maxlength="50" />
                          </div>
                          <div class="form-group">
                            <!-- <label>{{ 'NameEn' | translate }}</label> -->
                            <input type="text" class="form-control" formControlName="NameEn" required maxlength="50"
                              placeholder="{{ 'NameEn' | translate }}" />
                          </div>

                          <div class="form-group">
                            <!-- <label >{{ 'OnlineDescriptionAr' | translate }}</label> -->
                            <input type="text" class="form-control" formControlName="OnlineDescription" maxlength="50"
                              placeholder="{{
                                'OnlineDescriptionAr' | translate
                              }}" />
                          </div>
                          <div class="form-group">
                            <!-- <label >{{ 'OnlineDescriptionEn' | translate }}</label> -->
                            <input type="text" class="form-control" placeholder="{{
                                'OnlineDescriptionEn' | translate
                              }}" formControlName="OnlineDescriptionEn" maxlength="50" />
                          </div>
                          <br />
                          <div class="form-inline mb-3">
                            <label>{{ "Status" | translate }} </label>
                            <div class="switch mx-3">
                              <label>
                                <input type="checkbox" formControlName="Status" name="isCheck" checked="checked" />
                                <span class="lever"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="formCard">
                          <div class="form-group">
                            <!-- <label >{{ 'OnlineDetails' | translate }}</label> -->
                            <textarea rows="4" cols="50" class="form-control"
                              placeholder="{{ 'OnlineDetails' | translate }}"
                              formControlName="OnlineDetails"></textarea>
                          </div>

                          <div [hidden]="IsGymMembershipCategory.value" class="form-group">
                            <label class="ClassificationTitle" for="ClassId">{{
                              "ClassId" | translate
                              }}</label>
                            <select class="form-control" formControlName="ClassId"
                              [required]="!IsGymMembershipCategory.value">
                              <option [value]="null" disabled>
                                {{ "ClassId" | translate }}
                              </option>
                              <option *ngFor="let classification of Classifications" [value]="classification.dbId">
                                {{ classification.na }}
                              </option>
                            </select>
                          </div>
                          <br />
                          <div *ngIf="sharedService.AllowUseGym" class="form-inline mb-3">
                            <label>{{ "GymMembershipCategory" | translate }}
                            </label>
                            <div class="switch mx-3">
                              <label>
                                <input type="checkbox" formControlName="IsGymMembershipCategory"
                                  name="GymMembershipCategory" />
                                <span class="lever"></span>
                              </label>
                            </div>
                          </div>
                          <div class="form-inline mb-3">
                            <label> {{"IsFastCategoryForSerices" | translate}}</label>
                            <div class="switch mx-3">
                              <label>
                                <input type="checkbox" formControlName="IsFastCategory" name="IsFastCategory "
                                  checked="checked" />
                                <span class="lever"></span>
                              </label>
                            </div>
                          </div>

                          <div class="form-inline mb-3">
                            <label> {{"IsShowOnline" | translate}}</label>
                            <div class="switch mx-3">
                              <label>
                                <input type="checkbox" formControlName="ShowOnline" name="ShowOnline"
                                  checked="checked" />
                                <span class="lever"></span>
                              </label>
                            </div>
                          </div>

                          <div class="form-inline mb-3">
                            <label>{{ "ServiceCategoryOrder" | translate }}</label>
                            <input type="number" class="form-control" formControlName="Order" min="0"
                                placeholder="{{ 'ServiceCategoryOrder' | translate }}">

                          </div>

                        </div>
                      </div>

                      <!-- <div class="col-md-6">
                        <div class="formCard">
                          <div class="form-group">
                            <input class="col-md-6" type="file" accept=".png, .jpg,.jpeg" class="form-control"
                              name="file2" id="file2" (change)="onFileChange($event)" placeholder="  ">
                          </div>
                        </div>
                      </div> -->


                      <div class="col-md-3 col-sm-3 col-6" *ngIf="!imageSrc && !ImagePath">
                        <div class="card-upload" (click)="file.click()">
                          <i class="fas fa-plus"></i>
                          <p> اختار الصور </p>
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-3 col-6" *ngIf="imageSrc || ImagePath">
                        <div class="card-upload position-relative">
                          <i class="fas fa-times position-absolute top-0 left-0 delete-icon" (click)="deleteIcon()"></i>
                          <img [src]="imageSrc || ImagePath" class="img-fluid" alt="">
                        </div>
                      </div>
                      <!-- <button type="button">
                        <i class="fa-upload"></i></button> -->
                      <button class="btn z-depth-0" type="button" (click)="file.click()">
                        <i class="fas fa-upload fa-2x"></i>
                      </button>

                      <div style="display: none;" class="col-sm-3 col-6">
                        <button type="button" class="btn btn-upload" (click)="file.click()">رفع صورة </button>
                        <input type="file" hidden accept=".png, .jpg,.jpeg" class="form-control" name="file" id="file"
                          onclick="this.value = null" (change)="onFileChange($event)" #file>
                      </div>


                      <div class="form-group col-md-12">
                        <label>{{ "BranchesToViewAndUse" | translate }}
                          <span class="text-info">
                            -
                            {{
                            "Note-ThisWillAllowedInMainBranchIfChooseBranchesOrNot"
                            | translate
                            }}
                          </span>
                        </label>
                        <ng-select placeholder="{{
                            'BranchesToViewAndUse' | translate
                          }}" [multiple]="true" [hideSelected]="true" [items]="CompanyAccountSetups"
                          bindLabel="GlobalName" bindValue="Id" name="CategoryBranchesIdList"
                          formControlName="CategoryBranchesIdList" [(ngModel)]="CategoryBranchesIdList">
                        </ng-select>
                      </div>
                      <!-- <div class="col-md-12">
                        <div class="formCard">
                          <h6>{{ "Loyality" | translate }}</h6>
                          <div class="row">
                            <div class="form-group col-md-6" [hidden]="true">
                              <label>{{
                                "PointsRedemption" | translate
                              }}</label>
                              <select
                                class="form-control"
                                formControlName="PointsRedemption"
                              >
                                <option value="1">
                                  {{ "FreewhenClienthaspoints" | translate }}
                                </option>
                                <option value="0">
                                  {{ "Nevergiveforfree" | translate }}
                                </option>
                              </select>
                            </div>
                            <div class="form-group col-md-6">
                              <label for="LoyaltyPointSystem">{{
                                "LoyaltyPointSystem" | translate
                              }}</label>
                              <select
                                class="form-control"
                                formControlName="LoyaltyPointSystem"
                              >
                                <option
                                  *ngFor="
                                    let loyaltysystem of LoyaltyPointSystem
                                  "
                                  [value]="loyaltysystem.Id"
                                >
                                  {{ loyaltysystem.TranslateKey | translate }}
                                </option>
                              </select>
                            </div>
                            <div
                              class="form-group col-md-6"
                              *ngIf="GForm.get('LoyaltyPointSystem').value != 3"
                            >
                              <label for="LoyaltyPoints">{{
                                "LoyaltyPoints" | translate
                              }}</label>
                              <input
                                type="number"
                                class="form-control"
                                formControlName="LoyaltyPoints"
                              />
                            </div>

                          </div>
                        </div>
                      </div> -->
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button class="btn btn-danger" data-dismiss="modal">
                      {{ "Close" | translate }}
                    </button>
                    <button class="btn btn-success" [disabled]="!GForm.valid || isSubmitting">
                      <span *ngIf="!isSubmitting"> {{ 'Submit' | translate }}</span>
                      <span *ngIf="isSubmitting"> <i class="fas fa-circle-notch fa-spin"></i> {{ 'Loading' | translate
                        }}</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
  <div class="col-md-12">
    <div class="filter-card">
      <div class="row px-2">
        <div class="col-md-3 col-sm-6 px-2">
          <app-search (SendFreeText)="ReciveFreeText($event)" (autoSearchByText)="autoSearchByText($event)">
          </app-search>
        </div>

        <div *ngIf="sharedService.AllowUseGym" class="col-md-2 col-sm-6 px-2">
          <div class="input-group" *ngIf="sharedService.CheckAuthorization(2600)">
            <select class="form-control" (change)="filterByCatType($event)">
              <option value="" disabled selected>
                {{ "CategoryType" | translate }}
              </option>
              <option [value]="null">{{ "All" | translate }}</option>
              <option value="2">{{ "ServiceCat" | translate }}</option>
              <option value="3">
                {{ "GymMembershipCategory" | translate }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-2 col-sm-6 px-2">
          <div class="input-group" *ngIf="sharedService.CheckAuthorization(2600)">
            <select class="form-control" (change)="filterByStatus($event)">
              <option value="" disabled selected>
                {{ "Status" | translate }}
              </option>
              <option value="1">{{ "Active" | translate }}</option>
              <option value="0">{{ "Suspended" | translate }}</option>
            </select>
          </div>
        </div>

        <div class="col-md-3 col-sm-6 px-2"></div>
        <div class="col-md-2 col-sm-6 px-2" *ngIf="!asModal">
          <app-import-export (exportExelFile)="exportServicesCategories()"></app-import-export>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12" *ngIf="Categories; let Categories; else: loading">
    <div class="table-card">
      <table class="table table-stripedd table-sm table-bordered">
        <thead class="table-head">
          <tr>
            <th>{{ "Name" | translate }}</th>
            <th *ngIf="sharedService.AllowUseGym">
              {{ "CategoryType" | translate }}
            </th>
            <th>{{ "Status" | translate }}</th>
            <th *ngIf="!asModal">{{ "Action" | translate }}</th>
            <th *ngIf="asModal">{{ "Add_Account" | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let category of Categories; let i = index">
            <td>
              {{
              sharedService.Lang == "ar" ? category.NameAr : category.NameEn
              }}
            </td>
            <td *ngIf="sharedService.AllowUseGym">
              {{
              category.CatType == 2
              ? ("ServiceCat" | translate)
              : ("GymMembershipCategory" | translate)
              }}
            </td>
            <td>
              {{
              category.Status == 0
              ? ("Suspended" | translate)
              : ("Active" | translate)
              }}
            </td>
            <td *ngIf="!asModal">
              <button *ngIf="sharedService.CheckAuthorization(2602)" class="btn btn-success btn-sm my-0 px-3"
                (click)="ModalEdit($event, category)" data-toggle="modal" data-target="#ModalAddEdit">
                <i class="fas fa-edit"></i>
              </button>
              <button *ngIf="sharedService.CheckAuthorization(2603)" class="btn btn-danger btn-sm my-0 px-3"
                (click)="OpenModal($event, category)" data-toggle="modal" data-target="#Delete">
                <i class="fas fa-trash"></i>
              </button>

              <!-- Modal Delete   -->
              <div class="modal fade" id="Delete" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true" *ngIf="editState && itemToEdit.Id == category.Id">
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title text-capitalize" id="exampleModalLabel">
                        {{ "Delete" | translate }}
                      </h5>
                    </div>
                    <div class="modal-body">
                      <div class="text-center py-4">
                        <h4>{{ "DeleteConfirm" | translate }}?</h4>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="Delete(category)">
                        {{ "Yes" | translate }}
                      </button>
                      <button type="button" class="btn btn-success" data-dismiss="modal">
                        {{ "No" | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </td>

            <td *ngIf="asModal">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text px-1" id="basic-addon1" *ngIf="modeType">{{ "IsDebit" | translate
                    }}</span>
                  <span class="input-group-text px-1" id="basic-addon1" *ngIf="!modeType">{{ "IsCredit" | translate
                    }}</span>
                </div>
                <input class="form-control" type="text" name="ChildAccountName"
                  [ngModel]="category?.accountSelected?.Name" />

                <div class="input-group-prepend">
                  <button class="btn btn-sm mx-0 my-0 px-3 z-depth-0 waves-effect" type="button" id="button-addon1"
                    (click)="getAccountInfo2(category)">
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
                <div class="input-group-prepend">
                  <button class="btn btn-sm mx-0 my-0 px-3 z-depth-0 waves-effect" type="button"
                    (click)="onRemoveAccount(category)">
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="text-center py-4 my-4" *ngIf="Categories.length == 0">
        <h4 class="py-4">{{ "NoDataFound" | translate }}</h4>
      </div>
    </div>
  </div>
  <div class="col-md-12">
    <ng-template #loading>
      <div class="col-md-12 text-center py-4 my-4">
        <i class="fas fa-circle-notch fa-spin fa-3x"></i>
      </div>
    </ng-template>
  </div>

  <div class="col-md-12" [hidden]="totalCount < 10">
    <app-pagination (GetSources)="GetData()" #pager></app-pagination>
  </div>
</div>
