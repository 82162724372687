import { NgModule } from '@angular/core';
import { FilterEmployeesPipe } from '../pipes/filter-employees-pipe';
import { ToFixedPipe } from '../pipes/to-fixed-pipe';
import { TranslationPipe } from '../pipes/translation-pipe';
import { RoundNearestNumberPipe } from '../pipes/truncate-fraction-in-number-pipe';

@NgModule({
  imports: [
    // dep modules
  ],
  declarations: [
    FilterEmployeesPipe,
    RoundNearestNumberPipe,
    ToFixedPipe,
    TranslationPipe,
  ],
  exports: [
    FilterEmployeesPipe,
    RoundNearestNumberPipe,
    ToFixedPipe,
    TranslationPipe,
  ],
})
export class ApplicationPipesModule {}
