
import { ResourceStatus } from '../enums/resource-status';
import { SearchCriteria } from "./search-criteria";

export class CreditCardSearchCriteria extends SearchCriteria {
    public Id: number;
    public Name: string;
    public Status:ResourceStatus;
   
}

