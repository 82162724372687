import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CostCenterService } from '../../dashboard/accounts/services/cost-center.service';
import { AdminService } from '../../dashboard/admin/service/admin.service';
import { ResponseState } from '../../models/support/response-state';
import { ClientService } from '../../services/client/client.service';
import { BaseServiceSZ } from '../../services/other/baseService';
import { SharedService } from '../../services/other/shared.service';
import { Transaction1Service } from '../../services/transaction/transaction.service';
import { FinancialPeriodService } from 'src/app/dashboard/accounts/services/financial-period.service';
import { ChooseAccountsComponent } from '../choose-accounts/choose-accounts.component';

@Component({
  selector: 'app-close-finiancial-period',
  templateUrl: './close-finiancial-period.component.html',
  styleUrls: ['./close-finiancial-period.component.scss']
})
export class CloseFiniancialPeriodComponent implements OnInit {

  public GForm: UntypedFormGroup;
  public IsSubmit: boolean;
  public AccountSetupId: number;
  public AccountName: string;

  @Input() CompanyBranches: any[];
  @Output() LoadData = new EventEmitter();


  constructor(private toastr: ToastrService,
    private clientService: ClientService,
    public sharedService: SharedService,
    private _BaseService: BaseServiceSZ,
    private fb: UntypedFormBuilder,
    private transactionService: Transaction1Service,
    private router: Router,
    private route: ActivatedRoute,
    public financialPeriodService: FinancialPeriodService,
    public datepipe: DatePipe,
    public translateservice: TranslateService,
    public CostCenterService: CostCenterService,
    private adminService: AdminService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {

    this.GFormData();
    this.IsSubmit = false;

  }

  GFormData() {
    this.GForm = this.fb.group({
      BranchId: ['', Validators.required],
      AccountId: ['', Validators.required],
      ClosingPeriod: ['', Validators.required]
    })

  }
  ChangeIsActive(e) {
    if (e.target.checked) {
      this.GForm.controls.IsActive.setValue(true);
    } else {
      this.GForm.controls.IsActive.setValue(false);
    }
  }


  CreateJournalForClosingPeriod() {

    this.IsSubmit = true;
    this.financialPeriodService.CreateJournalForClosingPeriod(this.GForm.value).subscribe((response: any) => {
      if (response.State == ResponseState.Success) {

        this.sharedService.ToastrSuccess(response.Message);
        this.GForm.reset();
        this.GFormData();
        this.LoadData.emit('get');

      }
      else {
        this.sharedService.ToastrError(response.Message);
      }
      this.IsSubmit = false;
    });
  }

  chooseAccount() {

    const modalRef = this.modalService.open(ChooseAccountsComponent);
    modalRef.componentInstance.AccountsetupId = this.GForm.get('BranchId').value;
    modalRef.componentInstance.isViewPlus = true;

    modalRef.componentInstance.emitService.subscribe((account) => {

      this.GForm.get('AccountId')
        .patchValue(account.Id);

      this.AccountName = this.sharedService.Lang == "ar" ?
        account.NameAr : account.NameEn;

    });
  }

}
