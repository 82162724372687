<div id="printClientGiftCardInfo">
  <div class="modal-header">
    <h5 class="modal-title text-capitalize" id="exampleModalLabel">
      <i class="fas fa-user"></i> &nbsp;
      <span *ngIf="!EmployeeId">
        {{'AddEmployee' | translate}}
      </span>
      <span *ngIf="EmployeeId">
        {{'UpdateEmployee' | translate}}
      </span>
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12 col-md-12" *ngIf="!LoadingGetEmployee; else loading">
        <!-- <form [formGroup]="GForm" (ngSubmit)="CreateData()">
          <div class="modal-body">
            <h5>{{ 'EmployeeDetails' | translate }}</h5>
            <div class="formCard mt-3 ">
              <div class="row">
                <div class="form-group col-md-4">
                  <label>{{ 'Name' | translate }}</label>
                  <input type="text" class="form-control" id="Name" required formControlName="Name" maxlength="50">
                </div>
                <div class="form-group col-md-4">
                  <label>{{ 'Address' | translate }}</label>
                  <input type="text" class="form-control" id="Address" formControlName="Address" maxlength="50">
                </div>
                <div class="form-group col-md-4">
                  <label>{{ 'Phone' | translate }}</label>
                  <input type="text" class="form-control" id="PhoneNumber" formControlName="PhoneNumber" maxlength="50">
                </div>
                <div class="form-group col-md-4">
                  <label>{{ 'MobileNumber' | translate }}</label>
                  <input type="text" class="form-control" id="MobileNumber" formControlName="MobileNumber"
                    maxlength="50">
                </div>
                <div class="form-group col-md-4">
                  <label>{{ 'OtherPhoneNumber' | translate }}</label>
                  <input type="text" class="form-control" id="OtherPhoneNumber" formControlName="OtherPhoneNumber"
                    maxlength="50">
                </div>

                <div class="form-group col-md-4">
                  <label for="Postcode">{{ 'Postcode' | translate }}</label>
                  <input type="text" class="form-control" id="Postcode" formControlName="Postcode" maxlength="50">
                </div>
                <div class="col-md-4">
                  <label>{{ 'TaxNumber' | translate }}</label>
                  <input formControlName="EmployeeTaxNumber" class="form-control" >

                </div>
                <div class="col-md-2">
                  <div class="form-group row">
                    <label class="col-6">{{ 'Status' | translate }}
                    </label>
                    <div class="switch col-6 text-end">
                      <label>
                        <input type="checkbox" formControlName="Status" id="Status" checked="checked">
                        <span class="lever"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div><br>

                <div class="form-group col-md-12">
                  <label for="Notes">{{'Notes'|translate}}</label>
                  <textarea rows="2" cols="50" class="form-control" id="Notes" formControlName="Notes"
                    maxlength="50"></textarea>
                </div>

                <div class="col-md-12 text-center">
                  <button [disabled]="btnLoading" class="btn btn-danger" type="button"
                    (click)="openModalUploadAttachements()"> <i class="fas fa-cloud-upload-alt"></i>
                    &nbsp;{{'UploadAttachements' |
                    translate }}</button>
                </div>


          </div>
        </form> -->

        <form [formGroup]="GForm" (ngSubmit)="CreateData()">
          <div class="modal-body">
            <div class="btnsTab">

            </div>
            <div class="cardTabs">
              <div [hidden]="selectedTab!='2'" class="tabsContent">
                <div class="formCard mb-3">

                  <h5> {{'EmployeePicture' | translate}}</h5>

                  <div class="row">

                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-lg-12">
                          <label class="hoverable-add-edit" for="fileInputAddEdit">
                            <img class="img-add-edit" [src]="this.GForm.get('EmployeePicturePath').value &&
                              this.GForm.get('EmployeePicturePath').value.length > 0 ?
                              this.GForm.get('EmployeePicturePath').value :
                              'assets/img/img_avatar.png'">
                            <div class="hover-text">{{'ChoosePicture' | translate}}</div>
                            <div class="background"></div>
                          </label>
                          <br />
                          <input id="fileInputAddEdit" type='file' (change)="onSelectFile($event)"
                            accept=".png, .jpg,.jpeg">

                          <button class="btn btn-success" *ngIf="this.GForm.get('EmployeePicturePath').value &&
                          this.GForm.get('EmployeePicturePath').value.length > 0"
                            (click)="DeleteEmployeePicture()">{{'DeleteEmployeePicture' | translate}}</button>
                          <br />
                        </div>

                      </div>
                    </div>

                  </div>

                  <h5> {{'EmployeeDetails' | translate}}</h5>

                  <div class="row ">

                    <div class="form-group col-md-4">
                      <label>{{ 'NameAr' | translate }}</label>
                      <input type="text" class="form-control" required formControlName="NameAr">
                      <div *ngIf="NameAr.invalid && (NameAr.dirty || NameAr.touched)" class=" pt-2 text-danger">
                        <div *ngIf="NameAr.errors?.required"> {{ 'Required' | translate }} </div>

                      </div>
                    </div>

                    <div class="form-group col-md-4">
                      <label>{{ 'NameEn' | translate }}</label>
                      <input type="text" class="form-control" required formControlName="NameEn">
                      <!-- <input type="text" class="form-control" required formControlName="NameEn"
                        [(ngModel)]="EmployeeName"> -->
                    </div>


                    <div class="form-group col-md-4">
                      <label>{{ 'EmployeeCode' | translate }}</label>
                      <input type="number" class="form-control" formControlName="EmployeeCode">

                    </div>
                    <div class="form-group col-md-4">
                      <label>{{ 'MobileNumber' | translate }}</label>
                      <input type="text" numbersOnly class="form-control" formControlName="MobileNumber">
                      <div
                        *ngIf="VForm.MobileNumber.invalid && (VForm.MobileNumber.dirty || VForm.MobileNumber.touched)"
                        class=" pt-2 text-danger">

                        <small *ngIf="VForm.MobileNumber.hasError('pattern')">
                          {{'INCORRECT' | translate}}
                        </small>
                      </div>

                    </div>


                    <div class="form-group col-md-4">
                      <label>{{ 'Email' | translate }}</label>
                      <input type="text" class="form-control" formControlName="Email">
                      <small *ngIf="VForm.Email.hasError('pattern')">
                        {{'INCORRECT' | translate}}
                      </small>
                    </div>
                    <div class="form-group col-md-4">
                      <label>{{ 'BirthDate' | translate }}</label>
                      <input type="date" placeholder="{{ 'BirthDate' | translate }}" class="form-control"
                        formControlName="BirthDate">
                    </div>
                    <div class="form-group col-md-4">
                      <label>{{ 'identityType' | translate }}</label>
                      <ng-select (change)="onChangeidentityType($event)" placeholder="{{  'identityType' | translate }}"
                        [multiple]="false" [items]="identityType" formControlName="IdentityType" bindLabel="Name"
                        bindValue="Id">
                      </ng-select>
                    </div>
                    <div class="form-group col-md-4">
                      <label>{{ 'Nationality' | translate }}</label>
                      <ng-select placeholder="{{'Nationality' | translate }}" [multiple]="false" [items]="Nationalities"
                        formControlName="NationalityId" bindLabel="GlobalName" bindValue="Id">
                      </ng-select>
                    </div>
                    <div class="form-group col-md-4">
                      <label>{{ 'identityNumber' | translate }}</label>
                      <input type="text" class="form-control" maxlength="{{CheckcountryId}}"
                        formControlName="IdentityNumber">
                      <div
                        *ngIf="VForm.IdentityNumber.invalid && (VForm.IdentityNumber.dirty || VForm.IdentityNumber.touched)"
                        class=" pt-2 text-danger">
                        <div *ngIf="VForm.IdentityNumber.errors.required">
                          {{'INCORRECT' | translate}}
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-4">
                      <label>{{ 'Salary' | translate }}</label>
                      <input type="number" placeholder="{{'Salary' | translate }}" class="form-control"
                        formControlName="Salary">
                    </div>
                    <div class="form-group col-md-4">
                      <label>{{ 'BankAccountNumber' | translate }}</label>
                      <input type="text" placeholder="{{'BankAccountNumber' | translate }}" class="form-control"
                        formControlName="BankAccountNumber">
                    </div>

                    <div class="col-md-12">
                      <hr style="border-top: 5px solid rgb(147 136 136 / 72%);">
                    </div>

                    <div class="form-group col-md-4">
                      <label>{{ 'FingerprintAreas' | translate }}</label>
                      <ng-select placeholder="{{ 'FingerprintAreas' | translate }}" [multiple]="true"
                        [items]="autoCompleteAreas" bindLabel="NameAr" bindValue="Id" name="autoCompleteAreas"
                        formControlName="FingerprintAreaIds">
                      </ng-select>
                    </div>
                    <div *ngIf="VForm.FingerprintCode.value !=null && VForm.Id.value >0 " class="form-group col-md-4">
                      <label>{{ 'FingerPrintCode' | translate }}</label>
                      <input type="text" class="form-control" formControlName="FingerprintCode" readonly>
                    </div>

                    <div class="form-group col-md-4">
                      <label>{{ 'AttendMethodMaster' | translate }}</label>
                      <ng-select placeholder="{{ 'AttendMethodMaster' | translate }}" [multiple]="false"
                        [items]="AttendMethodMasters" formControlName="AttendMethodMasterId"
                        [bindLabel]="sharedService.Lang == 'ar' ? 'NameAr' : 'NameEn'" bindValue="Id">
                      </ng-select>

                    </div>
                    <div class="form-group col-md-4">
                      <label>{{ 'Department' | translate }}</label>
                      <ng-select placeholder="{{ 'Department' | translate }}" [multiple]="false" [items]="Departments"
                        formControlName="DepartmentId" [bindLabel]="sharedService.Lang == 'ar' ? 'NameAr' : 'NameEn'"
                        bindValue="Id">
                      </ng-select>
                    </div>
                    <div class="form-group col-md-4">
                      <label>{{ 'Positions' | translate }}</label>
                      <ng-select placeholder="{{ 'Positions' | translate }}" [multiple]="true"
                        [items]="EmployeePositions" formControlName="PositionsId"
                        [bindLabel]="sharedService.Lang == 'ar' ? 'NameAr' : 'NameEn'" bindValue="Id">
                      </ng-select>
                    </div>


                    <div class="form-group col-md-4">
                      <label>{{ 'HiringDate' | translate }}</label>
                      <input type="date" [ngModel]="hiringDate | date:'yyyy-MM-dd'"
                        (ngModelChange)="hiringDate = $event" class="form-control" formControlName="HiringDate">

                    </div>

                    <div class="col-md-12">
                      <hr style="border-top: 5px solid rgb(147 136 136 / 72%);">
                    </div>


                    <div class="form-group col-md-4">
                      <label>{{ 'Country' | translate }}</label>
                      <ng-select (change)="checkcountryCode($event)" placeholder="{{ 'Country' | translate }}"
                        [multiple]="false" [items]="Countries" formControlName="CountryId" bindLabel="na"
                        bindValue="dbId">
                      </ng-select>
                    </div>
                    <div class="form-group col-md-4">
                      <label>{{ 'City' | translate }}</label>
                      <ng-select (change)="checkCityCode($event)" placeholder="{{ 'City' | translate }}"
                        [multiple]="false" [items]="Cities" formControlName="CityId" bindLabel="na" bindValue="dbId">
                      </ng-select>
                    </div>
                    <div class="form-group col-md-4">
                      <label>{{ 'Area' | translate }}</label>
                      <ng-select (change)="onChangeArea($event)" placeholder="{{ 'Area' | translate }}"
                        [multiple]="false" [items]="Areas" formControlName="AreaId" bindLabel="na" bindValue="dbId">
                      </ng-select>
                    </div>
                    <div class="form-group col-md-4">
                      <label>{{ 'PostCode' | translate }}</label>
                      <input type="text" class="form-control" formControlName="PostCode">
                    </div>

                    <div class="form-group col-md-4">
                      <label>{{ 'AddressAr' | translate }}</label>
                      <input type="text" class="form-control" formControlName="AddressAr">
                    </div>
                    <div class="form-group col-md-4">
                      <label>{{ 'AddressEn' | translate }}</label>
                      <input type="text" class="form-control" formControlName="AddressEn">
                    </div>
                    <div class="col-md-12">
                      <hr style="border-top: 5px solid rgb(147 136 136 / 72%);">
                    </div>

                  </div>
                </div>
                <div class="formCard">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-6">{{ 'Status' | translate }}
                        </label>
                        <div class="switch col-6 text-end">
                          <label>
                            <input type="checkbox" formControlName="Status" (change)="changeStatus($event)"
                              [checked]="Status.value== 1? true: false">
                            <span class="lever"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-6">{{ 'ShowInBookingScreen' | translate }}
                        </label>
                        <div class="switch col-6 text-end">
                          <label>
                            <input type="checkbox" formControlName="ShowInBookingScreen" checked="checked">
                            <span class="lever"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-6">{{ 'AllowOnlineBooking' | translate }} </label>
                        <div class="switch col-6 text-end">
                          <label>
                            <input type="checkbox" formControlName="ShowOnline" checked="checked">
                            <span class="lever"></span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="this.GForm.get('Id').value == 0" class="col-md-6">
                      <div class="form-group row">
                        <label class="col-6">{{ 'HasFingerprint' | translate }}
                        </label>
                        <div class="switch col-6 text-end">
                          <label>
                            <input type="checkbox" formControlName="HasFingerprint"
                              [attr.disabled]="this.GForm.controls['HasFingerprint'].value ? true : null">
                            <span class="lever"></span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 text-center">
                      <button [disabled]="btnLoading" class="btn btn-danger" type="button"
                        (click)="openModalUploadAttachements()"> <i class="fas fa-cloud-upload-alt"></i>
                        &nbsp;{{'UploadAttachements' |
                        translate }}</button>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>

          <!--   <div *ngIf="selectedTab == 2 || selectedTab == 3 || selectedTab == 5">
            <div class="modal-footer">
              <button class="btn btn-danger" data-dismiss="modal">{{'Close' | translate }}</button>
              <button class="btn btn-success" type="submit" [disabled]="!GForm.valid || IsSubmit">
                {{ 'Submit' | translate }} </button>
            </div>
          </div> -->
        </form>
      </div>
      <div class="col-md-12">
        <ng-template #loading>
          <div class="col-md-12 text-center py-4 my-4">
            <i class="fas fa-circle-notch fa-spin fa-3x"></i>
          </div>
        </ng-template>
      </div>
    </div>


  </div>
  <div class="modal-footer">

    <button (click)="CreateData()" type="button" class="btn btn-danger" [disabled]="btnLoading || !GForm.valid">
      <span style="font-weight: 900;" *ngIf="!btnLoading"> {{ 'Submit' | translate }}</span>
      <span style="font-weight: 900;" *ngIf="btnLoading"> <i class="fas fa-circle-notch fa-spin"></i> {{ 'Loading' |
        translate
        }}</span>
    </button>

    <button type="button" class="btn btn-danger" (click)="closeModal()">
      {{'Close' | translate}}</button>

  </div>
</div>
