
import { BaseClass } from "../other/base-class";

export class CostCenter extends BaseClass {

  Id: number;
  CostCenterNo: string;
  NameAr: string;
  NameEn: string;
  DescriptionAr: string;
  DescriptionEn: string;
  LevelNumber: number;
  ParentId?: number;
  AccountSetupId: number;
  IsChild: boolean;

  IsActive: boolean;

  CostCenters: CostCenter[];
  ParentNumber: string;
  Code: number;

}
