import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutoRegistrationConfig } from './models/auto-registration-config';

export const CONFIG = new InjectionToken<AutoRegistrationConfig>(
  'RegistrationConfig'
);

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class RegistrationModule {
  static withConfig(
    config: AutoRegistrationConfig
  ): ModuleWithProviders<RegistrationModule> {
    return {
      ngModule: RegistrationModule,
      providers: [{ provide: CONFIG, useValue: config }],
    };
  }
}
