import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from '../message/message.service';
import { catchError, map, tap } from 'rxjs/operators';
import { formValueDates } from '../../shared/helpers';
import { CompanyImagesServiceEditedName } from '../company/company-image-edited-name.service';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { UIStateService, UISTATE_KEYS } from '../other/ui-state.service';
import { HttpBaseService } from '../other/httpbase.service';
import { AccountSetup } from '../../models/classes/account-setup/account-setup';
import { MyUser } from '../../models/classes/my-user/my-user';
import { AccountSetupSearchCriteria } from '../../models/search-criterias/account-setup-search-criteria';
import { AccountSetupResult } from '../../models/search-result/accountp-setup-result';
import { ExecutionResponse } from '../../models/support/execution-response';
import { SharedService } from '../other/shared.service';
import { ZatcaBranches } from 'src/app/models/classes/account-setup/zatca-branches';

@Injectable({
  providedIn: 'root',
})
export class AccountSetupService extends BaseService {
  accountSetupUrl: string;
  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private _BaseService: BaseServiceSZ,
    private httpBaseService: HttpBaseService,
    private sharedService: SharedService,
    private _UIStateService: UIStateService,
    private companyImagesService: CompanyImagesServiceEditedName
  ) {
    super();
    this.accountSetupUrl =
      this.sharedService.ApiUrl /*this._BaseService.ApiUrl */ + 'AccountSetup';
  }

  getAccountSetup(
    accountSetupId?: number
  ): Observable<ExecutionResponse<AccountSetup>> {
    let myAccountSetupId = accountSetupId || super.getAccountSetupId();
    return this.http
      .post<ExecutionResponse<AccountSetup>>(
        this.accountSetupUrl + '/getById',
        myAccountSetupId,
        super.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.ValidationResult(res);
          if (super.getAccountSetupId() == res.Result.Id) {
            this._UIStateService._AppGlobals.Country =
              res.Result && res.Result.Country && res.Result.Country.Name
                ? res.Result.Country.Name
                : '';

            this._UIStateService._UIState[UISTATE_KEYS.COUNTRY_SET] =
              res.Result && res.Result.Country && res.Result.Country.Name
                ? res.Result.Country.Name
                : '';

            this._UIStateService._AppGlobals.AccountData = res.Result;

            this._UIStateService._AppGlobals.DefaultCurrency =
              res.Result.AccountCurrencies.filter(
                (c) => c.IsDefault == true
              )[0];
          }

          super.bufferAuditColumns(res.Result, res.Result.constructor.name);
        }),

        catchError(
          this._BaseService.handleError<ExecutionResponse<AccountSetup>>(
            'getAccountSetup id=${id}'
          )
        )
      );
  }












  getByIdAccountSetup(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('AccountSetup/getById', body);
  }
  getAccountSetups(
    criteria?: AccountSetupSearchCriteria
  ): Observable<ExecutionResponse<AccountSetup[]>> {
    return this.http
      .post<ExecutionResponse<AccountSetup[]>>(
        this.accountSetupUrl + '/Get',
        criteria,
        super.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<ExecutionResponse<AccountSetup[]>>(
            'getAccountSetup id=${id}'
          )
        )
      );
  }

  
  GetBranchesDataForZatca(criteria?: AccountSetupSearchCriteria): Observable<AccountSetupResult> {
      criteria.CompanyId = this.sharedService.CompanyId;
    
    return this.http
      .post<AccountSetupResult>(
        this.accountSetupUrl +'/GetBranchesDataForZatca', criteria, super.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<AccountSetupResult>(
            'getAccountSetup id=${id}'
          )
        )
      );
  }
  getForAutoCompleteNewDoNotDepenOnTableUserAccountSetup(
    criteria?: AccountSetupSearchCriteria
  ): Observable<AccountSetupResult> {
    //criteria.CompanyId = this.companyImagesService.getCompanyId();
    //criteria.GetWithOutStatusAndVisibleToGlamera = true;
    if (!criteria.CompanyId) {
      criteria.CompanyId = this.sharedService.CompanyId;
    }

    return this.http
      .post<AccountSetupResult>(
        this.accountSetupUrl +
          '/GetForAutoCompleteNewDoNotDepenOnTableUserAccountSetup',
        criteria,
        super.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<AccountSetupResult>(
            'getAccountSetup id=${id}'
          )
        )
      );
  }

  getForAutoComplete(
    criteria?: AccountSetupSearchCriteria
  ): Observable<AccountSetupResult> {
    criteria.CompanyId = this.companyImagesService.getCompanyId();

    if (!criteria.CompanyId) {
      criteria.CompanyId = this.sharedService.CompanyId;
    }

    if (criteria.UserName == null) {
      criteria.UserName = this.sharedService.UserName; //localStorage.getItem("username");
    }

    return this.http
      .post<AccountSetupResult>(
        this.accountSetupUrl + '/GetForAutoComplete',
        criteria,
        this.sharedService.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<AccountSetupResult>(
            'getAccountSetup id=${id}'
          )
        )
      );
  }

  /** PUT: update the category on the server */
  updateAccountSetup(
    accountSetup: AccountSetup
  ): Observable<ExecutionResponse<boolean>> {
    accountSetup = formValueDates(accountSetup, true);

    return this.http
      .post<ExecutionResponse<boolean>>(
        this.accountSetupUrl + '/Update',
        accountSetup,
        super.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('added category w/ id=${category.Id}');
          this._BaseService.ValidationResult(res);
          super.setBufferAuditColumns(accountSetup, AccountSetup.name);

          if (
            accountSetup.Id == super.getAccountSetupId() &&
            res.Result == true
          ) {
            this.getAccountSetup().subscribe((res) => {});
          }
        }),

        catchError(
          this._BaseService.handleError<ExecutionResponse<boolean>>(
            'addAccountSetup id=${id}'
          )
        )
      );
  }
  GenerateCertificate( GenerateCertificateModel: ZatcaBranches): Observable<ExecutionResponse<AccountSetup>> {

  
    return this.http.post<ExecutionResponse<AccountSetup>>(
      this.sharedService.ApiUrl + 'Account/GenerateCertificate',
        GenerateCertificateModel,
        super.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('added category w/ id=${category.Id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<ExecutionResponse<AccountSetup>>(
            'addAccountSetup id=${id}'
          )
        )
      );
  }


  createAccountSetup(
    accountSetup: AccountSetup
  ): Observable<ExecutionResponse<AccountSetup>> {
    let data: any = {};
    data.oldAccountSetupData = this._UIStateService.getAccountSetup();
    data.newAccountSetupData = accountSetup;
    data.userData = new MyUser();
    data.userData.Id = this.getUserId();
    accountSetup = formValueDates(accountSetup, true);
    return this.http
      .post<ExecutionResponse<AccountSetup>>(
        this.accountSetupUrl + '/Create',
        data,
        super.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('added category w/ id=${category.Id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<ExecutionResponse<AccountSetup>>(
            'addAccountSetup id=${id}'
          )
        )
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
}
