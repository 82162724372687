import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-auto-registration',
  templateUrl: './auto-registration.component.html',
  styleUrls: ['./auto-registration.component.scss'],
})
export class AutoRegistrationComponent implements OnInit {
  dir: string = localStorage.getItem('lang') === 'en' ? 'ltr' : 'rtl';

  constructor(public readonly translate: TranslateService) {}

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((response) => {
      this.dir = response?.lang === 'en' ? 'ltr' : 'rtl';
    });
  }
}
