<div id="printClientMembershipFrozenDaysLogDetails">
  <div class="modal-header">

    <h5 class="modal-title" id="exampleModalLabel"><i class="fas fa-lock"></i> &nbsp;
      {{ (GymClientMembershipFreezId > 0 ?
      'EditFreezIndividualMembership':'FreezIndividualMembership') | translate }} </h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="modal-body text-start col-lg-12"
        *ngIf="!GymClientMembershipFreezId || gymClientMembershipFrozenDaysLog">
        <div class="modal-body">
          <div class="row">


            <div class="form-group col-md-4">
              <label>{{ 'Clients' | translate }} &nbsp; <a style="color: #309dfd;"
                  title="{{ 'HereAppearsClientsThatHaveActiveGymMembershipAndAvailableForFreeze' | translate }}"><i
                    class="fas fa-info-circle"></i></a> </label>

              <ng-select [placeholder]="'SearchClients' | translate" [(ngModel)]="FreezClientId"
                [ngModelOptions]="{standalone: true}" [multiple]="false" [items]="ClientsForFreez" bindLabel="Name"
                bindValue="Id" [searchFn]="customSearchFn" (change)="getClientMemberships('');FreezClient();"
                (clear)="getClients('', 2)" (search)="getClients($event.term, 2)">

                <ng-template ng-option-tmp let-item="item">
                  <div class="list-client-insearch">
                    <div [ngClass]="{'first-char': !item.IsBanned, 'first-char-banned' : item.IsBanned }"
                      [title]="item.IsBanned ? (('BannedClient' | translate) + ' - ' + ('BanReason' | translate) + ':' + item.BanReason) : item.Name">
                      {{ item.Name | slice : 0 : 1 }}
                    </div>
                    <div [ngClass]="{'info-client': !item.IsBanned, 'info-client-banned' : item.IsBanned }"
                      [title]="item.IsBanned ? (('BannedClient' | translate) + ' - ' + ('BanReason' | translate) + ':' + item.BanReason) : item.Name">
                      {{ item.Name }} <br />
                      <span>{{ item.Mobile }}</span>
                    </div>
                  </div>
                </ng-template>

              </ng-select>
            </div>

            <div class="form-group col-md-8">
              <label>{{ 'ClientMembershipUseFrozenDays' | translate }}</label>
              <ng-select placeholder="{{ 'ClientMembershipUseFrozenDays' | translate }}" [multiple]="false"
                [items]="GymClientMemberships" (change)="ClientMembershipUseFrozenDaysChanged()"
                bindLabel="NameWithTotalFrozenDaysAndAvailableFrozenDays" bindValue="Id"
                [(ngModel)]="FreezGymClientMembershipId" [ngModelOptions]="{standalone: true}"
                (search)="getClientMemberships($event.term)">
              </ng-select>
            </div>


            <div class="form-group col-md-4">
              <label>{{ 'FrozenDaysStartDate' | translate }}</label>
              <input type="date" class="form-control" name="FrozenDaysStartDate" [min]="MinDate" [max]="MaxDate"
                [ngModel]="FrozenDaysStartDate | date:'yyyy-MM-dd'" (ngModelChange)="FrozenDaysStartDate = $event"
                (change)="FrozenDaysStartDateChanged($event)" required>

            </div>

            <div class="form-group col-md-4">
              <label>{{ 'FrozenDaysEndDate' | translate }}</label>
              <input type="date" class="form-control" name="FrozenDaysEndDate"
                [ngModel]="FrozenDaysEndDate | date:'yyyy-MM-dd'" (ngModelChange)="FrozenDaysEndDate = $event"
                (change)="FrozenDaysEndDateChanged($event)" required>

            </div>

            <div class="form-group col-md-4">
              <label>{{ 'WantedFrozenDaysCount' | translate }}</label>
              <input class="form-control" type="number" [placeholder]="'WantedFrozenDaysCount' | translate"
                (change)="FrozenDaysCountChanged($event)" [(ngModel)]="FrozenDaysCount"
                [ngModelOptions]="{standalone: true}">

            </div>

            <div class="form-group col-md-12 text-center">

            </div>

          </div>

        </div>


      </div>

      <div class="col-lg-12" *ngIf="GymClientMembershipFreezId && !gymClientMembershipFrozenDaysLog">
        <div class="col-md-12 text-center py-4 my-4">
          <i class="fas fa-circle-notch fa-spin fa-3x"></i>
          <h1>{{'Loading' | translate}} </h1>
        </div>
      </div>

    </div>
  </div>
  <div class="modal-footer">

    <button class="btn btn-success" (click)="Freez()" [disabled]="SendingData || !IsFormValid()">
      {{ SendingData ? ((GymClientMembershipFreezId > 0 ? 'EditOnFreez':'OnFreez') | translate ) :
      ((GymClientMembershipFreezId > 0 ?
      'EditFreez':'Freez') | translate ) }} </button>

    <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">
      {{'Close' | translate}}</button>
  </div>
</div>
