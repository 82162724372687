import { Injectable } from '@angular/core';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../message/message.service';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { ServiceBranchEmployeeDTO } from '../../models/classes/service/service-branch-employee-list-dto';
import { ServiceBranchEmployeeSearchCriteria } from '../../models/search-criterias/service-branch-employee-search-criteria';
import { ServiceBranchEmployeeSearchResult } from '../../models/search-result/service-branch-employee-search-result';
import { ExecutionResponse } from '../../models/support/execution-response';
import { SharedService } from '../other/shared.service';

@Injectable({
  providedIn: 'root',
})
export class ServiceBranchEmployeeService extends BaseService {
  private serviceBranchEmployeeUrl; //= super.BaseUrl() + 'Service';  // URL to web api

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private sharedService: SharedService,
    private _BaseService: BaseServiceSZ
  ) {
    super();
    this.serviceBranchEmployeeUrl =
      this.sharedService.ApiUrl + 'ServiceBranchEmployee';
  }

  GetServiceBranchEmployees(
    serviceBranchEmployeeSearchCriteria: ServiceBranchEmployeeSearchCriteria
  ): Observable<ServiceBranchEmployeeSearchResult> {
    serviceBranchEmployeeSearchCriteria.AccountSetupId =
      super.getAccountSetupId();
    return this.http
      .post<ServiceBranchEmployeeSearchResult>(
        this.serviceBranchEmployeeUrl + '/GetServiceBranchEmployees',
        serviceBranchEmployeeSearchCriteria,
        super.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('Fetched Service Branch Employees');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<ServiceBranchEmployeeSearchResult>(
            'Fetched Service Branch Employees id=${id}'
          )
        )
      );
  }

  CreateList(
    serviceBranchEmployeeListDTO: ServiceBranchEmployeeDTO
  ): Observable<ExecutionResponse<boolean>> {
    return this.http
      .post<ExecutionResponse<boolean>>(
        this.serviceBranchEmployeeUrl + '/CreateList',
        serviceBranchEmployeeListDTO,
        super.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('Create Service Branch Employees');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<ExecutionResponse<boolean>>(
            'Create Service Branch Employees'
          )
        )
      );
  }

  UpdateList(
    serviceBranchEmployeeListDTO: ServiceBranchEmployeeDTO
  ): Observable<ExecutionResponse<boolean>> {
    return this.http
      .post<ExecutionResponse<boolean>>(
        this.serviceBranchEmployeeUrl + '/UpdateList',
        serviceBranchEmployeeListDTO,
        super.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('Update Service Branch Employees');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<ExecutionResponse<boolean>>(
            'Update Service Branch Employees'
          )
        )
      );
  }

  DeleteByServiceBranch(
    ServiceBranchId: number
  ): Observable<ExecutionResponse<boolean>> {
    return this.http
      .post<ExecutionResponse<boolean>>(
        this.serviceBranchEmployeeUrl + '/DeleteByServiceBranch',
        ServiceBranchId,
        super.getHeaders()
      )
      .pipe(
        tap((_) => this._BaseService.log('Delete service id=${id}')),
        catchError(
          this._BaseService.handleError<ExecutionResponse<boolean>>('Delete')
        )
      );
  }
}
