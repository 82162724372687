<div class="modal-header">
  <h1 class="modal-title text-capitalize" id="exampleModalLabel">
    <i class="fas fa-gift faa-shake animated"></i>
    {{ModalTitle | translate }}
  </h1>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <div class="row">

    <div class="col-lg-12 text-center">
      <a (click)="goToWhatsappBusinessServices()">

        <img src="assets/img/eid-mubarak8.jpeg" style="height: 100%;width: 100%;"
          title="{{'HappyEidFitterTitle' | translate }}">

      </a>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">
      {{'Close' | translate}}</button>
    <!-- <button type="button" class="btn btn-danger" (click)="goToWhatsappBusinessServices()">
      <i class="fab fa-solid fa-whatsapp faa-shake animated"></i>&nbsp;
      {{'ContactUsOverWhatsapp' | translate}}</button> -->
  </div>
