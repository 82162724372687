<span *ngIf="control && (!control.valid || control.touched || control.dirty)">
  <ng-container *ngIf="control.errors?.['required']">
    {{ name | translate }} {{ "REQUIRED" | translate }} !
  </ng-container>

  <ng-container *ngIf="control.errors?.['minlength']">
    {{ name | translate }} {{ "REQUIRED_MIN_LENGTH" | translate }}
    {{ minLength }}
  </ng-container>

  <ng-container *ngIf="control.errors?.['maxlength']">
    {{ name | translate }} {{ "NOT_MORE_THAN" | translate }} {{ maxLength }}
  </ng-container>

  <ng-container *ngIf="control.errors?.['min']">
    {{ name | translate }} {{ "NOT_LESS_THAN" | translate }} {{ min }}
  </ng-container>

  <ng-container *ngIf="control.errors?.['max']">
    {{ name | translate }} {{ "NOT_MORE_THAN" | translate }} {{ max }}
  </ng-container>

  <ng-container *ngIf="control?.errors?.['email']">
    {{ "VALID_EMAIL" | translate }}
  </ng-container>
</span>
