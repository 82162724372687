

export enum BookingOrTransactionItemType {
    Service = 1,
    Package = 2,
    Product = 3,
    GiftCard = 4,
    Voucher = 5,
    GymMembership = 6
}

