import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { ApiResponse, ExecutionResponse, ExecutionResponseCamel } from '../../models/support/execution-response';
import { BaseService, BaseServiceSZ } from 'src/app/services/other/baseService';
import { MessageService } from 'src/app/services/message/message.service';
import { SharedService } from '../other/shared.service'; 
import { formValueDates } from 'src/app/shared/helpers';
import { CheckLicenseResponse } from 'src/app/dashboard/licenses/license.model';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class LicenseService extends BaseService {

  private licensesUrl  //= super.BaseUrl() + 'License';  // URL to web api

  constructor(
    private http: HttpClient, private messageService: MessageService, 
    private _BaseService: BaseServiceSZ
  ) {
    super();
    this.licensesUrl = environment.ApiUrl + 'erp/web/licenses';

  }



 
   

  CheckCurrentSubscription( branchId:number): Observable<ApiResponse<CheckLicenseResponse>> {
    return this.http.get<ApiResponse<CheckLicenseResponse>>(
      this.licensesUrl + '/check/'+branchId,{  headers: super.getHeaders().headers,}
    )
      .pipe(
        tap((res) => {
          this._BaseService.log('CheckCurrentSubscription');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<ApiResponse<CheckLicenseResponse>>(
            'CheckCurrentSubscription'
          )
        )
      );


   }

 



}
