import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { ClientBalanceStatus } from '../../models/enums/client-balance-status';
import { ResponseState } from '../../models/support/response-state';
import { SharedService } from '../../services/other/shared.service';
import { GymClientMembershipService } from '../../services/gym/gym-client-membership.service';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from 'src/app/services/client/client.service';
import { GymClientMembershipsSearchCriteria } from 'src/app/models/search-criterias/gym-client-memberships-searchcriteria';
import { GymClientMembership } from 'src/app/models/classes/gym/gym-client-membership';
import { WeekDays } from '../../models/enums/week-days';
import { AlertMethod } from '../../models/enums/alert-method';
import { AlertStatus } from '../../models/enums/alert-status';
import { GymActivityType } from '../../models/enums/gym-activity-type';
import { PeriodStatus } from 'src/app/models/enums/gym-membership-period-status';
import { CancelationWay } from 'src/app/models/enums/cancelation-way';
import { GymMembershipClientStatus } from 'src/app/models/enums/gym-membership-client-status';

@Component({
  selector: 'app-modal-gym-membership-info',
  templateUrl: './modal-gym-membership-info.component.html',
  styleUrls: ['./modal-gym-membership-info.component.scss'],
  providers: [DatePipe],
})
export class ModalGymMembershipInfo implements OnInit {
  @Input() GymClientMembershipId: number;
  public LoadingGetGymClientMembership: boolean;
  public gymMembership: GymClientMembership;

  constructor(
    public activeModal: NgbActiveModal,
    public translate: TranslateService,
    public clientService: ClientService,
    public sharedService: SharedService,
    public gymClientMembershipService: GymClientMembershipService,
    public toaster: ToastrService
  ) { }

  ngOnInit(): void {
    this.GetGymMembershipInfo();
  }

  public get clientBalanceStatus(): typeof ClientBalanceStatus {
    return ClientBalanceStatus;
  }
  public get WeekDays(): typeof WeekDays {
    return WeekDays;
  }
  public get alertMethod(): typeof AlertMethod {
    return AlertMethod;
  }
  public get alertStatus(): typeof AlertStatus {
    return AlertStatus;
  }
  public get periodStatus(): typeof PeriodStatus {
    return PeriodStatus;
  }
  public get gymMembershipClientStatus(): typeof GymMembershipClientStatus {
    return GymMembershipClientStatus;
  }

  public get cancelationWay(): typeof CancelationWay {
    return CancelationWay;
  }
  getGymActivityTypeString(gymActivityType: GymActivityType): string {
    return 'GymActivityType' + GymActivityType[gymActivityType];
  }

  divName;
  printPage() {
    this.divName = 'printGymMembershipInfo';
    console.log(this.divName);

    let printContents = document.getElementById(this.divName).innerHTML;
    let originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  }

  GetGymMembershipInfo() {

    this.LoadingGetGymClientMembership = true;

    /* var clientGymMembershipsSearchCriteria =
      new GymClientMembershipsSearchCriteria();
    clientGymMembershipsSearchCriteria.AccountSetupId = this.sharedService.AccountSetupId;
    clientGymMembershipsSearchCriteria.Id = this.GymClientMembershipId; */

    this.gymClientMembershipService
      .GetGymClientMembershipInfo(this.GymClientMembershipId)
      .subscribe((response) => {
        if (response.State == ResponseState.Success) {
          this.gymMembership = response.Result;
        }
        this.LoadingGetGymClientMembership = false;
      });
  }
}
