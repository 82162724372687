<div class="modal-header">
  <h4 class="modal-title">{{'EmployeesStatus' | translate}}</h4>
  <button type="button" class="close" (click)="activeModal.close('Close click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-12">
      <div class="filter-card">
        <div class="row">
          <div class="col-md-10">
            <div class="row">
              <div class="input-group col-md-3">
                <input type="text" class="form-control" placeholder="{{'Employee' | translate }}"
                  [(ngModel)]="freeText">
              </div>
              <!-- <div class="form-group col-md-3">
                <label>{{ 'Branches' | translate }} </label>
                <ng-select placeholder="{{ 'Branches' | translate }}" [items]="CompanyAccountSetups"
                  bindLabel="GlobalName" bindValue="Id" [(ngModel)]="branchId">
                </ng-select>
              </div> -->
              <div class="col-md-3">
                <div class="input-group">
                  <select class="form-control" aria-label="Default select example" [(ngModel)]="available">
                    <option value="" selected disabled>{{'Status' | translate }}</option>
                    <option *ngFor="let item of slotStatus" [value]="item.id">
                      {{ item.name | translate }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-3">
                <div class="input-group">
                  <input type="date" class="form-control" placeholder="{{'Date' | translate }}" [(ngModel)]="dateFrom"
                    aria-label="Example text with button addon" aria-describedby="button-addon1">
                </div>
              </div>

            </div>
          </div>
          <div class="col-md-2">
            <button class="btn btn-search" (click)="getEmployeeStatuses()"> {{'Search' | translate}} </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12 col-12">
      <div class="table-card">
        <table class="table">
          <thead class="table-head table-bordered">
            <tr>
              <th scope="col">{{'EmployeeName' | translate }}</th>
              <th scope="col">{{'Status' | translate }}</th>
              <th scope="col">{{'AvailableIn' | translate }}</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody *ngFor=" let item of EmployeeStatus; let i = index">
            <tr (click)="selectEmplyeeTimeSlots(item,i)" class="cursor">
              <td class="cursor">{{item.Name}}</td>
              <td class="cursor">
                <h6 *ngIf="item.IsAvailable == true" class="free cursor">{{ 'Free' | translate }}</h6>
                <h6 *ngIf="item.IsAvailable == false" class="busy cursor">{{ 'Busy' | translate }}</h6>
              </td>
              <td class="cursor">{{item.AvailableIn.substring(0, 5)}}</td>
              <td class="cursor">
                <div class="text-end cursor">
                  <button class="btn btn-book" (click)="selectEmplyeeTimeSlots(item,i)">
                    <i *ngIf="i!==ExpandRow" class="fas fa-angle-down"></i>
                    <i *ngIf="i==ExpandRow" class="fas fa-angle-up"></i></button>
                </div>
              </td>
            </tr>
            <ng-container *ngIf="i==ExpandRow">
              <tr>
                <td colspan="5">
                  <div class="">
                    {{item.Name}}
                    <div class="time-slots">
                      <button *ngFor=" let time of SingleHourSlots; let t = index" [disabled]="time.SlotStatus == 1"
                        (click)="goToAppointment(item,time)" class="btn"> {{time.Hour}} </button>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <div class="text-center py-4 my-4" *ngIf="!EmployeeStatus || EmployeeStatus?.length == 0">
        <h4 class="py-4">{{'NoDataFound' | translate}}</h4>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-modal-action" (click)="activeModal.close('Close click')">{{'Close' | translate
    }}</button>
</div>
