<div class="modal-header">
  <h5 class="modal-title text-capitalize" id="exampleModalLabel">
    <i class="fas fa-money-bill-alt"></i> &nbsp; {{ modalTitle | translate }} - {{ 'ClientName:' | translate }} {{
    ClientName }}
  </h5>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-lg-12" *ngIf="InvoicesPayableByTheClient ">
      <div class="text-center py-4">


        <div class="row" style="font-size: 15px;color: black;">

          <div class="col-lg-4">
            {{ 'InvoicesPayableByTheClient:' | translate }}
          </div>
          <div class="col-lg-8">
            <ng-select [placeholder]="InvoicesPayableByTheClientPlaceHolder" [multiple]="true" required
              [items]="InvoicesPayableByTheClient" bindLabel="TransactionCode" [hideSelected]="true"
              (change)="InvoicesPayableByTheClientChange($event)" (clear)="InvoicesPayableByTheClientChange($event)"
              [(ngModel)]="SelectedInvoicesPayableByTheClientIds" bindValue="TransactionId">
            </ng-select>
          </div>
        </div>

        <br>

        <div class="row" style="font-size: 15px;color: black;" *ngIf="SelectedInvoicesPayableByTheClient &&
          SelectedInvoicesPayableByTheClient.length > 0">
          <div class="col-lg-12">

            <div class="table-card">
              <table class="table table-stripedd table-sm table-bordered">
                <thead class="table-head">
                  <tr>
                    <th style="width: 3%;">{{ 'TransactionCode' | translate }}</th>
                    <th style="width: 3%;">{{ 'TransactionDate' | translate }}</th>
                    <th style="width: 3%;">{{ 'TransactionTime' | translate }}</th>
                    <th style="width: 6%;">{{ 'TotalRemainingAmount' | translate }}</th>
                    <th style="width: 6%;">{{ 'TotalPaidAmount' | translate }}</th>
                    <th style="width: 8%;">{{ 'TotalPayableAmount' | translate }}</th>
                    <th style="width: 10%;">{{ 'PaidAmount' | translate }}</th>

                  </tr>
                </thead>
                <tbody style="font-size: 15px;font-weight: bolder;color: black;">
                  <tr *ngFor="let item of SelectedInvoicesPayableByTheClient; let i = index">

                    <td>
                      {{ item.TransactionCode }}
                    </td>

                    <td>{{ item.TransactionDate | date: 'yyyy-MM-dd' }}</td>

                    <td>{{ item.TransactionDate | date: 'hh:mm a' }}</td>

                    <td>{{ item.TotalRemainingAmount}}</td>

                    <td>{{ item.TotalPaidAmount}}</td>

                    <td>{{ item.TotalPayableAmount}}</td>

                    <td>

                      <div class="input-group">
                        <input type="number" class="form-control text-center" min="0" [max]="item.TotalPayableAmount"
                          placeholder="{{'PaidAmount' | translate}}" (keyup)="PaidAmountKeyup($event)"
                          [(ngModel)]="item.PaidAmount" (keypress)="numberOnly($event)">
                        <div class="input-group-append">
                          <span class="input-group-text" id="basic-addon2">
                            {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr :
                            sharedService.CurrencyNameEn}}</span>
                        </div>
                      </div>
                    </td>

                  </tr>
                </tbody>
              </table>
              <div class="text-center py-4 my-4" *ngIf="SelectedInvoicesPayableByTheClient.length == 0">
                <h4 class="py-4">{{'NoDataFound' | translate}}</h4>
              </div>
            </div>

          </div>

        </div>

      </div>
    </div>



  </div>

</div>
<div class="modal-footer">

  <button type="button" class="btn btn-success" (click)="activeModal.close('Close click')">
    {{ 'save' | translate }}
  </button>

  <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">
    {{'Close' | translate}}
  </button>

</div>
