import { SearchCriteria } from "./search-criteria";
import { ResourceStatus } from '../enums/resource-status';
import { Gender } from '../enums/gender';

export class ClientSearchCriteria extends SearchCriteria {

  constructor() {
    super();
    this.Gender = Gender.NotDefined;
  }

  public Name: string;
  public ForAutoComplete: number;
  public Status: ResourceStatus;
  public AccountSetupId: number;
  public Mobile: string;
  public CashClient: boolean;
  public HasNoAccount: boolean;
  public HaveActiveGymMembershipAndAvailableForFreeze: boolean;
  public HaveActiveGymMembershipThatContainsActivities: boolean;
  public IsSubClient: boolean;

  public AddingDateFrom: string | undefined;
  public AddingDateTo: string | undefined;

  public LastOrderDateFrom: string | undefined;
  public LastOrderDateTo: string | undefined;

  public BirthDateFrom: string | undefined;
  public BirthDateTo: string | undefined;

  public CountryId: number | undefined;
  public CityId: number | undefined;

  public ClientOrdersTotalGreaterThanOrEqual: number | undefined;

  public AddingBranchesIds: number[] | undefined;
  public UsedServicesIds: number[] | undefined;
  public UsedCategoriesIds: number[] | undefined;

  public Gender: Gender;
  public NationalitiesIds: number[] | undefined;
  public ExcludedClientsIds: number[] | undefined;
  public AdditionalClientsIds: number[] | undefined;

}
