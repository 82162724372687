<div class="modal-header">
  <h5 class="modal-title text-capitalize" id="exampleModalLabel">
    {{RequestTitle | translate }}</h5>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-lg-12">
      <div class="text-center py-4">
        <h4> {{ RequestBody | translate}}</h4>
      </div>
    </div>
  </div>
  <div class="row">

    <div class="form-group col-lg-12">
      <label>{{ 'SupervisorNotes-Optional' | translate }}</label>
      <input type="text" class="form-control" placeholder="{{ 'SupervisorNotes-Optional' | translate }}"
        [(ngModel)]="SupervisorNotes" name="SupervisorNotes" autocomplete="off" maxlength="50">

    </div>
  </div>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-success"
    (click)="AcceptOrRejectRequest(RequestPermissionNotification,RequestPermissionNotificationStatus)">
    {{ ButtonText | translate}}</button>

  <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">
    {{'Close' | translate}}</button>
</div>
