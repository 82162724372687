<div class="modal-header" dir="rtl">
  <h5
    *ngIf="dir === 'rtl'"
    class="modal-title text-capitalize"
    id="exampleModalLabel"
    data-backdrop="static"
  >
    <i class="fas fa-file-contract"></i> الشروط والأحكام
  </h5>
  <h5
    *ngIf="dir === 'ltr'"
    class="modal-title text-capitalize"
    id="exampleModalLabel"
    data-backdrop="static"
  >
    <i class="fas fa-file-contract"></i>
    General provisions
  </h5>
</div>

<mat-dialog-content class="mat-typography" [dir]="dir">
  <div class="container" *ngIf="dir === 'rtl'">
    <div class="right-one">
      <div class="upper-heading">
        <h1 class="main-heading text-center">الأحكام العامة</h1>
        <p class="first-paragraph">
          يرجى قراءة هذه الشروط والأحكام بعناية قبل الوصول إلى أي منتج أو خدمات
          مقدمة من جلاميرا.
        </p>
        <h3 class="main-nav-head">نظرة عامة:</h3>
        <p class="second-paragraph">
          يوافق المستخدم بموجب هذا على جميع الشروط والأحكام الخاصة بالوصول إلى
          خدمات جلاميرا أو استخدامها أنت أو الكيان الذي تمثله في هذه الاتفاقية.
        </p>

        <h3 class="main-nav-head">أولا</h3>
        <p class="third-paragraph">
          تنطبق هذه الشروط على جميع مستخدمي الخدمات، ويشير جميع المستخدمين إلى
          أصحاب الصالونات و مديريها وموظفيها القائمين على العمل الإداري .
        </p>
        <h3 class="main-nav-head">ثانيا</h3>
        <p class="fourth-paragraph">
          لجلاميرا الحق في تغيير أو إجراء تعديلات على هذه الشروط في أي وقت.
          علمًا بأنه سيتم إرسال إشعار بالتغييرات عبر البريد الإلكتروني أو من
          خلال رسالة عند بدء استخدامك للخدمات في المرة التالية، فإذا إذا استمر
          استخدامك لخدماتنا بعد إجراء تغييرات على الشروط ، فسيكون وصولك
          واستخدامك لها وفقًا لأحدث اتفاقية.
        </p>
        <h3 class="main-nav-head">ثالثا</h3>
        <p class="fifth-paragraph">
          لجلاميرا أيضًا الحرية في تغيير أو إضافة أو إزالة أي ميزات أو موارد من
          الخدمات في أي وقت، لذا قد يُطلب منك تحديث أو تثبيت أحدث إصدار من
          التطبيق أو ترقية الجهاز للاستفادة من أي تغييرات أو إضافات في الخدمات.
        </p>

        <div class="signup">
          <h3 class="login">تسجيل حساب</h3>
          <ol>
            <li>
              تقدم جلاميرا نظام إدارة سحابي لتمكين جميع الأنشطة في مجال التجميل
              مثل صالونات التجميل، الحلاقة الرجالية،مراكز السبا والمساج، الصالات
              الرياضية وعيادات التجميل والأسنان من إدارة وتقييم وتحسين معاملاتهم
              التجارية وتلبية احتياجاتهم، لذا عليك إنشاء حساب خاص بك يمثلك ويمثل
              الكيان الذي تمثله، لتتمكن من الاستفادة بخدماتنا.
            </li>
            <li>
              أثناء التسجيل ، سيطلب منك عدة بيانات ،تشمل على سبيل المثال لا
              الحصر ، اسمك و رقم الهاتف و بيانات شخصية أخرى، يجب عليك تحري الدقة
              عند الإجابة على أسئلتنا ،كما يجب عليك تحديث هذه المعلومات
              باستمرار.
            </li>

            <li>
              أنت مسؤول مسؤولية كاملة عن جميع الأنشطة المتعلقة بحساب جلاميرا
              الخاص بك ، بما في ذلك أي إجراءات يتخذها الأشخاص الذين منحتهم حق
              الوصول إلى حساب جلاميرا المذكور.
            </li>

            <li>
              تحتفظ جلاميرا بالحق في تغيير نوع الحساب أو تعليق أو إنهاء حساب
              جلاميرا لأي شخص يقدم معلومات غير دقيقة أو غير صحيحة أو غير كاملة ،
              أو الذي يفشل في الامتثال لمتطلبات تسجيل الحساب.
            </li>

            <li>
              تقدم جلاميرا خدماتها للمستخدمين المقيمين في المملكة العربية
              السعودية ومصر فقط حتى الآن.
            </li>
          </ol>
        </div>
        <div class="free-trail">
          <h3 class="login">تجربه مجانية</h3>
          <ul>
            <li>
              للمستخدمين الذين يرغبون في تجربة خدمات جلاميرا ،يمكنهم الاستفادة
              من تجربة مجانية لمدة 15 يومًا، حسب باقة الخدمات التي قام باختيارها
              مسبقًا.
            </li>
            <li>
              إذا اختار المستخدم الاستمرار في استخدام الخدمات بعد انتهاء الفترة
              التجريبية ، فيجب على المستخدم تقديم معلومات صالحة لطريقة الدفع
              المختارة ، لدفع أي رسوم يمكن تطبيقها على الاشتراك.
            </li>
          </ul>
        </div>
        <div class="cancel-subscription">
          <h4 class="login">إلغاء الحساب وحماية البيانات</h4>
          <h5>
            يحتفظ جلاميرا بالحق في إلغاء أو إنهاء أي حساب في حال انتهاك للشروط
            والأحكام وكذلك سياسات الخصوصية لهذه الاتفاقية بما في ذلك:
          </h5>
          <ul>
            <li>
              السماح لأي طرف ثالث بالوصول إلى حسابك أو استخدامه دون إشعار مسبق.
            </li>
            <li>
              ارتكاب الاحتيال وتزوير المعلومات فيما يتعلق باستخدامك لموقعنا أو
              فيما يتعلق بحسابك على موقعنا.
            </li>
          </ul>
        </div>
        <div class="cancel-subscription">
          <h3 class="login">
            إذا قمنا نحن أو أنت بإنهاء حسابك ، فيجوز لنا ، على سبيل المثال لا
            الحصر:
          </h3>

          <ul>
            <li>
              إلغاء أي ائتمانات حساب ترويجية معلقة أو حالية أو مستقبلية وأي
              قسائم غير مستردة في حسابك.
            </li>
            <li>
              عدم السماح لك أو منعك من الانضمام إلى حساب جديد ما لم ندعوك رسميًا
              للقيام بذلك.
            </li>
            <li>
              نحن نحتفظ بالحق في تحميلك المسؤولية عن أي وجميع الأضرار الناجمة عن
              سلوكك ، لمتابعة الإجراءات القانونية من خلال سلطات إنفاذ القانون
              المحلية والوطنية ذات الصلة .
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="container" *ngIf="dir === 'ltr'">
    <div class="right-one">
      <div class="upper-heading">
        <h1 class="main-heading text-center">General provisions</h1>
        <p class="first-paragraph">
          Please read these Terms and Conditions carefully before accessing any
          product or services provided by Glamera.
        </p>
        <h3 class="main-nav-head">Overview:</h3>
        <p class="second-paragraph">
          By this, you as a user agree to all terms and conditions for accessing
          or using the Glamera Services and the entity you represent in this
          Agreement.
        </p>

        <h3 class="main-nav-head">First</h3>
        <p class="third-paragraph">
          These Terms apply to all users of the Services, which refers to salon
          owners, managers and administrative staff.
        </p>
        <h3 class="main-nav-head">Second</h3>
        <p class="fourth-paragraph">
          Glamera reserves the right to change or make modifications to these
          Terms at any time. You will be notified of the changes by email or
          message when you next start using the services, so your access to and
          use of the Services in accordance with the most recent terms
          constitutes your acceptance thereof.
        </p>
        <h3 class="main-nav-head">Third</h3>
        <p class="fifth-paragraph">
          Glamera is also free to change, add or remove any features or
          resources from the Services at any time, so you may be required to
          update or install the latest version of the App or upgrade your device
          to take advantage of any changes or additions.
        </p>

        <div class="signup">
          <h3 class="login">Account Registration :</h3>
          <ol>
            <li>
              Glamera offers a cloud management system to enable all activities
              in the beauty industry such as beauty salons, men's barbershops,
              spa and massage centers, gyms, and beauty and dental clinics to
              manage, evaluate and improve their business transactions, so you
              must create your own account to represents you and the entity you
              represent, to be able to Take advantage of our services.
            </li>
            <li>
              During registration, you will be asked for several data, including
              but not limited to your name, phone number, and other personal
              data. You must be careful when answering our questions, and you
              must update this information constantly.
            </li>

            <li>
              You are fully responsible for all activities in connection with
              your Glamera account, including any actions taken by persons to
              whom you have given access to your Glamera account.
            </li>

            <li>
              Glamera reserves the right to change the account type or suspend
              or terminate a Glamera account for anyone who provides inaccurate,
              incorrect or incomplete information, or who fails to comply with
              the account registration requirements.
            </li>

            <li>
              Glamera provides its services to users residing in the Kingdom of
              Saudi Arabia and Egypt, and its services are available for all
              countries in accordance with future conditions
            </li>
          </ol>
        </div>
        <div class="free-trail">
          <h3 class="login">Free trial</h3>
          <ul>
            <li>
              For users who want to try Glamera services, they can take
              advantage of a 15-day free trial, depending on the package of
              services that he previously selected.
            </li>
            <li>
              If the User elects to continue using the Services after the trial
              period has expired, the User must provide valid information for
              the chosen payment method, to pay any fees that may be applicable
              to the Subscription.
            </li>
          </ul>
        </div>
        <div class="cancel-subscription">
          <h4 class="login">Account cancellation and data protection</h4>
          <h5>
            Glamera reserves the right to cancel or terminate any account in
            case of violation of the terms and conditions as well as the privacy
            policies of this Agreement including:
          </h5>
          <ul>
            <li>
              Allow any third party to access or use your account without prior
              notice.
            </li>
            <li>
              commit fraud and falsify information in connection with your use
              of our Website or in connection with your account on our Website.
            </li>
            <li></li>
          </ul>
        </div>
        <div class="cancel-subscription">
          <h3 class="login">
            If we or you terminate your account, we may, for example:
          </h3>

          <ul>
            <li>
              Cancel any pending, current or future promotional account credits
              and any unredeemed coupons in your account.
            </li>
            <li>
              Not to allow or prevent you from joining a new account unless we
              formally invite you to do so.
            </li>
            <li>
              We reserve the right to hold you liable for any and all damages
              arising from your conduct, to pursue legal action through the
              relevant local and national law enforcement authorities.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<div class="modal-footer">
  <mat-dialog-actions align="end">
    <button type="button" class="btn btn-danger" [mat-dialog-close]="true">
      {{ "Close" | translate }}
    </button>
    <!-- <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> -->
  </mat-dialog-actions>
</div>
