import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SupportChatService {
  private renderer: Renderer2;
  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private _document: Document,
  ) { 
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  chatScript() {
    let script = this.renderer.createElement('script');
    script.type = "text/javascript";
    script.text = `
      var $zoho=$zoho || {};
      $zoho.salesiq = $zoho.salesiq || {widgetcode: "siq747615281c1cf6673a3016b8a771d909dee8895c5e30ebeec919625d47b09ae5", values:{},ready:function(){}};
      var d=document;
      var s=d.createElement("script");
      s.type="text/javascript";
      s.id="zsiqscript";
      s.defer=true;
      s.src="https://salesiq.zohopublic.com/widget";
      var t=d.getElementsByTagName("script")[0];
      t.parentNode.insertBefore(s,t);
  `;

    this.renderer.appendChild(this._document.body, script);
}
}
