import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SalesChatService {
  private renderer: Renderer2;
  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private _document: Document,
  ) { 
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  chatScript() {
    let script = this.renderer.createElement('script');
    script.type = "text/javascript";
    script.text = `
      var $zoho=$zoho || {};
      $zoho.salesiq = $zoho.salesiq || {widgetcode: "siq9e9804977dd9626f493efca6d9fce65abb41ef30d02011c773f9bac137edd5aa", values:{},ready:function(){}};
      var d=document;
      var s=d.createElement("script");
      s.type="text/javascript";
      s.id="zsiqscript";
      s.defer=true;
      s.src="https://salesiq.zohopublic.com/widget";
      var t=d.getElementsByTagName("script")[0];
      t.parentNode.insertBefore(s,t);
  `;

    this.renderer.appendChild(this._document.body, script);
    this.clarityScript();
}

clarityScript(){
  let script = this.renderer.createElement('script');
  script.type = "text/javascript";
  script.text = `
  function(c,l,a,r,i,t,y){
    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", "lre5kz4lid");
`;

  this.renderer.appendChild(this._document.head, script);
}


}
