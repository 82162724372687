import { Injectable } from '@angular/core';
import { MessageService } from '../message/message.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { ClientFactorsLookupSearchCriteria } from '../../models/search-criterias/client-factors-lookup-search-criteria';
import { ClientFactorsLookupSearchResult } from '../../models/search-result/client-factors-lookup-search-result';

@Injectable({
  providedIn: 'root'
})
export class ClientFactorService extends BaseService {

  private ClientFactorsUrl // = super.BaseUrl() + 'Employee';  // URL to web api

  constructor(
    private http: HttpClient, private messageService: MessageService,
    private  _BaseService:BaseServiceSZ
  ) {
    super();
    this.ClientFactorsUrl =    this._BaseService.ApiUrl + 'ClientFactorsLookup';

  }


  searchClientFactors(clientFactorsLookupSearchCriteria: ClientFactorsLookupSearchCriteria): Observable<ClientFactorsLookupSearchResult> {
    clientFactorsLookupSearchCriteria.AccountSetupId = super.getAccountSetupId();
    return this.http.post<ClientFactorsLookupSearchResult>(this.ClientFactorsUrl + '/Get', clientFactorsLookupSearchCriteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched clientFactors');
          this._BaseService.ValidationResult(res);
      }),
        
        
      );

  }
}
