
<div class="p-5 cont" dir="rtl">
    <h5>كيف يعمل :-</h5>

    <ol>
          
       <li><p>جمع البيانات المناسب: قم بجمع البيانات اللازمة فقط لوظيفة التطبيق وأبلغ المستخدمين بوضوح عن البيانات التي تجمعونها والسبب وكيفية استخدامها.</p></li>
       <li><p>موافقة المستخدم: الحصول على موافقة المستخدم قبل جمع أي بيانات، والتأكد من أن الموافقة مفهومة وسهلة الفهم.</p></li>
           <li><p>التخزين الآمن: التأكد من أن أي بيانات مستخدم جمعت بشكل آمن ومحمية من الوصول غير المصرح به.</p></li>
               <li><p>مشاركة البيانات: إذا قمت بمشاركة بيانات المستخدم مع أي جهات خارجية، يجب أن يتم الشفافية حول مع من تتم مشاركة البيانات والسبب والحصول على موافقة المستخدم.</p></li>
                   <li><p>سياسة الخصوصية: توفير سياسة خصوصية واضحة وموجزة توضح كيفية جمع البيانات المستخدم واستخدامها ومشاركتها، بالإضافة إلى أي متطلبات قانونية ذات صلة.</p></li>
    </ol>
   
   </div>
   
   
   
   