import { ClientSearchCriteria } from "../../search-criterias/client-search-criteria";

export class Group {
  constructor() {
    this.Id = 0;
  }

  public Id: number;
  public CompanyId: number;
  public Name: string;
  public Notes: string;
  public NumberOfClients: number;
  public PercentageOfClientsFromAllClients: number;

  public FilterClientCriteria: ClientSearchCriteria;

}
