import { Component, Input } from '@angular/core';
import { SharedService } from 'src/app/services/other/shared.service';

@Component({
  selector: 'app-print-booking-per-employee',
  templateUrl: './print-booking-per-employee.component.html',
  styleUrls: ['./print-booking-per-employee.component.scss']
})
export class PrintBookingPerEmployeeComponent {
  @Input() item;
  @Input() public dirc;
  lang: string;

  constructor(protected sharedService: SharedService) {
    this.lang = this.sharedService.Lang;
    this.dirc = this.sharedService.Lang == 'ar' ? 'rtl' : 'ltr';
  }
}
