import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { InvoicePayableByTheClient } from '../../models/classes/balances/invoice-payable-by-the-client';
import { SetTotalPaidAmountForPostpaidBillsWithCashSaleModel } from '../../models/classes/balances/set-total-paid-amount-for-post-paid-bills-with-cash-sale-model';
import { TransactionPayPostpaidBill } from '../../models/classes/balances/transaction-pay-post-paid-bill';
import { AppointmentService } from '../../services/appointment/appointment.service';
import { ClientBalanceService } from '../../services/balances/client-balance.service';
import { SharedService } from '../../services/other/shared.service';
import { TransactionsSecondService } from '../../services/transaction/transactions-second.service';
import { PayForPostpaidBillsWithCashSaleModel } from '../../models/classes/balances/payfor-post-paid-bills-with-cash-sale-model';



@Component({
  selector: 'app-modal-pay-for-postpaid-bills-with-cash-sale',
  templateUrl: './modal-pay-for-postpaid-bills-with-cash-sale.component.html',
  styleUrls: ['./modal-pay-for-postpaid-bills-with-cash-sale.component.scss'],
  providers: [DatePipe]

})
export class ModalPayForPostpaidBillsWithCashSaleComponent implements OnInit {

  @Input() InvoicesPayableByTheClient: InvoicePayableByTheClient[];
  @Input() PayForPostpaidBillsWithCashSaleModel: PayForPostpaidBillsWithCashSaleModel;
  @Input() ClientName: string;

  @Output() SetTotalPaidAmountForPostpaidBillsWithCashSale =
    new EventEmitter<SetTotalPaidAmountForPostpaidBillsWithCashSaleModel>();

  public modalTitle: string = "";
  public InvoicesPayableByTheClientPlaceHolder = "";


  public SelectedInvoicesPayableByTheClient: InvoicePayableByTheClient[] = [];
  public SelectedInvoicesPayableByTheClientIds: number[];


  public TransactionPayPostpaidBills: TransactionPayPostpaidBill[];
  public TotalPaidAmountForPostpaidBillsWithCashSale: number = 0;



  constructor(
    public appointmentService: AppointmentService,
    public activeModal: NgbActiveModal,
    public translateService: TranslateService,
    public sharedService: SharedService,
    public _transactionsSecondService: TransactionsSecondService,
    private fb: UntypedFormBuilder,
    public datepipe: DatePipe,
    public clientBalanceService: ClientBalanceService) { }

  ngOnInit(): void {


    this.modalTitle = "InvoicesPayableByTheClient";
    this.InvoicesPayableByTheClientPlaceHolder =
      this.translateService.instant('InvoicesPayableByTheClient');;

    if (this.PayForPostpaidBillsWithCashSaleModel) {
      this.SelectedInvoicesPayableByTheClient =
        this.PayForPostpaidBillsWithCashSaleModel
          .SelectedInvoicesPayableByTheClient;
      this.SelectedInvoicesPayableByTheClientIds =
        this.PayForPostpaidBillsWithCashSaleModel
          .SelectedInvoicesPayableByTheClientIds;
    }

  }
  numberOnly(event): boolean {

    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;

  }


  InvoicesPayableByTheClientChange(event) {


    if (!this.SelectedInvoicesPayableByTheClientIds ||
      this.SelectedInvoicesPayableByTheClientIds.length <= 0) {
      this.SelectedInvoicesPayableByTheClient = [];
    }

    // Add
    if (this.SelectedInvoicesPayableByTheClient.length <
      this.SelectedInvoicesPayableByTheClientIds.length) {

      var AddedId =
        this.
          SelectedInvoicesPayableByTheClientIds.find(s => !this.
            SelectedInvoicesPayableByTheClient.find(x => x.TransactionId == s));

      const getInvoice: InvoicePayableByTheClient
        = Object.assign({}, this.InvoicesPayableByTheClient
          .find(item => item.TransactionId == AddedId));

      this.SelectedInvoicesPayableByTheClient
        .push(getInvoice);
    }

    //Remove
    else if (this.SelectedInvoicesPayableByTheClient.length >
      this.SelectedInvoicesPayableByTheClientIds.length) {
      var RemovedId =
        this.SelectedInvoicesPayableByTheClient.find(s => !this.SelectedInvoicesPayableByTheClientIds.find(x => x == s.TransactionId)).TransactionId;
      this.SelectedInvoicesPayableByTheClient = this.SelectedInvoicesPayableByTheClient.filter(s => s.TransactionId != RemovedId)
    }

    this.PaidAmountKeyup(null);

  }


  public PaidAmountKeyup(event) {



    var ed = this.InvoicesPayableByTheClient;

    if (this.SelectedInvoicesPayableByTheClient &&
      this.SelectedInvoicesPayableByTheClient.length > 0) {

      this.SelectedInvoicesPayableByTheClient.forEach(element => {

        if (element.PaidAmount > element.TotalPayableAmount) {
          element.PaidAmount = element.TotalPayableAmount;
        }
      });

      this.TotalPaidAmountForPostpaidBillsWithCashSale =
        this.SelectedInvoicesPayableByTheClient.map(o => o.PaidAmount)
          .reduce((a, c) => { return a + c });

    } else {

      this.TotalPaidAmountForPostpaidBillsWithCashSale = null;

    }

    var model = new SetTotalPaidAmountForPostpaidBillsWithCashSaleModel();
    model.TotalPaidAmountForPostpaidBillsWithCashSale = this.TotalPaidAmountForPostpaidBillsWithCashSale;
    model.TransactionPayPostpaidBills =
      this.SelectedInvoicesPayableByTheClient
        .map(o => ({
          PayForTransactionId: o.TransactionId,
          PaidAmount: o.PaidAmount,
          PayForTransaction: null,
          TransactionId: null,
          Transaction: null
        }));

    model.SelectedInvoicesPayableByTheClient = this.SelectedInvoicesPayableByTheClient;
    model.SelectedInvoicesPayableByTheClientIds = this.SelectedInvoicesPayableByTheClientIds;
    this.SetTotalPaidAmountForPostpaidBillsWithCashSale.emit(model);
  }

}
