<div class="row" [formGroup]="TPForm">
  <div class="col-md-12" formGroupName="Transaction">
    <!-- <div class="table-responsive text-nowrap"> -->
    <div>
      <table class="table table-sm table-bordered table-striped" formArrayName="TransactionsPayments">
        <thead class="MainColor text-white py-3">
          <tr>
            <th>{{ 'ThePayment' | translate }}</th>
            <th>{{ 'Value' | translate }}</th>
            <th>{{'Delete' | translate}}</th>
          </tr>
        </thead>
        <tr *ngFor="let item of TPForm.get('Transaction').get('TransactionsPayments')['controls']; let i=index"
          [formGroupName]="i">
          <td>
            <div class="form-group formgrouptbl">
              <ng-select [multiple]="false" [hideSelected]="true" [items]="PaymentMethods" bindLabel="GlobalName"
                bindValue="Id" (change)="ChoosePayment($event, i)" formControlName="PaymentMethodId"
                name="PaymentMethodId">
              </ng-select>
            </div>
            <div *ngIf="item.value.PaymentMethodType == 3">
              <div class="form-group ">
                <!-- <select class="form-control" formControlName="CreditCardId" name="CreditCardId">
                  <option value="null" selected disabled> {{'VisaType'|translate}} </option>
                  <option *ngFor="let p of CreditCards; let i = index" [value]="p.Id">
                    {{p.NameAr}}</option>
                </select> -->
                <input class="form-control mt-2" placeholder="{{'VisaNumber' | translate}}"
                  formControlName="ReferenceCode" name="ReferenceCode">
              </div>
            </div>
          </td>
          <td>
            <div class="row">
              <div class="col-md-12" *ngFor="let c of AccountCurrencies | slice:0:1">
                <div class="input-group mb-3">
                  <input type="number" class="form-control" placeholder="{{'Value' | translate}}"
                    formControlName="Amount" name="Amount" required (change)="CalcTypePay($event, i)" min="0" step="any"
                    [readOnly]="item.value.PaymentMethodId == 0 ||  i == 0">
                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2">{{sharedService.Lang== 'ar' ?
                      c.Currency.NameCultureVariant : c.Currency.Name}}</span>
                  </div>
                </div>
              </div>
              <!-- (keypress)="numberOnly($event)" -->
              <!-- <input type="number" class="form-control" formControlName="CurrencyId" name="CurrencyId"> -->

              <!-- <input type="number" class="form-control" value="{{c.Id}}" formControlName="CurrencyId"
            name="CurrencyId"> -->
            </div>

          </td>
          <td>
            <button type="button" class="btn btn-danger btn-sm px-3 my-0" [disabled]="i == 0" (click)="removeItem(i)">
              <i class="far fa-trash-alt"></i>
            </button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
