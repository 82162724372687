import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap, delay } from 'rxjs/operators';
import { MessageService } from '../message/message.service';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { SharedService } from '../other/shared.service';
import { HttpClient } from '@angular/common/http';
import { RegistrationCycleSaveResult as RegistrationCycleSaveResult } from '../../models/search-result/registration-cycle/registration-cycle-save-result';
import { RegistrationCyclePhaseOneModel } from '../../models/classes/registration-cycle/registration-cycle-phase-one-model';
import { GetCountriesCriteria } from '../../models/search-criterias/registration-cycle/get-countries-criteria';
import { SendVerificationCodeModel } from '../../models/classes/registration-cycle/send-verification-code-model';
import { GetCurrentCountryInfoSearchResult } from '../../models/search-result/registration-cycle/get-current-country-info-search-result';
import { VerifyMobileNumberModel } from '../../models/classes/registration-cycle/verify-mobile-number-model';
import { ChangeMobileNumberModel } from '../../models/classes/registration-cycle/change-mobile-number-model';
import { ChangeMobileNumberSearchResult } from '../../models/search-result/registration-cycle/change-mobile-number-search-result';
import { ExecutionResponse } from '../../models/support/execution-response';
import { CountryDTO } from '../../models/classes/registration-cycle/country-DTO';
import { NationalityDTO } from '../../models/classes/registration-cycle/nationality-dto';
import { GetNationalitiesCriteria } from '../../models/search-criterias/registration-cycle/get-nationalities-criteria';



@Injectable({
  providedIn: 'root',
})
export class RegistrationCyclePhaseOneService extends BaseService {
  private registrationCyclePhaseOneUrl;

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private _BaseService: BaseServiceSZ,
    private sharedService: SharedService
  ) {
    super();
    this.registrationCyclePhaseOneUrl =
      this.sharedService.ApiUrl + 'RegistrationCyclePhaseOne';
  }
  getApiUrl() {
    return this._BaseService.ApiUrl;
  }

  SavePhaseOneData(
    model: RegistrationCyclePhaseOneModel
  ): Observable<ExecutionResponse<RegistrationCycleSaveResult>> {
    return this.http
      .post<ExecutionResponse<RegistrationCycleSaveResult>>(
        this.registrationCyclePhaseOneUrl + '/SavePhaseOneData',
        model,
        this.sharedService.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('Save Phase One Data');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<
            ExecutionResponse<RegistrationCycleSaveResult>
          >('Save Phase One Data')
        )
      );
  }

  SendVerificationCode(
    model: SendVerificationCodeModel
  ): Observable<ExecutionResponse<boolean>> {
    return this.http
      .post<ExecutionResponse<boolean>>(
        this.registrationCyclePhaseOneUrl + '/SendVerificationCode',
        model,
        this.sharedService.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('SendVerificationCode');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<ExecutionResponse<boolean>>(
            'SendVerificationCode'
          )
        )
      );
  }

  GetCountries(
    criteria: GetCountriesCriteria
  ): Observable<ExecutionResponse<CountryDTO[]>> {
    return this.http
      .get<ExecutionResponse<CountryDTO[]>>(
        this.registrationCyclePhaseOneUrl + '/GetCountries',
        {
          headers: this.sharedService.getHeaders().headers,
          params: this.sharedService.ToHttpParams(criteria),
        }
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('GetCountries');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<ExecutionResponse<CountryDTO[]>>(
            'GetCountries'
          )
        )
      );
  }

  GetNationalities(
    criteria: GetNationalitiesCriteria
  ): Observable<ExecutionResponse<NationalityDTO[]>> {
    return this.http
      .get<ExecutionResponse<NationalityDTO[]>>(
        this.registrationCyclePhaseOneUrl + '/GetNationalities',
        {
          headers: this.sharedService.getHeaders().headers,
          params: this.sharedService.ToHttpParams(criteria),
        }
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('GetNationalities');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<ExecutionResponse<NationalityDTO[]>>(
            'GetNationalities'
          )
        )
      );
  }

  GetCurrentCountryInfo(): Observable<GetCurrentCountryInfoSearchResult> {
    return this.http
      .get<GetCurrentCountryInfoSearchResult>(
        this.registrationCyclePhaseOneUrl + '/GetCurrentCountryInfo',
        {
          headers: this.sharedService.getHeaders().headers,
          params: this.sharedService.ToHttpParams(null),
        }
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('GetCurrentCountryInfo');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<GetCurrentCountryInfoSearchResult>(
            'GetCurrentCountryInfo'
          )
        )
      );
  }

  VerifyMobileNumber(
    model: VerifyMobileNumberModel
  ): Observable<ExecutionResponse<boolean>> {
    return this.http
      .post<ExecutionResponse<boolean>>(
        this.registrationCyclePhaseOneUrl + '/VerifyMobileNumber',
        model,
        this.sharedService.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('VerifyMobileNumber');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<ExecutionResponse<boolean>>(
            'VerifyMobileNumber'
          )
        )
      );
  }

  ChangeMobileNumber(
    model: ChangeMobileNumberModel
  ): Observable<ChangeMobileNumberSearchResult> {
    return this.http
      .post<ChangeMobileNumberSearchResult>(
        this.registrationCyclePhaseOneUrl + '/ChangeMobileNumber',
        model,
        this.sharedService.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('ChangeMobileNumber');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<ChangeMobileNumberSearchResult>(
            'ChangeMobileNumber'
          )
        )
      );
  }
}
