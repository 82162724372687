import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { ResponseState } from '../../models/support/response-state';
import { AppointmentService } from '../../services/appointment/appointment.service';
import { ClientBalanceService } from '../../services/balances/client-balance.service';
import { ClientService } from '../../services/client/client.service';
import { SharedService } from '../../services/other/shared.service';
import { TransactionsSecondService } from '../../services/transaction/transactions-second.service';
import { ServiceConsumablesService } from 'src/app/services/Service/service-consumables.service';
import { ServiceConsumablesSearchCriteria } from '../../models/search-criterias/service-consumables-search-criteria';
import { ServiceConsumables } from '../../models/classes/service/service-consumables';

@Component({
  selector: 'app-modal-service-consumables-list',
  templateUrl: './modal-service-consumables-list.component.html',
  styleUrls: ['./modal-service-consumables-list.component.scss'],
  providers: [DatePipe],
})
export class ModalServiceConsumablesListComponent implements OnInit {
  @Input() ServiceId: number;

  @Output() CloseModal = new EventEmitter<boolean>();

  public ServiceConsumablesList: ServiceConsumables[];
  public modalTitle: string = '';
  public modalTitleForServiceName: string = '';
  public serviceConsumablesWithdrawStock: string = '';
  public ConsumableQuantitiesForExpirationDatesIdList: number[] = [];
  public HaveConsumableQuantityForExpirationDates: boolean = false;

  constructor(
    public appointmentService: AppointmentService,
    public activeModal: NgbActiveModal,
    public clientSearvice: ClientService,
    public translateService: TranslateService,
    public toaster: ToastrService,
    public serviceConsumablesService: ServiceConsumablesService,
    public sharedService: SharedService,
    public _transactionsSecondService: TransactionsSecondService,
    private fb: UntypedFormBuilder,
    public datepipe: DatePipe,

    public clientBalanceService: ClientBalanceService
  ) {}

  ngOnInit(): void {
    this.modalTitle = 'ServiceConsumablesList';
    this.GetServiceConsumables();
  }

  public ExpandRowLevelTwo: number;
  ExpandLevelTwo(i) {
    {
      this.ExpandRowLevelTwo == undefined || this.ExpandRowLevelTwo != i
        ? (this.ExpandRowLevelTwo = i)
        : (this.ExpandRowLevelTwo = undefined);
    }
  }

  GetServiceConsumables() {
    if (this.ServiceId && this.ServiceId > 0) {
      var criteria: ServiceConsumablesSearchCriteria =
        new ServiceConsumablesSearchCriteria();
      criteria.ServiceId = this.ServiceId;
      criteria.ForCashSale = true;

      this.serviceConsumablesService
        .GetServiceConsumables(criteria)
        .subscribe((response) => {
          if (response.State == ResponseState.Success) {
            if (
              response.TotalCount == 0 ||
              !response.ServiceConsumabless ||
              response.ServiceConsumabless.length <= 0
            ) {
              var message = this.translateService.instant(
                'NoServiceConsumablesFoundForSelectedService'
              );
              this.toaster.warning(message);
            } else {
              this.ServiceConsumablesList = response.ServiceConsumabless;

              this.HaveConsumableQuantityForExpirationDates =
                this.ServiceConsumablesList.find(
                  (s) =>
                    s.ConsumableQuantityForExpirationDates &&
                    s.ConsumableQuantityForExpirationDates.length > 0
                ) != null &&
                this.ServiceConsumablesList.find(
                  (s) =>
                    s.ConsumableQuantityForExpirationDates &&
                    s.ConsumableQuantityForExpirationDates.length > 0
                ) != undefined;

              if (
                response.ServiceGlobalName &&
                response.ServiceGlobalName.length > 0
              ) {
                this.modalTitleForServiceName =
                  ' - ' +
                  this.translateService.instant('ServiceName') +
                  ' : ' +
                  response.ServiceGlobalName +
                  '.';

                this.serviceConsumablesWithdrawStock =
                  this.translateService.instant(
                    'ServiceConsumablesWithdrawStock'
                  ) +
                  ' : ' +
                  (response.ServiceConsumablesWithdrawStockGlobalName &&
                  response.ServiceConsumablesWithdrawStockGlobalName.length > 0
                    ? response.ServiceConsumablesWithdrawStockGlobalName
                    : this.translateService.instant('NotFound')) +
                  '.';
              }
            }
          }
        });
    }
  }
}
