import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CORE_CONFIG, CoreModuleConfig } from '../model/core-module-config';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  baseUrl: string;
  constructor(
    private readonly http: HttpClient,
    private readonly translate: TranslateService,
    private readonly spinner: NgxSpinnerService,
    @Inject(CORE_CONFIG) public config: CoreModuleConfig
  ) {
    this.baseUrl = config.ApiBaseUrl;
  }
  getUploadHeaders() {
    const token: string | null = localStorage.getItem('token') || null;
    const language: string = localStorage.getItem('lang')
      ? String(localStorage.getItem('lang'))
      : this.translate.defaultLang;

    if (token) {
      return {
        headers: new HttpHeaders()
          .set('lang', language)
          .set('AppId', "4")
          .set('Authorization', 'Bearer ' + token),
      };
    }
    return {
      headers: new HttpHeaders().
        set('lang', language).
        set('AppId', "4")

    };
  }

  public get<TResponse>(type: string, params: any = {}): Observable<TResponse> {
    return this.http.get<TResponse>(this.baseUrl + type, {
      params: { ...this.removeNull(params) },
      ...this.getUploadHeaders(),
    });
  }

  public post<TInput, TResponse>(
    type: string,
    data: TInput
  ): Observable<TResponse> {
    return this.http.post<TResponse>(this.baseUrl + type, data ?? {}, {
      ...this.getUploadHeaders(),
    });
  }

  public put<TInput, TResponse>(
    type: string,
    data: TInput,
    params?: any
  ): Observable<TResponse> {
    return this.http.put<TResponse>(this.baseUrl + type, data, {
      params: { ...params },
      ...this.getUploadHeaders(),
    });
  }

  public delete<TInput, TResponse>(
    type: string,
    id?: TInput
  ): Observable<TResponse> {
    if (id) {
      return this.http.delete<TResponse>(this.baseUrl + type + id, {
        ...this.getUploadHeaders(),
      });
    } else {
      return this.http.delete<TResponse>(this.baseUrl + type, {
        ...this.getUploadHeaders(),
      });
    }
  }
  private removeNull(queryParams: any) {
    Object.keys(queryParams).forEach(key => {
      if (queryParams[key] == null || queryParams[key] == undefined
        || queryParams[key] == "null" || queryParams[key] == "undefined")
        delete queryParams[key];
    });
    return queryParams;
  }
}
