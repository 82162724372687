import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { formValueDates } from '../../shared/helpers';
import { SharedService } from '../other/shared.service';
import { AccountCurrency } from '../../models/classes/account-setup/account-currency';
import { AccountCurrencySearchCriteria } from '../../models/search-criterias/account-currency-search-criteria';
import { AccountCurrencySearchResult } from '../../models/search-result/account-currency-search-result';
import { ExecutionResponse } from '../../models/support/execution-response';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { GetBranchAccountCurrencySearchResult } from '../../models/search-result/GetBranchAccountCurrencySearchResult';
@Injectable({
  providedIn: 'root',
})
export class AccountCurrencyService extends BaseService {
  private accountCurrencyUrl; //= super.BaseUrl() + 'AccountCurrency';  // URL to web api

  constructor(
    private http: HttpClient,
    private _BaseService: BaseServiceSZ,
    private sharedService: SharedService
  ) {
    super();

    this.accountCurrencyUrl = this.sharedService.ApiUrl + 'AccountCurrency';
  }

  /** GETaccountCurrency by id. Will 404 if id not found */
  getAccountCurrency(
    id: String
  ): Observable<ExecutionResponse<AccountCurrency>> {
    //  getAccountCurrency(id: String): ExecutionResponse<AccountCurrency> {
    //const url = '${this.accountCurrencyUrl}/${id}';
    return this.http
      .post<ExecutionResponse<AccountCurrency>>(
        this.accountCurrencyUrl + '/getById',
        id,
        super.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('fetchedaccountCurrency id=${id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<ExecutionResponse<AccountCurrency>>(
            'getAccountCurrency id=${id}'
          )
        )
      );
  }

  GetBranchAccountCurrency(
    BranchId: number
  ): Observable<GetBranchAccountCurrencySearchResult> {
    return this.http
      .post<GetBranchAccountCurrencySearchResult>(
        this.accountCurrencyUrl + '/GetBranchAccountCurrency',
        BranchId,
        super.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('GetBranchAccountCurrency id=${id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<GetBranchAccountCurrencySearchResult>(
            'GetBranchAccountCurrency id=${id}'
          )
        )
      );
  }

  addAccountCurrency(
    accountCurrency: AccountCurrency
  ): Observable<ExecutionResponse<AccountCurrency>> {
    accountCurrency = formValueDates(accountCurrency, true);
    accountCurrency.AccountSetupId = super.getAccountSetupId();
    return this.http
      .post<ExecutionResponse<AccountCurrency>>(
        this.accountCurrencyUrl + '/Create',
        accountCurrency,
        super.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log(
            'addedaccountCurrency w/ id=${accountCurrency.Id}'
          );
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<ExecutionResponse<AccountCurrency>>(
            'addAccountCurrency id=${id}'
          )
        )
      );
  }

  /* GETaccountCurrency whose name contains search term */
  searchAccountCurrencys(
    accountCurrencySearchCriteria: AccountCurrencySearchCriteria
  ): Observable<AccountCurrencySearchResult> {
    accountCurrencySearchCriteria.AccountSetupId = super.getAccountSetupId();
    return this.http
      .post<AccountCurrencySearchResult>(
        this.accountCurrencyUrl + '/Get',
        accountCurrencySearchCriteria,
        super.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('fetchedaccountCurrency');
          this._BaseService.ValidationResult(res);
        }),
        catchError(
          this._BaseService.handleError<AccountCurrencySearchResult>(
            'searchAccountCurrencys id=${id}'
          )
        )
      );
  }

  /** DELETE: delete theaccountCurrency from the server */
  deleteAccountCurrency(
    accountCurrency: AccountCurrency | number
  ): Observable<ExecutionResponse<AccountCurrency>> {
    return this.http
      .post<ExecutionResponse<AccountCurrency>>(
        this.accountCurrencyUrl + '/delete',
        accountCurrency,
        super.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log('deletedaccountCurrency id=${id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(
          this._BaseService.handleError<ExecutionResponse<AccountCurrency>>(
            'deleteAccountCurrency'
          )
        )
      );
  }

  /** PUT: update theaccountCurrency on the server */
  updateAccountCurrency(
    accountCurrency: AccountCurrency
  ): Observable<ExecutionResponse<AccountCurrency>> {
    accountCurrency.AccountSetupId = super.getAccountSetupId();
    accountCurrency = formValueDates(accountCurrency, true);
    return this.http
      .post<ExecutionResponse<AccountCurrency>>(
        this.accountCurrencyUrl + '/Update',
        accountCurrency,
        super.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.log(
            'addedaccountCurrency w/ id=${accountCurrency.Id}'
          );
          this._BaseService.ValidationResult(res);
        }),
        catchError(
          this._BaseService.handleError<ExecutionResponse<AccountCurrency>>(
            'addAccountCurrency id=${id}'
          )
        )
      );
  }
}
