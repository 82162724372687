import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { formValueDates } from '../../shared/helpers';
import { ExecutionResponse } from '../../models/support/execution-response';
import { SharedService } from '../other/shared.service';
import { Group } from 'src/app/models/classes/client/group';
import { GroupSearchCriteria } from 'src/app/models/search-criterias/group-search-criteria';
import { GroupSearchResult } from 'src/app/models/search-result/group-search-result';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { GroupsMasterSearchResultDTO } from 'src/app/models/classes/client/Groups-Master-Search-Result-DTO';
import { GetCountriesCriteria } from 'src/app/models/search-criterias/registration-cycle/get-countries-criteria';
import { GetCitiesCriteria } from 'src/app/models/search-criterias/registration-cycle/get-cities-criteria';
import { ClientsGroupsMasterSearchCriteria } from 'src/app/models/search-criterias/Clients-Groups-Master-Search-Criteria';
import { ClientSearchCriteria } from 'src/app/models/search-criterias/client-search-criteria';
@Injectable({
  providedIn: 'root'
})
export class GroupService extends BaseService {
  private groupUrl

  constructor(
    private http: HttpClient,
    private _BaseService: BaseServiceSZ,
    private sharedService: SharedService
  ) {
    super();
    this.groupUrl = this.sharedService.ApiUrl + 'Group';

  }

  getGroup(id: String): Observable<ExecutionResponse<Group>> {
    return this.http.post<ExecutionResponse<Group>>(this.groupUrl + '/getById', id, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetchedgroup id=${id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<Group>>('getGroup id=${id}'))
      );
  }

  addGroup(group: Group): Observable<ExecutionResponse<number>> {
    group = formValueDates(group, true);
    group.CompanyId = this.sharedService.CompanyId;
    return this.http.post<ExecutionResponse<number>>(this.groupUrl + '/Create', group, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('addedgroup w/ id=${group.Id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<number>>('addGroup id=${id}'))
      );
  }

  GetLiteGroups(groupSearchCriteria: GroupSearchCriteria): Observable<GroupSearchResult> {
    return this.http.get<GroupSearchResult>(this.groupUrl + '/GetLite',
     { headers: this.sharedService.getHeaders().headers, params: this.sharedService.ToHttpParams(groupSearchCriteria) })
      .pipe(
        tap(res => {
          this._BaseService.log('fetchedgroup');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<GroupSearchResult>('searchGroups id=${id}'))
      );
  }

  /* GETgroup whose name contains search term */
  searchGroups(groupSearchCriteria: GroupSearchCriteria): Observable<GroupSearchResult> {
    groupSearchCriteria.AccountSetupId = this.sharedService.AccountSetupId;

    return this.http.post<GroupSearchResult>(this.groupUrl + '/Get', groupSearchCriteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetchedgroup');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<GroupSearchResult>('searchGroups id=${id}'))
      );
  }



  deleteGroup(group: Group | number): Observable<ExecutionResponse<Group>> {
    return this.http.post<ExecutionResponse<Group>>(this.groupUrl + '/delete', group, super.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deletedgroup id=${id}');
        this._BaseService.ValidationResult(res);
      }),

      catchError(this._BaseService.handleError<ExecutionResponse<Group>>('deleteGroup'))
    );
  }

  /** PUT: update thegroup on the server */
  updateGroup(group: Group): Observable<ExecutionResponse<boolean>> {
    group.CompanyId = this.sharedService.CompanyId;
    group = formValueDates(group, true);
    return this.http.post<ExecutionResponse<boolean>>(this.groupUrl + '/Update', group, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('addedgroup w/ id=${group.Id}');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<ExecutionResponse<boolean>>('addGroup id=${id}'))
      );
  }

  GetBranches(criteria: ClientsGroupsMasterSearchCriteria): Observable<ExecutionResponse<GroupsMasterSearchResultDTO[]>> {

    return this.http.get<ExecutionResponse<GroupsMasterSearchResultDTO[]>>(this.groupUrl + '/GetBranches', { headers: this.sharedService.getHeaders().headers, params: this.sharedService.ToHttpParams(criteria) })
      .pipe(
        tap(res => {
          this._BaseService.log('GetBranches');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<GroupsMasterSearchResultDTO[]>>('GetBranches'))
      );
  }

  GetCountries(criteria: GetCountriesCriteria): Observable<ExecutionResponse<GroupsMasterSearchResultDTO[]>> {

    return this.http.get<ExecutionResponse<GroupsMasterSearchResultDTO[]>>(this.groupUrl + '/GetCountries', { headers: this.sharedService.getHeaders().headers, params: this.sharedService.ToHttpParams(criteria) })
      .pipe(
        tap(res => {
          this._BaseService.log('GetCountries');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<GroupsMasterSearchResultDTO[]>>('GetCountries'))
      );
  }
  GetCities(criteria: GetCitiesCriteria): Observable<ExecutionResponse<GroupsMasterSearchResultDTO[]>> {

    return this.http.get<ExecutionResponse<GroupsMasterSearchResultDTO[]>>(this.groupUrl + '/GetCities', { headers: this.sharedService.getHeaders().headers, params: this.sharedService.ToHttpParams(criteria) })
      .pipe(
        tap(res => {
          this._BaseService.log('GetCities');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<GroupsMasterSearchResultDTO[]>>('GetCities'))
      );
  }
  GetCategories(criteria: ClientsGroupsMasterSearchCriteria): Observable<ExecutionResponse<GroupsMasterSearchResultDTO[]>> {

    return this.http.get<ExecutionResponse<GroupsMasterSearchResultDTO[]>>(this.groupUrl + '/GetCategories', { headers: this.sharedService.getHeaders().headers, params: this.sharedService.ToHttpParams(criteria) })
      .pipe(
        tap(res => {
          this._BaseService.log('GetCategories');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<GroupsMasterSearchResultDTO[]>>('GetCategories'))
      );
  }
  GetServices(criteria: ClientsGroupsMasterSearchCriteria): Observable<ExecutionResponse<GroupsMasterSearchResultDTO[]>> {

    return this.http.get<ExecutionResponse<GroupsMasterSearchResultDTO[]>>(this.groupUrl + '/GetServices', { headers: this.sharedService.getHeaders().headers, params: this.sharedService.ToHttpParams(criteria) })
      .pipe(
        tap(res => {
          this._BaseService.log('GetServices');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<GroupsMasterSearchResultDTO[]>>('GetServices'))
      );
  }
  GetTotalClientsNumbersInGroup(criteria: ClientSearchCriteria): Observable<ExecutionResponse<number>> {

    return this.http.get<ExecutionResponse<number>>(this.groupUrl + '/GetTotalClientsNumbersInGroup', { headers: this.sharedService.getHeaders().headers, params: this.sharedService.ToHttpParams(criteria) })
      .pipe(
        tap(res => {
          this._BaseService.log('GetTotalClientsNumbersInGroup');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<number>>('GetTotalClientsNumbersInGroup'))
      );
  }

}

