<div class="print-wrap">
  <div class="bg-print" *ngIf="item">

    <div style="
    text-align: start;
    margin: 10px 5px;
    background-color: #fff;
    page-break-after: always;" *ngFor="let transactionItem of GymTransactionItems; let theIndex = index"
      [dir]="sharedService.Lang == 'ar' ? 'rtl' : 'ltr'"
      [ngStyle]="{'page-break-after': theIndex != GymTransactionItems.length - 1 ? 'always' : ''}">


      <div id="print" class="printBox d-none1" style="background-color: #fff;">
        <div style="text-align: start; direction: rtl; width: 100%; margin: 0; background-color: #fff;"
          [dir]="sharedService.Lang == 'ar' ? 'rtl' : 'ltr'">

          <div class="row" style="text-align: center;" *ngIf="AccountSetup?.ImagePath">
            <div class="col-lg-12" style="text-align: center;">
              <img [src]="AccountSetup?.ImagePath" style="width: 120px;">
            </div>
          </div>
          <br />
          <div class="row" style="text-align: center;" *ngIf="AccountSetup?.ImagePath">
            <div class="col-lg-12" style="text-align: center;font-size:24px;">
              {{ AccountSetup?.SalesInvoiceHeaderTextAR ?
              AccountSetup?.SalesInvoiceHeaderTextAR : ('SimpleTaxInvoice' | translate) }}
            </div>
          </div>
          <br />

          <table style="width: 100%; border: 1px solid #000000; padding: 10px;"
            [dir]="sharedService.Lang == 'ar' ? 'rtl' : 'ltr'">
            <tr class="text-center" style="width: 100%;">

              <td class="text-center" style="width: 100%;">
                <p class="text-center" style="text-align:center; width: 100%;font-family:initial; margin: 0 0  0 0; color: #000;
                   font-weight: 600; font-size: 20px;"> {{ sharedService.Lang == 'ar' ? sharedService.BranchNameAr :
                  sharedService.BranchNameEn }}
                </p>
              </td>
            </tr>

            <tr style="height: 10px;">

            </tr>

            <tr class="" style="">

              <td class=" text-start">
                <p
                  style=" text-align:start; font-family:initial; font-size: 16px; margin: 0 0 0 0;font-weight: 500; color: #000;">
                  {{'MemberName' | translate}} &nbsp;&nbsp;&nbsp;&nbsp; {{ item.ClientName}}

                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                  {{'MemberMobile' | translate}} &nbsp;&nbsp;&nbsp;&nbsp; {{ item.client?.Mobile}}

                  <span *ngIf="item.client?.CardNumber">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {{'MemberIdNumber' | translate}} &nbsp;&nbsp;&nbsp;&nbsp; {{ item.client?.CardNumber}}
                  </span>

                </p>
              </td>



            </tr>

            <tr class="" style="width: 100% !important;">

              <td class=" text-start">
                <p
                  style=" text-align:start; font-family:initial; font-size: 16px; margin: 0 0 0 0;font-weight: 500; color: #000;">
                  {{'InvoiceCode' | translate}} &nbsp;&nbsp;&nbsp;&nbsp; {{ item.TransactionCodeText}}

                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                  {{'BillDate' | translate}} &nbsp;&nbsp;&nbsp;&nbsp; {{ item.Date | date:'dd/MM/yyyy'}}

                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                  {{'BillTime' | translate}} &nbsp;&nbsp;&nbsp;&nbsp; {{ item.Date | date:'hh:mm a'}}

                </p>
              </td>

            </tr>
            <tr class="3333333">
              <td class=" text-start" style="width: 100%;">
                <p
                  style=" text-align:start; font-family:initial; font-size: 16px; margin: 0 0 0 0;font-weight: 500; color: #000;">
                  {{'UserName' | translate}} &nbsp;&nbsp;&nbsp;&nbsp; {{ sharedService.Lang == 'ar' ?
                  item.employee?.NameAr
                  :
                  item.employee?.NameEn}}

                  <span
                    *ngIf="sharedService.AllowAccessToClientsMobileNumbers && AccountSetup.PrintReceiptIncludeClientphoneNumber">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{'BranchPhone' | translate}}
                    &nbsp;&nbsp;&nbsp;&nbsp; {{ item.Date | date:'dd/MM/yyyy'}} </span>

                </p>

              </td>

            </tr>


            <tr class="">


              <td class=" text-start" style="width: 100%;">
                <p
                  style="width: 100%; text-align:start; font-family:initial; font-size: 16px; margin: 0 0 0 0;font-weight: 500; color: #000;">
                  <span style="text-align:start;width: 100%;" *ngIf="AccountSetup?.Email"> {{'BranchEmail' | translate}}
                    &nbsp;&nbsp;&nbsp;&nbsp; {{
                    AccountSetup?.Email}} </span>

                </p>

              </td>

            </tr>

            <tr class="">

              <td class=" text-start" style="width: 100%;">
                <p
                  style=" text-align:start; font-family:initial; font-size: 16px; margin: 0 0 0 0;font-weight: 500; color: #000;">

                  <span *ngIf="AccountSetup?.Address"> {{'BranchAddress' | translate}} &nbsp;&nbsp;&nbsp;&nbsp; {{
                    AccountSetup?.Address}} </span>

                </p>

              </td>

            </tr>

            <tr style="height: 10px;">

            </tr>

          </table>

          <div>
            <table style="width: 100%;border: 1px solid #000000; margin-top:10px;padding: 10px;"
              [dir]="sharedService.Lang == 'ar' ? 'rtl' : 'ltr'">

              <tr class="">

                <td class=" text-start" style="width: 40%; ">

                  <table style="width: 100%;" [dir]="sharedService.Lang == 'ar' ? 'rtl' : 'ltr'">

                    <tr>
                      <td>
                        <p style="font-family:initial; font-weight: 500; color: #000;" *ngIf="transactionItem">
                          {{'MembershipIndex' | translate}} &nbsp;&nbsp; <span>
                            {{ theIndex + 1}} </span>
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <p style="font-family:initial; font-weight: 500; color: #000;" *ngIf="transactionItem">
                          {{'MembershipName' | translate}} &nbsp;&nbsp; <span>
                            {{ sharedService.Lang == 'ar' ? transactionItem.service?.NameAr :
                            transactionItem.service?.NameEn}} </span>
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <p style="font-family:initial; font-weight: 500; color: #000;" *ngIf="transactionItem">
                          {{'MembershipStartDate' | translate}} &nbsp;&nbsp; <span>
                            {{transactionItem.GymMembershipStartDate |
                            date:'dd/MM/yyyy'}}</span>
                        </p>
                      </td>
                    </tr>
                    <tr class="">
                      <td class=" text-start">
                        <p style="font-family:initial; font-weight: 500; color: #000;" *ngIf="transactionItem">
                          {{'MembershipEndDate' | translate}} &nbsp;&nbsp; <span>
                            {{transactionItem.GymMembershipEndDate |
                            date:'dd/MM/yyyy'}}</span>
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <td>


                        <p *ngIf="transactionItem.TransactionItemGymMembershipWeekDays &&
                  transactionItem.TransactionItemGymMembershipWeekDays.length > 0"
                          style="font-family:initial; font-weight: 500; color: #000;">
                          <b> {{'WeekDays' | translate}} &nbsp;&nbsp;
                            <span
                              *ngFor="let d of transactionItem.TransactionItemGymMembershipWeekDays; let index = index"
                              [ngSwitch]="d.WeekDay">
                              <span *ngSwitchCase="1"> {{'Monday' | translate}} <span
                                  *ngIf="index+1 != transactionItem.TransactionItemGymMembershipWeekDays.length">-</span>
                              </span>
                              <span *ngSwitchCase="2"> {{'Tuesday' | translate}}<span
                                  *ngIf="index+1 != transactionItem.TransactionItemGymMembershipWeekDays.length">-</span></span>
                              <span *ngSwitchCase="3"> {{'Wednesday' | translate}} <span
                                  *ngIf="index+1 != transactionItem.TransactionItemGymMembershipWeekDays.length">-</span>
                              </span>
                              <span *ngSwitchCase="4"> {{'Thursday' | translate}}<span
                                  *ngIf="index+1 != transactionItem.TransactionItemGymMembershipWeekDays.length">-</span>
                              </span>
                              <span *ngSwitchCase="5"> {{'Friday' | translate}} <span
                                  *ngIf="index+1 != transactionItem.TransactionItemGymMembershipWeekDays.length">-</span>
                              </span>
                              <span *ngSwitchCase="6"> {{'Saturday' | translate}} <span
                                  *ngIf="index+1 != transactionItem.TransactionItemGymMembershipWeekDays.length">-</span>
                              </span>
                              <span *ngSwitchCase="0"> {{'Sunday' | translate}} <span
                                  *ngIf="index+1 != transactionItem.TransactionItemGymMembershipWeekDays.length">-</span>
                              </span>
                            </span>
                          </b>
                      </td>
                    </tr>

                  </table>

                </td>
                <td class="text-start" style="width: 60%;">
                  <table style="width: 100%;" [dir]="sharedService.Lang == 'ar' ? 'rtl' : 'ltr'">

                    <tr class="">
                      <td class=" text-start"> {{'MembershipCategory' | translate}}
                        &nbsp;&nbsp;
                        {{ sharedService.Lang == 'ar' ? transactionItem.service?.Category?.NameAr :
                        transactionItem.service?.Category?.NameEn}}

                      </td>
                    </tr>

                    <tr class="" *ngIf="transactionItem">
                      <td class=" text-start">{{ "GymMembershipCode" | translate }}
                        &nbsp;&nbsp;
                        {{transactionItem.GymMembershipCode}}</td>
                    </tr>


                    <tr class="" *ngIf="transactionItem">
                      <td class=" text-start">{{ "MembershipPrice" | translate }}
                        &nbsp;&nbsp;
                        {{transactionItem.UnitPrice.toFixed(sharedService.GetFractionRoundCount)}}
                        {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr
                        : sharedService.CurrencyNameEn}}</td>
                    </tr>

                    <tr class="" *ngIf="transactionItem">
                      <td class=" text-start">{{ "Discount" | translate }}
                        &nbsp;&nbsp;
                        {{transactionItem.DiscountValue.toFixed(sharedService.GetFractionRoundCount)}}
                        {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr
                        : sharedService.CurrencyNameEn}}
                      </td>
                    </tr>

                    <tr class="" *ngIf="transactionItem">
                      <td class=" text-start">{{ "Total" | translate }}
                        &nbsp;&nbsp;
                        {{transactionItem.Total.toFixed(sharedService.GetFractionRoundCount)}}
                        {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr
                        : sharedService.CurrencyNameEn}}
                      </td>
                    </tr>

                  </table>
                </td>
              </tr>
            </table>
          </div>
          <div *ngIf="false && transactionItem">
            <table border="1" style="width: 100%; border-collapse: collapse; text-align: start !important; margin: 2px 0;
                border: 2px solid #000;" [dir]="sharedService.Lang == 'ar' ? 'rtl' : 'ltr'">
              <thead style="text-align: start;">
                <tr style="text-align: start;     ">
                  <th
                    style="font-family:initial; font-size: 20px;font-weight: 500; text-align: start !important; color: #fff; padding:5px;">
                    # </th>
                  <!-- <th
                  style="font-family:initial; font-size: 20px;font-weight: 500; text-align: start !important; color: #fff; padding:5px;">
                  </th> -->
                  <th
                    style="font-family:initial; font-size: 20px;font-weight: 500; text-align: start !important; color: #fff; padding:5px;">
                    {{ "Name" | translate }}</th>
                  <th
                    style="font-family:initial; font-size: 20px;font-weight: 500; text-align: start !important; color: #fff; padding:5px;">
                    طريقة الدفع</th>
                  <th
                    style="font-family:initial; font-size: 20px;font-weight: 500; text-align: start !important; color: #fff; padding:5px;">
                    السعر</th>
                  <th
                    style="font-family:initial; font-size: 20px;font-weight: 500; text-align: start !important; color: #fff; padding:5px;">
                    الخصم</th>
                  <th
                    style="font-family:initial; font-size: 20px;font-weight: 500; text-align: start !important; color: #fff; padding:5px;">
                    السعر النهائى</th>
                </tr>
              </thead>
              <tbody>
                <tr style="text-align: start;">

                  <td style="font-family:initial; font-size: 20px;font-weight: 500;color: #000; padding:5px;">
                    {{transactionItem.service?.Id}}
                  </td>
                  <!-- <td style="font-family:initial; font-size: 20px;font-weight: 500;color: #000; padding:5px;">
                  {{ sharedService.Lang == 'ar' ? transactionItem.service.Category?.NameAr :
                  transactionItem.service.Category?.NameEn}}
                </td> -->
                  <td style="font-family:initial; font-size: 20px;font-weight: 500;color: #000; padding:5px;">
                    {{ sharedService.Lang == 'ar' ? transactionItem.service?.NameAr :
                    transactionItem.service?.NameEn}}
                  </td>
                  <td style="font-family:initial; font-size: 20px;font-weight: 500;color: #000; padding:5px;">
                    <span *ngFor="let o of item.TransactionsPayments">{{ sharedService.Lang == 'ar' ?
                      o.PaymentMethod?.NameAr :
                      o.PaymentMethod?.NameEn}}</span>
                  </td>
                  <td style="font-family:initial; font-size: 20px;font-weight: 500;color: #000; padding:5px;">
                    {{transactionItem.UnitPrice.toFixed(sharedService.GetFractionRoundCount)}}
                    {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr
                    : sharedService.CurrencyNameEn}}
                  </td>
                  <td style="font-family:initial; font-size: 20px;font-weight: 500;color: #000; padding:5px;">
                    {{transactionItem.DiscountValue.toFixed(sharedService.GetFractionRoundCount)}}
                  </td>
                  <td style="font-family:initial; font-size: 20px;font-weight: 500;color: #000; padding:5px;">
                    {{transactionItem.Total.toFixed(sharedService.GetFractionRoundCount)}}
                    {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr
                    : sharedService.CurrencyNameEn}}</td>

                </tr>
              </tbody>
            </table>
          </div>

          <table style="width: 100%; text-align: start; margin-top: 10px;"
            [dir]="sharedService.Lang == 'ar' ? 'rtl' : 'ltr'">
            <tr>
              <td style="font-family:initial;font-size: 20px;font-weight: 500; color: #000; width: 160px;width: 48%;">

                <div style="display: flex;justify-content: space-between;"
                  *ngIf="sharedService.AllowUseSalesTaxRate == true">
                  <p style="font-size: 16px;padding: 8px 0px;font-weight: 600; margin: 0;">السعر بدون الضريبة المضافة
                  </p>
                  <p
                    style=" width: 130px;text-align: center;border: 2px solid #000;padding: 3px;margin: 2px; white-space: nowrap;">
                    {{transactionItem.PriceBeforeDiscount.toFixed(sharedService.GetFractionRoundCount)}}
                    {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr
                    : sharedService.CurrencyNameEn}}
                  </p>
                </div>
                <div style="display: flex;justify-content: space-between;"
                  *ngIf="sharedService.AllowUseSalesTaxRate == true">
                  <p style="font-size: 16px;padding: 8px 0px;font-weight: 600; margin: 0;">الخصومات
                  </p>
                  <p style=" width: 130px;text-align: center;border: 2px solid #000;padding: 2px;margin: 2px;">
                    {{transactionItem.DiscountValue.toFixed(sharedService.GetFractionRoundCount)}}
                    {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr
                    : sharedService.CurrencyNameEn}}
                  </p>
                </div>
                <div style="display: flex;justify-content: space-between;"
                  *ngIf="sharedService.AllowUseSalesTaxRate == true">
                  <p style="font-size: 16px;padding: 8px 0px;font-weight: 600; margin: 0;"> قيمة الضريبة المضافة 15 %
                  </p>
                  <p style=" width: 130px;text-align: center;border: 2px solid #000;padding: 2px;margin: 2px;">
                    {{transactionItem.TaxValue.toFixed(sharedService.GetFractionRoundCount)}}
                    {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr
                    : sharedService.CurrencyNameEn}}
                  </p>
                </div>
                <div style="display: flex;justify-content: space-between;"
                  *ngIf="sharedService.AllowUseSalesTaxRate == true">
                  <p style="font-size: 16px;padding: 8px 0px;font-weight: 600; margin: 0;">السعر شامل الضريبة المضافة
                  </p>
                  <p style=" width: 130px;text-align: center;border: 2px solid #000;padding: 2px;margin: 2px;">
                    {{transactionItem.Total.toFixed(sharedService.GetFractionRoundCount)}}
                    {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr
                    : sharedService.CurrencyNameEn}}
                  </p>
                </div>
                <div style="display: flex;justify-content: space-between;"
                  *ngIf="sharedService.AllowUseSalesTaxRate == false">
                  <p style="font-size: 16px;padding: 8px 0px;font-weight: 600; margin: 0;">السعر
                  </p>
                  <p style=" width: 130px;text-align: center;border: 2px solid #000;padding: 2px;margin: 2px;">
                    {{transactionItem.Total.toFixed(sharedService.GetFractionRoundCount)}}
                    {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr
                    : sharedService.CurrencyNameEn}}
                  </p>
                </div>

              </td>
              <td
                style="font-family:initial;font-size: 20px;font-weight: 500; color: #000; text-align: end;width: 25%;">
              </td>
              <td
                style="font-family:initial;font-size: 20px;font-weight: 500; color: #000; text-align: end;width: 25%;">
                <div style="display: flex;justify-content: space-between;">
                  <p style="font-size: 16px;padding: 8px 0px;font-weight: 600; margin: 0;">{{'Payed' | translate}}</p>
                  <p style="width: 100px;text-align: center;border: 2px solid #000;padding: 2px;margin: 2px;">
                    {{(transactionItem.Total - (item.RemainingAmountFromPostpaid ? item.RemainingAmountFromPostpaid :
                    0)).toFixed(sharedService.GetFractionRoundCount)}}
                    {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr
                    : sharedService.CurrencyNameEn}}
                  </p>
                </div>
                <div style="display: flex;justify-content: space-between;">
                  <p style="font-size: 16px;padding: 8px 0px;font-weight: 600; margin: 0;">{{'TheRemaining' |
                    translate}}
                  </p>
                  <p style="width: 100px;text-align: center;border: 2px solid #000;padding: 2px;margin: 2px;">
                    {{item.RemainingAmountFromPostpaid ?
                    item.RemainingAmountFromPostpaid.toFixed(sharedService.GetFractionRoundCount) : 0}}
                    {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr
                    : sharedService.CurrencyNameEn}}
                  </p>
                </div>

                <div style="display: flex;justify-content: space-between;">
                  <p style="font-size: 16px;padding: 8px 0px;font-weight: 600; margin: 0;">{{'PaymentMethods' |
                    translate}}
                  </p>
                  <p style="width: 100px;text-align: center;border: 2px solid #000;padding: 2px;margin: 2px;">
                    <span *ngFor="let o of item.TransactionsPayments">{{ sharedService.Lang == 'ar' ?
                      o.PaymentMethod?.NameAr :
                      o.PaymentMethod?.NameEn}}</span>
                  </p>
                </div>


                <div></div>

              </td>
            </tr>

          </table>
          <div *ngIf="transactionItem">

            <h4> تعليمات وشروط يجب على المشترك التقيد بها: </h4>
            <div *ngIf="transactionItem.ContractTerms">
              <pre>
              <div [innerHTML]="transactionItem.ContractTerms">  </div>
             </pre>
            </div>

            <div *ngIf="!transactionItem.ContractTerms">

              <p> 1 - الالتزام بالزى المناسب &nbsp;
                2 - يمنع منعا باتا الدخول الى الصالة الرياضبة بدون الحذاء الرياضى
              </p>
              <p> 3 - الحفاظ على النظافة العامة 4 - عدم اصطحاب الاطفال</p>
              <p> 5 - رسوم عضوية الاشتراك غير قابلة للاسترجاع نهائيا فى اى حال من الاحوال وفى حال عدم تستديد رسوم
                العضوية
                تعتبر لاغية</p>
              <p> 6 - فى حال الاشتراك وعدم الحضور يكون الاشتراك سارى المفعول ولا يتوقف الاشتراك نهائيا الا بطلب ايقاف من
                العضو نفسة </p>
              <p> 7 - فى حال كان الاشتراك اكثر من ثلاثة أشهر يحق للعضو طلب ايقاف الاشتراك مرتين بحيث لا تتجاوز مدة
                الايقاف
                15 يوما للمرة الواحده كما يحق لة طلب مرة واحده بمده زمنية لا تتجاوز 30 يوما </p>
              <p> 8 - لا يحق تبديل الاشتراك لاى فرد اخر وفى حال عدم رغبة المشترك الاستمرار فانة لا يحق لة استرجاع المبلغ
                نهائيا</p>
              <p> 9 - يجب ان يكون العضو لائق صحيا ولا يتحمل المركز مسؤلية اى مضاعفات صحية تحدث لة لاحقا</p>
            </div>

          </div>
          <!-- <div style="width: 100%; height: 0px; border:1px solid #000; margin: 0px;"></div> -->
          <div>
            <div style="text-align: center;" *ngIf="AccountSetup?.AllowGenerateQRCode ">
              <ngx-qrcode [width]="450" [elementType]="elementType" [errorCorrectionLevel]="correctionLevel"
                [value]="item.ZaqatQRCode" alt="Demo QR Code" cssClass="bshadow"></ngx-qrcode>
                <hr>

            </div>
          </div>
          <p *ngIf="AccountSetup?.AllowShowCommercialRecordNumberInPrint && AccountSetup?.CommercialRecordNumber && AccountSetup?.CommercialRecordNumber.length > 0"
            style="text-align: center; font-weight: 500;color: #000;margin: 3px 0px;font-family:initial; font-size: 16px;font-weight: 500;">
            {{ 'CommercialRecordNumber' | translate }}: {{AccountSetup?.CommercialRecordNumber}}</p>
          <p *ngIf="AccountSetup?.AllowShowTaxRegistrationNumberInPrint && AccountSetup?.TaxRegistrationNumber && AccountSetup?.TaxRegistrationNumber.length > 0"
            style="text-align: center; font-weight: 500;color: #000;margin: 3px 0px;font-family:initial; font-size: 16px;font-weight: 500;">
            {{ 'TaxRegistrationNumber' | translate }}: {{AccountSetup?.TaxRegistrationNumber}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
