<div class="modal-header">
  <h4 class="modal-title"> <i class="fas fa-bell"></i> &nbsp; {{'ClientAttendanceAlert' | translate }} </h4>
</div>

<div *ngIf="!client" class="col-md-12">
  <app-loading></app-loading>
</div>

<div *ngIf="client" class="modal-body px-0 py-0">
  <div class="row">
    <div class="col-lg-3 col-md-4">
      <div class="card-profile">
        <img *ngIf="!client.ImagePath" [class.red-border]="client.Status !=1" src="assets/img/profile.jpg" alt="">
        <img *ngIf="client.ImagePath" [class.red-border]="client.Status !=1" [src]="client.ImagePath" alt="">

        <h6> {{'ClientName' | translate }}: &nbsp; {{client.Name}}</h6>
        <h6>{{'ClientMobile' | translate }}: &nbsp; {{client.MobileNumber}}</h6>
        <h6 *ngIf="client.GymMembershipCode">{{'GymMembershipCode' | translate }}: &nbsp; {{client.GymMembershipCode}}
        </h6>

        <h6 *ngIf="client.IdentityNumber"> {{'IdNumber' | translate }}: &nbsp; {{client.IdentityNumber}}</h6>
        <h6>{{'AllAvailableAttendanceDays' | translate }}: &nbsp;
          <i class="fas fa-info-circle text-info"
            title="{{'HearAppearAllAllAvailableAttendanceDaysFromClientActiveMemberships' | translate }}"></i> &nbsp;
          <span *ngIf="client.AllowedWeekDaysNames">
            {{client.AllowedWeekDaysNames }}
          </span>
          <span class="text-danger" *ngIf="!client.AllowedWeekDaysNames">
            {{'NotDefined' | translate}}
          </span>
        </h6>
        <h6>{{'AttendanceStaus' | translate }}: &nbsp;
          <span class="text-success" style="font-weight:900;" *ngIf="client.IsAllowedAttendance == true">
            {{'AllowedAttendance' | translate}}
          </span>
          <span class="text-danger" style="font-weight:900;" class="text-danger"
            *ngIf="client.IsAllowedAttendance == false">
            {{'NotAllowedAttendance' | translate}}
          </span>
          <span class="text-info" style="font-weight:900;" *ngIf="client.IsAllowedAttendance == null">
            {{'NotDefined' | translate}}
          </span>
        </h6>
      </div>
    </div>
    <div class="col-lg-9  col-md-8">
      <div class="table-card">
        <h5 class="py-2"> <i class="fas fa-list"></i> &nbsp; {{'ClientMemberships' | translate }}</h5>

        <div class="table-responsive text-nowrap">
          <table class="table table-stripedd table-sm table-bordered">
            <thead class="table-head">
              <tr>
                <th width="40%"> {{'MembershipName' | translate }}</th>
                <th width="10%"> {{'DateFrom' | translate }}</th>
                <th width="10%"> {{'DateTo' | translate }}</th>
                <th width="10%"> {{'MembershipStatus' | translate }}</th>
                <th width="20%"> {{'AttendanceDays' | translate }}</th>
                <th width="10%"> {{'Actions' | translate }}</th>
              </tr>
            </thead>
            <tbody *ngFor="let membership of Memberships; let i = index">
              <tr>
                <td class="fontClass text-center">

                  <div class="row">
                    <div class="col-lg-4" *ngIf="membership.Activities && membership.Activities.length > 0">
                      <button class="btn ml-2" (click)="selectRow(i)">
                        <i *ngIf="i !== ExpandRow" class="fas fa-angle-down"></i>
                        <i *ngIf="i == ExpandRow" class="fas fa-angle-up"></i>
                        &nbsp; {{'TheActivities' | translate }}
                      </button>
                    </div>
                    <div class="col-lg-8 text-center">
                      <span class="overme20 fontClass text-center" title="{{ membership.Name }}">
                        {{membership.Name }}
                      </span>
                    </div>
                  </div>
                </td>

                <td>{{membership.FromDate | date:'yyyy-MM-dd'}}</td>
                <td>{{membership.ToDate | date:'yyyy-MM-dd'}}</td>
                <td>
                  {{('TheGymMembership' +
                  periodStatus[membership.Status]) | translate}}
                </td>
                <td>
                  <span *ngIf="membership.AllowedWeekDaysNames" class="overme20 fontClass text-center"
                    title="{{ membership.AllowedWeekDaysNames }}">
                    {{membership.AllowedWeekDaysNames}}
                  </span>
                  <span class="text-danger" *ngIf="!membership.AllowedWeekDaysNames">
                    {{'NotDefined' | translate}}
                  </span>
                </td>
                <td>
                  <button (click)="attendMembership(membership.Id)" class="btn">{{'Attend' | translate}}</button>
                </td>
              </tr>
              <ng-container *ngIf="i == ExpandRow">
                <tr>
                  <td colspan="10">
                    <table class="table table-stripedd table-sm table-bordered" style="border: 3px solid red;">
                      <thead class="table-head">
                        <tr>
                          <th>{{'TheActivityName' | translate}}</th>
                          <th>{{'ActivityType' | translate}}</th>
                          <th>{{'LimitedAttendance' | translate}}</th>
                          <th>{{'LimitedAttendanceCount' | translate}}</th>
                          <th>{{'CurrentAttendanceCount' | translate}}</th>
                          <th>{{'Actions' | translate}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let activity of membership.Activities">
                          <td>{{activity.ActivityName}}</td>
                          <td>{{ getGymActivityTypeString(activity.ActivityType) | translate }}</td>
                          <td>{{(activity.IsLimitedAttendance ? "Yes" : "No") | translate}}</td>
                          <td>
                            <span *ngIf="activity.LimitedAttendanceCount">
                              {{activity.LimitedAttendanceCount}}
                            </span>
                            <span class="text-danger" *ngIf="!activity.LimitedAttendanceCount">
                              {{'NotDefined' | translate}}
                            </span>
                          </td>
                          <td>{{activity.AttendanceCount}}</td>

                          <td> <button (click)="AttendClassClient(activity.Id)" class="btn">{{'Attend' |
                              translate}}</button></td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>

        </div>
      </div>
      <div class="modal-footer" style="margin-top: 10%;">

        <div class="row">
          <div class="col-lg-12 text-center">

            <p class="willClose">
              {{ 'WillCloseAutomaticallyAfter' | translate }}
              <span #seconds class="willCloseSeconds"></span> <span class="willClose"> {{ 'Seconds' | translate }}
              </span>
              &nbsp;&nbsp; <a href="javascript:void(0)" (click)="CancelClose()" class="cancelClose"> {{
                'CancelAutomaticClose' |
                translate }} </a>
            </p>

          </div>
          <div class="col-lg-12 text-center">

            <button type="button" class="btn btn-modal-action" (click)="CloseAll()">{{'Close' |
              translate}}</button>

          </div>
        </div>

      </div>
    </div>

  </div>
</div>
