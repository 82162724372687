import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { formValueDates } from '../../shared/helpers';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { ClientBalanceDetail } from '../../models/classes/balances/client-balance-detail';
import { ClientBalanceDetailSearchCriteria } from '../../models/search-criterias/client-balance-detail-search-criteria';
import { ClientBalanceDetailSearchResult } from '../../models/search-result/client-balance-detail-search-result';
import { ExecutionResponse } from '../../models/support/execution-response';
import { SharedService } from '../other/shared.service';

@Injectable({
  providedIn: 'root'
})
export class ClientBalanceDetailService extends BaseService {
  private clientBalanceDetailUrl;

  constructor(
    private http: HttpClient,
    private _BaseService: BaseServiceSZ,
    private sharedService: SharedService
  ) {
    super();
    this.clientBalanceDetailUrl = this.sharedService.ApiUrl +
      'ClientBalanceDetail';

  }



  getClientBalance(id: String): Observable<ExecutionResponse<ClientBalanceDetail>> {
    return this.http.post<ExecutionResponse<ClientBalanceDetail>>(this.clientBalanceDetailUrl + '/getById', id, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetchedclientBalanceDetail id=${id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<ClientBalanceDetail>>('getClientBalance id=${id}'))
      );
  }


  addClientBalance(clientBalanceDetail: ClientBalanceDetail): Observable<ExecutionResponse<ClientBalanceDetail>> {
    clientBalanceDetail = formValueDates(clientBalanceDetail, true);
    return this.http.post<ExecutionResponse<ClientBalanceDetail>>(this.clientBalanceDetailUrl + '/Create', clientBalanceDetail, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('addedclientBalance w/ id=${clientBalance.Id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<ClientBalanceDetail>>('addClientBalance id=${id}'))
      );
  }

  /* GETclientBalanceDetail whose name contains search term */
  searchClientBalanceDetails(clientBalanceSearchCriteria: ClientBalanceDetailSearchCriteria):
    Observable<ClientBalanceDetailSearchResult> {
    clientBalanceSearchCriteria.CompanyId = this.sharedService.CompanyId;
    return this.http.post<ClientBalanceDetailSearchResult>(this.clientBalanceDetailUrl +
      '/Get', clientBalanceSearchCriteria, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetchedclientBalanceDetail');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<ClientBalanceDetailSearchResult>('searchClientBalanceDetails id=${id}'))
      );
  }




  deleteClientBalance(clientBalanceDetail: ClientBalanceDetail | number): Observable<ExecutionResponse<boolean>> {
    return this.http.post<ExecutionResponse<boolean>>(this.clientBalanceDetailUrl + '/delete', clientBalanceDetail, this.sharedService.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deletedclientBalanceDetail id=${id}');
        this._BaseService.ValidationResult(res);
      }),

      catchError(this._BaseService.handleError<ExecutionResponse<boolean>>('deleteClientBalanceDetail'))
    );
  }

  /** PUT: update theclientBalance on the server */
  updateClientBalance(clientBalanceDetail: ClientBalanceDetail): Observable<ExecutionResponse<boolean>> {

    clientBalanceDetail = formValueDates(clientBalanceDetail, true);
    return this.http.post<ExecutionResponse<boolean>>(this.clientBalanceDetailUrl + '/Update', clientBalanceDetail, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('addedclientBalanceDetail w/ id=${clientBalanceDetail.Id}');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<ExecutionResponse<boolean>>('addClientBalance id=${id}'))
      );
  }


}

