<div id="printGymMembershipInfo">
  <div class="modal-header">
    <h5 class="modal-title text-capitalize" id="exampleModalLabel">
      <i class="fas fa-id-card px-2"></i> {{'GymMembershipInfo' | translate}}
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="modal-body text-start col-lg-12" *ngIf="gymMembership">
        <div class="formCard">
          <table class="table table-sm">
            <tr>
              <td>{{ 'GymMembershipCode' | translate }}</td>
              <td> {{ gymMembership.GymMembershipCode }}</td>
            </tr>

            <tr>
              <td>{{ 'MembershipName' | translate }}</td>
              <td> {{ gymMembership.GymMembershipName}}</td>
            </tr>

            <tr>
              <td>{{ 'ClientName' | translate }}</td>
              <td> {{ gymMembership.ClientName}}</td>
            </tr>

            <tr>
              <td>{{ 'Mobile' | translate }}</td>
              <td> {{ gymMembership.ClientMobile}}</td>
            </tr>

            <tr>
              <td>{{ 'BuyDate' | translate }}</td>
              <td> {{gymMembership.MembershipBuyDate | date: 'yyyy-MM-dd'}}</td>
            </tr>

            <tr>
              <td>{{ 'MembershipTotalCost' | translate }}</td>
              <td>
                {{gymMembership.MembershipTotalCost.toFixed(sharedService.GetFractionRoundCount)
                }}</td>
            </tr>
            <tr>
              <td>{{ 'MembershipStatus' | translate }}</td>
              <td> {{ ('TheGymMembership' +
                periodStatus[gymMembership.GymMembershipPeriodStatus]) | translate }}</td>
            </tr>

            <tr *ngIf="gymMembership.IsCanceled">
              <td>{{ 'CancelationWay' | translate }}</td>
              <td> {{cancelationWay[gymMembership.CancelationWay] | translate }}</td>
            </tr>
            <tr *ngIf="gymMembership.IsCanceled">
              <td>{{ 'CancelationDate' | translate }}</td>
              <td> {{gymMembership.CancelationDate | date: 'yyyy-MM-dd' }}</td>
            </tr>
            <tr *ngIf="gymMembership.IsCanceled">
              <td>{{ 'CancelationUserName' | translate }}</td>
              <td> {{gymMembership.CancelationUserName}}</td>
            </tr>

            <tr>
              <td>{{ 'GymMembershipStartDate' | translate }}</td>
              <td>{{ gymMembership.GymMembershipStartDate | date: 'yyyy-MM-dd'}}</td>
            </tr>
            <tr>
              <td>{{ 'GymMembershipEndDate' | translate }}</td>
              <td>{{ gymMembership.GymMembershipEndDate | date: 'yyyy-MM-dd'}}</td>
            </tr>


            <tr>
              <td>{{ 'TotalGymMembershipPeriod' | translate }}</td>

              <td>
                {{ (gymMembership.TotalGymMembershipPeriodYears) }}
                {{ ( 'AYear' | translate)}}
                -
                {{ (gymMembership.TotalGymMembershipPeriodMonthes) }}
                {{ ( 'Month' | translate)}}-
                {{ (gymMembership.TotalGymMembershipPeriodDays) }}
                {{ ( 'Day' | translate)}}

              </td>
            </tr>


            <tr>
              <td>{{ 'RemainingGymMembershipPeriod' | translate }}</td>

              <td *ngIf="gymMembership.GymMembershipPeriodStatus ==
                      periodStatus.NotStarted">
                {{ 'GymMembershipNotStarted' | translate }}
              </td>
              <td *ngIf="gymMembership.GymMembershipPeriodStatus ==
                      periodStatus.Finished">
                {{ 'GymMembershipFinished' | translate }}
              </td>
              <td *ngIf="gymMembership.GymMembershipPeriodStatus ==
                      periodStatus.Canceled">
                {{ 'GymMembershipCanceled' | translate }}
              </td>
              <td *ngIf="gymMembership.GymMembershipPeriodStatus ==
                periodStatus.FinishedAfterActivitiesTimesComplete">
                {{ 'GymMembershipFinishedAfterActivitiesTimesComplete' | translate }}
              </td>
              <td *ngIf="gymMembership.GymMembershipPeriodStatus ==
                periodStatus.FinishedAfterAttendanceTimesComplete">
                {{ 'GymMembershipFinishedAfterAttendanceTimesComplete' | translate }}
              </td>

              <td *ngIf="gymMembership.GymMembershipPeriodStatus ==
              periodStatus.OnGoing || gymMembership.GymMembershipPeriodStatus ==
              periodStatus.AlmostFinished">

                {{ (gymMembership.RemainingGymMembershipPeriodYears) }}
                {{ ( 'AYear' | translate)}}
                -
                {{ (gymMembership.RemainingGymMembershipPeriodMonthes) }}
                {{ ( 'Month' | translate)}}
                -
                {{ (gymMembership.RemainingGymMembershipPeriodDays) }}
                {{ ( 'Day' | translate)}}

              </td>
            </tr>
            <tr>
              <td>{{ 'GymMembershipWeekDaysNumber' | translate }}</td>
              <td>{{ gymMembership.GymMembershipWeekDaysNumber}}</td>
            </tr>
            <tr>
              <td>{{ 'IsMandatorySpecifayDayesPerWeekWhenSellGymMembership' | translate }}</td>
              <td>{{ (gymMembership.SpecifayDayesPerWeekWhenSellGymMembership ? 'Yes' : 'No') |
                translate}}</td>
            </tr>
            <tr>
              <td>{{ 'ConvertMembershipToExpiredAfterActivitiesTimesComplete' | translate }}</td>
              <td>{{ (gymMembership.ConvertMembershipToExpiredAfterActivitiesTimesComplete ?
                'Yes' : 'No') |
                translate}}</td>
            </tr>
            <tr>
              <td>{{ 'DoneConvertMembershipToExpiredAfterActivitiesTimesComplete' | translate }}</td>
              <td>{{ (gymMembership.IsExpiredAfterActivitiesTimesComplete ? 'Yes' : 'No') |
                translate}}</td>
            </tr>
            <tr>
              <td>{{ 'ConvertMembershipToExpiredAfterAllowedAttendanceTimesComplete' | translate }}
              </td>
              <td>{{
                (gymMembership.ConvertMembershipToExpiredAfterAllowedAttendanceTimesComplete ?
                'Yes' : 'No') |
                translate}}</td>
            </tr>
            <tr>
              <td>{{ 'DoneConvertMembershipToExpiredAfterAllowedAttendanceTimesComplete' | translate
                }}</td>
              <td>{{ (gymMembership.IsExpiredAfterAttendanceTimesComplete ? 'Yes' : 'No') |
                translate}}</td>
            </tr>
            <tr>
              <td>{{ 'AllowedAttendanceCount' | translate }}</td>
              <td>{{ gymMembership.AttendanceCount}}</td>
            </tr>
            <tr *ngIf="gymMembership.GymClientMembershipClientChangeLogs &&
              gymMembership.GymClientMembershipClientChangeLogs.length > 0">
              <td>{{ 'FreezCount' | translate }}</td>
              <td>{{ gymMembership.GymClientMembershipClientChangeLogs.length}}</td>
            </tr>
          </table>
        </div>

        <div class="formCard" *ngIf="gymMembership.GymClientMembershipActivities &&
            gymMembership.GymClientMembershipActivities.length > 0">
          <br>
          <h5 class="modal-title text-capitalize" id="exampleModalLabel">
            <i class="fas fa-tasks px-2"></i> {{'GymClientMembershipActivities' | translate}}
          </h5>
          <br>
          <div class="formCard">
            <div>
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th style="width: 10%;" class="text-center">{{ 'Index' | translate }}</th>
                    <th style="width: 20%;" class="text-center">{{ 'ActivityName' | translate }}</th>
                    <th style="width: 20%;" class="text-center">{{ 'ActivityType' | translate }}</th>
                    <th style="width: 10%;" class="text-center">{{ 'LimitedAttendance' | translate }}
                    </th>
                    <th style="width: 20%;" class="text-center">{{ 'LimitedAttendanceCount' |
                      translate }}</th>
                    <th style="width: 20%;" class="text-center">{{ 'CurrentAttendanceCount' |
                      translate }}</th>
                  </tr>
                </thead>
                <tbody
                  *ngFor="let gymClientMembershipActivity of gymMembership.GymClientMembershipActivities; let i=index">
                  <tr>
                    <td style="width: 10%;" class="text-center">
                      {{ i+1 }}
                    </td>
                    <td style="width: 20%;" class="text-center">
                      {{ gymClientMembershipActivity.ActivityName }}
                    </td>
                    <td style="width: 20%;" class="text-center">
                      {{ getGymActivityTypeString( gymClientMembershipActivity.ActivityType) |
                      translate }}
                    </td>
                    <td style="width: 10%;" class="text-center">
                      {{ (gymClientMembershipActivity.IsLimitedAttendance ? 'Yes' : 'No') | translate
                      }}
                    </td>
                    <td style="width: 20%;" class="text-center">
                      {{ (gymClientMembershipActivity.IsLimitedAttendance ?
                      gymClientMembershipActivity.LimitedAttendanceCount : ( 'NotFound' | translate))
                      }}
                    </td>
                    <td style="width: 20%;" class="text-center">
                      {{ (gymClientMembershipActivity.IsLimitedAttendance ?
                      gymClientMembershipActivity.AttendanceCount : ( 'NotFound' | translate)) }}
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="formCard"
          *ngIf="gymMembership.GymClientMembershipWeekDays && gymMembership.GymClientMembershipWeekDays.length > 0">
          <br>
          <h5 class="modal-title text-capitalize" id="exampleModalLabel">
            <i class="fas fa-calendar px-2"></i> {{'GymMembershipWeekDays' | translate}}
          </h5>
          <br>
          <div class="formCard">
            <div>
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th style="width: 20%;" class="text-center">{{ 'Index' | translate }}</th>
                    <th style="width: 80%;" class="text-center">{{ 'TheDay' | translate }}</th>

                  </tr>
                </thead>
                <tbody
                  *ngFor="let gymClientMembershipWeekDay of gymMembership.GymClientMembershipWeekDays; let i=index">
                  <tr>
                    <td style="width: 20%;" class="text-center">
                      {{ i+1 }}
                    </td>
                    <td style="width: 80%;" class="text-center">
                      {{ WeekDays[gymClientMembershipWeekDay.WeekDay] | translate }}
                    </td>

                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>


        <div class="formCard"
          *ngIf="gymMembership.GymClientMembershipAttendanceBranches && gymMembership.GymClientMembershipAttendanceBranches.length > 0">
          <br>
          <h5 class="modal-title text-capitalize" id="exampleModalLabel">
            <i class="fas fa-solid fa-building px-2"></i> {{'GymMembershipAttendanceBranches' |
            translate}}
          </h5>
          <br>
          <div class="formCard">
            <div>
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th style="width: 20%;" class="text-center">{{ 'Index' | translate }}</th>
                    <th style="width: 80%;" class="text-center">{{ 'ThisBranchName' | translate }}
                    </th>

                  </tr>
                </thead>
                <tbody
                  *ngFor="let gymClientMembershipAttendanceBranch of gymMembership.GymClientMembershipAttendanceBranches; let i=index">
                  <tr>
                    <td style="width: 20%;" class="text-center">
                      {{ i+1 }}
                    </td>
                    <td style="width: 80%;" class="text-center">
                      {{ gymClientMembershipAttendanceBranch.BranchName }}
                    </td>

                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="formCard"
          *ngIf="gymMembership.GymMembershipClientAlertLogs && gymMembership.GymMembershipClientAlertLogs.length > 0">
          <br>
          <h5 class="modal-title text-capitalize" id="exampleModalLabel">
            <i class="fas fa-solid fa-envelope px-2"></i> {{'AlmostDoneClientAlertLogs' | translate}}
          </h5>
          <br>
          <div class="formCard">
            <div>
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th style="width: 20%;" class="text-center">{{ 'Index' | translate }}</th>
                    <th style="width: 20%;" class="text-center">{{ 'AlertMethod' | translate }}</th>
                    <th style="width: 20%;" class="text-center">{{ 'AlertDate' | translate }}</th>
                    <th style="width: 20%;" class="text-center">{{ 'AlertTime' | translate }}</th>
                    <th style="width: 20%;" class="text-center">{{ 'AlertStatus' | translate }}</th>
                  </tr>
                </thead>
                <tbody
                  *ngFor="let gymMembershipClientAlertLog of gymMembership.GymMembershipClientAlertLogs; let i=index">
                  <tr>

                    <td style="width: 20%;" class="text-center">
                      {{ i+1 }}
                    </td>

                    <td style="width: 20%;" class="text-center">
                      {{ (gymMembershipClientAlertLog.AlertMethod == alertMethod.Mobile ?
                      ('Mobile' | translate) :gymMembershipClientAlertLog.AlertMethod ==
                      alertMethod.Email ? ('Email' | translate) :
                      gymMembershipClientAlertLog.AlertMethod ==
                      alertMethod.Both? ('Both' | translate) : '' ) }}
                    </td>

                    <td style="width: 20%;" class="text-center">
                      {{gymMembershipClientAlertLog.ActionDate | date: 'yyyy-MM-dd'}}
                    </td>

                    <td style="width: 20%;" class="text-center">
                      {{gymMembershipClientAlertLog.ActionDate |date: 'hh:mm a'}}
                    </td>

                    <td style="width: 20%;" class="text-center">
                      {{ (gymMembershipClientAlertLog.AlertStatus == alertStatus.Success ?
                      ('AlertStatusSuccess' | translate) :gymMembershipClientAlertLog.AlertStatus ==
                      alertStatus.Fail ? ('AlertStatusFail' | translate) : '' ) }}
                    </td>

                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="formCard"
          *ngIf="gymMembership.GymClientMembershipClientChangeLogs && gymMembership.GymClientMembershipClientChangeLogs.length > 0">
          <br>
          <h5 class="modal-title text-capitalize" id="exampleModalLabel">
            <i class="fas fa-solid fa-user px-2"></i> {{'GymClientMembershipClientChangeLogs' |
            translate}}
          </h5>
          <br>
          <div class="formCard">
            <div>
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th style="width: 20%;" class="text-center">{{ 'Index' | translate }}</th>
                    <th style="width: 20%;" class="text-center">{{ 'OldClientName' | translate }}</th>
                    <th style="width: 20%;" class="text-center">{{ 'NewClientName' | translate }}</th>
                    <th style="width: 20%;" class="text-center">{{ 'AddUserName' | translate }}</th>
                    <th style="width: 20%;" class="text-center">{{ 'ChangeDate' | translate }}</th>
                  </tr>
                </thead>
                <tbody
                  *ngFor="let gymClientMembershipClientChangeLog of gymMembership.GymClientMembershipClientChangeLogs; let i=index">
                  <tr>

                    <td style="width: 20%;" class="text-center">
                      {{ i+1 }}
                    </td>

                    <td style="width: 20%;" class="text-center">
                      {{ gymClientMembershipClientChangeLog.OldClientName}}
                    </td>

                    <td style="width: 20%;" class="text-center">
                      {{ gymClientMembershipClientChangeLog.NewClientName}}
                    </td>

                    <td style="width: 20%;" class="text-center">
                      {{ gymClientMembershipClientChangeLog.AddUserName}}
                    </td>

                    <td style="width: 20%;" class="text-center">
                      {{gymClientMembershipClientChangeLog.ChangeDate | date: 'yyyy-MM-dd'}}
                    </td>

                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>


        <div class="formCard" *ngIf="gymMembership.GymClientMembershipClientChangeLogs &&
            gymMembership.GymClientMembershipClientChangeLogs.length > 0">
          <br>
          <h5 class="modal-title text-capitalize" id="exampleModalLabel">
            <i class="fas fa-pause px-2"></i> {{'GymMembershipFreezInfo' | translate}}
          </h5>
          <br>
          <div class="formCard">
            <div>
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th style="width: 20%;" class="text-center">{{ 'Index' | translate }}</th>
                    <th style="width: 80%;" class="text-center">{{ 'FrozenDaysStartDate' | translate
                      }}</th>
                    <th style="width: 80%;" class="text-center">{{ 'FrozenDaysEndDate' | translate }}
                    </th>
                    <th style="width: 80%;" class="text-center">{{ 'FreezDaysCount' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody
                  *ngFor="let gymClientMembershipFrozenDaysLog of gymMembership.GymClientMembershipClientChangeLogs; let i=index">
                  <tr>
                    <td style="width: 25%;" class="text-center">
                      {{ i+1 }}
                    </td>
                    <td style="width: 25%;" class="text-center">
                      {{ gymClientMembershipFrozenDaysLog.FrozenDaysStartDate | date :'yyyy-MM-dd' }}
                    </td>
                    <td style="width: 25%;" class="text-center">
                      {{ gymClientMembershipFrozenDaysLog.FrozenDaysEndDate | date :'yyyy-MM-dd' }}
                    </td>
                    <td style="width: 25%;" class="text-center">
                      {{ gymClientMembershipFrozenDaysLog.FrozenDaysCount }}
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>

      <div class="col-lg-12" *ngIf="!gymMembership">
        <div class="col-md-12 text-center py-4 my-4">
          <i class="fas fa-circle-notch fa-spin fa-3x"></i>
          <h1>{{'Loading' | translate}} </h1>
        </div>
      </div>

    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">
      {{'Close' | translate}}</button>
    <button type="button" class="btn btn-danger" (click)="printPage()">{{'Print' |
      translate}}</button>
  </div>
</div>
