import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { SharedService } from '../../../services/other/shared.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BookingInfoDTO } from 'src/app/models/classes/booking/booking-info';

@Component({
  selector: 'app-print-booking-bill',
  templateUrl: './print-booking-bill.component.html',
  styleUrls: ['./print-booking-bill.component.scss']
})
export class PrintBookingBillComponent implements OnInit {

  @Input() public readonly booking: BookingInfoDTO;

  @Input() public readonly AccountSetup;


  constructor(
    public sharedService: SharedService,
    public activeModal: NgbActiveModal,

  ) { }

  ngOnInit(): void {



  }




}
