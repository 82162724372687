<div class="modal-header">
    <h5 class="modal-title text-capitalize" id="exampleModalLabel">
        <i class="fas fa-image"></i>
        {{ModalTitle | translate }} - {{Name}}
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">

    <div class="row" *ngIf="CurrentModalPictureType == modalPictureType.User">
        <div class="col-lg-12 text-center">
            <label class=" text-center">
                <div class="text-info text-center" style="font-size: 15px;font-weight: bolder;">
                    {{'UserPicture-EditAllowedFor(Admin,Manager,MainBranch,UserOwnsThePicture)' | translate}}
                </div>
            </label>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 text-center">
            <label class="hoverable" for="fileInput">
                <img [src]="PicturePath && 
                PicturePath.length > 0 ? 
                PicturePath : 
                'assets/img/img_avatar.png'">
                <div class="hover-text">{{'ChoosePicture' | translate}}</div>
                <div class="background"></div>
            </label>
            <br />
            <input id="fileInput" type='file' (change)="onSelectFile($event)" accept=".png, .jpg,.jpeg">



            <button class="btn btn-success" *ngIf="PicturePath && 
            PicturePath.length > 0" (click)="DeleteAnyPicture()">
                {{DeletePicture |
                translate}}</button>
            <br />
        </div>
        <div class="col-lg-12 text-center" *ngIf="!PicturePath ||  
                                 PicturePath.length <= 0">
            <label class=" text-center">
                <div class="text-danger" style="font-size: 15px;font-weight: bolder;">
                    {{NoPicture | translate}}</div>
            </label>
        </div>


    </div>
</div>
<div class="modal-footer">
    <button type="button" [disabled]="Loading" class="btn btn-success" (click)="SaveAnyPicture()">
        {{(Loading ? LoadingSavePicture : SavePicture) | translate}}</button>
    <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">
        {{'Close' | translate}}</button>
</div>