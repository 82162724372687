import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { Category } from '../../models/classes/product/category';
import { ServiceGroupingDetail } from '../../models/classes/service/service-grouping-detail';
import { CategorySearchCriteria } from '../../models/search-criterias/category-search-criteria';
import { CategorySearchResult } from '../../models/search-result/category-search-result';
import { GlameraHomeResult } from '../../models/search-result/glamera-home-result';
import { ExecutionResponse } from '../../models/support/execution-response';
import { ValidationCriteria } from '../../models/support/validation-criterias/validation-criteria';
@Injectable({ providedIn: 'root' })
export class CategoryService extends BaseService {
  private categoriesUrl //= super.BaseUrl() + 'Category';  // URL to web api

  constructor(
    private http: HttpClient,
    private  _BaseService:BaseServiceSZ
  ) {
    super();
    this.categoriesUrl =    this._BaseService.ApiUrl + 'Category';

  }

  IsNameUnique(validationCriteria: ValidationCriteria): Observable<ExecutionResponse<Boolean>> {
    validationCriteria.AccountSetupId = super.getAccountSetupId();
    return this.http.post<ExecutionResponse<Boolean>>(this.categoriesUrl + '/IsNameUnique', validationCriteria, super.getHeaders()).pipe(
      tap(_ => this._BaseService.log('deleted Category id=${id}')),
      catchError(this._BaseService.handleError<ExecutionResponse<Boolean>>('deleteCategory'))
    );
  }

  // /** GET categories from the server */
  // getCategories(): Observable<Category[]> {
  //   return this.http.get<Category[]>(this.categoriesUrl, super.getHeaders())
  //     .pipe(
  //       tap(categories => this._BaseService.log('fetched categories')),
  //       catchError(this._BaseService.handleError('getCategories', []))
  //     );
  // }

  // /** GET category by id. Return 'undefined' when id not found */
  // getCategoryNo404<Data>(id: number): Observable<Category> {
  //   const url = '${this.categoriesUrl}/?id=${id}';
  //   return this.http.get<Category[]>(url)
  //     .pipe(
  //       map(categories => categories[0]), // returns a {0|1} element array
  //       tap(h => {
  //         const outcome = h ? 'fetched' : 'did not find';
  //         this._BaseService.log('${outcome} category id=${id}');
  //       }),
  //       catchError(this._BaseService.handleError<Category>('getCategory id=${id}'))
  //     );
  // }

  /** GET category by id. Will 404 if id not found */
  getCategory(id: String): Observable<ExecutionResponse<Category>> {
    //  getCategory(id: String): ExecutionResponse<Category> {
    //const url = '${this.categoriesUrl}/${id}';
    return this.http.post<ExecutionResponse<Category>>(this.categoriesUrl + '/getById', id, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched category id=${id}');
          this._BaseService.ValidationResult(res);
      }),
        
        catchError(this._BaseService.handleError<ExecutionResponse<Category>>('getCategory id=${id}'))
      );
  }


  addCategory(category: Category): Observable<ExecutionResponse<Category>> {
    if(!category.AccountSetupId){
      category.AccountSetupId = super.getAccountSetupId();
    }
    return this.http.post<ExecutionResponse<Category>>(this.categoriesUrl + '/Create', category, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('added category w/ id=${category.Id}');
          this._BaseService.ValidationResult(res);
      }),
       
        catchError(this._BaseService.handleError<ExecutionResponse<Category>>('addCategory id=${id}'))
      );
  }


  /* GET categories whose name contains search term */
  searchCategories(categorySearchCriteria: CategorySearchCriteria): Observable<CategorySearchResult> {
    categorySearchCriteria.AccountSetupId = super.getAccountSetupId();
    return this.http.post<CategorySearchResult>(this.categoriesUrl + '/Get', categorySearchCriteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched categories');
          this._BaseService.ValidationResult(res);
      }),
        catchError(this._BaseService.handleError<CategorySearchResult>('searchCategories id=${id}'))
      );
  }


  
  /* GET searchGlameraCategoriesAndOffers whose name contains search term */
  searchGlameraCategoriesAndOffers(categorySearchCriteria: CategorySearchCriteria): Observable<ExecutionResponse<GlameraHomeResult>> {
    categorySearchCriteria.AccountSetupId = super.getAccountSetupId();
    return this.http.post<ExecutionResponse<GlameraHomeResult>>(this.categoriesUrl + '/GlameraCategoriesAndOffers', categorySearchCriteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched categories');
          this._BaseService.ValidationResult(res);
      }),
        catchError(this._BaseService.handleError<ExecutionResponse<GlameraHomeResult>>('searchCategories id=${id}'))
      );
  }

   /* GET searchGlameraServices whose name contains search term */
   
   searchGlameraServices(categorySearchCriteria: CategorySearchCriteria): Observable<ExecutionResponse<ServiceGroupingDetail[]>> {
    categorySearchCriteria.AccountSetupId = super.getAccountSetupId();
    
    return this.http.post<ExecutionResponse<ServiceGroupingDetail[]>>(this.categoriesUrl + '/GlameraServices', categorySearchCriteria, super.getHeaders())
      .pipe(
        tap(res => {
          
          this._BaseService.log('fetched categories');
          this._BaseService.ValidationResult(res);
          return res;
      }),
        catchError(this._BaseService.handleError<ExecutionResponse<ServiceGroupingDetail[]>>('searchCategories id=${id}'))
      );
  }
  /** DELETE: delete the category from the server */
  deleteCategory(category: Category | number): Observable<ExecutionResponse<Category>> {
    return this.http.post<ExecutionResponse<Category>>(this.categoriesUrl + '/delete', category, super.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deleted category id=${id}');
        this._BaseService.ValidationResult(res);
    }),
     
      catchError(this._BaseService.handleError<ExecutionResponse<Category>>('deleteCategory'))
    );
  }

  /** PUT: update the category on the server */
  updateCategory(category: Category): Observable<ExecutionResponse<Category>> {
    category.AccountSetupId = super.getAccountSetupId();
    return this.http.post<ExecutionResponse<Category>>(this.categoriesUrl + '/Update', category, super.getHeaders())
      .pipe(
        tap(res=> {
          this._BaseService.log('added category w/ id=${category.Id}');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<ExecutionResponse<Category>>('addCategory id=${id}'))
      );
  }

  
  
}