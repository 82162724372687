<section fxLayout="row" fxLayoutAlign="start stretch">
  <div class="slider" fxFlex="40" fxHide fxShow.gt-sm>
    <img
      class="logo"
      src="assets/img/glameraTradeMarkLogo.png"
      width="190px"
      alt=""
      title=""
    />
    <main fxLayout="column" fxLayoutAlign="start start">
      <h1>{{ "join_glamera_solution" | translate }} <br /></h1>
      <ul style="list-style-type: none">
        <li class="d-flex align-items-center my-3">
          <img src="assets/icons/Screen.svg" alt="report" width="40px" />
          <p class="get-help p-0 m-0 mx-2">
            {{ "join_glamera_solution_1" | translate }}
          </p>
        </li>
        <li class="d-flex align-items-center my-3">
          <img src="assets/icons/Payment.svg" alt="report" width="40px" />
          <p class="get-help p-0 m-0 mx-2">
            {{ "join_glamera_solution_2" | translate }}
          </p>
        </li>
        <li class="d-flex align-items-center my-3">
          <img src="assets/icons/Conniesion.svg" alt="report" width="40px" />
          <p class="get-help p-0 m-0 mx-2">
            {{ "join_glamera_solution_3" | translate }}
          </p>
        </li>
        <li class="d-flex align-items-center my-3">
          <img src="assets/icons/report.svg" alt="report" width="40px" />
          <p class="get-help p-0 m-0 mx-2">
            {{ "join_glamera_solution_4" | translate }}
          </p>
        </li>
      </ul>
    </main>
  </div>
  <form
    fxFlex="60"
    fxFlex.lt-md="100"
    fxLayout="column"
    fxLayoutAlign="start stretch"
    [formGroup]="form"
    (submit)="onSubmit()"
  >
    <lib-language></lib-language>
    <main>
      <h1 class="mt-3">
        {{ "ChooseTheTypeOfBusiness" | translate }}
      </h1>
      <p class="get-start">{{ "GET_START" | translate }}</p>
    </main>

    <div fxLayout="row wrap" fxLayoutAlign="space-between center">
      <div fxLayout="column" fxLayoutAlign="space-between stretch" fxFlex="100">
        <mat-label class="label">{{ "ActivityName" | translate }}</mat-label>
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="{{ 'EnterYourBusinessName' | translate }}"
            formControlName="BusinessName"
          />
          <mat-error>
            <app-error-form
              [name]="'ActivityName' | translate"
              [control]="form.controls['BusinessName']"
              [maxLength]="30"
              [minLength]="4"
            ></app-error-form>
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="space-between center">
      <div
        fxLayout="column"
        fxLayoutAlign="space-between stretch"
        fxFlex.gt-sm="45"
        fxFlex.lt-md="100"
      >
        <mat-label class="label">{{ "Country" | translate }}</mat-label>
        <mat-form-field appearance="outline">
          <mat-select
            disableRipple
            formControlName="CountryId"
            (selectionChange)="onChangeCountry($event)"
            [placeholder]="'Select_Country' | translate"
          >
            <mat-option
              *ngFor="let country of countries"
              [value]="country.Id"
              >{{ country.GlobalName }}</mat-option
            >
          </mat-select>
          <mat-error>
            <app-error-form
              [name]="'Country' | translate"
              [control]="form.controls['CountryId']"
            ></app-error-form>
          </mat-error>
        </mat-form-field>
      </div>

      <div
        fxLayout="column"
        fxLayoutAlign="space-between stretch"
        fxFlex.gt-sm="45"
        fxFlex.lt-md="100"
      >
        <mat-label class="label">{{ "City" | translate }}</mat-label>
        <mat-form-field appearance="outline">
          <mat-select
            disableRipple
            formControlName="CityId"
            [placeholder]="'Select_city' | translate"
          >
            <mat-option *ngFor="let city of cities" [value]="city.Id">{{
              city.GlobalName
            }}</mat-option>
          </mat-select>
          <mat-error>
            <app-error-form
              [name]="'City' | translate"
              [control]="form.controls['CityId']"
            ></app-error-form>
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="business-type" *ngIf="businessType?.length">
      <div
        fxLayout="column"
        fxLayoutAlign="space-between stretch"
        fxFlex.gt-sm="100"
        fxFlex.lt-md="100"
      >
        <p>{{ "BusinessType" | translate }}</p>

        <div class="gap-30" fxLayout="row wrap" fxLayoutAlign="start start">
          <div
            *ngFor="let type of businessType"
            fxLayout="row"
            fxLayoutAlign="start center"
            class="b-type gap-5"
            (click)="onSelectBusinessType(type)"
            fxFlex.gt-sm="20"
            fxFlex.lt-md="100"
            [ngClass]="{
              'not-allowed': notAllowedBusinessTypes.includes(
                type?.BusinessType
              )
            }"
          >
            <img
              [src]="type.active ? type.ImageSelectedPath : type.ImagePath"
              [alt]="type.GlobalName"
              [title]="type.GlobalName"
            />
            <span>{{ type.GlobalName }}</span>
          </div>
        </div>
      </div>
    </div>

    <div
      class="know-us"
      fxLayout="row wrap"
      fxLayoutAlign="space-between center"
    >
      <div
        fxLayout="column"
        fxLayoutAlign="space-between stretch"
        fxFlex.gt-sm="45"
        fxFlex.lt-md="100"
      >
        <mat-label class="label"
          >{{ "HOW_DID_U_KNOW_US" | translate }}
        </mat-label>
        <mat-form-field appearance="outline">
          <mat-select
            disableRipple
            formControlName="KnowUsWayId"
            [placeholder]="'Select_one' | translate"
          >
            <mat-option *ngFor="let item of knowUsWays" [value]="item.Id">
              <img
                [src]="item?.ImagePath ?? ''"
                alt=""
                class="img-icon"
                [title]="item.GlobalName"
              />
              {{ item.GlobalName }}</mat-option
            >
          </mat-select>
          <mat-error>
            <app-error-form
              [name]="'HOW_DID_U_KNOW_US' | translate"
              [control]="form.controls['KnowUsWayId']"
            ></app-error-form>
          </mat-error>
        </mat-form-field>
      </div>

      <div
        fxLayout="column"
        fxLayoutAlign="start stretch"
        fxFlex.gt-sm="45"
        fxFlex.lt-md="100"
      >
        <label class="mb-2 weight-400">
          {{ "ARE_U_CURRENTLY_USING_SYSTEM" | translate }}
        </label>
        <mat-radio-group formControlName="IsCurrentlyUsingAnyOtherSystem">
          <mat-radio-button [value]="true" color="primary">{{
            "Yes" | translate
          }}</mat-radio-button>
          <mat-radio-button class="mlr-30" [value]="false" color="primary">{{
            "No" | translate
          }}</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="end start">
      <button
        mat-raised-button
        color="primary"
        type="submit

  "
        class="submit-btn"
        [disabled]="form.invalid || loading"
      >
        {{ "Create_Account" | translate }}
      </button>
    </div>
  </form>
</section>
