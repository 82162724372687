<section fxLayout="row" fxLayoutAlign="start stretch">
  <div class="slider login-slider" fxFlex="50" fxHide fxShow.gt-sm>
    <div class="overlay">
      <img
        class="glamera_logo"
        src="assets/img/glamera_logo.svg"
        alt=""
        title=""
        loading="lazy"
      />
    </div>
  </div>
  <form
    fxFlex="50"
    fxFlex.lt-md="100"
    fxLayout="column"
    fxLayoutAlign="start stretch"
    [formGroup]="form"
    (submit)="onSubmit()"
  >
    <lib-language></lib-language>
    <main>
      <h1>
        {{ "WELCOME" | translate }}
        <span>{{ "GLAMERA" | translate }}</span>
        <img src="assets/img/auth_path.jpg" title="" alt="" />
      </h1>
      <p>
        {{ "LOGIN_YOUR_ACCOUNT" | translate }} <br />
        {{ "TO_WORK" | translate }}
      </p>
    </main>

    <mat-label class="label">{{ "AUTH.PHONE_NUMBER" | translate }}</mat-label>
    <mat-form-field appearance="outline">
      <input
        matInput
        [placeholder]="'PHONE_NUMBER' | translate"
        formControlName="UserName"
      />
      <mat-error>
        <app-error-form
          [name]="'AUTH.PHONE_NUMBER'"
          [control]="form.controls['UserName']"
          [minLength]="4"
        ></app-error-form>
      </mat-error>
    </mat-form-field>

    <mat-label class="label">{{ "AUTH.PASSWORD" | translate }}</mat-label>
    <mat-form-field appearance="outline">
      <input
        matInput
        [type]="hide ? 'password' : 'text'"
        formControlName="Password"
        [placeholder]="'PASSWORD' | translate"
      />
      <button
        mat-icon-button
        matSuffix
        (click)="hide = !hide"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hide"
        type="button"
      >
        <img
          [src]="hide ? 'assets/img/close_eye.svg' : 'assets/img/close_eye.svg'"
          alt=""
          title=""
          class="mt__5"
        />
      </button>

      <mat-error>
        <app-error-form
          [name]="'AUTH.PASSWORD'"
          [control]="form.controls['Password']"
          [minLength]="4"
        ></app-error-form>
      </mat-error>
    </mat-form-field>
    <div fxLayout="row" fxLayoutAlign="end center">
      <a [routerLink]="['/'+config.routes.RESET_PASSWORD]">{{
        "FORGET_PASSWORD" | translate
      }}</a>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between center" class="remember">
      <!-- <mat-checkbox class="example-margin" [color]="'primary'">{{
        "REMMEMBER_ME" | translate
      }}</mat-checkbox> -->

      <button
        mat-raised-button
        color="primary"
        type="submit"
        class="submit-btn"
        [disabled]="form.invalid || loading" 
      >
        {{ "LOGIN" | translate }}
      </button>
    </div>

    <div class="signUp">
      <p>
        {{ "DONT_HAVE_ACCOUNT" | translate
        }}<a [href]="'/'+config.routes.SIGNUP">{{
          "SIGN_UP" | translate
        }}</a>
      </p>
    </div>
  </form>
</section>
