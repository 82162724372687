import { SearchCriteria } from "./search-criteria";
import { ResourceStatus } from '../enums/resource-status';
import { ServiceType } from "../enums/service-type";

export class ServiceSearchCriteria extends SearchCriteria {
    constructor() {
        super();
        this.ServiceTypes = [];
    }
    //public Id:number;
    public Ids:number[];
    public Description: string;
    public Code: string;
    public CategoryId: number;
    public Status: ResourceStatus;
    //public AccountSetupId: number;
    public ServiceTypes:ServiceType[];
    public GetOnlyThatUseFrozenDays: boolean;
}
