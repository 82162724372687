import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ClientBalanceService } from '../../services/balances/client-balance.service';
import { SharedService } from '../../services/other/shared.service';
import { TransactionsSecondService } from '../../services/transaction/transactions-second.service';
import { GiftCardInformationService } from 'src/app/services/gift-card/gift.card.information.service';
import { ToastrService } from 'ngx-toastr';
import { AttachmentsComponent } from '../attachments/attachments.component';
import { Attachment } from 'src/app/models/classes/transactions/attachment';
import { ResponseState } from 'src/app/models/support/response-state';
import { ModalUploadAttachementsComponent } from '../modal-upload-attachements/modal-upload-attachements.component';
import { EmployeeService } from 'src/app/dashboard/employees/service/employee.service';
import { AdminService } from 'src/app/dashboard/admin/service/admin.service';
import { UploadImageResult } from 'src/app/models/search-result/upload-image-result';
import { NationalityDTO } from 'src/app/models/classes/registration-cycle/nationality-dto';
import { GetNationalitiesCriteria } from 'src/app/models/search-criterias/registration-cycle/get-nationalities-criteria';
import { RegistrationCyclePhaseOneService } from 'src/app/services/registration-cycle/registration-cycle-phase-one.service';
import { ExecutionResponse } from 'src/app/models/support/execution-response';
import { UploadType } from 'src/app/models/enums/upload-type';

@Component({
  selector: 'app-modal-add-update-employee',
  templateUrl: './modal-add-update-employee.component.html',
  styleUrls: ['./modal-add-update-employee.component.scss'],
  providers: [DatePipe],
})
export class ModalAddUpdateEmployeeComponent implements OnInit {

  @Input() EmployeeId: number;
  public selectedTab;
  public FilesList: string[] = [];
  public attachmentsFiles: Attachment[] = [];
  @Output() AddedOrUpdated = new EventEmitter<boolean>();
  @ViewChild('attachmentsComponent', { static: false })
  attachmentsComponent: AttachmentsComponent;
  public LoadingGetEmployee: boolean;
  public UploadImageResultList: UploadImageResult[] = [];
  Attachments: UntypedFormArray;
  GForm: UntypedFormGroup;
  freeTextGetPettyCashItems: string = null;
  autoCompleteAreas: any;
  public CurrentNationalityId: number;
  public Nationalities: NationalityDTO[];
  public NationalitiesSearchText: string;
  public hiringDate;

  public accountPayments: any;

  constructor(
    public activeModal: NgbActiveModal,
    public translate: TranslateService,
    public sharedService: SharedService,
    public _transactionsSecondService: TransactionsSecondService,
    private fb: UntypedFormBuilder,
    public datepipe: DatePipe,
    private phaseOneService: RegistrationCyclePhaseOneService,
    private toastr: ToastrService,
    private adminService: AdminService,

    private employeeService: EmployeeService,
    private ngbModal: NgbModal,
    public toaster: ToastrService,
    public giftCardInformationService: GiftCardInformationService,
    public clientBalanceService: ClientBalanceService
  ) { }

  ngOnInit(): void {

    this.selectedTab = 2;

    this.GetCachedDropdowns();
    this.GetAllPositions();
    this.GetAllDepartments();
    this.GetDataAttendMethodMasters();
    this.GFormDate();
    this.GetAreas();
    this.GetNationalities();

    if (this.EmployeeId && this.EmployeeId > 0) {
      this.GetEmployee(this.EmployeeId);
    }
    else {
      this.GenerateEmployeeCode()
    }

  }
  GenerateEmployeeCode() {

    this.employeeService.GenerateEmployeeCode().subscribe((response: any) => {
      if (response.State == ResponseState.Success) {
        this.GForm.get('EmployeeCode').setValue(response.Result);




      }
    });
  }

  EmployeePositions;
  GetAllPositions() {
    let JsonObj = { ForAutoComplete: 1, FreeText: '', AccountSetupId: this.sharedService.AccountSetupId }
    this.employeeService.GetAllPositions(JsonObj).subscribe((response: any) => {
      if (response.State == ResponseState.Success) {
        this.EmployeePositions = response.EmployeePositions
      }
      else {
        this.toastr.error(response.Message)
      }
    });
  }

  GetNationalities() {

    var criteria = new GetNationalitiesCriteria();
    criteria.PagingEnabled = true;
    criteria.PageNumber = 0;
    criteria.PageSize = 300;
    criteria.FreeText = this.NationalitiesSearchText;

    this.phaseOneService
      .GetNationalities(criteria)
      .subscribe((response: ExecutionResponse<NationalityDTO[]>) => {

        if (response.State == ResponseState.Success) {

          this.Nationalities = response.Result;
        }
      });
  }

  Departments;
  GetAllDepartments() {
    let JsonObj = { ForAutoComplete: 1, FreeText: '', AccountSetupId: this.sharedService.AccountSetupId }
    this.employeeService.GetAllDepartments(JsonObj).subscribe((response: any) => {
      if (response.State == ResponseState.Success) {
        this.Departments = response.Departments
      }
      else {
        this.toastr.error(response.Message)
      }
    });
  }

  GetAreas() {

    let criteria = {
      AccountSetupId: this.sharedService.AccountSetupId,
    };

    this.adminService.GetFPAreas(criteria).subscribe((response: any) => {
      this.autoCompleteAreas = response.FingerprintDeviceAreas;
      if (this.autoCompleteAreas.length > 0 && this.GForm && this.GForm.get('Id').value == 0) {
        this.GForm.get('FingerprintAreaIds').patchValue(
          [this.autoCompleteAreas[0].Id]
        );
      }
    });

  }


  get VForm() { return this.GForm.controls; }

  EmployeeName: any;
  CheckcountryId: number
  checkcountryCode(i) {


    this.Cities = i.Cities

    if (i.dbId) {
      if (i.dbId == 1938) { this.CheckcountryId = 14; }
      else if (i.dbId == 1939) { this.CheckcountryId = 10; }
      else { this.CheckcountryId = 15; }
    } else {
      this.GForm.get('IdentityNumber').clearValidators();
    }
  }

  AttendMethodMasters: any;
  GetDataAttendMethodMasters() {


    let jsonObj =
    {
      AccountSetupId: this.sharedService.AccountSetupId,
      /*  PageSize: this.pager.pageSize,
       PagingEnabled: false,
       PageNumber: this.pager.GlobalPageIndex, */
      FreeText: '',
    }
    this.employeeService.GetAttendMethodMaster(jsonObj).subscribe((response: any) => {
      if (response.State == ResponseState.Success) {
        this.AttendMethodMasters = response.AttendMethodMasters;
      }
    });
  }

  employee
  GetEmployee(employeeId: number) {
    this.LoadingGetEmployee = true;
    return this.employeeService.getEmployee(employeeId).subscribe((response: any) => {
      if (response.State == ResponseState.Success) {
        debugger
        this.employee = response.Result;
        this.hiringDate = new Date(this.employee.HiringDate);
        this.GForm.patchValue(this.employee);
        this.attachmentsFiles = this.employee.Attachments ?? [];
      }
      this.LoadingGetEmployee = false;
    });
  }

  CreateData() {

    if (this.attachmentsFiles) {

      this.Attachments = this.GForm.get('Attachments') as UntypedFormArray;

      this.Attachments.clear();

      this.attachmentsFiles.forEach((attachmentsFile) => {
        this.Attachments.push(
          this.fb.group({
            Id: attachmentsFile.Id,
            EmployeeId: attachmentsFile.EmployeeId,
            FolderName: attachmentsFile.FolderName,
            FileName: attachmentsFile.FileName,
            FileType: attachmentsFile.FileType,
            FilePath: attachmentsFile.FilePath,
            FileNotes: attachmentsFile.FileNotes,
            AttachmentTypeId: attachmentsFile.AttachmentTypeId,
            ExpirationDate: attachmentsFile.ExpirationDate,
          })
        );
      });
    }

    this.GForm.get('HiringDate').setValue(this.hiringDate);

    if (this.GForm.value.Id == 0) {
      this.Create();
    }
    else {
      this.Update();
    }
  }

  openModalUploadAttachements() {

    const modalRef = this.ngbModal.open(ModalUploadAttachementsComponent, {
      windowClass: 'myCustomModalClasMinWidth80',
    });

    modalRef.componentInstance.attachmentsFiles = this.attachmentsFiles;
    modalRef.componentInstance.UploadType = UploadType.Employees;
    modalRef.componentInstance.CloseModal.subscribe((result: Attachment[]) => {

      if (result) {
        this.attachmentsFiles = result;
      }

    });

  }

  onSearch(e) {
    this.freeTextGetPettyCashItems = e?.term ?? null;
  }

  btnLoading = false;

  GFormDate() {

    this.GForm = this.fb.group({
      Id: 0,
      AccountSetupId: this.sharedService.AccountSetupId,
      // Image: null,
      NameEn: ['', Validators.required],
      NameAr: ['', Validators.required],
      HiringDate: null,
      Phone: [''],
      FingerprintCode: [''],
      HasFingerprint: [''],
      MobileNumber: ['', Validators.compose([Validators.minLength(10), Validators.maxLength(14)])],
      Status: 1,
      ReviewStatus: 1,
      Email: ['', [Validators.pattern("^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$")]],
      AreaId: [''],
      CityId: [''],
      CountryId: new UntypedFormControl(),
      PostCode: [''],
      IdentityType: [''],
      BirthDate: null,
      ShowInBookingScreen: [''],
      AddressEn: [''],
      AddressAr: [''],
      EmployeeCode: ['', Validators.required],
      FingerprintAreaIds: [''],

      EmployeePictureFolder: null,
      EmployeePictureName: null,
      EmployeePicturePath: null,

      UpdateTemp: 0,
      ShowOnline: false,
      IsCashier: false,
      HasFingerprintCode: false,
      OnlineDescription: [''],
      OnlineDetails: [''],
      // applyToAllFlag: true,
      AttendMethodMasterId: [''],
      DepartmentId: [''],
      PositionsId: [],
      NationalityId: [],
      Salary: null,
      BankAccountNumber: null,
      IdentityNumber: new UntypedFormControl(),
      Attachments: this.fb.array([])
    });
  }


  get Status() {
    return this.GForm.get('Status');
  }
  get NameEn() {
    return this.GForm.get('NameEn')
  }
  get NameAr() {

    return this.GForm.get('NameAr')
  }


  public DeleteEmployeePicture() {

    this.GForm.get('EmployeePicturePath').patchValue(null);
    this.GForm.get('EmployeePictureFolder').patchValue(null);
    this.GForm.get('EmployeePictureName').patchValue(null);

    this.sharedService.ToastrSuccess(
      this.translate.instant('DoneDeleteEmployeePictureSuccessfully.')
    );
  }


  onSelectFile(event) {

    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event) => {
        //this.url = event.target.result;
        this.GForm.get('EmployeePicturePath').patchValue(event.target.result);
      };

      this.FilesList.push(event.target.files[0]);

      const formData = new FormData();
      formData.append('ImagesFolder', 'Pictures');
      for (var x = 0; x < this.FilesList.length; x++) {
        formData.append('Files', this.FilesList[x]);
      }
      return this.adminService
        .PostImageFile(formData)
        .subscribe((response: any) => {
          if (response) {
            this.UploadImageResultList = response.Result;

            this.GForm.get('EmployeePictureName').patchValue(
              this.UploadImageResultList[0].FileName
            );
            this.GForm.get('EmployeePictureFolder').patchValue(
              this.UploadImageResultList[0].FolderName
            );

            this.sharedService.ToastrSuccess(
              this.translate.instant('DoneSetEmployeePictureSuccessfully.')
            );
          } else {
            this.sharedService.ToastrError(response.Message);
          }
        });
    }
  }

  changeStatus(e) {


    if (e.target.checked) {
      this.GForm.controls.Status.setValue(1);


    } else {
      this.GForm.controls.Status.setValue(0);

    }

  }


  Dropdowns;
  Countries;
  Cities;
  Areas;
  identityType;
  GetCachedDropdowns() {
    let JsonObj = { "MasterCodeId": "4" }
    this.adminService.GetCachedDropdowns(JsonObj).subscribe((response: any) => {
      if (response.State == ResponseState.Success) {
        console.log(response);
        ;
        this.Dropdowns = response.Dropdowns
        this.Countries = this.Dropdowns.Countries
        this.Cities = this.Dropdowns.Cities
        this.Areas = this.Dropdowns.Areas
        this.identityType = this.Dropdowns.IdentityType
      }
      else {
        this.toastr.error(response.Message)
      }
    });
  }

  checkCityCode(i) {

    this.Areas = i.Areas

  }
  get CountryId() {
    return this.GForm.get('CountryId') as UntypedFormControl;
  }

  divName;
  printPage() {
    this.divName = 'printClientGiftCardInfo';
    console.log(this.divName);

    let printContents = document.getElementById(this.divName).innerHTML;
    let originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
    // this.attachmentsFiles = this.attachmentsComponent.attachmentsFiles;
  }

  closeModal() {
    this.activeModal.close('Close click');
  }


  Create() {

    this.btnLoading = true;
    this.GForm.controls.AccountSetupId.setValue(this.sharedService.AccountSetupId);

    this.GForm.get('AreaId').setValue(this.selectedArea);
    this.GForm.get('IdentityType').setValue(this.selectedIdentityType);

    this.employeeService.CreateEmployees(this.GForm.value).subscribe((response: any) => {
      if (response.State == ResponseState.Success) {
        this.sharedService.ToastrSuccess(response.Message);
        this.sharedService.HideModal();
        this.GForm.reset();
        this.AddedOrUpdated.emit(true);
        this.closeModal();
        this.btnLoading = false;
      }
      else {
        this.sharedService.ToastrError(response.Message)
      }
      this.btnLoading = false;
    });
  }

  selectedArea: any;
  onChangeArea(event) {
    this.selectedArea = event.dbId;
  }

  selectedIdentityType: any;
  onChangeidentityType(event: any) {
    this.selectedIdentityType = event.target.value;
  }

  Update() {

    this.btnLoading = true;
    this.GForm.controls.AccountSetupId.setValue(this.sharedService.AccountSetupId);
    this.GForm.get('AreaId').setValue(this.selectedArea);
    this.GForm.get('IdentityType').setValue(this.selectedIdentityType);
    this.GForm.get('UpdateTemp').setValue(this.selectedIdentityType);

    this.employeeService.UpdateEmployees(this.GForm.value).subscribe((response: any) => {
      if (response.State == ResponseState.Success) {
        this.sharedService.ToastrSuccess(response.Message);
        this.sharedService.HideModal();
        this.GForm.reset();
        this.AddedOrUpdated.emit(true);
        this.closeModal();
        this.btnLoading = false;
      }
      else {
        this.sharedService.ToastrError(response.Message)
      }
      this.btnLoading = false;
    });
  }


}
