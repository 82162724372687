<section fxLayout="column" fxLayoutAlign="center center">
  <main fxLayout="column" fxLayoutAlign="start stretch">
    <img
      src="assets/img/1103-confetti-outline-trans.gif"
      trigger="hover"
      style="width: 250px; height: 250px; margin: 0 auto"
    />
    <h1>{{ "Congratulations" | translate }}</h1>
    <p>{{ "YourAccountSuccessfullyCreated" | translate }}</p>
    <p>{{ "NOW_U_CAN" | translate }}</p>
  </main>
</section>
