import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { GymMembershipAttendanceService } from 'src/app/dashboard/gym/services/gym-membership-attendance.service';
import { GymActivityType } from 'src/app/models/enums/gym-activity-type';
import { PeriodStatus } from 'src/app/models/enums/gym-membership-period-status';
import { ClientGymMembershipService } from 'src/app/services/gym/client-gym-membership.service';

@Component({
  selector: 'app-client-attendance',
  templateUrl: './client-attendance.component.html',
  styleUrls: ['./client-attendance.component.scss']
})
export class ClientAttendanceComponent implements OnInit {

  @ViewChild('seconds', { static: false }) seconds: ElementRef;

  @Input() clientId: number;

  client: any;
  Memberships: any;
  public seconds10: number = 2;
  public tickTock: any;

  public get periodStatus(): typeof PeriodStatus {
    return PeriodStatus;
  }
  constructor(
    public toaster: ToastrService,
    public translate: TranslateService,
    private gymMembershipAttendanceService: GymMembershipAttendanceService,
    public activeModal: NgbActiveModal,
    private ngbModal: NgbModal,
    private clientGymMembershipService: ClientGymMembershipService
  ) { }
  ngOnInit(): void {
    this.getClientMembershipWithClassess();

  }

  CloseAll() {
    this.ngbModal.dismissAll();
  }
  AttendClient() {
    this.gymMembershipAttendanceService.CreateAttendance({
      ClientId: this.clientId,
      AttendanceType: 1,

    }).subscribe(res => {
      let xx = res;

      this.toaster.success("AttendSuccessfully");
    })
  }


  attendMembership(gymClientMembershipId) {
    this.gymMembershipAttendanceService.Attend({ gymClientMembershipId }).subscribe(res => {
      this.toaster.success("AttendSuccessfully");
      this.ngbModal.dismissAll();

    })
  }
  AttendClassClient(AppointmenetId) {
    this.gymMembershipAttendanceService.AttendClassClient({ AppointmenetId }).subscribe(res => {
      this.toaster.success("AttendSuccessfully");
      this.ngbModal.dismissAll();

    })
  }
  getClientMembershipWithClassess() {

    //this.clientId = 265461;

    this.clientGymMembershipService.GetClientMembershipInfo({ clientId: this.clientId }).subscribe((res: any) => {
      this.client = res;

      if (this.client.AllowedWeekDays && this.client.AllowedWeekDays.length > 0) {

        this.client.AllowedWeekDaysNames = ' ( ';

        this.client.AllowedWeekDays.forEach((weekDay, index) => {

          this.client.AllowedWeekDaysNames +=
            this.translate.instant(weekDay) ?? this.translate.instant('NotFound');

          if (index + 1 == this.client.AllowedWeekDays.length) {
            this.client.AllowedWeekDaysNames += ' ) ';
          } else {
            this.client.AllowedWeekDaysNames += ' - ';
          }
        });
      }

      this.Memberships = this.client.Memberships;

      if (this.Memberships) {

        this.Memberships.forEach(
          (membership) => {
            if (membership.AllowedWeekDays && membership.AllowedWeekDays.length > 0) {
              membership.AllowedWeekDaysNames = ' ( ';

              membership.AllowedWeekDays.forEach((weekDay, index) => {

                membership.AllowedWeekDaysNames +=
                  this.translate.instant(weekDay) ?? this.translate.instant('NotFound');

                if (index + 1 == membership.AllowedWeekDays.length) {
                  membership.AllowedWeekDaysNames += ' ) ';
                } else {
                  membership.AllowedWeekDaysNames += ' - ';
                }
              });
            }
          }
        );
      }

      this.TickTock();

    })
  }

  getGymActivityTypeString(gymActivityType: GymActivityType): string {
    return 'GymActivityType' + GymActivityType[gymActivityType];
  }

  TickTock() {
    this.seconds10 = 10;
    this.tickTock = setInterval(() => {
      this.seconds10 = this.seconds10 - 1;
      this.seconds.nativeElement.innerText = this.seconds10;
      if (this.seconds10 == 0) {
        clearInterval(this.tickTock);
        this.activeModal.dismiss('Cross click');
      }
    }, 1000);
  }

  CancelClose() {
    clearInterval(this.tickTock);
    this.seconds10 = 0;
    this.seconds.nativeElement.innerText = this.seconds10;
  }

  public selected: number = 0;

  public ExpandRow: number;
  selectRow(i) {
    this.selected = i;
    if (this.ExpandRow == undefined || this.ExpandRow != i) {
      this.ExpandRow = i;
    } else {
      this.ExpandRow = undefined;
    }
    // console.log(i)
    // console.log(this.selected)
    // this.selected = i
    // if (this.selected == i) {
    //   console.log('ssss')
    // }
  }


}
