import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ClientBalanceDetail } from '../../models/classes/balances/client-balance-detail';
import { ClientBalanceStatus } from '../../models/enums/client-balance-status';
import { ClientBalanceTransactionDirection } from '../../models/enums/client-balance-transaction-direction';
import { ClientBalanceTransactionType } from '../../models/enums/client-balance-transaction-type';
import { ResponseState } from '../../models/support/response-state';
import { ClientBalanceDetailService } from '../../services/balances/client-balance-detail.service';
import { SharedService } from '../../services/other/shared.service';
import { TransactionsSecondService } from '../../services/transaction/transactions-second.service';
import { PaginationComponent } from '../pagination/pagination.component';
import { GetSafeTransactionsCriteria } from 'src/app/models/search-criterias/get-safe-transactions-criteria';
import { GetSafeTransactionsSearchResult } from 'src/app/models/search-result/get-safe-transactions-search-result';
import { SafeTransactionDTO } from 'src/app/models/classes/cashier/safe-transaction-dto';
import { TransactionType } from 'src/app/models/enums/transaction-type';
import { CashierBalanceService } from 'src/app/services/cashier/cashier-balance.service';

@Component({
  selector: 'app-modal-safe-transactions',
  templateUrl: './modal-safe-transactions.component.html',
  styleUrls: ['./modal-safe-transactions.component.scss'],
  providers: [DatePipe]

})
export class ModalSafeTransactionsComponent implements OnInit {

  @ViewChild('pager', { static: true }) pager: PaginationComponent;

  @Input() SafeId: number;
  @Input() SafeName: string;
  public DateFrom: Date;
  public DateTo: Date;
  public SafeTransactions: SafeTransactionDTO[] = [];
  public Loading: boolean = false;
  public freeText: string;
  public TransactionType: TransactionType = -1;
  public ClientBalanceTransactionTypeToSearchWith: ClientBalanceTransactionType;

  public Direction: ClientBalanceTransactionDirection = -1;;
  public ClientBalanceTransactionDirectionToSearchWith: ClientBalanceTransactionDirection;

  constructor(public activeModal: NgbActiveModal,
    public translate: TranslateService,
    public sharedService: SharedService,
    public _transactionsSecondService: TransactionsSecondService,
    private fb: UntypedFormBuilder,
    public datepipe: DatePipe,
    public clientBalanceDetailService: ClientBalanceDetailService,
    public cashierBalanceService: CashierBalanceService) { }

  ngOnInit(): void {

    this.GetWithReset();
    //this.DateFrom = this.DateTo = new Date();
  }

  GetWithReset() {
    this.pager.pageSize = 10;
    this.pager.ddlPageSize = 10;
    this.pager.GlobalPageIndex = 0;
    this.GetSafeTransactions();
  }
  divName
  printPage() {

    this.divName = 'printSafeTransactions';
    console.log(this.divName)

    let printContents = document.getElementById(this.divName).innerHTML;
    let originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
    // this.divName = null;
  }

  public get clientBalanceStatus(): typeof ClientBalanceStatus {
    return ClientBalanceStatus;
  }

  public get transactionType(): typeof TransactionType {
    return TransactionType;
  }

  public get clientBalanceTransactionDirection(): typeof ClientBalanceTransactionDirection {
    return ClientBalanceTransactionDirection;
  }

  DateFromChanged(event) {
    this.DateFrom = event
      ? event?.target?.valueAsDate
      : this.DateFrom;
  }

  DateToChanged(event) {
    this.DateTo = event
      ? event?.target?.valueAsDate
      : this.DateTo;
  }

  GetSafeTransactions() {

    this.Loading = true;
    var criteria = new GetSafeTransactionsCriteria();

    criteria.FreeText = this.freeText;
    criteria.DateFrom = this.DateFrom;
    criteria.DateTo = this.DateTo;
    criteria.SafeId = this.SafeId;
    criteria.PageSize = this.pager.pageSize;
    criteria.PagingEnabled = true;
    criteria.PageNumber = this.pager.GlobalPageIndex;
    criteria.TransactionType = this.TransactionType;
    criteria.Direction = this.Direction;

    this.cashierBalanceService
      .getSafeTransactions(criteria)
      .subscribe((response: GetSafeTransactionsSearchResult) => {
        this.Loading = false;
        if (response.State == ResponseState.Success) {
          this.SafeTransactions = response.SafeTransactions;
          this.pager.EventsCount = response.TotalCount;
          this.pager.setPage(this.pager.GlobalPageIndex, false);
        }

      });
  }

  ReciveFreeText(e) {

    this.freeText = e
    this.GetWithReset();
  }

  filterByTransactionType(e) {
    if (!e.target.value || e.target.value == '' || e.target.value == '-1' || e.target.value.length <= 0) {
      this.ClientBalanceTransactionTypeToSearchWith = null;
    } else {
      this.ClientBalanceTransactionTypeToSearchWith =
        Number(e.target.value);
    }

    this.GetWithReset();
  }

  filterByDirection(e) {
    if (!e.target.value || e.target.value == '' || e.target.value == '-1' || e.target.value.length <= 0) {
      this.ClientBalanceTransactionDirectionToSearchWith = null;
    } else {
      this.ClientBalanceTransactionDirectionToSearchWith =
        Number(e.target.value);
    }

    this.GetWithReset();
  }
}
