import { Injectable } from '@angular/core';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../message/message.service';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Department } from '../../models/classes/employee/department';
import { DepartmentSearchCriteria } from '../../models/search-criterias/department-search-criteria';
import { DepartmentResult } from '../../models/search-result/department-result';
import { ExecutionResponse } from '../../models/support/execution-response';
import { ValidationCriteria } from '../../models/support/validation-criterias/validation-criteria';
import { formValueDates } from '../../shared/helpers';


@Injectable({
  providedIn: 'root'
})
export class DepartmentService  extends BaseService{

  private DepartmentUrl 

  constructor(
    private http: HttpClient, private messageService: MessageService,
    private  _BaseService:BaseServiceSZ
  ) {
    super();
    this.DepartmentUrl =    this._BaseService.ApiUrl + 'department';

  }



  getDepartment(id: String): Observable<ExecutionResponse<Department>> {
    //  getEmployee(id: String): ExecutionResponse<Employee> {
    //const url = '${this.employeesUrl}/${id}';
    
    return this.http.post<ExecutionResponse<Department>>(this.DepartmentUrl + '/GetDepartmentById', id, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched department id=${id}');
          this._BaseService.ValidationResult(res);
      }),
        
        catchError(this._BaseService.handleError<ExecutionResponse<Department>>
          ('getDepartment id=${id}'))
      );
  }



  /* GET Department whose name contains search term */
  searchDepartment(departmentSearchCriteria: DepartmentSearchCriteria): 
  Observable<DepartmentResult> {
    departmentSearchCriteria.AccountSetupId = super.getAccountSetupId();
    return this.http.post<DepartmentResult>
    (this.DepartmentUrl + '/GetAllDepartments', departmentSearchCriteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched Departments');
          this._BaseService.ValidationResult(res);
      }),
        //catchError(this._BaseService.handleError('getEmployees', []))
      );
  }




  addDepartment(department: Department): Observable<ExecutionResponse<Department>> {
    department.AccountSetupId = super.getAccountSetupId();

    return this.http.post<ExecutionResponse<Department>>(this.DepartmentUrl + '/Create', 
    department, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('add Department id=${id}');
          this._BaseService.ValidationResult(res);
      }),
        catchError(this._BaseService.handleError<ExecutionResponse<Department>>('addDepartment id=${id}'))
      );
  }

  /** DELETE: delete the employee from the server */
//   deleteEmployee(employee: Employee | number): Observable<ExecutionResponse<Employee>> {
//     return this.http.post<ExecutionResponse<Employee>>(this.employeesUrl + '/delete', employee, super.getHeaders()).pipe(
//       tap(res => {
//         this._BaseService.log('deleted employee id=${id}');
//         this._BaseService.ValidationResult(res);
//     }),
//       catchError(this._BaseService.handleError<ExecutionResponse<Employee>>('deleteEmployee'))
//     );
//   }


  IsNameUnique(validationCriteria: ValidationCriteria): Observable<ExecutionResponse<Boolean>> {
    return this.http.post<ExecutionResponse<Boolean>>(this.DepartmentUrl + '/IsNameUnique',
     validationCriteria, super.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deleted departmentmaindata id=${id}');
        this._BaseService.ValidationResult(res);
    }),
      
      catchError(this._BaseService.handleError<ExecutionResponse<Boolean>>('deleteDepartment'))
    );
  }

  /** PUT: update the Department on the server */
  updateDepartment(department: Department): Observable<ExecutionResponse<Department>> {
    department.AccountSetupId = super.getAccountSetupId();
    department = formValueDates(department,true);

    return this.http.post<ExecutionResponse<Department>>(this.DepartmentUrl + 
      '/Update', department, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('added Department w/ id=${Department.Id}');
          this._BaseService.ValidationResult(res);
      }),
        catchError(this._BaseService.handleError
          <ExecutionResponse<Department>>('addDepartment id=${id}'))
      );
  }




}
