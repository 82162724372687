import { Injectable } from '@angular/core';
import * as _ from 'underscore';

@Injectable({
  providedIn: 'root'
})
export class PagerService {

  constructor() { }

  getPager(totalItems: number, currentPage: number = 0, pageSize: number = 10) {
    let totalPages = Math.ceil(totalItems / pageSize);

    if (currentPage >= totalPages) {
      currentPage = totalPages - 1;
    }

    let startPage: number, endPage: number;

    if (totalPages <= 10) {
      startPage = 0;
      endPage = totalPages - 1;
    } else {
      if (currentPage <= 3) {
        startPage = 0;
        endPage = 9;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 10;
        endPage = totalPages - 1;
      } else {
        startPage = currentPage - 4;
        endPage = Math.min(currentPage + 5, totalPages - 1);
      }
    }

    let startIndex = currentPage * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    let pages = _.range(startPage, endPage + 1);

    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

}
