import { Injectable } from '@angular/core';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { HttpClient } from '@angular/common/http';
import { SharedService } from '../other/shared.service';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { ProductUnit } from '../../models/classes/product/product-unit';
import { ProductUnitSearchCriteria } from '../../models/search-criterias/product-unit-search-criteria';
import { ProductUnitSearchResult } from '../../models/search-result/product-unit-search-result';
import { ExecutionResponse } from '../../models/support/execution-response';

@Injectable({
  providedIn: 'root'
}) 
export class ProductUnitsService extends BaseService {
  private productUnitsUrl //= super.BaseUrl() + 'unit';  // URL to web api

  constructor(
    private http: HttpClient,
    private sharedService:SharedService,
    private  _BaseService:BaseServiceSZ
  ) {
    super();
    this.productUnitsUrl =    this.sharedService.ApiUrl + 'ProductUnit';

  }

  searchProductUnits(productUnitSearchCriteria  : ProductUnitSearchCriteria): Observable<ProductUnitSearchResult> {
    productUnitSearchCriteria.AccountSetupId = super.getAccountSetupId();
    return this.http.post<ProductUnitSearchResult>(this.productUnitsUrl + '/Get', productUnitSearchCriteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched units');
          this._BaseService.ValidationResult(res);
      }),
        catchError(this._BaseService.handleError<ProductUnitSearchResult>('searchProductUnits id=${id}'))
      );
  }

  getProductUnits(id: String): Observable<ExecutionResponse<ProductUnit>> {
   
    return this.http.post<ExecutionResponse<ProductUnit>>(this.productUnitsUrl + '/GetById', id, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched ProductUnits id=${id}');
          this._BaseService.ValidationResult(res);
      }),
        
        catchError(this._BaseService.handleError<ExecutionResponse<ProductUnit>>('getProductUnits id=${id}'))
      );
  }

}
