import { SearchCriteria } from "./search-criteria";
import { TransactionType } from "src/app/models/enums/transaction-type";
import { ClientBalanceTransactionDirection } from "src/app/models/enums/client-balance-transaction-direction";

export class GetSafeTransactionsCriteria extends SearchCriteria {
  public SafeId: number;
  public DateFrom: Date;
  public DateTo: Date;
  public TransactionType: TransactionType;
  public Direction: ClientBalanceTransactionDirection;
}
