<div id="printClientMembershipFrozenDaysLogDetails">
  <div class="modal-header">
    <h5 class="modal-title text-capitalize" id="exampleModalLabel">
      <i class="fas fa-id-card px-2"></i> {{'GymClientMembershipFrozenDaysLogInfo' | translate}}
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="modal-body text-start col-lg-12" *ngIf="gymClientMembershipFrozenDaysLog">
        <div class="formCard">
          <table class="table table-sm">
            <tr>
              <td>{{ 'MembershipName' | translate }}</td>
              <td>
                {{ gymClientMembershipFrozenDaysLog.GymMembershipName}}
              </td>

            </tr>
            <tr>
              <td>{{ 'ClientName' | translate }}</td>
              <td>
                {{ gymClientMembershipFrozenDaysLog.ClientName}}
              </td>
            </tr>
            <tr>
              <td>{{ 'ClientMobile' | translate }}</td>
              <td>
                {{ gymClientMembershipFrozenDaysLog.ClientMobile }}
              </td>
            </tr>

            <tr>
              <td>{{ 'MaxNumberOfFrozenDays' | translate }}</td>
              <td>{{ gymClientMembershipFrozenDaysLog.TotalFrozenDaysBalance}}</td>
            </tr>
            <tr>
              <td>{{ 'OperationDate' | translate }}</td>
              <td> {{gymClientMembershipFrozenDaysLog.OperationDate | date: 'yyyy-MM-dd'}}</td>
            </tr>
            <tr>
              <td>{{ 'LastUpdateDate' | translate }}</td>
              <td>{{(gymClientMembershipFrozenDaysLog.LastUpdateDate ?? gymClientMembershipFrozenDaysLog.OperationDate )
                |
                date: 'yyyy-MM-dd'}}</td>
            </tr>
            <tr>
              <td>{{ 'AvilableNumberOfFrozenDaysBeforeFreez' | translate }}</td>
              <td>{{ gymClientMembershipFrozenDaysLog.OldFrozenDaysBalance}}</td>
            </tr>

            <tr>
              <td>{{ 'FrozenDaysStartDate' | translate }}</td>
              <td>{{ gymClientMembershipFrozenDaysLog.FrozenDaysStartDate | date:
                'yyyy-MM-dd' }}</td>
            </tr>

            <tr>
              <td>{{ 'FrozenDaysEndDate' | translate }}</td>
              <td>{{ gymClientMembershipFrozenDaysLog.FrozenDaysEndDate | date:
                'yyyy-MM-dd' }}</td>
            </tr>


            <tr>
              <td>{{ 'GymMembershipEndDateBeforeFreez' | translate }}</td>
              <td>{{ gymClientMembershipFrozenDaysLog.OldGymMembershipEndDate | date:
                'yyyy-MM-dd' }}</td>
            </tr>

            <tr>
              <td>{{ 'FrozenDaysCount' | translate }}</td>
              <td>{{ gymClientMembershipFrozenDaysLog.FrozenDaysCount }}</td>
            </tr>
            <tr>
              <td>{{ 'GymMembershipEndDateAfterFreez' | translate }}</td>
              <td>{{ gymClientMembershipFrozenDaysLog.NewGymMembershipEndDate | date:
                'yyyy-MM-dd' }}</td>
            </tr>

            <tr>
              <td>{{ 'AvilableNumberOfFrozenDaysAfterFreez' | translate }}</td>
              <td>{{ gymClientMembershipFrozenDaysLog.NewFrozenDaysBalance }}</td>
            </tr>
          </table>
        </div>


      </div>

      <div class="col-lg-12" *ngIf="!gymClientMembershipFrozenDaysLog">
        <div class="col-md-12 text-center py-4 my-4">
          <i class="fas fa-circle-notch fa-spin fa-3x"></i>
          <h1>{{'Loading' | translate}} </h1>
        </div>
      </div>

    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">
      {{'Close' | translate}}</button>
    <button type="button" class="btn btn-danger" (click)="printPage()">{{'Print' |
      translate}}</button>
  </div>
</div>
