import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CostCenterService } from '../../dashboard/accounts/services/cost-center.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddCostCenterComponent } from '../add-cost-center/add-cost-center.component';
import { CostCenter } from '../../models/classes/accounts/cost-center';
import { CostCenterCriteria } from '../../models/search-criterias/cost-center-criteria';
import { ResponseState } from '../../models/support/response-state';
import { SharedService } from '../../services/other/shared.service';

@Component({
  selector: 'app-choose-cost-center',
  templateUrl: './choose-cost-center.component.html',
  styleUrls: ['./choose-cost-center.component.scss']
})
export class ChooseCostCenterComponent implements OnInit {
  @Input() name;
   @Input() levelNumber;
  @Input()  AccountsetupId ;
  @Input() isViewPlus: boolean;


  @Output() emitService = new EventEmitter();
  public freeText;
  public IsActive;
  public ExpandRowLevelTwo: number;
  public ExpandRowLevelThree: number;
  public ExpandRowLevelFour: number;
  public ExpandRowLevelFive: number;
  public ExpandRowLevelSix: number;
  public ExpandRowLevelSeven: number;
  public ExpandRowLevelEight: number;

  public AccountToSend: CostCenter;
  public AccountToDelete: CostCenter;

  constructor(
    private toastr: ToastrService,
    public sharedService: SharedService,
    public CostCenterService: CostCenterService,
    public datepipe: DatePipe,
    public translateService: TranslateService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal

  ) { }

  ngOnInit(): void {


    this.GetData(1, null);
  }
  public CostCenters;
  public CostCenters2;
  public CostCenters3;
  public CostCenters4;
  public CostCenters5;
  public CostCenters6;
  public CostCenters7;
  public CostCenters8;
  GetData(levelNumber: number, ParentId: number) {
    let criteria = new CostCenterCriteria();
    if(this.AccountsetupId != undefined)
    {
      criteria.AccountSetupId = this.AccountsetupId;
    }
    criteria.PageSize = 20;
    criteria.PagingEnabled = levelNumber == 1 ? true : false;
    criteria.PageNumber = 0;
    criteria.FreeText = '';
    criteria.LevelNumber = levelNumber;
    criteria.IsActive = true ;
    criteria.ParentId = ParentId
    criteria.FreeText = this.freeText == undefined ? "" : this.freeText;
    //criteria.AccountGroupCode = this.accountGroupCode;


    this.CostCenterService
      .getCostCenter(criteria)
      .subscribe((response: any) => {
        if (response.State == ResponseState.Success) {
          if(response.CostCenters.length > 0)
          {
          if (response.CostCenters[0].LevelNumber == 1) {
            this.CostCenters = response.CostCenters;
          }
          if (response.CostCenters[0].LevelNumber == 2) {
            this.CostCenters2 = response.CostCenters;
          }
          if (response.CostCenters[0].LevelNumber == 3) {
            this.CostCenters3 = response.CostCenters;
          }
          if (response.CostCenters[0].LevelNumber == 4) {
            this.CostCenters4 = response.CostCenters;
          }
          if (response.CostCenters[0].LevelNumber == 5) {
            this.CostCenters5 = response.CostCenters;
          }
          if (response.CostCenters[0].LevelNumber == 6) {
            this.CostCenters6 = response.CostCenters;
          }
          if (response.CostCenters[0].LevelNumber == 7) {
            this.CostCenters7 = response.CostCenters;
          }
          if (response.CostCenters[0].LevelNumber == 8) {
            this.CostCenters8 = response.CostCenters;
          }
        }
      }
      });
  }


  ExpandLevelTwo(i, parentId: number) {
    if (this.ExpandRowLevelTwo == undefined || this.ExpandRowLevelTwo != i) {
      this.ExpandRowLevelTwo = i;

      this.GetData(2, parentId);

    }
    else {
      (this.ExpandRowLevelTwo = undefined);
    }
    this.ExpandRowLevelThree = undefined;
    this.ExpandRowLevelFour = undefined;
    this.ExpandRowLevelFive = undefined;
    this.ExpandRowLevelSix = undefined;
    this.ExpandRowLevelSeven = undefined;
    this.ExpandRowLevelEight = undefined;
  }
  ExpandLevelThree(j, parentId: number) {
    if (this.ExpandRowLevelThree == undefined || this.ExpandRowLevelThree != j) {
      this.ExpandRowLevelThree = j;
      this.GetData(3, parentId);
    }
    else {
      this.ExpandRowLevelThree = undefined;
    }

    this.ExpandRowLevelFour = undefined;
    this.ExpandRowLevelFive = undefined;
    this.ExpandRowLevelSix = undefined;
    this.ExpandRowLevelSeven = undefined;
    this.ExpandRowLevelEight = undefined;

  }
  ExpandLevelFour(k, parentId: number) {
    if (this.ExpandRowLevelFour == undefined || this.ExpandRowLevelFour != k) {
      (this.ExpandRowLevelFour = k);
      this.GetData(4, parentId);
    }
    else {
      (this.ExpandRowLevelFour = undefined);
    }

    this.ExpandRowLevelFive = undefined;
    this.ExpandRowLevelSix = undefined;
    this.ExpandRowLevelSeven = undefined;
    this.ExpandRowLevelEight = undefined;

  }
  ExpandLevelFive(r, parentId: number) {
    if (this.ExpandRowLevelFive == undefined || this.ExpandRowLevelFive != r) {
      (this.ExpandRowLevelFive = r);
      this.GetData(5, parentId);
    }
    else {
      (this.ExpandRowLevelFive = undefined);
    }

    this.ExpandRowLevelSix = undefined;
    this.ExpandRowLevelSeven = undefined;
    this.ExpandRowLevelEight = undefined;
  }
  ExpandLevelSix(f, parentId: number) {
    if (this.ExpandRowLevelSix == undefined || this.ExpandRowLevelSix != f) {
      (this.ExpandRowLevelSix = f);
      this.GetData(6, parentId);
    }
    else {
      (this.ExpandRowLevelSix = undefined);
    }

    this.ExpandRowLevelSeven = undefined;
    this.ExpandRowLevelEight = undefined;
  }
  ExpandLevelSeven(s, parentId: number) {
    if (this.ExpandRowLevelSeven == undefined || this.ExpandRowLevelSeven != s) {
      (this.ExpandRowLevelSeven = s);
      this.GetData(7, parentId);
    }
    else {
      (this.ExpandRowLevelSeven = undefined);
    }

    this.ExpandRowLevelEight = undefined;
  }
  ExpandLevelEight(v, parentId: number) {
    if (this.ExpandRowLevelEight == undefined || this.ExpandRowLevelEight != v) {
      (this.ExpandRowLevelEight = v);
      this.GetData(8, parentId);
    }
    else {
      (this.ExpandRowLevelEight = undefined);
    }


  }
  ExpandSubLevelAndEditLevel(level: number, i, item: CostCenter) {

    this.AccountToSend = new CostCenter();
    if (level == 2) {
      this.ExpandRowLevelTwo = i;
      this.AccountToSend.LevelNumber = 2;
    }
    if (level == 3) {
      this.ExpandRowLevelThree = i;
      this.AccountToSend.LevelNumber = 3;
    }
    if (level == 4) {
      this.ExpandRowLevelFour = i;
      this.AccountToSend.LevelNumber = 4;
    }
    if (level == 5) {
      this.ExpandRowLevelFive = i;
      this.AccountToSend.LevelNumber = 5;
    }
    if (level == 6) {
      this.ExpandRowLevelSix = i;
      this.AccountToSend.LevelNumber = 6;
    }
    if (level == 7) {
      this.ExpandRowLevelSeven = i;
      this.AccountToSend.LevelNumber = 7;
    }
    this.AccountToSend.Id = 0;
    this.AccountToSend.IsChild = true;
    this.AccountToSend.ParentId = item.Id;
    this.AccountToSend.IsActive = true;
    this.AccountToSend.AccountSetupId = this.AccountsetupId;

    this.AccountToSend.ParentNumber = item.CostCenterNo;
    this.AccountToSend.CostCenterNo = '';
   
  }

  selectAccountId(inneritem) {
   
    if ((this.name != undefined && inneritem.IsChild != true) || (inneritem.IsChild == true))
    {
      this.emitService.next(inneritem);
    }
    else
    {
      this.sharedService.ToastrError('لا يمكن إختيار حساب رئيسي ');
    }
    this.activeModal.close();
  }

  selectAccountId2(innerinneritem) {
    this.emitService.next(innerinneritem)
    this.activeModal.close();

  }
  selectAccountId3(inneritem4) {
    this.emitService.next(inneritem4)
    this.activeModal.close();
  }
  selectAccountId5(inneritem5) {
    this.emitService.next(inneritem5)
    this.activeModal.close();
  }
  selectAccountId6(inneritem6) {
    this.emitService.next(inneritem6)
    this.activeModal.close();
  }
  selectAccountId7(inneritem7) {
    this.emitService.next(inneritem7)
    this.activeModal.close();
  }
  selectAccountId8(inneritem8) {
    this.emitService.next(inneritem8)
    this.activeModal.close();
  }


  ExpandSubLevelAndEditLevelOpenModal(i, item: CostCenter) {
    const modalRef = this.modalService.open(AddCostCenterComponent, { size: 'lg' });
    modalRef.componentInstance.isNewAccount = true;


    modalRef.componentInstance.infoDataItem = item;
    // modalRef.componentInstance.levelNum = level;

    this.ExpandRowLevelTwo = i;


  }



}
