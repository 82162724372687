
import { CashierBalance } from "./cashier-balance";
import { Transactions } from '../transactions/transactions';
import { CashierDailyAndShiftsStatus } from '../../enums/cashier-daily-status';
import { Time } from "@angular/common";
import { DailyOrShiftTransactionsGroupedByTransactionType } from "../day-and-shift/daily-or-shift-transactions-grouped-by-transaction-type";
import { DailyOrShiftTotalBalances } from "../day-and-shift/daily-or-shift-total-balances";

export class CashierDaily {
    constructor() {
        this.Id = 0;
        this.Status = 1;
    }

    public Id: number;

    public BranchId: number;

    public OpenUserGlobalName: string;
    public CloseUserGlobalName: string;


    public DayStartDateAndTime: Date;
    public DailyDate: Date;
    public Status: CashierDailyAndShiftsStatus;
    public DayEndDateAndTime: Date;
    public CashierBalance: CashierBalance[];
    public Transactions: Transactions[];


    public DayTotalTime: Time;
    public DayTotalTimeDays: number;
    public DayTotalTimeHours: number;
    public DayTotalTimeMinutes: number;




    public DayTransactionsGroupedByTransactionType: DailyOrShiftTransactionsGroupedByTransactionType;
    public DayTotalBalances: DailyOrShiftTotalBalances;

}

export class CashierTransHeaderData {
    constructor() { }
    EmployeeId: number;
    CashierDailyId: number;
    CashierId: number;
    CashierDailyDate: Date;

}
export class CashierTransHeaderCriteria {
    constructor() { }
    EmployeeId: number;
    AccountSetupId: number;

}
