import { Injectable } from '@angular/core';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../message/message.service';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { HrSalaryItemsMaster } from '../../models/classes/employee/hr-salary-items-master';
import { HrSalaryItemsMasterSearchCriteria } from '../../models/search-criterias/hr-salary-items-master-search-criteria';
import { HrSalaryItemsMasterSearchResult } from '../../models/search-result/hr-salary-items-master-search-result';
import { ExecutionResponse } from '../../models/support/execution-response';
import { formValueDates } from '../../shared/helpers';

@Injectable({
  providedIn: 'root'
})
export class HrSalaryItemsMasterService extends BaseService {

  private hrSalaryItemsMasterUrl // = super.BaseUrl() + 'AttendMethodMaster';  // URL to web api

  constructor(
    private http: HttpClient, private messageService: MessageService,
    private  _BaseService:BaseServiceSZ
  ) {
    super();
    this.hrSalaryItemsMasterUrl =    this._BaseService.ApiUrl + 'HrSalItemsMaster';

  }

  // /** GET attendMethodMasters from the server */
  // getAttendMethodMasters(): Observable<AttendMethodMaster[]> {
  //   return this.http.get<AttendMethodMaster[]>(this.attendMethodMastersUrl, super.getHeaders())
  //     .pipe(
  //       tap(attendMethodMasters => this._BaseService.log('fetched attendMethodMasters')),
  //       catchError(this._BaseService.handleError('getAttendMethodMasters', []))
  //     );
  // }


  /** GET attendMethodMaster by id. Will 404 if id not found */
  getHrSalaryItemsMaster(id: String): Observable<ExecutionResponse<HrSalaryItemsMaster>> {
    //  getAttendMethodMaster(id: String): ExecutionResponse<AttendMethodMaster> {
    //const url = '${this.attendMethodMastersUrl}/${id}';
    
    return this.http.post<ExecutionResponse<HrSalaryItemsMaster>>(this.hrSalaryItemsMasterUrl + '/getById', id, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched hrSalaryItemsMaster id=${id}');
          this._BaseService.ValidationResult(res);
      }),
        
        catchError(this._BaseService.handleError<ExecutionResponse<HrSalaryItemsMaster>>('getHrSalaryItemsMaster id=${id}'))
      );
  }



  /* GET attendMethodMasters whose name contains search term */
  searchHrSalaryItemsMasters(hrSalaryItemsMasterSearchCriteria: HrSalaryItemsMasterSearchCriteria): Observable<HrSalaryItemsMasterSearchResult> {
    
    hrSalaryItemsMasterSearchCriteria.AccountSetupId = super.getAccountSetupId();
    return this.http.post<HrSalaryItemsMasterSearchResult>(
      this.hrSalaryItemsMasterUrl + '/Get', hrSalaryItemsMasterSearchCriteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched hrSalaryItemsMasters');
          this._BaseService.ValidationResult(res);
      }),
      );

  }


  //////// Save methods //////////

  /** POST: add a new attendMethodMaster to the server */
  addHrSalaryItemsMaster(hrSalaryItemsMaster: HrSalaryItemsMaster): Observable<ExecutionResponse<HrSalaryItemsMaster>> {
    hrSalaryItemsMaster.AccountSetupId= super.getAccountSetupId();
    hrSalaryItemsMaster = formValueDates(hrSalaryItemsMaster,true);
    return this.http.post<ExecutionResponse<HrSalaryItemsMaster>>(this.hrSalaryItemsMasterUrl + '/Create', hrSalaryItemsMaster, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('add  HrSalaryItemsMaster id=${id}');
          this._BaseService.ValidationResult(res);
      }),
        catchError(this._BaseService.handleError<ExecutionResponse<HrSalaryItemsMaster>>('add HrSalaryItemsMaster id=${id}'))
      );
  }

  /** DELETE: delete the attendMethodMaster from the server */
  deleteHrSalaryItemsMaster(hrSalaryItemsMaster: HrSalaryItemsMaster | number): Observable<ExecutionResponse<HrSalaryItemsMaster>> {
    return this.http.post<ExecutionResponse<HrSalaryItemsMaster>>(this.hrSalaryItemsMasterUrl + '/delete', hrSalaryItemsMaster, super.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deleted HrSalaryItemsMaster id=${id}');
        this._BaseService.ValidationResult(res);
    }),
      catchError(this._BaseService.handleError<ExecutionResponse<HrSalaryItemsMaster>>('deleteHrSalaryItemsMaster'))
    );
  }

  updateHrSalaryItemsMaster(hrSalaryItemsMaster: HrSalaryItemsMaster): Observable<ExecutionResponse<HrSalaryItemsMaster>> {
     
    hrSalaryItemsMaster.AccountSetupId = super.getAccountSetupId();
    hrSalaryItemsMaster = formValueDates(hrSalaryItemsMaster,true);

    return this.http.post<ExecutionResponse<HrSalaryItemsMaster>>(this.hrSalaryItemsMasterUrl + '/Update', hrSalaryItemsMaster, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('added hrSalaryItemsMaster w/ id=${hrSalaryItemsMaster.Id}');
          this._BaseService.ValidationResult(res);
      }),
        catchError(this._BaseService.handleError<ExecutionResponse<HrSalaryItemsMaster>>('add HrSalaryItemsMaster id=${id}'))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
 
}
