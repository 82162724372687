import { Component, Input, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BRANCH } from 'core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-branch-item',
  templateUrl: './branch-item.component.html',
  styleUrls: ['./branch-item.component.scss'],
})
export class BranchItemComponent {
  @Input() branchList: BRANCH[] = [];
  @Output() clickItem = new EventEmitter<number>();
  loading: boolean = false;
  
  public isRtl: boolean = false;

  public constructor(translateService: TranslateService ,
    private spinner:NgxSpinnerService
  ) {
    this.isRtl = translateService.currentLang === 'ar';
  }

  onClickItem(branch: BRANCH): void {
    console.log(branch);
    this.loading = true;
    this.clickItem.emit(branch.Id);
  }
}
