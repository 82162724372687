import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TokenDTO } from '../../models/classes/account/token-dto';
import { AppEnum } from '../../models/enums/app-enum';
import { UXErrors } from '../../shared/helpers';
import { MessageService } from '../message/message.service';
import { environment } from '../../../environments/environment';
import { AddedByDevice } from '../../models/enums/added-by-device';

export class BaseService {
  public static lang: string = 'ar';
  AuditObj: any = {};

  getToken() {
    var token: TokenDTO;
    if (
      !(
        localStorage.getItem('tokenKey') == 'null' ||
        localStorage.getItem('tokenKey') == null
      )
    ) {
      token = JSON.parse(localStorage.getItem('tokenKey'));
    } else {
      token = new TokenDTO();
    }
    return token.Token;
  }

  bufferAuditColumns(entity: any, identifier: string) {
    this.AuditObj[identifier + entity.Id] = {
      AddedDate: entity.AddedDate,
      AddUserId: entity.AddUserId,
    };
  }
  setBufferAuditColumns(entity: any, identifier: string) {
    let buffered = this.AuditObj[identifier + entity.Id];
    if (buffered) {
      entity.AddedDate = buffered['AddedDate'];
      entity.AddUserId = buffered['AddUserId'];
    }
    this.AuditObj[identifier + entity.Id] = {
      AddedDate: entity.AddedDate,
      AddUserId: entity.AddUserId,
    };
  }

  getAccountSetupId() {
    var token: TokenDTO;
    var returnAccountSetupId: number = null;
    if (
      !(
        localStorage.getItem('tokenKey') == 'null' ||
        localStorage.getItem('tokenKey') == null
      )
    ) {
      token = JSON.parse(localStorage.getItem('tokenKey'));
      returnAccountSetupId = token.AccountSetupId;
    } else {
      token = new TokenDTO();
    }
    return returnAccountSetupId;
  }

  getIsMainBranch() {
    var token: TokenDTO;
    var returnIsMainBranch: boolean = null;
    if (
      !(
        localStorage.getItem('tokenKey') == 'null' ||
        localStorage.getItem('tokenKey') == null
      )
    ) {
      token = JSON.parse(localStorage.getItem('tokenKey'));
      returnIsMainBranch = token.IsMainBranch;
    } else {
      token = new TokenDTO();
    }
    return returnIsMainBranch;
  }

  getCompanyId() {
    var token: TokenDTO;
    var returnCompanyId: number = null;
    if (
      !(
        localStorage.getItem('tokenKey') == 'null' ||
        localStorage.getItem('tokenKey') == null
      )
    ) {
      token = JSON.parse(localStorage.getItem('tokenKey'));
      returnCompanyId = token.CompanyId;
    } else {
      token = new TokenDTO();
    }
    return returnCompanyId;
  }

  getPulsePrice() {
    var token: TokenDTO;
    var returnPulsePrice: number = null;
    if (
      !(
        localStorage.getItem('tokenKey') == 'null' ||
        localStorage.getItem('tokenKey') == null
      )
    ) {
      token = JSON.parse(localStorage.getItem('tokenKey'));
      returnPulsePrice = token.PulsePrice;
    } else {
      token = new TokenDTO();
    }
    return returnPulsePrice;
  }

  getCurrentBranchId() {
    var token: TokenDTO;
    var returnCurrentBranchId: number = null;
    if (
      !(
        localStorage.getItem('tokenKey') == 'null' ||
        localStorage.getItem('tokenKey') == null
      )
    ) {
      token = JSON.parse(localStorage.getItem('tokenKey'));
      returnCurrentBranchId = token.CurrentBranchId;
    } else {
      token = new TokenDTO();
    }
    return returnCurrentBranchId;
  }

  getEmployeeId() {
    var token: TokenDTO;
    var returnAccountSetupId: number = 0;
    if (
      !(
        localStorage.getItem('tokenKey') == 'null' ||
        localStorage.getItem('tokenKey') == null
      )
    ) {
      token = JSON.parse(localStorage.getItem('tokenKey'));
      returnAccountSetupId = token.EmployeeId;
      if (returnAccountSetupId == 0) returnAccountSetupId = null;
    } else {
      token = new TokenDTO();
    }
    return returnAccountSetupId;
  }

  setEmployeeId(employeeId) {
    var token: TokenDTO;
    if (
      !(
        localStorage.getItem('tokenKey') == 'null' ||
        localStorage.getItem('tokenKey') == null
      )
    ) {
      token = JSON.parse(localStorage.getItem('tokenKey'));
      token.EmployeeId = employeeId;
      localStorage.setItem('tokenKey', JSON.stringify(token));
    }
  }

  getUserId() {
    var token: TokenDTO;
    var returnUserId: number = 0;
    if (
      !(
        localStorage.getItem('tokenKey') == 'null' ||
        localStorage.getItem('tokenKey') == null
      )
    ) {
      token = JSON.parse(localStorage.getItem('tokenKey'));
      returnUserId = token.UserId;
      if (returnUserId == 0) returnUserId = null;
    } else {
      token = new TokenDTO();
    }
    return returnUserId;
  }

  getUserName() {
    var token: TokenDTO;
    var returnUserName: string = '';
    if (
      !(
        localStorage.getItem('tokenKey') == 'null' ||
        localStorage.getItem('tokenKey') == null
      )
    ) {
      token = JSON.parse(localStorage.getItem('tokenKey'));
      returnUserName = token.UserName;
      if (returnUserName == '') returnUserName = null;
    } else {
      token = new TokenDTO();
    }
    return returnUserName;
  }

  getUserFullName() {
    var token: TokenDTO;
    var returnUserFullName: string = '';
    if (
      !(
        localStorage.getItem('tokenKey') == 'null' ||
        localStorage.getItem('tokenKey') == null
      )
    ) {
      token = JSON.parse(localStorage.getItem('tokenKey'));
      returnUserFullName = token.UserFullName;
      if (returnUserFullName == '') returnUserFullName = null;
    } else {
      token = new TokenDTO();
    }
    return returnUserFullName;
  }

  protected getHeaders() {
    return {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', 'Bearer ' + this.getToken())
        .set('Accept-Language', BaseService.lang)
        .set('Lang', BaseService.lang)
        .set(
          'CurrentBranchId',
          this.getCurrentBranchId() ? this.getCurrentBranchId().toString() : ''
        )
        .set('AppId', AppEnum.GlameraBusinessWeb.toString())
        .set(
          'CompanyId',
          this.getCompanyId() ? this.getCompanyId().toString() : ''
        )
        .set('IsMainBranch', this.getIsMainBranch() ? 'TRUE' : 'FALSE')
        .set('DEVICETYPE', 'Branch')
        .set('AddedByDevice', AddedByDevice.GlameraBusinessWebInBranch + '')
        .set('Access-Control-Allow-Headers', '*'),
    };
  }

  protected getUploadHeaders() {
    return {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + this.getToken())
        .set('reportProgress', 'true')
        .set('observe', 'events'),
    };
  }
  protected getHeadersWithoutAuthorization() {
    return {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };
  }

  //
  protected getHeadersXWWWFormURLEncoded() {
    return {
      headers: new HttpHeaders().set(
        'Content-Type',
        'application/x-www-form-urlencoded'
      ),
    };
  }
}

@Injectable({
  providedIn: 'root',
})
export class BaseServiceSZ {
  public urlLoadSubject: Subject<boolean> = new Subject<boolean>();
  ApiUrl: string = environment.ApiUrl;
  SmallApiUrl: string = environment.OldApiUrlFromGlamatekWeb;
  ReportUrl: string = environment.ReportDevUrl;

  ReportDevUrl: Subject<string> = new Subject<string>();
  reportDevUrl$ = this.ReportDevUrl.asObservable();
  constructor(
    private messageService: MessageService,
    private toastr: ToastrService,
    private _transaletService: TranslateService,
    private httpClient: HttpClient
  ) {
    this.urlLoadSubject.next(true);
  }

  setReportsDevUrl(url: string) {
    this.ReportDevUrl.next(url);
  }

  SmallBaseUrl(): string {
    return this.SmallApiUrl;
  }
  urlLoadSubject$(): Observable<boolean> {
    return this.urlLoadSubject.asObservable();
  }
  BaseUrl(): string {
    return this.ApiUrl;
  }
  ReportsUrl(): string {
    return this.ReportUrl;
  }

  getTranslation(_key: string): Observable<string> {
    return this._transaletService.get(_key);
  }
  ValidationResult(executionResponse) {
    if (executionResponse.State == 102 || executionResponse.State == 107) {
      var mess = '';
      if (
        executionResponse.ValidationResult &&
        executionResponse.ValidationResult.errors
      ) {
        for (let error of executionResponse.ValidationResult.errors) {
          mess = mess + error['<ErrorMessage>k__BackingField'] + ' ';
        }
      }

      if (
        executionResponse.DetailedMessages &&
        executionResponse.DetailedMessages.length > 0
      ) {
        let messageLen = executionResponse.DetailedMessages.length;
        for (let error of executionResponse.DetailedMessages) {
          if (error.Property != '' && error.Property != null) {
            // this.getTranslation(error.Property).subscribe(t => {
            //     mess = mess + t + "\n";
            //     // this.toastr.error(mess);
            // });

            mess += error.MetaPlus + error.Meta + '\n';
          } else {
            // mess = mess + error.Meta + "\n";
            mess += error.MetaPlus + error.Meta + '\n';
          }
        }
        if (messageLen > 0) this.toastr.error(mess);
      } else {
        if (executionResponse.Message) {
          this.toastr.error(executionResponse.Message);
        } else if (mess) {
          this.toastr.error(mess);
        }
      }
      // this.toastr.error(mess);
    }
    if (executionResponse.State == 101) {
      this.toastr.error(UXErrors(executionResponse));
    }
  }
  handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.toastr.error(UXErrors(error));
      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  log(message: string) {
    this.messageService.add('Service: ' + message);
  }

  private pending: any;
  private settings: any = {};
  // public getSettings(fileName: string): Observable<any> {
  //   let _staticLoader = new StaticLoader(this.httpClient)
  //   this.pending = _staticLoader.getSetting(fileName);
  //   this.pending.subscribe((res: Object) => {
  //     this.settings[fileName] = res;
  //     this.SmallApiUrl = res['OldApiUrlFromGlamatekWeb'];
  //     this.ApiUrl = this.SmallApiUrl + 'api/';
  //     this.ReportUrl = res['ReportDevUrl'];
  //     this.setReportsDevUrl(res['ReportDevUrl'])
  //     this.urlLoadSubject.next(true);
  //   }, (err: any) => {
  //     throw err;
  //   }, () => {
  //     this.pending = undefined;
  //   });

  //   return this.pending;
  // }
}

export class StaticLoader {
  constructor(
    private http: HttpClient,
    private prefix: string = 'setting',
    private suffix: string = '.json'
  ) {}
  public getSetting(fileName: string): Observable<any> {
    let _version = new Date().getTime();
    return this.http
      .get(`assets/${this.prefix}/${fileName}${this.suffix}?v=${_version}`)
      .pipe((res) => res);
  }
}
