<div id="printSafeTransactions">
  <div class="modal-header">
    <h5 class="modal-title text-capitalize" id="exampleModalLabel">
      <i class="fas fa-exchange"></i> &nbsp; {{'SafeTransactions' | translate}}
      &nbsp; {{'SafeName' | translate}} &nbsp; ( {{ SafeName }} )
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">

      <div class="col-md-12">
        <div class="filter-card">
          <div class="row px-2">

            <div class="col-md-2">
              <label>{{ 'DateFrom' | translate }}</label>
              <input type="date" class="form-control" name="DateFrom" (change)="DateFromChanged($event)"
                [ngModel]="DateFrom | date:'yyyy-MM-dd'">

            </div>

            <div class="col-md-2">
              <label>{{ 'DateTo' | translate }}</label>
              <input type="date" class="form-control" name="DateTo" (change)="DateToChanged($event)"
                [ngModel]="DateTo | date:'yyyy-MM-dd'">

            </div>

            <div class="col-md-2 col-sm-8 col-12 px-2"
              title="{{ 'SearchByTransactionCodeOrPaymentMethods' | translate }}">
              <label>
                {{ 'Search' | translate
                }}</label>
              <app-search (SendFreeText)="ReciveFreeText($event)"
                [SearchName]="('SearchByTransactionCodeOrPaymentMethods')">
              </app-search>
            </div>


            <div class="col-md-2 col-sm-4  px-2">
              <label>{{ 'TransactionType' | translate }}</label>
              <div class="input-group">
                <select class="form-control" [(ngModel)]="TransactionType" (change)="filterByTransactionType($event)">
                  <option value="-1" selected> {{ 'TransactionType' | translate }}</option>
                  <option [value]="transactionType.CashSale">
                    {{'Sale'|translate}} </option>
                  <option [value]="transactionType.SaleRefund">
                    {{'SaleRefund'|translate}}</option>
                  <option [value]="transactionType.AddToClientBalance">
                    {{'AddToClientBalance'|translate}}</option>
                  <option [value]="transactionType.WithdrawBalanceToTheClient">
                    {{'WithdrawFromClientBalance'|translate}}</option>
                  <option [value]="transactionType.CashierTransfere">
                    {{'TransferToSafe'|translate}}</option>
                  <option [value]="transactionType.RecieveFromCashier">
                    {{'ReceiveTransfer'|translate}}</option>
                  <option [value]="transactionType.ClientDownPayment">
                    {{'ClientDownPayment'|translate}}</option>
                  <option [value]="transactionType.ChargeBeginningShift">
                    {{'ChargeBeginningShift'|translate}}</option>
                  <option [value]="transactionType.BookingDownPaymentRefund">
                    {{'ClientDownPaymentRefund'|translate}}</option>
                  <option [value]="transactionType.HandOver">
                    {{'ShiftBalanceHandOver'|translate}}</option>
                </select>
              </div>
            </div>


            <div class="col-md-2 col-sm-4  px-2">
              <label>{{ 'TransactionDirection' | translate }}</label>
              <div class="input-group">
                <select class="form-control" [(ngModel)]="Direction" (change)="filterByDirection($event)">
                  <option value="-1" selected> {{ 'TransactionDirection' | translate }}</option>
                  <option [value]="clientBalanceTransactionDirection.Input">{{'Adding'|translate}}</option>
                  <option [value]="clientBalanceTransactionDirection.Output">{{'Deducting'|translate}}</option>
                </select>
              </div>
            </div>

            <div class="col-md-2 col-sm-4  px-2">
              <button type="button" class="btn btn-safe-transactions" (click)="GetWithReset()">
                {{'ViewSafeTransactions' | translate}}</button>
            </div>




          </div>
        </div>
      </div>

      <div class="col-md-12" *ngIf="(!Loading && SafeTransactions); let SafeTransactions; else loading">
        <div class="table-card">
          <table class="table table-stripedd table-sm table-bordered">
            <thead class="table-head">
              <tr style="color: black; font-size: large;">

                <th style="width: 25%;" class="formatTd text-center">{{ 'TransactionType' | translate }}</th>
                <th style="width: 10%;" class="formatTd text-center">{{ 'TransactionCode' | translate }}</th>
                <th style="width: 10%;" class="formatTd text-center">{{ 'TransactionDirection' | translate }}</th>
                <th style="width: 15%;" class="formatTd text-center">{{ 'TransactionDate' | translate }}</th>
                <th style="width: 15%;" class="formatTd text-center">{{ 'TransactionTotal' | translate }}</th>
                <th style="width: 25%;" class="formatTd text-center">{{ 'PaymentMethods' | translate }}</th>
              </tr>
            </thead>
            <tbody>

              <tr *ngFor="let safeTransaction of SafeTransactions; let i = index"
                [ngClass]="safeTransaction.Direction ==
              clientBalanceTransactionDirection.Input ? 'greenColor formatTd  text-center' : 'redColor formatTd  text-center'">

                <td class="formatTd">{{ safeTransaction.TransactionTypeText | translate}}</td>
                <td class="formatTd">{{ safeTransaction.TransactionCode }}</td>
                <td class="formatTd" *ngIf="safeTransaction.Direction ==
                clientBalanceTransactionDirection.Input">{{
                  ('Adding'| translate) }}</td>
                <td class="formatTd" *ngIf="safeTransaction.Direction ==
                clientBalanceTransactionDirection.Output">{{
                  ('Deducting' |translate)}}</td>

                <td class="formatTd">{{ safeTransaction.Date | date: 'yyyy-MM-dd hh:mm a' }}</td>

                <td class="formatTd">{{ safeTransaction.Amount.toFixed(sharedService.GetFractionRoundCount) }}
                  <span>
                    &nbsp;
                    {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr :
                    sharedService.CurrencyNameEn}}</span>
                </td>

                <td class="formatTd"> {{ safeTransaction.PaymentMethods ??
                  ('NotFound' | translate)}}</td>

              </tr>

            </tbody>
          </table>
          <div class="text-center py-4 my-4" *ngIf="SafeTransactions.length == 0">
            <h4 class="py-4">{{'NoDataFound' | translate}}</h4>
          </div>
        </div>
      </div>

      <div class="col-md-12" [hidden]="!SafeTransactions || SafeTransactions.length <= 0 ">
        <app-pagination (GetSources)="GetSafeTransactions()" #pager></app-pagination>
      </div>

      <div class="col-md-12">
        <ng-template #loading>
          <div class="col-md-12">
            <app-loading></app-loading>
          </div>
        </ng-template>
      </div>

      <div class="col-lg-12" *ngIf="false">
        <div class="col-md-12 text-center py-4 my-4">
          <i class="fas fa-circle-notch fa-spin fa-3x"></i>
          <h1>{{'Loading' | translate}} </h1>
        </div>
      </div>

    </div>

  </div>
  <div class="modal-footer">

    <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">
      {{'Close' | translate}}</button>

    <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="printPage()">{{'Print' |
      translate}}</button>
  </div>
</div>
