import { Injectable } from '@angular/core';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../message/message.service';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SharedService } from '../other/shared.service';
import { ServiceConsumablesSearchResult } from '../../models/search-result/service-consumables-search-result';
import { ServiceConsumablesSearchCriteria } from '../../models/search-criterias/service-consumables-search-criteria';

@Injectable({
  providedIn: 'root'
})
export class ServiceConsumablesService extends BaseService {

  private servicesConsumablesUrl  //= super.BaseUrl() + 'Service';  // URL to web api

  constructor(
    private http: HttpClient, private messageService: MessageService,
    private sharedService:SharedService,
    private _BaseService: BaseServiceSZ

  ) {
    super();
    this.servicesConsumablesUrl = this.sharedService.ApiUrl + 'ServiceConsumables';

  }


  GetServiceConsumables(criteria: ServiceConsumablesSearchCriteria): Observable<ServiceConsumablesSearchResult> {
    criteria.AccountSetupId = super.getAccountSetupId();
    return this.http.post<ServiceConsumablesSearchResult>(this.servicesConsumablesUrl + '/GetServiceConsumables', criteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('Fetched Service Branch');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ServiceConsumablesSearchResult>('Fetched Service Consumables'))
      );
  }

}
