import { PeriodStatus } from "../enums/gym-membership-period-status";
import { SearchCriteria } from "./search-criteria";
import { GymMembershipClientStatus } from "../enums/gym-membership-client-status";

export class GymClientMembershipsSearchCriteria extends SearchCriteria {

  public ClientIds: number[];
  public DateFrom: Date;
  public DateTo: Date;
  public GetUseFrozenDaysMemberships: boolean;
  public GetThatContainsActivities: boolean;
  public GymMembershipCode: number;
  public ForMultiFrozenDays: boolean;
  public MultiFrozenDaysStartDate: string;
  public MultiFrozenDaysEndDate: string;
  public MultiFrozenDaysCount: number;
  public MembershipStatus: PeriodStatus;
  public ClientStatus: GymMembershipClientStatus;
  public GetActiveOnly: boolean;

}

