export enum AddedByDevice {

  GlameraBusinessWebInBranch = 1,
  GlameraWebAndCustom = 2,
  Android = 3,
  Ios = 4,
  AdminPanel = 5,
  BookingLink = 6

}
