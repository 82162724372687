import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { DiscountType } from "../../../models/classes/cashier/discount-type";
import { Client } from "../../../models/classes/client/client";
import { AttendMethodMaster } from "../../../models/classes/employee/attend-method-master";
import { Department } from "../../../models/classes/employee/department";
import { Position } from "../../../models/classes/employee/position";
import { DropDowDTO, MASTER_CODES } from "../../../models/classes/other/detail-code";
import { VisaType } from "../../../models/classes/other/visa-type";
import { ProductUnit } from "../../../models/classes/product/product-unit";
import { RetailProduct } from "../../../models/classes/product/retail-product";
import { Unit } from "../../../models/classes/product/unit";
import { ServiceGroupingDetail } from "../../../models/classes/service/service-grouping-detail";
import { StockMainData } from "../../../models/classes/stock/stock-main-data";
import { Supplier } from "../../../models/classes/supplier/supplier";
import { Transactions } from "../../../models/classes/transactions/transactions";
import { AccountCurrencyService } from "../../../services/account/account-currency.service";
import { AccountSetupService } from "../../../services/account/account-setup.service";
import { AppTagsService } from "../../../services/app/app-tags.service";
import { AppServicesService } from "../../../services/app/appservices.service";
import { ABookingService } from "../../../services/booking/abooking.service";
import { CashierDailyService } from "../../../services/cashier/cashier-daily.service";
import { CashierPaymentService } from "../../../services/cashier/cashier-payment.service";
import { CashierMainDataService } from "../../../services/cashier/cashier.service";
import { CategoryService } from "../../../services/client/category.service";
import { ClientFactorService } from "../../../services/client/client-factor.service";
import { ClientService } from "../../../services/client/client.service";
import { CompanyCategoriesService } from "../../../services/company/company-categories.service";
import { CompanyService } from "../../../services/company/company.service";
import { CompanyServicesService } from "../../../services/company/companyservices.service";
import { AttendMethodMasterService } from "../../../services/employee/attend-method-master.service";
import { DepartmentService } from "../../../services/employee/department.service";
import { EmployeeService } from "../../../services/employee/employee.service";
import { HrSalaryItemsMasterService } from "../../../services/employee/hr-salary-items-master.service";
import { PositionService } from "../../../services/employee/position.service";
import { OfferSettingService } from "../../../services/offers/offer-setting.service";
import { DetailCodeService } from "../../../services/other/detail-code.service";
import { SharedService } from "../../../services/other/shared.service";
import { SupplierService } from "../../../services/other/supplier.service";
import { VisaTypeService } from "../../../services/other/visa-type.service";
import { ProductUnitsService } from "../../../services/product/product-units.service";
import { RetailProductService } from "../../../services/product/retail-product.service";
import { UnitService } from "../../../services/product/unit.service";
import { ScreenModuleService } from "../../../services/security-group/screen-module.service";
import { SecurityGroupService } from "../../../services/security-group/securitygroup.service";
import { ServiceService } from "../../../services/service/service.service";
import { StockMainDataService } from "../../../services/stock/stock-main-data-service";
import { DiscountTypeService } from "../../../services/transaction/discount-type.service";
import { EquipmentOrRoomService } from "../../../services/transaction/equipment-or-room.service";
import { PettyCashItemsService } from "../../../services/transaction/petty-cash-items.service";
import { Transaction1Service } from "../../../services/transaction/transaction.service";
import { Booking } from "../../bookings/models/booking";
import { Category } from "../../bookings/models/category";
import { Employee } from "../../bookings/models/employee";
import { EquipmentOrRoom } from "../../bookings/models/equipmentOrRoom";
import { Service } from "../../bookings/models/Service";


@Injectable({
    providedIn: 'root'
})

export class AutoCompleteService {
    SearchCriteria: any = {
        PagingEnabled: true,
        PageNumber: 0,
        PageSize: 10,
        FreeText: ''
    }
    constructor(private _clientService: ClientService,
        private _transactionService: Transaction1Service,
        private _bookingService: ABookingService,
        private accountCurrencyService: AccountCurrencyService,
        private accountSetupService: AccountSetupService,
        private attendMethodMasterService: AttendMethodMasterService,
        private appTagsService: AppTagsService,
        private appServicesService: AppServicesService,


        private sharedService: SharedService,
        private companyService: CompanyService,
        private companyServicesService: CompanyServicesService,
        private companyCategoriesService: CompanyCategoriesService,
        private cashierPaymentService: CashierPaymentService,
        private cashierService: CashierMainDataService,
        private cashierDailyService: CashierDailyService,
        private _categoryService: CategoryService,
        private clientFactorService: ClientFactorService,

        private detailCodeService: DetailCodeService,
        private _departmentService: DepartmentService,
        private discountTypeService: DiscountTypeService,

        private _equipmentOrRoomService: EquipmentOrRoomService,
        private _employeeService: EmployeeService,


        private hrSalaryItemsMasterService: HrSalaryItemsMasterService,


        private pettyCashItemsService: PettyCashItemsService,
        private productUnitsService: ProductUnitsService,
        private offerSettingService: OfferSettingService,
        private _supplierService: SupplierService,
        private _stockMainDataService: StockMainDataService,
        private _serviceService: ServiceService,


        private _productService: RetailProductService,
        private _positionService: PositionService,
        private _unitService: UnitService,

        private visaTypeService: VisaTypeService,
        private SecurityGroupService: SecurityGroupService,
        private screenModuleService: ScreenModuleService


    ) {
    }


    getLookupData(lookupKey): Observable<DropDowDTO[]> {
        switch (lookupKey) {
            case '11': {
                return this.GetDropDown(lookupKey);
            }
        }
    }



    private GetDropDown(masterCode: number): Observable<DropDowDTO[]> {

        return this.detailCodeService.getDropDownsDTO(masterCode)

            .pipe(map(res => {



                if (MASTER_CODES.Cities == masterCode) {
                    this.setDropDownsProp(res.Dropdowns.Cities);
                    return res.Dropdowns.Cities;
                } else if (MASTER_CODES.Currencies == masterCode) {
                    this.setDropDownsProp(res.Dropdowns.Currencies);

                    return res.Dropdowns.Currencies;
                }
                else if (MASTER_CODES.Areas == masterCode) {
                    this.setDropDownsProp(res.Dropdowns.Areas);

                    return res.Dropdowns.Areas;
                }
                else if (MASTER_CODES.Classifications == masterCode) {
                    this.setDropDownsProp(res.Dropdowns.Classifications);

                    return res.Dropdowns.Classifications;
                }
                else if (MASTER_CODES.Countries == masterCode) {
                    this.setDropDownsProp(res.Dropdowns.Countries);
                    return res.Dropdowns.Countries;
                }
                else if (MASTER_CODES.Brands == masterCode) {

                    let x = res;
                    this.setDropDownsProp(res.Dropdowns.Brands);
                    return res.Dropdowns.Brands;
                }
                else if (MASTER_CODES.BlackListReasons == masterCode) {

                    let x = res;
                    this.setDropDownsProp(res.Dropdowns.BlackListReasons);
                    return res.Dropdowns.BlackListReasons;
                }


            }));
    }
    private setDropDownsProp(list: DropDowDTO[]) {
        list.forEach(x => {
            x['Id'] = x.dbId;
            x['Name'] = x.na;
            x['NameEn'] = x.na;
        });

    }

    formSearchCriteria(criteria: any) {
        this.SearchCriteria = {};
        if (typeof criteria == "object") {
            for (var key in criteria) {
                // skip loop if the property is from prototype
                if (!criteria.hasOwnProperty(key)) continue;
                this.SearchCriteria[key] = criteria[key];
            }
        }
        this.SearchCriteria.ForAutoComplete = 1;

    }

    handelIfEditCase(isEdit: boolean, searchWord) {

        if (isEdit) {
            this.SearchCriteria.Id = searchWord;
            this.SearchCriteria.FreeText = '';
        } else {
            this.SearchCriteria.FreeText = searchWord;
        }
    }

    getData(lookupKey, searchWord, criteria, isEdit): Observable<any[]> {

        if (lookupKey == 1001) {

        }

        this.formSearchCriteria(criteria);
        this.handelIfEditCase(isEdit, searchWord);

        if (lookupKey < 1000) {
            return this.GetDropDown(lookupKey);
        }
        //------------

        //------------
        let dataList;
        switch (lookupKey) {

            case '1001': {

                dataList = this.getClients(criteria);
                break;
            }
            case '1002': {
                dataList = this.getTransactions(criteria);
                break;

            }
            case '1003': {
                dataList = this.getSuppliers(criteria);
                break;

            }
            case '1004': {

                dataList = this.getProducts(criteria);
                break;

            }
            case '1005': {
                dataList = this.getEquipments(criteria);
                break;

            }
            case '1006': {
                dataList = this.getRooms(criteria);
                break;
            }
            case '1007': {
                dataList = this.getEmployees(criteria);
                break;
            }
            case '1008': {

                dataList = this.getServices(criteria);
                break;
            }
            case '1009': {

                dataList = this.getCategories(criteria);
                break;
            }
            case '1010': {
                dataList = this.getUnits(criteria);
                break;
            }
            case '1011': {

                dataList = this.getStocks(criteria);
                break;

            }
            case '1012': {
                dataList = this.getBranches(criteria);
                break;

            }
            case '1013': {
                dataList = this.getCashiers(criteria);
                break;
            }
            case '1014': {
                dataList = this.getAccountCurrencies(criteria);
                break;
            }
            case '1015': {
                dataList = this.getPettyCashItems(criteria);
                break;

            }
            case '1016': {

                dataList = this.getPackages(criteria);
                break;

            }

            case '1017': {
                dataList = this.getGiftCards(criteria);
                break;

            }
            case '1018': {
                dataList = this.getVouchers(criteria);
                break;

            }
            case '1019': {
                dataList = this.getOffers(criteria);
                break;

            }
            case '1020': {
                dataList = this.getDiscountTypes(criteria);
                break;

            }
            case '1021': {
                dataList = this.getVisaTypes(criteria);
                break;
            }
            case '1022': {
                dataList = this.attendMethod(criteria);
                break;
            }
            case '1023': {

                dataList = this.getProductUnits(2);
                break;
            }

            case '1024': {
                dataList = this.getAppServices(criteria);
                break;
            }
            case '1025': {
                dataList = this.getClientFactors(criteria);
                break;
            }
            case '1026': {
                dataList = this.getCashierDailyes(criteria);
                break;
            }
            case '1027': {
                dataList = this.getCompanyServices(criteria);
                break;
            }
            case '1028': {
                dataList = this.getCompanyCategories(criteria);
                break;
            }

            case '1029': {
                dataList = this.getAppPackages(criteria);
                break;
            }
            case '1030': {


                dataList = this.getDepartments(criteria);
                break;

            }
            case '1031': {


                dataList = this.getPositions(criteria);
                break;

            }
            //------------------------------
            case '2000': {
                dataList = this.getPaymentTypes(1);
                break;
            }
            case '2001': {

                dataList = this.getPaymentTypes(2);
                break;

            }
            case '2002': {

                dataList = this.getHrSalMatres(criteria);
                break;

            }

            case '2003': {


                dataList = this.getAccountSetups(criteria);
                break;

            }
            case '2004': {

                dataList = this.getGlameraServices(criteria);
                break;

            }
            case '2005': {

                dataList = this.getGlameraServices(criteria);
                break;
            }
            case '2006': {
                dataList = this.GetDropDown(MASTER_CODES.Brands);
                break;
            }
            case '2007': {
                dataList = this.GetBookings(criteria);
                break;
            }
            case '2008': {
                dataList = this.getSecurityGroup(criteria);
                break;
            }
            case '2009': {
                dataList = this.getScreenPermissions(criteria);
                break;
            }
            case '2010': {
                dataList = this.getScreenModules(criteria);
                break;
            }
            case '2011': {

                dataList = this.getMainScreenModules(criteria);
                break;
            }
            default: {
                dataList = of([]);
            }

        }

        return dataList;
    }

    loopDataToAssignCriteria(criteria: any, data: any[]) {

        if (typeof criteria == "object") {
            for (var key in criteria) {
                if (!criteria.hasOwnProperty(key)) continue;
                data.forEach(ww => {
                    ww[key] = criteria[key];
                })
            }
        }

    }

    private getTransactions(criteria: any): Observable<Transactions[]> {
        return this._transactionService.searchTransactions(this.SearchCriteria)
            .pipe(map(res => {
                //console.log(res);
                this.loopDataToAssignCriteria(criteria, res.Transactions);

                // res.Transactions.forEach(x=>{
                //     x['NameAr'] =  x['DateFormated'];
                //     x['NameEn'] =  x['DateFormated'];
                // })
                return res.Transactions
            }
            ));
    }

    private GetBookings(criteria: any): Observable<Booking[]> {


        return this._bookingService.searchBookings(this.SearchCriteria)
            .pipe(map(res => {
                console.log(res);
                this.loopDataToAssignCriteria(criteria, res.Bookings);

                // res.Transactions.forEach(x=>{
                //     x['NameAr'] =  x['DateFormated'];
                //     x['NameEn'] =  x['DateFormated'];
                // })
                return res.Bookings
            }
            ));
    }


    private getSuppliers(criteria: any): Observable<Supplier[]> {
        return this._supplierService.searchSuppliers(this.SearchCriteria)
            .pipe(map(res => {
                this.loopDataToAssignCriteria(criteria, res.Suppliers);
                return res.Suppliers;
            }));
    }

    private getDiscountTypes(criteria: any): Observable<DiscountType[]> {
        return this.discountTypeService.searchDiscountTypes(this.SearchCriteria)
            .pipe(map(res => {
                this.loopDataToAssignCriteria(criteria, res.DiscountTypes);
                return res.DiscountTypes;
            }));
    }

    private getVisaTypes(criteria: any): Observable<VisaType[]> {
        return this.visaTypeService.searchVisaTypes(this.SearchCriteria)
            .pipe(map(res => {
                this.loopDataToAssignCriteria(criteria, res.VisaTypes);
                return res.VisaTypes;
            }));
    }


    private getProducts(criteria: any): Observable<RetailProduct[]> {
        return this._productService.searchRetailProducts(this.SearchCriteria)
            .pipe(map(res => {
                this.loopDataToAssignCriteria(criteria, res.RetailProducts);
                return res.RetailProducts;
            }));
    }

    private getStocks(criteria: any): Observable<StockMainData[]> {
        return this._stockMainDataService.searchStock(this.SearchCriteria)
            .pipe(map(res => {

                let x = res;
                this.loopDataToAssignCriteria(criteria, res.Stocks);
                return res.Stocks;
            }));
    }

    private getEquipments(criteria: any): Observable<EquipmentOrRoom[]> {
        this.SearchCriteria.Type = 1;
        return this._equipmentOrRoomService.searchEquipmentsOrRooms(this.SearchCriteria)
            .pipe(map(res => res.EquipmentsOrRooms));
    }

    private getRooms(criteria: any): Observable<EquipmentOrRoom[]> {
        this.SearchCriteria.Type = 2;
        return this._equipmentOrRoomService.searchEquipmentsOrRooms(this.SearchCriteria)
            .pipe(map(res => {
                this.loopDataToAssignCriteria(criteria, res.EquipmentsOrRooms);
                return res.EquipmentsOrRooms;
            }));
    }

    private getEmployees(criteria: any): Observable<Employee[]> {
        return this._employeeService.searchEmployees(this.SearchCriteria)
            .pipe(map(res => res.Employees));
    }

    private getDepartments(criteria: any): Observable<Department[]> {

        return this._departmentService.searchDepartment(this.SearchCriteria)
            .pipe(map(res => res.Departments));
    }

    private getPositions(criteria: any): Observable<Position[]> {

        return this._positionService.searchPosition(this.SearchCriteria)
            .pipe(map(res => res.EmployeePositions));
    }



    private attendMethod(criteria: any): Observable<AttendMethodMaster[]> {
        return this.attendMethodMasterService.searchAttendMethodMasters(this.SearchCriteria)
            .pipe(map(res => res.AttendMethodMasters));
    }

    private getClients(criteria: any): Observable<Client[]> {


        // this.SearchCriteria.PageSize=300;
        // this.SearchCriteria.PagingEnabled=true;
        // this.SearchCriteria.PageNumber=0
        return this._clientService.searchClients(this.SearchCriteria)
            .pipe(map(res => {
                this.loopDataToAssignCriteria(criteria, res.ClientsBasicData);
                return res.ClientsBasicData;
            }));
    }

    private getCategories(criteria: any): Observable<Category[]> {

        return this._categoryService.searchCategories(this.SearchCriteria)
            .pipe(map(res => {
                this.loopDataToAssignCriteria(criteria, res.Categories);
                return res.Categories;
            }));
    }

    private getUnits(criteria: any): Observable<Unit[]> {

        return this._unitService.searchUnits(this.SearchCriteria)
            .pipe(map(res => {
                this.loopDataToAssignCriteria(criteria, res.Units);
                return res.Units;
            }));
    }

    private getServices(criteria: any): Observable<Service[]> {


        this.SearchCriteria.Status = 1;
        return this._serviceService.searchServicesBasic(this.SearchCriteria)
            .pipe(map(res => res.Services));
    }

    private getGlameraServices(criteria: any): Observable<ServiceGroupingDetail[]> {

        this.SearchCriteria.Status = 1;
        this.SearchCriteria.PagingEnabled = false;

        return this._categoryService.searchGlameraServices(this.SearchCriteria).pipe(map(res => {

            if (res.State == 100) {
                return res.Result
            }
            // return res.Result;
        }));
    }




    // private getGlameraCategoriesAndOffers(criteria: any): Observable<ServiceGrouping[]> {

    //     this.SearchCriteria.Status = 1;
    //     this.SearchCriteria.PagingEnabled=false;
    //     this.SearchCriteria.AccountSetupId=this._categoryService.getAccountSetupId();
    //     return this._categoryService.searchGlameraCategoriesAndOffers(this.SearchCriteria)
    //         .pipe(map(res => {

    //              let x=res.Result.Services;
    //             return res.Result.Services
    //         }
    //         ));
    // }


    // private getGlameraCategoriesAndOffersServices(criteria: any)// :Observable<ServiceGroupingDetail[]>
    //  {
    //  this.getGlameraCategoriesAndOffers(criteria).subscribe(res=>{

    //     return res.map(x=>x.Services);

    //  })
    // }

    private getBranches(criteria: any) {

        return this.companyService.getBranches(localStorage.getItem('username'))
            .pipe(map(res => {
                this.loopDataToAssignCriteria(criteria, res.Result);
                return res.Result;
            }));
    }

    private getCashiers(criteria: any) {

        return this.cashierService.searchCashierMainDatas(this.SearchCriteria)
            .pipe(map(res => {
                this.loopDataToAssignCriteria(criteria, res.Cashiers);
                return res.Cashiers;
            }));
    }

    private getCashierDailyes(criteria: any) {

        return this.cashierDailyService.searchCashierDailys(this.SearchCriteria)
            .pipe(map(res => {
                this.loopDataToAssignCriteria(criteria, res.CashierDailys);
                return res.CashierDailys;
            }));
    }
    private getAccountCurrencies(criteria: any) {

        return this.accountCurrencyService.searchAccountCurrencys(this.SearchCriteria)
            .pipe(map(res => res.AccountCurrencys));
    }

    private getPettyCashItems(criteria: any) {

        return this.pettyCashItemsService.searchPettyCashItems(this.SearchCriteria)
            .pipe(map(res => {
                this.loopDataToAssignCriteria(criteria, res.PettyCashItems);
                return res.PettyCashItems;
            }));
    }

    private getPaymentTypes(index: number) {
        return this.cashierPaymentService.getPaymentTypes(index)
            .pipe(map(res => res));
    }


    private getProductUnits(criteria: any): Observable<ProductUnit[]> {
        return this.productUnitsService.searchProductUnits(this.SearchCriteria)
            .pipe(map(res => {

                this.loopDataToAssignCriteria(criteria, res['ProductUnits']);
                return res['ProductUnits'];
            }));
    }

    private getPackages(criteria: any) {

        return this.offerSettingService.getPackages(this.SearchCriteria)
            .pipe(map(res => {

                let re = res;

                this.loopDataToAssignCriteria(criteria, res.Result);
                return res.Result;
            }));
    }
    private getGiftCards(criteria: any) {
        return this.offerSettingService.getGiftCards(this.SearchCriteria)
            .pipe(map(res => {
                this.loopDataToAssignCriteria(criteria, res.Result);
                return res.Result;
            }));
    }
    private getVouchers(criteria: any) {
        return this.offerSettingService.getVouchers(this.SearchCriteria)
            .pipe(map(res => {
                this.loopDataToAssignCriteria(criteria, res.Result);
                return res.Result;
            }));
    }
    private getOffers(criteria: any) {
        return this.offerSettingService.getOffers(this.SearchCriteria)
            .pipe(map(res => {
                this.loopDataToAssignCriteria(criteria, res.Result);
                return res.Result;
            }));
    }
    private getAppServices(criteria: any) {
        return this.appServicesService.searchappServices(this.SearchCriteria)
            .pipe(map(res => {
                this.loopDataToAssignCriteria(criteria, res.AppServicess);
                return res.AppServicess;
            }));
    }

    private getCompanyServices(criteria: any) {
        return this.companyServicesService.searchcompanyServices(this.SearchCriteria)
            .pipe(map(res => {
                this.loopDataToAssignCriteria(criteria, res.CompanyServicess);
                return res.CompanyServicess;
            }));
    }

    private getCompanyCategories(criteria: any) {
        return this.companyCategoriesService.searchComapnyCategories(this.SearchCriteria)
            .pipe(map(res => {

                this.loopDataToAssignCriteria(criteria, res.ComapnyCategoriess);
                return res.ComapnyCategoriess;
            }));
    }

    private getAppPackages(criteria: any) {
        this.SearchCriteria.Types = '3,4'
        return this.appTagsService.searchAppTags(this.SearchCriteria)
            .pipe(map(res => {
                this.loopDataToAssignCriteria(criteria, res.Result);
                return res.Result;
            }));
    }


    private getClientFactors(criteria: any) {
        return this.clientFactorService.searchClientFactors(this.SearchCriteria)
            .pipe(map(res => {
                this.loopDataToAssignCriteria(criteria, res.ClientFactorsLookups);
                return res.ClientFactorsLookups;
            }));
    }


    private getHrSalMatres(criteria: any) {
        return this.hrSalaryItemsMasterService.searchHrSalaryItemsMasters(this.SearchCriteria)
            .pipe(map(res => {
                this.loopDataToAssignCriteria(criteria, res.HrSalItemsMasters);
                return res.HrSalItemsMasters;
            }));
    }

    private getAccountSetups(criteria: any) {
        return this.accountSetupService.getForAutoComplete(this.SearchCriteria)
            .pipe(map(res => {
                this.loopDataToAssignCriteria(criteria, res.AccountSetupBasic);
                return res.AccountSetupBasic;
            }));
    }

    private getSecurityGroup(criteria: any) {

        return this.SecurityGroupService.getSecurityGroup(this.SearchCriteria)
            .pipe(map(res => {
                this.loopDataToAssignCriteria(criteria, res.Result);
                return res.Result;
            }));
    }

    private getScreenPermissions(criteria: any) {

        return this.screenModuleService.GetScreenWithPermissions(this.SearchCriteria)
            .pipe(map(res => {
                this.loopDataToAssignCriteria(criteria, res.screenModules);
                return res.screenModules
            }));
    }

    private getScreenModules(criteria: any) {
        let sublinks: any[] = [];

        return this.screenModuleService.getScreenModules(this.SearchCriteria)
            .pipe(map(res => {
                let index = 0;
                res.screenModules.forEach(links => {
                    let Isthreelevel = -1;
                    links.ScreenModules.forEach(s => {

                        s.ScreenModules.forEach(e => {
                            Isthreelevel = -1;
                        }
                        )
                    })
                    links.ScreenModules.forEach(s => {

                        s.ScreenModules.forEach(e => {
                            Isthreelevel = 1;
                        }
                        )
                    })

                    if (Isthreelevel == 1) {

                        links.ScreenModules.forEach(f => {
                            sublinks.push(f);
                        })
                        sublinks.forEach(newlinks => {

                            newlinks.TitleAr = newlinks.TitleAr + '/' + links.TitleAr;
                            newlinks.TitleEn = newlinks.TitleEn + '/' + links.TitleEn;

                        })
                        res.screenModules.slice(index, index + 1);

                        sublinks.forEach(s => {
                            res.screenModules.push(s);
                        })
                    }
                    index++;
                })

                res.screenModules.forEach(s => {
                    s.NameAr = s.TitleAr,
                        s.NameEn = s.TitleEn

                })
                this.loopDataToAssignCriteria(criteria, res.screenModules);
                return res.screenModules
            }));
    }
    private getMainScreenModules(criteria: any) {


        return this.screenModuleService.getScreenModules(this.SearchCriteria)
            .pipe(map(res => {
                res.screenModules.forEach(s => {
                    s.NameAr = s.TitleAr,
                        s.NameEn = s.TitleEn

                })
                this.loopDataToAssignCriteria(criteria, res.screenModules);

                return res.screenModules
            }));
    }
}

