import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CostCenter } from '../../../models/classes/accounts/cost-center';
import { ExecutionResponse } from '../../../models/support/execution-response';
import { BaseService, BaseServiceSZ } from '../../../services/other/baseService';
import { SharedService } from '../../../services/other/shared.service';
import { FinancialPeriodCriteria } from 'src/app/models/search-criterias/financial-period-criteria';
import { FinancialPeriodsSearchResult } from 'src/app/models/search-result/financial-periods-search-result';

@Injectable({
  providedIn: 'root'
})


export class FinancialPeriodService extends BaseService {
  financialPeriodUrl: string;
  constructor(private http: HttpClient,
    private _BaseService: BaseServiceSZ,
    private sharedService: SharedService) {
    super();

    this.financialPeriodUrl = this.sharedService.ApiUrl + 'Journal';
  }

  GetClosingPeriods(criteria?: FinancialPeriodCriteria): Observable<FinancialPeriodsSearchResult> {


    return this.http.post<FinancialPeriodsSearchResult>(this.financialPeriodUrl + '/GetClosingPeriods', criteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);
        }),
      );
  }

  CreateJournalForClosingPeriod(CostCenter?: CostCenter): Observable<ExecutionResponse<CostCenter>> {

    return this.http.post<ExecutionResponse<CostCenter>>(this.financialPeriodUrl + '/CreateJournalForClosingPeriod', CostCenter, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);

        }),

        catchError(this._BaseService.handleError<ExecutionResponse<CostCenter>>('CreateJournalForClosingPeriod'))
      );
  }



}
