import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChangeDetectorRef, Injectable } from '@angular/core';

@Pipe({
  name: 'dateLang',
  pure: false
})
@Injectable({
  providedIn: 'root'
})
export class DateLangPipe implements PipeTransform {

  constructor(
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef
  ) {

    this.translateService.onLangChange.subscribe(() => {
      this.cdr.markForCheck();
    });
  }

  transform(value: Date | any, ...args: any[]): any {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    value = new Date(value);

    if (this.translateService.currentLang === 'ar') {
      return value.toLocaleTimeString('ar-EG') + '  -  ' + value.toLocaleDateString('ar-EG', options);
    } else {
      return value.toLocaleTimeString('en-US') + '  -  ' + value.toLocaleDateString('en-US', options);
    }
  }
}
