import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { RESPONCE } from '../model/responce';
import { BRANCH } from '../model/branch.model';
import { AutoRegistrationService } from '../services/auto-registration/auto-registration.service';
import { Observable, of } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';

export const branchesResolver: ResolveFn<Observable<RESPONCE<BRANCH[]>>> = (
  route,
  state
): Observable<RESPONCE<BRANCH[]>> => {
  const autoRegisterService = inject(AutoRegistrationService);
  return autoRegisterService.getBranches({ PageNumber: 0, PageSize: 10 }).pipe(
    take(1),
    mergeMap((response) => {
      return of(response);
    })
  );
};
