<div class="row">
    <div class="col-6">
        <div class="card-upload flex-column" (click)="file.click()">
          <i class="fas fa-plus"></i>
          <p> اختار الصوره </p>
        </div>
      </div>
      <div class="col-6" *ngIf="path">
        <div class="card-upload">
          <img [src]="path" class="img-fluid" alt="">
        </div>
      </div>
    
      <div class="col-sm-3 col-6">
        <div>
          <br>
          <br>
          <input type="file" hidden accept=".png, .jpg,.jpeg" class="form-control" name="file" id="file"
            (change)="UploadBranchLogo($event)" #file>
        </div>
      </div>
</div>