import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LangModule } from './shared/language/lang.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { RatingModule } from 'ng-starrating';
import { ToastrModule } from 'ngx-toastr';
import { PaginationModule } from './shared/pagination/pagination.module';

import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgxPrintModule } from 'ngx-print';
import { ChartsModule } from 'ng2-charts';

import { environment } from '../environments/environment';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AgmCoreModule } from '@agm/core';
import { FileSaverModule } from 'ngx-filesaver';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FlatpickrModule } from 'angularx-flatpickr';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { SzAutocompleteModule } from './dashboard/shared/sz-autocomplete/sz-autocomplete.module';
import { SharedModule } from './shared/shared.module';
import { BaseService } from './services/other/baseService';
import { TreeviewModule } from 'ngx-treeview';
import { ServiceWorkerModule } from '@angular/service-worker';
import { DatePipe } from '@angular/common';
import {  NgxSpinnerModule } from 'ngx-spinner';
import { PureModule } from './pure/pure.module';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { DeleteStepsComponent } from './delete-steps/delete-steps.component';
import { CoreModule } from 'core';
import {  RegistrationModule } from 'auto-registration';
import { registrationRoutesConfig } from './auth-new/registration/registration-routing.module';
import { LogoutComponent } from './auth/logout/logout.component'; 



@NgModule({
  declarations: [AppComponent, PrivacyPolicyComponent, DeleteStepsComponent   ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    SharedModule,
    NgxSpinnerModule  ,
    SzAutocompleteModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgSelectModule,
    NgOptionHighlightModule,
    ChartsModule,
    NgxPrintModule,
    RatingModule,
    LangModule,
    PaginationModule,
    NgxPrintModule,
    PureModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDha_i_u5mxnPiPtrwfJImhW1xLMfTJS_w',
      libraries: ['places'],
    }),
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    CoreModule.withConfig({
      ApiBaseUrl: environment.NewApiUrl,
    }),
    RegistrationModule.withConfig({
      routes: registrationRoutesConfig
    }),

    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgbModule,
    NgbModalModule,
    FileSaverModule,
    FlatpickrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),

    TreeviewModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
  ],
  providers: [BaseService, DatePipe, NgbActiveModal],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule { }
