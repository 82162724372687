import { Component } from '@angular/core';

@Component({
  selector: 'app-pure-delete-steps',
  templateUrl: './pure-delete-steps.component.html',
  styleUrls: ['./pure-delete-steps.component.scss']
})
export class PureDeleteStepsComponent {

}
