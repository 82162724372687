import { Injectable } from '@angular/core';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { HttpClient } from '@angular/common/http';
import { CompanyImagesServiceEditedName } from '../company/company-image-edited-name.service';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { OfferSetting } from '../../models/classes/offers/offer-seting';
import { Package } from '../../models/classes/offers/offer-setting-base';
import { PaidItemsPrices } from '../../models/classes/transactions/paid-items-prices';
import { OffersSettingDtoSearchCriteria } from '../../models/search-criterias/offers-setting-search-criteria';
import { OffersettingBaseSearchCriteria } from '../../models/search-criterias/offer-setting-base-search-criteria';
import { OfferSettingSearchCriteria } from '../../models/search-criterias/offerSettingSearchCriteria';
import { OfferSettingSearchResult } from '../../models/search-result/offer-setting-search-result';
import { PackageSearchResult } from '../../models/search-result/package-search.result';
import { ExecutionResponse } from '../../models/support/execution-response';
import { ValidationCriteria } from '../../models/support/validation-criterias/validation-criteria';
import { formValueDates } from '../../shared/helpers';


@Injectable({
  providedIn: 'root'
})
export class OfferSettingService extends BaseService {
  private offersettingUrl //= super.BaseUrl() + 'OffersSetting';  // URL to web api

  constructor(private http: HttpClient, private _BaseService: BaseServiceSZ, 
    private companyImagesService: CompanyImagesServiceEditedName) {
    super();
    this.offersettingUrl = this._BaseService.ApiUrl + 'OffersSetting';
  }
  /** GET offerSetting by id. Will 404 if id not found */
  getOffersSetting(id: String): Observable<ExecutionResponse<OfferSetting>> {
    //  getOfferSetting(id: String): ExecutionResponse<OfferSetting> {
    //const url = '${this.offersettingUrl}/${id}';
     
    let criteria=new OffersSettingDtoSearchCriteria();
    criteria.Id=id;

    return this.http.post<ExecutionResponse<OfferSetting>>(this.offersettingUrl + '/getById', criteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched offer Setting id=${id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<OfferSetting>>('getOfferSetting id=${id}'))
      );
  }

  getAll(): Observable<ExecutionResponse<OfferSetting[]>> {
    return this.http.post<ExecutionResponse<OfferSetting[]>>(this.offersettingUrl + '/getAll', null, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<OfferSetting[]>>('GetPackages[]'))
      );
  }


  addOfferSetting(offersSetting: OfferSetting): Observable<ExecutionResponse<OfferSetting>> {
    offersSetting = formValueDates(offersSetting, true);
    offersSetting.CompanyId = this.companyImagesService.getCompanyId();
    // offerSetting.AccountSetupId = super.getAccountSetupId();
    return this.http.post<ExecutionResponse<OfferSetting>>(this.offersettingUrl + '/Create', offersSetting, super.getHeaders())
      .pipe(
        tap(res => {
          
          this._BaseService.log('added offerSetting w/ id=${offerSetting.Id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<OfferSetting>>('addOfferSetting id=${id}'))
      );
  }


  /* GET categories whose name contains search term */
  searchOffersSettings(offerSettingSearchCriteria: OfferSettingSearchCriteria): Observable<OfferSettingSearchResult> {
    offerSettingSearchCriteria.AccountSetupId = super.getAccountSetupId();
    offerSettingSearchCriteria.CompanyId = this.companyImagesService.getCompanyId();

    return this.http.post<OfferSettingSearchResult>(this.offersettingUrl + '/Get', offerSettingSearchCriteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched categories');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<OfferSettingSearchResult>('searchCategories id=${id}'))
      );
  }

  /** DELETE: delete the offerSetting from the server */
  deleteOfferSetting(offerSetting: OfferSetting | number): Observable<ExecutionResponse<OfferSetting>> {
    return this.http.post<ExecutionResponse<OfferSetting>>(this.offersettingUrl + '/delete', offerSetting, super.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deleted offerSetting id=${id}');
        this._BaseService.ValidationResult(res);
      }),

      catchError(this._BaseService.handleError<ExecutionResponse<OfferSetting>>('deleteOfferSetting'))
    );
  }

  /** PUT: update the offerSetting on the server */
  updateOfferSetting(offerSetting: OfferSetting): Observable<ExecutionResponse<OfferSetting>> {

    offerSetting = formValueDates(offerSetting, true);
    // offerSetting.AccountSetupId = super.getAccountSetupId();
    offerSetting.CompanyId = this.companyImagesService.getCompanyId();
    return this.http.post<ExecutionResponse<OfferSetting>>(this.offersettingUrl + '/Update', offerSetting, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('added offerSetting w/ id=${offerSetting.Id}');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<ExecutionResponse<OfferSetting>>('addOfferSetting id=${id}'))
      );
  }

  getPackages(packageSearchCriteria: OffersettingBaseSearchCriteria): Observable<ExecutionResponse<Package[]>> {
    packageSearchCriteria.AccountSetupId = super.getAccountSetupId();
    return this.http.post<ExecutionResponse<Package[]>>(this.offersettingUrl + '/GetPackages', packageSearchCriteria, super.getHeaders())
      .pipe(
        tap(res => {
          
          let x=res;
       
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<Package[]>>('GetPackages[]'))
      );
  }
  getGiftCards(giftcardSearchCriteria: OffersettingBaseSearchCriteria): Observable<ExecutionResponse<Package[]>> {
    giftcardSearchCriteria.AccountSetupId = super.getAccountSetupId();
    return this.http.post<ExecutionResponse<Package[]>>(this.offersettingUrl + '/GetGiftCards', giftcardSearchCriteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<Package[]>>('GetGiftCards[]'))
      );
  }
  getVouchers(voucherSearchCriteria: OffersettingBaseSearchCriteria): Observable<ExecutionResponse<Package[]>> {
    voucherSearchCriteria.AccountSetupId = super.getAccountSetupId();
    return this.http.post<ExecutionResponse<Package[]>>(this.offersettingUrl + '/GetVouchers', voucherSearchCriteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<Package[]>>('GetVouchers[]'))
      );
  }
  getOffers(offerSearchCriteria: OffersettingBaseSearchCriteria): Observable<ExecutionResponse<Package[]>> {
    return this.http.post<ExecutionResponse<Package[]>>(this.offersettingUrl + '/GetOffers', offerSearchCriteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<Package[]>>('GetOffers[]'))
      );
  }


  getPackageDetails(offerSearchCriteria: OffersettingBaseSearchCriteria): Observable<PackageSearchResult> {
    offerSearchCriteria.AccountSetupId = super.getAccountSetupId();
    offerSearchCriteria.CompanyId = this.companyImagesService.getCompanyId();
    return this.http.post<PackageSearchResult>(this.offersettingUrl + '/GetPackageById', offerSearchCriteria, super.getHeaders())
      .pipe(
        tap(res => {
          
          let x=res;
         
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<PackageSearchResult>('GetPackageById id=${id}'))
      );
  }
  getPrice(paidItemsPrices: PaidItemsPrices): Observable<ExecutionResponse<PaidItemsPrices>> {
    paidItemsPrices.CompanyId = this.companyImagesService.getCompanyId();
    paidItemsPrices.AccountSetupId = super.getAccountSetupId();
    return this.http.post<ExecutionResponse<PaidItemsPrices>>(this.offersettingUrl + '/GetPrice', paidItemsPrices, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched Paid Items Prices');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<ExecutionResponse<PaidItemsPrices>>('Paid Items Prices'))
      );
  }


  IsNameUnique(validationCriteria: ValidationCriteria): Observable<ExecutionResponse<Boolean>> {
    return this.http.post<ExecutionResponse<Boolean>>(this.offersettingUrl + '/IsNameUnique', validationCriteria, super.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deleted OfferSetting id=${id}');
        this._BaseService.ValidationResult(res);
      }),

      catchError(this._BaseService.handleError<ExecutionResponse<Boolean>>('deleteOfferSetting'))
    );
  }





}
