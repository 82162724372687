import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PurePrivacyPolicyComponent } from './pure-privacy-policy/pure-privacy-policy.component';
import { PureDeleteStepsComponent } from './pure-delete-steps/pure-delete-steps.component';

const routes: Routes = [
  {path:'privacy-policy', component:PurePrivacyPolicyComponent},
  {path:'delete-steps', component:PureDeleteStepsComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PureRoutingModule { }
