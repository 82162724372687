import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, Renderer2 } from '@angular/core';
import {
  FormGroup,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AutoRegistrationService,
  BaseForm,
  BusinessDetailsService,
  COUNTRY,
  ComponentBase,
  SavePhasesDataType,
  SharedService,
} from 'core';
import { ToastrService } from 'ngx-toastr';
import { AutoRegistrationConfig } from '../../models/auto-registration-config';
import { CONFIG } from '../../registration.module';
import { TranslateService } from '@ngx-translate/core';
import { TermsConditionsComponent } from '../terms-conditions/terms-conditions.component';
import { Title } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { VideoComponent } from 'src/app/shared/video/video.component';
import { DOCUMENT } from '@angular/common';
import { SalesChatService } from 'projects/core/src/lib/services/sales-chat.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent extends ComponentBase implements BaseForm, OnInit {
  hide: boolean = true;
  form!: FormGroup;
  countries!: COUNTRY[];
  dir: string = localStorage.getItem('lang');

  constructor(
    private salesChatService: SalesChatService,
    private readonly router: Router,
    private readonly autoRegistrationService: AutoRegistrationService,
    private readonly sharedService: SharedService,
    private readonly toastr: ToastrService,
    @Inject(CONFIG) public config: AutoRegistrationConfig,
    public readonly dialog: MatDialog,
    public readonly translateService: TranslateService,
    readonly businessDetailsService: BusinessDetailsService,
    readonly route: ActivatedRoute,
    readonly title: Title
  ) {
    super();

    route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params['getRegisterData']) {
        businessDetailsService
          .getRegisteredPhases({
            RegistrationCycleLogId: +this.sharedService.getLocalStorage(
              'RegistrationCycleLogId'
            ),
          })
          .pipe(takeUntil(this.destroy$))
          .subscribe((response) => {
            const data = response?.Result?.One?.OneData;
            if (data) {
              this.form.get('FirstName')?.setValue(data.FirstName);
              this.form.get('LastName')?.setValue(data.LastName);
              this.form.get('Email')?.setValue(data.Email);
              this.form.get('MobileNumber')?.setValue(data.MobileNumber);
              this.form.get('CountryId')?.setValue(data.CountryId);
              this.form.get('Password')?.setValue(data.Password);
              this.form.get('AgreeToPrivacyPolicy')?.setValue(true);
            }
          });
      }
    });

    translateService.onLangChange.subscribe((_) => {
      title.setTitle(translateService.instant('Title_phase_one'));
    });
    title.setTitle(translateService.instant('Title_phase_one'));
  }
 

  ngOnInit(): void {
    this.initForm();
    this.getCountriesList();
    this.salesChatService.chatScript();

  }

  initForm(): void {
    this.form = new UntypedFormGroup({
      FirstName: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(10),
      ]),
      LastName: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(10),
      ]),
      Email: new UntypedFormControl(null, [
        Validators.required,
        Validators.email,
      ]),
      MobileNumber: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(12),
        Validators.minLength(4),
      ]),
      CountryId: new UntypedFormControl(null, [Validators.required]),
      Password: new UntypedFormControl(null, [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(20),
      ]),
      AgreeToPrivacyPolicy: new UntypedFormControl(false),
      SavePhasesDataType: new UntypedFormControl(SavePhasesDataType.Add),
    });
  }

  onOpenVideo(): void {
    this.dialog.open(VideoComponent, {
      minWidth: '60%',
      maxWidth: '100%',
      panelClass: 'aspect_ratio',
    });
  }

  private getCountriesList(): void {
    this.sharedService
      .getCountries()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.countries = res.Result;
        if (!this.form.get('CountryId').value) {
          this.form.patchValue({
            CountryId: this.countries.filter(
              (country) => country.IsCurrentCountry
            )[0]?.Id,
          });
        }
      });
  }

  getSelectedCountry(): COUNTRY {
    return this.countries.filter(
      (country) => country.Id === this.form.get('CountryId')?.value
    )[0];
  }

  public onOpenPrivacy(event: Event): void {
    event.preventDefault();
    this.dialog.open(TermsConditionsComponent);
  }

  onSubmit(): void {
    if (!this.form.get('AgreeToPrivacyPolicy')?.value) {
      this.toastr.warning(this.translateService.instant('PRIVACY_ERROR'));
      return;
    }
    this.loading = true;
    this.autoRegistrationService
      .signUpPhaseOne(this.form.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.loading = false;
        this.checkResponse = this.sharedService.checkResposeStatus(
          res.Message,
          res.State
        );
        if (this.checkResponse) {
          this.sharedService.setLocalStorage(
            'RegistrationCycleLogId',
            res.Result.RegistrationCycleLogId!
          );
          this.router.navigate(['../verify'], {
            skipLocationChange: true,
            queryParams: {
              RegistrationCycleLogId: res.Result.RegistrationCycleLogId,
              user: this.form.get('MobileNumber')?.value,
              requestType: 'auto',
            },
          });
        } else {
          if (
            res.Result.IsRegisteredBefore &&
            res.Result.IsMobileNumberVerified
          ) {
            this.router.navigate(['/']);
          }
        }
      });
  }


}
