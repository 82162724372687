import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { ClientBalanceStatus } from '../../models/enums/client-balance-status';
import { ResponseState } from '../../models/support/response-state';
import { SharedService } from '../../services/other/shared.service';
import { Client } from '../../models/classes/client/client';
import { GymClientMembershipService } from '../../services/gym/gym-client-membership.service';
import { ExecutionResponse } from '../../models/support/execution-response';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from 'src/app/services/client/client.service';
import { ClientSearchCriteria } from 'src/app/models/search-criterias/client-search-criteria';
import { GetLiteForGBClientDTO } from 'src/app/models/classes/client/get-lite-for-gb-client';
import { Group } from 'src/app/models/classes/client/group';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { GroupService } from 'src/app/services/client/group.service';
import { ClientBasicDTO } from 'src/app/dashboard/membership/clients/models/client-basic-dto';
import { GetCountriesCriteria } from 'src/app/models/search-criterias/registration-cycle/get-countries-criteria';
import { GroupsMasterSearchResultDTO } from 'src/app/models/classes/client/Groups-Master-Search-Result-DTO';
import { GetCitiesCriteria } from 'src/app/models/search-criterias/registration-cycle/get-cities-criteria';
import { ClientsGroupsMasterSearchCriteria } from 'src/app/models/search-criterias/Clients-Groups-Master-Search-Criteria';
import { NationalityDTO } from 'src/app/models/classes/registration-cycle/nationality-dto';
import { GetNationalitiesCriteria } from 'src/app/models/search-criterias/registration-cycle/get-nationalities-criteria';
import { RegistrationCyclePhaseOneService } from 'src/app/services/registration-cycle/registration-cycle-phase-one.service';
import { Gender } from 'src/app/models/enums/gender';
import { GetClientGroupDTO } from 'src/app/models/classes/client/get-client-group-dto';
import { GetClientGroupsSearchCriteria } from 'src/app/models/search-criterias/get-client-groups-search-criteria';



@Component({
  selector: 'app-modal-group-add-and-update',
  templateUrl: './modal-group-add-and-update.component.html',
  styleUrls: ['./modal-group-add-and-update.component.scss'],
  providers: [DatePipe]

})
export class ModalGroupAddAndUpdate implements OnInit {

  @Input() GroupId: number;
  @Output() public DoneSave = new EventEmitter<boolean>();

  public freeText: string;

  public freeTextBranches: string;
  public freeTextCountries: string;
  public freeTextCities: string;
  public freeTextCategories: string;
  public freeTextServices: string;
  public freeTextClients: string;
  public freeTextNationalities: string;

  public Branches: GroupsMasterSearchResultDTO[];
  public Countries: GroupsMasterSearchResultDTO[];
  public Cities: GroupsMasterSearchResultDTO[];
  public Categories: GroupsMasterSearchResultDTO[];
  public Services: GroupsMasterSearchResultDTO[];
  public Nationalities: NationalityDTO[];

  public totalClientsNumbersInGroup: number;
  public LoadingGetTotalClientsNumbersInGroup: boolean = false;
  public Clients: GetLiteForGBClientDTO[];
  public ClientId: number;
  public LoadingGetGroup: boolean = false;
  public LoadingSaveGroup: boolean = false;
  public clientSearchCriteria: ClientSearchCriteria = new ClientSearchCriteria();
  GForm: UntypedFormGroup;
  public selectedClients: ClientBasicDTO[] = [];

  constructor(public activeModal: NgbActiveModal,
    public translate: TranslateService,
    private phaseOneService: RegistrationCyclePhaseOneService,
    public clientService: ClientService,
    public groupService: GroupService,
    public datePipe: DatePipe,
    private fb: UntypedFormBuilder,
    public sharedService: SharedService,
    public gymClientMembershipService: GymClientMembershipService,
    public toaster: ToastrService) { }

  ngOnInit(): void {

    this.GetClientGroups();
    this.GetBranches();
    this.GetCountries();
    this.GetCategories();
    this.GetServices();
    this.GetClients();
    this.GetNationalities();
    this.GFormDate(new Group());

    if (this.GroupId && this.GroupId > 0)
      this.GetGroupForEdit();
  }

  public get gender(): typeof Gender {
    return Gender;
  }

  AddingDateFromChanged() {

    if (this.clientSearchCriteria.AddingDateFrom)
      this.clientSearchCriteria.AddingDateTo = this.datePipe.transform(this.clientSearchCriteria.AddingDateFrom, 'yyyy-MM-dd')

  }

  LastOrderDateFromChanged() {

    if (this.clientSearchCriteria.LastOrderDateFrom)
      this.clientSearchCriteria.LastOrderDateTo = this.datePipe.transform(this.clientSearchCriteria.LastOrderDateFrom, 'yyyy-MM-dd')

  }
  BirthDateFromChanged() {

    if (this.clientSearchCriteria.BirthDateFrom)
      this.clientSearchCriteria.BirthDateTo = this.datePipe.transform(this.clientSearchCriteria.BirthDateFrom, 'yyyy-MM-dd')

  }

  customSearchFnClients(term: string, item: Client) {
    term = term.toLowerCase();
    return (
      item.Name.toLowerCase().indexOf(term) > -1 ||
      item.Mobile.toLowerCase().indexOf(term) > -1 ||
      item.Mobile.toLowerCase() === term
    );
  }

  searchClientsTimeout: any;
  onSearchClients(e) {

    this.Clients = [];
    if (this.searchClientsTimeout) {
      clearTimeout(this.searchClientsTimeout);
    }

    if (e && e.term && e.term.length > 2) {
      this.freeTextClients = e.term;
      this.searchClientsTimeout = setTimeout(() => {
        this.GetClients();
      }, 300);
    }


    if (!e || !e.term || e.term.length == 0) {
      this.freeTextClients = null;
      this.GetClients();
    }
  }

  onSearch(e) {
    this.freeText = e.term;
    this.Clients = [];
  }

  customSearchFn(term: string, item: Client) {
    term = term.toLowerCase();

    return (
      item.Name.toLowerCase().indexOf(term) > -1 ||
      item.Mobile.toLowerCase().indexOf(term) > -1 ||
      item.Mobile.toLowerCase() === term
    );
  }


  onSearchBranches(e) {
    this.freeTextBranches = e.term;
    this.Countries = [];
    this.GetBranches();
  }

  customSearchFnBranches(term: string, item: Client) {
    term = term.toLowerCase();

    return (
      item.Name.toLowerCase().indexOf(term) > -1
    );
  }

  onSearchCountries(e) {
    this.freeTextCountries = e.term;
    this.Countries = [];
    this.GetCountries();
  }

  onSearchNationalities(e) {
    /*this.freeTextNationalities = e.term;
    this.Nationalities = [];
    this.GetNationalities();*/
  }

  customSearchFnCountries(term: string, item: Client) {
    term = term.toLowerCase();

    return (
      item.Name.toLowerCase().indexOf(term) > -1
    );
  }

  customSearchFnNationalities(term: string, item: NationalityDTO) {
    term = term.toLowerCase();

    return (
      item.GlobalName.toLowerCase().indexOf(term) > -1
    );
  }

  onSearchCities(e) {
    this.freeTextCities = e.term;
    this.Cities = [];
    this.GetCities();
  }

  customSearchFnCities(term: string, item: Client) {
    term = term.toLowerCase();

    return (
      item.Name.toLowerCase().indexOf(term) > -1
    );
  }
  onSearchCategories(e) {
    this.freeTextCategories = e.term;
    this.Categories = [];
    this.GetCategories();
  }

  customSearchFnCategories(term: string, item: Client) {
    term = term.toLowerCase();

    return (
      item.Name.toLowerCase().indexOf(term) > -1
    );
  }
  onSearchServices(e) {
    this.freeTextServices = e.term;
    this.Services = [];
    this.GetServices();
  }

  customSearchFnServices(term: string, item: Client) {
    term = term.toLowerCase();

    return (
      item.Name.toLowerCase().indexOf(term) > -1
    );
  }

  public get clientBalanceStatus(): typeof ClientBalanceStatus {
    return ClientBalanceStatus;
  }

  GFormDate(group: Group) {
    this.GForm = this.fb.group({
      Id: new UntypedFormControl(group.Id),
      Name: new UntypedFormControl(group.Name, Validators.required),
      Notes: new UntypedFormControl(group.Notes)
    })
  }

  removeClient(clientId: number) {
    this.selectedClients = this.selectedClients ?
      this.selectedClients.filter(c => c.Id != clientId) : this.selectedClients;
  }

  GetGroupForEdit() {

    this.LoadingGetGroup = true;

    this.groupService.getGroup(this.GroupId + "").subscribe((response: ExecutionResponse<Group>) => {
      if (response.State == ResponseState.Success) {

        this.GFormDate(response.Result);
        this.clientSearchCriteria = response.Result.FilterClientCriteria;

        this.clientSearchCriteria.AddingDateFrom = this.datePipe.transform(this.clientSearchCriteria.AddingDateFrom, 'yyyy-MM-dd')
        this.clientSearchCriteria.AddingDateTo = this.datePipe.transform(this.clientSearchCriteria.AddingDateTo, 'yyyy-MM-dd')
        this.clientSearchCriteria.BirthDateFrom = this.datePipe.transform(this.clientSearchCriteria.BirthDateFrom, 'yyyy-MM-dd')
        this.clientSearchCriteria.BirthDateTo = this.datePipe.transform(this.clientSearchCriteria.BirthDateTo, 'yyyy-MM-dd')
        this.clientSearchCriteria.LastOrderDateFrom = this.datePipe.transform(this.clientSearchCriteria.LastOrderDateFrom, 'yyyy-MM-dd')
        this.clientSearchCriteria.LastOrderDateTo = this.datePipe.transform(this.clientSearchCriteria.LastOrderDateTo, 'yyyy-MM-dd')

        if (this.clientSearchCriteria.AddingBranchesIds)
          this.GetBranches(this.clientSearchCriteria.AddingBranchesIds);
        if (this.clientSearchCriteria.CountryId)
          this.GetCountries(this.clientSearchCriteria.CountryId);
        if (this.clientSearchCriteria.CityId)
          this.GetCities(this.clientSearchCriteria.CityId);
        if (this.clientSearchCriteria.UsedCategoriesIds)
          this.GetCategories(this.clientSearchCriteria.UsedCategoriesIds);
        if (this.clientSearchCriteria.UsedServicesIds)
          this.GetServices(this.clientSearchCriteria.UsedServicesIds);

        let clientsIds: number[] = [];

        if (this.clientSearchCriteria.ExcludedClientsIds &&
          this.clientSearchCriteria.ExcludedClientsIds.length > 0)
          clientsIds.push.apply(clientsIds, this.clientSearchCriteria.ExcludedClientsIds);

        if (this.clientSearchCriteria.AdditionalClientsIds &&
          this.clientSearchCriteria.AdditionalClientsIds.length > 0)
          clientsIds.push.apply(clientsIds, this.clientSearchCriteria.AdditionalClientsIds);

        if (clientsIds && clientsIds.length > 0)
          this.GetClients(clientsIds);

      }
      this.LoadingGetGroup = false;
    });
  }

  CheckIfFiltersAccepted(): boolean {

    return true;/*this.clientSearchCriteria.AddingDateFrom != undefined ||
      this.clientSearchCriteria.AddingDateTo != undefined ||
      this.clientSearchCriteria.BirthDateFrom != undefined ||
      this.clientSearchCriteria.BirthDateTo != undefined ||
      this.clientSearchCriteria.LastOrderDateFrom != undefined ||
      this.clientSearchCriteria.LastOrderDateTo != undefined ||
      this.clientSearchCriteria.AddingBranchesIds != undefined ||
      this.clientSearchCriteria.UsedServicesIds != undefined ||
      this.clientSearchCriteria.UsedCategoriesIds != undefined ||
      this.clientSearchCriteria.ClientOrdersTotalGreaterThanOrEqual != undefined;*/
  }

  HandleClientSearchCriteria() {


    this.clientSearchCriteria.AddingDateFrom = this.clientSearchCriteria.AddingDateFrom == "" ? null :
      this.clientSearchCriteria.AddingDateFrom;

    this.clientSearchCriteria.AddingDateTo = this.clientSearchCriteria.AddingDateTo == "" ? null :
      this.clientSearchCriteria.AddingDateTo;

    this.clientSearchCriteria.LastOrderDateFrom = this.clientSearchCriteria.LastOrderDateFrom == "" ? null :
      this.clientSearchCriteria.LastOrderDateFrom;

    this.clientSearchCriteria.LastOrderDateTo = this.clientSearchCriteria.LastOrderDateTo == "" ? null :
      this.clientSearchCriteria.LastOrderDateTo;

    this.clientSearchCriteria.BirthDateFrom = this.clientSearchCriteria.BirthDateFrom == "" ? null :
      this.clientSearchCriteria.BirthDateFrom;

    this.clientSearchCriteria.BirthDateTo = this.clientSearchCriteria.BirthDateTo == "" ? null :
      this.clientSearchCriteria.BirthDateTo;

  }

  SaveGroup() {

    this.LoadingSaveGroup = true;
    var group: Group = this.GForm.value;
    this.HandleClientSearchCriteria();
    group.FilterClientCriteria = this.clientSearchCriteria;

    if (this.GroupId) {
      this.EditGroup(group);
    } else {
      this.AddGroup(group);
    }

  }

  AddGroup(group: Group) {

    this.LoadingSaveGroup = true;
    this.groupService.addGroup(group).subscribe((response: ExecutionResponse<number>) => {
      if (response.State == ResponseState.Success) {

        this.toaster.success(this.translate.instant("DoneAddGroupSuccessfully."));
        this.activeModal.close();
        this.DoneSave.emit(true);
      }
      this.LoadingSaveGroup = false;
    });

  }

  EditGroup(group: Group) {

    this.LoadingSaveGroup = true;
    this.groupService.updateGroup(group).subscribe((response: ExecutionResponse<boolean>) => {
      if (response.State == ResponseState.Success) {

        this.toaster.success(this.translate.instant("DoneUpdateGroupSuccessfully."));
        this.activeModal.close();
        this.DoneSave.emit(true);
      }
      this.LoadingSaveGroup = false;
    });

  }


  GetBranches(Ids: number[] = null) {

    var criteria: ClientsGroupsMasterSearchCriteria = new ClientsGroupsMasterSearchCriteria();
    criteria.PagingEnabled = true;
    criteria.PageSize = Ids && Ids.length > 0 ? Ids.length : 2;
    criteria.PageNumber = 0;
    criteria.FreeText = this.freeTextBranches;
    criteria.Ids = Ids;

    this.groupService.GetBranches(criteria).subscribe((response) => {
      if (response.State == ResponseState.Success) {
        this.Branches = response.Result;
      }
    });
  }
  GetCountries(Id: number = null) {

    this.Countries = [];
    this.Cities = [];

    var criteria: GetCountriesCriteria = new GetCountriesCriteria();
    criteria.PagingEnabled = true;
    criteria.PageSize = 2;
    criteria.PageNumber = 0;
    criteria.FreeText = this.freeTextCountries;
    criteria.Id = Id;

    this.groupService.GetCountries(criteria).subscribe((response) => {
      if (response.State == ResponseState.Success) {
        this.Countries = response.Result;
        this.clientSearchCriteria.CountryId = this.sharedService?.CurrentBranch?.CountryId;
        this.GetCities();
      }
    });
  }
  GetCities(Id: number = null) {

    if (!this.clientSearchCriteria.CountryId)
      return;
    var criteria: GetCitiesCriteria = new GetCitiesCriteria();
    criteria.CountryId = this.clientSearchCriteria.CountryId;
    criteria.PagingEnabled = true;
    criteria.PageSize = 2;
    criteria.PageNumber = 0;
    criteria.FreeText = this.freeTextCities;
    criteria.Id = Id;

    this.groupService.GetCities(criteria).subscribe((response) => {
      if (response.State == ResponseState.Success) {
        this.Cities = response.Result;
      }
    });
  }
  GetCategories(Ids: number[] = null) {

    var criteria: ClientsGroupsMasterSearchCriteria = new ClientsGroupsMasterSearchCriteria();
    criteria.PagingEnabled = true;
    criteria.PageSize = Ids && Ids.length > 0 ? Ids.length : 2;
    criteria.PageNumber = 0;
    criteria.FreeText = this.freeTextCategories;
    criteria.Ids = Ids;

    this.groupService.GetCategories(criteria).subscribe((response) => {
      if (response.State == ResponseState.Success) {
        this.Categories = response.Result;
      }
    });
  }

  GetClients(Ids: number[] = null) {

    let criteria: ClientSearchCriteria = new ClientSearchCriteria();
    criteria.AccountSetupId = this.sharedService.AccountSetupId;
    criteria.FreeText = this.freeText;
    criteria.PagingEnabled = true;
    criteria.PageSize = Ids && Ids.length > 0 ? Ids.length : 2;
    criteria.PageNumber = 0;
    criteria.FreeText = this.freeTextClients;
    criteria.Ids = Ids;

    this.clientService.GetClientsLite(criteria).subscribe((response) => {
      if (response.State == ResponseState.Success) {
        this.Clients = response.Clients;
      }
    });

  }

  GetClientGroups() {

    let criteria: GetClientGroupsSearchCriteria = new GetClientGroupsSearchCriteria();
    criteria.Id = 265461;

    this.clientService.GetClientGroups(criteria).subscribe((response) => {
      if (response.State == ResponseState.Success) {

        //this.Clients = response.Clients;
      }
    });

  }
  GetServices(Ids: number[] = null) {

    var criteria: ClientsGroupsMasterSearchCriteria = new ClientsGroupsMasterSearchCriteria();
    criteria.PagingEnabled = true;
    criteria.PageSize = Ids && Ids.length > 0 ? Ids.length : 2;
    criteria.PageNumber = 0;
    criteria.FreeText = this.freeTextServices;
    criteria.Ids = Ids;

    this.groupService.GetServices(criteria).subscribe((response) => {
      if (response.State == ResponseState.Success) {
        this.Services = response.Result;
      }
    });
  }

  GetNationalities() {

    var criteria = new GetNationalitiesCriteria();
    criteria.PagingEnabled = true;
    criteria.PageNumber = 0;
    criteria.PageSize = 300;
    criteria.FreeText = this.freeTextNationalities;

    this.phaseOneService
      .GetNationalities(criteria)
      .subscribe((response: ExecutionResponse<NationalityDTO[]>) => {

        if (response.State == ResponseState.Success) {
          this.Nationalities = response.Result;
        }
      });
  }


  GetTotalClientsNumbersInGroup() {

    this.LoadingGetTotalClientsNumbersInGroup = true;
    this.groupService.GetTotalClientsNumbersInGroup(this.clientSearchCriteria).subscribe((response) => {
      if (response.State == ResponseState.Success) {
        this.totalClientsNumbersInGroup = response.Result;
      }
      this.LoadingGetTotalClientsNumbersInGroup = false;
    });
  }
}
