import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ScreenModule } from '../../models/classes/account/screen-module';
import { ScreenSearchCriteria } from '../../models/search-criterias/screen-search-criteria';
import { ScreenModuleSearchResult } from '../../models/search-result/screen-module-search-result';
import { ExecutionResponse } from '../../models/support/execution-response';
import { MessageService } from '../message/message.service';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { SharedService } from '../other/shared.service';
import { UIStateService } from '../other/ui-state.service';

@Injectable({
  providedIn: 'root'
})
export class ScreenModuleService  extends BaseService{

  ScreenModuleUrl: string;
  loadingTree: boolean;
  constructor(private http: HttpClient,
    private messageService: MessageService,
    private _BaseService: BaseServiceSZ,
    private sharedService: SharedService,
    private _UIStateService: UIStateService) {

    super()
    this.ScreenModuleUrl = this.sharedService.ApiUrl/*this._BaseService.ApiUrl */ + 'ScreenModule';
   }

   getScreenModules(criteria?: ScreenSearchCriteria): Observable<ScreenModuleSearchResult> {
    
   
    return this.http.post<ScreenModuleSearchResult>(this.ScreenModuleUrl + '/Get', criteria, super.getHeaders())
      .pipe(
        tap(res => {
          let x=res;
          console.log(res);
          
          this._BaseService.ValidationResult(res);

        }),

        catchError(this._BaseService.handleError<ScreenModuleSearchResult>('getSecurityGroup id=${id}'))
      );
  }

  getMainScreenModules(): Observable<ScreenModuleSearchResult> {
    return this.http.get<ScreenModuleSearchResult>(this.ScreenModuleUrl + '/Main', super.getHeaders())
      .pipe(
        tap(res => {
          console.log(res);
          this._BaseService.ValidationResult(res);

        }),

        catchError(this._BaseService.handleError<ScreenModuleSearchResult>('getSecurityGroup id=${id}'))
      );
  }

  getScreenModulTree(): Observable<ScreenModuleSearchResult> {
    this.loadingTree = true;
    return this.http.get<ScreenModuleSearchResult>(this.ScreenModuleUrl +'/Tree', super.getHeaders())
      .pipe(
        tap(res => {
          this.loadingTree = false;

          console.log(res);
          this._BaseService.ValidationResult(res);
        }),
        catchError(err=>{
          this.loadingTree = false;
          throw err;
        }),
        catchError(this._BaseService.handleError<ScreenModuleSearchResult>('getSecurityGroup id=${id}'))
      );
  }

  

  
  GetScreenWithPermissions(criteria?: ScreenSearchCriteria): Observable<ScreenModuleSearchResult> 
  {
    
   // criteria.AccountSetupId=this.getAccountSetupId();
    return this.http.post<ScreenModuleSearchResult>(this.ScreenModuleUrl + '/GetScreenWithPermissions', criteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);

        }),

        catchError(this._BaseService.handleError<ScreenModuleSearchResult>('getSecurityGroup id=${id}'))
      );
  }

  GetScreenModuleWithPermissions(criteria?: ScreenSearchCriteria): Observable<ScreenModuleSearchResult> 
  {
    
   // criteria.AccountSetupId=this.getAccountSetupId();
    return this.http.post<ScreenModuleSearchResult>(this.ScreenModuleUrl + '/GetScreenModulsWithPermissions', criteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);

        }),

        catchError(this._BaseService.handleError<ScreenModuleSearchResult>('getSecurityGroup id=${id}'))
      );
  }
  getDynamicScreenModules(criteria?: ScreenSearchCriteria): Observable<ExecutionResponse<any[]>> {
    
    return this.http.post<ExecutionResponse<any[]>>(this.ScreenModuleUrl + '/GetDynamic', criteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);

        }),

        catchError(this._BaseService.handleError<ExecutionResponse<any[]>>('getSecurityGroup id=${GetDynamic}'))
      );
  }

  GetPackagScreensTree(criteria?: ScreenSearchCriteria): Observable<ExecutionResponse<any[]>> {
    
    return this.http.post<ExecutionResponse<any[]>>(this.ScreenModuleUrl + '/GetPackagScreensTree', criteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);

        }),

        catchError(this._BaseService.handleError<ExecutionResponse<any[]>>('getSecurityGroup id=${GetDynamic}'))
      );
  }

  GetPackagDefaultScreensTree(): Observable<ExecutionResponse<any[]>> {
    
    return this.http.post<ExecutionResponse<any[]>>(this.ScreenModuleUrl + '/GetPackagDefaultScreensTree', {}, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);

        }),

        catchError(this._BaseService.handleError<ExecutionResponse<any[]>>('getSecurityGroup id=${GetDynamic}'))
      );
  }
  getScreenModulesPaging(criteria?: ScreenSearchCriteria): Observable<ExecutionResponse<any[]>> 
  {
    
    return this.http.post<ExecutionResponse<any[]>>(this.ScreenModuleUrl + '/GetMainData', criteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);

        }),

        catchError(this._BaseService.handleError<ExecutionResponse<any>>('GetMainData criteria=${criteria}'))
      );
  }


  CreateScreenModules(criteria?: ScreenSearchCriteria): Observable<ExecutionResponse<any[]>> 
  {
    
    return this.http.post<ExecutionResponse<any[]>>(this.ScreenModuleUrl + '/Create', criteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);

        }),

        catchError(this._BaseService.handleError<ExecutionResponse<any>>('create criteria=${criteria}'))
      );
  }

  UpdateScreenModules(PackageId:number , criteria?: ScreenSearchCriteria): Observable<ExecutionResponse<any[]>> 
  {
    if(PackageId>0)
    {
      criteria.PackageId =PackageId;
    }
    return this.http.post<ExecutionResponse<any[]>>(this.ScreenModuleUrl + '/Update', criteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);

        }),

        catchError(this._BaseService.handleError<ExecutionResponse<any>>('Update criteria=${criteria}'))
      );
  }
  DeleteScreenModules(Id: number): Observable<ExecutionResponse<any[]>> 
  {
    
    return this.http.post<ExecutionResponse<any[]>>(this.ScreenModuleUrl + '/Delete', Id, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);

        }),

        catchError(this._BaseService.handleError<ExecutionResponse<any>>('Update criteria=${criteria}'))
      );
  }
  GetScreenModulesById(Id:any): Observable<ExecutionResponse<ScreenModule>> 
  {
    
    return this.http.post<ExecutionResponse<ScreenModule>>(this.ScreenModuleUrl + '/GetById', Id, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);

        }),

        catchError(this._BaseService.handleError<ExecutionResponse<ScreenModule>>('GetMainData criteria=${criteria}'))
      );
  }

 
  GetScreenModulesBypackage(criteria?: ScreenSearchCriteria): Observable<ExecutionResponse<ScreenModule>> 
  {
    
    return this.http.post<ExecutionResponse<ScreenModule>>(this.ScreenModuleUrl + '/GetByPackage', criteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);

        }),

        catchError(this._BaseService.handleError<ExecutionResponse<ScreenModule>>('GetMainData criteria=${criteria}'))
      );
  }
}
