import { Component, OnInit } from '@angular/core';
import { BaseForm, ComponentBase, SharedService } from 'core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AutoRegistrationService } from 'core';
import { takeUntil } from 'rxjs/operators';

import {
  FormGroup,
  UntypedFormGroup,
  UntypedFormControl,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SupportChatService } from 'projects/core/src/lib/services/support-chat.service';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss'],
})
export class UpdatePasswordComponent
  extends ComponentBase
  implements BaseForm, OnInit
{
  form: FormGroup;

  constructor(
    private supportChatService: SupportChatService,
    public readonly sharedService: SharedService,
    private readonly route: ActivatedRoute,
    private readonly autoRegistrationService: AutoRegistrationService,
    private readonly router: Router,
    private readonly toastr: ToastrService
  ) {
    super();
  }

  hideConfirm: boolean = true;
  hide: boolean = true;

  ngOnInit(): void {
    this.supportChatService.chatScript();
    this.initForm();
    this.route.queryParams.pipe().subscribe((params: Params) => {
      const paramsData = JSON.parse(params['data']);
      this.form.patchValue({
        Code: paramsData?.Code,
        UserName: paramsData?.UserName,
      });
    });
  }

  initForm(): void {
    this.form = new UntypedFormGroup({
      newPassword: new UntypedFormControl(null),
      UserName: new UntypedFormControl(null),
      Code: new UntypedFormControl(null),
    });
  }

  onSubmit(): void {
    this.loading = true;
    this.autoRegistrationService
      .updatePassword(this.form.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.loading = false;
          this.checkResponse = this.sharedService.checkResposeStatus(
            res.Message,
            res.State
          );
          if (this.checkResponse) {
            this.toastr.success(res.Message);
            this.router.navigate(['/']);
          }
        },
        (error) => {
          this.loading;
        }
      );
  }
}
