import { Injectable } from '@angular/core';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../message/message.service';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { SharedService } from '../other/shared.service';
import { CategoryBranchSearchCriteria } from '../../models/search-criterias/category-branch-search-criteria';
import { CategoryBranchSearchResult } from '../../models/search-result/category-branch-search-result';

@Injectable({
  providedIn: 'root'
})
export class CategoryBranchService extends BaseService {

  private categoryBranchEmployeeUrl  //= super.BaseUrl() + 'Category';  // URL to web api

  constructor(
    private http: HttpClient, private messageService: MessageService,
    private sharedService:SharedService,
    private _BaseService: BaseServiceSZ

  ) {
    super();
    this.categoryBranchEmployeeUrl = this.sharedService.ApiUrl + 'CategoryBranch';

  }

  GetCategoryBranches(categoryBranchSearchCriteria: CategoryBranchSearchCriteria): Observable<CategoryBranchSearchResult> {
    categoryBranchSearchCriteria.AccountSetupId = super.getAccountSetupId();
    return this.http.post<CategoryBranchSearchResult>(this.categoryBranchEmployeeUrl + '/Get', categoryBranchSearchCriteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('Fetched Category Branch');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<CategoryBranchSearchResult>('Fetched Category Branch'))
      );
  }
}
