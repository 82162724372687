import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../../services/other/shared.service';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { BookingOrTransactionItemType } from 'src/app/models/enums/booking-or-transaction-item-type';
import * as _ from 'underscore';
import { BookingInfoDTO } from 'src/app/models/classes/booking/booking-info';

@Component({
  selector: 'app-print-employee-booking',
  templateUrl: './print-employee-booking.component.html',
  styleUrls: ['./print-employee-booking.component.scss']
})
export class PrintEmployeeBookingComponent implements OnInit {


  public booking: BookingInfoDTO;

  @Input('Booking')
  set in(theBooking) {

    this.booking = theBooking;
    this.handleBookingItems();

  }

  @Input() public readonly AccountSetup;
  @Input() public dirc;
  public BillInfoUrl: string;
  public printLang: string = 'ar';

  constructor(
    public sharedService: SharedService,
    public translate: TranslateService,
    public translateService: TranslateService

  ) {

  }

  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.LOW;
  public get bookingOrTransactionItemType(): typeof BookingOrTransactionItemType {
    return BookingOrTransactionItemType;
  }

  ngOnInit(): void {


    if (this.booking) {
      this.handleBookingItems();
    }
    this.BillInfoUrl = location.origin + "/transaction/bill-info/billItemId/";

    if (this.AccountSetup?.SelectedPrintEmployeeLanguage === 'en') {
      // this.translate.use('en');
      // console.log(this.AccountSetup?.SelectedPrintEmployeeLanguage)
      // this.translate.use('en');
      this.dirc = 'ltr';
      this.sharedService.TempLangKey = "en";
      this.sharedService.SetTranslationPipeData();
    }
    else if (this.AccountSetup?.SelectedPrintEmployeeLanguage === 'ar') {
      // this.translate.use('ar');
      this.dirc = 'rtl';
      this.sharedService.TempLangKey = "ar";
      this.sharedService.SetTranslationPipeData();

    }
    else if (this.AccountSetup?.SelectedPrintEmployeeLanguage === 'all') {
      console.log('all')

    }

    this.printLang = this.sharedService.Lang;

    if (this.AccountSetup?.SelectedPrintEmployeeLanguage && this.AccountSetup?.SelectedPrintEmployeeLanguage != 'all') {
      this.printLang = this.AccountSetup?.SelectedPrintEmployeeLanguage;
    }

  }

  handleBookingItems() {

    this.booking.BookingEmployees = [];

    if (this.booking.BookingItems && this.booking.BookingItems.length > 0) {

      this.booking.BookingItems.forEach((bookingService, index) => {

        if (bookingService.EmployeeId > 0) {
          this.booking.BookingEmployees.push(bookingService.EmployeeId);
        }
        if (!bookingService.Quantity) {
          bookingService.Quantity = 1;
        }

        /*  if (bookingService.ItemType == BookingOrTransactionItemType.Package) {

           var getPackageOriginalPrice = this.booking.BookingItems.
             filter(bs => bs.ItemType == BookingOrTransactionItemType.Package &&
               bs.OffersSettingId == bookingService.OffersSettingId)?.map(a => a.Service.Price)?.
             reduce(function (a, b) {
               return a + b;
             });

           var getPackagePrice = this.booking.ClientPackages.
             find(cp => cp.OffersSettingId == bookingService.OffersSettingId)?.TotalNetPrice ?? 0;

           bookingService.Price = bookingService?.Service?.Price ?? 0;
           bookingService.TotalNetPrice = ((bookingService?.Service?.Price ?? 0) /
             getPackageOriginalPrice) * getPackagePrice;

         } */


      });
      this.booking.BookingEmployees = _.uniq(this.booking.BookingEmployees);
    }
  }

  hasNonNullEmployeeTransactionCommission(booking: any): boolean {
    return booking.BookingItems.some(service => service.EmployeeTransactionCommission
      && service.EmployeeTransactionCommission.CommissionValue > 0);
  }

  CheckIfPackage(BookingItems: any): boolean {
    return BookingItems && BookingItems.length > 0 &&
      BookingItems.some(b => b.TransactionsPackageServices &&
        b.TransactionsPackageServices.length > 0);
  }

  getEmployeeName(employeeId: number): string {

    var getBookingService = this.booking.BookingItems.find(bs => bs.EmployeeId == employeeId);

    if (getBookingService) {

      var employeeName = getBookingService.EmployeeName ? getBookingService.EmployeeName :
        this.translateService.instant('NotFound');

      return employeeName;
    }

    return null;

  }

}
