<div class="row" [formGroup]="TrPaForm" class="payment-com">
  <div class="col-md-12" [formGroupName]="CurrentGFormMaster">
    <div>
      <table class="table mb-0" formArrayName="TransactionsPayments">

        <tr *ngFor="let item of TrPaForm.get(CurrentGFormMaster).get('TransactionsPayments')['controls']; let i=index"
          [formGroupName]="i">
          <td style="width: 50%;">
            <div class="formgrouptbl">
              <ng-select [multiple]="false" [hideSelected]="true"
                [items]="i == 0 ? PaymentMethodsListWithoutLoyalty : PaymentMethodsList" bindLabel="GlobalName"
                bindValue="Id" (change)="ChoosePayment($event, i)" formControlName="PaymentMethodId"
                name="PaymentMethodId">
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <b>
                    {{item.GlobalName}}
                  </b>
                </ng-template>
              </ng-select>
            </div>
            <div *ngIf="item.value.PaymentMethodType == paymentMethodType.CreditCard">
              <div class="form-group mt-2">
                <!--  <select class="form-control" formControlName="CreditCardId" name="CreditCardId">
                  <option value="null" selected disabled> {{'CreditCards'|translate}} </option>
                  <option *ngFor="let p of CreditCards; let i = index" [value]="p.Id">
                    {{p.NameAr}}
                    {{sharedService.Lang== 'ar' ? p.NameAr : p.NameEn}}</option>
                </select> -->

                <input class="form-control mt-2 text-center" [placeholder]="CheckIsReferenceNumberMandatory(item.value.PaymentMethodId)  ?
                  ('ReferenceNumber-Mandatory' | translate) : ('ReferenceCode' | translate) "
                  formControlName="ReferenceCode"
                  [required]="CheckIsReferenceNumberMandatory(item.value.PaymentMethodId)" name="ReferenceCode">
              </div>
            </div>
            <div
              *ngIf="item.value.PaymentMethodType == paymentMethodType.Loyalty && PaymentScreenType == paymentScreenType.Appointment">
              <div class="form-group mb-0">
                <div class="" *ngIf="ClientLoyalityAmount>=0">
                  <div class="">
                    <p> {{ 'Loyalty-balance' | translate }} :
                      <span> {{ClientLoyalityAmount}} </span>
                      {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr :
                      sharedService.CurrencyNameEn}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="item.value.PaymentMethodType == paymentMethodType.Other">
              <div class="form-group ">
                <input class="form-control mt-2 text-center" [placeholder]=" CheckIsReferenceNumberMandatory(item.value.PaymentMethodId)?
                   ('ReferenceNumber-Mandatory' | translate) : ('ReferenceCode' | translate) "
                  formControlName="ReferenceCode" name="ReferenceCode"
                  [required]="CheckIsReferenceNumberMandatory(item.value.PaymentMethodId)">
              </div>
            </div>
            <div
              *ngIf="item.value.PaymentMethodType == paymentMethodType.Cash && PaymentScreenType == paymentScreenType.Appointment">
              <div class="form-group ">
                <input class="form-control mt-2 text-center" type="number" step="any" [min]="item.value.Amount"
                  placeholder="{{'ThePaidAmount' | translate}}" (keyup)="PaidAmountChanged(item.value.Amount)"
                  [(ngModel)]="PaidAmount" [ngModelOptions]="{standalone: true}">
              </div>
            </div>
            <div *ngIf="item.value.PaymentMethodType == paymentMethodType.GiftCard &&
               PaymentScreenType == paymentScreenType.Appointment">
              <div class="form-group ">
                <div class="row">
                  <div class="col-lg-5">
                    <input class="form-control mt-2 text-center" placeholder="{{'GiftCardCode' | translate}}"
                      formControlName="GiftCardCode" (change)="GiftCardCodeChanged($event,i)"
                      ((keyup.enter))="GiftCardCodeChanged($event,i)" name="GiftCardCode" required>
                  </div>
                  <div class="col-lg-1.5">
                    <button class="form-control checkClass text-center" (click)="GiftCardCodeChanged( $event,i)"
                      title="{{ ('CheckGiftCard' |translate)}}">
                      <i class="fas fa-check"></i>
                    </button>
                  </div>
                  <div class="col-lg-6">
                    <button class="form-control acceptClass text-center"
                      (click)="openModalClientGiftCardDetailsComponent( item.value.GiftCardCode)"
                      title="{{ ('GiftCardInformation' |translate)}}">
                      <i class="fas fa-info-circle"></i> &nbsp;
                      {{ ('GiftCardInformation' |translate)}}
                    </button>
                  </div>
                </div>

                <div class="row" *ngIf="PaymentScreenType == paymentScreenType.Appointment &&
                Company.AllowUseTheOTPSystemWhenWithdrawFromGiftCards
                  && item.value.GiftCardBalanceChecked && item.value.IsNewWithClient">
                  <div class="col-lg-5">
                    <input class="form-control mt-2 text-center" placeholder="{{'GiftCardOTPCode' | translate}}"
                      formControlName="GiftCardOTPCode" name="GiftCardOTPCode" required
                      [title]="((item.value.GiftCardState != responseState.Success && !LoadingCheckGiftCardCode) ?  'GiftCardCodeAndAmountMustBeValidFirstBeforeSendOTPToClient' : 'ToWithdrowFromGiftCard-YouMustSendOTPCodeToClient') | translate"
                      [readonly]="(item.value.GiftCardState != responseState.Success && !LoadingCheckGiftCardCode)">
                  </div>
                  <div class="col-lg-7">
                    <button class="form-control acceptClass sendOTPCodeToClientClass text-center"
                      [disabled]="LoadingSendOTPCodeToClient"
                      [title]="((item.value.GiftCardState != responseState.Success && !LoadingCheckGiftCardCode) ?  'GiftCardCodeAndAmountMustBeValidFirstBeforeSendOTPToClient' : 'ToWithdrowFromGiftCard-YouMustSendOTPCodeToClient') | translate"
                      (click)="SendOTPCodeToClientClicked(i)">
                      <i *ngIf="!LoadingSendOTPCodeToClient" class="fas fa-sms"></i> &nbsp;
                      {{ ((LoadingSendOTPCodeToClient ?'SendingOTPCode' : 'SendOTPCodeToClient') |translate)}}
                    </button>
                  </div>
                </div>
                <div class="row" *ngIf="PaymentScreenType == paymentScreenType.Appointment &&
                Company.AllowUseTheOTPSystemWhenWithdrawFromGiftCards
                  && item.value.GiftCardBalanceChecked && !item.value.IsNewWithClient
                  && item.value.GiftCardState == responseState.Success">
                  <label class="col-12 text-info" style="font-size: 15px;margin-top: 2%;margin-bottom: -3%;">{{
                    'GiftCardDoNotConnectedToAnyClient-OTPWillNotUsed' |
                    translate }}
                  </label>
                </div>
              </div>
            </div>
            <div
              *ngIf="PaymentScreenType == paymentScreenType.Appointment &&
              item.value.PaymentMethodType == paymentMethodType.GiftCard &&
              item.value.GiftCardCode && item.value.GiftCardCode.length > 0">
              <div class="">
                <div class="row">
                  <div class="col-lg-12" *ngIf="LoadingCheckGiftCardCode">
                    <i class="fas fa-spinner fa-pulse text-info" style="font-size: 20px;"></i>
                  </div>
                  <div class="col-lg-12" *ngIf="item.value.GiftCardState == responseState.Success &&
                    !LoadingCheckGiftCardCode && item.value.GiftCardBalanceChecked">

                    <label class="text-success">
                      <i class="fas fa-check"></i>
                      <span>
                        {{ 'GiftCardBalance' | translate }}: &nbsp;&nbsp;
                      </span>
                      <span>
                        {{item.value.GiftCardBalance}}
                        &nbsp;&nbsp; ({{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr :
                        sharedService.CurrencyNameEn}} )
                      </span>

                    </label>

                  </div>
                  <div class="col-lg-12"
                    *ngIf="PaymentScreenType == paymentScreenType.Appointment &&
                    item.value.GiftCardState != responseState.Success &&
                    !LoadingCheckGiftCardCode && item.value.GiftCardBalanceChecked && ErrorText && ErrorText.length > 0">

                    <div class="row">

                      <label class="text-danger">
                        <i class="fas fa-times"></i>

                        <span>
                          {{ 'ErrorHappene' | translate }}
                        </span>

                      </label>

                      <button class="form-control btn rejectClass text-center" (click)="ViewError()"
                        title="{{ ('ViewError' |translate)}}">
                        <i class="fas fa-eye"></i> &nbsp; {{ ('ViewError' |translate)}}
                      </button>

                    </div>

                  </div>

                </div>

              </div>
            </div>
          </td>
          <td style="width: 50%;">
            <div class="row">
              <div class="col-md-6">
                <div class="row" *ngIf="item.value.PaymentMethodType == paymentMethodType.Loyalty &&
                  PaymentScreenType == paymentScreenType.Appointment &&
                  GetTotalBeforeDiscountsAndTax() <= ClientLoyalityAmount">
                  <label class="col-5 pt-1">{{ "OnAllAmount" | translate }} &nbsp; <i title="{{ 'ApplyAddAllAmountToLoyaltyPaymentMethodWhenChoosen' |
                      translate }}" class="fas fa-info-circle" style="font-size:17px; color:rgb(103, 103, 230);"></i>
                  </label>
                  <div class="switch col-4 pt-1 text-end">
                    <label>
                      <input type="checkbox" name="OnAllAmount" (change)="LoyaltyOnAllAmountChanged($event,i)" />
                      <span class="lever"></span>
                    </label>
                  </div>
                </div>

              </div>
              <div class="col-md-6" *ngFor="let c of AccountCurrencies | slice:0:1">
                <div class="input-group">

                  <div *ngIf="i == 0" class="input-group-append input-group-append-first text-center form-control">
                    <span class="input-group-text input-group-text-span text-center" id="basic-addon2">
                      {{item.value.Amount | ToFixedPipe}}</span>
                  </div>

                  <input *ngIf="i != 0" type="number" class="form-control" placeholder="{{'Value' | translate}}"
                    formControlName="Amount" name="Amount"
                    [value]="item.value.TempAmount >= 0 ? item.value.TempAmount : item.value.Amount "
                    (change)="CalcSecondPayValue($event,i,null)" min="1"
                    [readOnly]="item.value.PaymentMethodId == 0 ||  i == 0">

                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2">
                      {{sharedService.Lang== 'ar' ? c.Currency.NameCultureVariant : c.Currency.Name}}</span>
                  </div>
                </div>
              </div>
              <div class="col-md-6">

              </div>
              <div class="col-md-6">
                <div *ngIf="item.value.PaymentMethodType == paymentMethodType.Cash &&
                  PaymentScreenType == paymentScreenType.Appointment">
                  <div class="form-group ">
                    <input class="form-control mt-2 text-center" type="text" disabled="true"
                      placeholder="{{'TheRemainingAmount' | translate}}" [(ngModel)]="RemainingAmount"
                      [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>



            </div>

          </td>
          <td>
            <button type="button" class="btn btn-remove" [hidden]="i == 0" (click)="removeItem(i)">
              <i class="fas fa-times"></i>
            </button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
