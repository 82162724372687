import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { ComponentBase } from '../components/base/common-base';
import { BUSINESS_TYPE, CITY, KNOW_US } from '../model/login';
import { RESPONCE } from '../model/responce';
import { COUNTRY } from '../model/signUp.model';
import { ApiService } from './api.service';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class SharedService extends ComponentBase {
  constructor(
    private readonly toastr: ToastrService,
    private readonly spinner: NgxSpinnerService,
    private readonly location: Location,
    private readonly apiService: ApiService,
    private readonly router: Router
  ) {
    super();
  }

  getCountries(): Observable<RESPONCE<COUNTRY[]>> {
    return this.apiService.get<RESPONCE<COUNTRY[]>>(
      'RegistrationCyclePhaseOne/GetCountries',
      { WithCurrentOne: true }
    );
  }

  getBusinessType(countryId: number): Observable<RESPONCE<BUSINESS_TYPE[]>> {
    return this.apiService.get<RESPONCE<BUSINESS_TYPE[]>>(
      'RegistrationCyclePhaseTwo/GetBusinessTypes?',
      { countryId: countryId }
    );
  }

  getCities(countryId: number): Observable<RESPONCE<CITY[]>> {
    return this.apiService.get<RESPONCE<CITY[]>>(
      'RegistrationCyclePhaseTwo/GetCities',
      { countryId: countryId }
    );
  }

  getKnowUs(): Observable<RESPONCE<KNOW_US[]>> {
    return this.apiService.get<RESPONCE<KNOW_US[]>>(
      'RegistrationCyclePhaseTwo/GetKnowUsWays',
      ''
    );
  }

  setLocalStorage(type: string, value: string): void {
    localStorage.setItem(type, value);
  }

  clearLocalStorage(type: string): void {
    localStorage.removeItem(type);
  }

  getLocalStorage(type: string): string {
    return localStorage.getItem(type)!;
  }

  checkResposeStatus(message: string, status: number): boolean {
    this.spinner.hide();

    switch (status) {
      case 100:
        this.toastr.success(message);
        return true;

      case 0:
        this.toastr.success(message);
        return true;

      case 102:
        this.toastr.error(message);
        return false;

      case 124:
        this.toastr.error(message);
        return false;

      case 114:
        this.toastr.error(message);
        return false;

      default:
        this.toastr.error(message);
        return false;
    }
  }

  goToPhase(phaseNumber: number): void {
    switch (phaseNumber) {
      case 1:
        this.router.navigate(['/choose-business'], {
          queryParams: {
            RegistrationCycleLogId: this.getLocalStorage(
              'RegistrationCycleLogId'
            ),
          },
        });
        break;

      default:
        this.router.navigate(['/details']);
    }
  }

  goToWhatsappSupport(): void {
    window.open('https://wa.me/966555017849', '_blank');
  }

  back(): void {
    this.location.back();
  }
}
