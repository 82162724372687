import { Component, OnInit, Inject, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { SharedService } from '../../../services/other/shared.service';

@Component({
  selector: 'app-print-contract',
  templateUrl: './print-contract.component.html',
  styleUrls: ['./print-contract.component.scss']
})
export class PrintBillA4Component implements OnInit {

  @Input() public readonly item;
  @Input() public readonly AccountSetup;
  public printLang: string = 'ar';
  public ContractTermText: string;

  cashsaleId: number;

  constructor(
    public sharedService: SharedService,
    private route: ActivatedRoute,
  ) {

  }
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.LOW;
  // value = 'https://app.glamera.com/invoices/qr-code.pdf/';
  // value = '';
  ngOnInit(): void {
    this.cashsaleId = this.route.snapshot.params['id'];

    // this.value = 'https://app.glamera.com/invoices/' + this.item.Id;


    this.ContractTermText = this.item?.ContractTerms ?? this.AccountSetup?.ContractTermText;

    this.printLang = this.sharedService.Lang;

    if (this.AccountSetup?.SelectedPrintInvoiceLanguage && this.AccountSetup?.SelectedPrintInvoiceLanguage != 'all') {
      this.printLang = this.AccountSetup?.SelectedPrintInvoiceLanguage;
    }

  }
  PrintPage() {
    window.print();
  }


}
