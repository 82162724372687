
import { ClientBalanceTransactionDirection as ClientBalanceTransactionDirection } from "../enums/client-balance-transaction-direction";
import { ClientBalanceTransactionType } from "../enums/client-balance-transaction-type";
import { SearchCriteria } from "./search-criteria";

export class ClientBalanceDetailSearchCriteria extends SearchCriteria {

    public ClientBalanceId:number;

    public TransactionId:number;

    public ClientId:number;
    
    public ClientBalanceTransactionDirection: ClientBalanceTransactionDirection;

    public ClientBalanceTransactionType: ClientBalanceTransactionType;

}
