import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AccountInfo } from '../../../models/classes/accounts/account-info';
import { AccountInfoCriteria } from '../../../models/search-criterias/account-info-criteria';
import { AccountInfoSearchResult } from '../../../models/search-result/account-info-search-result';
import { ExecutionResponse } from '../../../models/support/execution-response';
import { MessageService } from '../../../services/message/message.service';
import { BaseService, BaseServiceSZ } from '../../../services/other/baseService';
import { SharedService } from '../../../services/other/shared.service';
import { UIStateService } from '../../../services/other/ui-state.service';


@Injectable({
  providedIn: 'root'
})


export class AccountInfoService extends BaseService {
  accountInfoUrl: string;
  constructor(private http: HttpClient,
    private messageService: MessageService,
    private _BaseService: BaseServiceSZ,
    private sharedService: SharedService,
    private _UIStateService: UIStateService) {
    super();

    this.accountInfoUrl = this.sharedService.ApiUrl/*this._BaseService.ApiUrl */ + 'AccountInfo';
  }

  getAccountInfo(criteria?: AccountInfoCriteria): Observable<AccountInfoSearchResult> {


    return this.http.post<AccountInfoSearchResult>(this.accountInfoUrl + '/Get', criteria, super.getHeaders())
      .pipe(
        tap(res => {
          // this._BaseService.ValidationResult(res);
        }),

        // catchError(this._BaseService.handleError<AccountInfoSearchResult>('getAccountInfo get=${criteria}'))
      );
  }
  
  downloadExcelFile(apiActionName){
    return this.http.get(this.accountInfoUrl  + '/' + apiActionName, {
   
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.sharedService.Token,
        'reportProgress': 'true',
        'observe':'events',
        'CompanyId': this.getCompanyId() ? this.getCompanyId().toString() : "",
        'Content-Type': 'application/octet-stream',
      }), responseType: 'blob',}).pipe(
      tap(
        data => console.log('You received data'),
        error => console.log(error)
      )
    );
}

   uploadAccountExcel(body){

    return this.http.post<ExecutionResponse<boolean>>(this.accountInfoUrl + '/Upload', body,{
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.sharedService.Token,
        'reportProgress': 'true',
        'observe':'events',
        'CompanyId': this.getCompanyId() ? this.getCompanyId().toString() : "",

      })})
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);

        }),

        catchError(this._BaseService.handleError<ExecutionResponse<boolean>>('upload formData=${formData}'))
      );
}




  getAccountInfo2(body) {
    return this.http.post<any>(this.accountInfoUrl + '/Get', body, super.getHeaders())

  }

  CreateAccountInfo(accountinfo?: AccountInfo): Observable<ExecutionResponse<AccountInfo>> {


    return this.http.post<ExecutionResponse<AccountInfo>>(this.accountInfoUrl + '/Create', accountinfo, super.getHeaders())
      .pipe(
        tap(res => {
          //  this._BaseService.ValidationResult(res);

        }),

        catchError(this._BaseService.handleError<ExecutionResponse<AccountInfo>>('createAccountInfo create=${accountinfo}'))
      );
  }



  UpdateAccountInfo(accountinfo?: AccountInfo): Observable<ExecutionResponse<AccountInfo>> {


    return this.http.post<ExecutionResponse<AccountInfo>>(this.accountInfoUrl + '/Update', accountinfo, super.getHeaders())
      .pipe(
        tap(res => {
          //  this._BaseService.ValidationResult(res);

        }),

        catchError(this._BaseService.handleError<ExecutionResponse<AccountInfo>>('UpdateAccountInfo Update=${accountinfo}'))
      );
  }

  DeleteAccountInfo(Id: number): Observable<ExecutionResponse<AccountInfo>> {


    return this.http.post<ExecutionResponse<AccountInfo>>(this.accountInfoUrl + '/Delete', Id, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);

        }),

        catchError(this._BaseService.handleError<ExecutionResponse<AccountInfo>>('DeleteAccountInfo Update=${accountinfo}'))
      );
  }



}
