export class GymReservationDTO {

  constructor(){

  }

  public GymClientMembershipClassId: number;

  public GymClassDetailId: number;

  public GymClientMembershipAppointmentIds: number[];

}
