import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import FileSaver from 'file-saver';
import { ChooseAccountsComponent } from 'src/app/shared/choose-accounts/choose-accounts.component';
// import saveAs from "file-saver";
// import saveAs from "file-saver";
import { AccountSetup } from '../../../models/classes/account-setup/account-setup';
import { LOYALTY_POINT_SYSTEM } from '../../../models/const/loyality-const';
import { CategoryType } from '../../../models/enums/category-type';
import { AccountSetupSearchCriteria } from '../../../models/search-criterias/account-setup-search-criteria';
import { ResponseState } from '../../../models/support/response-state';
import { AccountSetupService } from '../../../services/account/account-setup.service';
import { BaseServiceSZ } from '../../../services/other/baseService';
import { SharedService } from '../../../services/other/shared.service';
import { PaginationComponent } from '../../../shared/pagination/pagination.component';
import { StockService } from '../service/stock.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-productcategories',
  templateUrl: './productcategories.component.html',
  styleUrls: ['./productcategories.component.scss'],
})
export class ProductcategoriesComponent implements OnInit {
  @ViewChild('pager', { static: true }) pager: PaginationComponent;
  @ViewChild('fileUpload') fileUpload: ElementRef<HTMLElement>;
  @Input() asModal: boolean;
  @Input() modeType: number;
  @Output() changeAccount = new EventEmitter();
  @Output() removeAccount = new EventEmitter();
  @Input() editAccounts: any[] = [];

  public LoyaltyPointSystem: any;
  public CategoryBranchesIdList: number[] = [];
  public CompanyAccountSetups: AccountSetup[] = [];
  Loading: boolean;
  file: any;
  constructor(
    private toastr: ToastrService,
    private stockService: StockService,
    public sharedService: SharedService,
    private readonly modalService: NgbModal,
    public translate: TranslateService,
    private accountSetUpService: AccountSetupService,
    private fb: UntypedFormBuilder
  ) {
    this.LoyaltyPointSystem = LOYALTY_POINT_SYSTEM;
  }

  ngOnInit(): void {
    this.pager.pageSize = 10;
    this.pager.ddlPageSize = 5;
    this.pager.GlobalPageIndex = 0;

    this.GetData();
    this.GetAccountSetUps();

    this.GFormDate();
  }
  GFormDate() {
    this.GForm = this.fb.group({
      Id: 0,
      TagId: [],
      Name: [],
      NameEn: [''],
      NameAr: [''],
      OnlineDescriptionEn: [''],
      OnlineDescription: [''],
      OnlineDetails: [''],
      Status: true,
      CatType: CategoryType.Product,
      ClassId: [],
      PointsRedemption: 0,
      LoyaltyPoints: null,
      LoyaltyMoney: null,
      LoyaltyPointSystem: null,
      ComapnyCategoriesId: [],
      AccountSetupId: this.sharedService.AccountSetupId,
      CategoryBranchesIdList: [],
      IsFastCategory: false,
    });
  }

  GForm: UntypedFormGroup;

  // get name() { return this.GForm.get('name'); }
  get VForm() {
    return this.GForm.controls;
  }
  onSubmit() {
    // TODO: Use EventEmitter with form value
    console.log(this.GForm.value);
  }

  Categories;
  GetData() {
    let category = {
      AccountSetupId: this.sharedService.AccountSetupId,
      CatType: CategoryType.Product,
      PageSize: this.pager.pageSize,
      PagingEnabled: true,
      IsForTableView: true,
      PageNumber: this.pager.GlobalPageIndex,
      FreeText: '',
      Status: '',
    };
    category.FreeText = this.freeText;
    category.Status = this.status;

    this.stockService.GetCategory(category).subscribe((response: any) => {
      if (response.State == ResponseState.Success) {
        this.Categories = response.Categories;
        if (this.editAccounts.length) {
          this.Categories.forEach((element, index) => {
            this.editAccounts.forEach((itemAcc) => {
              if ((itemAcc.categoryId || itemAcc.Id) === element.Id) {
                this.Categories[index] = {
                  ...this.Categories[index],
                  accountSelected: {
                    ...itemAcc,
                    Name: this.modeType
                      ? itemAcc?.accountSelected?.DebitAccountName ||
                      itemAcc?.accountSelected?.Name
                      : itemAcc?.accountSelected?.CreditAccountName ||
                      itemAcc?.accountSelected?.Name,
                  },
                };
              }
            });
          });
        }

        this.pager.EventsCount = response.TotalCount;
        this.pager.setPage(this.pager.GlobalPageIndex, false);
      }
    });
    this.Loading = false;
  }

  freeText;
  searchByText() {
    this.GetData();
  }

  ReciveFreeText(e) {
    console.log(e);
    this.freeText = e;
    this.GetData();
  }

  status;
  filterByStatus(e) {
    this.status = Number(e.target.value);
    this.GetData();
  }
  // ComapnyCategoriess;
  // comapnyCategories() {
  //   let companyservice = {
  //     AccountSetupId: this.sharedService.AccountSetupId,
  //     CompanyId: this.sharedService.CompanyId,
  //     CatType: 2,
  //     ForAutoComplete: 1,
  //     FreeText: '',
  //   };
  //   this.stockService
  //     .GetcomapnyCategories(companyservice)
  //     .subscribe((response: any) => {
  //       if (response.State == ResponseState.Success) {
  //         this.ComapnyCategoriess = response.ComapnyCategoriess;
  //       }
  //     });
  // }
  // Dropdowns;
  // Classifications;
  // GetDropdowns() {
  //   let dropdowns = {
  //     MasterCodeId: '11',
  //   };
  //   this.stockService.GetDropdowns(dropdowns).subscribe((response: any) => {
  //     if (response.State == ResponseState.Success) {
  //       this.Dropdowns = response.Dropdowns;
  //       this.Classifications = this.Dropdowns.Classifications;
  //       console.log(this.Dropdowns);
  //       console.log(this.Classifications);
  //     }
  //   });
  // }

  CreateData() {
    if (this.GForm.value.Id == 0) {
      this.Create();
    } else {
      this.Update();
    }
  }

  Create() {
    console.log(this.GForm.value);
    this.stockService
      .CreateCategory(this.GForm.value)
      .subscribe((response: any) => {
        if (response.State == ResponseState.Success) {
          this.sharedService.ToastrSuccess(response.Message);
          this.sharedService.HideModal();
          this.GForm.reset();
          this.GetData();
        } else {
          this.sharedService.ToastrError(response.Message);
        }
      });
  }

  Update() {
    this.stockService
      .UpdateCategory(this.GForm.value)
      .subscribe((response: any) => {
        if (response.State == ResponseState.Success) {
          this.sharedService.ToastrSuccess(response.Message);
          this.sharedService.HideModal();
          this.GForm.reset();
          this.GetData();
        } else {
          this.sharedService.ToastrError(response.Message);
        }
      });
  }

  Delete(item) {
    this.stockService.DeleteCategory(item.Id).subscribe((response: any) => {
      if (response.State == ResponseState.Success) {
        this.sharedService.ToastrSuccess(response.Message);
        this.sharedService.HideModal();
        this.GForm.reset();
        this.GetData();
      } else {
        this.sharedService.ToastrError(response.Message);
      }
    });
  }

  editState: boolean = false;
  itemToEdit;
  OpenModal(event, item) {
    this.editState = true;
    this.itemToEdit = item;
  }

  stockById;
  ModelAdd() {
    this.GForm.reset();
    this.GFormDate();
    this.GForm.controls.Id.setValue(0);
  }

  ModalEdit(event, item) {
    this.CategoryBranchesIdList = [];

    let CategoryBranchesIdListTemp: number[] = [];

    if (item.CategoryBranches && item.CategoryBranches.length > 0) {
      CategoryBranchesIdListTemp = item.CategoryBranches.map((s) => s.BranchId);
    }

    this.editState = true;
    this.itemToEdit = item;

    this.GForm.patchValue(item);

    this.CategoryBranchesIdList = CategoryBranchesIdListTemp;
  }

  checkValue(st: any) {
    console.log(st);
  }

  handleUploadFile() {

    if (this.file) {
      this.uploadFile();
    } else {
      let fileUploadButton: HTMLElement = this.fileUpload.nativeElement;
      fileUploadButton.click();
    }
  }
  uploadFile() {
    this.Loading = true;
    if (!this.file) {
      this.sharedService.ToastrSuccess("No file selected");

      return;

    }
    const formData = new FormData();
    formData.append('file', this.file);

    this.stockService.uploadProCatExcel(formData).subscribe(
      response => {
        if (response.State == ResponseState.Success) {
          this.sharedService.ToastrSuccess(response.Message);
          this.GetData();

        } else if (response.State == ResponseState.ValidationError) {

          this.toastr.warning(this.translate.instant(response.Message));
          this.Loading = false;
        }
        else {
          this.sharedService.ToastrError(response.Message);
          this.Loading = false;
        }
      });
     
    this.Loading = true;
    this.file = undefined;
  }
  FileName;
  onFileSelected(event: any) {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];


      this.FileName = this.file.name;
    }

  }
  downloadFile() {

    this.stockService.downloadProCatExcelFile("DownloadFile").subscribe((res: any) => {
      const blob = new Blob([res], { type: 'application/octet-stream' });
      FileSaver.saveAs(blob, 'ProductCatTemplate.xlsx');

    });
  }
  GetAccountSetUps() {
    var accountSetupSearchCriteria = new AccountSetupSearchCriteria();

    this.accountSetUpService
      .getForAutoCompleteNewDoNotDepenOnTableUserAccountSetup(
        accountSetupSearchCriteria
      )
      .subscribe((result) => {
        this.CompanyAccountSetups = result.AccountSetupBasic;
      });
  }

  exportProductsCategories() {
    this.stockService.ExportProductCategories().subscribe((res: any) => {
      const blob = new Blob([res], { type: 'application/octet-stream' });
      FileSaver.saveAs(blob, 'product-category.xlsx');
    });
  }

  getAccountInfo2(category: any) {
    const modalRef = this.modalService.open(ChooseAccountsComponent);
    modalRef.componentInstance.isViewPlus = true;
    modalRef.componentInstance.emitService.subscribe((emmitedValue) => {
      category.accountSelected = emmitedValue;
      this.changeAccount.emit(category);
    });
  }

  onRemoveAccount(category: any): void {
    this.removeAccount.emit(category);
    delete category.accountSelected;
  }
}
