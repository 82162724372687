<div class="modal-header">
    <h5 class="modal-title text-capitalize" id="exampleModalLabel">
        {{'Delete' | translate }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="text-center py-4">
        <h4> {{'DeleteConfirm' | translate}}?</h4>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="deleteItem(item)">{{'Yes'
        | translate}}</button>
    <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">{{'No' |
        translate}}</button>
</div>