
<div class="p-5 cont" dir="ltr">

    <ol>
          
       <li><p>open app</p></li>
       <li><p>login with your account credentials</p></li>
       <li><p>select profile tab </p></li>
       <li><p>click delete account</p></li>
      
    </ol>
   
   </div>
   
   
   
   