import { Injectable } from '@angular/core';
import { BaseService, BaseServiceSZ } from './baseService';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../message/message.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { VisaTypeSearchCriteria, VisaTypeSearchResult } from '../../models/classes/other/visa-type';

@Injectable({
  providedIn: 'root'
})
export class VisaTypeService extends BaseService {
  private visaTypeUrl   //= super.BaseUrl() + 'Transactions';  // URL to web api

  constructor(
    private http: HttpClient, private messageService: MessageService,private  _BaseService:BaseServiceSZ) {
    super();
    this.visaTypeUrl =    this._BaseService.ApiUrl + 'VisaType';

  }

  searchVisaTypes(visaTypeSearchCriteria: VisaTypeSearchCriteria): Observable<VisaTypeSearchResult> {
    return this.http.post<VisaTypeSearchResult>(this.visaTypeUrl + '/Get', visaTypeSearchCriteria, super.getHeaders())
      .pipe(
        tap(visaTypes => this._BaseService.log('fetched transactions'))
      );
  }
}
