import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-buy-sure',
  templateUrl: './modal-buy-sure.component.html',
  styleUrls: ['./modal-buy-sure.component.scss']
})
export class ModalBuySureComponent implements OnInit {

  @Input() item;
  @Input () title;
  @Input() messageCode;
  @Output() IamSure = new EventEmitter<string>();

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }
  SuteItem(item: any) { 
    this.IamSure.emit(item);
  }
}
