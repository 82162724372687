<section class="verification" fxLayout="column" fxLayoutAlign="center center">
  <form fxLayout="column" fxLayoutAlign="start stretch">
    <i
      class="fa-solid fa-arrow-left-long fa-xl close-btn-x"
      (click)="backToPhaseOne()"
    ></i>
    <main>
      <lib-language style="visibility: hidden"></lib-language>

      <img src="assets/img/verify.jpg" alt="" title="" />
      <h1>{{ "Verification_Code" | translate }}</h1>
      <p>
        {{
          (+resendCodeData.VerifyMobileNumberType === 2
            ? "Enter_The_Code_WhatsApp"
            : "Enter_The_Code_Phone"
          ) | translate : { number: registeredMobileNumber }
        }}
      </p>
    </main>

    <ng-otp-input
      [config]="otpInputConfig"
      (onInputChange)="onFill($event)"
    ></ng-otp-input>

    <div fxLayout="row" fxLayoutAlign="space-between center" class="desc">
      <h4>
        {{ count$ | async }}<span>{{ "Seconds" | translate }}</span>
      </h4>
      <button [disabled]="!openResendCode" (click)="onResendCode()">
        {{ "Resend_code" | translate }}
      </button>
    </div>

    <a (click)="onGetVerifiyIdentity()">{{
      "Get_your_code_another_way" | translate
    }}</a>
  </form>
</section>
