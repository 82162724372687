import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { ClientBalanceStatus } from '../../models/enums/client-balance-status';
import { ResponseState } from '../../models/support/response-state';
import { SharedService } from '../../services/other/shared.service';
import { Client } from '../../models/classes/client/client';
import { GymClientMembershipService } from '../../services/gym/gym-client-membership.service';
import { ExecutionResponse } from '../../models/support/execution-response';
import { ToastrService } from 'ngx-toastr';
import { CancelGymMembershipModel } from '../../models/search-criterias/cancel-gym-membership-model';

@Component({
  selector: 'app-modal-cancel-gym-membership',
  templateUrl: './modal-cancel-gym-membership.component.html',
  styleUrls: ['./modal-cancel-gym-membership.component.scss'],
  providers: [DatePipe],
})
export class ModalCancelGymMembership implements OnInit {
  @Input() GymClientMembershipId: number;
  @Input() CurrentClientName: string;
  @Input() CurrentGymMembershipName: string;

  @Output() public DoneCanceling = new EventEmitter<ResponseState>();

  public freeText: string;
  public LoadingCancelGymMembership: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    public translate: TranslateService,
    public sharedService: SharedService,
    public gymClientMembershipService: GymClientMembershipService,
    public toaster: ToastrService
  ) {}

  ngOnInit(): void {}

  onSearch(e) {
    this.freeText = e.term;
  }
  customSearchFn(term: string, item: Client) {
    term = term.toLowerCase();
    return (
      item.Name.toLowerCase().indexOf(term) > -1 ||
      item.Mobile.toLowerCase().indexOf(term) > -1 ||
      item.Mobile.toLowerCase() === term
    );
  }
  public get clientBalanceStatus(): typeof ClientBalanceStatus {
    return ClientBalanceStatus;
  }

  CancelGymMembership() {
    this.LoadingCancelGymMembership = true;
    var model = new CancelGymMembershipModel();

    model.AccountSetupId = this.sharedService.AccountSetupId;
    model.GymClientMembershipId = this.GymClientMembershipId;

    this.gymClientMembershipService
      .CancelGymMembership(model)
      .subscribe((response: ExecutionResponse<boolean>) => {
        if (response.State == ResponseState.Success) {
          this.toaster.success(
            this.translate.instant('DoneCancelingGymMembershipSuccessfully.')
          );
          this.activeModal.close();
        }
        this.LoadingCancelGymMembership = false;
        this.DoneCanceling.emit(response?.State);
      });
  }
}
