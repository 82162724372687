<section class="reset-password" fxLayout="column" fxLayoutAlign="center center">
  <form
    fxLayout="column"
    fxLayoutAlign="start stretch"
    (submit)="onSubmit()"
    [formGroup]="form"
  >
    <lib-language style="visibility: hidden"></lib-language>

    <main>
      <img
        src="assets/img/back.jpg"
        alt=""
        title=""
        (click)="sharedService.back()"
      />
    </main>

    <p class="black-color">{{ "RESET_PASSWORD" | translate }}</p>
    <span class="span"
      >{{ "ENTER_PHONE_NUMBER" | translate }} & <br />
      {{ "WILL_RESET_PASSWORD" | translate }}</span
    >

    <mat-label class="label mt-40">{{
      "AUTH.PHONE_NUMBER" | translate
    }}</mat-label>
    <mat-form-field appearance="outline">
      <input
        matInput
        placeholder="{{ 'PHONE_NUMBER' | translate }}"
        formControlName="UserName"
      />
      <mat-error>
        <app-error-form
          [name]="'AUTH.PHONE_NUMBER'"
          [control]="form.controls['UserName']"
          [maxLength]="20"
          [minLength]="4"
        ></app-error-form>
      </mat-error>
    </mat-form-field>

    <div fxLayout="row" fxLayoutAlign="end start">
      <button
        mat-raised-button
        color="primary"
        type="submit"
        class="submit-btn"
        [disabled]="form.invalid || loading"
        [class.spinner]="loading"
      >
        {{ "RESET_PASSWORD" | translate }}
      </button>
    </div>

    <div
      class="different-account"
      fxLayout="column"
      fxLayoutAlign="start stretch"
    >
      <h4 (click)="onGetDifferentAccount()">
        {{ "SIGNIN_DIFFERENT_ACCOUNT" | translate }}
      </h4>
    </div>
  </form>
</section>
