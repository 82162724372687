import { SearchCriteria } from "./search-criteria";

export class AccountInfoCriteria extends SearchCriteria {
   LevelNumber?: number;
   IsActive: boolean;
   TransactionType?: number;
   AccountGroups?: number;
   AccountGroupId?: number;
   AccountGroupCode?: number;
   IsDebit?: boolean;
   GetChilds?: boolean;
   ParentNumber?: string;
   ParentId: number;
   GetLeafsOnly:boolean;
   IsChild:boolean;
}
