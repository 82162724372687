import { DatePipe } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BookingService } from '../../dashboard/bookings/service/booking.service';
import { EmployeeService } from '../../dashboard/employees/service/employee.service';
import { AccountSetup } from '../../models/classes/account-setup/account-setup';
import { ResponseState } from '../../models/support/response-state';
import { AccountSetupService } from '../../services/account/account-setup.service';
import { SharedService } from '../../services/other/shared.service';
import { AccountSetupSearchCriteria } from "./../../models/search-criterias/account-setup-search-criteria";

export enum SlotStatus {
  Free = 0,
  Busy = 1
}
export enum Status {
  Available = 0,
  Blooked = 1,
}
@Component({
  selector: 'app-employee-status',
  templateUrl: './employee-status.component.html',
  styleUrls: ['./employee-status.component.scss'],
  providers: [DatePipe],
})
export class EmployeeStatusComponent implements OnInit {

  @Input() name;

  public selected: number;
  public CompanyAccountSetups: AccountSetup[] = [];
  slotStatus = []
  Employees: any;
  public ExpandRow: number;

  constructor(
    public accountSetUpService: AccountSetupService,
    private employeeService: EmployeeService,
    public sharedService: SharedService,
    private bookingService: BookingService,
    private route: ActivatedRoute,
    private router: Router,
    private ngbModal: NgbModal,
    public datepipe: DatePipe,
    public activeModal: NgbActiveModal) { }

  ngOnInit(): void {

    this.getEmployeeStatuses();
    this.GetAccountSetUps();
    this.convertEnumToArray();
    // this.GetEmployeeTimeSlots();

    // this.date = new Date();
    this.dateFrom = this.datepipe.transform(new Date(), 'yyyy-MM-dd');

  }
  // GetEmployeeTimeSlots
  selectEmplyeeTimeSlots(item, i) {

    if (this.ExpandRow == undefined || this.ExpandRow != i) {
      this.ExpandRow = i;
      this.GetEmployeeTimeSlots(item);
    }
    else {
      this.ExpandRow = undefined;
    }
  }

  convertEnumToArray() {
    for (const [propertyKey, propertyValue] of Object.entries(SlotStatus)) {
      if (!Number.isNaN(Number(propertyKey))) {
        continue;
      }
      this.slotStatus.push({ id: propertyValue, name: propertyKey });
    }
  }
  getEmployees() {
    let jsonData =
    {
      AccountSetupId: this.sharedService.AccountSetupId,
      PagingEnabled: false,

    }
    this.employeeService.GetEmployees(jsonData).subscribe((response: any) => {
      if (response.State == ResponseState.Success) {
        this.Employees = response.Employees;

      }
    });
  }

  GetAccountSetUps() {
    var accountSetupSearchCriteria = new AccountSetupSearchCriteria();
    this.accountSetUpService.getForAutoCompleteNewDoNotDepenOnTableUserAccountSetup(accountSetupSearchCriteria).subscribe(result => {
      this.CompanyAccountSetups = result.AccountSetupBasic;

    });
  }

  employeeId: number;
  freeText: string;
  dateFrom: string;
  available: string = '';
  branchId: number;
  EmployeeStatus: any;
  getEmployeeStatuses() {

    // ShowInBookingScreen: true,
    // ShowActiveEmployes: true,
    // ShowActiveRooms: true,
    // DateFrom: this.dateFrom,
    // AccountSetupId: this.sharedService.AccountSetupId
    let TimeSlots = {
      "AccountSetupId": this.sharedService.AccountSetupId, "PagingEnabled": true, "PageSize": 30, "PageNumber": 0,
      "FreeText": this.freeText, "EmployeeId": this.employeeId, "DateFrom": this.dateFrom, "IsAvailableNow": this.available,
      ShowInBookingScreen: true, ShowActiveEmployes: true,
    }
    this.bookingService.GetEmployeeStatuses(TimeSlots).subscribe((response: any) => {
      if (response.State == ResponseState.Success) {
        this.EmployeeStatus = response.Result
      }
    });
  }


  SingleHourSlots;
  GetEmployeeTimeSlots(item) {
    let TimeSlots = { "AccountSetupId": this.sharedService.AccountSetupId, "DateFrom": "2022-01-26", "IncludeTodayHours": true, "EmployeeId": item.Id }
    this.bookingService.GetEmployeeTimeSlots(TimeSlots).subscribe((response: any) => {
      if (response.State == ResponseState.Success) {
        this.SingleHourSlots = response.SingleHourSlots
      }
    });
  }
  dataToday;
  goToAppointment(item, time) {
    console.log(item.Id)
    console.log(time.Time)
    this.dataToday = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    console.log(this.dataToday)

    let dateTime = this.dataToday + 'T' + time.Time
    console.log(dateTime)
    this.router.navigate(['/appointment/calendar', item.Id, dateTime]);
    this.ngbModal.dismissAll();

  }

}
