import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { UIStateService, UISTATE_KEYS } from '../other/ui-state.service';
import { HttpBaseService } from '../other/httpbase.service';
import { AccountSetup } from '../../models/classes/account-setup/account-setup';
import { ExecutionResponse } from '../../models/support/execution-response';



@Injectable({
  providedIn: 'root'
})
export class GetCurrentAccountSetupService extends BaseService {
  accountSetupUrl: string;
  constructor(
    private http: HttpClient,
    private _BaseService: BaseServiceSZ,
    private _UIStateService: UIStateService
  ) {
    super();
    //this.accountSetupUrl = this.sharedService.ApiUrl/*this._BaseService.ApiUrl */ + 'AccountSetup';

  }

  getCurrentAccountSetup(ApiUrl: string, accountSetupId?: number): Observable<ExecutionResponse<AccountSetup>> {

    this.accountSetupUrl = ApiUrl/*this._BaseService.ApiUrl */ + 'AccountSetup';
    let myAccountSetupId = accountSetupId || super.getAccountSetupId();
    return this.http.post<ExecutionResponse<AccountSetup>>
      (this.accountSetupUrl + '/GetByIdLite', myAccountSetupId, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<ExecutionResponse<AccountSetup>>('getAccountSetup id=${id}'))
      );
  }


}
