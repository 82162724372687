<div class="row mb-2" *ngFor="let day of days">
  <div class="col-2">
    <h5> السبت </h5>
  </div>
  <div class="col-5">
    <div>
      <input type="time" class="form-control">
    </div>
  </div>
  <div class="col-5">
    <div>
      <input type="time" class="form-control">
    </div>
  </div>
</div>
