import { SearchCriteria } from "./search-criteria";

export class AccountSetupSearchCriteria extends SearchCriteria {
  public Id: number;
  public UserId: number;
  public UserName: string;
  public FromGlamatek: boolean;
  public GetWithOutStatusAndVisibleToGlamera: boolean;
  public GetMainBranch: boolean;
}
