import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormArray,
} from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { ClientBalance } from '../../models/classes/balances/client-balance';
import { InvoicePayableByTheClient } from '../../models/classes/balances/invoice-payable-by-the-client';
import { ClientBalanceStatus } from '../../models/enums/client-balance-status';
import { TransactionType } from '../../models/enums/transaction-type';
import { ClientSearchCriteria } from '../../models/search-criterias/client-search-criteria';
import { GetInvoicesPayableByTheClientSearchCriteria } from '../../models/search-criterias/get-invoices-payable-by-the-client-search-criteria';
import { GetInvoicesPayableByTheClientSearchResult } from '../../models/search-result/getInvoices-payable-by-the-client-search-result';
import { ResponseState } from '../../models/support/response-state';
import { AppointmentService } from '../../services/appointment/appointment.service';
import { ClientBalanceService } from '../../services/balances/client-balance.service';
import { ClientService } from '../../services/client/client.service';
import { SharedService } from '../../services/other/shared.service';
import { TransactionsSecondService } from '../../services/transaction/transactions-second.service';
import { Client } from '../../models/classes/client/client';
import { GetLiteForGBClientDTO } from 'src/app/models/classes/client/get-lite-for-gb-client';

@Component({
  selector: 'app-modal-client-balance-operations',
  templateUrl: './modal-client-balance-operations.component.html',
  styleUrls: ['./modal-client-balance-operations.component.scss'],
  providers: [DatePipe],
})
export class ModalClientBalanceOperationsComponent implements OnInit {
  @Input() ClientBalance: ClientBalance;
  @Input() TransactionType: TransactionType;

  @Output() CloseModal = new EventEmitter<boolean>();

  GForm: UntypedFormGroup;
  btnLoading: boolean;
  public Clients: GetLiteForGBClientDTO[];
  TransactionPayPostpaidBills: UntypedFormArray;
  TransactionsPayments: UntypedFormArray;
  public modalTitle: string = '';
  public InvoicesPayableByTheClientPlaceHolder = '';
  public TotalAmountForClientBalance: number = 0;
  public InvoicesPayableByTheClient: InvoicePayableByTheClient[];
  public clientSearchText: string;

  public ClientId: number;
  public SelectedInvoicesPayableByTheClient: InvoicePayableByTheClient[] = [];
  public SumSelectedInvoicesPayableByTheClient: number = 0;
  public SelectedInvoicesPayableByTheClientIds: number[];
  public AddAmountExcessOfPostpaidToClientBalance: boolean;

  constructor(
    public appointmentService: AppointmentService,
    public activeModal: NgbActiveModal,
    public clientService: ClientService,
    public translateService: TranslateService,
    public toaster: ToastrService,
    public sharedService: SharedService,
    public _transactionsSecondService: TransactionsSecondService,
    private fb: UntypedFormBuilder,
    public datepipe: DatePipe,

    public clientBalanceService: ClientBalanceService
  ) {}

  ngOnInit(): void {
    if (
      this.TransactionType == TransactionType.AddToClientBalance ||
      this.TransactionType == TransactionType.AddToAnyClientBalance
    ) {
      this.modalTitle = 'AddMoneyForClientBalance';
    } else {
      this.modalTitle = 'WithdrawBalanceToTheClient';
    }

    this.GFormData();

    if (this.TransactionType == TransactionType.AddToClientBalance) {
      this.GetInvoicesPayableByTheClient();
    } else {
      this.InvoicesPayableByTheClient = [];
    }

    if (this.TransactionType == TransactionType.AddToAnyClientBalance) {
      this.GetClients();
    }

    this.GetTransactionHeader();

    let myDate = new Date();

    if (this.ClientBalance) {
      this.GForm.get('BookingOrTransactionModel')
        .get('BookingOrTransactionClientId')
        .patchValue(this.ClientBalance.ClientId);
    }
    this.GForm.get('BookingOrTransactionModel')
      .get('BookingOrTransactionDate')
      .patchValue(this.datepipe.transform(myDate, 'yyyy-MM-dd'));
    this.GForm.get('BookingOrTransactionModel')
      .get('BookingOrTransactionTime')
      .patchValue(this.datepipe.transform(myDate, 'HH:mm:ss'));
  }

  onSearch(e) {
    this.clientSearchText = e.term;
    this.GetClients();
  }

  ClientChanged() {
    if (this.ClientId && this.ClientId > 0) {
      this.GetInvoicesPayableByTheClient(this.ClientId);
    } else {
      this.InvoicesPayableByTheClient = [];
    }
  }

  ClientsCustomSearchFn(term: string, item: Client) {
    term = term.toLowerCase();
    return (
      item.Name.toLowerCase().indexOf(term) > -1 ||
      item.Mobile.toLowerCase().indexOf(term) > -1 ||
      item.Mobile.toLowerCase() === term
    );
  }

  GetClients() {
    var clientSearchCriteria: ClientSearchCriteria = new ClientSearchCriteria();
    clientSearchCriteria.AccountSetupId = this.sharedService.AccountSetupId;
    clientSearchCriteria.PagingEnabled = true;
    clientSearchCriteria.PageSize = 2;
    clientSearchCriteria.PageNumber = 0;
    clientSearchCriteria.FreeText = this.clientSearchText;

    this.clientService
      .GetClientsLite(clientSearchCriteria)
      .subscribe((response) => {
        if (response.State == ResponseState.Success) {
          if (response.TotalCount == 0) {
            var message = this.translateService.instant('NoClientsFound');
            this.toaster.warning(message);
          } else {
            this.Clients = response.Clients;
          }
        }
      });
  }

  numberOnly(event): boolean {
    var charCode = event.which ? event.which : event.keyCode;
    if (
      charCode != 190 &&
      charCode != 46 &&
      charCode > 31 &&
      (charCode < 48 || charCode > 57)
    )
      return false;
    return true;
  }

  GFormData() {
    this.GForm = this.fb.group({
      AccountSetupId: this.sharedService.AccountSetupId,
      AddOrEdit: 1,
      BookingOrTransactionType: 2,
      BookingOrTransactionModel: this.fb.group({
        Id: 0,
        TransactionType: this.TransactionType,
        BookingOrTransactionId: 0,
        BookingOrTransactionCompanyId: this.sharedService.CompanyId,
        BookingOrTransactionAccountSetupId: this.sharedService.AccountSetupId,
        BookingOrTransactionClientId: [null, Validators.required],
        BookingOrTransactionTotalPrice: 0,
        BookingOrTransactionDate: null,
        BookingOrTransactionTime: null,
        TransactionCashierDailyId: 0,
        TransactionCashierShiftId: 0,
        TransactionCashierId: 0,
        TransactionEnteredById: 0,
        TransactionStatus: 3,
        TransactionAddAmountExcessOfPostpaidToClientBalance: false,
        TransactionPayPostpaidBills: this.fb.array([]),
        TransactionsPayments: this.fb.array([this.AddTransactionsPayments()]),
      }),
    });
  }
  AddTransactionPayPostpaidBills() {
    return this.fb.group({
      TransactionId: 0,
      PaidAmount: 0,
    });
  }
  AddTransactionsPayments() {
    return this.fb.group({
      Id: 0,
      TransactionId: 0,
      PaymentTypeId: 0,
      Amount: 0,
      AmountInDefault: 0,
      CurrencyId: 0,
      AvalableCredit: null,
      AvalableClientPaymentDown: null,
      ShowAvalableCredit: false,
      ShowAvalableClientPaymentDown: null,
      showGiftcardCode: false,
      availablecredit: null,
      giftCardChoosed: false,
      voucherChoosed: false,
      giftCardCode: null,
      Code: null,
      enableCheckButton: null,
      showVisaDetailsButton: false,
      loyalityMonyChoosed: false,
      clientDownPaymentChoosed: false,
      VisaTypeId: null,
      VisaNumber: null,
      VisaTypeName: null,
      CurrencyFactor: 1,
      PaymentMethodId: 0,
      CreditCardId: null,
      ReferenceCode: null,
      GiftCardCode: null,
      GiftCardInformationId: null,
      PaymentMethodType: 0,
      TipAmount: null,
    });
  }
  InvoicesPayableByTheClientChange(event) {
    if (
      !this.SelectedInvoicesPayableByTheClientIds ||
      this.SelectedInvoicesPayableByTheClientIds.length <= 0
    ) {
      this.SelectedInvoicesPayableByTheClient = [];
      this.AddAmountExcessOfPostpaidToClientBalance = false;
    }

    // Add
    if (
      this.SelectedInvoicesPayableByTheClient.length <
      this.SelectedInvoicesPayableByTheClientIds.length
    ) {
      var AddedId = this.SelectedInvoicesPayableByTheClientIds.find(
        (s) =>
          !this.SelectedInvoicesPayableByTheClient.find(
            (x) => x.TransactionId == s
          )
      );

      const getInvoice: InvoicePayableByTheClient = Object.assign(
        {},
        this.InvoicesPayableByTheClient.find(
          (item) => item.TransactionId == AddedId
        )
      );

      this.SelectedInvoicesPayableByTheClient.push(getInvoice);
    }

    //Remove
    else if (
      this.SelectedInvoicesPayableByTheClient.length >
      this.SelectedInvoicesPayableByTheClientIds.length
    ) {
      var RemovedId = this.SelectedInvoicesPayableByTheClient.find(
        (s) =>
          !this.SelectedInvoicesPayableByTheClientIds.find(
            (x) => x == s.TransactionId
          )
      ).TransactionId;
      this.SelectedInvoicesPayableByTheClient =
        this.SelectedInvoicesPayableByTheClient.filter(
          (s) => s.TransactionId != RemovedId
        );
    }

    this.TotalAmountForClientBalanceKeyup(null);
  }

  GetInvoicesPayableByTheClient(clientId?: number) {
    var criteria = new GetInvoicesPayableByTheClientSearchCriteria();
    criteria.ClientId = clientId ? clientId : this.ClientBalance.ClientId;

    this.clientBalanceService
      .GetInvoicesPayableByTheClient(criteria)
      .subscribe((response: GetInvoicesPayableByTheClientSearchResult) => {
        if (response.State == ResponseState.Success) {
          this.InvoicesPayableByTheClient = response.InvoicesPayableByTheClient;

          if (
            !this.InvoicesPayableByTheClient ||
            this.InvoicesPayableByTheClient.length <= 0
          ) {
            this.InvoicesPayableByTheClientPlaceHolder =
              this.translateService.instant(
                'ThereIsNoInvoicesPayableByTheClient'
              );
          } else {
            this.InvoicesPayableByTheClientPlaceHolder =
              this.translateService.instant('InvoicesPayableByTheClient');
          }
        }
      });
  }

  public get clientBalanceStatus(): typeof ClientBalanceStatus {
    return ClientBalanceStatus;
  }
  public get transactionType(): typeof TransactionType {
    return TransactionType;
  }

  listPaid = [];

  public TotalAmountForClientBalanceKeyup(event) {

    if (
      this.TotalAmountForClientBalance &&
      this.TotalAmountForClientBalance > 0
    ) {
      this.GForm.get('BookingOrTransactionModel')
        .get('BookingOrTransactionTotalPrice')
        .patchValue(this.TotalAmountForClientBalance);

      if (
        this.SelectedInvoicesPayableByTheClient &&
        this.SelectedInvoicesPayableByTheClient.length > 0
      ) {
        var remainingTotalAddedAmountToClientBalance =
          this.TotalAmountForClientBalance;

        this.SelectedInvoicesPayableByTheClient.forEach((element) => {
          if (remainingTotalAddedAmountToClientBalance <= 0) {
            return true;
          }

          if (
            element.TotalPayableAmount <=
            remainingTotalAddedAmountToClientBalance
          ) {
            element.PaidAmount = element.TotalPayableAmount;
            remainingTotalAddedAmountToClientBalance -= element.PaidAmount;
          } else {
            element.PaidAmount = remainingTotalAddedAmountToClientBalance;
            remainingTotalAddedAmountToClientBalance -= element.PaidAmount;
          }
        });
      }
    }

    if (
      this.SelectedInvoicesPayableByTheClient &&
      this.SelectedInvoicesPayableByTheClient.length > 0
    ) {
      this.SumSelectedInvoicesPayableByTheClient =
        this.SelectedInvoicesPayableByTheClient.map((o) => o.PaidAmount).reduce(
          (a, c) => {
            return a + c;
          }
        );
    } else {
      this.SumSelectedInvoicesPayableByTheClient = 0;
    }

   /*  const controlArray = <UntypedFormArray>(
      this.GForm.get('BookingOrTransactionModel').get('TransactionsPayments')
    );
    controlArray.controls[0]
      .get('Amount')
      .patchValue(this.TotalAmountForClientBalance); */

    // this.listPaid =
    //   this.SelectedInvoicesPayableByTheClient.map(item => ({
    //     TransactionId: item.TransactionId,
    //     PaidAmount: item.PaidAmount
    //   }))
    // console.log(this.listPaid)
    // this.GForm.get('BookingOrTransactionModel').get('TransactionPayPostpaidBills').setValue(this.listPaid)
  }

  SubmitClientBalance() {
    this.btnLoading = true;

    this.GForm.get('BookingOrTransactionModel')
      .get('TransactionAddAmountExcessOfPostpaidToClientBalance')
      .setValue(this.AddAmountExcessOfPostpaidToClientBalance);

    if (this.TransactionType == TransactionType.AddToAnyClientBalance) {
      this.GForm.get('BookingOrTransactionModel')
        .get('BookingOrTransactionClientId')
        .patchValue(this.ClientId);

      this.GForm.get('BookingOrTransactionModel')
        .get('TransactionType')
        .patchValue(TransactionType.AddToClientBalance);
    }

    const control = <UntypedFormArray>(
      this.GForm.get('BookingOrTransactionModel').get(
        'TransactionPayPostpaidBills'
      )
    );
    for (let i = control.length - 1; i >= 0; i--) {
      control.removeAt(i);
    }

    this.TransactionPayPostpaidBills = this.GForm.get(
      'BookingOrTransactionModel'
    ).get('TransactionPayPostpaidBills') as UntypedFormArray;
    for (var i = 0; i < this.SelectedInvoicesPayableByTheClient.length; i++) {
      this.TransactionPayPostpaidBills.push(
        this.fb.group({
          PayForTransactionId:
            this.SelectedInvoicesPayableByTheClient[i].TransactionId,
          PaidAmount: this.SelectedInvoicesPayableByTheClient[i].PaidAmount,
        })
      );
    }

    console.log(this.GForm.value);
    this.appointmentService
      .CreateBookingOrTransaction(this.GForm.value)
      .subscribe((response: any) => {
        this.btnLoading = false;

        if (response.State == ResponseState.Success) {
          var message = '';
          if (this.TransactionType == TransactionType.AddToClientBalance) {
            message = this.translateService.instant(
              'DoneAddToClientBalanceSuccessfully.'
            );
            this.sharedService.ToastrSuccess(message);
          } else if (
            this.TransactionType == TransactionType.WithdrawBalanceToTheClient
          ) {
            message = this.translateService.instant(
              'DoneWithdrawBalanceToTheClientSuccessfully.'
            );
            this.sharedService.ToastrSuccess(message);
          }

          this.CloseModal.emit(true);
        } else {
          this.sharedService.ToastrError(response.Message);
        }
      });
  }

  SetTotalAmountForClientBalance(e) {

    this.TotalAmountForClientBalance = e;
    this.TotalAmountForClientBalanceKeyup(null);
    this.GForm.get('BookingOrTransactionModel')
      .get('BookingOrTransactionTotalPrice')
      .patchValue(e);
  }

  PushTransactionsPayments() {
    this.TransactionsPayments = this.GForm.get('BookingOrTransactionModel').get(
      'TransactionsPayments'
    ) as UntypedFormArray;
    this.TransactionsPayments.push(
      this.fb.group({
        TransactionId: 0,
        PaymentTypeId: 0,
        Amount: 0,
        AmountInDefault: 0,
        CurrencyId: 0,
        AvalableCredit: null,
        AvalableClientPaymentDown: null,
        ShowAvalableCredit: false,
        ShowAvalableClientPaymentDown: null,
        showGiftcardCode: false,
        availablecredit: null,
        giftCardChoosed: false,
        voucherChoosed: false,
        giftCardCode: null,
        Code: null,
        enableCheckButton: null,
        showVisaDetailsButton: false,
        loyalityMonyChoosed: false,
        clientDownPaymentChoosed: false,
        VisaTypeId: null,
        VisaNumber: null,
        VisaTypeName: null,
        CurrencyFactor: 1,
        PaymentMethodId: 0,
        CreditCardId: null,
        ReferenceCode: null,
        GiftCardCode: null,
        GiftCardInformationId: null,
        PaymentMethodType: 0,
      })
    );
  }
  /*======== TransactionHeader=========================================================*/
  TransactionHeader;
  GetTransactionHeader() {
    let jsonData = {
      EmployeeId: this.sharedService.EmployeeId,
      AccountSetupId: this.sharedService.AccountSetupId,
    };

    this.appointmentService
      .GetTransactionHeader(jsonData)
      .subscribe((response: any) => {
        if (response.State == ResponseState.Success) {
          this.TransactionHeader = response.Result;

          this.GForm.get('BookingOrTransactionModel').patchValue({
            TransactionEnteredById: this.TransactionHeader.EmployeeId,
            TransactionCashierDailyId: this.TransactionHeader.CashierDailyId,
            TransactionCashierShiftId: this.TransactionHeader.CashierShiftId,
            TransactionCashierId: this.TransactionHeader.CashierId,
          });
        }
      });
  }
}
