import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumberOnly]',
})
export class NumberOnlyDirective {
  constructor(private readonly element: ElementRef) {}

  @HostListener('input', ['event']) onChangeInput(event: Event) {
    const initalValue = this.element.nativeElement.value;
    this.element.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
    if (initalValue !== this.element.nativeElement.value) {
      event.preventDefault();
    }
  }
}
