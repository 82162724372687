import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AccountTypeCriteria } from '../../../models/search-criterias/account-type-criteria';
import { AccountTypeSearchResult } from '../../../models/search-result/account-type-search-result';
import { MessageService } from '../../../services/message/message.service';
import {
  BaseService,
  BaseServiceSZ,
} from '../../../services/other/baseService';
import { SharedService } from '../../../services/other/shared.service';
import { UIStateService } from '../../../services/other/ui-state.service';

@Injectable({
  providedIn: 'root',
})
export class AccountTypeService extends BaseService {
  AccountTypeUrl: string;
  private selectedBranchId: number;
  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private _BaseService: BaseServiceSZ,
    private sharedService: SharedService,
    private _UIStateService: UIStateService
  ) {
    super();
    this.AccountTypeUrl = this.sharedService.ApiUrl;
  }
  getAccountTypes(
    criteria?: AccountTypeCriteria
  ): Observable<AccountTypeSearchResult> {
    return this.http
      .post<AccountTypeSearchResult>(
        this.AccountTypeUrl + 'AccountType/Get',
        criteria,
        super.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.ValidationResult(res);
        }),
        catchError(
          this._BaseService.handleError<AccountTypeSearchResult>(
            'getAccountInfo get=${criteria}'
          )
        )
      );
  }
  getAccountGroup(): Observable<any> {
    return this.http
      .post<any>(this.AccountTypeUrl + 'AccountGroup/Get', super.getHeaders())
      .pipe(
        tap((res) => {
          this._BaseService.ValidationResult(res);
        })
      );
  }
  getSupplier(body): Observable<any> {
    return this.http
      .post<any>(this.AccountTypeUrl + 'Supplier/Get', body, super.getHeaders())
      .pipe(
        tap((res) => {
          this._BaseService.ValidationResult(res);
        })
      );
  }
  getDistributors(body): Observable<any> {
    return this.http
      .post<any>(
        this.AccountTypeUrl + 'Distributor/Get',
        body,
        super.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.ValidationResult(res);
        })
      );
  }

  /*  getClient(body): Observable<any> {
    return this.http.post<any>(this.AccountTypeUrl + 'Client/Get',body, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);
        }),
      );
  } */
  CreateSettingMigrationAccount(body): Observable<any> {
    return this.http
      .post<any>(
        this.AccountTypeUrl + 'SettingMigrationAccount/Create',
        body,
        super.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.ValidationResult(res);
        })
      );
  }

  GetSettingMigrationAccount(body): Observable<any> {
    return this.http
      .post<any>(
        this.AccountTypeUrl + 'SettingMigrationAccount/Get',
        body,
        super.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.ValidationResult(res);
        })
      );
  }
  GetJournal(body): Observable<any> {
    return this.http
      .post<any>(this.AccountTypeUrl + 'Journal/Get', body, super.getHeaders())
      .pipe(
        tap((res) => {
          this._BaseService.ValidationResult(res);
        })
      );
  }
  getSelectedBranchId(): number {
    return this.selectedBranchId;
  }

  setSelectedBranchId(branchId: number): void {
    this.selectedBranchId = branchId;
  }
  CreateJournal(body): Observable<any> {
    return this.http
      .post<any>(
        this.AccountTypeUrl + 'Journal/Create',
        body,
        super.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.ValidationResult(res);
        })
      );
  }
  UpdateJournal(body): Observable<any> {
    return this.http
      .post<any>(
        this.AccountTypeUrl + 'Journal/Update',
        body,
        super.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.ValidationResult(res);
        })
      );
  }

  GenerateJournalNumber(body): Observable<any> {
    return this.http
      .post<any>(
        this.AccountTypeUrl + 'Journal/GenerateJournalNumber',
        body,
        super.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.ValidationResult(res);
        })
      );
  }
  GenerateNumber(body): Observable<any> {
    return this.http
      .post<any>(
        this.AccountTypeUrl + 'AccountInfo/GenerateNumber',
        body,
        super.getHeaders()
      )
      .pipe(
        tap((res) => {
          this._BaseService.ValidationResult(res);
        })
      );
  }
}
