import { Component } from '@angular/core';

@Component({
  selector: 'app-delete-steps',
  templateUrl: './delete-steps.component.html',
  styleUrls: ['./delete-steps.component.scss']
})
export class DeleteStepsComponent {

}
