<section class="verify-identity" fxLayout="column" fxLayoutAlign="start center">
  <form>
    <lib-language style="visibility: hidden"></lib-language>

    <img
      src="assets/img/back.jpg"
      alt=""
      title=""
      (click)="onSelectVerifyType(verifyMobileNumberType.SMS)"
      class="ptr"
    />
    <main>
      <h1>{{ "Verify_your_identity" | translate }}</h1>
      <p>{{ "Select_way_to_receive_the_verification_code" | translate }}</p>
    </main>

    <!-- <div
      class="type"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      (click)="onSelectVerifyType(verifyMobileNumberType.Email)"
    >
      <div fxLayout="row" fxLayoutAlign="start center">
        <img src="assets/img/email.jpg" alt="" title="" />
        <div class="desc">
          <h1>{{ "Email_Code_To" | translate }}</h1>
        </div>
      </div>

      <img src="assets/img/right-arrow.jpg" alt="" title="" />
    </div> -->

    <div
      class="type"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      (click)="onSelectVerifyType(verifyMobileNumberType.SMS)"
    >
      <div fxLayout="row" fxLayoutAlign="start center">
        <img src="assets/img/phone.jpg" alt="" title="" />
        <div class="desc">
          <h1>{{ "Phone_Code_To" | translate }}</h1>
        </div>
      </div>

      <img src="assets/img/right-arrow.jpg" alt="" title="" />
    </div>

    <div
      class="type"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      (click)="onSelectVerifyType(verifyMobileNumberType.Whatsapp)"
    >
      <div fxLayout="row" fxLayoutAlign="start center">
        <img src="assets/img/whatsapp.jpg" alt="" title="" />
        <div class="desc">
          <h1>{{ "Whatsapp_Code_To" | translate }}</h1>
        </div>
      </div>

      <img src="assets/img/right-arrow.jpg" alt="" title="" />
    </div>

    <a [routerLink]="['/']">{{ "Sign_in_to_different_account" | translate }}</a>
  </form>
</section>
