import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-week-days',
  templateUrl: './week-days.component.html',
  styleUrls: ['./week-days.component.scss']
})
export class WeekDaysComponent implements OnInit {

  constructor() { }
  days = ['1', '2', '3', '4', '5', '6', '7']
  ngOnInit(): void {
  }

}
