
import { InvoicePayableByTheClient } from "./invoice-payable-by-the-client";
import { TransactionPayPostpaidBill } from "./transaction-pay-post-paid-bill";

export class SetTotalPaidAmountForPostpaidBillsWithCashSaleModel {
  constructor() {

  }

  public TransactionPayPostpaidBills: TransactionPayPostpaidBill[];

  public TotalPaidAmountForPostpaidBillsWithCashSale: number;


  public SelectedInvoicesPayableByTheClient: InvoicePayableByTheClient[] = [];
  public SelectedInvoicesPayableByTheClientIds: number[];

}
