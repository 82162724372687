import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ClientBalanceStatus } from '../../models/enums/client-balance-status';
import { ResponseState } from '../../models/support/response-state';
import { ClientBalanceService } from '../../services/balances/client-balance.service';
import { SharedService } from '../../services/other/shared.service';
import { TransactionsSecondService } from '../../services/transaction/transactions-second.service';
import { PaginationComponent } from '../pagination/pagination.component';
import { GiftCardInformation } from 'src/app/models/classes/gift-cards/gift-card-information';
import { GiftCardInformationService } from 'src/app/services/gift-card/gift.card.information.service';
import { GetGiftCardUsingDetailsSearchCriteria } from 'src/app/models/search-criterias/getGiftCardUsingDetailsSearchCriteria';
import { GetGiftCardUsingDetailsSearchResult } from 'src/app/models/search-result/getGiftCardUsingDetailsSearchResult';
import { GiftCardInformationUsingDetail } from 'src/app/models/classes/gift-cards/gift-card-information-using-detail';
import { PeriodStatus } from 'src/app/models/enums/gym-membership-period-status';
import { GetGiftCardBranchesSearchCriteria } from 'src/app/models/search-criterias/getGiftCardBranchesSearchCriteria';
import { GetGiftCardBranchesSearchResult } from 'src/app/models/search-result/getGiftCardBranchesSearchResult';
import { GiftCardInformationBranch } from 'src/app/models/classes/gift-cards/giftCardInformationBranch';
import { GiftCardInformationSearchCriteria } from '../../models/search-criterias/gift-card-information-search-criteria';
import { ToastrService } from 'ngx-toastr';
import { GiftCardType } from '../../models/enums/gift-card-type';
import { Company } from '../../models/classes/company/company';
import { GetGiftCardOTPCodesSearchCriteria } from '../../models/search-criterias/getGiftCardOTPCodesSearchCriteria';
import { GetGiftCardOTPCodesSearchResult } from '../../models/search-result/getGiftCardOTPCodesSearchResult';
import { GiftCardInformationOTP } from '../../models/classes/gift-cards/gift-card-information-otp';
import { OTPStatus } from '../../models/enums/otp-status';
import { ClientBalanceTransactionDirection } from 'src/app/models/enums/client-balance-transaction-direction';

@Component({
  selector: 'app-modal-client-gift-card-details',
  templateUrl: './modal-client-gift-card-details.component.html',
  styleUrls: ['./modal-client-gift-card-details.component.scss'],
  providers: [DatePipe],
})
export class ModalClientGiftCardDetailsComponent implements OnInit {
  @ViewChild('pagerBranches', { static: true })
  pagerBranches: PaginationComponent;
  @ViewChild('pagerGiftCardUsingDetails', { static: true })
  pagerGiftCardUsingDetails: PaginationComponent;
  @ViewChild('pagerGiftCardOTPCodes', { static: true })
  pagerGiftCardOTPCodes: PaginationComponent;

  @Input() GiftCardInformationId: number;
  @Input() GiftCardCode: string;
  public GiftCardInformationUsingDetails: GiftCardInformationUsingDetail[];
  public GiftCardInformationOTPs: GiftCardInformationOTP[];
  public GiftCardInformationBranches: GiftCardInformationBranch[];
  public LoadingGetGiftCardUsingDetails: boolean = false;
  public LoadingGetGiftCardOTPCodes: boolean = false;
  public LoadingGetGiftCardBranches: boolean = false;
  public freeTextGetGiftCardBranches: string;
  public freeTextGetGiftCardUsingDetails: string;
  public freeTextGetGiftCardOTPCodes: string;
  public GiftCardInformation: GiftCardInformation;
  public Company: Company;

  constructor(
    public activeModal: NgbActiveModal,
    public translate: TranslateService,
    public sharedService: SharedService,
    public _transactionsSecondService: TransactionsSecondService,
    private fb: UntypedFormBuilder,
    public datepipe: DatePipe,
    private ngbModal: NgbModal,
    public toaster: ToastrService,
    public giftCardInformationService: GiftCardInformationService,
    public clientBalanceService: ClientBalanceService
  ) {}

  ngOnInit(): void {
    this.Company = this.sharedService.CurrentCompany;

    this.pagerBranches.pageSize = 10;
    this.pagerBranches.ddlPageSize = 10;
    this.pagerBranches.GlobalPageIndex = 0;

    this.pagerGiftCardUsingDetails.pageSize = 10;
    this.pagerGiftCardUsingDetails.ddlPageSize = 10;
    this.pagerGiftCardUsingDetails.GlobalPageIndex = 0;

    this.GetGiftCardInformation(this.GiftCardInformationId, this.GiftCardCode);
  }
  public get giftCardType(): typeof GiftCardType {
    return GiftCardType;
  }

  public get oTPStatus(): typeof OTPStatus {
    return OTPStatus;
  }

  public get clientBalanceTransactionDirection(): typeof ClientBalanceTransactionDirection {
    return ClientBalanceTransactionDirection;
  }

  GetGiftCardInformation(GiftCardInformationId: number, GiftCardCode: string) {
    var clientGiftCardInformationSearchCriteria =
      new GiftCardInformationSearchCriteria();
    clientGiftCardInformationSearchCriteria.Id = GiftCardInformationId;
    clientGiftCardInformationSearchCriteria.GiftCardCode = GiftCardCode;

    if (
      (!GiftCardInformationId || GiftCardInformationId <= 0) &&
      (!GiftCardCode || GiftCardCode.length <= 0)
    ) {
      clientGiftCardInformationSearchCriteria.Id = 0;
    }

    this.giftCardInformationService
      .searchGiftCardInformations(clientGiftCardInformationSearchCriteria)
      .subscribe((result) => {
        if (result.State == ResponseState.Success) {
          if (
            result.GiftCardInformations &&
            result.GiftCardInformations.length > 0
          ) {
            this.GiftCardInformation = result.GiftCardInformations[0];

            this.GetGiftCardUsingDetails();
            this.GetGiftCardBranches();
            this.GetGiftCardOTPCodes();
          } else {
            this.ngbModal.dismissAll();
            this.toaster.error(
              this.translate.instant('Sorry!GiftCardNotFound!')
            );
          }
        }
      });
  }

  divName;
  printPage() {
    this.divName = 'printClientGiftCardInfo';
    console.log(this.divName);

    let printContents = document.getElementById(this.divName).innerHTML;
    let originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
    // this.divName = null;
  }

  public get clientBalanceStatus(): typeof ClientBalanceStatus {
    return ClientBalanceStatus;
  }

  public get periodStatus(): typeof PeriodStatus {
    return PeriodStatus;
  }

  GetGiftCardUsingDetails() {
    this.LoadingGetGiftCardUsingDetails = true;
    var criteria = new GetGiftCardUsingDetailsSearchCriteria();

    criteria.FreeText = this.freeTextGetGiftCardUsingDetails;
    criteria.AccountSetupId = this.GiftCardInformation.BranchId;
    criteria.GiftCardInformationId = this.GiftCardInformation.Id;
    criteria.PageSize = this.pagerGiftCardUsingDetails.pageSize;
    criteria.PagingEnabled = true;
    criteria.PageNumber = this.pagerGiftCardUsingDetails.GlobalPageIndex;

    this.giftCardInformationService
      .GetGiftCardUsingDetails(criteria)
      .subscribe((response: GetGiftCardUsingDetailsSearchResult) => {
        this.LoadingGetGiftCardUsingDetails = false;
        if (response.State == ResponseState.Success) {
          this.GiftCardInformationUsingDetails =
            response.GiftCardInformationUsingDetails ?? [];
          this.pagerGiftCardUsingDetails.EventsCount = response.TotalCount;
          this.pagerGiftCardUsingDetails.setPage(
            this.pagerGiftCardUsingDetails.GlobalPageIndex,
            false
          );
        }
      });
  }

  GetGiftCardOTPCodes() {
    this.LoadingGetGiftCardOTPCodes = true;
    var criteria = new GetGiftCardOTPCodesSearchCriteria();

    criteria.FreeText = this.freeTextGetGiftCardOTPCodes;
    criteria.AccountSetupId = this.GiftCardInformation.BranchId;
    criteria.GiftCardInformationId = this.GiftCardInformation.Id;
    criteria.PageSize = this.pagerGiftCardOTPCodes.pageSize;
    criteria.PagingEnabled = true;
    criteria.PageNumber = this.pagerGiftCardOTPCodes.GlobalPageIndex;

    this.giftCardInformationService
      .GetGiftCardOTPCodes(criteria)
      .subscribe((response: GetGiftCardOTPCodesSearchResult) => {
        this.LoadingGetGiftCardOTPCodes = false;
        if (response.State == ResponseState.Success) {
          this.GiftCardInformationOTPs = response.GiftCardInformationOTPs ?? [];
          this.pagerGiftCardOTPCodes.EventsCount = response.TotalCount;
          this.pagerGiftCardOTPCodes.setPage(
            this.pagerGiftCardOTPCodes.GlobalPageIndex,
            false
          );
        }
      });
  }

  GetGiftCardBranches() {
    this.LoadingGetGiftCardBranches = true;
    var criteria = new GetGiftCardBranchesSearchCriteria();

    criteria.FreeText = this.freeTextGetGiftCardBranches;
    criteria.AccountSetupId = this.GiftCardInformation.BranchId;
    criteria.GiftCardInformationId = this.GiftCardInformation.Id;
    criteria.PageSize = this.pagerBranches.pageSize;
    criteria.PagingEnabled = true;
    criteria.PageNumber = this.pagerBranches.GlobalPageIndex;

    this.giftCardInformationService
      .GetGiftCardBranches(criteria)
      .subscribe((response: GetGiftCardBranchesSearchResult) => {
        this.LoadingGetGiftCardBranches = false;
        if (response.State == ResponseState.Success) {
          this.GiftCardInformationBranches =
            response.GiftCardInformationBranches;
          this.pagerBranches.EventsCount = response.TotalCount;
          this.pagerBranches.setPage(this.pagerBranches.GlobalPageIndex, false);
        }
      });
  }

  ReciveFreeTextGetGiftCardUsingDetails(e) {
    console.log(e);
    this.freeTextGetGiftCardUsingDetails = e;
    this.GetGiftCardUsingDetails();
  }

  ReciveFreeTextGiftCardOTPCodes(e) {
    console.log(e);
    this.freeTextGetGiftCardOTPCodes = e;
    this.GetGiftCardOTPCodes();
  }

  ReciveFreeTextGetGiftCardBranches(e) {
    console.log(e);
    this.freeTextGetGiftCardBranches = e;
    this.GetGiftCardBranches();
  }
}
