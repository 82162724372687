export enum ResponseState {
  Success = 100,
  Error = 101,
  ValidationError = 102,
  NotFound = 103,
  AccessDenied = 104,
  AuthenticationError = 105,
  ProcessError = 106,
  InvalidInput = 107,
  NoData = 108,
  NotRegisteredUser = 109,
  ActionNotAllowed = 110,
  AppInDevelopment = 111,
  ExternalError = 112,
  RegisteredBefore = 113,
  MustCompleteRegistration = 114,
  NoWorkingHoursValidationError = 115,
  NoOpenShiftForCurrentCashierValidationError = 116,
  NoOpenDayForCurrentBranchValidationError = 117,
  VerificationCodeIsExpired = 118,
  OldRegistrationCycleLog = 119,
  BookingOverLap = 120,
  CartProviderOverLap = 121,
  UserNotFound = 122,
  NoChangesInData = 123,
  OTPError = 150
}
