<div style="
    text-align: start;
    margin: 10px 5px;
    background-color: #fff;
    page-break-after: always;" *ngFor="let bookingItem of booking.BookingItems; let e = index" [dir]="dirc"
  [ngStyle]="{'page-break-after': e != booking.BookingItems.length - 1 ? 'always' : ''}">

  <div style="">

    <div class="row">
      <div class="col-lg-12 " style="text-align: center;">
        <div style="text-align: center;width: 100%;" *ngIf="AccountSetup?.ImagePath">
          <img [src]="AccountSetup?.ImagePath" style="width: 100px;text-align: center;" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 " style="text-align: center;">
        <p class="hed" style="
        margin: 0;
        padding: 0;
        width: 100%;
        text-align: center;
        color: #000;
        font-weight: 600;
        font-size: 16px; ">
          {{ sharedService.Lang == 'ar' ? sharedService.BranchNameAr : sharedService.BranchNameEn }} &nbsp; {{
          printLang }}
        </p>
      </div>
    </div>

    <hr />

    <table style="width: 100%" [dir]="dirc">

      <!-- <tr class="row">
        <td class="col-12 text-start">
          <div style="text-align: center" *ngIf="AccountSetup?.ImagePath">
            <img [src]="AccountSetup?.ImagePath" style="width: 60px" />
          </div>
          <p class="hed" style="
    margin: 0;
    padding: 0;
    text-align: center;
    color: #000;
    font-weight: 600;
    font-size: 16px; ">
            {{ sharedService.BranchNameAr
            }} &nbsp; {{ printLang }}
          </p>
        </td>
      </tr> -->

      <tr class="row">
        <td class="col-6 text-start">
          <p style="
              font-family: initial;
              font-size: 18px;
              margin: 0;
              font-weight: 600;
              color: #000;
            ">
            {{ booking.Date | date: "dd/MM/yyyy" }}
          </p>

          <p style="
          font-family: initial;
          font-size: 18px;
          margin: 0;
          font-weight: 600;
          color: #000;
        ">
            {{ booking.Date | date: "hh:mm a" }}
          </p>

          <!--  <p *ngIf="booking.BookingDate" style="
              font-family: initial;
              font-size: 18px;
              margin: 0;
              font-weight: 600;
              color: #000;
            ">
            {{ booking.BookingDate | date: "dd/MM/yyyy hh:mm a" }}
          </p> -->

          <!-- <p style="font-size: 14px; font-weight: 600; margin: 5px 0px" *ngIf="
          sharedService.CurrentBranch
            .AllowUseNumberOfPrintsInBills &&
          sharedService.CurrentBranch.AllowShowNumberOfPrintsInBills
        ">
            <span>{{ "PrintNumber:" | translate }}</span>
            {{ (booking.ItemsNumberOfPrints ?? 0) + 1 }} .
          </p> -->

          <!--  <p class="hed" *ngIf="booking.BookingId"
            style="margin: 5px 0  0 0; padding: 0;color: #430fd4; text-align: center; font-weight: 600; font-size: 16px;">
            {{'ForBookingPayment' | translate }}
          </p> -->

        </td>

        <td class="col-6 text-center">


          <p style="
          font-family: initial;
          font-size: 18px;
          margin: 0;
          font-weight: 600;
          color: #000;
        ">
            {{ booking.Id }}
          </p>
          <!--
          <p class="hed" *ngIf="booking.TransactionType == 2 ||
          booking.TransactionType == 1 " style="
              margin: 0;
              padding: 0;
              text-align: center;
              color: #000;
              font-weight: 600;
              font-size: 16px;
            ">
           {{booking.TransactionCodeText}}
          </p> -->


        </td>

      </tr>
    </table>
    <div>

      <div class="row">
        <div class="col-lg-12 " style="text-align: center;">
          <h2 style="color:#000; font-weight:900;">
            {{ ('PrintItems'|translate) }}
            <span style="color:#464141; font-weight:900;font-size: 14px;">({{
              ('BookingItems'|translate) }})</span>
          </h2>
        </div>
        <div class="col-lg-12 " style="text-align: center;">
          <h4 style=" color:#000; font-weight:900;">
            {{ ('ItemName'|translate) + " - " + getItemName(bookingItem) }}
          </h4>
        </div>
      </div>

      <hr />

      <!-- [dir]="printLang  == 'ar' ? 'rtl' : 'ltr'" -->
      <table style="width: 100%; text-align: start" [dir]="dirc">
        <tr>
          <td style="
              font-family: initial;
              font-size: 20px;
              font-weight: 500;
              width: 360px;
              color: #000;
            ">
            <p style="font-size: 16px; font-weight: 600; margin: 0px">
              {{ "ClientName" | translate }} :
              {{ booking.ClientName }}
            </p>

            <p style="font-size: 16px; font-weight: 600; margin: 0px">
              {{ "UserName" | translate }} :
              {{booking.AddUserName}}
            </p>
            <p style="font-size: 16px; font-weight: 600; margin: 0px">
              {{ "ItemDateAndTime" | translate }} :
              {{ (bookingItem.Date | date: "dd/MM/yyyy hh:mm") + " " + ( (bookingItem.Date | date: "a") | translate)}}
            </p>
          </td>
          <td style="text-align: end">
            <div style="text-align: end" *ngIf="AccountSetup?.QRImagePath">
              <img [src]="AccountSetup?.QRImagePath" alt="" style="width: 80px" />
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div>
      <div *ngIf="booking.BookingItems.length > 0">
        <table border="1" style="
            width: 100%;
            border-collapse: collapse;
            text-align: start !important;
            margin: 15px 0;
            border: 2px solid #000;
          " [dir]="dirc">
          <thead style="text-align: start !important" [dir]="dirc">
            <tr style="text-align: start !important" [dir]="dirc">
              <th style="text-align: start !important; color: #000; padding: 5px">
                {{ "Name" | translate }}
              </th>
              <th style="text-align: start !important; color: #000; padding: 5px">
                {{ "Employee" | translate }}
              </th>
              <th style="text-align: start !important; color: #000; padding: 5px">
                {{ "Quantity" | translate }}
              </th>
              <th style="text-align: start !important; color: #000; padding: 5px">
                {{ "Price" | translate }}
              </th>
              <!-- <th style="text-align: start !important; color: #000; padding:5px;">{{ 'Commission' | translate }}
              </th> -->
              <th style="text-align: start !important; color: #000; padding: 5px">
                {{ "Total" | translate }}
              </th>
              <!--  <th *ngIf="sharedService?.CurrentBranch?.AllowTracingCommissionInCashSale == true &&
               this.hasNonNullEmployeeBookingCommission(booking)"
                style="text-align: start !important; color: #000; padding: 5px">
                {{ "Commission" | translate }}
              </th> -->

              <!--  <th *ngIf="sharedService?.CurrentBranch?.AllowUseClientsQueueSystem &&
              sharedService?.CurrentBranch?.AllowShowClientsQueueNumberInPrintEmployees &&
              sharedService?.CurrentBranch?.ClientsQueueSystemType == 2"
                style="text-align: start !important; color: #000; padding: 5px">
                {{ "QueueNumber" | translate }}
              </th> -->

            </tr>
          </thead>
          <tbody>
            <tr style="text-align: start">
              <td style="color: #000; padding: 5px">
                {{bookingItem.ItemName}}
                <div *ngIf="AccountSetup?.AllowUseRooms == true">
                  <span *ngFor="
                      let room of bookingItem.ResourceManagements
                    ">
                    {{ room.ResourceName }} <br />
                    {{ "From" | translate }}:
                    {{ room.StartTime | date: "shortTime" }} ,
                    {{ "To" | translate }}:
                    {{ room.EndTime | date: "shortTime" }}
                  </span>
                </div>
              </td>
              <td style="color: #000; padding: 5px">


                {{ bookingItem.EmployeeName ? bookingItem.EmployeeName: ('NotFound'|translate)}}


                {{ bookingItem.SecondEmployeeName ? " - " +
                bookingItem.SecondEmployeeName : "" }}
              </td>
              <td style="color: #000; padding: 5px">
                {{ bookingItem.Quantity }}
              </td>
              <td style="color: #000; padding: 5px">
                <span *ngIf="sharedService?.CurrentBranch?.AllowShowItemsPricesInPrintEmployees">
                  {{ bookingItem?.Price?.toFixed(sharedService.GetFractionRoundCount) }}
                </span>
                <span *ngIf="!sharedService?.CurrentBranch?.AllowShowItemsPricesInPrintEmployees">
                  {{ "NotAllowed" | translate }}
                </span>
              </td>
              <!-- <td style="color: #000; padding:5px;">
                {{TrProductService?.EmployeeTransactionCommission[0]?.CommissionValue }} </td> -->
              <td style="color: #000; padding: 5px">
                <span *ngIf="sharedService?.CurrentBranch?.AllowShowItemsPricesInPrintEmployees">
                  {{ bookingItem?.Total?.toFixed(sharedService.GetFractionRoundCount) }}
                </span>
                <span *ngIf="!sharedService?.CurrentBranch?.AllowShowItemsPricesInPrintEmployees">
                  {{ "NotAllowed" | translate }}
                </span>
              </td>
              <!-- <td *ngIf="AccountSetup?.AllowTracingCommissionInCashSale == true
               && bookingItem?.EmployeeTransactionCommission != null
               && bookingItem.EmployeeTransactionCommission?.CommissionValue > 0" style="color: #000; padding: 5px">

                <span *ngIf="bookingItem.EmployeeTransactionCommission?.OverTimeCommissionId != null">
                  {{ "ServiceFeeOvertime" | translate }}
                </span>
                <span>
                  {{
                  bookingItem.EmployeeTransactionCommission?.CommissionValue.toFixed(sharedService.GetFractionRoundCount)
                  }}
                </span>
              </td> -->

              <!--  <td *ngIf="sharedService?.CurrentBranch?.AllowUseClientsQueueSystem &&
              sharedService?.CurrentBranch?.AllowShowClientsQueueNumberInPrintEmployees &&
              sharedService?.CurrentBranch?.ClientsQueueSystemType == 2" style="color: #000; padding: 5px">
                {{ bookingItem.ClientNumberInQueue }}
              </td> -->
            </tr>

            <!--  <tr *ngIf="AccountSetup?.AllowTracingCommissionInCashSale == true
                 && bookingItem?.EmployeeTransactionCommission != null
                 && bookingItem?.EmployeeTransactionCommission?.HrCommissionSettingDetailId == null
                 && bookingItem?.EmployeeTransactionCommission?.TargetCommissionValue != 0
                 && bookingItem.ItemId === bookingItem.ItemId">
              <td colspan="6">
                <div style="display: flex; justify-content: space-between">

                  <p style="padding: 0px; margin: 0px; font-size: 12px">
                    {{ "Target" | translate }} :

                    {{ bookingItem?.EmployeeTransactionCommission?.TargetCommissionValue }}
                    {{ bookingItem?.EmployeeTransactionCommission?.IsCommissionTypeCount == false ?
                    (sharedService.Lang == 'ar' ? (sharedService.AccountCurrency?.NameAr | translate) :
                    (sharedService.AccountCurrency?.NameEn | translate)) : ('Servicess' | translate) }}:


                  </p>

                </div>
                <div style="display: flex; justify-content: space-between">
                  <p style="padding: 0px; margin: 0px; font-size: 12px">
                    {{ "TotalInvestigator" | translate }} :

                    {{ bookingItem?.EmployeeTransactionCommission?.IsCommissionTypeCount == false ?
                    ( bookingItem?.EmployeeTransactionCommission?.TotalAchievedServicePrice) :
                    ( bookingItem?.EmployeeTransactionCommission?.TotalInvoicesCount)}}
                  </p>
                  <p style="padding: 0px; margin: 0px; font-size: 12px" *ngIf="
                      bookingItem?.EmployeeTransactionCommission?.IsTargetAccomplished == true">
                    {{ "TargetAchieved" | translate }}
                  </p>
                  <p style="padding: 0px; margin: 0px; font-size: 12px" *ngIf="
                      bookingItem?.EmployeeTransactionCommission?.IsTargetAccomplished == false">
                    لم يتم تحقيق التارجت حتى الأن
                  </p>
                </div>
                <div style="display: flex; justify-content: space-between">
                  <p style="padding: 0px; margin: 0px; font-size: 12px">
                    الايام المتبقية لتحقيق التارجت :
                    {{ bookingItem?.EmployeeTransactionCommission?.RemainingTargetDays }}
                  </p>
                </div>

              </td>

            </tr> -->

            <!--   <tr *ngIf="AccountSetup?.AllowTracingCommissionInCashSale == true
            && bookingItem?.EmployeeTransactionCommission == null
            && bookingItem.ItemId === bookingItem.ItemId">

              <td colspan="6">
                <div style="display: flex; justify-content: space-between">
                  <p style="padding: 0px; margin: 0px; font-size: 12px">
                    {{ "NoCommissionCalculated" | translate }}
                  </p>
                </div>
              </td>
            </tr> -->
          </tbody>
        </table>
      </div>
      <p style="
          text-align: start;
          font-size: 14px;
          font-weight: 500;
          color: #000;
        ">
        {{ booking.Comment }}
      </p>

      <!--  <div style="display: flex; align-bookings: center; justify-content: center;">
        <span *ngIf=" booking.ItemNumber > 0" style="margin-right:30px;">

          <p style="text-align: center; font-weight: 700; color: #000;
              font-family: initial;font-size: 30px; font-weight: 700;margin: 15; background-color: #373333  !important;
              color: white;padding: 5px; border-radius: 5px; -webkit-print-color-adjust: exact !important;">
            <span>{{ booking?.ItemNumber }}</span>
          </p>
        </span>
      </div> -->

      <!--  <div style="display: flex; align-bookings: center; justify-content: center;">
        <span *ngIf="sharedService?.CurrentBranch?.AllowUseClientsQueueSystem &&
        sharedService?.CurrentBranch?.AllowShowClientsQueueNumberInPrintEmployees &&
        sharedService?.CurrentBranch?.ClientsQueueSystemType == 2" style="margin-right:30px;">


          <p *ngIf="getCategoryNameName(bookingItem)" style="text-align: center; font-weight: 700; color: #000;
                        font-family: initial; font-size: 20px; background-color: #373333  !important;
                        color: white; border-radius: 5px; -webkit-print-color-adjust: exact !important;">
            <span>{{ getCategoryNameName(bookingItem) }}</span>
          </p>

          <p style="text-align: center; font-weight: 700; color: #000;
              font-family: initial;font-size: 30px; background-color: #373333  !important;
              color: white; border-radius: 5px; -webkit-print-color-adjust: exact !important;">
            <span>{{ bookingItem.ClientNumberInQueue }}</span>
          </p>
        </span>
      </div>
 -->

      <!--   <span *ngIf="sharedService.CurrentBranch.AllowChangeBillItemsEmployeesByQRCode">

        <div class="row">
          <div class="col-lg-12 " style="text-align: center;">
            <h3 style=" color:#000; font-weight:900;">
              {{ ('ScanToChangeItemEmployeeToYourself'|translate )}}
            </h3>
          </div>
          <div class="col-lg-12 " style="text-align: center;">
            <h4 style=" color:#000; font-weight:900;">
              {{ 'ItemName'|translate }} :

              {{(bookingItem.Note == "PackageService" ? (('PackageService'|translate) + " - ") : "") +
              (bookingItem.service
              ? printLang == "ar"
              ? bookingItem.service.NameAr
              : bookingItem.service.NameEn
              : bookingItem.Service
              ? printLang == "ar"
              ? bookingItem.Service.NameAr
              : bookingItem.Service.NameEn
              : bookingItem.product
              ? printLang == "ar"
              ? bookingItem.product.NameAr
              : bookingItem.product.NameEn
              : bookingItem.Package
              ? printLang == "ar"
              ? bookingItem.Package.NameAr
              : bookingItem.Package.NameEn
              : bookingItem.OffersSetting
              ? printLang == "ar"
              ? bookingItem.OffersSetting.NameAr
              : bookingItem.OffersSetting.NameEn
              : "")
              }}
            </h4>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12 " style="text-align: center;">

            <div style="display: flex; align-bookings: center; justify-content: center;">
              <div style="text-align: center;">
                <ngx-qrcode  [width]="250" [elementType]="elementType" [errorCorrectionLevel]="correctionLevel" [value]="(bookingItem.Note == 'PackageService' ?
                   BillInfoForPackageUrl : BillInfoUrl) + bookingItem.Id" alt="Demo QR Code"
                  cssClass="bshadow"></ngx-qrcode>
              </div>
            </div>

          </div>
        </div>
      </span>
 -->
    </div>
  </div>
</div>
