<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel"> <i class="fas fa-edit"></i> &nbsp;
      {{'EditItemEmployee' | translate}}
    </h5>
    <button type="button" class="close" (click)="activeModal.close('Close click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-card">

      <div class="row">
        <div class="col-lg-12">
          <div class="formCard">
            <span style="font-size:20px;color:black;font-weight:900;">
              {{'AreYouSureToChangeItemEmployeeToYourself' | translate}}
            </span>

          </div>
        </div>
        <div class="col-lg-12">
          <div class="formCard">
            <table class="table table-sm">
              <tr>
                <td>{{ "ItemName" | translate }}</td>
                <td>
                  {{ItemName}}
                </td>
              </tr>
              <tr>
                <td>{{ "CurrentEmployeeName" | translate }}</td>
                <td>
                  {{EmployeeName}}
                </td>
              </tr>
            </table>
          </div>

        </div>

      </div>

      <div class="row">
        <div class="col-lg-6">
          <button class="btn btn-modal-sure" type="button" (click)="EditBillItemEmployee()">
            <span *ngIf="!btnLoading"> {{ 'YesIamSure' | translate }}</span>
            <span *ngIf="btnLoading"> <i class="fas fa-circle-notch fa-spin"></i> {{ 'Loading' | translate
              }}</span>
          </button>
        </div>
        <div class="col-lg-6">
          <button class="btn btn-modal-close" (click)="activeModal.close('Close click')">{{'Close' | translate
            }}</button>
        </div>
      </div>


    </div>
  </div>
</div>
