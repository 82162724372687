<div class="modal-header">إدارة الحسابات</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-12">
      <div class="btnsTab">
        <button
          *ngIf="accountName === 'المبيعات'"
          type="button"
          [ngClass]="{ active: activeType === 'serviceCategories' }"
          class="btn active"
          (click)="onGetData('serviceCategories')"
        >
          {{ "ServiceCategories" | translate }}
        </button>
        <button
          type="button"
          class="btn"
          [ngClass]="{ active: activeType === 'productCategory' }"
          (click)="onGetData('productCategory')"
        >
          {{ "ProductCategories" | translate }}
        </button>
      </div>
      <ng-template #loading>
        <div class="col-md-12 text-center py-4 my-4">
          <i class="fas fa-circle-notch fa-spin fa-3x"></i>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="row">
    <app-service-categories
      [asModal]="true"
      [modeType]="type"
      *ngIf="activeType === 'serviceCategories'"
      [editAccounts]="type ? debitAccounts : creditAccounts"
      (changeAccount)="onChangeAccount($event)"
      (removeAccount)="onRemoveAccount($event)"
    ></app-service-categories>
    <app-productcategories
      [asModal]="true"
      [modeType]="type"
      [editAccounts]="type ? debitAccounts : creditAccounts"
      *ngIf="activeType === 'productCategory'"
      (changeAccount)="onChangeAccount($event)"
      (removeAccount)="onRemoveAccount($event)"
    ></app-productcategories>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-modal-action" (click)="onCloseModal()">
    {{ "Save" | translate }}
  </button>
  <button type="button" class="btn btn-modal-action" (click)="onClose()">
    Close
  </button>
</div>
