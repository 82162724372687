
export enum Pages {
  home = 1,
  about = 2,
  blog = 3,
  contact = 4,
  features = 5,
  login = 6,
  register = 7,
  bookingCalendar = 8,
  bookingAdd = 9,
  bookingView = 10,
  clients = 11,
  clientsBlackList = 12,
  clientsBlackListingReason = 13,
  name = 99,

}
