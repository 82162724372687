import { BaseService, BaseServiceSZ } from '../other/baseService';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Unit } from '../../models/classes/product/unit';
import { UnitSearchCriteria } from '../../models/search-criterias/unit-search-criteria';
import { UnitSearchResult } from '../../models/search-result/unit-search-result';
import { ExecutionResponse } from '../../models/support/execution-response';
import { ValidationCriteria } from '../../models/support/validation-criterias/validation-criteria';

@Injectable({
  providedIn: 'root'
})
export class UnitService extends BaseService {
  private unitsUrl //= super.BaseUrl() + 'unit';  // URL to web api

  constructor(
    private http: HttpClient,
    private  _BaseService:BaseServiceSZ
  ) {
    super();
    this.unitsUrl =    this._BaseService.ApiUrl + 'unit';

  }


  getUnit(id: String): Observable<ExecutionResponse<Unit>> {
    return this.http.post<ExecutionResponse<Unit>>(this.unitsUrl + '/getById', id, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched unit id=${id}');
          this._BaseService.ValidationResult(res);
      }),
        
        catchError(this._BaseService.handleError<ExecutionResponse<Unit>>('getunit id=${id}'))
      );
  }

  
  addUnit(unit: Unit): Observable<ExecutionResponse<Unit>> {
    unit.AccountSetupId = super.getAccountSetupId();
    return this.http.post<ExecutionResponse<Unit>>(this.unitsUrl + '/Create', unit, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('added unit w/ id=${unit.Id}');
          this._BaseService.ValidationResult(res);
      }),
       
        catchError(this._BaseService.handleError<ExecutionResponse<Unit>>('addunit id=${id}'))
      );
  }

  searchUnits(unitSearchCriteria: UnitSearchCriteria): Observable<UnitSearchResult> {
    unitSearchCriteria.AccountSetupId = super.getAccountSetupId();
    return this.http.post<UnitSearchResult>(this.unitsUrl + '/Get', unitSearchCriteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched units');
          this._BaseService.ValidationResult(res);
      }),
        catchError(this._BaseService.handleError<UnitSearchResult>('searchUnits id=${id}'))
      );
  }

  deleteUnit(unit: Unit | number): Observable<ExecutionResponse<Unit>> {
    return this.http.post<ExecutionResponse<Unit>>(this.unitsUrl + '/delete', unit, super.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deleted unit id=${id}');
        this._BaseService.ValidationResult(res);
    }),
     
      catchError(this._BaseService.handleError<ExecutionResponse<Unit>>('deleteUnit'))
    );
  }

  updateUnit(unit: Unit): Observable<ExecutionResponse<Unit>> {
    unit.AccountSetupId = super.getAccountSetupId();
    return this.http.post<ExecutionResponse<Unit>>(this.unitsUrl + '/Update', unit, super.getHeaders())
      .pipe(
        tap(res=> {
          this._BaseService.log('added unit w/ id=${unit.Id}');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<ExecutionResponse<Unit>>('addUnit id=${id}'))
      );
  }

  IsNameUnique(validationCriteria: ValidationCriteria): Observable<ExecutionResponse<Boolean>> {
    return this.http.post<ExecutionResponse<Boolean>>(this.unitsUrl + '/IsNameUnique', validationCriteria, super.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deleted stockmaindata id=${id}');
        this._BaseService.ValidationResult(res);
    }),
      
      catchError(this._BaseService.handleError<ExecutionResponse<Boolean>>('deleteStock'))
    );
  }
}
