import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ComponentBase,
  FORGET_PASSWORD_INPUT,
  VERFICATION_RESET_INPUT,
  VERIFY_SIGNUP_INPUT,
  VerifyMobileNumberType,
  SharedService,
  AutoRegistrationService,
  BusinessDetailsService,
} from 'core';
import { Config as NgOtpInputConfig } from 'ng-otp-input/lib/models/config';
import { ToastrService } from 'ngx-toastr';
import { Observable,interval } from 'rxjs';
import { AutoRegistrationConfig } from '../../models/auto-registration-config';
import { CONFIG } from '../../registration.module';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { map, take, takeUntil, tap } from 'rxjs/operators';
import { SalesChatService } from 'projects/core/src/lib/services/sales-chat.service';

@Component({
  selector: 'app-verfication',
  templateUrl: './verfication.component.html',
  styleUrls: ['./verfication.component.scss'],
})
export class VerficationComponent extends ComponentBase implements OnInit {
  public registeredMobileNumber: string;
  openResendCode: boolean = false;
  resendCodeData: FORGET_PASSWORD_INPUT = {
    UserName: undefined,
    VerifyMobileNumberType: undefined,
  };

  verifyResetData: VERFICATION_RESET_INPUT = {
    UserName: undefined,
    Code: undefined,
  };

  verifySignUpData: VERIFY_SIGNUP_INPUT = {
    RegistrationCycleLogId: undefined,
    VerificationCode: undefined,
  };

  otpInputConfig: NgOtpInputConfig = {
    length: 4,
  };
  count$!: Observable<number>;
  verifyMobileNumberType = VerifyMobileNumberType;
  requestType: string = '';
  isAutoResend: boolean = false;

  constructor(
    private salesChatService: SalesChatService,
    private readonly autoRegistrationService: AutoRegistrationService,
    private readonly sharedService: SharedService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly toastr: ToastrService,
    @Inject(CONFIG) public config: AutoRegistrationConfig,
    public readonly translateService: TranslateService,
    readonly businessDetailsService: BusinessDetailsService,
    readonly title: Title
  ) {
    super();

    businessDetailsService
      .getRegisteredPhases({
        RegistrationCycleLogId: +this.sharedService.getLocalStorage(
          'RegistrationCycleLogId'
        ),
      })
      .pipe(
        map((response) => response?.Result?.One),
        takeUntil(this.destroy$)
      )
      .subscribe((response) => {
        if (response?.OneData?.MobileNumber) {
          this.registeredMobileNumber = response?.OneData?.MobileNumber;
        }
      });

    translateService.onLangChange.subscribe((_) => {
      title.setTitle(translateService.instant('Title_verfication_phone'));
    });
    title.setTitle(translateService.instant('Title_verfication_phone'));
  }

  ngOnInit(): void {
    this.salesChatService.chatScript();
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        if (params['RegistrationCycleLogId']) {
          this.verifySignUpData = {
            RegistrationCycleLogId: params['RegistrationCycleLogId'],
          };
        }

        if (params['user']) {
          this.verifyResetData.UserName = params['user'];
          this.resendCodeData.UserName = params['user'];
        }

        if (params['requestType']) {
          this.requestType = params['requestType'];
        }

        if (
          params['isAutoResend'] !== null &&
          params['isAutoResend'] !== undefined
        ) {
          this.isAutoResend = params['isAutoResend'];
        }

        this.resendCodeData = {
          ...this.resendCodeData,
          VerifyMobileNumberType: params['verifyType']
            ? params['verifyType']
            : this.verifyMobileNumberType.SMS,
        };

        if (this.isAutoResend) {
          this.onResendCode();
        }
      });

    this.countDown();
  }

  onFill(otpCode: string): void {
    if (otpCode.length === 4) {
      if (this.verifySignUpData.RegistrationCycleLogId) {
        this.verifySignUpData = {
          ...this.verifySignUpData,
          VerificationCode: otpCode,
        };

        this.autoRegistrationService
          .verificationPhaseOne(this.verifySignUpData)
          .pipe(takeUntil(this.destroy$))
          .subscribe((res) => {
            this.checkResponse = this.sharedService.checkResposeStatus(
              res.Message,
              res.State
            );

            if (this.checkResponse) {
              this.router.navigate(['../choose-business'], {
                skipLocationChange: true,
                queryParams: {
                  RegistrationCycleLogId:
                    this.verifySignUpData.RegistrationCycleLogId,
                },
              });
            }
          });
      } else {
        this.verifyResetData = {
          ...this.verifyResetData,
          Code: otpCode,
        };
        this.autoRegistrationService
          .verification(this.verifyResetData)
          .pipe(takeUntil(this.destroy$))
          .subscribe((res) => {
            this.checkResponse = this.sharedService.checkResposeStatus(
              res.Message,
              res.State
            );

            if (this.checkResponse) {
              this.router.navigate(['../update-password'], {
                queryParams: { data: JSON.stringify(this.verifyResetData) },
              });
            }
          });
      }
    }
  }

  private countDown(): void {
    const initialCount = 59;

    this.count$ = interval(1000).pipe(
      takeUntil(this.destroy$),
      take(initialCount + 1),
      map((count) => initialCount - count),
      tap((count) => {
        if (count === 0) {
          this.openResendCode = true;
        }
      })
    );
  }

  onResendCode(): void {
    if (this.requestType === 'auto') {
      if (
        this.resendCodeData.VerifyMobileNumberType &&
        this.verifySignUpData.RegistrationCycleLogId
      ) {
        this.openResendCode = false;
        this.countDown();
        this.autoRegistrationService
          .resendCode({
            VerifyMobileNumberType: this.resendCodeData.VerifyMobileNumberType,
            RegistrationCycleLogId:
              this.verifySignUpData.RegistrationCycleLogId,
          })
          .pipe(takeUntil(this.destroy$))
          .subscribe((res) => {
            this.sharedService.checkResposeStatus(res.Message, res.State);
          });
      }
    }

    if (this.requestType === 'normal') {
      if (
        this.resendCodeData.UserName &&
        this.resendCodeData.VerifyMobileNumberType
      ) {
        this.autoRegistrationService
          .forgetPassword(this.resendCodeData)
          .pipe(takeUntil(this.destroy$))
          .subscribe((res) => {
            this.sharedService.checkResposeStatus(res.Message, res.State);
          });
      } else {
        this.toastr.error('Error Occcurs , please try on another time !');
      }
    }
  }

  onGetVerifiyIdentity(): void {
    this.router.navigate(['verify-identity'], {
      skipLocationChange: true,
      queryParams: {
        user: this.verifyResetData.UserName,
        RegistrationCycleLogId: this.verifySignUpData.RegistrationCycleLogId,
        requestType: this.requestType,
        verifyMobileNumberType: this.resendCodeData.VerifyMobileNumberType,
      },
    });
  }

  public backToPhaseOne(): void {
    this.router.navigate(['', 'sign-up'], {
      skipLocationChange: true,
      queryParams: {
        getRegisterData: true,
      },
    });
  }
}
