import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { ClientBalanceStatus } from '../../models/enums/client-balance-status';
import { ResponseState } from '../../models/support/response-state';
import { SharedService } from '../../services/other/shared.service';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from 'src/app/services/client/client.service';
import { GymClientMembershipFrozenDaysLog } from 'src/app/models/classes/gym/gym-client-membership-frozen-days-log';
import { WeekDays } from '../../models/enums/week-days';
import { AlertMethod } from '../../models/enums/alert-method';
import { AlertStatus } from '../../models/enums/alert-status';
import { GymActivityType } from '../../models/enums/gym-activity-type';
import { PeriodStatus } from 'src/app/models/enums/gym-membership-period-status';
import { CancelationWay } from 'src/app/models/enums/cancelation-way';
import { GymMembershipClientStatus } from 'src/app/models/enums/gym-membership-client-status';
import { GymClientMembershipFrozenDaysLogServiceService } from 'src/app/services/gym/gym-client-membership-frozen-days-log-service.service';
import { GymClientMembershipFrozenDaysLogsSearchCriteria } from 'src/app/models/search-criterias/gym-client-membership-frozen-days-log-search-criteria';

@Component({
  selector: 'app-modal-gym-membership-freez-info',
  templateUrl: './modal-gym-membership-freez-info.component.html',
  styleUrls: ['./modal-gym-membership-freez-info.component.scss'],
  providers: [DatePipe],
})
export class ModalGymMembershipFreezInfo implements OnInit {
  @Input() GymClientMembershipFreezId: number;
  public LoadingGetGymClientMembershipFreez: boolean;
  public gymClientMembershipFrozenDaysLog: GymClientMembershipFrozenDaysLog;

  constructor(
    public activeModal: NgbActiveModal,
    public translate: TranslateService,
    public clientService: ClientService,
    public sharedService: SharedService,
    private gymClientMembershipFrozenDaysLogService: GymClientMembershipFrozenDaysLogServiceService,
    public toaster: ToastrService
  ) { }

  ngOnInit(): void {
    this.GetGymMembershipFreezInfo();
  }

  public get clientBalanceStatus(): typeof ClientBalanceStatus {
    return ClientBalanceStatus;
  }
  public get WeekDays(): typeof WeekDays {
    return WeekDays;
  }
  public get alertMethod(): typeof AlertMethod {
    return AlertMethod;
  }
  public get alertStatus(): typeof AlertStatus {
    return AlertStatus;
  }
  public get periodStatus(): typeof PeriodStatus {
    return PeriodStatus;
  }
  public get gymMembershipClientStatus(): typeof GymMembershipClientStatus {
    return GymMembershipClientStatus;
  }

  public get cancelationWay(): typeof CancelationWay {
    return CancelationWay;
  }
  getGymActivityTypeString(gymActivityType: GymActivityType): string {
    return 'GymActivityType' + GymActivityType[gymActivityType];
  }

  divName;
  printPage() {
    this.divName = 'printClientMembershipFrozenDaysLogDetails';
    console.log(this.divName);

    let printContents = document.getElementById(this.divName).innerHTML;
    let originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  }

  GetGymMembershipFreezInfo() {

    this.LoadingGetGymClientMembershipFreez = true;

    /* var clientGymMembershipsSearchCriteria =
      new GymClientMembershipFrozenDaysLogsSearchCriteria();
    clientGymMembershipsSearchCriteria.AccountSetupId =
      this.sharedService.AccountSetupId;
    clientGymMembershipsSearchCriteria.Id = this.GymClientMembershipFreezId; */

    this.gymClientMembershipFrozenDaysLogService
      .GetGymClientMembershipFrozenDayLogInfo( this.GymClientMembershipFreezId)
      .subscribe((response) => {
        if (response.State == ResponseState.Success) {
          this.gymClientMembershipFrozenDaysLog = response.Result;
        }
        this.LoadingGetGymClientMembershipFreez = false;
      });
  }
}
