<div id="printClientGiftCardInfo">
  <div class="modal-header">
    <h5 class="modal-title text-capitalize" id="exampleModalLabel">
      <i class="fas fa-times text-danger"></i> &nbsp; {{'CancelGymMembership' | translate}}
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12">
        <div class="text-start py-4">
          <div class="formCard">

            <div class="row">
              <div class="col-md-12 col-12 " style="color: black;font-size: 14px;margin-bottom: 5%;">
                {{'CurrentGymMembershipName:' | translate}} &nbsp; {{CurrentGymMembershipName}}
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 col-12 " style="color: black;font-size: 14px;margin-bottom: 5%;">
                {{'CurrentClientName:' | translate}} &nbsp; {{CurrentClientName}}
              </div>
            </div>

          </div>

        </div>
      </div>

      <!-- <div class="col-lg-12" *ngIf="!ClientsBasicData">
        <div class="col-md-12 text-center py-4 my-4">
          <i class="fas fa-circle-notch fa-spin fa-3x"></i>
          <h1>{{'Loading' | translate}} </h1>
        </div>
      </div> -->

    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">
      {{'Close' | translate}}</button>
    <button type="button" class="btn btn-success" [disabled]="LoadingCancelGymMembership"
      (click)="CancelGymMembership()">
      <span *ngIf="!LoadingCancelGymMembership">
        {{'CancelGymMembership' | translate}}
      </span>
      <span *ngIf="LoadingCancelGymMembership">
        {{'LoadingCancelGymMembership' | translate}}
      </span>
    </button>
  </div>
</div>
