import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { formValueDates } from '../../shared/helpers';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { ClientBalance } from '../../models/classes/balances/client-balance';
import { ClientBalanceSearchCriteria } from '../../models/search-criterias/client-balance-search-criteria';
import { GetClientBalanceSearchCriteria } from '../../models/search-criterias/get-client-balance-search-criteria';
import { GetInvoicesPayableByTheClientSearchCriteria } from '../../models/search-criterias/get-invoices-payable-by-the-client-search-criteria';
import { MakeClientBalanceValidationModel } from '../../models/search-criterias/make-client-balance-validation-model';
import { ClientBalanceSearchResult } from '../../models/search-result/client-balance-search-result';
import { GetClientBalanceSearchResult } from '../../models/search-result/get-client-balance-search-result';
import { GetInvoicesPayableByTheClientSearchResult } from '../../models/search-result/getInvoices-payable-by-the-client-search-result';
import { MakeClientBalanceValidationResult } from '../../models/search-result/make-client-balance-validation-result';
import { ExecutionResponse } from '../../models/support/execution-response';
import { SharedService } from '../other/shared.service';

@Injectable({
  providedIn: 'root'
})
export class ClientBalanceService extends BaseService {
  private clientBalanceUrl;

  constructor(
    private http: HttpClient,
    private _BaseService: BaseServiceSZ,
    private sharedService: SharedService
  ) {
    super();
    this.clientBalanceUrl = this.sharedService.ApiUrl +
      'ClientBalance';

  }



  getClientBalance(id: String): Observable<ExecutionResponse<ClientBalance>> {
    return this.http.post<ExecutionResponse<ClientBalance>>(this.clientBalanceUrl + '/getById', id, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetchedclientBalance id=${id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<ClientBalance>>('getClientBalance id=${id}'))
      );
  }


  addClientBalance(clientBalance: ClientBalance): Observable<ExecutionResponse<ClientBalance>> {
    clientBalance = formValueDates(clientBalance, true);
    return this.http.post<ExecutionResponse<ClientBalance>>(this.clientBalanceUrl + '/Create', clientBalance, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('addedclientBalance w/ id=${clientBalance.Id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<ClientBalance>>('addClientBalance id=${id}'))
      );
  }

  /* GETclientBalance whose name contains search term */
  searchClientBalances(clientBalanceSearchCriteria: ClientBalanceSearchCriteria):
    Observable<ClientBalanceSearchResult> {
    clientBalanceSearchCriteria.AccountSetupId = this.sharedService.AccountSetupId;
    return this.http.post<ClientBalanceSearchResult>(this.clientBalanceUrl +
      '/Get', clientBalanceSearchCriteria, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetchedclientBalance');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<ClientBalanceSearchResult>('searchClientBalances id=${id}'))
      );
  }


  GetInvoicesPayableByTheClient(criteria: GetInvoicesPayableByTheClientSearchCriteria):
    Observable<GetInvoicesPayableByTheClientSearchResult> {
    criteria.AccountSetupId = this.sharedService.AccountSetupId;
    return this.http.post<GetInvoicesPayableByTheClientSearchResult>(this.clientBalanceUrl +
      '/GetInvoicesPayableByTheClient',
      criteria, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetchedInvoicesPayableByTheClient');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<GetInvoicesPayableByTheClientSearchResult>('searchInvoicesPayableByTheClient id=${id}'))
      );
  }


  GetClientBalance(criteria: GetClientBalanceSearchCriteria):
    Observable<GetClientBalanceSearchResult> {
    criteria.AccountSetupId = this.sharedService.AccountSetupId;
    return this.http.post<GetClientBalanceSearchResult>(this.clientBalanceUrl +
      '/GetClientBalance',
      criteria, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('GetClientBalanceSearchResult');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<GetClientBalanceSearchResult>('GetClientBalanceSearchResult id=${id}'))
      );
  }


  MakeClientBalanceValidation(makeClientBalanceValidationModel: MakeClientBalanceValidationModel):
    Observable<MakeClientBalanceValidationResult> {
    makeClientBalanceValidationModel.AccountSetupId = this.sharedService.AccountSetupId;
    return this.http.post<MakeClientBalanceValidationResult>(this.clientBalanceUrl +
      '/MakeClientBalanceValidation', makeClientBalanceValidationModel, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('MakeClientBalanceValidation');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<MakeClientBalanceValidationResult>('MakeClientBalanceValidation'))
      );
  }

  deleteClientBalance(clientBalance: ClientBalance | number): Observable<ExecutionResponse<boolean>> {
    return this.http.post<ExecutionResponse<boolean>>(this.clientBalanceUrl + '/delete', clientBalance, this.sharedService.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deletedclientBalance id=${id}');
        this._BaseService.ValidationResult(res);
      }),

      catchError(this._BaseService.handleError<ExecutionResponse<boolean>>('deleteClientBalance'))
    );
  }

  /** PUT: update theclientBalance on the server */
  updateClientBalance(clientBalance: ClientBalance): Observable<ExecutionResponse<boolean>> {

    clientBalance = formValueDates(clientBalance, true);
    return this.http.post<ExecutionResponse<boolean>>(this.clientBalanceUrl + '/Update', clientBalance, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('addedclientBalance w/ id=${clientBalance.Id}');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<ExecutionResponse<boolean>>('addClientBalance id=${id}'))
      );
  }


}

