import { Injectable } from '@angular/core';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../message/message.service';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { PettyCashItem } from '../../models/classes/transactions/petty-cash-items';
import { PettyCashItemsSearchCriteria } from '../../models/search-criterias/petty-cash-items-search-criteria';
import { PettyCashItemsSearchResult } from '../../models/search-result/petty-cash-items-search-result';
import { ExecutionResponse } from '../../models/support/execution-response';
import { ValidationCriteria } from '../../models/support/validation-criterias/validation-criteria';
import { formValueDates } from '../../shared/helpers';


@Injectable({
  providedIn: 'root'
})
export class PettyCashItemsService extends BaseService {
  private pettyCashItemssUrl //= super.BaseUrl() + 'PettyCashItems';  // URL to web api

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private  _BaseService:BaseServiceSZ

  ) {
    super();
    this.pettyCashItemssUrl =    this._BaseService.ApiUrl + 'PettyCashItems';

  }


  searchPettyCashItems(pettyCashItemsSearchCriteria: PettyCashItemsSearchCriteria): Observable<PettyCashItemsSearchResult> {
    pettyCashItemsSearchCriteria.AccountSetupId = super.getAccountSetupId();
    return this.http.post<PettyCashItemsSearchResult>(this.pettyCashItemssUrl + '/Get', pettyCashItemsSearchCriteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched pettyCashItems');
          this._BaseService.ValidationResult(res);
      }),
        
      );
  }


  getPettyCashItems(id: String): Observable<ExecutionResponse<PettyCashItem>> {
    return this.http.post<ExecutionResponse<PettyCashItem>>(this.pettyCashItemssUrl + '/getById', id, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched pettyCashItems id=${id}');
          this._BaseService.ValidationResult(res);
      }),
        
        catchError(this._BaseService.handleError<ExecutionResponse<PettyCashItem>>('pettyCashItems id=${id}'))
      );
  }




   //////// Save methods //////////

  /** POST: add a new attendMethodMaster to the server */
  addPettyCashItem(pettyCashItems: PettyCashItem): Observable<ExecutionResponse<PettyCashItem>> {
     
    pettyCashItems.AccountSetupId = super.getAccountSetupId();
    pettyCashItems = formValueDates(pettyCashItems,true);
    return this.http.post<ExecutionResponse<PettyCashItem>>(this.pettyCashItemssUrl + '/Create', pettyCashItems, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('add pettyCashItems id=${id}');
          this._BaseService.ValidationResult(res);
      }),
        catchError(this._BaseService.handleError<ExecutionResponse<PettyCashItem>>('addPettyCashItems id=${id}'))
      );
  }

  /** DELETE: delete the PettyCashItem from the server */
  deletePettyCashItems(pettyCashItems: PettyCashItem | number): Observable<ExecutionResponse<PettyCashItem>> {
    
    return this.http.post<ExecutionResponse<PettyCashItem>>(this.pettyCashItemssUrl + '/delete', pettyCashItems, super.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deleted pettyCashItems id=${id}');
        this._BaseService.ValidationResult(res);
    }),
      catchError(this._BaseService.handleError<ExecutionResponse<PettyCashItem>>('deletepettyCashItems'))
    );
  }



  updatePettyCashItem(pettyCashItems: PettyCashItem): Observable<ExecutionResponse<PettyCashItem>> {
    pettyCashItems.AccountSetupId = super.getAccountSetupId();
    pettyCashItems = formValueDates(pettyCashItems,true);
     

    return this.http.post<ExecutionResponse<PettyCashItem>>(this.pettyCashItemssUrl + '/Update', pettyCashItems, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('added PettyCashItems w/ id=${PettyCashItems.Id}');
          this._BaseService.ValidationResult(res);
      }),
        catchError(this._BaseService.handleError<ExecutionResponse<PettyCashItem>>('Update PettyCashItems id=${id}'))
      );
  }

  IsNameUnique(validationCriteria: ValidationCriteria): Observable<ExecutionResponse<Boolean>> {
    return this.http.post<ExecutionResponse<Boolean>>(this.pettyCashItemssUrl + '/IsNameUnique', validationCriteria, super.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deleted PettyCash id=${id}');
        this._BaseService.ValidationResult(res);
    }),
      
      catchError(this._BaseService.handleError<ExecutionResponse<Boolean>>('deletePettyCash'))
    );
  }

}
