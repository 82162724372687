import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-terms-condition',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss'],
})
export class TermsConditionsComponent {
  dir: string = localStorage.getItem('lang') === 'en' ? 'ltr' : 'rtl';
  constructor(public readonly translate: TranslateService) {
    console.log(this.dir);
  }
}
