import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { MessageService } from '../message/message.service';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { AppServices } from '../../models/classes/app/appservices';
import { AppServiceSearchCriteria } from '../../models/search-criterias/appservices-search-criteria';
import { AppServiceSearchResult } from '../../models/search-result/appservices-search-result';
import { ExecutionResponse } from '../../models/support/execution-response';


@Injectable({ providedIn: 'root' }) 
export class AppServicesService extends BaseService {
  private appServicesUrl //= super.BaseUrl() + 'appService';  // URL to web api

  constructor(
    private  _BaseService:BaseServiceSZ,
    private http: HttpClient) {
    super();
    this.appServicesUrl =    this._BaseService.ApiUrl + 'appServices';
  }
  
  /** GET appService by id. Will 404 if id not found */
  getappService(id: String): Observable<ExecutionResponse<AppServices>> {
  
    return this.http.post<ExecutionResponse<AppServices>>(this.appServicesUrl + '/getById', id, super.getHeaders())
      .pipe(
        tap(res =>{
          this._BaseService.log('fetched appService id=${id}');
          this._BaseService.ValidationResult(res);

        } ),
        catchError(this._BaseService.handleError<ExecutionResponse<AppServices>>('getappService id=${id}'))
      );
  }
  
  addappService(appService: AppServices): Observable<ExecutionResponse<AppServices>> { 
       return this.http.post<ExecutionResponse<AppServices>>(this.appServicesUrl + '/Create', appService , super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('added appService w/ id=${appService.Id}');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<ExecutionResponse<AppServices>>('addappService id=${id}'))
      );
  }
 
  /* GET appServices whose name contains search term */
  searchappServices(appServiceSearchCriteria: AppServiceSearchCriteria): Observable<AppServiceSearchResult> {
    appServiceSearchCriteria.AccountSetupId = super.getAccountSetupId();
    return this.http.post<AppServiceSearchResult>(this.appServicesUrl + '/Get', appServiceSearchCriteria, super.getHeaders())
      .pipe(
        tap(appServices =>{ 
          this._BaseService.log('fetched appServices');
          this._BaseService.ValidationResult(appServices);
      }), catchError(this._BaseService.handleError<AppServiceSearchResult>('searchappServices id=${id}')));
  }
  /** DELETE: delete the appService from the server */
  deleteappService(appService: AppServices | number): Observable<ExecutionResponse<AppServices>> {
    return this.http.post<ExecutionResponse<AppServices>>(this.appServicesUrl + '/delete', appService, super.getHeaders()).pipe(
      tap(res => {

        this._BaseService.ValidationResult(res);
        this._BaseService.log('deleted appService id=${id}')

      } ),
      catchError(this._BaseService.handleError<ExecutionResponse<AppServices>>('deleteappService'))
    );
  }
  /** PUT: update the appService on the server */
  updateappService(appService: AppServices): Observable<ExecutionResponse<AppServices>> {
    return this.http.post<ExecutionResponse<AppServices>>(this.appServicesUrl + '/Update', appService, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('added appService w/ id=${appService.Id}');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<ExecutionResponse<AppServices>>('addappService id=${id}'))
      );
  }
 
}