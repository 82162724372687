import { SearchCriteria } from "./search-criteria";

export class DetailCodeSearchCriteria extends SearchCriteria {
    public MasterCodeId: number;
    public Lang: string;
    public Version: number;
    public ParentId:number;
    
    
}

