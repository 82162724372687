import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import { SharedService } from 'src/app/services/other/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent  {

  constructor(    private readonly cookieService: CookieService,
    private readonly sharedService: SharedService,
     private router: Router) 
  { 
    var lang = !localStorage.getItem('lang')
    ? 'ar'
    : localStorage.getItem('lang');
  var dir = !localStorage.getItem('dir')
    ? 'rtl'
    : localStorage.getItem('dir');

  localStorage.clear();

  localStorage.setItem('lang', lang);
  localStorage.setItem('dir', dir);

  this.sharedService.TokenObject = null;
  this.cookieService.delete('tokenObject', '/', `.${environment.RootDomain}`); 
  location.href = '/login';
  }
}
