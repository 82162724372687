import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'timeLang'
})
export class TimeLangPipe implements PipeTransform {


  constructor(public translateService: TranslateService,) {

  }

  transform(value: Date | any, ...args: any[]): any {

    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    value = new Date(value);

    if (this.translateService.currentLang == 'ar') {
      return value.toLocaleTimeString('ar-EG');
    }
    else {
      return value.toLocaleTimeString('en-US');
    }

  }

}
