import { Component, Inject, OnInit } from '@angular/core';
import {
  FormGroup,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  AutoRegistrationService,
  BaseForm,
  ComponentBase,
  SharedService,
} from 'core';
import { takeUntil } from 'rxjs/operators';
import { AutoRegistrationConfig } from '../../models/auto-registration-config';
import { CONFIG } from '../../registration.module';
import { SupportChatService } from 'projects/core/src/lib/services/support-chat.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
})
export class ForgetPasswordComponent
  extends ComponentBase
  implements BaseForm, OnInit
{
  form!: FormGroup;

  constructor(
    private supportChatService: SupportChatService,
    private readonly router: Router,
    public readonly sharedService: SharedService,
    private readonly autoRgistrationService: AutoRegistrationService,
    @Inject(CONFIG) public config: AutoRegistrationConfig
  ) {
    super();
  }

  ngOnInit(): void {
    this.supportChatService.chatScript();
    this.initForm();
  }

  initForm(): void {
    this.form = new UntypedFormGroup({
      UserName: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(20),
        Validators.minLength(4),
      ]),
      VerifyMobileNumberType: new UntypedFormControl(1),
    });
  }

  onGetDifferentAccount(): void {
    this.router.navigate([`../${this.config.routes.VERIFY_IDENTITY}`]);
  }

  onSubmit(): void {
    this.loading = true;
    this.autoRgistrationService
      .forgetPassword(this.form.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.checkResponse = this.sharedService.checkResposeStatus(
          res.Message,
          res.State
        );
        this.loading = false;
        if (this.checkResponse) {
          this.router.navigate(['../verify'], {
            queryParams: {
              user: this.form.get('UserName')?.value,
              requestType: 'normal',
            },
          });
        }
      });
  }
}
