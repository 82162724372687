import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { catchError, tap } from 'rxjs/operators';
import { AttendMethodMaster } from '../../models/classes/employee/attend-method-master';
import { AttendMethodMasterSearchCriteria } from '../../models/search-criterias/attend-method-master-search-criteria';
import { AttendMethodMasterSearchResult } from '../../models/search-result/attend-method-master-search-result';
import { ExecutionResponse } from '../../models/support/execution-response';
import { ValidationCriteria } from '../../models/support/validation-criterias/validation-criteria';
import { formValueDates } from '../../shared/helpers';
import { MessageService } from '../message/message.service';
import { BaseService, BaseServiceSZ } from '../other/baseService';

@Injectable({
  providedIn: 'root'
})
export class AttendMethodMasterService extends BaseService {

  private attendMethodMastersUrl // = super.BaseUrl() + 'AttendMethodMaster';  // URL to web api

  constructor(
    private http: HttpClient, private messageService: MessageService,
    private  _BaseService:BaseServiceSZ
  ) {
    super();
    this.attendMethodMastersUrl =    this._BaseService.ApiUrl + 'AttendMethodMaster';

  }

  // /** GET attendMethodMasters from the server */
  // getAttendMethodMasters(): Observable<AttendMethodMaster[]> {
  //   return this.http.get<AttendMethodMaster[]>(this.attendMethodMastersUrl, super.getHeaders())
  //     .pipe(
  //       tap(attendMethodMasters => this._BaseService.log('fetched attendMethodMasters')),
  //       catchError(this._BaseService.handleError('getAttendMethodMasters', []))
  //     );
  // }


  /** GET attendMethodMaster by id. Will 404 if id not found */
  getAttendMethodMaster(id: String): Observable<ExecutionResponse<AttendMethodMaster>> {
    //  getAttendMethodMaster(id: String): ExecutionResponse<AttendMethodMaster> {
    //const url = '${this.attendMethodMastersUrl}/${id}';
    
    return this.http.post<ExecutionResponse<AttendMethodMaster>>(this.attendMethodMastersUrl + '/getById', id, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched attendMethodMaster id=${id}');
          this._BaseService.ValidationResult(res);
      }),
        
        catchError(this._BaseService.handleError<ExecutionResponse<AttendMethodMaster>>('getAttendMethodMaster id=${id}'))
      );
  }



  /* GET attendMethodMasters whose name contains search term */
  searchAttendMethodMasters(attendMethodMasterSearchCriteria: AttendMethodMasterSearchCriteria): Observable<AttendMethodMasterSearchResult> {
     
    attendMethodMasterSearchCriteria.AccountSetupId = super.getAccountSetupId();
    return this.http.post<AttendMethodMasterSearchResult>(this.attendMethodMastersUrl + '/Get', attendMethodMasterSearchCriteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched attendMethodMasters');
          this._BaseService.ValidationResult(res);
      }),
        //catchError(this._BaseService.handleError('getAttendMethodMasters', []))
      );

  }

  /* GET attendMethodMasters whose name contains search term */
  searchAttendMethodMastersBasic(attendMethodMasterSearchCriteria: AttendMethodMasterSearchCriteria): Observable<AttendMethodMasterSearchResult> {
    attendMethodMasterSearchCriteria.AccountSetupId = super.getAccountSetupId();
    return this.http.post<AttendMethodMasterSearchResult>(this.attendMethodMastersUrl + '/GetBasic', attendMethodMasterSearchCriteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched attendMethodMasters');
          this._BaseService.ValidationResult(res);
      }),
        //catchError(this._BaseService.handleError('getAttendMethodMasters', []))
      );

  }

  //////// Save methods //////////

  /** POST: add a new attendMethodMaster to the server */
  addAttendMethodMaster(attendMethodMaster: AttendMethodMaster): Observable<ExecutionResponse<AttendMethodMaster>> {
    attendMethodMaster.AccountSetupId= super.getAccountSetupId();
    attendMethodMaster = formValueDates(attendMethodMaster,true);
    return this.http.post<ExecutionResponse<AttendMethodMaster>>(this.attendMethodMastersUrl + '/Create', attendMethodMaster, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('add attendMethodMaster id=${id}');
          this._BaseService.ValidationResult(res);
      }),
        catchError(this._BaseService.handleError<ExecutionResponse<AttendMethodMaster>>('addAttendMethodMaster id=${id}'))
      );
  }

  /** DELETE: delete the attendMethodMaster from the server */
  deleteAttendMethodMaster(attendMethodMaster: AttendMethodMaster | number): Observable<ExecutionResponse<AttendMethodMaster>> {
    return this.http.post<ExecutionResponse<AttendMethodMaster>>(this.attendMethodMastersUrl + '/delete', attendMethodMaster, super.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deleted attendMethodMaster id=${id}');
        this._BaseService.ValidationResult(res);
    }),
      catchError(this._BaseService.handleError<ExecutionResponse<AttendMethodMaster>>('deleteAttendMethodMaster'))
    );
  }


  IsNameUnique(validationCriteria: ValidationCriteria): Observable<ExecutionResponse<Boolean>> {
    return this.http.post<ExecutionResponse<Boolean>>(this.attendMethodMastersUrl + '/IsNameUnique', validationCriteria, super.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deleted client id=${id}');
        this._BaseService.ValidationResult(res);
    }),
      
      catchError(this._BaseService.handleError<ExecutionResponse<Boolean>>('deleteClient'))
    );
  }

  IsMobileNumberUnique(validationCriteria: ValidationCriteria): Observable<ExecutionResponse<Boolean>> {
    return this.http.post<ExecutionResponse<Boolean>>(this.attendMethodMastersUrl + '/IsMobileNumberUnique', validationCriteria, super.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deleted client id=${id}');
        this._BaseService.ValidationResult(res);
    }),
      
      catchError(this._BaseService.handleError<ExecutionResponse<Boolean>>('deleteClient'))
    );
  }

  IsPhoneNumberUnique(validationCriteria: ValidationCriteria): Observable<ExecutionResponse<Boolean>> {
    return this.http.post<ExecutionResponse<Boolean>>(this.attendMethodMastersUrl + '/IsPhoneNumberUnique', validationCriteria, super.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deleted client id=${id}');
        this._BaseService.ValidationResult(res);
    }),
      
      catchError(this._BaseService.handleError<ExecutionResponse<Boolean>>('deleteClient'))
    );
  }

  /** PUT: update the attendMethodMaster on the server */
  updateAttendMethodMaster(attendMethodMaster: AttendMethodMaster): Observable<ExecutionResponse<AttendMethodMaster>> {
     
    attendMethodMaster.AccountSetupId = super.getAccountSetupId();
    attendMethodMaster = formValueDates(attendMethodMaster,true);

    return this.http.post<ExecutionResponse<AttendMethodMaster>>(this.attendMethodMastersUrl + '/Update', attendMethodMaster, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('added attendMethodMaster w/ id=${attendMethodMaster.Id}');
          this._BaseService.ValidationResult(res);
      }),
        catchError(this._BaseService.handleError<ExecutionResponse<AttendMethodMaster>>('addAttendMethodMaster id=${id}'))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
 
}
