import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  ComponentBase,
  BaseForm,
  COUNTRY,
  KNOW_US,
  CITY,
  BUSINESS_TYPE,
  SharedService,
  AutoRegistrationService,
  SavePhasesDataType,
} from 'core';
import { SalesChatService } from 'projects/core/src/lib/services/sales-chat.service';
import { forkJoin } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-choose-business',
  templateUrl: './choose-business.component.html',
  styleUrls: ['./choose-business.component.scss'],
})
export class ChooseBusinessComponent
  extends ComponentBase
  implements BaseForm, OnInit
{
  form!: FormGroup;
  countries: COUNTRY[] = [];
  knowUsWays: KNOW_US[] = [];
  cities: CITY[] = [];
  businessType: BUSINESS_TYPE[] = [];
  businessTypeIds: number[] = [];
  notAllowedBusinessTypes: number[] = [];

  private get activeBusinessTypes(): BUSINESS_TYPE[] {
    return this.businessType.filter((type) => type.active);
  }

  constructor(
    private salesChatService: SalesChatService,
    private readonly sharedservice: SharedService,
    private readonly route: ActivatedRoute,
    private readonly autoRegistrationService: AutoRegistrationService,
    private readonly router: Router,
    readonly translateService: TranslateService,
    readonly title: Title
  ) {
    super();

    this.getCountriesList();
    this.getKnowUs();
    translateService.onLangChange.subscribe((_) => {
      title.setTitle(translateService.instant('Title_phase_two'));
      this.getCountriesList();
      this.getKnowUs();
      this.getCitiesAndBusinessTypes(this.form.get('CountryId')?.value);
    });
    title.setTitle(translateService.instant('Title_phase_two'));
  }

  ngOnInit(): void {
    this.salesChatService.chatScript();
    this.initForm();

    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        this.form.patchValue({
          RegistrationCycleLogId: +params['RegistrationCycleLogId'],
        });
      });
  }

  initForm(): void {
    this.form = new UntypedFormGroup({
      RegistrationCycleLogId: new UntypedFormControl(null),
      BusinessName: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(30),
        Validators.minLength(4),
      ]),
      BusinessTypes: new UntypedFormControl([], Validators.required),
      CountryId: new UntypedFormControl(null, Validators.required),
      CityId: new UntypedFormControl(null, Validators.required),
      KnowUsWayId: new UntypedFormControl(null, [Validators.required]),
      IsCurrentlyUsingAnyOtherSystem: new UntypedFormControl(
        Validators.required
      ),
      SavePhasesDataType: new UntypedFormControl(SavePhasesDataType.Add),
    });
  }

  onChangeCountry(event: MatSelectChange): void {
    this.getCitiesAndBusinessTypes(event.value);
  }

  onSelectBusinessType(busType: BUSINESS_TYPE): void {
    if (
      !busType.active &&
      this.notAllowedBusinessTypes.includes(busType.BusinessType)
    ) {
      return;
    }
    busType.active = !busType.active;
    this.updateNotAllowedBusinessTypes();
    this.form.patchValue({
      BusinessTypes: this.activeBusinessTypes.map((type) => type.Id),
    });
  }

  private updateNotAllowedBusinessTypes(): void {
    this.notAllowedBusinessTypes = Array.from(
      new Set(
          this.activeBusinessTypes
              .map(type => type.NotAllowedWithBusinessTypes)
              .reduce((acc, val) => acc.concat(val), [])
      )
  );
  
  }

  private getCountriesList(): void {
    this.sharedservice
      .getCountries()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.countries = res.Result;
      });
  }

  private getKnowUs(): void {
    this.sharedservice
      .getKnowUs()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.knowUsWays = res.Result;
      });
  }

  private getCitiesAndBusinessTypes(countryId: number): void {
    forkJoin({
      citiesResponse: this.sharedservice.getCities(countryId),
      businessTypesResponse: this.sharedservice.getBusinessType(countryId),
    })
      .pipe(
        mergeMap(async (res) => ({
          cities: res.citiesResponse.Result,
          businessTypes: res.businessTypesResponse.Result,
        })),
        takeUntil(this.destroy$)
      )
      .subscribe((res) => {
        this.cities = res.cities;
        this.businessTypeIds = [];
        this.businessType = res.businessTypes.map((item) => ({
          ...item,
          active: false,
        }));
      });
  }

  onSubmit(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.loading = true;
      this.autoRegistrationService
        .saveBusinessType(this.form.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res) => {
            this.loading = false;
            if (this.sharedservice.checkResposeStatus(res.Message, res.State)) {
              this.router.navigate(['', 'success-registration'], {
                skipLocationChange: true,
              });
            }
          },
          (error) => {
            this.loading = false;
          }
        );
    }
  }
}
