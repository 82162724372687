import { OrderByCulture } from '../enums/order-by-culture';
import { OrderDirection } from '../enums/order-direction';

export class SearchCriteria {
  public PagingEnabled: boolean;
  public PageSize: number;

  public PageNumber: number;
  public OrderByDirection: OrderDirection;
  public OrderByCultureMode: OrderByCulture;
  public AccountSetupId: number;
  public ForListView: number;
  public IsCashiers: boolean;
  public IsForTableView: boolean;
  public FreeText: string;
  public CompanyId: number;
  public UserId: number;
  public UseLoyalityPoints: boolean;
  public Id: number | null;
  public PackageId: number;
  public ForAutoComplete?: number | null;
  public SearchWithGymMembershipCode: boolean;
  public GymMembershipCode: number;
  KnowUsId?: number | null;
  public Ids: number[] | null;

  public DateFrom: Date | string;
  public DateTo: Date | string;

}
