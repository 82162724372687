import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PurePrivacyPolicyComponent } from './pure-privacy-policy/pure-privacy-policy.component';
import { PureRoutingModule } from './pure-routing.module';
import { PureDeleteStepsComponent } from './pure-delete-steps/pure-delete-steps.component';



@NgModule({
  declarations: [
    PurePrivacyPolicyComponent,
    PureDeleteStepsComponent
  ],
  imports: [
    CommonModule,
    PureRoutingModule
  ],
  exports:[PurePrivacyPolicyComponent]
})
export class PureModule { }
