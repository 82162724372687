<div class="successPage">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="success">
          <div class="item">
            <img
              src="assets/img/1103-confetti-outline-trans.gif"
              trigger="hover"
              style="width: 250px; height: 250px; margin: 0 auto"
            />
            <h3>{{ "Congratulations" | translate }}</h3>
            <h6>
              {{ "YourAccountSuccessfullyCreated" | translate }}
            </h6>
            <h6>
              {{ "JustFewStepsMoreToSetupYourBusinessAccount" | translate }}
            </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
