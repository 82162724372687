<section

  class="branch-item"
  fxLayout="row"
  fxLayoutAlign="space-between center"
  *ngFor="let user of userList"
  (click)="onClickItem(user)"
  [class.spinner]="loading"
>
  <main fxLayout="row" fxLayoutAlign="start center" >
    <img src="assets/img/profile.jpg" alt="" title="" class="img-profile" />
    <div class="desc">
      <h1>{{ user.UserName }}</h1>
    </div>
  </main>

  <div fxLayout="row" fxLayoutAlign="end center" class="icon-container">
    <img src="assets/img/right-arrow.jpg" alt="" title="" />
    <i *ngIf="user.IsAdmin" class="fas fa-user-shield" title="Admin"></i>
    <i *ngIf="user.IsCashier" class="fas fa-cash-register" title="Cashier"></i>
    <i *ngIf="user.IsShiftOpen" class="fa fa-circle green-icon" title="Shift Opened"></i>
  </div>
</section>
