import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { BRANCH, USER } from '../../model/branch.model';
import {
  AUTH_USER_INPUT,
  AUTH_USER_RESPONCE,
  FORGET_PASSWORD_INPUT,
  FORGET_PASSWORD_RESPONSE,
  GET_GENERAL_TOKEN_INPUT,
  UPDATE_PASSWORD_INPUT,
  UPDATE_PASSWORD_RESPONSE,
  VERFICATION_RESET_INPUT,
  VERIFY_SIGNUP_INPUT,
  RESEND_INPUT,
  GENERAL_TOKEN_INPUT,
} from '../../model/login';
import {
  RESPONCE,
  PAGING_PARAMS,
  USER_BRANCHS_PARAMS,
} from '../../model/responce';
import {
  PHASE_ONE_INPUT,
  PHASE_RESPONSE,
  BUSINESS_TYPE_INPUT,
  BUSINESS_TYPE_RESPONSE,
  VERFICATION_CODE_INPUT,
  VERIFICATION_CODE_RESPONSE,
} from '../../model/signUp.model';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class AutoRegistrationService {
  constructor(
    private readonly apiService: ApiService,
    private readonly spinner: NgxSpinnerService
  ) {}

  login(data: AUTH_USER_INPUT): Observable<RESPONCE<AUTH_USER_RESPONCE>> {
    return this.apiService.post<AUTH_USER_INPUT, RESPONCE<AUTH_USER_RESPONCE>>(
      'v2/Auth/AuthUser',
      data
    );
  }

  getBranches(params: PAGING_PARAMS): Observable<RESPONCE<BRANCH[]>> {
    return this.apiService.get<RESPONCE<BRANCH[]>>('branch/GetAll', params);
  }

  forgetPassword(
    data: FORGET_PASSWORD_INPUT
  ): Observable<RESPONCE<FORGET_PASSWORD_RESPONSE>> {
    return this.apiService.post<
      FORGET_PASSWORD_INPUT,
      RESPONCE<FORGET_PASSWORD_RESPONSE>
    >('v2/Auth/ForgetPassword', data);
  }

  updatePassword(
    data: UPDATE_PASSWORD_INPUT
  ): Observable<RESPONCE<UPDATE_PASSWORD_RESPONSE>> {
    return this.apiService.post<
      UPDATE_PASSWORD_INPUT,
      RESPONCE<UPDATE_PASSWORD_RESPONSE>
    >('v2/Auth/ResetPassword', data);
  }

  verification(data: VERFICATION_RESET_INPUT): Observable<RESPONCE<boolean>> {
    return this.apiService.post<VERFICATION_RESET_INPUT, RESPONCE<boolean>>(
      'v2/Auth/VerifyCode',
      data
    );
  }

  verificationPhaseOne(data: VERIFY_SIGNUP_INPUT): Observable<RESPONCE<any>> {
    return this.apiService.post<VERIFY_SIGNUP_INPUT, any>(
      'RegistrationCyclePhaseOne/VerifyMobileNumber',
      data
    );
  }

  signUpPhaseOne(data: PHASE_ONE_INPUT): Observable<RESPONCE<PHASE_RESPONSE>> {
    return this.apiService.post<PHASE_ONE_INPUT, RESPONCE<PHASE_RESPONSE>>(
      'RegistrationCyclePhaseOne/SavePhaseOneData',
      data
    );
  }

  saveBusinessType(
    data: BUSINESS_TYPE_INPUT
  ): Observable<RESPONCE<BUSINESS_TYPE_RESPONSE>> {
    return this.apiService.post<
      BUSINESS_TYPE_INPUT,
      RESPONCE<BUSINESS_TYPE_RESPONSE>
    >('RegistrationCyclePhaseTwo/SavePhaseTwoData', data);
  }

  sendVerficationCode(
    data: VERFICATION_CODE_INPUT
  ): Observable<RESPONCE<VERIFICATION_CODE_RESPONSE>> {
    return this.apiService.post<
      VERFICATION_CODE_INPUT,
      RESPONCE<VERIFICATION_CODE_RESPONSE>
    >('RegistrationCyclePhaseOne/SendVerificationCode', data);
  }

  getUserBranches(params: USER_BRANCHS_PARAMS): Observable<RESPONCE<any>> {
    return this.apiService.get<RESPONCE<USER[]>>(
      'branch/GetBranchUsers',
      params
    );
  }

  getGeneralToken(data: GET_GENERAL_TOKEN_INPUT): Observable<RESPONCE<any>> {

    return this.apiService.post<GET_GENERAL_TOKEN_INPUT, RESPONCE<any>>(
      'v2/Auth/GetGeneralToken',
      data
    );
  }

  resendCode(data: RESEND_INPUT): Observable<any> {
    return this.apiService.post<any, any>(
      'RegistrationCyclePhaseOne/SendVerificationCode',
      data
    );
  }
}
