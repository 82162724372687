<div class="print-wrap">
  <div class="" style="width: 380px; padding: 10px; margin: 0px auto">
    <div *ngIf="item" style="width: 100%;">
      <div id="print" class="printBox" style="background-color: #fff; padding: 20px;">

        <div style="text-align: start; direction: rtl; width: 100%; margin: 0; background-color: #fff;" [dir]="dirc">

          <h1 class="bordered-heading" style="border: 1px solid #000; border-radius: 20px; border-top-right-radius: 0; border-bottom-left-radius: 0; padding: 10px; width: 100%; text-align: center; margin-bottom: 20px;">
            {{'BookingReminder' | translate}}
          </h1>

          <hr />

          <table style="width: 100%; color: #000; font-size: 20px; font-weight: bold; margin-bottom: 20px;" [dir]="dirc">
            <tbody>
              <tr>
                <td style="width: 25%;">{{'TheDay' | translate}}</td>
                <td>
                  <span *ngIf="item.WeekDay == 0">{{ 'Sunday' | translate }}</span>
                  <span *ngIf="item.WeekDay == 1">{{ 'Monday' | translate }}</span>
                  <span *ngIf="item.WeekDay == 2">{{ 'Tuesday' | translate }}</span>
                  <span *ngIf="item.WeekDay == 3">{{ 'Wednesday' | translate }}</span>
                  <span *ngIf="item.WeekDay == 4">{{ 'Thursday' | translate }}</span>
                  <span *ngIf="item.WeekDay == 5">{{ 'Friday' | translate }}</span>
                  <span *ngIf="item.WeekDay == 6">{{ 'Saturday' | translate }}</span>
                </td>
                <td *ngIf="lang == 'en'">{{ item.CurrDate | date: 'dd/MM/yyyy' }}</td>
                <td *ngIf="lang == 'ar'">{{ item.CurrDate | date: 'yyyy/MM/dd' | arabicDate }}</td>
              </tr>
              <tr>
                <td>{{'Employee' | translate}}</td>
                <td>{{item.EmployeeName }}</td>
                <td>{{item.EmployeeId }}</td>
              </tr>
            </tbody>
          </table>

          <div class="row">
            <table style="width: 100%; border-collapse: collapse;">
              <thead>
                <tr style="background-color: #f2f2f2; border-bottom: 2px solid #000;">
                  <th style="padding: 10px;">{{'Client' | translate}}</th>
                  <th style="padding: 10px;">{{'Time' | translate}}</th>
                  <th style="padding: 10px;">{{'Details' | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let x of item.ServicesList" style="border-bottom: 1px solid #ddd;">
                  <td style="padding: 10px;">{{x.ClientName}}</td>
                  <td style="padding: 10px;">
                    <span *ngIf="lang == 'ar'">{{x.ServiceDate | date: 'hh:mm ' | arabicDate }}</span>
                    <span *ngIf="lang == 'en'">{{x.ServiceDate | date: 'hh:mm ' }}</span>
                    {{x.ServiceDate | date: 'a' | translate}}
                  </td>
                  <td style="padding: 10px;">
                    <div>{{x.ServiceName}}</div>
                    <div>{{'Count' | translate}} : {{x.Quantity}}</div>
                    <div>{{'BookingNumber' | translate}} : {{x.BookingId}}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
