import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ClientBalanceDetail } from '../../models/classes/balances/client-balance-detail';
import { ResponseState } from '../../models/support/response-state';
import { ClientBalanceDetailService } from '../../services/balances/client-balance-detail.service';
import { ClientBalanceService } from '../../services/balances/client-balance.service';
import { SharedService } from '../../services/other/shared.service';
import { TransactionsSecondService } from '../../services/transaction/transactions-second.service';
import { PaginationComponent } from '../pagination/pagination.component';
import { ClientService } from 'src/app/services/client/client.service';
import { Gender } from 'src/app/models/enums/gender';
import { GetCurrentEmployeeItemInfoModel } from 'src/app/models/classes/transactions/get-cuurent-employee-item-info-model';
import { GetCurrentEmployeeItemInfoSearchResult } from 'src/app/models/search-result/get-current-employee-item-info-search-result';
import { CurrentEmployeeItemInfoDTO } from 'src/app/models/classes/transactions/current-employee-item-info-DTO';
import { BookingOrTransactionItemType } from "src/app/models/enums/booking-or-transaction-item-type";


@Component({
  selector: 'app-modal-employee-item-info',
  templateUrl: './modal-employee-item-info.component.html',
  styleUrls: ['./modal-employee-item-info.component.scss'],
  providers: [DatePipe]

})
export class ModalEmployeeItemInfoComponent implements OnInit {

  @ViewChild('pager', { static: true }) pager: PaginationComponent;

  @Input() ItemId: number;
  public ClientBalanceDetails: ClientBalanceDetail[] = [];
  public Loading: boolean = false;
  public CurrentEmployeeItemInfo: CurrentEmployeeItemInfoDTO;

  constructor(public activeModal: NgbActiveModal,
    public translate: TranslateService,
    public clientService: ClientService,
    public sharedService: SharedService,
    public _transactionsSecondService: TransactionsSecondService,
    private fb: UntypedFormBuilder,
    public datepipe: DatePipe,
    public clientBalanceDetailService: ClientBalanceDetailService,
    public clientBalanceService: ClientBalanceService) { }

  ngOnInit(): void {

    this.GetCurrentEmployeeItemInfo();
  }

  public get bookingOrTransactionItemType(): typeof BookingOrTransactionItemType {
    return BookingOrTransactionItemType;
  }


  GetCurrentEmployeeItemInfo() {

    let model = new GetCurrentEmployeeItemInfoModel();
    model.ItemId = this.ItemId;

    this._transactionsSecondService
      .GetCurrentEmployeeItemInfo(model)
      .subscribe((response: GetCurrentEmployeeItemInfoSearchResult) => {

        if (response.State == ResponseState.Success) {
          this.CurrentEmployeeItemInfo = response.CurrentEmployeeItemInfo;
        } else {
          this.sharedService.ToastrError(response.Message);
        }
      });
  }

  divName
  printPage() {

    this.divName = 'printCurrentEmployeeItemInfo';
    console.log(this.divName)

    let printContents = document.getElementById(this.divName).innerHTML;
    let originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  }

  public get gender(): typeof Gender {
    return Gender;
  }

}
