import {
  Component,
  Output,
  EventEmitter,
  Input,
  ElementRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
})
export class UploadFileComponent {
  constructor() {}
  selectedFile!: File;
  @Input() imageUrl!: string;
  @Output() getFile = new EventEmitter<File>();
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

  onFileSelected(event: any) {
    this.selectedFile = <File>event.target.files[0];
    this.getFile.emit(this.selectedFile);
    this.previewImage(this.selectedFile);
  }

  previewImage(file: File) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.imageUrl = e.target.result;
    };
    reader.readAsDataURL(file);
  }

  uploadImage() {
    // const formData = new FormData();
    // formData.append('image', this.selectedFile);
    // this.http.post<any>('your-upload-url', formData).subscribe(
    //   (response) => {
    //     this.imageUrl = response.imageUrl;
    //   },
    //   (error) => {
    //   }
    // );
  }

  onOpenFile(): void {
    this.fileInput.nativeElement.click();
  }
}
