import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { ResponseState } from '../../models/support/response-state';
import { SharedService } from '../../services/other/shared.service';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from 'src/app/services/client/client.service';
import { ClientSearchCriteria } from 'src/app/models/search-criterias/client-search-criteria';
import { GetLiteForGBClientDTO } from 'src/app/models/classes/client/get-lite-for-gb-client';
import { PaginationComponent } from '../pagination/pagination.component';



@Component({
  selector: 'app-modal-view-group-clients',
  templateUrl: './modal-view-group-clients.component.html',
  styleUrls: ['./modal-view-group-clients.component.scss'],
  providers: [DatePipe]

})
export class ModalViewGroupClients implements OnInit {

  @Input() FilterClientCriteria: ClientSearchCriteria;
  @ViewChild('pager', { static: true }) pager: PaginationComponent;

  public LoadingGetClients: boolean = false;
  public Clients: GetLiteForGBClientDTO[];


  constructor(public activeModal: NgbActiveModal,
    public translate: TranslateService,
    public clientService: ClientService,
    public sharedService: SharedService,
    public toaster: ToastrService) { }

  ngOnInit(): void {

    this.pager.pageSize = 10;
    this.pager.ddlPageSize = 10;
    this.pager.GlobalPageIndex = 0;

    this.GetClients();
  }


  GetClients() {

    this.Clients = [];
    this.FilterClientCriteria.PageNumber = this.pager.GlobalPageIndex;
    this.FilterClientCriteria.PageSize = 10;
    this.FilterClientCriteria.PagingEnabled = true;

    this.LoadingGetClients = true;
    this.clientService.GetClientsLite(this.FilterClientCriteria).subscribe((response) => {
      if (response.State == ResponseState.Success) {
        this.Clients = response.Clients;
        this.pager.EventsCount = response.TotalCount;
        this.pager.setPage(this.pager.GlobalPageIndex, false);
      }
      this.LoadingGetClients = false;
    });
  }


}
