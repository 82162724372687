import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { AppointmentService } from '../../services/appointment/appointment.service';
import { ClientBalanceService } from '../../services/balances/client-balance.service';
import { ClientService } from '../../services/client/client.service';
import { SharedService } from '../../services/other/shared.service';
import { TransactionsSecondService } from '../../services/transaction/transactions-second.service';
import { ServiceConsumables } from '../../models/classes/service/service-consumables';
import { StockTransTypes } from '../../models/enums/stock-trans-types.enum';
import { ServiceConsumablesService } from 'src/app/services/Service/service-consumables.service';

@Component({
  selector: 'app-modal-show-service-consumables-info',
  templateUrl: './modal-show-service-consumables-info.component.html',
  styleUrls: ['./modal-show-service-consumables-info.component.scss'],
  providers: [DatePipe],
})
export class ModalShowServiceConsumablesInfo implements OnInit {
  @Input() ConsumablesServiceGlobalName: string;
  @Input() ConsumablesTransactionCode: string;
  @Input() TransTypeId: StockTransTypes;

  @Output() CloseModal = new EventEmitter<boolean>();

  public ServiceConsumablesList: ServiceConsumables[];
  public modalTitle: string = '';
  public modalTitleForServiceName: string = '';

  constructor(
    public appointmentService: AppointmentService,
    public activeModal: NgbActiveModal,
    public clientSearvice: ClientService,
    public translateService: TranslateService,
    public toaster: ToastrService,
    public serviceConsumablesService: ServiceConsumablesService,
    public sharedService: SharedService,
    public _transactionsSecondService: TransactionsSecondService,
    private fb: UntypedFormBuilder,
    public datepipe: DatePipe,

    public clientBalanceService: ClientBalanceService
  ) {}

  ngOnInit(): void {
    this.modalTitle =
      this.TransTypeId == StockTransTypes.AddServiceConsumables
        ? 'AddServiceConsumablesInfo'
        : 'SubtractServiceConsumablesInfo';
  }
}
