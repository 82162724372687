<div class="alert" fxLayout="row" fxLayoutAlign="start center">
  <p fxLayout="row" fxLayoutAlign="start center" class="gap-5">
    <i class="fa-solid fa-info"></i>
    {{
      (!haveTax
        ? "Add_prices"
        : isPricesWithTax
        ? "Add_prices_with_tax"
        : "Add_prices_without_tax"
      ) | translate
    }}
  </p>
</div>
