import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  @Output() SendFreeText = new EventEmitter();
  @Output() autoSearchByText = new EventEmitter();
  @Output() SendCurrentFreeText = new EventEmitter();


  @Input() SearchName;

  constructor() { }

  ngOnInit(): void {
    let dd = this.SearchName;
  }

  freeText;
  searchByText() {
    this.SendFreeText.emit(this.freeText);
  }
  safeId;
  ClearSearch() {
    this.freeText = null;
    this.safeId = null;
    this.SendFreeText.emit(this.freeText);
  }
  AutoSendCurrentFreeText() {
    this.SendCurrentFreeText.emit(this.freeText);
  }
}



