import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ExceptionResponse } from '../models/support/exception-response';
import { SharedService } from './other/shared.service';

@Injectable({
  providedIn: 'root'
})
export class HttpBaseServiceV2Service {
  private apiUrl;
  newApiUrl: string;
  constructor(private sharedService: SharedService,
    private httpClient: HttpClient) {
    this.apiUrl = sharedService.ApiUrl;
    this.newApiUrl = sharedService.NewApiUrl;
  }


  private RemoveNull(queryParams: any) {
    Object.keys(queryParams).forEach(key => {
      if (queryParams[key] == null || queryParams[key] == undefined
        || queryParams[key] == "null" || queryParams[key] == "undefined")
        delete queryParams[key];
    });
    return queryParams;
  }
  handleError(error: HttpErrorResponse) {
    if (error.status == 422 || error.status == 500) {
      var errorResponse: ExceptionResponse = error.error;
      this.sharedService.ToastrError(errorResponse.Message);
    }
    throw error;
  }


  Get(endPoint: string, queryParams: any = {}, isNew: boolean = false): Observable<any> {
    let url = `${isNew ? this.newApiUrl : this.apiUrl}${endPoint}`;
    return this.httpClient.get(url, {
      params: this.RemoveNull(queryParams),
      headers: this.sharedService.getHeaders().headers
    })
      .pipe(
        tap(res => {

        }),
        map(res => res),
        catchError(this.handleError.bind(this))
      );
  }


  Post(endPoint: string, body: any, isNew: boolean = false): Observable<any> {
    let url = `${isNew ? this.newApiUrl : this.apiUrl}${endPoint}`;
    return this.httpClient.post(url, body, this.sharedService.getHeaders())
      .pipe(
        tap(res => {

        }),
        map(res => res),
        catchError(this.handleError.bind(this))
      );
  }

  Put(endPoint: string, body: any): Observable<any> {
    let url = `${this.apiUrl}${endPoint}`;
    return this.httpClient.put(url, body, this.sharedService.getHeaders())
      .pipe(
        tap(res => {

        }),
        map(res => res),
        catchError(this.handleError.bind(this))
      );
  }


  Delete(endPoint: string, queryParams: any = {}): Observable<any> {
    let url = `${this.apiUrl}${endPoint}`;
    return this.httpClient.delete(url, {
      params: this.RemoveNull(queryParams),
      headers: this.sharedService.getHeaders().headers
    })
      .pipe(
        tap(res => {

        }),
        map(res => res),
        catchError(this.handleError.bind(this))
      );
  }


}
