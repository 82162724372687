<!-- <div class="dropdown">
  <button class="btn option-btn dropdown-toggle" type="button" id="dropdownOption"
    data-toggle="dropdown" aria-expanded="false">
    {{ 'Options' | translate }}
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownOption">
    <li><a class="dropdown-item"(click)="exportExelFile.emit()">{{ 'ExportExcel' | translate }}</a></li>

  </ul>
</div> -->


<button [disabled]="LoadingExport" class="btn-export" ngbDropdownItem (click)="exportExelFile.emit()">

  <span *ngIf="!LoadingExport"> <i class="fas fa-solid fa-file-excel"></i> &nbsp; {{ 'ExportToExcelFile' | translate }}
  </span>
  <span *ngIf="LoadingExport"> <i class="fas fa-circle-notch fa-spin"></i> &nbsp; {{ 'LoadingExport' | translate }}
  </span>

</button>
<!-- <button ngbDropdownItem> link</button> -->
