import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { formValueDates } from '../../shared/helpers';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { CashierMainData } from '../../models/classes/cashier/cashier-main-data';
import { CashierMainDataSearchCriteria } from '../../models/search-criterias/cashier-main-data-search-criteria';
import { CashierMainDataSearchResult } from '../../models/search-result/cashier-main-data-search-result';
import { ExecutionResponse } from '../../models/support/execution-response';
import { ValidationCriteria } from '../../models/support/validation-criterias/validation-criteria';
import { SharedService } from '../other/shared.service';
@Injectable({
  providedIn: 'root'
})
export class CashierMainDataService extends BaseService {
  private cashierMainDataUrl //= super.BaseUrl() + 'CashierMainData';  // URL to web api

  constructor(
    private http: HttpClient,
    private _BaseService: BaseServiceSZ,
    private sharedService: SharedService
  ) {
    super();
    this.cashierMainDataUrl = this.sharedService.ApiUrl + 'CashierMainData';

  }



  /** GETcashierMainData by id. Will 404 if id not found */
  getCashierMainData(id: String): Observable<ExecutionResponse<CashierMainData>> {
    //  getCashierMainData(id: String): ExecutionResponse<CashierMainData> {
    //const url = '${this.cashierMainDataUrl}/${id}';
    return this.http.post<ExecutionResponse<CashierMainData>>(this.cashierMainDataUrl + '/getById', id, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetchedcashierMainData id=${id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<CashierMainData>>('getCashierMainData id=${id}'))
      );
  }


  addCashierMainData(cashierMainData: CashierMainData): Observable<ExecutionResponse<CashierMainData>> {
    cashierMainData = formValueDates(cashierMainData, true);
    cashierMainData.AccountSetupId = super.getAccountSetupId();
    return this.http.post<ExecutionResponse<CashierMainData>>(this.cashierMainDataUrl + '/Create', cashierMainData, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('addedcashierMainData w/ id=${cashierMainData.Id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<CashierMainData>>('addCashierMainData id=${id}'))
      );
  }


  /* GETcashierMainData whose name contains search term */
  searchCashierMainDatas(cashierMainDataSearchCriteria: CashierMainDataSearchCriteria): Observable<CashierMainDataSearchResult> {

    if (!cashierMainDataSearchCriteria.AccountSetupId || cashierMainDataSearchCriteria.AccountSetupId <= 0) {
      cashierMainDataSearchCriteria.AccountSetupId = super.getAccountSetupId();
    }


    return this.http.post<CashierMainDataSearchResult>(this.cashierMainDataUrl + '/Get', cashierMainDataSearchCriteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetchedcashierMainData');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<CashierMainDataSearchResult>('searchCashierMainDatas id=${id}'))
      );
  }


  GetAllUserBranchesSafes(cashierMainDataSearchCriteria: CashierMainDataSearchCriteria): Observable<CashierMainDataSearchResult> {

    return this.http.post<CashierMainDataSearchResult>(this.cashierMainDataUrl + '/GetAllUserBranchesSafes', cashierMainDataSearchCriteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('GetAllUserBranchesSafes');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<CashierMainDataSearchResult>('GetAllUserBranchesSafes id=${id}'))
      );
  }

  GetByEmployeeId(emploueeId: number): Observable<CashierMainDataSearchResult> {
    return this.http.post<CashierMainDataSearchResult>(this.cashierMainDataUrl + '/GetByEmployeeId', emploueeId, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetchedcashierMainData');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<CashierMainDataSearchResult>('searchCashierMainDatas id=${id}'))
      );
  }


  CheckIfMainCashier(emploueeId?: number): Observable<ExecutionResponse<number>> {
    emploueeId = emploueeId || super.getEmployeeId();
    return this.http.post<ExecutionResponse<number>>(this.cashierMainDataUrl + '/CheckIfMainCashier', emploueeId, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetchedcashierMainData');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<ExecutionResponse<number>>('searchCashierMainDatas id=${id}'))
      );
  }

  /** DELETE: delete thecashierMainData from the server */
  deleteCashierMainData(cashierMainData: CashierMainData | number): Observable<ExecutionResponse<CashierMainData>> {
    return this.http.post<ExecutionResponse<CashierMainData>>(this.cashierMainDataUrl + '/delete', cashierMainData, super.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deletedcashierMainData id=${id}');
        this._BaseService.ValidationResult(res);
      }),

      catchError(this._BaseService.handleError<ExecutionResponse<CashierMainData>>('deleteCashierMainData'))
    );
  }

  /** PUT: update thecashierMainData on the server */
  updateCashierMainData(cashierMainData: CashierMainData): Observable<ExecutionResponse<CashierMainData>> {
    cashierMainData.AccountSetupId = super.getAccountSetupId();
    cashierMainData = formValueDates(cashierMainData, true);
    return this.http.post<ExecutionResponse<CashierMainData>>(this.cashierMainDataUrl + '/Update', cashierMainData, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('addedcashierMainData w/ id=${cashierMainData.Id}');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<ExecutionResponse<CashierMainData>>('addCashierMainData id=${id}'))
      );
  }
  IsNameUnique(validationCriteria: ValidationCriteria): Observable<ExecutionResponse<Boolean>> {
    return this.http.post<ExecutionResponse<Boolean>>(this.cashierMainDataUrl + '/IsNameUnique', validationCriteria, super.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deleted cashier id=${id}');
        this._BaseService.ValidationResult(res);
      }),

      catchError(this._BaseService.handleError<ExecutionResponse<Boolean>>('deletecashier'))
    );
  }

}

