<div class="row" [formGroup]="TPForm" class="payment-com">
  <div class="col-md-12" formGroupName="BookingOrTransactionModel">
    <div>
      <table class="table table-bordered mb-0" formArrayName="TransactionsPayments">

        <tr
          *ngFor="let item of TPForm.get('BookingOrTransactionModel').get('TransactionsPayments')['controls']; let i=index"
          [formGroupName]="i">
          <td>
            <div class="formgrouptbl">
              <ng-select [multiple]="false" [hideSelected]="true" [items]="PaymentMethods" bindLabel="NameEn"
                bindValue="Id" (change)="ChoosePayment($event, i)" formControlName="PaymentMethodId"
                name="PaymentMethodId">
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <b [hidden]="item.PaymentMethodType == 6 && i == 0">
                    {{sharedService.Lang== 'ar' ? item.NameAr : item.NameEn}}
                  </b>
                </ng-template>
              </ng-select>
            </div>
            <div *ngIf="item.value.PaymentMethodType == paymentMethodType.CreditCard">
              <div class="form-group mt-2">
                <input class="form-control mt-2 text-center" placeholder="{{'ReferenceCode' | translate}}"
                  formControlName="ReferenceCode" name="ReferenceCode">
              </div>
            </div>
            <div *ngIf="item.value.PaymentMethodType == paymentMethodType.Loyalty">
              <div class="form-group mb-0">
                <div class="" *ngIf="ClientLoyalityAmount>=0">
                  <div class="">
                    <p> {{ 'Loyalty-balance' | translate }} :
                      <span> {{ClientLoyalityAmount}} </span>
                      {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr :
                      sharedService.CurrencyNameEn}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="item.value.PaymentMethodType == paymentMethodType.Other">
              <div class="form-group ">
                <input class="form-control mt-2 text-center" placeholder="{{'ReferenceCode' | translate}}"
                  formControlName="ReferenceCode" name="ReferenceCode">
              </div>
            </div>
            <div *ngIf="item.value.PaymentMethodType == paymentMethodType.GiftCard">
              <div class="form-group ">
                <input class="form-control mt-2 text-center" placeholder="{{'GiftCardCode' | translate}}"
                  formControlName="GiftCardCode" name="GiftCardCode" required>
              </div>
            </div>
          </td>
          <td>
            <div class="row">
              <div class="col-md-6">

              </div>
              <div class="col-md-6" *ngFor="let c of AccountCurrencies | slice:0:1">
                <div class="input-group">
                  <input type="number" class="form-control" placeholder="{{'Value' | translate}}"
                    formControlName="Amount" name="Amount" (keyup)="checkKey($event,i)">
                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2">
                      {{sharedService.Lang== 'ar' ? c.Currency.NameCultureVariant : c.Currency.Name}}</span>
                  </div>
                </div>
              </div>
              <!-- [readOnly]="item.value.PaymentMethodId == 0 ||  i == 0" -->
            </div>

          </td>
          <td>
            <button type="button" class="btn btn-remove" [hidden]="i == 0" (click)="removeItem(i)">
              <i class="fas fa-times"></i>
            </button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
