import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormArray,
} from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { ClientBasicDTO } from '../../dashboard/membership/clients/models/client-basic-dto';
import { InvoicePayableByTheClient } from '../../models/classes/balances/invoice-payable-by-the-client';
import { ClientBalanceStatus } from '../../models/enums/client-balance-status';
import { TransactionType } from '../../models/enums/transaction-type';
import { ClientSearchCriteria } from '../../models/search-criterias/client-search-criteria';
import { ResponseState } from '../../models/support/response-state';
import { AppointmentService } from '../../services/appointment/appointment.service';
import { ClientBalanceService } from '../../services/balances/client-balance.service';
import { ClientService } from '../../services/client/client.service';
import { SharedService } from '../../services/other/shared.service';
import { TransactionsSecondService } from '../../services/transaction/transactions-second.service';
import { GymClientMembershipService } from '../../services/gym/gym-client-membership.service';
import { GetGymClientMembershipClassesSearchCriteria } from '../../models/search-criterias/getGymClientMembershipClassesSearchCriteria';
import { GetGymClientMembershipClassesSearchResult } from '../../models/search-result/getGymClientMembershipClassesSearchResult';
import { GetGymClientMembershipClassesModel } from '../../models/classes/gym/getGymClientMembershipClassesModel';
import { GymClassDetailsService } from '../../services/gym/gym-class-details.service';
import { GymClassDetailsSearchCriteria } from '../../models/search-criterias/gymClassDetailsSearchCriteria';
import { GymClassDetailsSearchResult } from '../../models/search-result/gymClassDetailsSearchResult';
import { GetGymClassDetailAppointmentsSearchCriteria } from '../../models/search-criterias/getGymClassDetailAppointmentsSearchCriteria';
import { GetGymClassDetailAppointmentsSearchResult } from '../../models/search-result/getGymClassDetailAppointmentsSearchResult';
import { GymClassDetailAppointment } from '../../models/classes/gym/gymClassDetailAppointment';
import { GymClientMembershipClassAppointmentService } from '../../services/gym/gym-client-membership-appointment.service';
import { GymReservationDTO } from '../../models/classes/gym/gym-reservation-dto';
import { ExecutionResponse } from '../../models/support/execution-response';
import { PaginationComponent } from '../pagination/pagination.component';
import { GetLiteForGBClientDTO } from 'src/app/models/classes/client/get-lite-for-gb-client';

@Component({
  selector: 'app-modal-assign-membership-exercises',
  templateUrl: './modal-assign-membership-exercises.component.html',
  styleUrls: ['./modal-assign-membership-exercises.component.scss'],
  providers: [DatePipe],
})
export class ModalAssignMembershipExercisesComponent implements OnInit {
  @ViewChild('pager1', { static: true }) pager1: PaginationComponent;

  @Input() GymClientMembershipId: number;

  @Output() CloseModal = new EventEmitter<boolean>();

  public GymClientMembershipClasses: GetGymClientMembershipClassesModel[];

  public SelectedGymClassId: number;
  public SelectedGymClassDetailId: number;

  GForm: UntypedFormGroup;
  btnLoading: boolean;
  public Clients: GetLiteForGBClientDTO[];
  TransactionPayPostpaidBills: UntypedFormArray;
  TransactionsPayments: UntypedFormArray;
  public modalTitle: string = '';
  public InvoicesPayableByTheClientPlaceHolder = '';
  public TotalAmountForClientBalance: number = 0;

  public clientSearchText: string;

  public ClientId: number;
  public SelectedInvoicesPayableByTheClient: InvoicePayableByTheClient[] = [];
  public SumSelectedInvoicesPayableByTheClient: number = 0;
  public SelectedInvoicesPayableByTheClientIds: number[];
  public AddAmountExcessOfPostpaidToClientBalance: boolean;

  constructor(
    public appointmentService: AppointmentService,
    public activeModal: NgbActiveModal,
    public clientService: ClientService,
    public translateService: TranslateService,
    public toaster: ToastrService,
    public gymClientMembershipAppointmentService: GymClientMembershipClassAppointmentService,
    private gymClientMembershipService: GymClientMembershipService,
    private gymClassDetailsService: GymClassDetailsService,
    public sharedService: SharedService,
    public _transactionsSecondService: TransactionsSecondService,
    private fb: UntypedFormBuilder,
    public datepipe: DatePipe,
    public clientBalanceService: ClientBalanceService
  ) {}

  ngOnInit(): void {
    //this.GFormData();
    this.GetGymClientMembershipClasses(this.GymClientMembershipId);
    this.pager1.pageSize = 10;
    this.pager1.ddlPageSize = 10;
    this.pager1.GlobalPageIndex = 0;
  }

  GetGymClientMembershipClasses(GymClientMembershipId: number) {
    this.GymClientMembershipClasses = null;
    this.SelectedGymClassId = null;

    var criteria = new GetGymClientMembershipClassesSearchCriteria();
    criteria.GymClientMembershipId = GymClientMembershipId;
    criteria.PageSize = this.pager1.pageSize;
    criteria.PagingEnabled = true;
    criteria.PageNumber = this.pager1.GlobalPageIndex;

    this.gymClientMembershipService
      .GetGymClientMembershipClasses(criteria)
      .subscribe((response: GetGymClientMembershipClassesSearchResult) => {
        if (response.State == ResponseState.Success) {
          this.GymClientMembershipClasses = response.GymClientMembershipClasses;
          this.pager1.EventsCount = response.TotalCount;
          this.pager1.setPage(this.pager1.GlobalPageIndex, false);
        }
      });
  }

  SaveGymClassDetailAppointments(
    GymClassDetailId: number,
    GymClassDetailAppointments: GymClassDetailAppointment[],
    gymClientMembershipClassId: number
  ) {
    if (
      GymClassDetailAppointments &&
      GymClassDetailAppointments.length > 0 &&
      GymClassDetailAppointments.find((gc) => gc.Assigned)
    ) {
      var criteria = new GymReservationDTO();
      criteria.GymClientMembershipClassId = gymClientMembershipClassId;
      criteria.GymClientMembershipAppointmentIds =
        GymClassDetailAppointments.filter((gc) => gc.Assigned).map(
          (gc) => gc.Id
        );
      criteria.GymClassDetailId = GymClassDetailId;

      this.gymClientMembershipAppointmentService
        .SaveGymClientMembershipClassAppointment(criteria)
        .subscribe((response: ExecutionResponse<boolean>) => {
          if (response.State == ResponseState.Success) {
            var message = this.translateService.instant(
              'GymClientMembershipAppointmentsSavedSuccessfully.'
            );
            this.toaster.success(message);
          }
        });
    }
  }

  getGymClassDetails(GymClassId: number) {
    if (this.SelectedGymClassId == GymClassId) {
      this.SelectedGymClassId = null;
    } else {
      this.SelectedGymClassId = GymClassId;
      var getGymClientMembershipClass = this.GymClientMembershipClasses.find(
        (c) => c.GymClassId == GymClassId
      );

      this.GymClientMembershipClasses.forEach((gymClientMembershipClass) => {
        gymClientMembershipClass.GymClassDetails = null;
        this.SelectedGymClassDetailId = null;
      });

      if (getGymClientMembershipClass) {
        var criteria = new GymClassDetailsSearchCriteria();
        criteria.GymClassId = GymClassId;
        criteria.ForAssignExercises = true;

        this.gymClassDetailsService
          .GetGymClassDetails(criteria)
          .subscribe((response: GymClassDetailsSearchResult) => {
            if (response.State == ResponseState.Success) {
              if (getGymClientMembershipClass) {
                getGymClientMembershipClass.GymClassDetails =
                  response.GymClassDetails;
              }
            }
          });
      }
    }
  }

  getGymClassDetailAppointments(
    GymClassId: number,
    GymClassDetailId: number,
    gymClientMembershipClassId: number
  ) {
    if (this.SelectedGymClassDetailId == GymClassDetailId) {
      this.SelectedGymClassDetailId = null;
    } else {
      this.SelectedGymClassDetailId = GymClassDetailId;

      var getGymClassDetails = this.GymClientMembershipClasses.find(
        (c) => c.GymClassId == GymClassId
      )?.GymClassDetails;

      if (getGymClassDetails) {
        getGymClassDetails.forEach((gymClassDetail) => {
          gymClassDetail.GymClassDetailAppointments = null;
        });
      }

      var criteria = new GetGymClassDetailAppointmentsSearchCriteria();
      criteria.GymClassDetailId = GymClassDetailId;
      criteria.GymClientMembershipClassId = gymClientMembershipClassId;

      this.gymClassDetailsService
        .GetGymClassDetailAppointments(criteria)
        .subscribe((response: GetGymClassDetailAppointmentsSearchResult) => {
          if (response.State == ResponseState.Success) {
            var getGymClassDetail = this.GymClientMembershipClasses?.find(
              (c) => c.GymClassId == GymClassId
            ).GymClassDetails?.find((c) => c.Id == GymClassDetailId);

            if (getGymClassDetail) {
              getGymClassDetail.GymClassDetailAppointments =
                response.GymClassDetailAppointments;
            }
          }
        });
    }
  }

  getGymClassDetailAppointmentSelected(
    GymClassId: number,
    GymClassDetailId: number,
    GymClassDetailAppointmentId: number
  ) {
    var getGymClassDetailAppointment = this.GymClientMembershipClasses?.find(
      (c) => c.GymClassId == GymClassId
    )
      .GymClassDetails?.find((c) => c.Id == GymClassDetailId)
      .GymClassDetailAppointments?.find(
        (c) => c.Id == GymClassDetailAppointmentId
      );

    if (getGymClassDetailAppointment) {
      if (getGymClassDetailAppointment.Assigned) {
        getGymClassDetailAppointment.Assigned = false;
      } else {
        getGymClassDetailAppointment.Assigned = true;
      }
    }
  }

  checkIfAnyGymClassDetailAppointmentSelected(
    GymClassId: number,
    GymClassDetailId: number
  ): boolean {
    var GymClassDetailAppointments = this.GymClientMembershipClasses?.find(
      (c) => c.GymClassId == GymClassId
    )
      .GymClassDetails?.find((c) => c.Id == GymClassDetailId)
      .GymClassDetailAppointments?.find((c) => c.Assigned);

    if (GymClassDetailAppointments) {
      return true;
    } else {
      return false;
    }
  }

  onSearch(e) {
    console.log(e);
    this.clientSearchText = e.term;
    this.GetClients();
  }

  GetClients() {
    var clientSearchCriteria: ClientSearchCriteria = new ClientSearchCriteria();
    clientSearchCriteria.AccountSetupId = this.sharedService.AccountSetupId;
    clientSearchCriteria.PagingEnabled = true;
    clientSearchCriteria.PageSize = 2;
    clientSearchCriteria.PageNumber = 0;
    clientSearchCriteria.FreeText = this.clientSearchText;

    this.clientService
      .GetClientsLite(clientSearchCriteria)
      .subscribe((response) => {
        if (response.State == ResponseState.Success) {
          if (response.TotalCount == 0) {
            var message = this.translateService.instant('NoClientsFound');
            this.toaster.warning(message);
          } else {
            this.Clients = response.Clients;
          }
        }
      });
  }

  numberOnly(event): boolean {
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }
  GFormData() {
    this.GForm = this.fb.group({
      AccountSetupId: this.sharedService.AccountSetupId,
      AddOrEdit: 1,
      BookingOrTransactionType: 2,

      BookingOrTransactionModel: this.fb.group({
        Id: 0,
        TransactionType: /*this.TransactionType*/ 0,
        BookingOrTransactionId: 0,
        BookingOrTransactionCompanyId: this.sharedService.CompanyId,
        BookingOrTransactionAccountSetupId: this.sharedService.AccountSetupId,
        BookingOrTransactionClientId: [null, Validators.required],
        BookingOrTransactionTotalPrice: 0,
        BookingOrTransactionDate: null,
        BookingOrTransactionTime: null,
        TransactionCashierDailyId: 0,
        TransactionCashierShiftId: 0,
        TransactionCashierId: 0,
        TransactionEnteredById: 0,

        TransactionStatus: 3,
        TransactionAddAmountExcessOfPostpaidToClientBalance: false,

        TransactionPayPostpaidBills: this.fb.array([]),

        TransactionsPayments: this.fb.array([this.AddTransactionsPayments()]),
      }),
    });
  }
  AddTransactionPayPostpaidBills() {
    return this.fb.group({
      TransactionId: 0,
      PaidAmount: 0,
    });
  }
  AddTransactionsPayments() {
    return this.fb.group({
      Id: 0,
      TransactionId: 0,
      PaymentTypeId: 0,
      Amount: 0,
      AmountInDefault: 0,
      CurrencyId: 0,
      AvalableCredit: null,
      AvalableClientPaymentDown: null,
      ShowAvalableCredit: false,
      ShowAvalableClientPaymentDown: null,
      showGiftcardCode: false,
      availablecredit: null,
      giftCardChoosed: false,
      voucherChoosed: false,
      giftCardCode: null,
      Code: null,
      enableCheckButton: null,
      showVisaDetailsButton: false,
      loyalityMonyChoosed: false,
      clientDownPaymentChoosed: false,
      VisaTypeId: null,
      VisaNumber: null,
      VisaTypeName: null,
      CurrencyFactor: 1,
      PaymentMethodId: 0,
      CreditCardId: null,
      ReferenceCode: null,
      GiftCardCode: null,
      GiftCardInformationId: null,
      PaymentMethodType: 0,
      TipAmount: null,
    });
  }
  InvoicesPayableByTheClientChange(event) {
    if (
      !this.SelectedInvoicesPayableByTheClientIds ||
      this.SelectedInvoicesPayableByTheClientIds.length <= 0
    ) {
      this.SelectedInvoicesPayableByTheClient = [];
      this.AddAmountExcessOfPostpaidToClientBalance = false;
    }

    // Add
    if (
      this.SelectedInvoicesPayableByTheClient.length <
      this.SelectedInvoicesPayableByTheClientIds.length
    ) {
      var AddedId = this.SelectedInvoicesPayableByTheClientIds.find(
        (s) =>
          !this.SelectedInvoicesPayableByTheClient.find(
            (x) => x.TransactionId == s
          )
      );

      /* const getInvoice: InvoicePayableByTheClient
        = Object.assign({}, this.GymClientMembershipClasses
          .find(item => item.TransactionId == AddedId)); */

      /* this.SelectedInvoicesPayableByTheClient
        .push(getInvoice); */
    }

    //Remove
    else if (
      this.SelectedInvoicesPayableByTheClient.length >
      this.SelectedInvoicesPayableByTheClientIds.length
    ) {
      var RemovedId = this.SelectedInvoicesPayableByTheClient.find(
        (s) =>
          !this.SelectedInvoicesPayableByTheClientIds.find(
            (x) => x == s.TransactionId
          )
      ).TransactionId;
      this.SelectedInvoicesPayableByTheClient =
        this.SelectedInvoicesPayableByTheClient.filter(
          (s) => s.TransactionId != RemovedId
        );
    }

    this.TotalAmountForClientBalanceKeyup(null);
  }

  public get clientBalanceStatus(): typeof ClientBalanceStatus {
    return ClientBalanceStatus;
  }
  public get transactionType(): typeof TransactionType {
    return TransactionType;
  }

  listPaid = [];

  public TotalAmountForClientBalanceKeyup(event) {
    if (
      this.TotalAmountForClientBalance &&
      this.TotalAmountForClientBalance > 0
    ) {
      this.GForm.get('BookingOrTransactionModel')
        .get('BookingOrTransactionTotalPrice')
        .patchValue(this.TotalAmountForClientBalance);

      if (
        this.SelectedInvoicesPayableByTheClient &&
        this.SelectedInvoicesPayableByTheClient.length > 0
      ) {
        var remainingTotalAddedAmountToClientBalance =
          this.TotalAmountForClientBalance;

        this.SelectedInvoicesPayableByTheClient.forEach((element) => {
          if (remainingTotalAddedAmountToClientBalance <= 0) {
            return true;
          }

          if (
            element.TotalPayableAmount <=
            remainingTotalAddedAmountToClientBalance
          ) {
            element.PaidAmount = element.TotalPayableAmount;
            remainingTotalAddedAmountToClientBalance -= element.PaidAmount;
          } else {
            element.PaidAmount = remainingTotalAddedAmountToClientBalance;
            remainingTotalAddedAmountToClientBalance -= element.PaidAmount;
          }
        });
      }
    }

    if (
      this.SelectedInvoicesPayableByTheClient &&
      this.SelectedInvoicesPayableByTheClient.length > 0
    ) {
      this.SumSelectedInvoicesPayableByTheClient =
        this.SelectedInvoicesPayableByTheClient.map((o) => o.PaidAmount).reduce(
          (a, c) => {
            return a + c;
          }
        );
    } else {
      this.SumSelectedInvoicesPayableByTheClient = 0;
    }

    const controlArray = <UntypedFormArray>(
      this.GForm.get('BookingOrTransactionModel').get('TransactionsPayments')
    );
    controlArray.controls[0]
      .get('Amount')
      .patchValue(this.TotalAmountForClientBalance);

    // this.listPaid =
    //   this.SelectedInvoicesPayableByTheClient.map(item => ({
    //     TransactionId: item.TransactionId,
    //     PaidAmount: item.PaidAmount
    //   }))
    // console.log(this.listPaid)
    // this.GForm.get('BookingOrTransactionModel').get('TransactionPayPostpaidBills').setValue(this.listPaid)
  }

  SubmitClientBalance() {
    this.btnLoading = true;

    this.GForm.get('BookingOrTransactionModel')
      .get('TransactionAddAmountExcessOfPostpaidToClientBalance')
      .setValue(this.AddAmountExcessOfPostpaidToClientBalance);

    /*  if (this.TransactionType == TransactionType.AddToAnyClientBalance) {
       this.GForm.get('BookingOrTransactionModel')
         .get('BookingOrTransactionClientId')
         .patchValue(this.ClientId);

       this.GForm.get('BookingOrTransactionModel')
         .get('TransactionType')
         .patchValue(TransactionType.AddToClientBalance);


     } */

    const control = <UntypedFormArray>(
      this.GForm.get('BookingOrTransactionModel').get(
        'TransactionPayPostpaidBills'
      )
    );
    for (let i = control.length - 1; i >= 0; i--) {
      control.removeAt(i);
    }

    this.TransactionPayPostpaidBills = this.GForm.get(
      'BookingOrTransactionModel'
    ).get('TransactionPayPostpaidBills') as UntypedFormArray;
    for (var i = 0; i < this.SelectedInvoicesPayableByTheClient.length; i++) {
      this.TransactionPayPostpaidBills.push(
        this.fb.group({
          PayForTransactionId:
            this.SelectedInvoicesPayableByTheClient[i].TransactionId,
          PaidAmount: this.SelectedInvoicesPayableByTheClient[i].PaidAmount,
        })
      );
    }

    console.log(this.GForm.value);
    this.appointmentService
      .CreateBookingOrTransaction(this.GForm.value)
      .subscribe((response: any) => {
        this.btnLoading = false;

        if (response.State == ResponseState.Success) {
          var message = '';
          /*  if (this.TransactionType == TransactionType.AddToClientBalance) {
           message = this.translateService.instant('DoneAddToClientBalanceSuccessfully.');
           this.sharedService.ToastrSuccess(message);
         } else if (this.TransactionType == TransactionType.WithdrawBalanceToTheClient) {
           message = this.translateService.instant('DoneWithdrawBalanceToTheClientSuccessfully.');
           this.sharedService.ToastrSuccess(message);
         } */

          this.CloseModal.emit(true);
        } else {
          this.sharedService.ToastrError(response.Message);
        }
      });
  }

  SetTotalAmountForClientBalance(e) {
    console.log(e);
    this.TotalAmountForClientBalance = e;
    this.TotalAmountForClientBalanceKeyup(null);
    this.GForm.get('BookingOrTransactionModel')
      .get('BookingOrTransactionTotalPrice')
      .patchValue(e);
  }

  PushTransactionsPayments() {
    this.TransactionsPayments = this.GForm.get('BookingOrTransactionModel').get(
      'TransactionsPayments'
    ) as UntypedFormArray;
    this.TransactionsPayments.push(
      this.fb.group({
        TransactionId: 0,
        PaymentTypeId: 0,
        Amount: 0,
        AmountInDefault: 0,
        CurrencyId: 0,
        AvalableCredit: null,
        AvalableClientPaymentDown: null,
        ShowAvalableCredit: false,
        ShowAvalableClientPaymentDown: null,
        showGiftcardCode: false,
        availablecredit: null,
        giftCardChoosed: false,
        voucherChoosed: false,
        giftCardCode: null,
        Code: null,
        enableCheckButton: null,
        showVisaDetailsButton: false,
        loyalityMonyChoosed: false,
        clientDownPaymentChoosed: false,
        VisaTypeId: null,
        VisaNumber: null,
        VisaTypeName: null,
        CurrencyFactor: 1,
        PaymentMethodId: 0,
        CreditCardId: null,
        ReferenceCode: null,
        GiftCardCode: null,
        GiftCardInformationId: null,
        PaymentMethodType: 0,
      })
    );
  }
  /*======== TransactionHeader=========================================================*/
  TransactionHeader;
  GetTransactionHeader() {
    let jsonData = {
      EmployeeId: this.sharedService.EmployeeId,
      AccountSetupId: this.sharedService.AccountSetupId,
    };

    this.appointmentService
      .GetTransactionHeader(jsonData)
      .subscribe((response: any) => {
        if (response.State == ResponseState.Success) {
          this.TransactionHeader = response.Result;

          this.GForm.get('BookingOrTransactionModel').patchValue({
            TransactionEnteredById: this.TransactionHeader.EmployeeId,
            TransactionCashierDailyId: this.TransactionHeader.CashierDailyId,
            TransactionCashierShiftId: this.TransactionHeader.CashierShiftId,
            TransactionCashierId: this.TransactionHeader.CashierId,
          });
        }
      });
  }
}
