import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { formValueDates } from '../../shared/helpers';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { CreditCard } from '../../models/classes/cashier/credit-cards';
import { CreditCardSearchCriteria } from '../../models/search-criterias/credit-card-search-criteria';
import { CreditCardSearchResult } from '../../models/search-result/credit-card-search-result';
import { ExecutionResponse } from '../../models/support/execution-response';
import { SharedService } from '../other/shared.service';
@Injectable({
  providedIn: 'root'
})
export class CreditCardService extends BaseService {
  private creditCardUrl //= super.BaseUrl() + 'CreditCard';  // URL to web api

  constructor(
    private http: HttpClient,
    private  _BaseService:BaseServiceSZ,
    private sharedService:SharedService
  ) {
    super();
    this.creditCardUrl =    this.sharedService.ApiUrl + 'CreditCard';

  }



  getCreditCard(id: String): Observable<ExecutionResponse<CreditCard>> {
    return this.http.post<ExecutionResponse<CreditCard>>(this.creditCardUrl + '/getById', id, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetchedcreditCard id=${id}');
          this._BaseService.ValidationResult(res);
      }),
        
        catchError(this._BaseService.handleError<ExecutionResponse<CreditCard>>('getCreditCard id=${id}'))
      );
  }


  addCreditCard(creditCard: CreditCard): Observable<ExecutionResponse<CreditCard>> {
   creditCard= formValueDates(creditCard,true);
   creditCard.MainBranchId = this.sharedService.AccountSetupId;
    return this.http.post<ExecutionResponse<CreditCard>>(this.creditCardUrl + '/Create',creditCard, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('addedcreditCard w/ id=${creditCard.Id}');
          this._BaseService.ValidationResult(res);
      }),
       
        catchError(this._BaseService.handleError<ExecutionResponse<CreditCard>>('addCreditCard id=${id}'))
      );
  }


  /* GETcreditCard whose name contains search term */
  searchCreditCards(creditCardSearchCriteria: CreditCardSearchCriteria): Observable<CreditCardSearchResult> {
   creditCardSearchCriteria.AccountSetupId = this.sharedService.AccountSetupId;
   
    return this.http.post<CreditCardSearchResult>(this.creditCardUrl + '/Get',creditCardSearchCriteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetchedcreditCard');
          this._BaseService.ValidationResult(res);
      }),
        catchError(this._BaseService.handleError<CreditCardSearchResult>('searchCreditCards id=${id}'))
      );
  }

  

  deleteCreditCard(creditCard: CreditCard | number): Observable<ExecutionResponse<CreditCard>> {
    return this.http.post<ExecutionResponse<CreditCard>>(this.creditCardUrl + '/delete',creditCard, super.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deletedcreditCard id=${id}');
        this._BaseService.ValidationResult(res);
    }),
     
      catchError(this._BaseService.handleError<ExecutionResponse<CreditCard>>('deleteCreditCard'))
    );
  }

  /** PUT: update thecreditCard on the server */
  updateCreditCard(creditCard: CreditCard): Observable<ExecutionResponse<CreditCard>> {
   creditCard.MainBranchId = this.sharedService.AccountSetupId;
   creditCard= formValueDates(creditCard,true);
    return this.http.post<ExecutionResponse<CreditCard>>(this.creditCardUrl + '/Update',creditCard, super.getHeaders())
      .pipe(
        tap(res=> {
          this._BaseService.log('addedcreditCard w/ id=${creditCard.Id}');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<ExecutionResponse<CreditCard>>('addCreditCard id=${id}'))
      );
  }
  

}

