import { Injectable } from '@angular/core';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from '../message/message.service';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { CashierEmployees } from '../../models/classes/cashier/cashier-employee';
import { EmployeeSearchCriteria } from '../../models/search-criterias/employee-search-criteria';
import { EmployeeSearchResult } from '../../models/search-result/employee-search-result';
import { ExecutionResponse } from '../../models/support/execution-response';
import { ValidationCriteria } from '../../models/support/validation-criterias/validation-criteria';
import { formValueDates } from '../../shared/helpers';
import { SharedService } from '../other/shared.service';
import { Employee } from '../../models/classes/employee/employee';
import { FingerprintReportSearchCriteria } from 'src/app/models/search-criterias/fingerprint-report-search-criteria';
import { FingerprintReportSearchResult } from 'src/app/models/search-result/fingerprint-report-search-result';
import { EmployeeLiteSearchResult } from 'src/app/models/search-result/employee-lite-search-result';
import { GetPictureSearchResult } from 'src/app/models/search-result/get-picture-search-result';
import { GetPictureSearchCriteria } from 'src/app/models/search-criterias/get-picture-search-criteria';
import { SavePictureSearchResult } from 'src/app/models/search-result/save-picture-search-result';
import { SavePictureSearchCriteria } from 'src/app/models/search-criterias/save-picture-search-criteria';






@Injectable({
  providedIn: 'root'
})
export class EmployeeService extends BaseService {

  private employeesUrl // = super.BaseUrl() + 'Employee';  // URL to web api

  constructor(
    private http: HttpClient, private messageService: MessageService,
    private  _BaseService:BaseServiceSZ,
    private sharedService:SharedService
  ) {
    super();
    this.employeesUrl =    this.sharedService.ApiUrl + 'Employee';

  }

  // /** GET employees from the server */
  // getEmployees(): Observable<Employee[]> {
  //   return this.http.get<Employee[]>(this.employeesUrl, this.sharedService.getHeaders())
  //     .pipe(
  //       tap(employees => this._BaseService.log('fetched employees')),
  //       catchError(this._BaseService.handleError('getEmployees', []))
  //     );
  // }


  /** GET employee by id. Will 404 if id not found */
  getEmployee(id: String): Observable<ExecutionResponse<Employee>> {
    //  getEmployee(id: String): ExecutionResponse<Employee> {
    //const url = '${this.employeesUrl}/${id}';
    return this.http.post<ExecutionResponse<Employee>>(this.employeesUrl + '/getById', id, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched employee id=${id}');
          this._BaseService.ValidationResult(res);
      }),

        catchError(this._BaseService.handleError<ExecutionResponse<Employee>>('getEmployee id=${id}'))
      );
  }



  /* GET employees whose name contains search term */
  searchEmployees(employeeSearchCriteria: EmployeeSearchCriteria): Observable<EmployeeSearchResult> {
  employeeSearchCriteria.AccountSetupId = this.sharedService.AccountSetupId;
    return this.http.post<EmployeeSearchResult>(this.employeesUrl +
      '/Get', employeeSearchCriteria, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched employees');
          this._BaseService.ValidationResult(res);
      }),
        //catchError(this._BaseService.handleError('getEmployees', []))
      );

  }
  GetEmployeePicture(criteria: GetPictureSearchCriteria): Observable<GetPictureSearchResult> {

    return this.http.post<GetPictureSearchResult>(this.employeesUrl + '/GetEmployeePicture', criteria, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('Fetched Employee');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<GetPictureSearchResult>('Fetched Employee  id=${id}'))
      );
  }


  SaveEmployeePicture(criteria: SavePictureSearchCriteria): Observable<SavePictureSearchResult> {

    return this.http.post<SavePictureSearchResult>(this.employeesUrl + '/SaveEmployeePicture', criteria, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('Fetched Employee');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<SavePictureSearchResult>('Fetched Employee  id=${id}'))
      );
  }
  GetEmployeesLite(employeeSearchCriteria: EmployeeSearchCriteria): Observable<EmployeeLiteSearchResult> {
    employeeSearchCriteria.AccountSetupId = this.sharedService.AccountSetupId;
      return this.http.post<EmployeeLiteSearchResult>(this.employeesUrl +
        '/GetLite', employeeSearchCriteria, this.sharedService.getHeaders())
        .pipe(
          tap(res => {
            this._BaseService.log('fetched employees');
            this._BaseService.ValidationResult(res);
        }),
        );

    }

  EmployeeFingerprintReport(reportSearchCriteria: FingerprintReportSearchCriteria): Observable<FingerprintReportSearchResult> {


    reportSearchCriteria.AccountSetupId = this.sharedService.AccountSetupId;
    return this.http.post<FingerprintReportSearchResult>(this.sharedService.ApiUrl + 'FingerprintTransaction/EmployeeFingerprintReport', reportSearchCriteria, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched clients fingerprint');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<FingerprintReportSearchResult>('addClient id=${id}'))
      );
  }

  /* GET employees whose name contains search term */
  searchEmployeesBasic(employeeSearchCriteria: EmployeeSearchCriteria): Observable<EmployeeSearchResult> {
    employeeSearchCriteria.AccountSetupId = this.sharedService.AccountSetupId;
    return this.http.post<EmployeeSearchResult>(this.employeesUrl + '/GetBasic', employeeSearchCriteria, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched employees');
          this._BaseService.ValidationResult(res);
      }),
        //catchError(this._BaseService.handleError('getEmployees', []))
      );

  }

  //////// Save methods //////////

  /** POST: add a new employee to the server */
  addEmployee(employee: Employee): Observable<ExecutionResponse<Employee>> {

    employee.AccountSetupId = this.sharedService.AccountSetupId;
    employee = formValueDates(employee,true);
    return this.http.post<ExecutionResponse<Employee>>(this.employeesUrl + '/Create', employee, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('add employee id=${id}');
          this._BaseService.ValidationResult(res);
      }),
        catchError(this._BaseService.handleError<ExecutionResponse<Employee>>('addEmployee id=${id}'))
      );
  }

  createCashierEmployee(cashierEmployees: CashierEmployees): Observable<ExecutionResponse<CashierEmployees>> {
    cashierEmployees.AccountSetupId = this.sharedService.AccountSetupId;
    return this.http.post<ExecutionResponse<CashierEmployees>>(this.employeesUrl + '/CreateCashierEmployee', cashierEmployees, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('add employee id=${id}');
          this._BaseService.ValidationResult(res);
      }),
        catchError(this._BaseService.handleError<ExecutionResponse<CashierEmployees>>('addEmployee id=${id}'))
      );
  }




  /** DELETE: delete the employee from the server */
  deleteEmployee(employee: Employee | number): Observable<ExecutionResponse<Employee>> {
    return this.http.post<ExecutionResponse<Employee>>(this.employeesUrl + '/delete', employee, this.sharedService.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deleted employee id=${id}');
        this._BaseService.ValidationResult(res);
    }),
      catchError(this._BaseService.handleError<ExecutionResponse<Employee>>('deleteEmployee'))
    );
  }

  public ExportFingerprints(criteria:FingerprintReportSearchCriteria) {

    if(criteria.From == null)
    {
      return this.http.get(`${this.sharedService.ApiUrl}FingerprintTransaction/ExportEmployeesFingerprints?AccountSetupId=${this.sharedService.AccountSetupId}&To=${criteria.To}&From=${criteria.From}&SearchText=${criteria.SearchText}`, {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.sharedService.Token,
          'Content-Type': 'application/octet-stream',
        }), responseType: 'blob',
      }).pipe(
        tap(
          data => console.log('You received data'),
          error => console.log(error)
        )
      );
    }
    else{


    return this.http.get(`${this.sharedService.ApiUrl}FingerprintTransaction/ExportEmployeesFingerprints?AccountSetupId=${this.sharedService.AccountSetupId}&From=${criteria.From}&To=${criteria.To}&SearchText=${criteria.SearchText}`, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.sharedService.Token,
        'Content-Type': 'application/octet-stream',
      }), responseType: 'blob',
    }).pipe(
      tap(
        data => console.log('You received data'),
        error => console.log(error)
      )
    );}
  }


  IsNameUnique(validationCriteria: ValidationCriteria): Observable<ExecutionResponse<Boolean>> {
    return this.http.post<ExecutionResponse<Boolean>>(this.employeesUrl + '/IsNameUnique', validationCriteria, this.sharedService.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deleted client id=${id}');
        this._BaseService.ValidationResult(res);
    }),

      catchError(this._BaseService.handleError<ExecutionResponse<Boolean>>('deleteClient'))
    );
  }

  IsMobileNumberUnique(validationCriteria: ValidationCriteria): Observable<ExecutionResponse<Boolean>> {
    return this.http.post<ExecutionResponse<Boolean>>(this.employeesUrl + '/IsMobileNumberUnique', validationCriteria, this.sharedService.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deleted client id=${id}');
        this._BaseService.ValidationResult(res);
    }),

      catchError(this._BaseService.handleError<ExecutionResponse<Boolean>>('deleteClient'))
    );
  }

  IsPhoneNumberUnique(validationCriteria: ValidationCriteria): Observable<ExecutionResponse<Boolean>> {
    return this.http.post<ExecutionResponse<Boolean>>(this.employeesUrl + '/IsPhoneNumberUnique', validationCriteria, this.sharedService.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deleted client id=${id}');
        this._BaseService.ValidationResult(res);
    }),

      catchError(this._BaseService.handleError<ExecutionResponse<Boolean>>('deleteClient'))
    );
  }

  /** PUT: update the employee on the server */
  updateEmployee(employee: Employee): Observable<ExecutionResponse<Employee>> {

    employee.AccountSetupId = this.sharedService.AccountSetupId;
    employee = formValueDates(employee,true);

    return this.http.post<ExecutionResponse<Employee>>(this.employeesUrl + '/Update', employee, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('added employee w/ id=${employee.Id}');
          this._BaseService.ValidationResult(res);
      }),
        catchError(this._BaseService.handleError<ExecutionResponse<Employee>>('addEmployee id=${id}'))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */

}
