<div id="printCurrentEmployeeItemInfo">
  <div class="modal-header">
    <h5 class="modal-title text-capitalize" id="exampleModalLabel">
      <i class="fas fa-info-circle"></i> &nbsp; {{'EmployeeItemInfo' | translate}}
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">

      <div class="col-lg-12" *ngIf="CurrentEmployeeItemInfo">
        <div class="text-start py-4">

          <div class="row">
            <div class="col-lg-12">
              <div class="formCard">
                <table class="table table-sm">

                  <tr>
                    <td>{{ 'ItemTypeName' | translate }}</td>
                    <td>
                      <span class="itemTypeNameClass"
                        [ngClass]="{'text-info': CurrentEmployeeItemInfo.ItemType == bookingOrTransactionItemType.Service,
                          'text-warning' : CurrentEmployeeItemInfo.ItemType == bookingOrTransactionItemType.Package,
                          'text-danger' : CurrentEmployeeItemInfo.ItemType == bookingOrTransactionItemType.Product,
                          'text-success' : CurrentEmployeeItemInfo.ItemType == bookingOrTransactionItemType.GymMembership }">
                        {{ CurrentEmployeeItemInfo.ItemTypeName }}</span>
                    </td>
                  </tr>

                  <tr>
                    <td>{{ 'ItemName' | translate }}</td>
                    <td>{{ CurrentEmployeeItemInfo.ItemName }}</td>
                  </tr>

                  <tr>
                    <td>{{ 'BillCodeText' | translate }}</td>
                    <td>{{ CurrentEmployeeItemInfo.BillCodeText }}</td>
                  </tr>

                  <tr>
                    <td>{{ 'TheBranchName' | translate }}</td>
                    <td>{{ CurrentEmployeeItemInfo.BranchName }}</td>
                  </tr>

                  <tr>
                    <td>{{ 'ItemDate' | translate }}</td>
                    <td>{{ CurrentEmployeeItemInfo.ItemDate | date : "yyyy-MM-dd - hh:mm a"}}</td>
                  </tr>

                </table>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="col-lg-12" *ngIf="CurrentEmployeeItemInfo?.PackageItems">

        <h4>
          {{'PackageItems' | translate}}
        </h4>
        <div class="text-start py-4">

          <div class="row">
            <div class="col-lg-12">
              <div class="table-card">
                <div class="table-responsive text-nowrap">

                  <table class="table table-stripedd table-sm table-bordered">
                    <thead class="table-head">
                      <tr>
                        <th>#</th>
                        <th>{{'ItemTypeName' | translate}}</th>
                        <th>{{'ItemName' | translate}}</th>
                        <th>{{'ItemDate' | translate}}</th>
                        <th>{{'ItemQuantity' | translate}}</th>
                        <th>{{'TotalAmount' | translate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let employeeItem of CurrentEmployeeItemInfo?.PackageItems; let i = index">
                        <td>{{ i + 1 }}</td>
                        <td>
                          <span class="itemTypeNameClass" [ngClass]="{
                            'text-info': employeeItem.ItemType == bookingOrTransactionItemType.Service,
                            'text-warning' : employeeItem.ItemType == bookingOrTransactionItemType.Package,
                            'text-danger' : employeeItem.ItemType == bookingOrTransactionItemType.Product,
                            'text-success' : employeeItem.ItemType == bookingOrTransactionItemType.GymMembership }">
                            {{ employeeItem.ItemTypeName }}
                          </span>
                        </td>
                        <td>{{employeeItem.ItemName }}</td>

                        <td>{{ employeeItem.ItemDate | date : "yyyy-MM-dd - hh:mm a"}}</td>
                        <td>{{employeeItem.ItemQuantity }}</td>
                        <td>
                          <span class="overme20 text-center"
                            title="{{ employeeItem.TotalAmount.toFixed(sharedService.GetFractionRoundCount)}}">
                            {{ employeeItem.TotalAmount.toFixed(sharedService.GetFractionRoundCount)}}
                            &nbsp;
                            ( {{sharedService.Lang== 'ar' ?
                            sharedService.CurrencyNameAr :
                            sharedService.CurrencyNameEn}} )
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>


      <div class="col-lg-12" *ngIf="!CurrentEmployeeItemInfo">
        <div class="col-md-12 text-center py-4 my-4">
          <i class="fas fa-circle-notch fa-spin fa-3x"></i>
          <h1>{{'Loading' | translate}} </h1>
        </div>
      </div>

    </div>

  </div>
  <div class="modal-footer">

    <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">
      {{'Close' | translate}}</button>

    <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="printPage()">{{'Print' |
      translate}}</button>
  </div>
</div>
