import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, NgModel } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ErrorsMessages } from '../../model/error-messge.model';

@Component({
  selector: 'app-error-form',
  templateUrl: './error-form.component.html',
  styleUrls: ['./error-form.component.scss'],
})
export class ErrorFormComponent implements OnInit, OnChanges {
  formValues: any;

  @Input() minLength: number = 8;
  @Input() maxLength: number = 24;
  @Input() name!: string;
  @Input() max: number = 20;
  @Input() min: number = 1;
  @Input() control!: AbstractControl | NgModel;
  @Input() messages: Partial<ErrorsMessages> = {};

  constructor(public readonly translate: TranslateService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
  }
}
