<div class="modal-header">
  <h4 class="modal-title"> <i class="fas fa-user"></i> &nbsp;{{ "ServiceBranchEmployeesInfo" | translate }} </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" id="modalServiceBranchEmployeesDetails">
  <div class="row">
    <div class="col-lg-12" id="printServiceBranchEmployeesDetails">
      <div *ngIf="serviceBranchEmployee.Employees && serviceBranchEmployee.Employees.length > 0">

        <h5 class="modal-title text-capitalize" id="exampleModalLabel">
          {{'ServiceBranchEmployees' | translate}}
        </h5>
        <br>
        <div class="formCard">
          <div>
            <table class="table table-sm">
              <thead>
                <tr>
                  <th>{{ 'Index' | translate }}</th>
                  <th>{{ 'Employee' | translate }}</th>

                </tr>
              </thead>
              <tbody *ngFor="let employee of serviceBranchEmployee.Employees; let i=index">
                <tr>
                  <td>
                    {{ i+1 }}
                  </td>
                  <td>
                    {{ sharedService?.Lang == 'en' ?
                    employee.NameEn : employee.NameAr }}
                  </td>

                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">{{'Close' |
    translate}}</button>
  <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="printPage()">{{'Print' |
    translate}}</button>
</div>
