import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AccountInfoService } from '../../dashboard/accounts/services/account-info.service';
import { AccountTypeService } from '../../dashboard/accounts/services/account-type.service';
import { AdminService } from '../../dashboard/admin/service/admin.service';
import { Transaction2Service } from '../../dashboard/transaction/service/transaction.service';
import { AccountInfo } from '../../models/classes/accounts/account-info';
import { AccountType } from '../../models/classes/accounts/account-type';
import { AccountTypeCriteria } from '../../models/search-criterias/account-type-criteria';
import { AccountSetupSearchCriteria } from '../../models/search-criterias/account-setup-search-criteria';
import { ResponseState } from '../../models/support/response-state';
import { SharedService } from '../../services/other/shared.service';
import { ChooseCostCenterComponent } from '../choose-cost-center/choose-cost-center.component';
import { ClientService } from 'src/app/services/client/client.service';
import { ClientSearchCriteria } from 'src/app/models/search-criterias/client-search-criteria';
import { ClientsService } from 'src/app/dashboard/membership/clients/services/clients.service';
import { ClientService as ClientServiceOld } from '../../dashboard/clients/service/client.service';
import { Client } from 'src/app/models/classes/client/client';
import { BaseServiceSZ } from 'src/app/services/other/baseService';
import { NullLogger } from '@microsoft/signalr';

@Component({
  selector: 'app-add-account',
  templateUrl: './add-account.component.html',
  styleUrls: ['./add-account.component.scss'],
})
export class AddAccountComponent implements OnInit {
  public GForm: UntypedFormGroup;
  public IsSubmit: boolean;
  public SalesTaxRate: number = 0;
  public AccountSetupId: number;
  public AccountTypes: AccountType[];
  public IsClientGroup: boolean;

  public CompanyId : number = this.sharedService.CompanyId;
  @Input() isNewAccount: boolean;
  @Input() infoDataItem: AccountInfo;
  @Input() levelNum: number;
  @Input() supplierData: any;
  @Input() employeeData: any;
  freeClientText: any;
  ClientId: any;
  freeSupplierText: any;
  empfreeText: any;

  constructor(
    private toastr: ToastrService,
    private clientService: ClientService,
    private clientServiceOld: ClientServiceOld,
    public sharedService: SharedService,
    private _BaseService: BaseServiceSZ,
    private fb: UntypedFormBuilder,
    private transactionService: Transaction2Service,
    public datepipe: DatePipe,
    public translateservice: TranslateService,
    public accountInfoService: AccountInfoService,
    public accountTypeService: AccountTypeService,
    private adminService: AdminService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.initializeForm();
    this.isNewAccount ?   this.initializeNewAccount() : this.initializeExistingAccount();
 
  }

  initializeForm(): void {
    // Initialize your form and any common properties here
    this.GFormData();
    this.IsClientGroup = false;
    this.IsSubmit = false;

    this.GetAccountTypes();
    this.getAccountGroup();

    if (this.supplierData) {
     
      this.GForm.get('NameAr').patchValue(this.supplierData.Name);
      this.GForm.get('NameEn').patchValue(this.supplierData.Name);
      this.GForm.get('DescriptionAr').patchValue(this.supplierData.Name);
      this.GForm.get('DescriptionEn').patchValue(this.supplierData.Name);
      this.GForm.get('SupplierId').patchValue(this.supplierData.Id);
    }
    if (this.employeeData) {
     
      this.GForm.get('NameAr').patchValue(this.employeeData.Name);
      this.GForm.get('NameEn').patchValue(this.employeeData.Name);
      this.GForm.get('DescriptionAr').patchValue(this.employeeData.Name);
      this.GForm.get('DescriptionEn').patchValue(this.employeeData.Name);
      this.GForm.get('EmployeeId').patchValue(this.employeeData.Id);
    }
  }

  initializeNewAccount(): void {
    // Initialize a new account setup
    if (this.infoDataItem) {
      this.GForm.patchValue({
        IsChild: true,
        LevelNumber: this.levelNum,
        ParentId: this.infoDataItem.Id || null,
        AccountTypeId: this.infoDataItem.AccountTypeId || null,
        AccountGroupId: this.infoDataItem.AccountGroupId || null,
        ParentNumber: this.infoDataItem.AccountNo || null,
      });
      const accountGroup = this.infoDataItem?.AccountGroup;
      this.accountGroupCode = accountGroup?.Code || null;
    } else {
      this.GForm.get('IsChild').patchValue(false);
    }
    this.GForm.get('IsActive').patchValue(true);
    this.pNum = this.infoDataItem?.AccountNo || null;
    this.pId = this.infoDataItem?.Id || null;
    this.GenerateNumber();
  }
  initializeExistingAccount(): void {
    if (this.infoDataItem?.AccountSetupId) {
      this.getAccountSetups();
    }
    const accountGroup = this.infoDataItem?.AccountGroup;

    if (accountGroup) {
  
      switch (accountGroup.Code) {
        case 3:
        if (this.infoDataItem.ClientId) {
          this.getClient(this.infoDataItem.ClientId);
        }
        
        if (this.infoDataItem.ClientGroupId) {
          this.IsClientGroup = true;
          this.GetClientGroup();
        }
        break;
        case 2: this.getSupplier(this.infoDataItem.SupplierId);
        break;
        case 4: this.GetEmployee(this.infoDataItem.EmployeeId);
        break;
        case 6: this.GetCashier(this.infoDataItem.CashierId);
        break;
      }

      this.GForm.get('Code').patchValue(accountGroup.Code);
      this.accountGroupCode = accountGroup.Code;
    }

    this.AccountSetupId = this.infoDataItem?.IsChild? this.infoDataItem.AccountSetupId : null;

    this.GForm.patchValue(this.infoDataItem);
  }

  get IsActive() {
    return this.GForm.get('IsActive');
  }
  get IsChild() {
    return this.GForm.get('IsChild');
  }

  get ApplyTax() {
    return this.GForm.get('ApplyTax');
  }
  get IsDebit() {
    return this.GForm.get('IsDebit');
  }
  GFormData() {
    this.GForm = this.fb.group({
      Id: 0,
      AccountNo: ['', Validators.required],
      AccountTypeId: ['', Validators.required],
      AccountGroupId: '',
      NameAr: ['', Validators.required],
      NameEn: ['', Validators.required],
      DescriptionAr: '',
      DescriptionEn: '',
      LevelNumber: 1,
      ParentId: null,
      AccountSetupId: null,
      IsChild: false,
      ClientGroupId: '',
      IsActive: true,
      IsDebit: false,
      ApplyTax: false,
      CostCenterId: null,
      CostCenterName: '',
      ClientId: '',
      EmployeeId:'',
      SupplierId: '',
      CashierId: '',
      DistributorId: '',
      TaxValue: 0,
      ParentNumber: '',
      Code: '',
    });
  }
  ChangeIsActive(e) {
    if (e.target.checked) {
      this.GForm.controls.IsActive.setValue(true);
    } else {
      this.GForm.controls.IsActive.setValue(false);
    }
  }
  ChangeIsDebit(e) {
    if (e.target.checked) {
      this.GForm.controls.IsDebit.setValue(true);
    } else {
      this.GForm.controls.IsDebit.setValue(false);
    }
  }
  openModalAccounts(AccountsetupId) {
    const modalRef = this.modalService.open(ChooseCostCenterComponent);
    modalRef.componentInstance.AccountsetupId = AccountsetupId;
    modalRef.componentInstance.isViewPlus = true;
    modalRef.componentInstance.emitService.subscribe((emmitedValue) => {
      this.GForm.get('CostCenterId').patchValue(emmitedValue.Id);
      this.GForm.get('CostCenterName').patchValue(emmitedValue.NameAr);
    });
  }
  onClientSearch(e) {
    this.freeClientText = e.term;
    this.getClient(null);
  }
  onSupplierSearch(e) {
    this.freeSupplierText = e.term;
    this.getSupplier(null);
  }
  onSearchEmp(e) {
    this.empfreeText = e.term;
    this.GetEmployee(null);

  }
  clearSearchEmp() {
    this.empfreeText = '';
    this.GetEmployee(null);
  }
  ChangeApplyTax(e) {
    const isCheckboxChecked = e.target.checked;
    const isNewRecord =
      this.GForm.value.Id === 0 || this.GForm.value.Id === null;

    this.GForm.patchValue({
      TaxValue: isCheckboxChecked && isNewRecord ? this.SalesTaxRate : 0,
      ApplyTax: isCheckboxChecked,
    });
  }
  CreateData() {
    if (!this.IsSubmit) {
      this.IsSubmit = true;
      if (this.GForm.value.Id == 0 || this.GForm.value.Id == null) {
        this.Create();
      } else {
        this.Update();
      }
    }
  }
  @Output() LoadData = new EventEmitter();
  @Output() SetClientData = new EventEmitter();

  Create() {
    this.GForm.get('IsChild').setValue(Number(this.GForm.get('IsChild').value));
    this.GForm.get('TaxValue').setValue(
      Number(this.GForm.get('TaxValue').value)
    );
    if (this.GForm.value.ParentNumber == null) {
      this.GForm.get('AccountNo').setValue(this.GForm.value.AccountNo);
      this.GForm.get('LevelNumber').setValue(1);
    } else {
      this.GForm.get('AccountNo').setValue(
        this.GForm.value.ParentNumber + this.GForm.value.AccountNo
      );
    }

    this.accountInfoService
      .CreateAccountInfo(this.GForm.value)
      .subscribe((response: any) => {
        if (response.State == ResponseState.Success) {
          this.sharedService.ToastrSuccess(response.Message);

          this.IsSubmit = false;
          this.GForm.reset();
          this.GFormData();
          this.LoadData.emit('get');
          this.sharedService.accountAdded = this.GForm.value;
        } else {
          this.IsSubmit = false;
          this.sharedService.ToastrError(response.Message);
        }
        this.activeModal.close();
      });
  }
  Update() {
    this.GForm.get('IsChild').setValue(Number(this.GForm.get('IsChild').value));
    this.GForm.get('ApplyTax').setValue(
      Number(this.GForm.get('ApplyTax').value)
    );

    this.accountInfoService
      .UpdateAccountInfo(this.GForm.value)
      .subscribe((response: any) => {
        if (response.State == ResponseState.Success) {
          this.sharedService.ToastrSuccess(response.Message);

          this.GForm.reset();
          this.GFormData();
          this.sharedService.HideModal();
          this.IsSubmit = false;
          this.LoadData.emit('get');
          // this.GetData();
        } else {
          this.sharedService.ToastrError(response.Message);
          this.IsSubmit = false;
        }
        this.activeModal.close();
      });
  }

  GetAccountTypes() {
    let criteria = new AccountTypeCriteria();
    this.accountTypeService
      .getAccountTypes(criteria)
      .subscribe((response: any) => {
        if (response) {
          this.AccountTypes = response.AccountTypes;
        }
      });
  }
  AccountGroup: any;
  getAccountGroup() {
    this.accountTypeService.getAccountGroup().subscribe((response: any) => {
      if (response) {
        this.AccountGroup = response.AccountGroups;
      }
    });
  }

  customSearchFn(term: string, item: Client) {
    term = term.toLowerCase();
    return (
      item.Name.toLowerCase().indexOf(term) > -1 ||
      item.Mobile.toLowerCase().indexOf(term) > -1 ||
      item.Mobile.toLowerCase() === term
    );
  }

  Clients: any;
  getClient(ClientId) {
    var clientSearchCriteria: ClientSearchCriteria = new ClientSearchCriteria();
    clientSearchCriteria.AccountSetupId = this.AccountSetupId;
    clientSearchCriteria.CompanyId= this.CompanyId;
    clientSearchCriteria.PagingEnabled = true;
    clientSearchCriteria.PageSize = 20;
    clientSearchCriteria.PageNumber = 0;
    clientSearchCriteria.FreeText = this.freeClientText;
    clientSearchCriteria.Id = ClientId;
    clientSearchCriteria.HasNoAccount = ClientId == null ? true : false;

    this.clientService
      .GetClientsLite(clientSearchCriteria)
      .subscribe((response) => {
        if (response.State == ResponseState.Success) {
          this.Clients = response.Clients;
        }
      });


  }
    /*======== GetEmployee=========================================================*/
    Employees;
    GetEmployee(EmpId) {
      let jsonData = {
        AccountSetupId: this.AccountSetupId,
        CompanyId: this.CompanyId,
        PageSize: 20,
        PagingEnabled: true,
        PageNumber: 0,
        Id: EmpId,
        freeText: this.empfreeText
  
      };
  
      this.adminService.GetEmployee(jsonData).subscribe((response: any) => {
        if (response.State == ResponseState.Success) {
          this.Employees = response.Employees;
        }
      });
    }
  Suppliers: any;
  getSupplier(SupplierId) {
    let JsonObj = {
      AccountSetupId: this.AccountSetupId,
      Id: SupplierId,
      freeText: this.freeSupplierText,
    };
    this.accountTypeService.getSupplier(JsonObj).subscribe((response: any) => {
      if (response) {
        this.Suppliers = response.Suppliers;
      }
    });
  }
  Distributors: any;
  getDistributors() {
    let JsonObj = {  CompanyId: this.CompanyId, AccountSetupId: this.AccountSetupId };
    this.accountTypeService
      .getDistributors(JsonObj)
      .subscribe((response: any) => {
        if (response) {
          this.Distributors = response.Distributors;
        }
      });
  }
  Cashiers: any;
  GetCashier(CashierId) {
    let jsonObj = { CompanyId: this.CompanyId, AccountSetupId: this.AccountSetupId, Id: CashierId };

    this.adminService.GetCashierMainData(jsonObj).subscribe((response: any) => {
      if (response) {
        this.Cashiers = response.Cashiers;
      }
    });
  }
  accountGroupCode: number;

  CheckData(e) {
    this.accountGroupCode = e.Code;
  }
  ChangeIsClientGroup(e) {
    if (e.target.checked) {
      this.IsClientGroup = true;
      this.GetClientGroup();
    } else {
      this.IsClientGroup = false;
    }
  }
  changeBranch(e) {
    if (e != undefined) {
      this.AccountSetupId = e.Id;
      this.GetByIdAccountSetup();
    }
    const accountGroup = this.infoDataItem?.AccountGroup;

    if (accountGroup) {
   
      switch (accountGroup.Code) {
        case 3:
        if (this.infoDataItem.ClientId) {
          this.getClient(this.infoDataItem.ClientId);
        }
        
        if (this.infoDataItem.ClientGroupId) {
          this.IsClientGroup = true;
          this.GetClientGroup();
        }
        break;
        case 2: this.getSupplier(this.infoDataItem.SupplierId);
        break;
        case 4: this.GetEmployee(this.infoDataItem.EmployeeId);
        break;
        case 6: this.GetCashier(this.infoDataItem.CashierId);
        break;
      }
    }
  }

  pNum: any;
  pId: number;
  GenerateNumber() {
    this.accountTypeService
      .GenerateNumber({ parentNumber: this.pNum, parentId: this.pId })
      .subscribe((response: any) => {
        if (response) {
          this.GForm.get('AccountNo').patchValue(response.Result);
        }
      });
  }

  ChooseGroup(e) {
    this.GForm.get('ClientGroupId').patchValue(e.Id);
  }
  ClientGroups;
  GetClientGroup() {
    let jsonObj = { CompanyId: this.CompanyId, AccountSetupId: this.AccountSetupId };
    this.clientServiceOld.GetClientGroup(jsonObj).subscribe((response: any) => {
      if (response.State == ResponseState.Success) {
        this.ClientGroups = response.ClientGroups;
      }
    });
  }
  branches;
  totalCount;
  getAccountSetups() {
    if (this.branches == undefined) {
      let accountSetupSearchCriteria = new AccountSetupSearchCriteria();
      accountSetupSearchCriteria.CompanyId = this.CompanyId;
      accountSetupSearchCriteria.PagingEnabled = false;

      this.adminService
        .getAccountSetups(accountSetupSearchCriteria)
        .subscribe((response: any) => {
          if (response.State == ResponseState.Success) {
            this.branches = response.AccountSetups;
          }
        });
    }
  }

  GetByIdAccountSetup() {
    this.transactionService
      .GetByIdAccountSetup(this.AccountSetupId)
      .subscribe((response: any) => {
        if (response) {
          if (response.State == ResponseState.Success) {
            this.SalesTaxRate =
              response.Result.SalesTaxRate == undefined
                ? 0
                : response.Result.SalesTaxRate;
            this.GForm.patchValue({ TaxValue: this.SalesTaxRate });
          }
        }
      });
  }
}
