import { SearchCriteria } from "./search-criteria";

export class CostCenterCriteria extends SearchCriteria {
   LevelNumber?: number;
   IsActive: boolean;
   TransactionType?: number;
 
   IsDebit?: boolean;
   GetChilds?: boolean;
   ParentNumber?: string;
   ParentId: number;

   IsChild:boolean;
}
