<div id="printGroupInfo">
  <div class="modal-header">
    <h5 class="modal-title text-capitalize" id="exampleModalLabel">
      <i class="fas fa-user"></i> &nbsp;
      <span>{{'GroupClients' | translate}}</span>
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-md-12" *ngIf="Clients; let Clients;">
        <div class="table-card">
          <div class="table-responsive text-nowrap">
            <table class="table table-stripedd table-sm table-bordered">
              <thead class="table-head">
                <tr>
                  <th style="width: 70%;">{{ 'ClientName' | translate }}</th>
                  <th style="width: 30%;">{{ 'ClientMobile' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of Clients; let i = index">
                  <td>
                    {{ item.Name}}
                  </td>
                  <td>
                    {{ item.Mobile}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-lg-12" *ngIf="!Clients || LoadingGetClients">
        <div class="col-md-12 text-center py-4 my-4">
          <i class="fas fa-circle-notch fa-spin fa-3x"></i>
          <h1>{{'Loading' | translate}} </h1>
        </div>
      </div>

      <div class="col-md-12" [hidden]="Clients?.length < 5">
        <app-pagination (GetSources)="GetClients()" #pager></app-pagination>
      </div>

    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">
      {{'Close' | translate}}</button>

  </div>
</div>
