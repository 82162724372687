import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { formValueDates } from '../../shared/helpers';
import { BaseServiceSZ, BaseService } from '../other/baseService';
import { CashierDaily } from '../../models/classes/cashier/cashier-daily';
import { CloseDayModel } from '../../models/classes/shifts/close-day-model';
import { CashierDailySearchCriteria } from '../../models/search-criterias/cashier-daily-searchcriteria';
import { CashierDailySearchResult } from '../../models/search-result/cashier-daily-search.result';
import { ExecutionResponse } from '../../models/support/execution-response';
import { CashierDailyGetByIdSearchCriteria } from '../../models/search-criterias/cashier-daily-get-by-id-search-criteria';


@Injectable({
  providedIn: 'root'
})
export class CashierDailyService extends BaseService {
  private cashierDailyUrl //= super.BaseUrl() + 'CashierDaily';  // URL to web api

  constructor(
    private http: HttpClient,
    private _BaseService: BaseServiceSZ
  ) {
    super();
    this.cashierDailyUrl = this._BaseService.ApiUrl + 'CashierDaily';

  }



  /** GETcashierDaily by id. Will 404 if id not found */
  getCashierDaily(criteria: CashierDailyGetByIdSearchCriteria): Observable<ExecutionResponse<CashierDaily>> {
    //  getCashierDaily(id: String): ExecutionResponse<CashierDaily> {
    //const url = '${this.cashierDailyUrl}/${id}';
    return this.http.post<ExecutionResponse<CashierDaily>>(this.cashierDailyUrl + '/GetCashierDailyById', criteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetchedcashierDaily id=${id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<CashierDaily>>('getCashierDaily id=${id}'))
      );
  }

  getCurrentCashierDaily(): Observable<ExecutionResponse<CashierDaily>> {
    //  getCashierDaily(id: String): ExecutionResponse<CashierDaily> {
    //const url = '${this.cashierDailyUrl}/${id}';
    let accountSetupId = super.getAccountSetupId();
    return this.http.post<ExecutionResponse<CashierDaily>>(this.cashierDailyUrl + '/getCurrentCashierDaily', accountSetupId, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetchedcashierDaily id=${id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<CashierDaily>>('getCashierDaily id=${id}'))
      );
  }


  addCashierDaily(cashierDaily: CashierDaily): Observable<ExecutionResponse<CashierDaily>> {
    cashierDaily = formValueDates(cashierDaily, true);
    cashierDaily.BranchId = super.getAccountSetupId();
    return this.http.post<ExecutionResponse<CashierDaily>>(this.cashierDailyUrl + '/Create', cashierDaily, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('addedcashierDaily w/ id=${cashierDaily.Id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<CashierDaily>>('addCashierDaily id=${id}'))
      );
  }


  /* GETcashierDaily whose name contains search term */
  searchCashierDailys(cashierDailySearchCriteria: CashierDailySearchCriteria): Observable<CashierDailySearchResult> {
    cashierDailySearchCriteria.AccountSetupId = super.getAccountSetupId();
    return this.http.post<CashierDailySearchResult>(this.cashierDailyUrl + '/Get', cashierDailySearchCriteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetchedcashierDaily');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<CashierDailySearchResult>('searchCashierDailys id=${id}'))
      );
  }

  /** DELETE: delete thecashierDaily from the server */
  deleteCashierDaily(cashierDaily: CashierDaily | number): Observable<ExecutionResponse<CashierDaily>> {
    return this.http.post<ExecutionResponse<CashierDaily>>(this.cashierDailyUrl + '/delete', cashierDaily, super.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deletedcashierDaily id=${id}');
        this._BaseService.ValidationResult(res);
      }),

      catchError(this._BaseService.handleError<ExecutionResponse<CashierDaily>>('deleteCashierDaily'))
    );
  }

  closeDay(closeDayModel: CloseDayModel): Observable<ExecutionResponse<boolean>> {
    closeDayModel.accountSetupId = super.getAccountSetupId();
    return this.http.post<ExecutionResponse<boolean>>(this.cashierDailyUrl + '/CloseDay', closeDayModel, super.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deletedcashierDaily id=${id}');
        this._BaseService.ValidationResult(res);
      }),

      catchError(this._BaseService.handleError<ExecutionResponse<boolean>>('deleteCashierDaily'))
    );
  }



  /** PUT: update thecashierDaily on the server */
  updateCashierDaily(cashierDaily: CashierDaily): Observable<ExecutionResponse<CashierDaily>> {
    cashierDaily.BranchId = super.getAccountSetupId();
    cashierDaily = formValueDates(cashierDaily, true);
    return this.http.post<ExecutionResponse<CashierDaily>>(this.cashierDailyUrl + '/Update', cashierDaily, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('addedcashierDaily w/ id=${cashierDaily.Id}');
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<ExecutionResponse<CashierDaily>>('addCashierDaily id=${id}'))
      );
  }

}

