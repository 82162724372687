<div class="modal-header">
  <h5 class="modal-title text-capitalize" id="exampleModalLabel">
    <i class="fas fa-solid fa-book"></i> &nbsp; {{ "AssignMembershipExercises" | translate }}
  </h5>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">

    <div class="col-lg-12">

      <div class="row" style="font-size: 15px;color: black;">

        <div class="col-lg-12" *ngIf="
        GymClientMembershipClasses &&
        GymClientMembershipClasses.length > 0">

          <div class="table-card">
            <table class="table table-stripedd table-sm table-bordered">
              <thead class="table-head">
                <tr>
                  <th style="width: 70%;">{{ 'ExerciseName' | translate }}</th>
                  <th style="width: 30%;">{{ 'Actions' | translate }}</th>

                </tr>
              </thead>
              <tbody *ngFor="let gymClientMembershipClass of GymClientMembershipClasses; let i = index"
                style="font-size: 15px;font-weight: bolder;color: black;">
                <tr>

                  <td>

                    {{ gymClientMembershipClass.GymClassGlobalName }}
                  </td>

                  <td>
                    <button type="button" class="btn btn-sm btn-detl my-0"
                      (click)="getGymClassDetails(gymClientMembershipClass.GymClassId)">
                      {{ 'ExerciseDetails' | translate }}
                    </button>
                  </td>

                </tr>

                <tr *ngIf="SelectedGymClassId == gymClientMembershipClass.GymClassId">
                  <td colspan="2">
                    <div class="">
                      <div>

                        <div class="row">

                          <div class="col-lg-12" *ngIf="gymClientMembershipClass.GymClassDetails">

                            <div class="table-card">
                              <table class="table table-stripedd table-sm table-bordered">
                                <thead class="table-head">
                                  <tr>
                                    <th style="width: 20%;">{{ 'ThisBranchName' | translate }}</th>
                                    <th style="width: 20%;">{{ 'TrainerName' | translate }}</th>
                                    <th style="width: 20%;">{{ 'WeekDayName' | translate }}</th>
                                    <th style="width: 20%;">{{ 'RoomName' | translate }}</th>
                                    <th style="width: 20%;">{{ 'Actions' | translate }}</th>

                                  </tr>
                                </thead>
                                <tbody
                                  *ngFor="let gymClassDetail of gymClientMembershipClass.GymClassDetails; let i = index"
                                  style="font-size: 15px;font-weight: bolder;color: black;">
                                  <tr>
                                    <td>
                                      {{ gymClassDetail.BranchGlobalName }}
                                    </td>
                                    <td>
                                      {{ gymClassDetail.TrainerGlobalName }}
                                    </td>
                                    <td>
                                      {{ gymClassDetail.WeekDayGlobalName | translate }}
                                    </td>
                                    <td>
                                      {{ gymClassDetail.RoomName }}
                                    </td>
                                    <td>
                                      <button type="button" class="btn btn-sm btn-detl my-0"
                                        (click)="getGymClassDetailAppointments(gymClientMembershipClass.GymClassId, gymClassDetail.Id, gymClientMembershipClass.Id)">
                                        {{ 'ExerciseAppointments' | translate }}
                                      </button>
                                    </td>

                                  </tr>

                                  <tr *ngIf="SelectedGymClassDetailId == gymClassDetail.Id">
                                    <td colspan="5">
                                      <div class="">
                                        <div>
                                          <div class="row">
                                            <div class="col-lg-12" *ngIf="gymClassDetail.GymClassDetailAppointments">
                                              <!-- (click)="getGymClassDetailAppointmentSelected(gymClientMembershipClass.GymClassId, gymClassDetail.Id,gymClassDetailAppointment.Id)" -->
                                              <button [ngClass]="gymClassDetailAppointment.Assigned ? 'gymClassDetailAppointmentSelectedBtn'
                                                 : 'gymClassDetailAppointmentBtn' "
                                                (click)="gymClassDetailAppointment.Assigned = !gymClassDetailAppointment.Assigned"
                                                *ngFor=" let gymClassDetailAppointment of gymClassDetail.GymClassDetailAppointments; let t = index"
                                                class="btn">
                                                {{gymClassDetailAppointment.TimeFromString}} -
                                                {{gymClassDetailAppointment.TimeToString}}
                                              </button>
                                            </div>
                                            <div class="col-lg-12" *ngIf="gymClassDetail.GymClassDetailAppointments
                                              && gymClassDetail.GymClassDetailAppointments.length > 0">
                                              <button Class="btn btn-sm btn-detl my-0"
                                                (click)="SaveGymClassDetailAppointments(gymClassDetail.Id, gymClassDetail.GymClassDetailAppointments, gymClientMembershipClass.Id)"
                                                [disabled]="(!checkIfAnyGymClassDetailAppointmentSelected(gymClientMembershipClass.GymClassId, gymClassDetail.Id))">
                                                {{ "SaveGymClassDetailAppointments" | translate }}
                                              </button>
                                            </div>

                                            <div class="col-lg-12" *ngIf="!gymClassDetail.GymClassDetailAppointments">
                                              <div class="col-md-12 text-center py-4 my-4">
                                                <i class="fas fa-circle-notch fa-spin fa-3x"></i>
                                                <h5>{{'Loading' | translate}} </h5>
                                              </div>
                                            </div>

                                            <div class="text-center py-4 my-4" *ngIf="gymClassDetail.GymClassDetailAppointments &&
                                              gymClassDetail.GymClassDetailAppointments.length == 0">
                                              <h4 class="py-4">{{'NoGymClassDetailAppointmentsFound' | translate}}</h4>
                                            </div>

                                          </div>



                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div class="text-center py-4 my-4"
                                *ngIf="gymClientMembershipClass.GymClassDetails.length == 0">
                                <h4 class="py-4">{{'NoGymClassDetailsFound' | translate}}</h4>
                              </div>
                            </div>

                          </div>



                          <div class="col-lg-12" *ngIf="!gymClientMembershipClass.GymClassDetails ">
                            <div class="col-md-12 text-center py-4 my-4">
                              <i class="fas fa-circle-notch fa-spin fa-3x"></i>
                              <h5>{{'Loading' | translate}} </h5>
                            </div>
                          </div>

                        </div>

                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="text-center py-4 my-4" *ngIf="GymClientMembershipClasses.length == 0">
              <h4 class="py-4">{{'NoDataFound' | translate}}</h4>
            </div>
          </div>

        </div>

        <div class="col-md-12" [hidden]="!GymClientMembershipClasses">
          <app-pagination (GetSources)="GetGymClientMembershipClasses(GymClientMembershipId)" #pager1></app-pagination>
        </div>

      </div>


    </div>
    <div class="col-lg-12" *ngIf="!GymClientMembershipClasses">
      <div class="col-md-12 text-center py-4 my-4">
        <i class="fas fa-circle-notch fa-spin fa-3x"></i>
        <h5>{{'Loading' | translate}} </h5>
      </div>
    </div>
  </div>



</div>


<div class="modal-footer">

  <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">
    {{'Close' | translate}}
  </button>

</div>
