import { Injectable, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { Subject, Observable, of } from 'rxjs';
import { AccountPayments, ClientPayment } from '../../dashboard/transaction/model/cashier-constants';
import { AccountCurrency } from '../../models/classes/account-setup/account-currency';
import { TransactionsPayments } from '../../models/classes/transactions/transactions-payments';
import { UIStateService } from '../other/ui-state.service';
@Injectable({
  providedIn: 'root'
})
export class CashierPaymentService implements OnInit {
  public cashierPaymentForm: UntypedFormGroup;
  private _totalPrice;
  private _amountToPay;
  public totalPriceChanged$ = new Subject<number>();
  public amountToPayChanged$ = new Subject<number>();
  public accountCurrency: AccountCurrency;
  constructor(private fb: UntypedFormBuilder, private _UIStateService: UIStateService) {

    this.createCashierPaymentForm();
  }
  get TransactionsPayments() { return this.cashierPaymentForm.get('TransactionsPayments'); }
  get AmoutDue() { return this.cashierPaymentForm.get('AmoutDue'); }
  getTotalNetPrice() { return this._totalPrice; }
  setTotalNetPrice(value) {

    this._totalPrice = value;
    this.totalPriceChanged$.next(value);
  }

  get AmountToPay() { return this._amountToPay; }
  set AmountToPay(value) {

    this._amountToPay = value;
    this.amountToPayChanged$.next(value);
  }
  ngOnInit() {

  }
  public getPaymentTypes(code: number): Observable<any> {

    //  let config = PaymentConstants[code];
    // let types = config.paymentType;
    let types = code == 1 ? AccountPayments : ClientPayment;
    // types = config.excludedPaymentTypesIds? types.filter(t=>!config.excludedPaymentTypesIds.includes(t.Id)):types;
    return of(types);
  }

  createCashierPaymentForm() {
    this.cashierPaymentForm = new UntypedFormGroup({
      'TransactionsPayments': this.fb.array([]),
      'AmoutDue': new UntypedFormControl(0)
    });
  }

  formNewTransactionsPayment() {
    let payments = this.TransactionsPayments.value;
    let paymentType = payments.length > 0 ? 3 : 1;
    let amountEmpty: boolean = false;
    if (payments && payments.length > 0) {
      amountEmpty = payments.filter(x => x.Amount <= 0).length > 0;
    }
    if (amountEmpty) return null;
    let payment = new TransactionsPayments();
    payment.TransactionId = 0;
    payment.PaymentTypeId = paymentType;
    this.accountCurrency = this._UIStateService.getAccountSetup().AccountCurrencies.filter(c => c.IsDefault = true)[0];
    payment.CurrencyId = this.accountCurrency.Id;
    payment.Amount = 0;
    return payment;

  }

  addTransactionsPayments(transactionsPayments: TransactionsPayments[]) {
    return transactionsPayments.length > 0 ?
      transactionsPayments.map(x => this.addTransactionsPayment(x)) :
      this.addTransactionsPayment();
  }
  addTransactionsPayment(transactionsPayment?: TransactionsPayments) {
    let formGroup = <UntypedFormGroup>this.cashierPaymentForm;
    let controls = <UntypedFormArray>formGroup.controls.TransactionsPayments;
    transactionsPayment = transactionsPayment || this.formNewTransactionsPayment();
    let newRow = this.fb.group({
      'TransactionId': new UntypedFormControl(transactionsPayment.Id),
      'PaymentTypeId': new UntypedFormControl(transactionsPayment.PaymentTypeId),
      'Amount': new UntypedFormControl(transactionsPayment.Amount),
      'AmountInDefault': new UntypedFormControl(transactionsPayment.Amount),
      'CurrencyId': new UntypedFormControl(transactionsPayment.CurrencyId),
      'AvalableCredit': new UntypedFormControl(),
      'AvalableClientPaymentDown': new UntypedFormControl(),
      'ShowAvalableCredit': new UntypedFormControl(),
      'ShowAvalableClientPaymentDown': new UntypedFormControl(),
      'showGiftcardCode': new UntypedFormControl(),
      'availablecredit': new UntypedFormControl(),
      'giftCardChoosed': new UntypedFormControl(),
      'voucherChoosed': new UntypedFormControl(),
      'giftCardCode': new UntypedFormControl(),
      'Code': new UntypedFormControl(transactionsPayment.Code),
      'enableCheckButton': new UntypedFormControl(),
      'showVisaDetailsButton': new UntypedFormControl(),
      'loyalityMonyChoosed': new UntypedFormControl(),
      'clientDownPaymentChoosed': new UntypedFormControl(),
      'VisaTypeId': new UntypedFormControl(transactionsPayment.VisaTypeId),
      'VisaNumber': new UntypedFormControl(transactionsPayment.VisaNumber),
      'VisaTypeName': new UntypedFormControl(),

      'CurrencyFactor': new UntypedFormControl(transactionsPayment.CurrencyFactor || 1)
    });

    controls.push(newRow);
    // newRow.get('Amount').valueChanges.subscribe(v=>{
    //    
    //   this.paymentAmountChange(controls.length-1,newRow);
    // })
  }

  deleteAllPayments() {
    let control = <UntypedFormArray>this.TransactionsPayments;
    for (var i = control.controls.length; i >= 0; i--) {
      control.removeAt(i)
    }
    this.cashierPaymentForm.get('AmoutDue').setValue(0);

  }

}
