import { Injectable } from '@angular/core';
import { MessageService } from '../message/message.service';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { AppTags } from '../../models/classes/app/app-tags';
import { AppTagsSearchCriteria } from '../../models/search-criterias/app-tags-search-criteria';
import { AppTagsSearchResult } from '../../models/search-result/app-tags-search-result';
import { ExecutionResponse } from '../../models/support/execution-response';

@Injectable({
  providedIn: 'root'
})
export class AppTagsService extends BaseService {

  private AppTagsUrl 

  constructor(
    private http: HttpClient, private messageService: MessageService,
    private  _BaseService:BaseServiceSZ
  ) {
    super();
    this.AppTagsUrl =    this._BaseService.ApiUrl + 'AppTags';

  }

  searchAppTags(appTagsSearchCriteria: AppTagsSearchCriteria): Observable<AppTagsSearchResult> {
    
    return this.http.post<AppTagsSearchResult>(this.AppTagsUrl + '/Get', appTagsSearchCriteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('fetched AppTags');
          this._BaseService.ValidationResult(res);
      }),
        
        
      );

  }


  addAppTag(appTags: AppTags): Observable<ExecutionResponse<AppTags>> {
    return this.http.post<ExecutionResponse<AppTags>>(this.AppTagsUrl + '/Create', appTags, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('add AppTag id=${id}');
          this._BaseService.ValidationResult(res);
      }),
        catchError(this._BaseService.handleError<ExecutionResponse<AppTags>>('addAppTag id=${id}'))
      );
  }


}
