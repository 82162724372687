<div fxLayout="row" fxLayoutAlign="start center" class="gap-5">
  <img src="assets/img/Lang.svg" title="Language" alt="" />
  <select
    (change)="onChangeLang($event.target.value)"
    [value]="selectedLanguage"
  >
    <option value="en">English</option>
    <option value="ar">العربية</option>
  </select>
</div>
