import { Injectable } from '@angular/core';
import { BaseService, BaseServiceSZ } from './baseService';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../message/message.service';
import { Observable } from 'rxjs';
import { SharedService } from './shared.service';
import { HttpBaseService } from './httpbase.service';
import { tap, catchError } from 'rxjs/operators';
import { Supplier } from '../../models/classes/supplier/supplier';
import { SupplierSearchCriteria } from '../../models/search-criterias/supplier-search-criteria';
import { SupplierSearchResult } from '../../models/search-result/supplier-search-result';
import { ExecutionResponse } from '../../models/support/execution-response';
import { ValidationCriteria } from '../../models/support/validation-criterias/validation-criteria';

@Injectable({
  providedIn: 'root'
})
export class SupplierService extends BaseService {

  private suppliersUrl // = super.BaseUrl() + 'Supplier';  // URL to web api

  constructor(
    private httpBaseService: HttpBaseService,
    private http: HttpClient, private messageService: MessageService,
    private  _BaseService:BaseServiceSZ,
    private sharedService:SharedService

  ) {
    super();
    this.suppliersUrl =    this.sharedService.ApiUrl + 'Supplier';

  }

  // /** GET suppliers from the server */
  // getSuppliers(): Observable<Supplier[]> {
  //   return this.http.get<Supplier[]>(this.suppliersUrl, this.sharedService.getHeaders())
  //     .pipe(
  //       tap(suppliers => this._BaseService.log('fetched suppliers')),
  //       catchError(this._BaseService.handleError('getSuppliers', []))
  //     );
  // }

  /*======================= GetSupplierGroup =========================*/
  GetSupplierGroup(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('SupplierGroup/Get', body)
  }
  CreateSupplierGroup(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('SupplierGroup/Create', body)
  }
  UpdateSupplierGroup(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('SupplierGroup/Update', body)
  }
  DeleteSupplierGroup(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('SupplierGroup/Delete', body)
  }
  /** GET supplier by id. Will 404 if id not found */
   getSupplier(id: number): Observable<ExecutionResponse<Supplier>> {
   //  getSupplier(id: String): ExecutionResponse<Supplier> {
    //const url = '${this.suppliersUrl}/${id}';
    return this.http.post<ExecutionResponse<Supplier>>(this.suppliersUrl + '/getById', id, this.sharedService.getHeaders())
      .pipe(
        tap(_ => this._BaseService.log('fetched supplier id=${id}')),
        catchError(this._BaseService.handleError<ExecutionResponse<Supplier>>('getSupplier id=${id}'))
      );
  }



  /* GET suppliers whose name contains search term */
  searchSuppliers(supplierSearchCriteria: SupplierSearchCriteria): Observable<SupplierSearchResult> {
    supplierSearchCriteria.AccountSetupId = this.sharedService.AccountSetupId;
    return this.http.post<SupplierSearchResult>(this.suppliersUrl + '/Get', supplierSearchCriteria, this.sharedService.getHeaders())
      .pipe(
        tap(suppliers => this._BaseService.log('fetched suppliers')),
        //catchError(this._BaseService.handleError('getSuppliers', []))
      );

    //if (!term.trim()) {
    //  // if not search term, return empty supplier array.
    //  return of([]);
    //}
    //return this.http.get<Supplier[]>('${this.suppliersUrl}/?name=${term}').pipe(
    //  tap(_ => this._BaseService.log('found suppliers matching "${term}"')),
    //  catchError(this._BaseService.handleError<Supplier[]>('searchSuppliers', []))
    //);
  }

  //////// Save methods //////////

  /** POST: add a new supplier to the server */
  addSupplier(supplier: Supplier): Observable<ExecutionResponse<Supplier>> {

    supplier.AccountSetupId = this.sharedService.AccountSetupId;
    return this.http.post<ExecutionResponse<Supplier>>(this.suppliersUrl + '/Create', supplier, this.sharedService.getHeaders())
      .pipe(
        tap(res => {

          this._BaseService.log('added supplier w/ id=${supplier.Id}');
          this._BaseService.ValidationResult(res);
      }),

        catchError(this._BaseService.handleError<ExecutionResponse<Supplier>>('addSupplier id=${id}'))
      );
  }

  /** DELETE: delete the supplier from the server */
  deleteSupplier(supplier: Supplier | number): Observable<ExecutionResponse<Supplier>> {
    return this.http.post<ExecutionResponse<Supplier>>(this.suppliersUrl + '/delete', supplier, this.sharedService.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deleted supplier id=${id}');
        this._BaseService.ValidationResult(res);
    }),

      catchError(this._BaseService.handleError<ExecutionResponse<Supplier>>('deleteSupplier'))
    );
  }

  IsNameUnique(validationCriteria: ValidationCriteria): Observable<ExecutionResponse<Boolean>> {
    return this.http.post<ExecutionResponse<Boolean>>(this.suppliersUrl + '/IsNameUnique', validationCriteria, this.sharedService.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deleted client id=${id}');
        this._BaseService.ValidationResult(res);
    }),

      catchError(this._BaseService.handleError<ExecutionResponse<Boolean>>('deleteClient'))
    );
  }

  IsMobileNumberUnique(validationCriteria: ValidationCriteria): Observable<ExecutionResponse<Boolean>> {
    return this.http.post<ExecutionResponse<Boolean>>(this.suppliersUrl + '/IsMobileNumberUnique', validationCriteria, this.sharedService.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deleted client id=${id}');
        this._BaseService.ValidationResult(res);
    }),

      catchError(this._BaseService.handleError<ExecutionResponse<Boolean>>('deleteClient'))
    );
  }

  IsPhoneNumberUnique(validationCriteria: ValidationCriteria): Observable<ExecutionResponse<Boolean>> {
    return this.http.post<ExecutionResponse<Boolean>>(this.suppliersUrl + '/IsPhoneNumberUnique', validationCriteria, this.sharedService.getHeaders()).pipe(
      tap(res => {
        this._BaseService.log('deleted client id=${id}');
        this._BaseService.ValidationResult(res);
    }),

      catchError(this._BaseService.handleError<ExecutionResponse<Boolean>>('deleteClient'))
    );
  }

  /** PUT: update the supplier on the server */
  updateSupplier(supplier: Supplier): Observable<ExecutionResponse<Supplier>> {
    supplier.AccountSetupId = this.sharedService.AccountSetupId;
    return this.http.post<ExecutionResponse<Supplier>>(this.suppliersUrl + '/Update', supplier, this.sharedService.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('added supplier w/ id=${supplier.Id}');
          this._BaseService.ValidationResult(res);

      }),
        catchError(this._BaseService.handleError<ExecutionResponse<Supplier>>('addSupplier id=${id}'))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */

}
