import { Pipe, PipeTransform } from '@angular/core';
import { SharedService } from 'src/app/services/other/shared.service';

@Pipe({
  name: 'RoundNearestNumber',
  pure: false
})
export class RoundNearestNumberPipe implements PipeTransform {

  constructor(public sharedService: SharedService) {

  }

  transform(number: number): number {

    if (!number || !Number(number)) {
      return (number && Number(number) ? Number(Number(number).toFixed(this.sharedService.GetFractionRoundCount)) : number);
    }

    let outNumber = Math.trunc(number);

    var diff = number - outNumber;

    if (diff >= .5)
      outNumber += 1;

    return outNumber;
  }
}
