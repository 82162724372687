<div class="row" [formGroup]="THForm">
  <div class="col-md-12" formGroupName="Transaction">
    <div class="row" *ngIf="TransactionHeader">
      <div class="form-group col-md-4 col-sm-4 col-12">
        <label>{{ 'LinkedEmployee' | translate }}</label>
        <input type="text" readonly hidden class="form-control" formControlName="EnteredById" name="EnteredById">
        <input type="text" readonly class="form-control" [value]="TransactionHeader?.Employee?.Name">
      </div>
      <div class="form-group col-md-4 col-sm-4 col-6">
        <label>{{ 'Cashier' | translate }}</label>
        <input type="text" readonly hidden class="form-control"  formControlName="CashierId" name="CashierId">
        <input type="text" readonly class="form-control" [value]="TransactionHeader?.Cashier?.NameAr">

        <!-- <select class="form-control" formControlName="CashierId" name="CashierId">
          <option value="null">{{ 'Cashier' | translate }}</option>
          <option *ngFor="let c of Cashiers" value="{{c.Id}}">{{c.NameAr}}</option>
        </select> -->
      </div>
      <div class="form-group col-md-4 col-sm-4 col-6">
        <label>{{ 'CashierDaily' | translate }}</label>
        <input type="text" readonly hidden class="form-control" formControlName="CashierDailyId" name="CashierDailyId">
        <input type="text" readonly class="form-control"
          [value]="TransactionHeader?.CashierDailyDate | date:'yyyy-MM-dd' ">
      </div>
    </div>
  </div>
</div>
