import { SearchCriteria } from "./search-criteria";
import { ResourceStatus } from '../enums/resource-status';

export class EmployeeSearchCriteria extends SearchCriteria {
  public Name: string;
  public AccountSetupId: number;
  public Id: number;
  public ShowInBookingScreen: boolean;
  public ShowOnline: boolean;
  public Gender: boolean;
  public Status: ResourceStatus;

  public IdentityNumber: string;
  public employeesIds: number[];

  public ShowActiveEmployes: boolean;
}

