import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { TranslateModule } from '@ngx-translate/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSliderModule } from '@angular/material/slider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ErrorFormComponent } from './components/error-form/error-form.component';
import { LanguageComponent } from './components/language/language.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { AlertsComponent } from './components/alerts/alerts.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { CongratulationsComponent } from './components/congratulations/congratulations.component';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { StringOnlyDirective } from './directives/string-only.directive';
import { CORE_CONFIG, CoreModuleConfig } from './model/core-module-config';
import { DoneSuccessfullyComponent } from './components/done-successfully/done-successfully.component';
import { MatTooltipModule } from '@angular/material/tooltip';

const MODULES = [
  FlexLayoutModule,
  MatMenuModule,
  MatIconModule,
  MatInputModule,
  MatFormFieldModule,
  MatOptionModule,
  MatButtonModule,
  MatCheckboxModule,
  MatDialogModule,
  MatSelectModule,
  MatRadioModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSliderModule,
  MatProgressBarModule,
  MatSlideToggleModule,
  NgxMaterialTimepickerModule,
  MatTooltipModule,
];

const COMPMNENTS = [
  ErrorFormComponent,
  LanguageComponent,
  UploadFileComponent,
  AlertsComponent,
  NotFoundComponent,
  CongratulationsComponent,
  DoneSuccessfullyComponent,
];

const DIRECTIVES = [NumberOnlyDirective, StringOnlyDirective];

@NgModule({
  declarations: [...COMPMNENTS, ...DIRECTIVES],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    ...MODULES,
  ],
  exports: [
    ...MODULES,
    ...COMPMNENTS,
    ...DIRECTIVES,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class CoreModule {
  static withConfig(config: CoreModuleConfig): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [{ provide: CORE_CONFIG, useValue: config }],
    };
  }
}
