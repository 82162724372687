<div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{modalHeader}}</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p><strong>{{modalBody}}</strong></p>
    <!-- <span class="text-danger">This operation can not be undone.</span> -->
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel')">Cancel</button>
    <button type="button" ngbAutofocus class="btn btn-suvccess" (click)="modal.close('ok')">Ok</button>
  </div>