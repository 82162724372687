import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AutoRegistrationService,
  BaseForm,
  ComponentBase,
  SharedService,
  VerifyMobileNumberType,
} from 'core';
import { SalesChatService } from 'projects/core/src/lib/services/sales-chat.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-verify-indentity',
  templateUrl: './verify-indentity.component.html',
  styleUrls: ['./verify-indentity.component.scss'],
})
export class VerifyIndentityComponent
  extends ComponentBase
  implements BaseForm, OnInit
{
  form!: FormGroup;
  verifyMobileNumberType = VerifyMobileNumberType;
  queryParamsData: {
    user: string | null;
    verifyType: VerifyMobileNumberType | null;
    RegistrationCycleLogId: number | null;
    requestType?: string;
  } = {
    user: null,
    verifyType: null,
    RegistrationCycleLogId: null,
    requestType: null,
  };

  constructor(
    private salesChatService: SalesChatService,
    public readonly sharedService: SharedService,
    private readonly autoRegistrationSer: AutoRegistrationService,
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.salesChatService.chatScript();
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        this.queryParamsData.user = params['user'];
        this.queryParamsData.requestType = params['requestType'];
        this.queryParamsData.RegistrationCycleLogId =
          params['RegistrationCycleLogId'];
      });
  }

  onSelectVerifyType(verifyType: VerifyMobileNumberType): void {
    this.queryParamsData.verifyType = verifyType;

    if (this.queryParamsData.requestType === 'auto') {
      this.autoRegistrationSer
        .resendCode({
          VerifyMobileNumberType: this.queryParamsData.verifyType,
          RegistrationCycleLogId:
            this.queryParamsData.RegistrationCycleLogId.toString(),
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          this.router.navigate(['../verify'], {
            skipLocationChange: true,
            queryParams: this.queryParamsData,
          });
        });
    } else {
      this.autoRegistrationSer
        .forgetPassword({
          UserName: this.queryParamsData.user!,
          VerifyMobileNumberType: this.queryParamsData.verifyType,
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          this.router.navigate(['../verify'], {
            skipLocationChange: true,
            queryParams: this.queryParamsData,
          });
        });
    }
  }

  initForm(): void {}

  onSubmit(): void {}
}
