import { GiftCardType } from "../enums/gift-card-type";
import { SearchCriteria } from "./search-criteria";

export class GiftCardInformationSearchCriteria extends SearchCriteria {

  public SaleBranchIds: number[];
  public UsedInBranchIds: number[];

  public ClientGiftCardId?: number;

  public GlobalStartDateFrom: string;
  public GlobalStartDateTo: string;

  public GlobalEndDateFrom: string;
  public GlobalEndDateTo: string;

  public ClientIds: number[];

  public GiftCardType?: GiftCardType;


  public GiftCardCode: string;


}
