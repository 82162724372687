<div class="row">

  <div class="col-12">
    <div class="attachments" *ngIf="!vedioOnly">
      <h4>{{ title | translate }}</h4>
      <ng-container *ngFor="let attachment of attachmentsFiles; let i = index">
        <div class="attachment">
          <ng-container [ngSwitch]="attachment?.FileType">
            <img *ngSwitchCase="FILE_TYPE.IMAGE" [src]="attachment?.FilePath" width="100" height="100"
              (click)="openLightbox(i)" />
            <a [href]=""></a>
            <img *ngSwitchCase="FILE_TYPE.FILE" src="assets/img/PDF.png" width="100" height="100"
              (click)="pdfInput.click()" />

            <a #pdfInput [hidden]="attachment?.FileType != FILE_TYPE.FILE" [href]="attachment?.FilePath" download>
              {{'Download' | translate}}
            </a>
          </ng-container>

          <p></p>

          <p *ngIf="!previewOnly">{{ attachment?.FileName }}</p>

          <p *ngIf="previewOnly && attachment.AttachmentTypeName && attachment.AttachmentTypeName.length > 0">
            {{'AttachmentType' | translate}} - {{
            attachment.AttachmentTypeName }}</p>

          <p *ngIf="previewOnly && attachment.ExpirationDate">
            {{'AttachmentExpirationDate' | translate}} - {{
            attachment.ExpirationDate | date: 'yyyy-MM-dd'}}</p>

          <p *ngIf="previewOnly && attachment.FileNotes && attachment.FileNotes.length > 0">
            {{'Notes' | translate}} - {{
            attachment.FileNotes }}</p>

          <ng-select *ngIf="!previewOnly && UploadType == uploadType.Employees" style="width: 100%;"
            placeholder="{{'AttachmentType' | translate }}" name="AttachmentType" [multiple]="false"
            [items]="AttachmentTypes" [(ngModel)]="attachment.AttachmentTypeId" bindLabel="GlobalName" bindValue="Id">
          </ng-select>

          <label *ngIf="!previewOnly && UploadType == uploadType.Employees"
            style="text-transform: capitalize;  font-size: 14px;  color: #7e7e7e;  font-weight: 1;">
            {{ 'AttachmentExpirationDate' | translate }}
          </label>

          <input *ngIf="!previewOnly && UploadType == uploadType.Employees" type="date" class="form-control"
            [(ngModel)]="attachment.ExpirationDate">

          <textarea *ngIf="!previewOnly" class="form-control" [(ngModel)]="attachment.FileNotes"
            placeholder="{{'Notes' | translate}}"></textarea>

          <mat-icon *ngIf="!previewOnly" color="error" matTooltip="Remove" maTooltipPosition="above"
            (click)="removeAttachment(i)">{{'Clear' | translate}}</mat-icon>

        </div>
      </ng-container>
    </div>

  </div>

  <div class="col-12">
    <div class="upload-buttons" *ngIf="!previewOnly">

      <button *ngIf="!vedioOnly" type="button" (click)="fileInput.click()" class="btn btnUpload">
        <i class="fas fa-cloud-upload-alt"></i>
        {{ 'UploadPDF' | translate }}
        <i class="fa fa-spinner fa-spin" *ngIf="uploadPDFLoading"></i>
      </button>
      <button *ngIf="!vedioOnly" type="button" (click)="imageInput.click()" class="btn btnUpload">
        <i class="fas fa-cloud-upload-alt"></i>
        {{ 'UploadPhoto' | translate }}
        <i class="fa fa-spinner fa-spin" *ngIf="uploadImageLoading"></i>
      </button>

      <input type="file" accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
    text/plain, application/pdf" #fileInput style="display: none" (change)="uploadAttachment($event, fileType.FILE)" />
      <input type="file" accept="image/x-png,image/gif,image/jpeg" #imageInput style="display: none"
        (change)="uploadAttachment($event, fileType.IMAGE)" />

    </div>
  </div>
</div>
