import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ClientBalanceDetail } from '../../models/classes/balances/client-balance-detail';
import { ResponseState } from '../../models/support/response-state';
import { ClientBalanceDetailService } from '../../services/balances/client-balance-detail.service';
import { ClientBalanceService } from '../../services/balances/client-balance.service';
import { SharedService } from '../../services/other/shared.service';
import { TransactionsSecondService } from '../../services/transaction/transactions-second.service';
import { PaginationComponent } from '../pagination/pagination.component';
import { ClientInfoDTO } from 'src/app/models/classes/client/client-info-dto';
import { GetClientInfoSearchCriteria } from 'src/app/models/search-criterias/get-client-info-search-criteria';
import { ExecutionResponse } from 'src/app/models/support/execution-response';
import { ClientService } from 'src/app/services/client/client.service';
import { GetClientBookingsSearchCriteria } from 'src/app/models/search-criterias/get-client-bookings-search-criteria';
import { ClientBookingDTO } from 'src/app/models/classes/client/client-booking-dto';
import { Gender } from 'src/app/models/enums/gender';
import { BookingStatus } from 'src/app/models/enums/booking-status';
import { PaidStatus } from 'src/app/models/enums/paid-status';
import { GetClientSalesSearchCriteria } from 'src/app/models/search-criterias/get-client-sales-search-criteria';
import { ClientSaleDTO } from 'src/app/models/classes/client/client-sale-dto';
import { GetClientGroupsSearchCriteria } from 'src/app/models/search-criterias/get-client-groups-search-criteria';
import { GroupSearchCriteria } from 'src/app/models/search-criterias/group-search-criteria';
import { Group } from 'src/app/models/classes/client/group';
import { GroupSearchResult } from 'src/app/models/search-result/group-search-result';
import { GroupService } from 'src/app/services/client/group.service';
import { ToastrService } from 'ngx-toastr';
import { EditClientGroupsModel } from 'src/app/models/classes/client/edit-client-groups-model';

@Component({
  selector: 'app-modal-client-profile',
  templateUrl: './modal-client-profile.component.html',
  styleUrls: ['./modal-client-profile.component.scss'],
  providers: [DatePipe]

})
export class ModalClientProfileComponent implements OnInit {

  @ViewChild('bookingsPager', { static: true }) bookingsPager: PaginationComponent;
  @ViewChild('salesPager', { static: true }) salesPager: PaginationComponent;

  @Input() ClientId: number;
  @Input() ClientName: string;

  public ClientBalanceDetails: ClientBalanceDetail[] = [];

  public LoadingGetClientInfo: boolean = false;
  public LoadingGetBookings: boolean = false;
  public LoadingGetSales: boolean = false;
  public LoadingGetClientGroups: boolean = false;
  public LoadingSaveGroups: boolean = false;

  public ClientInfo: ClientInfoDTO;
  public selectedTab: number = 1;


  public bookingsTotalCount: number;
  public salesTotalCount: number;

  public freeTextBookings: string;
  public ClientBookings: ClientBookingDTO[];
  public BookingDateFrom: Date;
  public BookingDateTo: Date;
  public CurrencyName: string;

  public freeTextSales: string;
  public ClientSales: ClientSaleDTO[];
  public SaleDateFrom: Date;
  public SaleDateTo: Date;


  public ClientGroupsIds: number[];
  public TempClientGroupsIds: number[];
  public Groups: Group[];
  public groupsFreeText: string;
  public ClientGroupsCount: number;

  constructor(public activeModal: NgbActiveModal,
    public translate: TranslateService,
    public clientService: ClientService,
    public sharedService: SharedService,
    public _transactionsSecondService: TransactionsSecondService,
    private fb: UntypedFormBuilder, private toastr: ToastrService,
    public datepipe: DatePipe, public groupService: GroupService,
    public clientBalanceDetailService: ClientBalanceDetailService,
    public clientBalanceService: ClientBalanceService) { }

  ngOnInit(): void {

    this.tabClick(1);

    this.CurrencyName =
      this.sharedService.Lang == 'ar'
        ? this.sharedService.CurrencyNameAr
        : this.sharedService.CurrencyNameEn;

  }

  public get gender(): typeof Gender {
    return Gender;
  }
  public get bookingStatus(): typeof BookingStatus {
    return BookingStatus;
  }
  public get paidStatus(): typeof PaidStatus {
    return PaidStatus;
  }

  GetClientInfo() {

    this.ClientInfo = null;
    this.LoadingGetClientInfo = true;
    let criteria = new GetClientInfoSearchCriteria();
    criteria.Id = this.ClientId;

    this.clientService
      .GetClientInfo(criteria)
      .subscribe((response: ExecutionResponse<ClientInfoDTO>) => {

        if (response.State == ResponseState.Success) {
          this.ClientInfo = response.Result;
        } else {
          this.sharedService.ToastrError(response.Message);
        }
        this.LoadingGetClientInfo = false;
      });
  }

  divName
  printPage() {

    this.divName = 'printClientProfile';
    console.log(this.divName)

    let printContents = document.getElementById(this.divName).innerHTML;
    let originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  }

  BookingReciveFreeText(e) {
    this.freeTextBookings = e
    this.GetClientBookingsWithReset();
  }
  SalesReciveFreeText(e) {
    this.freeTextSales = e
    this.GetClientSalesWithReset();
  }

  tabClick(value) {

    if (value == 1)
      this.GetClientInfo();
    else if (value == 2)
      this.GetClientBookingsWithReset();
    else if (value == 3)
      this.GetClientSalesWithReset();
    else if (value == 4)
      this.GetClientGroups();

    this.selectedTab = value;

  }

  GetClientBookingsWithReset() {

    this.bookingsPager.pageSize = 10;
    this.bookingsPager.ddlPageSize = 10;
    this.bookingsPager.GlobalPageIndex = 0;

    this.GetClientBookings();

  }
  GetClientBookings() {

    this.LoadingGetBookings = true;

    let criteria = new GetClientBookingsSearchCriteria();
    criteria.Id = this.ClientId;
    criteria.FreeText = this.freeTextBookings;
    criteria.PageSize = this.bookingsPager.pageSize;
    criteria.PagingEnabled = true;
    criteria.PageNumber = this.bookingsPager.GlobalPageIndex;
    criteria.DateFrom = this.BookingDateFrom;
    criteria.DateTo = this.BookingDateTo;

    this.clientService
      .GetClientBookings(criteria)
      .subscribe((response: ExecutionResponse<ClientBookingDTO[]>) => {

        if (response.State == ResponseState.Success) {
          this.ClientBookings = response.Result;

          this.bookingsPager.EventsCount = response.TotalCount;
          this.bookingsTotalCount = response.TotalCount;
          this.bookingsPager.setPage(this.bookingsPager.GlobalPageIndex, false);

        } else {
          this.sharedService.ToastrError(response.Message);
        }

        this.LoadingGetBookings = false;
      });
  }

  GetClientSalesWithReset() {

    this.salesPager.pageSize = 10;
    this.salesPager.ddlPageSize = 10;
    this.salesPager.GlobalPageIndex = 0;

    this.GetClientSales();

  }
  GetClientSales() {

    this.LoadingGetSales = true;
    let criteria = new GetClientSalesSearchCriteria();
    criteria.Id = this.ClientId;
    criteria.FreeText = this.freeTextSales;
    criteria.PageSize = this.salesPager.pageSize;
    criteria.PagingEnabled = true;
    criteria.PageNumber = this.salesPager.GlobalPageIndex;
    criteria.DateFrom = this.SaleDateFrom;
    criteria.DateTo = this.SaleDateTo;

    this.clientService
      .GetClientSales(criteria)
      .subscribe((response: ExecutionResponse<ClientSaleDTO[]>) => {

        if (response.State == ResponseState.Success) {
          this.ClientSales = response.Result;

          this.salesPager.EventsCount = response.TotalCount;
          this.salesTotalCount = response.TotalCount;
          this.salesPager.setPage(this.salesPager.GlobalPageIndex, false);

        } else {
          this.sharedService.ToastrError(response.Message);
        }
        this.LoadingGetSales = false;
      });
  }

  GetClientGroups() {

    this.ClientGroupsIds = null;
    this.ClientGroupsCount = null;

    this.LoadingGetClientGroups = true;

    let criteria = new GetClientGroupsSearchCriteria();
    criteria.Id = this.ClientId;

    this.clientService
      .GetClientGroups(criteria)
      .subscribe((response: ExecutionResponse<number[]>) => {

        if (response.State == ResponseState.Success) {
          this.ClientGroupsIds = this.TempClientGroupsIds = response.Result;
          this.ClientGroupsCount = response.TotalCount;
          this.GetGroups(this.ClientGroupsIds);
        }
        this.LoadingGetClientGroups = false;
      });
  }

  CheckAllowSave(): boolean {


    const difference_ClientGroupsIds = this.ClientGroupsIds?.filter(x => !this.TempClientGroupsIds?.includes(x));
    const difference_TempClientGroupsIds = this.TempClientGroupsIds?.filter(x => !this.ClientGroupsIds?.includes(x));

    return (difference_ClientGroupsIds && difference_ClientGroupsIds.length > 0) ||
      (difference_TempClientGroupsIds && difference_TempClientGroupsIds.length > 0);
  }

  GetGroups(Ids: number[] = null) {

    let criteria = new GroupSearchCriteria();
    criteria.Ids = Ids && Ids.length > 0 ? Ids : null;
    criteria.FreeText = this.groupsFreeText;

    this.groupService
      .GetLiteGroups(criteria)
      .subscribe((response: GroupSearchResult) => {

        if (response.State == ResponseState.Success) {
          this.Groups = response.Groups;
        }
      });
  }

  onSearchGroups(e) {
    this.groupsFreeText = e.term;
    this.Groups = [];
    this.GetGroups();
  }

  customSearchFnGroups(term: string, item: Group) {
    term = term.toLowerCase();

    return (
      item.Name.toLowerCase().indexOf(term) > -1
    );
  }

  Save() {

    this.LoadingSaveGroups = true;
    let model = new EditClientGroupsModel();
    model.ClientId = this.ClientId;
    model.ClientGroupsIds = this.ClientGroupsIds;

    this.clientService
      .EditClientGroups(model)
      .subscribe((response: ExecutionResponse<boolean>) => {

        if (response.State == ResponseState.Success) {
          this.toastr.success(response.Message);
          this.activeModal.dismiss('Cross click');
        }

        this.LoadingSaveGroups = false;
      });
  }

}
