<div class="modal-header">
  <h4 class="modal-title"> {{ "CloseFinancialPeriod" | translate }} </h4>

  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-12">
      <form [formGroup]="GForm">
        <div class="cardTabs">
          <div class="tabsContent">
            <div class="formCard">
              <div class="row">
                <div class="col-12">
                </div>
                <div class="form-group  col-md-6">
                  <label>{{ "ChooseBranch" | translate }}</label>
                  <ng-select [items]="CompanyBranches" bindValue="Id"
                    [bindLabel]="sharedService.Lang == 'ar' ? 'Name' : 'Name'" placeholder="{{ 'Branch' | translate }}"
                    formControlName="BranchId" name="BranchId" class="form-group">
                  </ng-select>
                </div>

                <div class="form-group col-md-6">
                  <label>{{ "ClosingDate" | translate }}</label>
                  <input type="date" class="form-control" placeholder="{{ 'ClosingDate' | translate }}"
                    formControlName="ClosingPeriod" required name="ClosingPeriod" />
                </div>

                <div class="form-group col-md-6">
                  <label>{{ "ChooseProfitAndLossAccount" | translate }}</label>

                  <div class="input-group mb-3">
                    <input class="form-control" type="text" placeholder='{{"ChooseProfitAndLossAccount" | translate }}'
                      [disabled]="true" />
                    <div class="input-group-prepend">
                      <button class="btn btn-sm mx-0 my-0 px-3 z-depth-0 waves-effect" type="button" id="button-addon1"
                        [disabled]="!this.GForm.get('BranchId').value" (click)="chooseAccount()">
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>

                  </div>

                </div>


              </div>
            </div>
          </div>
        </div>
        <div class="formCard mt-3">
          <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-4">
              <button class="btn btn-success btn-block" (click)="CreateJournalForClosingPeriod()"
                [disabled]="!GForm.valid || IsSubmit">
                <span *ngIf="IsSubmit">
                  {{ "LoadingCloseThePeriod" | translate }}
                </span>
                <span *ngIf="!IsSubmit">
                  {{ "CloseThePeriod" | translate }}
                </span>
              </button>
            </div>
            <div class="col-md-4"></div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-modal-action" (click)="activeModal.close('Close click')">{{'Close' |
    translate}}</button>
</div>
