import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchResult } from '../../../models/support/search-result';
import { HttpBaseServiceV2Service } from '../../../services/http-base-service-v2.service';

@Injectable({
  providedIn: 'root',
})
export class GymMembershipAttendanceService {
  constructor(private httpService: HttpBaseServiceV2Service) { }

  GetCLasses(criteria: any): Observable<SearchResult<any>> {
    return this.httpService.Get('v2/gym/Membership/Attendances', criteria);
  }

  GetAttendance(attendanceId: number): Observable<any> {
    return this.httpService.Get(
      `v2/gym/Membership/Attendances/${attendanceId}`
    );
  }
  AttendClassClient(body: any): Observable<any> {
    return this.httpService.Post('GymClientMembership/AttendClassClient', body);
  }
  CreateAttendance(cls: any): Observable<any> {
    return this.httpService.Post('v2/gym/Membership/Attendances', cls);
  }
  Attend(cls: any): Observable<any> {
    return this.httpService.Put('v2/gym/Membership/Attendances', cls);
  }
  UpdateAttendance(attendanceId: number, cls: any): Observable<any> {
    return this.httpService.Post(
      `v2/gym/Membership/Attendances/${attendanceId}`,
      cls
    );
  }

  DeleteAttendance(attendanceId: any): Observable<any> {
    return this.httpService.Delete('v2/gym/Membership/Attendances', attendanceId);
  }
}
