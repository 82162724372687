import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { ClientBalanceStatus } from '../../models/enums/client-balance-status';
import { ResponseState } from '../../models/support/response-state';
import { SharedService } from '../../services/other/shared.service';
import { AppointmentService } from '../../services/appointment/appointment.service';
import { Client } from '../../models/classes/client/client';
import { GymClientMembershipService } from '../../services/gym/gym-client-membership.service';
import { ChangeGymMembershipClientModel } from '../../models/search-criterias/change-gym-membership-client-model';
import { ExecutionResponse } from '../../models/support/execution-response';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from 'src/app/services/client/client.service';
import { ClientSearchCriteria } from 'src/app/models/search-criterias/client-search-criteria';
import { GetForGBClientDTO } from 'src/app/models/classes/client/get-for-gb-client';
import { GetLiteForGBClientDTO } from 'src/app/models/classes/client/get-lite-for-gb-client';

@Component({
  selector: 'app-modal-change-gym-membership-client',
  templateUrl: './modal-change-gym-membership-client.component.html',
  styleUrls: ['./modal-change-gym-membership-client.component.scss'],
  providers: [DatePipe],
})
export class ModalChangeGymMembershipClient implements OnInit {
  @Input() GymClientMembershipId: number;
  @Input() CurrentClientName: string;
  @Output() public DoneChanging = new EventEmitter<ResponseState>();

  public freeText: string;
  public Clients: GetLiteForGBClientDTO[];
  public ClientId: number;
  public LoadingChangeClient: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    public translate: TranslateService,
    public clientService: ClientService,
    public sharedService: SharedService,
    private appointmentService: AppointmentService,
    public gymClientMembershipService: GymClientMembershipService,
    public toaster: ToastrService
  ) {}

  ngOnInit(): void {
    this.GetClient();
  }

  onSearch(e) {
    this.freeText = e.term;
    this.GetClient();
  }
  customSearchFn(term: string, item: Client) {
    term = term.toLowerCase();
    return (
      item.Name.toLowerCase().indexOf(term) > -1 ||
      item.Mobile.toLowerCase().indexOf(term) > -1 ||
      item.Mobile.toLowerCase() === term
    );
  }
  public get clientBalanceStatus(): typeof ClientBalanceStatus {
    return ClientBalanceStatus;
  }

  ChangeGymMembershipClient() {
    this.LoadingChangeClient = true;
    var model = new ChangeGymMembershipClientModel();

    model.AccountSetupId = this.sharedService.AccountSetupId;
    model.GymClientMembershipId = this.GymClientMembershipId;
    model.NewClientId = this.ClientId;

    this.gymClientMembershipService
      .ChangeGymMembershipClient(model)
      .subscribe((response: ExecutionResponse<boolean>) => {
        if (response.State == ResponseState.Success) {
          this.toaster.success(
            this.translate.instant('DoneChangeGymMembershipClientSuccessfully.')
          );
          this.activeModal.close();
        }
        this.LoadingChangeClient = false;
        this.DoneChanging.emit(response?.State);
      });
  }

  GetClient() {
    var clientSearchCriteria: ClientSearchCriteria = new ClientSearchCriteria();
    clientSearchCriteria.AccountSetupId = this.sharedService.AccountSetupId;
    clientSearchCriteria.PagingEnabled = true;
    clientSearchCriteria.PageSize = 2;
    clientSearchCriteria.PageNumber = 0;
    clientSearchCriteria.FreeText = this.freeText;
    clientSearchCriteria.Status = 1;

    this.clientService
      .GetClientsLite(clientSearchCriteria)
      .subscribe((response) => {
        if (response.State == ResponseState.Success) {
          this.Clients = response.Clients;
        }
      });
  }
}
