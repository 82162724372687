<div *ngIf="!multi">
  <ng-template class="w-75" #rt let-r="result" let-t="term">
    <ngb-highlight [result]="getDisplayoedResult(r)" [term]="t"></ngb-highlight>
  </ng-template>
  <div class="form-group field fieldPairedSearch Width100P">
    
    <input 
    #szautocomplete
    [required]="isRequired"
    [class.borderRrequired]="isRequired"
     id="typeahead-template" 
     type="text" 
     class="form-control" 
     [class.pen]="disabled"
     [formControl]="modelDataCtrl" 
     [placeholder]=" placeholderKey |translate"
     [ngbTypeahead]="autocomplete"
     [resultTemplate]="rt" 
     [inputFormatter]="formatter" 
     (selectItem)="ItemSelected($event)"
     [(ngModel)]="SelectedId"
     (focusin)="onFocus($event)" 
     (change)="$event.preventDefault()" 
     (focusout)="onFocusOut($event)" />

    <i *ngIf="!disabled  &&  !value  " (click)="unSelect()" class="fa fa-angle-down prefix grey-text"></i>
    <i *ngIf="!disabled  &&   value > 0 " (click)="unSelect()" class="fa fa-times-circle prefix grey-text"></i>
  </div>
</div>
<div *ngIf="multi">
  <div class="row">
    <div class="col-md-10">
        <ng-select 
   [ngClass]="'ng-select'" 
   [options]="ModelDataMulti"
   [multiple]="true" 
   [placeholder]="placeholderKey |translate"
   (filterInputChanged)="onFilterInputChanged($event)"
   (focus) = "onFilterInputChanged('')"
   [(ngModel)]="selectedOptions"
   (selected)="selected($event)"
   (deselected)="deselected($event)"
   [disabled]="disabled"
   ></ng-select>
    </div>
  
   <div class="col-md-2">
        
        <button *ngIf="!hideAddButton"  (click)="pushSelected()" type="button" class="addButton"><i  class="fa fa-plus"></i></button>



        <button *ngIf="viewClearAllButton" (click)="clearAll()" type="button" class="clearButton" title="{{ 'ClearAll' | translate }}"><i  class="fa fa-times"></i></button>

        <button *ngIf="viewAddPositionButton" (click)="addPosition()" type="button" class="addPositionButton" title="{{ 'CreatePosition' | translate }}"><i  class="fa fa-plus"></i></button>
    
    </div> 
    
  </div>
  </div>
